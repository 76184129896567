import { useState, useRef, useEffect } from 'react'
import { inject, observer } from 'mobx-react';
import { Input, Card, Button, ModalAlert, Select, FormLoading } from '../../components'
import { CommonService, MobileLogService } from "../../services";
import DataGrid, { SearchPanel, Scrolling, Item, Paging, Pager, Column, Toolbar, HeaderFilter } from 'devextreme-react/data-grid';
import { RefreshIcon, SearchIcon } from '@heroicons/react/outline';
import { DatePicker } from 'antd';
import dayjs from 'dayjs';
import moment from 'moment'
import { isEmpty } from 'lodash';

const { RangePicker } = DatePicker;
const dateFormat = 'YYYY-MM-DD HH:mm:ss';
let firstRender = false
const MobileLog = inject("authenStore")(observer((props) => {
  let authenStore = props.authenStore
  let { getAccountAuth } = authenStore // STATE
  let auth = { action_user_id: getAccountAuth?.user_id, token_id: getAccountAuth?.access_token }

  const [masterOptions, setMasterOptions] = useState({
    func_name: []
  })
  const [loading, setLoading]: any = useState(false)
  const [dataSource, setDataSource]: any = useState([])
  const [filterCurrent, setFilterCurrent]: any = useState({})
  const [filter, setFilter]: any = useState({
    sdate: moment().format("YYYY-MM-DD 00:00:00"),
    edate: moment().format("YYYY-MM-DD 23:59:59"),
    func_name: ""
  })
  const [optionAlert, setOptionAlert]: any = useState({
    show: false,
    action: {
      id: "",
      name: ""
    },
    type: "success",
    title: "",
    description: {
      visible: false,
      type: "",
      content: "",
      contentLists: []
    }
  })

  const gridRef: any = useRef(null);
  let columns = [
    {
      dataField: 'call_date',
      caption: "วันที่เรียก"
    },
    {
      dataField: 'call_func',
      caption: "ฟังก์ชัน",
      alignment: 'center'
    },
    {
      dataField: 'call_content',
      caption: "ข้อมูลเรียก"
    },
    {
      dataField: 'call_by',
      caption: "ร้องขอโดย"
    },
    {
      dataField: 'resp_content',
      caption: "ข้อมูลตอบกลับ"
    },
    {
      dataField: 'resp_date',
      caption: "เวลาตอบกลับ"
    }
  ]

  useEffect(() => {
    if (firstRender || !isEmpty(getAccountAuth)) {
      CommonService.getMasterData(auth, "func_name")
        .then((res: any) => {
          let func_name = res.result.func_name.map((item) => ({ label: item.desc, value: item.code }))
          setMasterOptions({
            ...masterOptions,
            func_name,
          })
        })
        .catch((err) => {
          setOptionAlert({
            ...optionAlert,
            type: "danger",
            show: true,
            action: {
              id: "",
              name: ""
            },
            title: "ผิดพลาด",
            description: { visible: true, content: `Load master data : ${err}` }
          })
        })
    }
    else
      firstRender = true
  }, [getAccountAuth])

  const searchLog = (isReloadCurrent = false) => {
    setLoading(true)
    setFilterCurrent(filter)
    let data = isReloadCurrent ? filterCurrent : filter
    MobileLogService.getMobileLog(auth, data)
      .then((res: any) => {
        let { code, message, result, sys_message } = res
        if (code === 200) {
          setDataSource(result)
        }
        else {
          setOptionAlert({
            ...optionAlert,
            type: "danger",
            show: true,
            action: {
              id: "",
              name: ""
            },
            title: "ผิดพลาด",
            description: { visible: true, type: "text", content: `${message} ${sys_message}` }
          })
        }
        setLoading(false)
      })
      .catch((err) => {
        setLoading(false)
        setOptionAlert({
          ...optionAlert,
          type: "danger",
          show: true,
          action: {
            id: "",
            name: ""
          },
          title: "ผิดพลาด",
          description: { visible: true, type: "text", content: `${err}` }
        })
      })
  }

  const execute = (id) => {
    MobileLogService.execute(auth, id)
      .then((res: any) => {
        let { code, message, result, sys_message } = res?.data
        if (code === 200) {
          setOptionAlert({
            ...optionAlert,
            type: "success",
            show: true,
            action: {
              id: "",
              name: ""
            },
            title: "Execute Success",
            description: { visible: false, type: "text", content: " ", contentLists: [] }
          })
          searchLog(true)
        }
        else {
          setOptionAlert({
            ...optionAlert,
            type: "danger",
            show: true,
            action: {
              id: "",
              name: ""
            },
            title: "ผิดพลาด",
            description: { visible: true, type: "text", content: `${message} ${sys_message}` }
          })
        }

      })
      .catch((err) => {
        setOptionAlert({
          ...optionAlert,
          type: "danger",
          show: true,
          action: {
            id: "",
            name: ""
          },
          title: "ผิดพลาด",
          description: { visible: true, type: "text", content: `${err}` }
        })
      })
  }
  //#endregion

  return (
    <div className="space-y-3 p-4">
      <Card title="ตรวจสอบ Log Mobile Application">
        <div className="mt-5 md:col-span-2 md:mt-0">
          <FormLoading loading={loading} >
            <div className="flex flex-row mb-2">
              <div className="flex flex-row space-x-2">
                <div className="flex flex-row">
                  <label className="text-sm font-medium text-gray-700 mt-3 mr-2 text-start">
                    วันที่ :
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <RangePicker
                      allowClear={false}
                      defaultValue={[dayjs(filter.sdate, dateFormat), dayjs(filter.edate, dateFormat)]}
                      style={{ marginTop: 4 }}
                      showTime={{
                        hideDisabledOptions: true,
                      }}
                      format="YYYY-MM-DD HH:mm:ss"
                      onChange={(d: any) => {
                        if (d) {
                          setFilter({
                            ...filter,
                            sdate: d[0].format("YYYY-MM-DD  HH:mm:ss"),
                            edate: d[1].format("YYYY-MM-DD  HH:mm:ss")
                          })
                        }
                        else {
                          setFilter({
                            ...filter,
                            sdate: "",
                            edate: ""
                          })
                        }
                      }}
                    />
                  </div>
                </div>

                <div className="flex flex-row">
                  <label className="text-sm font-medium text-gray-700 mt-3 mr-2 text-start">
                    ฟังก์ชัน :
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <Select
                      allowClear={true}
                      showSearch={true}
                      defaultValue={filter.func_name}
                      placeholder="ฟังก์ชัน"
                      width={150}
                      options={masterOptions.func_name}
                      onChange={(value) => setFilter({ ...filter, func_name: value })}
                    />
                  </div>
                </div>

                <div className="flex flex-row">
                  <label className="text-sm font-medium text-gray-700 mt-3 text-start"> </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0 pt-1">
                    <Button
                      text="ค้นหา"
                      icon={<SearchIcon />}
                      size="md"
                      type="white"
                      title="ค้นหา"
                      onClick={() => {
                        if (!loading) {
                          searchLog()
                        }
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>

            <DataGrid
              ref={(ref) => gridRef.current = ref}
              dataSource={dataSource}
              keyExpr={'id'}
              showBorders={true}
              columnAutoWidth={true}
              hoverStateEnabled={true}
            >
              <Scrolling columnRenderingMode="virtual" />
              <SearchPanel visible={false} />
              <Paging defaultPageSize={10} />
              <Pager
                showInfo={true}
                showPageSizeSelector={true}
                allowedPageSizes={[10, 20, 50]}
              />
              <HeaderFilter visible={true} />
              {
                columns.map((column) => <Column
                  key={column.dataField}
                  allowFiltering={false}
                  dataField={column.dataField}
                  caption={column.caption}
                  alignment={column.alignment}
                />)
              }

              <Column
                fixed={true}
                caption="Execute"
                type="buttons"
                width={80}
                buttons={[
                  {
                    hint: "Execute",
                    text: "Execute",
                    visible: true,
                    onClick: (e) => {
                      e.cancel = "true"
                      let data = e.row.data

                      setOptionAlert({
                        ...optionAlert,
                        action: {
                          id: data.id,
                          name: 'execute'
                        },
                        type: "confirm",
                        title: "ยืนยัน",
                        show: true,
                        description: {
                          visible: true,
                          type: "text",
                          content: "ยืนยัน Execute ?",
                          contentLists: []
                        }
                      })
                    }
                  }
                ]}
              />

              <Toolbar visible={false}>
                <Item location="after">
                  <button
                    type="button"
                    className="-mb-1 inline-flex items-center px-1.5 py-1.5 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50"
                    title="Refresh"
                    onClick={() => { }}
                  >
                    <RefreshIcon className="w-4 h-4" />
                  </button>
                </Item>

                <Item location="after">
                  <button
                    type="button"
                    className="-mb-1 inline-flex items-center px-1.5 py-1.5 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50"
                    title="Export"
                  >
                    <img className="h-4 w-4" src="/Images/excel.png" />
                  </button>
                </Item>

                <Item location="after">
                  <Input
                    icon={<SearchIcon className="w-5 h-5 text-gray-500" />}
                    placeholder="ค้นหา..."
                    onChange={(e) => gridRef.current.instance.searchByText(e.target.value)}
                  />
                </Item>
              </Toolbar>
            </DataGrid>
          </FormLoading>
        </div>
      </Card >

      <ModalAlert
        {...optionAlert}
        onOk={() => {
          let { type, action } = optionAlert
          if (action.name === "execute") {
            execute(action.id)
          }

          setOptionAlert({ ...optionAlert, show: false })
        }}
        onCancel={() => {
          setOptionAlert({ ...optionAlert, show: false })
        }}
      />
    </div>
  )
}))

export default MobileLog