import axios from 'axios'
import { BASE_URL } from '../AppConfig'
import IAuth from '../interfaces/Common/IAuth'

interface IRoute {
  status: string
  delivery_date: string,
  number_vehicle: number
  max_distance: number
  max_drop: number
  rest_time: number
  cond_id: number
}

interface ICond {
  number_vehicle: number
  max_distance: number
  max_drop: number
  rest_time: number
  cond_id: number
  max_item: number
}


export default {
  routeAutoExecute: async function (auth: IAuth, data: IRoute) {
    const response = await axios.post(`${BASE_URL}/route_auto/execute`, {
      status: data.status,
      delivery_date: data.delivery_date,
      number_vehicle: data.number_vehicle,
      max_distance: data.max_distance,
      max_drop: data.max_drop,
      rest_time: data.rest_time,
      cond_id: data.cond_id
    },
      {
        timeout: 3600000,
        headers: { action_user_id: auth?.action_user_id, token_id: auth?.token_id }
      })
    return response
  },

  routeAutoCheckModel: async function (auth: IAuth, data: IRoute) {
    const response = await axios.post(`${BASE_URL}/route_auto/check_model`, {
      status: data.status,
      delivery_date: data.delivery_date,
      number_vehicle: data.number_vehicle,
      max_distance: data.max_distance,
      max_drop: data.max_drop,
      rest_time: data.rest_time,
      cond_id: data.cond_id
    },
      {
        timeout: 3600000,
        headers: { action_user_id: auth?.action_user_id, token_id: auth?.token_id }
      })
    return response
  },

  checkBuildStatus: async function (auth: IAuth, build_model_id) {
    try {
      const response = await axios.get(`${BASE_URL}/route_auto/build_model_status?build_model_id=${build_model_id}`, {
        headers: { action_user_id: auth?.action_user_id, token_id: auth?.token_id }
      })
      return response.data
    } catch (error) {
      throw error
    }
  },

  getCond: async function (auth: IAuth) {
    try {
      const response = await axios.get(`${BASE_URL}/route_auto/get_cond`, {
        headers: { action_user_id: auth?.action_user_id, token_id: auth?.token_id }
      })
      return response.data
    } catch (error) {
      throw error
    }
  },

  setCond: async function (auth: IAuth, data: ICond) {
    const response = await axios.post(`${BASE_URL}/route_auto/set_cond`, {
      number_vehicle: data.number_vehicle,
      max_distance: data.max_distance,
      max_drop: data.max_drop,
      rest_time: data.rest_time,
      cond_id: data.cond_id,
      max_item: data.max_item
    },
      {
        headers: { action_user_id: auth?.action_user_id, token_id: auth?.token_id }
      })
    return response
  },


  getShippoint: async function (auth: IAuth) {
    try {
      const response = await axios.get(`${BASE_URL}/route_auto/get_shippoint`, {
        headers: { action_user_id: auth?.action_user_id, token_id: auth?.token_id }
      })
      return response.data
    } catch (error) {
      throw error
    }
  },
}