
import { useState, useRef, useEffect } from 'react'
import { inject, observer } from 'mobx-react';
import { Card, ModalAlert, Select, FormLoading } from '../../../components'
import { CommonService, ReportService } from "../../../services";
import { SearchIcon } from '@heroicons/react/outline';
import { DatePicker, FloatButton } from 'antd';
import { GOOGLE_MAP_KEY } from '../../../AppConfig'
import dayjs from 'dayjs';
import moment from 'moment'
import GoogleMapReact from "google-map-react"
import {
  Chart,
  Series,
  ArgumentAxis,
  CommonSeriesSettings,
  Export,
  Legend,
  ValueAxis,
  Point,
  Tooltip
} from 'devextreme-react/chart';
import { isEmpty } from 'lodash'

let firstRender = false
const { RangePicker } = DatePicker;
const dateFormat = 'YYYY-MM-DD HH:mm:ss';
let polylines: any = [], markerPoint: any = []
let locationAll: any = []
const Temperature = inject("authenStore")(observer((props) => {
  let authenStore = props.authenStore
  let { getAccountAuth } = authenStore // STATE
  let auth = { action_user_id: getAccountAuth?.user_id, token_id: getAccountAuth?.access_token }

  const [masterOptions, setMasterOptions] = useState({ vehicle: [] })
  const mapRef: any = useRef()
  const [map, setMap]: any = useState(null)
  const [loading, setLoading] = useState({ visible: false, tip: "กำลังโหลด" })
  const defaultTable = {
    reportName: "",
    description: "",
    reportTime: "",
    dataSource: [],
    columns: []
  }
  const [tableConfig, setTableConfig]: any = useState({
    reportName: "",
    description: "",
    reportTime: "",
    dataSource: [],
    columns: []
  })
  const [filter, setFilter]: any = useState({
    sdate: moment().format("YYYY-MM-DD 00:00:00"),
    edate: moment().format("YYYY-MM-DD 23:59:59"),
    mid: ""
  })
  const [optionAlert, setOptionAlert]: any = useState({
    show: false,
    action: {
      id: "",
      name: ""
    },
    type: "success",
    title: "",
    description: {
      visible: false,
      type: "",
      content: "",
      contentLists: [],

    }
  })

  const [info, setInfo]: any = useState({
    "licenseplate": "",
    "service_name": "",
    "range_date": "",
    "distance": "",
    "avg_temp": "",
    "locs": []
  })

  useEffect(() => {
    if (firstRender || !isEmpty(getAccountAuth)) {
      CommonService.getMasterData(auth, "vehicle")
        .then((res: any) => {
          let vehicle = res.result.vehicle.map((item) => ({ label: item.licenseplate, value: item.mid }))

          setMasterOptions({ ...masterOptions, vehicle })
          if (vehicle.length > 0) {
            setFilter({
              ...filter,
              mid: vehicle[0].value
            })
          }
        })
        .catch((err) => {
          setOptionAlert({
            ...optionAlert,
            type: "danger",
            show: true,
            action: {
              id: "",
              name: ""
            },
            title: "ผิดพลาด",
            description: { visible: true, content: `Load master data : ${err}` }
          })
        })
    }
    else
      firstRender = true
  }, [getAccountAuth])

  //#region 

  const searchReports = () => {
    setLoading({ ...loading, visible: true })
    ReportService.getTempReport(auth, filter)
      .then((res: any) => {
        let { code, message, result, sys_message } = res
        if (code === 200) {
          let { locs } = result
          setInfo(result)

          createGeoLine(locs)
        }
        else {
          setTableConfig(defaultTable)
          setOptionAlert({
            ...optionAlert,
            type: "danger",
            show: true,
            action: {
              id: "",
              name: ""
            },
            title: "ผิดพลาด",
            description: { visible: true, type: "text", content: `${message} ${sys_message}` }
          })
        }
        setLoading({ ...loading, visible: false })
      })
      .catch((err) => {
        setLoading({ ...loading, visible: false })
        setTableConfig(defaultTable)
        setOptionAlert({
          ...optionAlert,
          type: "danger",
          show: true,
          action: {
            id: "",
            name: ""
          },
          title: "ผิดพลาด",
          description: { visible: true, type: "text", content: `${err}` }
        })
      })
  }
  //#endregion

  const createGeoLine = (data) => {
    for (let index in polylines) polylines[index].setMap(null)
    polylines.length = 0
    for (let index in markerPoint) markerPoint[index].setMap(null)
    markerPoint.length = 0

    if (mapRef.current) {
      try {
        if (data.length > 0) {
          if (data.length > 2) {
            createMarker(data[0].lat, data[0].lon, "A")
            createMarker(data[data.length - 1].lat, data[data.length - 1].lon, "B")
          }
          else {
            createMarker(data[0].lat, data[0].lon, "A")
            createMarker(data[0].lat, data[0].lon, "B")
          }
        }

        let coordinates = data.map((item) => {
          locationAll.push({ lat: item?.lat, lng: item?.lon })
          return ({ lat: item?.lat, lng: item?.lon })
        })
        const polyLine: any = new window.google.maps.Polyline({
          path: coordinates,
          geodesic: true,
          strokeColor: 'blue',
          strokeOpacity: 0.8,
          strokeWeight: 4,
          draggable: false,
          visible: true,
          zIndex: 1
        });

        polylines.push(polyLine);
        polyLine.setMap(mapRef.current);

        fitBounds()

      } catch (error) {
        console.log("ERROR : createGeoLine : ", error)
      }
    }
  }

  const createMarker = (lat = 0, lng = 0, label = "A") => {
    try {
      if (map) {
        const marker: any = new window.google.maps.Marker({
          position: { lat, lng },
          map,
          label
        })

        markerPoint.push(marker);
      }
    } catch (error) {

    }
  }

  const fitBounds = () => {
    // fitBounds     
    let bounds: any = new window.google.maps.LatLngBounds();
    locationAll.forEach(item => {
      bounds.extend({ lat: item?.lat, lng: item?.lng })
    });
    mapRef.current.fitBounds(bounds)
  }

  return (
    <div className="space-y-3 p-4">
      <Card title="รายงานอุณหภูมิ">
        <div className="mt-5 md:col-span-2 md:mt-0"  >
          <FormLoading loading={loading.visible} tip={loading.tip} >
            <div className="flex flex-col mb-4  space-y-1 border-b border-bold">
              <div className="space-y-1 mb-6">
                <div className="flex lg:flex-row sm:flex-col">
                  <div className="flex-1 sm:grid sm:grid-cols-3 sm:items-end sm:gap-2">
                    <label className="block text-xs font-medium text-gray-700 mb-1 text-right">
                      ทะเบียนรถ :
                    </label>
                    <div className="mt-1 sm:col-span-2 sm:mt-0">
                      <Select
                        allowClear={false}
                        showSearch={true}
                        defaultValue={filter.mid}
                        placeholder="ทะเบียนรถ"
                        options={masterOptions.vehicle}
                        onChange={(value) => setFilter({ ...filter, mid: value })}
                      />
                    </div>
                  </div>

                  <div className="flex-1 sm:grid sm:grid-cols-4 sm:items-end sm:gap-2">
                    <label className="block text-xs font-medium text-gray-700 mb-1 text-right">
                      วันที่ :
                    </label>
                    <div className="mt-1 sm:col-span-3 sm:mt-0">
                      <RangePicker
                        allowClear={false}
                        defaultValue={[dayjs(filter.sdate, dateFormat), dayjs(filter.edate, dateFormat)]}
                        style={{ marginTop: 4, width: '100%' }}
                        showTime={{
                          hideDisabledOptions: true,
                        }}
                        format="YYYY-MM-DD HH:mm:ss"
                        onChange={(d: any) => {
                          if (d) {
                            setFilter({
                              ...filter,
                              sdate: d[0].format("YYYY-MM-DD HH:mm:ss"),
                              edate: d[1].format("YYYY-MM-DD HH:mm:ss")
                            })
                          }
                          else {
                            setFilter({
                              ...filter,
                              sdate: "",
                              edate: ""
                            })
                          }
                        }}
                      />
                    </div>
                  </div>

                  <div className="flex-none sm:grid sm:grid-cols-3 sm:items-end sm:gap-2">
                    <label className="block text-xs font-medium text-gray-700 mb-1 text-right">
                    </label>
                    <div className="flex flex-row mt-1 sm:col-span-2 sm:mt-0 space-x-1">
                      <button
                        type="button"
                        title="ค้นหา"
                        className={`flex flex-row ${loading.visible && 'cursor-not-allowed'} items-center rounded border border-transparent bg-blue-100 mt-0.5 px-2.5 py-1.5 text-xs font-medium text-blue-700 hover:bg-blue-200`}
                        onClick={() => searchReports()}>
                        <div className="flex flex-row-reverse">
                          <span>ค้นหา</span>
                          <SearchIcon className="w-4 h-4 mr-1" />
                        </div>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex lg:flex-row   md:flex-col sm:flex-col">
              <div className="shrink-0 mr-3 lg:w-1/5  lg:border-r md:border-b sm:border-b border-dashed">
                <div className="flex flex-col">
                  <div className="flex-row space-y-6">
                    <div className="grid grid-cols sm:items-start">
                      <span className="block text-sm font-semibold leading-2 bg-blue-100 px-2.5 py-0.5">
                        ทะเบียน
                      </span>
                      <span className="flex-1 flex-row truncate text-sm col-span-1 h-6 px-2.5 py-0.5" style={{ backgroundColor: "#f9f9f9" }}>{info?.licenseplate}</span>
                    </div>
                  </div>

                  <div className="flex-row">
                    <div className="grid grid-cols sm:items-start ">
                      <span className="block text-sm font-semibold leading-2 bg-blue-100 px-2.5 py-0.5">
                        พนักงาน
                      </span>
                      <span className="flex-1 flex-row truncate text-sm col-span-1 h-6 px-2.5 py-0.5" style={{ backgroundColor: "#f9f9f9" }}>{info?.service_name}</span>
                    </div>
                  </div>

                  <div className="flex-row">
                    <div className="grid grid-cols sm:items-start ">
                      <span className="block text-sm font-semibold leading-2 bg-blue-100 px-2.5 py-0.5">
                        วันที่
                      </span>
                      <span className="flex-1 flex-row truncate text-sm col-span-1 h-6 px-2.5 py-0.5" style={{ backgroundColor: "#f9f9f9" }}>{info?.range_date}</span>
                    </div>
                  </div>

                  <div className="flex-row">
                    <div className="grid grid-cols sm:items-start ">
                      <span className="block text-sm font-semibold leading-2 bg-blue-100 px-2.5 py-0.5">
                        {`ระยะทางรวม (กม.)`}
                      </span>
                      <span className="flex-1 flex-row truncate text-sm col-span-1 h-6 px-2.5 py-0.5" style={{ backgroundColor: "#f9f9f9" }}>{info?.distance}</span>
                    </div>
                  </div>

                  <div className="flex-row">
                    <div className="grid grid-cols sm:items-start ">
                      <span className="block text-sm font-semibold leading-2 bg-blue-100 px-2.5 py-0.5">
                        อุณหภูมิสูงสุด
                      </span>
                      <span className="flex-1 flex-row truncate text-sm col-span-1 h-6 px-2.5 py-0.5" style={{ backgroundColor: "#f9f9f9" }}>{info?.max_temp}</span>
                    </div>
                  </div>

                  <div className="flex-row">
                    <div className="grid grid-cols sm:items-start ">
                      <span className="block text-sm font-semibold leading-2 bg-blue-100 px-2.5 py-0.5">
                        อุณหภูมิต่ำสุด
                      </span>
                      <span className="flex-1 flex-row truncate text-sm col-span-1 h-6 px-2.5 py-0.5" style={{ backgroundColor: "#f9f9f9" }}>{info?.min_temp}</span>
                    </div>
                  </div>

                  <div className="flex-row">
                    <div className="grid grid-cols sm:items-start ">
                      <span className="block text-sm font-semibold leading-2 bg-blue-100 px-2.5 py-0.5">
                        อุณหภูมิเฉลี่ย
                      </span>
                      <span className="flex-1 flex-row truncate text-sm col-span-1 h-6 px-2.5 py-0.5" style={{ backgroundColor: "#f9f9f9" }}>{info?.avg_temp}</span>
                    </div>
                  </div>
                </div>
              </div>
              {/* overflow-auto */}
              <div className={`flex-1 flex flex-col lg:w-4/5   space-y-6`}>
                <div style={{ height: 400, width: '100%' }}>
                  <GoogleMapReact
                    bootstrapURLKeys={{ key: `${GOOGLE_MAP_KEY}&libraries=drawing,geometry` }}
                    defaultCenter={{ lat: 13.788500, lng: 100.610055 }}
                    defaultZoom={5}
                    yesIWantToUseGoogleMapApiInternals
                    onGoogleApiLoaded={({ map }) => {
                      mapRef.current = map
                      setMap(map)
                    }}
                  >
                  </GoogleMapReact>
                </div>

                <div className='flex-1'>
                  <Chart
                    palette="Violet"
                    dataSource={info?.locs}
                    style={{ height: 200 }}
                  >
                    <CommonSeriesSettings
                      argumentField="gpsdate"
                      type={"line"}
                    >
                      <Point visible={false} />
                    </CommonSeriesSettings>
                    <Series
                      valueField={"temp"}
                      name={"อุณหภูมิ"}
                    />
                    <ArgumentAxis
                      minVisualRangeLength={{ minutes: 10 }}
                      position={"buttom"}
                      argumentType={'datetime'}
                      label={{
                        format: "HH:mm"
                      }}
                    />
                    <ValueAxis
                      visualRange={[-30, 50]}
                      tickInterval={10}
                    ></ValueAxis>
                    <Legend visible={false} />
                    <Export enabled={false} />

                    <Tooltip enabled={true} />
                  </Chart>
                </div>
              </div>
            </div>

          </FormLoading>
        </div>
      </Card >

      <ModalAlert
        {...optionAlert}
        onOk={() => {
          setOptionAlert({ ...optionAlert, show: false })
        }}
        onCancel={() => {
          setOptionAlert({ ...optionAlert, show: false })
        }}
      />

      <FloatButton.BackTop type="primary" />
    </div >
  )
}))

export default Temperature