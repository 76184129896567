import { useState, useRef, useEffect } from 'react'
import { inject, observer } from 'mobx-react';
import { BASE_URL } from '../../../AppConfig'
import { setOptions } from '../../../helpers'
import { Input, Card, Button, ModalAlert, Select, FormLoading } from '../../../components'
import { CommonService, CalendarService } from "../../../services";
import DataGrid, { SearchPanel, Scrolling, Item, Paging, Pager, Column, Toolbar, HeaderFilter } from 'devextreme-react/data-grid';
import { RefreshIcon, SearchIcon, DownloadIcon, UploadIcon, PlusIcon } from '@heroicons/react/outline';
import ModalForm from './ModalForm';
import ModalCalendar from './ModalCalendar';
import { isEmpty } from 'lodash';

const dateFormat = 'YYYY-MM-DD';
let firstRender = false
const Calendar = inject("authenStore", "calendarStore")(observer((props) => {
  let authenStore = props.authenStore
  let calendarStore = props.calendarStore
  let { setCalendarStore } = calendarStore // STATE
  let { getAccountAuth } = authenStore // STATE
  let auth = { action_user_id: getAccountAuth?.user_id, token_id: getAccountAuth?.access_token }

  const gridRef: any = useRef(null);
  const inputRef: any = useRef(null);
  const [masterOptions, setMasterOptions] = useState({ calendar: [] })
  const [loading, setLoading] = useState({ visible: false, tip: "กำลังโหลด" })
  const [dataSource, setDataSource]: any = useState([])
  const [open, setOpen]: any = useState(false)
  const [filterCurrent, setFilterCurrent]: any = useState({})
  const [filter, setFilter]: any = useState({ year: "" })
  const [fileUploadName, setFileUploadName] = useState("")
  const [showTableName, setShowTableName] = useState("upload")
  const [optionAlert, setOptionAlert]: any = useState({
    show: false,
    action: {
      id: "",
      name: ""
    },
    type: "success",
    title: "",
    description: {
      visible: false,
      type: "",
      content: "",
      contentLists: [],

    }
  })

  const [dataForm, setDataForm]: any = useState({
    "dlv_doc_no": "",
    "route_id": "",
    "ts_gate_in": "",
    "ts_gate_out": "",
    "mileage_start": "",
    "mileage_end": "",
    "service_info": "",
    "tasks": [],
    "cost_items": []
  })

  let columns = [
    {
      dataField: 'no',
      caption: "ลำดับ",
      width: 90,
      alignment: "center"
    },
    {
      dataField: 'timeid',
      caption: "วันที่",
      alignment: "center"
    },
    {
      dataField: 'time_desc',
      caption: "รายละเอียด"
    }
  ]

  useEffect(() => {
    return () => {
      setCalendarStore({
        openModalCalendar: false,
        calendarData: {}
      })
    }
  }, [])

  useEffect(() => {
    if (firstRender || !isEmpty(getAccountAuth)) {
      CommonService.getMasterData(auth, "calendar")
        .then((res: any) => {
          let calendar = res.result.calendar.map((item) => ({ label: item.year, value: item.year }))

          let year = ""
          if (calendar.length > 0) year = calendar[0].value

          setMasterOptions({ calendar })
          setFilter({ year })
          setFilterCurrent({ year })
        })
        .catch((err) => {
          setOptionAlert({
            ...optionAlert,
            type: "danger",
            show: true,
            action: {
              id: "",
              name: ""
            },
            title: "ผิดพลาด",
            description: { visible: true, content: `Load master data : ${err}` }
          })
        })
    }
    else
      firstRender = true
  }, [getAccountAuth])

  const searchData = (isReloadCurrent = false) => {
    let year = isReloadCurrent ? filterCurrent?.year : filter?.year

    setLoading({ ...loading, visible: true, tip: 'กำลังโหลด' })
    setFilterCurrent(filter)

    CalendarService.getCalendar(auth, year)
      .then((res: any) => {
        let { code, message, result, sys_message } = res
        if (code === 200) {
          setDataSource(result)
        }
        else {
          setOptionAlert({
            ...optionAlert,
            type: "danger",
            show: true,
            action: {
              id: "",
              name: ""
            },
            title: "ผิดพลาด",
            description: { visible: true, type: "text", content: `${message} ${sys_message}` }
          })
        }
        setLoading({ ...loading, visible: false, tip: 'กำลังโหลด' })
      })
      .catch((err) => {
        setLoading({ ...loading, visible: false, tip: 'กำลังโหลด' })
        setOptionAlert({
          ...optionAlert,
          type: "danger",
          show: true,
          action: {
            id: "",
            name: ""
          },
          title: "ผิดพลาด",
          description: { visible: true, type: "text", content: `${err}` }
        })
      })
  }

  const deleteCalendar = (id) => {
    CalendarService.deleteCalendar(auth, id)
      .then((res: any) => {
        if (res.code === 200) {
          setOptionAlert(setOptions({
            defaultOption: optionAlert,
            show: true,
            title: "ลบข้อมูลสำเร็จ"
          }))
          searchData(true)
        }
      })
      .catch((err) => {
        setOptionAlert(setOptions({
          defaultOption: optionAlert,
          show: true,
          type: "danger",
          title: "ผิดพลาด",
          description: { visible: true, content: `${err}` }
        }))
      })
  }

  const handleFileChange = (e) => {
    if (!e.target.files) return;

    setShowTableName("upload")
    // VALIDATE FILE UPLOAD
    let isError = false
    const fileLists = Array.from(e.target?.files)
    fileLists.map((file: any) => {
      let nameSplite = file.name.split('.')
      let fileType = nameSplite[(nameSplite.length - 1)]
      setFileUploadName(file.name)
      if (!["xls", "xlsx"].includes(fileType)) {
        setOptionAlert({
          ...optionAlert,
          type: "danger",
          show: true,
          action: {
            id: "",
            name: ""
          },
          title: "อัปโหลดไฟล์ผิดพลาด",
          description: { visible: true, type: "text", content: `สามารถอัปโหลดไฟล์เฉพาะ Excel  เท่านั้น` }
        })

        isError = true
      }
    })

    if (!isError) {
      setLoading({ ...loading, visible: true, tip: 'กำลังอัปโหลด' })

      const data = new FormData();
      fileLists.forEach((file: any, i) => {
        data.append('files', file, file.name);
      });
      fetch(`${BASE_URL}/calendar/upload`, {
        method: 'POST',
        body: data,
      })
        .then((res) => res.json())
        .then((data) => {
          let { message, sys_message, result } = data
          if (data.code === 200) {
            setOptionAlert({
              ...optionAlert,
              type: "success",
              show: true,
              action: {
                id: "",
                name: ""
              },
              title: "อัปโหลดสำเร็จ",
              description: { visible: false, type: "text", content: " ", contentLists: [] }
            })
            searchData(true)
          }
          else {
            setOptionAlert({
              ...optionAlert,
              type: "danger",
              show: true,
              action: {
                id: "",
                name: ""
              },
              title: "ผิดพลาด",
              description: { visible: true, type: "text", content: `${message} ${sys_message}` }
            })
          }
          setLoading({ ...loading, visible: false, tip: '' })
        })
        .catch((err) => {
          setOptionAlert({
            ...optionAlert,
            type: "danger",
            show: true,
            action: {
              id: "",
              name: ""
            },
            title: "ผิดพลาด",
            description: { visible: true, type: "text", content: `${err}` }
          })
          setLoading({ ...loading, visible: false, tip: '' })
        })
    }
  }


  return (
    <div className="space-y-3 p-4">
      <Card title="ตารางวันหยุด">
        <div className="mt-5 md:col-span-2 md:mt-0">
          <FormLoading loading={loading.visible} >
            <div className="flex lg:flex-row sm:flex-col mb-2">
              <div className="flex-1">
                <div className="flex space-x-2">
                  <div className="flex flex-row">
                    <label className="text-sm font-medium text-gray-700 mt-3 mr-2 text-start">
                      วันหยุดประจำปี :
                    </label>
                    <div className="mt-1 sm:col-span-2 sm:mt-0">
                      <Select
                        showSearch={true}
                        defaultValue={filter.year}
                        placeholder="วันหยุดประจำปี"
                        width={130}
                        options={masterOptions.calendar}
                        onChange={(value) => setFilter({ ...filter, year: value })}
                      />
                    </div>
                  </div>

                  <div className="flex flex-row">
                    <label className="text-sm font-medium text-gray-700 mt-3 text-start"> </label>
                    <div className="mt-1 sm:col-span-2 sm:mt-0 pt-1">
                      <Button
                        text="แสดง"
                        icon={<SearchIcon />}
                        size="md"
                        type="primary"
                        title="แสดง"
                        onClick={() => {
                          if (!loading.visible) searchData()
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex-1">
                <div className='flex justify-end'>
                  <div className="flex flex-row">
                    <label className="text-sm font-medium text-gray-700 mt-3 text-start"> </label>
                    <div className="mt-1 sm:col-span-2 sm:mt-0 pt-1">
                      <input
                        id="input-upload"
                        className='hidden'
                        type="file"
                        ref={inputRef}
                        onChange={handleFileChange}
                      />

                      <Button
                        text="อัปโหลด"
                        icon={<UploadIcon />}
                        size="md"
                        type="primary"
                        title="อัปโหลด"
                        onClick={() => inputRef.current.click()}
                      />
                    </div>
                  </div>

                  <div className="flex flex-row">
                    <label className="text-sm font-medium text-gray-700 mt-3 text-start"> </label>
                    <div className="mt-1 sm:col-span-2 sm:mt-0 pt-1">
                      <Button
                        text="Excel"
                        icon={<DownloadIcon />}
                        size="md"
                        type="primary"
                        title="Excel"
                        onClick={() => window.open(`${BASE_URL}/calendar/example`)}
                      />
                    </div>
                  </div>

                  <div className="flex flex-row">
                    <label className="text-sm font-medium text-gray-700 mt-3 text-start"> </label>
                    <div className="mt-1 sm:col-span-2 sm:mt-0 pt-1">
                      <Button
                        text="เพิ่มวันหยุด"
                        icon={<PlusIcon />}
                        size="md"
                        type="primary"
                        title="เพิ่มวันหยุด"
                        onClick={() => setCalendarStore({ openModalCalendar: true })}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <DataGrid
              ref={(ref) => gridRef.current = ref}
              dataSource={dataSource}
              keyExpr={'id'}
              showBorders={true}
              columnAutoWidth={true}
              hoverStateEnabled={true}
            >
              <Scrolling columnRenderingMode="virtual" />
              <SearchPanel visible={false} />
              <Paging defaultPageSize={10} />
              <Pager
                showInfo={true}
                showPageSizeSelector={true}
                allowedPageSizes={[10, 20, 50]}
              />
              <HeaderFilter visible={true} />
              {
                columns.map((column) => <Column
                  key={column.dataField}
                  width={column.width}
                  allowFiltering={false}
                  dataField={column.dataField}
                  caption={column.caption}
                  alignment={column.alignment}
                />)
              }

              <Column
                fixed={true}
                caption="แก้ไข/ลบ"
                type="buttons"
                width={80}
                buttons={[
                  {
                    hint: "แก้ไข",
                    icon: "fa fa-pencil-square-o",
                    visible: true,
                    onClick: (e) => {
                      e.cancel = "true"
                      let data = e.row.data
                      setCalendarStore({
                        calendarData: {
                          "id": data.id,
                          "timeid": data.timeid,
                          "time_desc": data.time_desc
                        }
                      })
                      setCalendarStore({ openModalCalendar: true })
                    }
                  },
                  {
                    hint: "ลบ",
                    icon: "trash",
                    visible: true,
                    onClick: (e) => {
                      e.cancel = "true"
                      let data = e.row.data

                      setOptionAlert(setOptions({
                        defaultOption: alert,
                        action: {
                          id: data.id,
                          name: 'delete'
                        },
                        show: true,
                        type: "confirm",
                        title: "ยืนยัน",
                        description: {
                          visible: true,
                          content: "คุณแน่ใจที่จะลบข้อมูล ?"
                        }
                      }))
                    }
                  }
                ]} />
              <Toolbar visible={false}>
                <Item location="after">
                  <button
                    type="button"
                    className="-mb-1 inline-flex items-center px-1.5 py-1.5 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50"
                    title="Refresh"
                    onClick={() => { }}
                  >
                    <RefreshIcon className="w-4 h-4" />
                  </button>
                </Item>

                <Item location="after">
                  <button
                    type="button"
                    className="-mb-1 inline-flex items-center px-1.5 py-1.5 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50"
                    title="Export"
                  >
                    <img className="h-4 w-4" src="/Images/excel.png" />
                  </button>
                </Item>

                <Item location="after">
                  <Input
                    icon={<SearchIcon className="w-5 h-5 text-gray-500" />}
                    placeholder="ค้นหา..."
                    onChange={(e) => gridRef.current.instance.searchByText(e.target.value)}
                  />
                </Item>
              </Toolbar>
            </DataGrid>
          </FormLoading>
        </div>

        <ModalForm
          auth={auth}
          open={open}
          data={dataForm}
          onClosed={(reload) => {
            setOpen(false)
            reload && searchData(true)
          }}
          onReload={() => searchData(true)}
          masterOptions={masterOptions}
        />

        <ModalCalendar
          auth={auth}
          masterOptions={masterOptions}
          onReload={() => searchData(true)}
        />
      </Card >

      <ModalAlert
        {...optionAlert}
        onOk={() => {
          let { type, action } = optionAlert
          if (action.name === "delete") {
            deleteCalendar(action.id)
          }
          setOptionAlert({ ...optionAlert, show: false })
        }}
        onCancel={() => {
          setOptionAlert({ ...optionAlert, show: false })
        }}
      />
    </div>
  )
}))

export default Calendar