import axios from 'axios'
import { BASE_URL } from '../AppConfig'
import IAuth from '../interfaces/Common/IAuth'

interface ICalendar {
  timeid: string
  time_desc: string
}

interface ICalendarUpdate {
  timeid: string
  time_desc: string
  id: number
}

export default {
  getCalendar: async function (auth: IAuth, year: number) {
    try {
      const response = await axios.get(`${BASE_URL}/calendar/list?year=${year}`, {
        headers: { action_user_id: auth?.action_user_id, token_id: auth?.token_id }
      })
      return response.data
    } catch (error) {
      throw error
    }
  },

  saveCalendar: async function (auth: IAuth, data: ICalendar) {
    const response = await axios.post(`${BASE_URL}/calendar/add`, {
      timeid: data.timeid,
      time_desc: data.time_desc
    },
      {
        headers: { action_user_id: auth?.action_user_id, token_id: auth?.token_id }
      })
    return response
  },

  updateCalendar: async function (auth: IAuth, data: ICalendarUpdate) {
    const response = await axios.put(`${BASE_URL}/calendar/update`, {
      timeid: data.timeid,
      time_desc: data.time_desc,
      id: data.id
    },
      {
        headers: { action_user_id: auth?.action_user_id, token_id: auth?.token_id }
      })
    return response
  },


  deleteCalendar: async function (auth: IAuth, id) {
    try {
      const response = await axios.delete(`${BASE_URL}/calendar/delete?id=${id}`, {
        headers: {
          action_user_id: auth?.action_user_id, token_id: auth?.token_id
        }
      })
      return response.data
    } catch (error) {
      throw error
    }
  }
}