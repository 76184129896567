import { useEffect, useState, useRef } from 'react'
import { Input, Button } from '../../../components'
import DataGrid, { SearchPanel, Toolbar, Item, Scrolling, Selection, Column, HeaderFilter } from 'devextreme-react/data-grid';
import { PlusIcon, RefreshIcon, SearchIcon } from '@heroicons/react/outline';

const TableData = (props) => {
  let { onOpenForm } = props
  const gridRef: any = useRef(null);
  const [dataSource, setDataSource]: any = useState([])

  useEffect(() => {
    let data: any = [], round = 0
    while (round < 100) {
      data.push({
        id: (round + 1),
        code: '100' + round,
        storeName: "XXXX",
        status: "ใช้งาน"
      })
      round++
    }
    setDataSource(data)
  }, [])

  return <>
    <DataGrid
      ref={(ref) => gridRef.current = ref}
      dataSource={dataSource}
      keyExpr={'id'}
      showBorders={true}
      wordWrapEnabled={false}
      hoverStateEnabled={true}
      height="calc(100vh - 90px)"
    >
      <Selection
        mode="multiple"
        selectAllMode={'allPages'}
        showCheckBoxesMode={'always'}
        allowSelectAll={true}
      />
      <SearchPanel visible={true} />
      <Scrolling mode="virtual" />
      <HeaderFilter visible={true} />
      <Column
        allowFiltering={false}
        dataField={"code"}
        caption={"รหัส"}
        width={80}
      />
      <Column
        allowFiltering={true}
        dataField={"storeName"}
        caption={"ชื่อคลัง/สาขา"}
      />
      <Column
        allowFiltering={true}
        dataField={"status"}
        caption={"สถานะ"}
        width={80}
      />
      <Column
        fixed={true}
        type="buttons"
        width={110}
        buttons={[
          {
            hint: "แก้ไข",
            icon: "fa fa-pencil-square-o",
            visible: true,
            onClick: (e) => {
              e.cancel = "true"
              let data = e.row.data
            }
          },
          {
            hint: "ลบ",
            icon: "trash",
            visible: true,
            onClick: (e) => {
              e.cancel = "true"
              let data = e.row.data
            }
          }
        ]} />
      <Toolbar>
        <Item location="before">
          <span>{`จำนวน ${dataSource.length} รายการ`}</span>
        </Item>
        <Item location="after">
          <button
            type="button"
            className="-mb-1 inline-flex items-center px-1.5 py-1.5 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50"
            title="Refresh"
          >
            <RefreshIcon className="w-4 h-4" />
          </button>
        </Item>

        <Item location="after">
          <button
            type="button"
            className="-mb-1 inline-flex items-center px-1.5 py-1.5 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50"
            title="Export"
          >
            <img className="h-4 w-4" src="/Images/excel.png" />
          </button>
        </Item>


        <Item location="after">
          <Input
            icon={<SearchIcon className="w-5 h-5 text-gray-500" />}
            placeholder="ค้นหา..."
            size='sm'
            onChange={(e) => gridRef.current.instance.searchByText(e.target.value)}
          />
        </Item>

        <Item location="after">
          <Button
            text="เพิ่มข้อมูล"
            icon={<PlusIcon />}
            iconPosition='start'
            onClick={() => onOpenForm(true)}
            style={{ marginBottom: -5 }}
          />
        </Item>
      </Toolbar>
    </DataGrid>
  </>
}

export default TableData