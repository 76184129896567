import { t } from '../../helpers'
import { HomeIcon } from '@heroicons/react/outline'
import { InputNumber, Space } from 'antd';


export declare type IconPosition = 'start' | 'end';
export declare type roundedType = 'rounded' | 'full';
export declare type shapeType = 'circle';
export declare type SizeType = 'sm' | 'md' | 'lg';

type Props = {
    id?: string
    icon?: React.ReactNode;
    iconPosition?: IconPosition;
    label?: string
    labelHelp?: string
    labelOptional?: string
    disabled?: boolean
    // defaultValue?: string
    valueStart?: string
    valueEnd?: string
    hiddenLabel?: boolean
    maxLength?: number
    required?: boolean
    size?: SizeType
    placeholder?: string
    validate?: { isError: boolean; message: string };
    width?: number | string
    // onRef?: React.RefObject<HTMLInputElement>;
    onRef?: (ref: any) => void;
    onChange?: (value: any, field: string) => void;
    onKeyDown?: (value: any) => void;
}

export default (props: Props) => {
    let {
        id,
        icon,
        iconPosition = "start",
        label,
        labelHelp,
        labelOptional,
        disabled = false,
        // defaultValue = "",
        valueStart = "",
        valueEnd = "",
        hiddenLabel = false,
        required = false,
        size = "sm",
        maxLength = "",
        placeholder = "",
        validate = { isError: false, message: "" },
        width = "w-full",
        onRef,
        onChange,
        onKeyDown
    }: any = props

    return (
        <div>
            <div className={`flex justify-between ${hiddenLabel && 'sr-only'}`}>
                <label htmlFor="email" className={`flex flex-row text-sm font-medium text-gray-700 dark:text-gray-200`}>
                    {t(label)}<span className={`text-red-500 ${!required && 'sr-only'}`}>*</span>

                    <p className={`text-sm italic  ml-2
                     ${validate.isError ? 'text-danger-600' : 'text-gray-500'}
                     ${!validate.isError && 'sr-only'}`}
                    >
                        {`${validate.isError && validate.message}`}
                    </p>
                </label>
            </div>
            <div className={`${icon && 'relative'} mt-1`}>
                <div className={`pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3 ${icon && iconPosition === "start" ? '' : 'sr-only'}`}>
                    {icon}
                </div>
                {/* <input
                    id={id}
                    type="text"
                    ref={(ref) => onRef && onRef(ref)}
                    onChange={onChange}
                    onKeyDown={onKeyDown}
                    disabled={disabled}
                    maxLength={maxLength}
                    defaultValue={defaultValue}
                    className={`
                        block  
                        ${width}
                        ${size === "sm" ? 'h-8' : size === "lg" ? 'h-12' : 'h-auto'}
                        rounded-md 
                        ${validate.isError ?
                            'border-danger-400'
                            : 'border-gray-300 placeholder-gray-400'}
                        shadow-sm 
                        focus:border
                        focus:border-primary-400 
                        focus:ring-primary-400 
                        sm:text-sm
                        ${icon && iconPosition === "start" ? 'pl-10' : iconPosition === "end" ? 'pr-10' : ''}
                        ${disabled && 'bg-gray-50 text-gray-500 cursor-not-allowed'}
                        dark:bg-gray-800 dark:text-gray-200
                    `}
                    placeholder={t(placeholder)}
                /> */}
                <Space>
                    <InputNumber
                        value={valueStart}
                        onChange={(value) => onChange(value, "start")}
                    />
                    <InputNumber
                        value={valueEnd}
                        onChange={(value) => onChange(value, "end")}
                    />
                </Space>
                <div className={`pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3 ${icon && iconPosition === "end" ? '' : 'sr-only'}`}>
                    {icon}
                </div>
            </div>
        </div>
    )
}