
export interface CardTabListType {
    key: string;
    tab: React.ReactNode;
    disabled?: boolean;
}

type Props = {
    id?: string
    title?: string
    children: React.ReactNode
    headerPanel?: {
        visible: boolean,
        children?: React.ReactNode
    }
    footer?: {
        visible: boolean,
        flexCustom?: boolean,
        children?: React.ReactNode
    }
    headStyle?: React.CSSProperties;
    contentStyle?: React.CSSProperties;
    contentClass?: string;
    contentWithoutPadding?: boolean
    extra?: React.ReactNode;
    onChange?: (value: any) => void;
}

export default (props: Props) => {
    let {
        title,
        children,
        footer,
        extra,
        contentStyle,
        contentWithoutPadding = false
    }: any = props

    return (
        <div className="divide-y divide-gray-200 dark:divide-gray-600 overflow-hidden rounded-lg bg-white dark:bg-gray-800 shadow">
            {
                title && <div className="px-4 py-2 sm:px-6 dark:bg-gray-800">
                    <div className="-ml-4 -mt-4 flex flex-wrap items-center justify-between sm:flex-nowrap">
                        <div className="ml-4 mt-4">
                            <h3 className="text-md font-medium leading-6 text-gray-900 dark:text-white">{title}</h3>
                        </div>
                        {extra && <div className="ml-4 mt-4 flex flex-shrink-0">{extra}</div>}
                    </div>
                </div>
            }
            <div className={`${!contentWithoutPadding && 'px-4 py-5 sm:p-6'}`} style={{ ...contentStyle }}>
                {children}
            </div>
            {
                footer?.visible && <div className={`px-2 py-2 sm:px-6 mx-auto max-w-7xl ${!footer?.flexCustom && 'md:flex md:items-center md:justify-between'}`}>
                    {footer?.children}
                </div>
            }
        </div>
    )
}