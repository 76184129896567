import FormMaster from '../FormMaster'
const Carrier = () => {
  return <FormMaster
    title="ข้อมูลประเภทเชื้อเพลิง"
    tableConfig={{
      key: "id",
      apiPath: "fuel/list",
      allowDelete: {
        visible: true,
        key: "fuel_id",
        apiPath: "fuel/delete",
        paramType: "param"
      },
      optionDeleteFag: true,
      defaultColumn: [],
      columns: [
        {
          dataField: 'no',
          caption: "ลำดับ",
          width: 60,
          alignment: "center",
          allowFiltering: false
        },
        {
          dataField: 'fuel_code',
          caption: "รหัสเชื้อเพลิง",
          allowFiltering: true
        },
        {
          dataField: 'fuel_name',
          caption: "ชื่อเชื้อเพลิง",
          allowFiltering: true
        },
        {
          dataField: 'updated_by',
          caption: "แก้ไขโดย"
        }
      ],
      exportExcel: {
        fileName: 'ข้อมูลประเภทเชื้อเพลิง',
        columnConfig: [
          { label: 'ลำดับ', value: 'no' },
          { label: 'รหัสเชื้อเพลิง', value: 'fuel_code' },
          { label: 'ชื่อเชื้อเพลิง', value: 'fuel_name' },
          { label: 'แก้ไขโดย', value: 'updated_by' }
        ]
      }
    }}
    formData={{
      fuel_code: "",
      fuel_name: ""
    }}
    modalConfig={{
      visible: true,
      title: "เชื้อเพลิง",
      required: ["fuel_code", "fuel_name"],
      fieldTypeNumber: [],
      paramName: "fuel_id",
      apiPath: {
        "get": '',
        "post": 'fuel/add',
        "put": 'fuel/update'
      },
      elements: [
        {
          fieldName: "fuel_code",
          label: "รหัสเชื้อเพลิง",
          placeholder: "รหัสเชื้อเพลิง",
          required: true,
          inputType: "text",
          isOneRow: true
        },
        {
          fieldName: "fuel_name",
          label: "ชื่อเชื้อเพลิง",
          placeholder: "ชื่อเชื้อเพลิง",
          required: true,
          inputType: "text",
          isOneRow: true
        }
      ]
    }}
  />
}

export default Carrier