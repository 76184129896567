import axios from 'axios'
import { BASE_URL } from '../AppConfig'
import IAuth from '../interfaces/Common/IAuth'

interface ICost {
  vehicleType: string
}

export default {
  getCost: async function (auth: IAuth, vehicleType: ICost) {
    try {
      const response = await axios.get(`${BASE_URL}/cost_set/list?vehicle_type_id=${vehicleType}`, {
        headers: { action_user_id: auth?.action_user_id, token_id: auth?.token_id }
      })
      return response.data
    } catch (error) {
      throw error
    }
  },


  addCostSet: async function (auth: IAuth, data) {
    const response = await axios.post(`${BASE_URL}/cost_set/add`, data,
      {
        headers: { action_user_id: auth?.action_user_id, token_id: auth?.token_id }
      })
    return response
  },


  updateCostSet: async function (auth: IAuth, data) {
    const response = await axios.put(`${BASE_URL}/cost_set/update`, data,
      {
        headers: { action_user_id: auth?.action_user_id, token_id: auth?.token_id }
      })
    return response
  },

  updateFuelSet: async function (auth: IAuth, data) {
    const response = await axios.put(`${BASE_URL}/fuel_set/update`, data,
      {
        headers: { action_user_id: auth?.action_user_id, token_id: auth?.token_id }
      })
    return response
  },
}