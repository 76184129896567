import { useEffect, useState } from 'react'
import { inject, observer } from 'mobx-react';
import { GoogleMap } from '../../../components'
import TableData from './TableData';
import FormData from './FormData';

const Stored = inject("authenStore")(observer((props) => {
  const [dataSource, setDataSource]: any = useState([])
  const [openForm, setOpenForm] = useState(false)

  useEffect(() => {
    let data: any = [], round = 0
    while (round < 50) {
      data.push({
        id: (round + 1),
        code: '100' + round,
        storeName: "XXXX",
        status: "ใช้งาน"
      })
      round++
    }
    setDataSource(data)
  }, [])

  return <>
    <div className="flex flex-row">
      <div className="w-1/3 m-3 " style={{ height: "calc(100vh - 90px)" }}>
        {
          openForm ? <FormData
            onOpenForm={(open) => setOpenForm(open)}
          />
            : <TableData
              onOpenForm={(open) => setOpenForm(open)}
            />
        }
      </div>
      <div className="w-2/3">
        <GoogleMap
          id="realtime-map"
          height="calc(100vh - 64px)"
          customOptions={[{
            id: 'legend',
            name: 'Legend',
            icon: 'InformationCircleIcon',
            active: true,
            visible: true
          }]}
          onMapRef={(map) => {

          }}
          optionChange={(id, active) => {

          }}
        >
        </GoogleMap>
      </div>
    </div>

  </>
}))

export default Stored