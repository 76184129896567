import { useState, useRef, useEffect } from 'react'
import { inject, observer } from 'mobx-react';
import { createMultipleRef, getValueInputRef } from '../../helpers'
import { Modal, Select, Card, Button, ModalAlert, FormLoading, Input } from '../../components'
import { StandardCostService, CommonService } from '../../services';
import DataGrid, { Editing, SearchPanel, Scrolling, Paging, Column, RequiredRule, Toolbar, HeaderFilter } from 'devextreme-react/data-grid';
import { CogIcon } from '@heroicons/react/outline';
import './style.css'
import { PlusIcon } from '@heroicons/react/solid';
import { get, isEmpty } from 'lodash'

let firstRender = false
const Cost = inject("authenStore")(observer((props) => {
  let authenStore = props.authenStore
  let { getAccountAuth } = authenStore // STATE
  let auth = { action_user_id: getAccountAuth?.user_id, token_id: getAccountAuth?.access_token }

  const [open, setOpen]: any = useState(false)
  const [shakeIsWrong, setShakeIsWrong]: any = useState(false)
  const [dataForm, setDataForm]: any = useState({
    show: false,
    action: "add",
    data: {},
    fuelValue: []
  })
  const [loading, setLoading] = useState({ visible: false, tip: "กำลังโหลด" })
  const [dataSource, setDataSource]: any = useState({
    fuel_range: [],
    routes: []
  })
  const [vehicleType, setVehicleType]: any = useState("")
  const [fuelRange, setFuelRange]: any = useState([])
  const [masterOptions, setMasterOptions] = useState({
    vehicle_type: [],
    province: [],
    distince: []
  })
  const [ampSelected, setAmpSelected]: any = useState("")
  const [optionAlert, setOptionAlert]: any = useState({
    show: false,
    action: {
      id: "",
      name: "",
      data: {}
    },
    type: "success",
    title: "",
    description: {
      visible: false,
      type: "",
      content: "",
      contentLists: [],

    }
  })
  const [validation, setValidation]: any = useState({})
  const [columns, setColumns]: any = useState([
    {
      dataField: 'no',
      caption: "ลำดับ",
      width: 60,
      alignment: "center",
      fixed: true
    },
    {
      dataField: 'name',
      caption: "เส้นทาง",
      fixed: true,
      required: true
    },
    {
      dataField: 'prov_name',
      caption: "จังหวัด",
      width: 150,
      required: true,
      lookup: "prov"
    },
    {
      dataField: 'amp_name',
      caption: "เขต/อำเภอ",
      width: 150,
      lookup: "amp"
    },
    {
      dataField: 'updated_by',
      caption: "ผู้ปรับปรุง"
    },
    {
      dataField: 'updated_date',
      caption: "วันที่ปรับปรุง"
    }
  ])

  const gridRef: any = useRef(null);
  const gridCostRef: any = useRef(null);
  const inputRef: any = useRef([]);
  createMultipleRef(inputRef, 2)

  useEffect(() => {
    if (firstRender || !isEmpty(getAccountAuth)) {
      CommonService.getMasterData(auth, "vehicle_type,admin_level1")
        .then((res: any) => {
          let vehicle_type = res.result.vehicle_type.map((item) => ({ label: item.vehicle_type_name, value: item.vehicle_type_id }))
          let admin_level1 = res.result.admin_level1.map((item) => ({ label: item.admin_level1_name, value: item.admin_level1_id }))
          setMasterOptions({
            ...masterOptions,
            vehicle_type,
            province: admin_level1
          })
          if (vehicle_type.length > 0) {
            setVehicleType(vehicle_type[0].value)
            getCostSet(vehicle_type[0].value)
            setAmpSelected(vehicle_type[0].value)
          }
        })
        .catch((err) => {
          setOptionAlert({
            ...optionAlert,
            type: "danger",
            show: true,
            action: {
              id: "",
              name: ""
            },
            title: "ผิดพลาด",
            description: { visible: true, content: `Load master data : ${err}` }
          })
        })
    }
    else
      firstRender = true
  }, [getAccountAuth])

  const onChangeProvince = (id_ref1) => { // GET MASTER DISTRICT
    if (id_ref1) {
      CommonService.getMasterData(auth, "admin_level2", id_ref1)
        .then((res: any) => {
          let admin_level2 = res.result.admin_level2.map((item) => ({ label: item.admin_level2_name, value: item.admin_level2_id }))
          setMasterOptions({
            ...masterOptions,
            distince: admin_level2
          })
        })
        .catch((err) => {
        })
    }
    else {
      setMasterOptions({
        ...masterOptions,
        distince: []
      })
    }
  }

  const clearForm = () => {
    setOpen(false)
    setFuelRange(dataSource?.fuel_range)
    resetFuelValue()
    setValidation({})
  }

  const resetFuelValue = () => {
    let fuel_values: any = []
    fuelRange.forEach((item) => {
      fuel_values.push({
        id: item.id,
        fuel_range_name: item.name,
        value: 0
      })
    });
    setDataForm({
      show: false,
      action: "add",
      data: {},
      fuelValue: fuel_values
    })
  }

  const getCostSet = (vehicle_type) => {
    setLoading({ ...loading, visible: true, tip: "กำลังโหลด..." })
    StandardCostService.getCost(auth, vehicle_type)
      .then((res: any) => {
        let { code, message, result, sys_message } = res
        if (code === 200) {
          let { fuel_range } = result
          setColumnConfig(fuel_range)
          setDataSource(result)
          setFuelRange(JSON.parse(JSON.stringify(fuel_range)))

          let fuel_values: any = []
          fuel_range.forEach((item) => {
            fuel_values.push({
              id: item.id,
              fuel_range_name: item.name,
              value: 0
            })
          });
          setDataForm({ ...dataForm, show: false, fuelValue: fuel_values })
        }
        else {
          setOptionAlert({
            ...optionAlert,
            type: "danger",
            show: true,
            action: {
              id: "",
              name: ""
            },
            title: "ผิดพลาด",
            description: { visible: true, type: "text", content: `${message} ${sys_message}` }
          })
        }
        setLoading({ ...loading, visible: false })
      })
      .catch((err) => {
        setLoading({ ...loading, visible: false })
        setOptionAlert({
          ...optionAlert,
          type: "danger",
          show: true,
          action: {
            id: "",
            name: ""
          },
          title: "ผิดพลาด",
          description: { visible: true, type: "text", content: `${err}` }
        })
      })
  }

  const updateCostSet = () => {
    let { action, data, fuelValue } = dataForm
    let formData = getValueInputRef(inputRef)

    let values = fuelValue.map((item) => ({ id: item.id, value: item.value }))

    let body = {
      "id": action === "add" ? 0 : data.id,
      "prov_id": data.prov_id,
      "cost_name": formData.cost_name,
      "amp_id": data.amp_id || "",
      "vehicle_type_id": vehicleType,
      "values": values
    }

    setLoading({ ...loading, visible: true, tip: "กำลังบันทึก..." })
    if (action === "add") {
      StandardCostService.addCostSet(auth, body)
        .then((res: any) => {
          let { code, message, result, sys_message } = res?.data
          if (code === 200) {
            setOptionAlert({
              ...optionAlert,
              type: "success",
              show: true,
              action: {
                id: "",
                name: "",
                data: {}
              },
              title: "บันทึกข้อมูลสำเร็จ",
              description: { visible: false, type: "text", content: " ", contentLists: [] }
            })
            getCostSet(vehicleType)
            // setDataForm({ ...dataForm, show: false })
            clearForm()
          }
          else {
            setOptionAlert({
              ...optionAlert,
              type: "danger",
              show: true,
              action: {
                id: "",
                name: "",
                data: {}
              },
              title: "ผิดพลาด",
              description: { visible: true, type: "text", content: `${message} ${sys_message}` }
            })
          }
          setLoading({ ...loading, visible: false })
        })
        .catch((err) => {
          setLoading({ ...loading, visible: false })
          setOptionAlert({
            ...optionAlert,
            type: "danger",
            show: true,
            action: {
              id: "",
              name: "",
              data: {}
            },
            title: "ผิดพลาด",
            description: { visible: true, type: "text", content: `${err}` }
          })
        })
    }
    else {
      StandardCostService.updateCostSet(auth, body)
        .then((res: any) => {
          let { code, message, result, sys_message } = res?.data
          if (code === 200) {
            setOptionAlert({
              ...optionAlert,
              type: "success",
              show: true,
              action: {
                id: "",
                name: "",
                data: {}
              },
              title: "บันทึกข้อมูลสำเร็จ",
              description: { visible: false, type: "text", content: " ", contentLists: [] }
            })
            getCostSet(vehicleType)
            clearForm()
          }
          else {
            setOptionAlert({
              ...optionAlert,
              type: "danger",
              show: true,
              action: {
                id: "",
                name: "",
                data: {}
              },
              title: "ผิดพลาด",
              description: { visible: true, type: "text", content: `${message} ${sys_message}` }
            })
          }
          setLoading({ ...loading, visible: false })
        })
        .catch((err) => {
          setLoading({ ...loading, visible: false })
          setOptionAlert({
            ...optionAlert,
            type: "danger",
            show: true,
            action: {
              id: "",
              name: "",
              data: {}
            },
            title: "ผิดพลาด",
            description: { visible: true, type: "text", content: `${err}` }
          })
        })
    }
  }

  const updateFuelSet = () => {
    let { action } = optionAlert
    let data = { "fuel_range": action?.data }
    setLoading({ ...loading, visible: true, tip: "กำลังบันทึก..." })
    StandardCostService.updateFuelSet(auth, data)
      .then((res: any) => {
        let { code, message, result, sys_message } = res?.data
        if (code === 200) {
          setOptionAlert({
            ...optionAlert,
            type: "success",
            show: true,
            action: {
              id: "",
              name: "",
              data: {}
            },
            title: "บันทึกข้อมูลสำเร็จ",
            description: { visible: false, type: "text", content: " ", contentLists: [] }
          })
          setOpen(false)
          getCostSet(vehicleType)
          clearForm()
        }
        else {
          setOptionAlert({
            ...optionAlert,
            type: "danger",
            show: true,
            title: "ผิดพลาด",
            description: { visible: true, type: "text", content: `${message} ${sys_message}` }
          })
        }
      })
      .catch((err) => {
        setLoading({ ...loading, visible: false })
        setOptionAlert({
          ...optionAlert,
          type: "danger",
          show: true,
          title: "ผิดพลาด",
          description: { visible: true, type: "text", content: `${err}` }
        })
      })
  }
  //#endregion

  const setColumnConfig = (fuel_range) => {
    if (fuel_range.length > 0) {
      let defaultColumn = [{ ...columns[0] }, { ...columns[1] }, { ...columns[2] }, { ...columns[3] }]

      fuel_range.forEach((range, idx) => {
        defaultColumn.push({
          dataField: `values[${idx}].value`,
          caption: fuel_range[idx]?.name,
          alignment: "center",
          dataType: "number",
          required: true
        })
      });

      defaultColumn.push(
        {
          dataField: 'updated_by',
          caption: "ผู้ปรับปรุง"
        },
        {
          dataField: 'updated_date',
          caption: "วันที่ปรับปรุง"
        })

      setColumns(defaultColumn)
    }
  }

  //#region MANAGE COST
  const getVehicleTypeName = () => {
    let vehiceType: any = masterOptions.vehicle_type.find((item) => item['value'] === vehicleType)
    return get(vehiceType, 'label', '')
  }

  const onSave = () => {
    let formData = getValueInputRef(inputRef)
    let validation: any = {}
    if (formData.cost_name === "") {
      validation.cost_name = { isError: true, message: "โปรดระบุ" }
    }
    if (get(dataForm?.data, 'prov_id', "") === "") {
      validation.prov_id = { isError: true, message: "โปรดระบุ" }
    }

    if (isEmpty(validation)) {
      setValidation({})
      setOptionAlert({
        ...optionAlert,
        action: {
          id: "",
          name: "update_cost_set",
          data: fuelRange
        },
        type: "confirm",
        title: "ยืนยัน",
        show: true,
        description: {
          visible: true,
          type: "text",
          content: "คุณแน่ใจที่จะบันทึกข้อมูล ?",
          contentLists: []
        }
      })
    }
    else {
      setValidation(validation)
      setShakeIsWrong(true)
      setTimeout(() => setShakeIsWrong(false), 100);
    }
  }
  //#endregion

  return (
    <div className="space-y-3 p-4">
      <Card
        title="กำหนดค่าขนส่ง"
        extra={<>
          <Button
            text="เพิ่มข้อมูล"
            icon={<PlusIcon />}
            iconPosition='start'
            onClick={() => setDataForm({
              ...dataForm,
              show: true,
              action: 'add',
              data: {}
            })}
          />

          <Button
            text="จัดการช่วงราคา"
            icon={<CogIcon />}
            iconPosition='start'
            onClick={() => setOpen(true)}
          />
        </>}
      >
        <div className="mt-5 md:col-span-2 md:mt-0">
          <FormLoading loading={loading.visible} tip={loading.tip}>
            <div className="flex flex-row mb-2">
              <label className="text-sm font-medium text-gray-700 mt-3 mr-2 text-start">
                ประเภทรถ :
              </label>
              <div className="mt-1 sm:col-span-2 sm:mt-0">
                <Select
                  showSearch={true}
                  defaultValue={vehicleType}
                  placeholder="ประเภทรถ"
                  width={200}
                  options={masterOptions.vehicle_type}
                  onChange={(value) => {
                    setVehicleType(value)
                    getCostSet(value)
                  }}
                />
              </div>
            </div>
            <DataGrid
              dataSource={dataSource?.routes}
              keyExpr={'id'}
              showBorders={true}
              columnAutoWidth={true}
              hoverStateEnabled={true}
              height="calc(100vh - 237px)"
            >
              <Scrolling mode="virtual" />
              <SearchPanel visible={false} />
              <HeaderFilter visible={true} />
              {
                columns.map((column) => <Column
                  cssClass="row-middle"
                  fixed={column.fixed}
                  key={column.dataField}
                  width={column.width}
                  allowFiltering={false}
                  dataField={column.dataField}
                  caption={column.caption}
                  alignment={column.alignment}
                  cellRender={column?.cellRender}
                />)
              }
              <Column
                fixed={true}
                caption="แก้ไข"
                type="buttons"
                alignment="center"
                width={50}
                buttons={[
                  {
                    hint: "แก้ไข",
                    icon: "fa fa-pencil-square-o",
                    visible: true,
                    onClick: (e) => {
                      let data = e.row.data
                      e.cancel = "true"
                      let fuelValue = [...dataForm.fuelValue]
                      data?.values.forEach((item, index) => {
                        fuelValue[index].value = item?.value
                      });

                      onChangeProvince(data.prov_id)

                      setDataForm({
                        ...dataForm,
                        show: true,
                        action: 'update',
                        data,
                        fuelValue
                      })
                    }
                  }
                ]} />
              <Toolbar visible={false} />
            </DataGrid>
          </FormLoading>
        </div>
      </Card >

      <Modal
        title={{ text: "ช่วงราคา" }}
        open={open}
        maskClosable={true}
        contentPadding="p-0"
        width='lg:w-2/4 sm:4/4'
        onClosed={() => clearForm()}
        onOk={() => {
          setOptionAlert({
            ...optionAlert,
            action: {
              id: "",
              name: "update_fuel_set",
              data: fuelRange
            },
            type: "confirm",
            title: "ยืนยัน",
            show: true,
            description: {
              visible: true,
              type: "text",
              content: "คุณแน่ใจที่จะบันทึกข้อมูล ?",
              contentLists: []
            }
          })
        }}
        extra={<Button
          text="เพิ่มข้อมูล"
          icon={<PlusIcon />}
          size="sm"
          type="white"
          iconPosition='start'
          onClick={() => gridRef.current.instance.addRow()}
        />}
      >
        <FormLoading loading={loading.visible} tip="กำลังบันทึก...">
          <div className="space-y-2" style={{ padding: 20 }}>
            <DataGrid
              ref={(ref) => gridRef.current = ref}
              dataSource={fuelRange}
              keyExpr="id"
              showBorders={true}
              allowColumnReordering={true}
              columnAutoWidth={false}
              hoverStateEnabled={true}
              height={300}
              onSaved={(e) => {
                let dataSave = e.component.getDataSource().items()
                let dataRunning = dataSave.map((item, idx) => ({ ...item, id: (idx + 1) }))
                setFuelRange(dataRunning)
              }}
            >
              <Scrolling columnRenderingMode="virtual" />
              <Paging enabled={false} />
              <Editing
                mode="row"
                allowUpdating={true}
                texts={{
                  editRow: "แก้ไข",
                  cancelRowChanges: "ยกเลิก",
                  saveRowChanges: "บันทึก"
                }} />
              <Column
                dataField="id"
                caption="ลำดับ"
                width={60}
                alignment="center"
                allowEditing={false}
                allowFiltering={false}
                allowSorting={false}
              />
              <Column dataField="name" caption="รายละเอียด" >
                <RequiredRule />
              </Column>
              <Column dataField="min" caption="ต่ำสุด" >
                <RequiredRule />
              </Column>
              <Column dataField="max" caption="สูงสุด" >
                <RequiredRule />
              </Column>
            </DataGrid>
          </div>
        </FormLoading>
      </Modal>

      <Modal
        title={{
          text: `${dataForm.action === "add" ? 'เพิ่ม' : 'แก้ไข'}ค่าขนส่ง`,
          description: `ประเภทรถ : ${getVehicleTypeName()}`
        }}
        open={dataForm.show}
        maskClosable={true}
        contentPadding="p-0"
        width='lg:w-2/4 sm:4/4'
        shake={shakeIsWrong}
        onClosed={() => clearForm()}
        onOk={() => onSave()}
      >
        <FormLoading loading={loading.visible} tip="กำลังบันทึก...">
          <div className="space-y-2" style={{ padding: 20 }}>
            <div className="flex flex-col space-y-2">

              <div className="flex flex-row">
                <label className="text-sm font-medium text-gray-700 mt-3 mr-2 text-start">
                  เส้นทาง<span className="text-red-500">*</span> :
                </label>

                <div className="flex-1">
                  <Input
                    id="cost_name"
                    label="เส้นทาง"
                    placeholder="เส้นทาง"
                    hiddenLabel={true}
                    validate={validation['cost_name']}
                    defaultValue={dataForm.data.name}
                    width="w-full"
                    onRef={(ref) => inputRef.current[0] = ref}
                  />
                </div>
              </div>

              <div className="flex flex-row">
                <div className="flex flex-1 flex-row">
                  <label className="text-sm font-medium text-gray-700 mt-3 mr-2 text-start">
                    จังหวัด<span className="text-red-500">*</span> :
                  </label>
                  <div className="flex-1 ml-1 mr-3">
                    <Select
                      allowClear={false}
                      showSearch={true}
                      defaultValue={dataForm.data?.prov_id}
                      placeholder="จังหวัด"
                      hiddenLabel={true}
                      validate={validation['prov_id']}
                      options={masterOptions.province}
                      onChange={(value) => {
                        onChangeProvince(value)
                        setDataForm({
                          ...dataForm, data: {
                            ...dataForm.data, prov_id: value
                          }
                        })
                      }}
                    />
                  </div>
                </div>

                <div className="flex flex-1 flex-row">
                  <label className="text-sm font-medium text-gray-700 mt-3 mr-2 text-start">
                    เขต/อำเภอ :
                  </label>
                  <div className="flex-1 mt-1 sm:mt-0">
                    <Select
                      allowClear={true}
                      showSearch={true}
                      defaultValue={dataForm.data?.amp_id}
                      placeholder="เขต/อำเภอ"
                      options={masterOptions.distince}
                      onChange={(value) => setDataForm({
                        ...dataForm, data: {
                          ...dataForm.data, amp_id: value
                        }
                      })}
                    />
                  </div>
                </div>

              </div>

            </div>
            <DataGrid
              ref={(ref) => gridCostRef.current = ref}
              dataSource={dataForm.fuelValue}
              keyExpr="id"
              showBorders={true}
              allowColumnReordering={true}
              columnAutoWidth={false}
              hoverStateEnabled={true}
              height={300}
              onSaved={(e) => {
                let dataSave = e.component.getDataSource().items()
                let dataRunning = dataSave.map((item, idx) => ({ ...item, id: (idx + 1) }))
                setFuelRange(dataRunning)
              }}
            >
              <Scrolling columnRenderingMode="virtual" />
              <Paging enabled={false} />
              <Editing
                mode="row"
                allowUpdating={true}
                texts={{
                  editRow: "แก้ไข",
                  cancelRowChanges: "ยกเลิก",
                  saveRowChanges: "บันทึก"
                }} />
              <Column dataField="fuel_range_name" caption="ช่วงราคา" allowEditing={false} />
              <Column dataField="value" caption="ราคา" dataType="number" >
                <RequiredRule />
              </Column>
            </DataGrid>
          </div>
        </FormLoading>
      </Modal>

      <ModalAlert
        {...optionAlert}
        onOk={() => {
          let { action } = optionAlert

          if (action.name === "update_cost_set") updateCostSet()
          if (action.name === "update_fuel_set") updateFuelSet()

          setOptionAlert({ ...optionAlert, show: false })
        }}
        onCancel={() => {
          setOptionAlert({ ...optionAlert, show: false })
        }}
      />
    </div>
  )
}))

export default Cost