import axios from 'axios'
import { BASE_URL } from '../AppConfig'
import IAuth from '../interfaces/Common/IAuth'

interface ICost {
  sdate: string
  edate?: string
  vid?: string
  service_id?: string
  status?: string
  job_status?: string
  doc_no?: string
}

interface IComment {
  route_id: string
  drop_id: string
  remark?: string
}

interface IFltlist {
  sdate: string
  edate: string
  vid?: string
}


export default {
  //#region DELIVERY COST
  getDeliveryCost: async function (auth: IAuth, data: ICost) {
    let prmList: any = []
    for (const key in data) {
      if (data[key] !== "") prmList.push(`${key}=${data[key]}`)
    }
    let param = prmList.join('&');

    try {
      const response = await axios.get(`${BASE_URL}/dlv_cost/list?${param}`, {
        headers: { action_user_id: auth?.action_user_id, token_id: auth?.token_id }
      })
      return response.data
    } catch (error) {
      throw error
    }
  },

  updateDeliveryCost: async function (auth: IAuth, data) {
    const response = await axios.put(`${BASE_URL}/dlv_cost/update`, data,
      {
        headers: { action_user_id: auth?.action_user_id, token_id: auth?.token_id }
      })
    return response
  },

  approveDeliveryCost: async function (auth: IAuth, data) {
    const response = await axios.put(`${BASE_URL}/dlv_cost/approve`, data,
      {
        headers: { action_user_id: auth?.action_user_id, token_id: auth?.token_id }
      })
    return response
  },

  //#endregion

  //#region FLEET CARD
  uploadDoc: async function (auth: IAuth, data: IComment) {
    const response = await axios.post(`${BASE_URL}/dlv_cost/upload_doc`, data,
      {
        headers: { action_user_id: auth?.action_user_id, token_id: auth?.token_id }
      })
    return response
  },

  clearCost: async function (auth: IAuth, id) {
    try {
      const response = await axios.delete(`${BASE_URL}/dlv_cost/clear?route_ids=${id}`,
        {
          headers: { action_user_id: auth?.action_user_id, token_id: auth?.token_id }
        }
      )
      return response.data
    } catch (error) {
      throw error
    }
  },

  getFlclist: async function (auth: IAuth, data: IFltlist) {
    let prmList: any = []
    for (const key in data) {
      if (data[key] !== "") prmList.push(`${key}=${data[key]}`)
    }
    let param = prmList.join('&');

    try {
      const response = await axios.get(`${BASE_URL}/dlv_cost/flclist?${param}`, {
        headers: { action_user_id: auth?.action_user_id, token_id: auth?.token_id }
      })
      return response.data
    } catch (error) {
      throw error
    }
  },
  //#endregion
}