
import React, { useEffect, useState, useRef } from "react";
import { inject, observer } from 'mobx-react';
import { Button, ModalAlert, Modal, FormLoading, DatePicker, Input, Select } from '../../components'
import { CommonService, RouteService } from "../../services";
import { PlusIcon } from "@heroicons/react/solid";
import DataGrid, { MasterDetail, Paging, RowDragging, Column } from 'devextreme-react/data-grid';
import './style.css'
import SearchQueue from "./SearchQueue";
import { get, isEmpty } from 'lodash'

// const ModalPlan = (props) => {
let inputRemark: any = null, firstRender = false
const ModalPlan = inject("routeStore", "authenStore")(observer((props) => {
  let routeStore = props.routeStore
  let authenStore = props.authenStore
  let { getAccountAuth } = authenStore // STATE
  let auth = { action_user_id: getAccountAuth?.user_id, token_id: getAccountAuth?.access_token }
  let {
    getIsRefreshData,
    setRouteStore,
    getDefaultDeliveryDate,
    getDataPlanEdit
  } = routeStore // ACTIONS

  let { open, action } = props // STATES
  let { onClosed, onReloadData } = props // ACTIONS
  const gridRef: any = useRef(null);
  const [loading, setLoading] = useState(false)
  const [showSearch, setShowSearch] = useState(false)
  const [validation, setValidation]: any = useState({})
  const [shakeIsWrong, setShakeIsWrong] = useState(false)
  const [currentRouteId, setCurrentRouteId]: any = useState("")
  const [jobTypeDesc, setJobTypeDesc]: any = useState("")
  const [modalInfo, setModalInfo]: any = useState({
    open: false,
    title: "",
    name: "",
    icon: ''
  })
  const [dataSource, setDataSource]: any = useState([])
  const [dataForm, setDataForm]: any = useState({
    no: "",
    route_id: "",
    center_id: "",
    loc_receipt_id: "",
    service_id: "",
    logistic_type_id: "",
    loc_receipt_name: "",
    doc_no: "",
    licenseplate: "",
    vid: "",
    job_type_id: "",
    job_type_name: "",
    way: 0,
    away: 0,
    way_time: 0,
    away_time: 0,
    loc_receipt_lat: "",
    loc_receipt_lon: "",
    delivery_date: "",
    status: "",
    status_name: "",
    delivery_cost: "",
    total_dimension: "",
    total_weight: "",
    doc_url: "",
    loc_receipt_icon_url: "",
    total_distance: "",
    total_time: "",
    remark: "",
    drops: [],
    price_value: ""
  })

  const [optionAlert, setOptionAlert]: any = useState({
    show: false,
    action: {
      id: "",
      name: ""
    },
    type: "success",
    title: "",
    description: {
      visible: false,
      type: "",
      content: "",
      contentLists: []
    }
  })

  const [masterOptions, setMasterOptions] = useState({
    service: [],
    shipping_point: [],
    vehicle: [],
    job_type: [],
    logistic_type: [],
    dlv_time: [],
    center: []
  })

  useEffect(() => {
    if (firstRender || !isEmpty(getAccountAuth)) {
      CommonService.getMasterData(auth, "service,vehicle,shipping_point,job_type,logistic_type,dlv_time,center")
        .then((res: any) => {
          let service = res.result.service.map((item) => ({ label: item.service_name, value: item.service_id }))
          let vehicle = res.result.vehicle.map((item) => ({ label: item.licenseplate, value: item.vehicle_id }))
          let shipping_point = res.result.shipping_point.map((item) => ({ label: item.shipping_name, value: item.shipping_id }))
          let job_type = res.result.job_type.map((item) => ({ label: item.job_Type_name, value: item.job_type_id, desc: item.job_type_desc }))
          let logistic_type = res.result.logistic_type.map((item) => ({ label: item.logistic_type_name, value: item.logistic_type_id }))
          let dlv_time = res.result.dlv_time.map((item) => ({ label: item.dlv_time, value: item.dlv_time_id }))
          let center = res.result.center.map((item) => ({ label: item.ceneter_name, value: item.center_id }))

          setMasterOptions({
            ...masterOptions,
            service,
            vehicle,
            shipping_point,
            job_type,
            logistic_type,
            dlv_time,
            center
          })
        })
        .catch((err) => {

        })
    }
    else
      firstRender = true
  }, [getAccountAuth])

  useEffect(() => {
    getIsRefreshData && getRoute(currentRouteId)
  }, [getIsRefreshData])

  useEffect(() => {
    if (action === "create") {
      setDataForm({
        ...dataForm,
        delivery_date: getDefaultDeliveryDate
      })
    }
  }, [open])

  useEffect(() => {
    let data = getDataPlanEdit
    if (!isEmpty(data) && action === "update") {
      setDefaultFormData([data])
      setCurrentRouteId(data.route_id)

      if (data?.job_type_id !== "") setJobDesc(data?.job_type_id)
    }
    else {
      setJobTypeDesc("")
      setDefaultFormData([])
    }
  }, [getDataPlanEdit])

  const setJobDesc = (id) => {
    let info: any = masterOptions.job_type.find((item: any) => item.value === id)
    if (info) setJobTypeDesc(info.desc)
  }

  const getRoute = (route_id) => {
    RouteService.getRoute(auth, {
      delivery_date: dataForm.delivery_date,
      route_id
    })
      .then((res: any) => {
        let { code, message, sys_message, result } = res
        if (code === 200) {
          setDefaultFormData(result)
        }
        else {
          setOptionAlert({
            ...optionAlert,
            type: "danger",
            show: true,
            action: {
              id: "",
              name: ""
            },
            title: "ดึงข้อมูลผิดพลาด",
            description: { visible: true, type: "text", content: `${message} ${sys_message}` }
          })
        }
        setLoading(false)
        setRouteStore({ isRefreshData: false })
      })
      .catch((err) => {
        setLoading(false)
        setRouteStore({ isRefreshData: false })
        setOptionAlert({
          ...optionAlert,
          type: "danger",
          show: true,
          action: {
            id: "",
            name: ""
          },
          title: "ดึงข้อมูลผิดพลาด",
          description: { visible: true, type: "text", content: `webapi/route/list : ${err}` }
        })
      })
  }

  const setDefaultFormData = (data) => {
    if (data.length > 0) {
      let dt = data[0]
      setDataForm({
        ...dataForm,
        no: dt.no,
        route_id: dt.route_id,
        center_id: dt.center_id,
        loc_receipt_id: dt.loc_receipt_id,
        service_id: dt.service_id,
        logistic_type_id: dt.logistic_type_id,
        loc_receipt_name: dt.loc_receipt_name,
        doc_no: dt.doc_no,
        licenseplate: dt.licenseplate,
        vid: dt.vid,
        job_type_id: dt.job_type_id,
        job_type_name: dt.job_type_name,
        way: dt.way,
        away: dt.away,
        way_time: dt.way_time,
        away_time: dt.away_time,
        loc_receipt_lat: dt.loc_receipt_lat,
        loc_receipt_lon: dt.loc_receipt_lon,
        delivery_date: dt.delivery_date,
        status: dt.status,
        status_name: dt.status_name,
        delivery_cost: dt.delivery_cost,
        total_dimension: dt.total_dimension,
        total_weight: dt.total_weight,
        total_distance: dt.total_distance,
        total_time: dt.total_time,
        doc_url: dt.doc_url,
        remark: dt.remark,
        loc_receipt_icon_url: dt.loc_receipt_icon_url,
        drops: dt.drops,
        price_value: dt.price_value
      })
      setDataSource(dt.drops)
    }
    else {
      setDataSource([])
      initialData()
    }
  }

  const onSave = () => {
    let validateCheck = {}, message = { isError: true, message: "โปรดระบุ" }

    if (dataForm.delivery_date.trim() === "")
      validateCheck['delivery_date'] = message
    if (dataForm.vid.length === 0 || dataForm.vid === "")
      validateCheck['vid'] = message
    if (dataForm.service_id.length === 0 || dataForm.service_id === "")
      validateCheck['service_id'] = message
    if (dataForm.center_id.length === 0 || dataForm.center_id === "")
      validateCheck['center_id'] = message
    if (dataForm.logistic_type_id.length === 0 || dataForm.logistic_type_id === "")
      validateCheck['logistic_type_id'] = message
    if (dataForm.drops.length === 0)
      validateCheck['drops'] = message

    if (isEmpty(validateCheck)) {
      setValidation({})
      setOptionAlert({
        ...optionAlert,
        action: {
          id: dataForm.id,
          name: action
        },
        type: "confirm",
        title: "ยืนยัน",
        show: true,
        description: {
          visible: true,
          type: "text",
          content: "คุณแน่ใจที่จะบันทึกข้อมูล ?",
          contentLists: []
        }
      })
    }
    else {
      setValidation(validateCheck)
      setShakeIsWrong(true)
      setTimeout(() => setShakeIsWrong(false), 100);
    }
  }

  const saveData = () => {
    let drops = dataSource.map((item) => ({
      drop_id: item.drop_id,
      dlv_time_id: item.dlv_time_id,
      remark: item.remark
    }))

    setLoading(true)
    RouteService.addRoute(auth, {
      vid: dataForm.vid,
      logistic_type_id: dataForm.logistic_type_id,
      service_id: dataForm.service_id,
      loc_receipt_id: dataForm.loc_receipt_id,
      center_id: dataForm.center_id,
      job_type_id: dataForm.job_type_id,
      delivery_date: dataForm.delivery_date,
      route_id: dataForm.route_id,
      remark: dataForm.remark,
      drops
    })
      .then((res: any) => {
        let { code, message, sys_message } = res.data
        if (code === 200) {
          setOptionAlert({
            ...optionAlert,
            type: "success",
            show: true,
            action: {
              id: "",
              name: ""
            },
            title: action === "create" ? "สร้างแผนงานสำเร็จ" : "แก้ไขแผนงานสำเร็จ",
            description: { visible: false, type: "text", content: " ", contentLists: [] }
          })
          onReloadData()
          initialData()
          onClosed(true)
        }
        else {
          setOptionAlert({
            ...optionAlert,
            type: "danger",
            show: true,
            action: {
              id: "",
              name: ""
            },
            title: action === "create" ? "สร้างแผนงานไม่สำเร็จ" : "แก้ไขแผนงานไม่สำเร็จ",
            description: { visible: true, type: "text", content: `${message} ${sys_message}` }
          })
        }
        setLoading(false)
      })
      .catch((err) => {
        setOptionAlert({
          ...optionAlert,
          type: "danger",
          show: true,
          action: {
            id: "",
            name: ""
          },
          title: action === "create" ? "สร้างแผนงานไม่สำเร็จ" : "แก้ไขแผนงานไม่สำเร็จ",
          description: { visible: true, type: "text", content: `${err}` }
        })
        setLoading(false)
      })
  }

  const initialData = () => {
    setShowSearch(false)
    setValidation({})
    setDataSource([])
    setCurrentRouteId("")
    setDataForm({
      no: "",
      route_id: "",
      loc_receipt_id: "",
      center_id: "",
      loc_rcenter_ideceipt_id: "",
      service_id: "",
      logistic_type_id: "",
      loc_receipt_name: "",
      doc_no: "",
      licenseplate: "",
      vid: "",
      job_type_id: "",
      job_type_name: "",
      way: 0,
      away: 0,
      way_time: 0,
      away_time: 0,
      loc_receipt_lat: "",
      loc_receipt_lon: "",
      delivery_date: action === "create" ? getDefaultDeliveryDate : "",
      status: "",
      status_name: "",
      delivery_cost: "",
      total_dimension: "",
      total_weight: "",
      doc_url: "",
      loc_receipt_icon_url: "",
      total_distance: "",
      total_time: "",
      remark: "",
      drops: [],
      price_value: ""
    })
  }

  const onReorder = (e) => {
    const visibleRows = e.component.getVisibleRows();
    const newTasks = [...dataSource];

    const toIndex = newTasks.findIndex((item) => item.drop_id === visibleRows[e.toIndex].data.drop_id);
    const fromIndex = newTasks.findIndex((item) => item.drop_id === e.itemData.drop_id);

    newTasks.splice(fromIndex, 1);
    newTasks.splice(toIndex, 0, e.itemData);

    setDataSource(newTasks)
  }

  const labelError = (fieldName) => {
    if (get(validation, fieldName)) {
      if (validation[fieldName].isError)
        return <p className="text-xs italic text-danger-600">
          {validation[fieldName].message}
        </p>
      else
        return null
    }
  }

  const DetailQueueTemplate = inject("routeStore")(observer((props) => {
    let routeStore = props.routeStore
    let { setRouteStore } = routeStore // ACTIONS
    const [optionAlert, setOptionAlert]: any = useState({
      show: false,
      action: {
        id: "",
        name: ""
      },
      type: "success",
      title: "",
      description: {
        visible: false,
        type: "",
        content: "",
        contentLists: [],

      }
    })
    let dataSource = props?.data?.data?.queues
    let route_id = props?.data?.data?.route_id

    const removeQueue = (q_id) => {
      RouteService.removeQueue(auth, route_id, q_id)
        .then((res: any) => {
          let { code, message, sys_message } = res
          if (code === 200) {
            setOptionAlert({
              ...optionAlert,
              type: "success",
              show: true,
              action: {
                id: "",
                name: ""
              },
              title: "ลบข้อมูลสำเร็จ",
              description: { visible: false, type: "text", content: " ", contentLists: [] }
            })
            setRouteStore({ isRefreshData: true })
          }
          else {
            setOptionAlert({
              ...optionAlert,
              type: "danger",
              show: true,
              action: {
                id: "",
                name: ""
              },
              title: "ผิดพลาด",
              description: { visible: true, type: "text", content: `${message} ${sys_message}` }
            })
          }
        })
        .catch((err) => {
          setOptionAlert({
            ...optionAlert,
            type: "danger",
            show: true,
            action: {
              id: "",
              name: ""
            },
            title: "ผิดพลาด",
            description: { visible: true, type: "text", content: `${err}` }
          })
        })
    }

    return <>
      <React.Fragment>
        <div className="master-detail-caption">
          ข้อมูลใบส่งสินค้า
        </div>
        <DataGrid
          dataSource={dataSource}
          showBorders={true}
          columnAutoWidth={true}
          width={"80%"}
        >
          <Column
            dataField="no"
            caption="ลำดับ"
            width={50}
            alignment="center"
          />
          <Column dataField="qno" caption="เลขที่ใบงาน" />
          <Column dataField="delivery_cost" caption="ค่าขนส่ง(บาท)" width={150} />
          <Column
            fixed={true}
            caption="ลบ"
            type="buttons"
            width={50}
            buttons={[
              {
                hint: "ลบ",
                icon: "trash",
                visible: true,
                onClick: (e) => {
                  e.cancel = "true"
                  let dt = e.row.data
                  setOptionAlert({
                    ...optionAlert,
                    action: {
                      id: e.row.data.q_id,
                      name: 'delete'
                    },
                    show: true,
                    type: "confirm",
                    title: "ยืนยัน",
                    description: {
                      visible: true,
                      type: "text",
                      content: "คุณแน่ใจที่จะลบข้อมูล ?",
                      contentLists: []
                    }
                  })
                }
              }
            ]} />
          <MasterDetail
            enabled={true}
            component={DetailProducTemplate}
          />
        </DataGrid>
      </React.Fragment>

      <ModalAlert
        {...optionAlert}
        onOk={() => {
          let { action } = optionAlert

          if (action.name === "delete") removeQueue(action.id)

          setOptionAlert({ ...optionAlert, show: false })
        }}
        onCancel={() => {
          setOptionAlert({ ...optionAlert, show: false })
        }}
      />
    </>
  }))

  const DetailProducTemplate = (props) => {
    let dataSource = props?.data?.data?.items

    return <React.Fragment>
      <div className="master-detail-caption">
        รายละเอียดสินค้า
      </div>
      <DataGrid
        dataSource={dataSource}
        showBorders={true}
        columnAutoWidth={true}
      >
        <Column dataField="product_code" caption="รหัสสินค้า" width={150} />
        <Column dataField="product_name" caption="ชื่อสินค้า" />
        <Column dataField="qty" caption="จำนวน" width={100} />
        <Column dataField="unit_name" caption="หน่วย" width={100} />
        <Column dataField="delivery_cost" caption="ค่าขนส่ง(บาท)" width={100} />

      </DataGrid>
    </React.Fragment>
  }

  return (<>
    <Modal
      title={{ text: `${action === "create" ? 'สร้าง' : 'แก้ไข'}แผนงาน` }}
      open={open}
      maskClosable={true}
      width={"w-4/5"}
      loading={false}
      onClosed={() => {

      }}
      shake={shakeIsWrong}
      icon={modalInfo.icon}
      onOk={() => { }}
      footer={<>
        <Button
          text="บันทึก"
          width="w-20"
          type="primary"
          onClick={() => onSave()}
        />

        <Button
          text="ยกเลิก"
          width="w-20"
          type="white"
          onClick={() => {
            initialData()
            onClosed(false)
            onReloadData(true)
          }}
        />
      </>}
    >
      <FormLoading loading={loading} tip="กำลังบันทึก...">
        <div className="flex flex-col -my-4 ">
          <div className="flex lg:flex-row  sm:flex-col sm:space-y-2">
            <div className="flex-1 sm:grid sm:grid-cols-3 sm:items-end sm:gap-2">
              <label className="block text-xs font-medium text-gray-700 mb-1 text-right">
                วันที่ส่งสินค้า<span className="text-red-500">* </span> :{labelError("delivery_date")}
              </label>
              <div className="mt-1 sm:col-span-2 sm:mt-0">
                <DatePicker
                  allowClear={false}
                  hiddenLabel={true}
                  placeholder="วันที่จัดส่ง"
                  validate={validation['delivery_date']}
                  defaultValue={dataForm.delivery_date}
                  onChange={(d) => {
                    if (d)
                      setDataForm({ ...dataForm, delivery_date: d.format("YYYY-MM-DD") })
                    else
                      setDataForm({ ...dataForm, delivery_date: "" })
                  }}
                />
              </div>
            </div>

            <div className="flex-1 sm:grid sm:grid-cols-3 sm:items-end sm:gap-2">
              <label className="block text-xs font-medium text-gray-700 mb-1 text-right">
                เลขที่แผนงาน :
              </label>
              <div className="mt-1 sm:col-span-2 sm:mt-0">
                <Input
                  placeholder="เลขที่แผนงาน"
                  disabled={true}
                  defaultValue={dataForm.doc_no}
                />
              </div>
            </div>
          </div>

          <div className="flex lg:flex-row  sm:flex-col sm:space-y-2">
            <div className="flex-1 sm:grid sm:grid-cols-3 sm:items-end sm:gap-2">
              <label className="block text-xs font-medium text-gray-700 mb-1 text-right">
                ทะเบียนรถ<span className="text-red-500">* </span> :{labelError("vid")}
              </label>
              <div className="mt-1 sm:col-span-2 sm:mt-0">
                <Select
                  showSearch={true}
                  hiddenLabel={true}
                  defaultValue={dataForm.vid}
                  placeholder="ทะเบียนรถ"
                  validate={validation['vid']}
                  options={masterOptions.vehicle}
                  onChange={(value) => setDataForm({ ...dataForm, vid: value })}
                />
              </div>
            </div>

            <div className="flex-1 sm:grid sm:grid-cols-3 sm:items-end sm:gap-2">
              <label className="block text-xs font-medium text-gray-700 mb-1 text-right">
                พนักงาน<span className="text-red-500">* </span> :{labelError("service_id")}
              </label>
              <div className="mt-1 sm:col-span-2 sm:mt-0">
                <Select
                  showSearch={true}
                  hiddenLabel={true}
                  defaultValue={dataForm.service_id}
                  placeholder="พนักงาน"
                  validate={validation['service_id']}
                  options={masterOptions.service}
                  onChange={(value) => setDataForm({ ...dataForm, service_id: value })}
                />
              </div>
            </div>
          </div>

          <div className="flex lg:flex-row sm:flex-col sm:space-y-2">
            <div className="flex-1 sm:grid sm:grid-cols-3 sm:items-end sm:gap-2">
              <label className="block text-xs font-medium text-gray-700 mb-1 text-right">
                หน่วยงาน<span className="text-red-500">* </span> :{labelError("center_id")}
              </label>
              <div className="mt-1 sm:col-span-2 sm:mt-0">
                <Select
                  showSearch={true}
                  hiddenLabel={true}
                  defaultValue={dataForm.center_id}
                  placeholder="หน่วยงาน"
                  validate={validation['center_id']}
                  options={masterOptions.center}
                  onChange={(value) => setDataForm({ ...dataForm, center_id: value })}
                />
              </div>
            </div>

            <div className="flex-1 sm:grid sm:grid-cols-3 sm:items-end sm:gap-2">
              <label className="block text-xs font-medium text-gray-700 mb-1 text-right">
                ประเภทการขนส่ง<span className="text-red-500">* </span> :{labelError("logistic_type_id")}
              </label>
              <div className="mt-1 sm:col-span-2 sm:mt-0">
                <Select
                  showSearch={true}
                  hiddenLabel={true}
                  defaultValue={dataForm.logistic_type_id}
                  placeholder="ประเภทการขนส่ง"
                  validate={validation['logistic_type_id']}
                  options={masterOptions.logistic_type}
                  onChange={(value) => setDataForm({ ...dataForm, logistic_type_id: value })}
                />
              </div>
            </div>
          </div>

          <div className="flex lg:flex-row  sm:flex-col sm:space-y-2 pt-2">
            <div className="flex-1 sm:grid sm:grid-cols-3 sm:items-end sm:gap-2">
              <label className="block text-xs font-medium text-gray-700 mb-1 text-right">
                ข้อมูลจุดจัดส่ง<span className="text-red-500">* </span>:
              </label>
              <div className="flex flex-row mt-1 sm:col-span-2 sm:mt-0">
                <button
                  type="button"
                  className="-mb-1 inline-flex items-center px-1.5 py-1.5 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50"
                  onClick={() => setShowSearch(true)}
                >
                  <PlusIcon className="w-4 h-4" />
                </button>
                <p className={`text-sm italic text-danger-600 mt-1 ml-1 ${!validation?.drops?.isError && 'sr-only'}`}>{`(ระบุอย่างน้อย 1 จุดจัดส่ง)`}</p>
              </div>
            </div>
            <div className="flex-1 sm:grid sm:grid-cols-3 sm:items-end sm:gap-2" />
          </div>

          <div className="flex lg:flex-row sm:flex-col sm:space-y-2 pt-2">
            <div className="flex-1 sm:grid sm:grid-cols-6 sm:items-end sm:gap-2">
              <label className="block text-xs font-medium text-gray-700 mb-1 text-right">
              </label>
              <div className="mt-1 sm:col-span-5 sm:mt-0">
                {
                  showSearch ? <SearchQueue
                    auth={auth}
                    action={action}
                    dataMain={dataForm}
                    routeId={currentRouteId}
                    onClosed={() => setShowSearch(false)}
                    onAddCallback={(data, route_id) => {
                      setCurrentRouteId(route_id)
                      setDefaultFormData(data)
                      setShowSearch(false)
                    }}
                    onValidate={(validate) => {
                      if (isEmpty(validate)) {
                        setValidation(validate)
                      }
                      else {
                        setValidation(validate)
                        setShakeIsWrong(true)
                        setTimeout(() => setShakeIsWrong(false), 100);
                      }
                    }}
                  />
                    : <DataGrid
                      ref={(ref) => gridRef.current = ref}
                      dataSource={dataSource}
                      keyExpr="drop_id"
                      height={341}
                      allowColumnReordering={true}
                      columnAutoWidth={true}
                      showBorders={true}
                    >

                      <RowDragging
                        allowReordering={true}
                        onReorder={onReorder}
                      />
                      <Paging enabled={true} />
                      <Column dataField="no" caption="ลำดับ" width={60} alignment="center" allowFiltering={false} />
                      <Column dataField="origin_site_name" caption="ต้นทาง" />
                      <Column dataField="shipto_name" caption="สาขา/ปลายทาง" />
                      <Column dataField="job_type_name" caption="ประเภทงาน" />
                      <Column dataField="zone_name" caption="โซน" alignment="center" />
                      <Column
                        dataField="shipto_name"
                        width={180}
                        caption="เวลาจัดส่ง"
                        cellRender={(e) => {
                          let dt = e.data
                          let dtSource = [...dataSource]
                          let dropIndex = dtSource.findIndex((item) => item.drop_id === dt.drop_id)

                          return <Select
                            hiddenLabel={true}
                            defaultValue={dt.dlv_time_id === 0 ? "" : dt.dlv_time_id}
                            placeholder="เวลาจัดส่ง"
                            size="small"
                            options={masterOptions.dlv_time}
                            onChange={(value) => {
                              dtSource[dropIndex].dlv_time_id = value
                              setDataSource(dtSource)
                            }} />
                        }}
                      />
                      <Column dataField="total_product" caption="จำนวนสินค้า" />
                      <Column dataField="price_value" caption="ค่าขนส่ง(บาท)" />
                      <Column dataField="way" caption="ระยะทาง(กม.)" />
                      <Column dataField="way_time" caption="เวลาเดินทาง(ชม.)" />
                      <Column
                        dataField="remark"
                        width={180}
                        caption="หมายเหตุ"
                        cellRender={(e) => {
                          let dt = e.data
                          let dtSource = [...dataSource]
                          let dropIndex = dtSource.findIndex((item) => item.drop_id === dt.drop_id)

                          return <Input
                            placeholder="หมายเหตุ"
                            defaultValue={dt.remark}
                            size="sm"
                            onChange={(e) => {
                              clearTimeout(inputRemark)
                              inputRemark = setTimeout(() => {
                                dtSource[dropIndex].remark = e.target.value
                                setDataSource(dtSource)
                              }, 1000)
                            }}
                          />
                        }}
                      />
                      <MasterDetail
                        enabled={true}
                        component={DetailQueueTemplate}
                      />
                    </DataGrid>
                }
              </div>
            </div>
          </div>

          <div className="flex-1 pt-2">
            <div className="flex lg:flex-row sm:flex-col sm:space-y-2">
              <div className="flex-1 sm:grid sm:grid-cols-3 sm:items-end sm:gap-2">
                <label className="block text-xs font-medium text-gray-700 mb-1 text-right">
                  ระยะทางรวม :
                </label>
                <div className="mt-1 sm:col-span-2 sm:mt-0">
                  <Input placeholder="ระยะทางรวม" disabled={true} width="w-40" defaultValue={dataForm.total_distance} />
                </div>
              </div>

              <div className="flex-1 sm:grid sm:grid-cols-3 sm:items-end sm:gap-2">
                <label className="block text-xs font-medium text-gray-700 mb-1 text-right">
                  น้ำหนักรวม :
                </label>
                <div className="mt-1 sm:col-span-2 sm:mt-0">
                  <Input placeholder="น้ำหนักรวม" disabled={true} width="w-40" defaultValue={dataForm.total_weight} />
                </div>
              </div>

              <div className="flex-1 sm:grid sm:grid-cols-3 sm:items-end sm:gap-2">
                <label className="block text-xs font-medium text-gray-700 mb-1 text-right">
                  ปริมาตรรวม :
                </label>
                <div className="mt-1 sm:col-span-2 sm:mt-0">
                  <Input placeholder="ปริมาตรรวม" disabled={true} width="w-40" defaultValue={dataForm.total_dimension} />
                </div>
              </div>
            </div>

            <div className="flex lg:flex-row sm:flex-col sm:space-y-2">
              <div className="flex-1 sm:grid sm:grid-cols-3 sm:items-end sm:gap-2">
                <label className="block text-xs font-medium text-gray-700 mb-1 text-right">
                  เวลารวม :
                </label>
                <div className="mt-1 sm:col-span-2 sm:mt-0">
                  <Input placeholder="เวลารวม" disabled={true} width="w-40" defaultValue={dataForm.total_time} />
                </div>
              </div>

              <div className="flex-1 sm:grid sm:grid-cols-3 sm:items-end sm:gap-2">
                <label className="block text-xs font-medium text-gray-700 mb-1 text-right">
                  สถานะ :
                </label>
                <div className="mt-1 sm:col-span-2 sm:mt-0">
                  <Input placeholder="สถานะ" disabled={true} width="w-40" defaultValue={dataForm.status_name} />
                </div>
              </div>

              <div className="flex-1 sm:grid sm:grid-cols-3 sm:items-end sm:gap-2">
                {
                  action === "update" && <>
                    <label className="block text-xs font-medium text-gray-700 mb-1 text-right">
                      ค่าขนส่ง :
                    </label>
                    <div className="mt-1 sm:col-span-2 sm:mt-0">
                      <Input placeholder="ค่าขนส่ง" disabled={true} width="w-40" defaultValue={dataForm.price_value} />
                    </div>
                  </>
                }
              </div>
            </div>

            <div className="flex lg:flex-row sm:flex-col sm:space-y-2 pt-2">
              <div className="flex-1 sm:grid sm:grid-cols-3 sm:items-end sm:gap-2">
                <label className="block text-xs font-medium text-gray-700 mb-1 text-right">
                  หมายเหตุ :
                </label>
                <div className="mt-1 sm:col-span-2 sm:mt-0">
                  <Input
                    placeholder="หมายเหตุ"
                    defaultValue={dataForm.remark}
                    onChange={(e) => setDataForm({ ...dataForm, remark: e.target.value })}
                  />
                </div>
              </div>
              <div className="flex-1 sm:grid sm:grid-cols-3 sm:items-end sm:gap-2"></div>
              <div className="flex-1 sm:grid sm:grid-cols-3 sm:items-end sm:gap-2"></div>
            </div>
          </div>
        </div>
      </FormLoading>
    </Modal>

    <ModalAlert
      {...optionAlert}
      onOk={() => {
        let { action } = optionAlert

        if (["create", "update"].includes(action.name)) {
          setLoading(true)
          saveData()
        }

        setOptionAlert({ ...optionAlert, show: false })
      }}
      onCancel={() => {
        setOptionAlert({ ...optionAlert, show: false })
      }}
    />
  </>
  )
}))

export default ModalPlan