import '../i18n'
import { useTranslation } from 'react-i18next'

export function t(KEY_NAME) {
  const Translation = () => {
    const { t }: any = useTranslation()
    return t(KEY_NAME)
  }
  return Translation()
}

export function rTT() { }