import axios from 'axios'
import { BASE_URL } from '../AppConfig'
import IAuth from '../interfaces/Common/IAuth'

interface IQueue {
  delivery_date: string
  loc_receipt_id?: string
  shipto_id?: string
  qno?: string
  job_type_id?: string
  route_id?: string,
  cust_id?: string
  status?: string
  filter_id?: string
}

interface IRoute {
  delivery_date: string
  loc_receipt_id?: string
  shipto_id?: string
  qno?: string
  doc_no?: string
  job_type_id?: string
  route_id?: string
  cust_id?: string
  status?: string
  service_id?: string
  vid?: string,
  is_geom?: number
  is_auto?: string
}

interface IQueueAdd {
  vid: string
  logistic_type_id: string
  service_id: string
  // loc_receipt_id: string
  // job_type_id: string
  center_id: string
  delivery_date: string
  queues: string
  route_id?: number
}

interface IAssign {
  route_id: number
  vid: number
  sub_id: number
  service_id: number
}

interface IFetchOption {
  delivery_date: string
  route_id?: string
  is_geom?: number
  is_auto?: string
}

export default {
  addQueue: async function (auth: IAuth, data: IQueueAdd) {
    const response = await axios.post(`${BASE_URL}/route/add_q`, data,
      {
        headers: { action_user_id: auth?.action_user_id, token_id: auth?.token_id }
      })
    return response
  },

  addRoute: async function (auth: IAuth, data) {
    const response = await axios.post(`${BASE_URL}/route/save`, {
      vid: data.vid,
      logistic_type_id: data.logistic_type_id,
      center_id: data.center_id,
      service_id: data.service_id,
      // loc_receipt_id: data.loc_receipt_id,
      // job_type_id: data.job_type_id,
      delivery_date: data.delivery_date,
      route_id: data.route_id,
      remark: data.remark,
      drops: data.drops
    },
      {
        headers: { action_user_id: auth?.action_user_id, token_id: auth?.token_id }
      })
    return response
  },

  updateRoute: async function (auth: IAuth, data) {
    const response = await axios.put(`${BASE_URL}/route/update`, {
      vid: data.vid,
      logistic_type_id: data.logistic_type_id,
      center_id: data.center_id,
      service_id: data.service_id,
      // loc_receipt_id: data.loc_receipt_id,
      // job_type_id: data.job_type_id,
      delivery_date: data.delivery_date,
      route_id: data.route_id,
      remark: data.remark,
      drops: data.drops
    },
      {
        headers: { action_user_id: auth?.action_user_id, token_id: auth?.token_id }
      })
    return response
  },

  confirmRoute: async function (auth: IAuth, route_ids) {
    try {
      const response = await axios.put(`${BASE_URL}/route/confirm?route_ids=${route_ids}`,
        {
          headers: { action_user_id: auth?.action_user_id, token_id: auth?.token_id }
        })
      return response.data
    } catch (error) {
      throw error
    }
  },

  deleteRoute: async function (auth: IAuth, route_ids) {
    try {
      const response = await axios.delete(`${BASE_URL}/route/delete?route_ids=${route_ids}`, {
        headers: {
          action_user_id: auth?.action_user_id, token_id: auth?.token_id
        }
      })
      return response.data
    } catch (error) {
      throw error
    }
  },

  removeQueue: async function (auth: IAuth, route_id, q_id) {
    try {
      const response = await axios.put(`${BASE_URL}/route/remove_q?route_id=${route_id}&q_id=${q_id}`,
        {
          headers: { action_user_id: auth?.action_user_id, token_id: auth?.token_id }
        })
      return response.data
    } catch (error) {
      throw error
    }
  },

  deleteQueue: async function (auth: IAuth, qids) {
    try {
      const response = await axios.delete(`${BASE_URL}/queue/delete?qids=${qids}`, {
        headers: {
          action_user_id: auth?.action_user_id, token_id: auth?.token_id
        }
      })
      return response.data
    } catch (error) {
      throw error
    }
  },


  getQueue: async function (auth: IAuth, data: IQueue) {
    try {
      let prmList: any = []
      for (const key in data) {
        if (data[key] !== "") prmList.push(`${key}=${data[key]}`)
      }
      let param = prmList.join('&');

      const response = await axios.get(`${BASE_URL}/route/get_q?${param}`, {
        headers: { action_user_id: auth?.action_user_id, token_id: auth?.token_id }
      })
      return response.data
    } catch (error) {
      throw error
    }
  },

  getRoute: async function (auth: IAuth, data: IRoute) {
    try {
      let prmList: any = []
      for (const key in data) {
        if (data[key] !== "") prmList.push(`${key}=${data[key]}`)
      }
      let param = prmList.join('&');

      const response = await axios.get(`${BASE_URL}/route/list?${param}`, {
        headers: { action_user_id: auth?.action_user_id, token_id: auth?.token_id }
      })
      return response.data
    } catch (error) {
      throw error
    }
  },

  getMultiRoute: async function (auth: IAuth, fetchOption: IFetchOption[]) {
    try {
      let fetchList: any = []
      fetchOption.forEach(option => {
        let prmList: any = []
        for (const key in option) {
          if (option[key] !== "") prmList.push(`${key}=${option[key]}`)
        }
        let param = prmList.join('&');

        fetchList.push(axios.get(`${BASE_URL}/route/list?${param}`,
          {
            headers: { action_user_id: auth?.action_user_id, token_id: auth?.token_id }
          }
        ))
      });
      const response = await axios.all(fetchList)

      return response
    } catch (error) {
      throw error
    }
  },

  getRouteHistory: async function (auth: IAuth, route_id) {
    try {
      const response = await axios.get(`${BASE_URL}/track/history?route_id=${route_id}`, {
        headers: { action_user_id: auth?.action_user_id, token_id: auth?.token_id }
      })
      return response.data
    } catch (error) {
      throw error
    }
  },

  getDash: async function (auth: IAuth, delivery_date) {
    try {
      const response = await axios.get(`${BASE_URL}/route/dash?delivery_date=${delivery_date}`, {
        headers: { action_user_id: auth?.action_user_id, token_id: auth?.token_id }
      })
      return response.data
    } catch (error) {
      throw error
    }
  },

  routeAssign: async function (auth: IAuth, data: IAssign) {
    const response = await axios.put(`${BASE_URL}/route/assign?route_id=${data.route_id}&vid=${data.vid}&sub_id=${data.sub_id}&service_id=${data.service_id}`,
      {},
      {
        headers: { action_user_id: auth?.action_user_id, token_id: auth?.token_id }
      }
    )
    return response
  }
}