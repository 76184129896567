declare type AlertType = 'success' | 'danger' | 'warning' | 'info' | 'confirm';

type Props = {
  defaultOption: any
  show: boolean
  type?: AlertType
  title?: string
  action?: {
    id?: string
    name?: string
  }
  description?: {
    visible?: boolean
    type?: string
    content?: string,
    contentLists?: any[]
  }
}

export function setOptions(props: Props) {
  let {
    defaultOption,
    show = true,
    type = "success",
    title = "",
    action = {
      id: "",
      name: ""
    },
    description = {
      visible: false,
      type: "text",
      content: ""
    }
  }: any = props

  let options = {
    ...defaultOption,
    type,
    show,
    action: {
      id: action?.id,
      name: action?.name
    },
    title,
    description: {
      visible: description?.visible,
      type: description?.type,
      content: `${description?.content}`
    }
  }

  return options
}