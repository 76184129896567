import axios from 'axios'
import { BASE_URL } from '../AppConfig'
import IAuth from '../interfaces/Common/IAuth'

export default {
  login: async function (username, password) {
    const response = await axios.post(`${BASE_URL}/auth/login`, {
      username,
      password
    })

    return response
  },

  refreshTokekn: async function (auth: IAuth, refresh_token) {
    const response = await axios.post(`${BASE_URL}/auth/refresh`,
      { refresh_token },
      {
        headers: { action_user_id: auth?.action_user_id, token_id: auth?.token_id }
      })

    return response
  }
}