import FormMaster from '../FormMaster'
const ProductType = () => {
  return <FormMaster
    title="ข้อมูลประเภทสินค้า"
    tableConfig={{
      key: "id",
      apiPath: "product_type/list",
      allowDelete: {
        visible: true,
        key: "product_type_id",
        apiPath: "product_type/delete",
        paramType: 'param'
      },
      optionDeleteFag: true,
      defaultColumn: [],
      columns: [
        {
          dataField: 'no',
          caption: "ลำดับ",
          width: 60,
          alignment: "center",
          allowFiltering: false
        },
        {
          dataField: 'product_type_name',
          caption: "ชื่อประเภทสินค้า",
          allowFiltering: true
        },
        {
          dataField: 'description',
          caption: "คำอธิบาย"
        },
        {
          dataField: 'updated_by',
          caption: "แก้ไขโดย"
        }
      ],
      exportExcel: {
        fileName: 'ข้อมูลประเภทสินค้า',
        columnConfig: [
          { label: 'ลำดับ', value: 'no' },
          { label: 'ชื่อประเภทสินค้า', value: 'product_type_name' },
          { label: 'คำอธิบาย', value: 'description' },
          { label: 'แก้ไขโดย', value: 'updated_by' }
        ]
      }
    }}
    formData={{
      product_type_name: "",
      description: ""
    }}
    modalConfig={{
      visible: true,
      title: "ประเภทสินค้า",
      required: ["product_type_name"],
      fieldTypeNumber: [],
      paramName: "product_type_id",
      apiPath: {
        "get": '',
        "post": 'product_type/add',
        "put": 'product_type/update'
      },
      elements: [
        {
          fieldName: "product_type_name",
          label: "ชื่อประเภทสินค้า",
          placeholder: "ชื่อประเภทสินค้า",
          required: true,
          inputType: "text"
        },
        {
          fieldName: "description",
          label: "คำอธิบาย",
          placeholder: "คำอธิบาย",
          rows: 2,
          inputType: "text-area",
          isOneRow: true
        }
      ]
    }}
  />
}

export default ProductType