import { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { inject, observer } from 'mobx-react';
import { classNames } from '../helpers/classNames'
import { Disclosure } from '@headlessui/react'
import { menuConfig } from './MenuConfig'
import { Fragment } from 'react'
import { Dialog, Transition, } from '@headlessui/react'
import {
  XIcon,
  ChevronDownIcon,
  ChevronLeftIcon
} from '@heroicons/react/outline'
import { v4 as uuidv4 } from 'uuid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faScrewdriverWrench } from '@fortawesome/free-solid-svg-icons'
import { isEmpty } from 'lodash';

const Sidebar = inject("authenStore", "commonStore")(observer((props) => {
  const navigate = useNavigate()
  let commonStore = props.commonStore
  let authenStore = props.authenStore
  let { getAccountAuth, setCommonValue } = authenStore // STATE
  let { setCommonStore, getCurrentMenu, getBreadcrumbs, getExpandSidebar } = commonStore // ACTIONS

  let { open } = props // STATE
  let { onClose } = props // ACTION
  let expand = getExpandSidebar

  const [expandOverlay, setExpandOverlay] = useState(false)
  const [menuList, setMenuList]: any = useState([])

  useEffect(() => {
    try {
      if (!isEmpty(getAccountAuth)) {
        let { menu } = getAccountAuth

        let mConfig = [...menuConfig]
        menu.forEach(item => {
          let { is_read, main_code, permission_code } = item
          let mainIndex = mConfig.findIndex((item) => item.id == main_code)
          if (is_read) {
            if (mainIndex >= 0) {
              let mainMenu = mConfig[mainIndex]
              if (mainMenu?.subMenu) {
                let subIndex = mainMenu.subMenu.findIndex((item) => item.id == permission_code)
                if (subIndex >= 0) {
                  mConfig[mainIndex].visible = true
                  mConfig[mainIndex].subMenu[subIndex].visible = true
                }
                else {
                  mConfig[mainIndex].subMenu[subIndex].visible = false
                }
              }
              else {
                mConfig[mainIndex].visible = true
              }
            }
          }
          else {
            mConfig[mainIndex].visible = false
          }
        })
        setMenuList(mConfig)
      }
    } catch (error) {
      navigate("/")
      setCommonValue({ accountAuth: {} })
    }

  }, [getAccountAuth])


  const onHandleMouseOver = (event) => {
    if (!expand) setExpandOverlay(event === "mouseOver" ? true : false)
  }

  const onHandleMenuChange = (menu, isSubMenu = false, mainMenu) => {
    if (isSubMenu) {
      let breadcrums = { ...getBreadcrumbs }
      setCommonStore({
        currentMenu: menu.id,
        breadcrumbs: {
          ...breadcrums,
          path: menu.path,
          mainMenu: mainMenu.name,
          subMenu: [menu.name]
        }
      })
    }
    else {
      let breadcrums = { ...getBreadcrumbs }
      setCommonStore({
        currentMenu: menu.id,
        breadcrumbs: {
          ...breadcrums,
          path: menu.path,
          mainMenu: menu.name,
          subMenu: []
        }
      })
    }
    navigate(menu.path)
  }

  return (
    <div
      onMouseOver={() => onHandleMouseOver("mouseOver")}
      onMouseLeave={() => onHandleMouseOver("mouseOut")}
    >
      {/* sidebar for mobile */}
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-40 md:hidden" onClose={onClose}>
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
          </Transition.Child>

          <div className="fixed inset-0 z-40 flex">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel className="relative flex w-full max-w-xs flex-1 flex-col bg-blue-900 pb-4">
                <div className="flex flex-shrink-0 items-center px-4 space-x-4 shadow h-12 bg-white">
                  <img
                    className="h-8 w-auto"
                    // src="https://tailwindui.com/img/logos/mark.svg?color=blue&shade=300"
                    src="/icons/favicon.ico"
                  />
                  <span className="font-bold text-primary-500">Qlog Cold Chain</span>
                  <div className="absolute top-0 right-0 -mr-12 pt-2">
                    <button
                      type="button"
                      className="ml-1 flex h-10 w-10 items-center justify-center rounded-full "
                      onClick={() => onClose()}
                    >
                      <XIcon className="h-6 w-6 text-primary-500" aria-hidden="true" />
                    </button>
                  </div>
                </div>
                <div className="mt-5 h-0 flex-1 overflow-y-auto">

                  <nav className="space-y-1 px-2">
                    {menuList.map((menu) => {
                      if (menu.subMenu) {
                        if (menu.visible) {
                          return <Disclosure key={menu.id} as="div">
                            {({ open }) => (
                              <>
                                <Disclosure.Button className="w-full">
                                  <a
                                    key={menu.name}
                                    className={classNames(
                                      getCurrentMenu === menu.id ? 'bg-blue-100 text-blue-600' : 'text-white hover:bg-blue-800',
                                      'cursor-pointer group flex items-center px-2 py-2 text-xs font-medium rounded-md'
                                    )}
                                  >
                                    <div className='flex flex-row w-full'>
                                      <div className="flex flex-1 flex-row">
                                        <menu.icon className={`mr-3 h-5 w-5 ${getCurrentMenu === menu.id ? 'text-blue-600' : 'text-white'}`} />
                                        <span className="text-xs mt-0.5">{menu.name}</span>
                                      </div>
                                      <div className="flex-2 text-right">
                                        {open ? <ChevronDownIcon className="w-4 h-4" /> : <ChevronLeftIcon className="w-4 h-4" />}
                                      </div>
                                    </div>
                                  </a>
                                </Disclosure.Button>
                                <Disclosure.Panel as="ul">
                                  {
                                    menu.subMenu.map((sub) => {
                                      if (sub.visible) {
                                        return <a
                                          // key={sub.name}
                                          key={uuidv4()}
                                          onClick={() => onHandleMenuChange(sub, true, menu)}
                                          className={classNames(
                                            getCurrentMenu === sub.id ? 'bg-blue-100 text-blue-600' : 'text-white hover:bg-blue-800',
                                            'cursor-pointer group flex items-center px-2 py-2 text-xs font-medium rounded-md'
                                          )}
                                        >
                                          <span className='ml-9 text-xs'>{sub.name}</span>
                                        </a>
                                      }
                                    })
                                  }
                                </Disclosure.Panel>
                              </>
                            )}

                          </Disclosure>
                        }
                      }
                      else {
                        if (menu.visible) {
                          return <a
                            key={menu.name}
                            onClick={() => onHandleMenuChange(menu, false, null)}
                            className={classNames(
                              getCurrentMenu === menu.id ? 'bg-blue-100 text-blue-600' : 'text-white hover:bg-blue-800',
                              'cursor-pointer group flex items-center px-2 py-2 text-xs font-medium rounded-md'
                            )}
                          >
                            <menu.icon className={`mr-3 h-5 w-5 flex-shrink-0 ${getCurrentMenu === menu.id ? 'text-blue-600' : 'text-white'}`} />
                            {menu.name}
                          </a>
                        }
                      }
                    })}

                  </nav>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      {/* sidebar for desktop */}
      <div className={`hidden md:fixed z-50 md:inset-y-0 md:flex ${expand ? 'md:w-60' : expandOverlay ? 'md:w-60' : 'md:w-14'} md:flex-col`}>
        <div className="flex flex-grow flex-col overflow-y-auto bg-blue-900 ">
          <div className={`flex flex-shrink-0 items-center px-2 space-x-4 cursor-pointer shadow h-10 bg-white border-2 border-r-primary-900`}>
            <img
              className="h-8 w-auto"
              // src="https://tailwindui.com/img/logos/mark.svg?color=blue&shade=300"
              src="/icons/favicon.ico"
            />
            <span className={`font-bold text-primary-500 ${(!expand && !expandOverlay) && 'sr-only'}`}>Qlog Cold Chain</span>
          </div>
          <div className="mt-5 flex flex-1 flex-col">
            <nav className={`flex-1 space-y-1 px-2 pb-4`} >
              {menuList.map((menu, index) => {
                if (expand || expandOverlay) {
                  if (menu.subMenu) {
                    if (menu.visible) {
                      return <Disclosure key={menu.id} as="div">
                        {({ open }) => (
                          <>
                            <Disclosure.Button className="w-full">
                              <a
                                key={menu.name}
                                className={classNames(
                                  getCurrentMenu === menu.id ? 'bg-blue-100 text-blue-600' : 'text-white hover:bg-blue-800',
                                  'cursor-pointer group flex items-center px-2 py-2 text-xs font-medium rounded-md'
                                )}
                              >
                                <div className='flex flex-row w-full'>
                                  <div className="flex flex-1 flex-row">
                                    <menu.icon className={`ml-0.5 h-5 w-5 ${getCurrentMenu === menu.id ? 'text-blue-600' : 'text-white'}`} />
                                    <span className="text-xs mt-0.5 ml-2">{menu.name}</span>
                                  </div>
                                  <div className="flex-2 text-right">
                                    {open ? <ChevronDownIcon className="w-4 h-4" /> : <ChevronLeftIcon className="w-4 h-4" />}
                                  </div>
                                </div>
                              </a>
                            </Disclosure.Button>
                            <Disclosure.Panel as="ul">
                              {
                                menu.subMenu.map((sub) => {
                                  if (sub.visible) {
                                    return <a
                                      // key={`${menu.name}`}
                                      key={uuidv4()}
                                      onClick={() => onHandleMenuChange(sub, true, menu)}
                                      className={`
                                  ${getCurrentMenu === sub.id ? 'bg-blue-100 text-blue-600' : 'text-white hover:bg-blue-800'}
                                    cursor-pointer group flex items-center px-2 py-2 text-xs font-medium rounded-md
                                  `}

                                    // ${getCurrentMenu === menu.id && 'sr-only'}
                                    >
                                      <span className='ml-9 text-xs'>{sub.name}</span>
                                    </a>
                                  }
                                })
                              }
                            </Disclosure.Panel>
                          </>
                        )}
                      </Disclosure>
                    }
                  }
                  else {
                    if (menu.visible) {
                      return <a
                        key={`${menu.name}_${index}`}
                        onClick={() => onHandleMenuChange(menu, false, null)}
                        className={classNames(
                          getCurrentMenu === menu.id ? 'bg-blue-100 text-blue-600' : 'text-white hover:bg-blue-800',
                          'cursor-pointer group flex items-center px-2 py-2 text-xs font-medium rounded-md'
                        )}
                      >
                        {
                          menu.id === 600 ? <FontAwesomeIcon icon={faScrewdriverWrench} style={{ margin: '0px 2px 0px 3px', fontSize: 16 }} />
                            : <menu.icon className={`ml-0.5 h-5 w-5 flex-shrink-0 ${getCurrentMenu === menu.id ? 'text-blue-600' : 'text-white'}`} />
                        }
                        <span className='ml-2'>{menu.name}</span>
                      </a>
                    }

                  }
                }
                else {
                  if (menu.visible) {
                    return <div
                      key={menu.id}
                      className={`cursor-pointer group flex items-center px-2 py-2 text-xs font-medium rounded-md ${getCurrentMenu === menu.id ? 'bg-blue-100 text-blue-600' : 'text-white hover:bg-blue-800'}`}
                      onClick={() => onHandleMenuChange(menu, false, null)}
                    >
                      {
                        menu.id === 600 ? <FontAwesomeIcon icon={faScrewdriverWrench} style={{ margin: '0px 14px 0px 3px', fontSize: 16 }} />
                          : <menu.icon className={`ml-0.5 h-5 w-5 flex-shrink-0 ${getCurrentMenu === menu.id ? 'text-blue-600' : 'text-white'}`} />
                      }
                    </div>
                  }
                }
              })}
            </nav>
          </div>
        </div>
      </div>
    </div>
  )
}))

export default Sidebar;