
import ReactExport from "react-data-export";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

type Props = {
    filename: string,
    data?: any,
    columnConfig?: any
    element?: any,
}

export default (props: Props) => {
    let {
        filename,
        data = [],
        columnConfig = [],
        element
    }: any = props

    let btnExport = element || <button
        type="button"
        className="-mb-1 inline-flex items-center px-1.5 py-1.5 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50"
        title="Export"
    >
        <img className="h-4 w-4" src="/Images/excel.png" />
    </button>

    return (
        <ExcelFile element={btnExport} filename={filename}>
            <ExcelSheet data={data} name={filename}>
                {
                    columnConfig.map((column) => (<ExcelColumn label={column?.label} value={column?.value} />))
                }
            </ExcelSheet>
        </ExcelFile>
    )
}