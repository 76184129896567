import axios from 'axios'
import { BASE_URL } from '../AppConfig'
import IAuth from '../interfaces/Common/IAuth'

export default {
  login: async function (username, password) {
    const response = await axios.post('https://reqres.in/api/login', {
      email: username,
      password
    })

    return response
  },

  getMasterData: async function (auth: IAuth, master_names, id_ref1: any = "", id_ref2: any = "") {
    try {
      let refParm = ""
      if (id_ref1 !== "") refParm += `&id_ref1=${id_ref1}`
      if (id_ref2 !== "") refParm += `&id_ref2=${id_ref2}`

      const response = await axios.get(`${BASE_URL}/master/list?names=${master_names}${refParm}`, {
        headers: { action_user_id: auth?.action_user_id, token_id: auth?.token_id }
      })
      return response.data
    } catch (error) {
      throw error
    }
  },
}