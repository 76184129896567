import { useState, useRef, useEffect } from 'react'
import { inject, observer } from 'mobx-react';
import { createMultipleRef } from '../../helpers'
import { Card, Button, ModalAlert, Select, FormLoading } from '../../components'
import { CommonService, DeliveryCostService } from "../../services";
import DataGrid, { SearchPanel, Scrolling, Paging, Pager, Column } from 'devextreme-react/data-grid';
import { SearchIcon } from '@heroicons/react/outline';
import { DatePicker } from 'antd';
import dayjs from 'dayjs';
import moment from 'moment'
import { BASE_URL } from '../../AppConfig'
import { DownloadIcon, UploadIcon } from '@heroicons/react/solid';
import { isEmpty } from 'lodash';

const { RangePicker } = DatePicker;
const dateFormat = 'YYYY-MM-DD';
let firstRender = false
const FleetCard = inject("authenStore")(observer((props) => {
  let authenStore = props.authenStore
  let { getAccountAuth } = authenStore // STATE
  let auth = { action_user_id: getAccountAuth?.user_id, token_id: getAccountAuth?.access_token }

  const inputUploadRef: any = useRef(null);
  const [fileUploadName, setFileUploadName] = useState("")
  const [masterOptions, setMasterOptions] = useState({ vehicle: [] })
  const [loading, setLoading]: any = useState({ visible: false, tip: 'กำลังบันทึก...' })
  const [dataSource, setDataSource]: any = useState([])
  const [filterCurrent, setFilterCurrent]: any = useState({})
  const [filter, setFilter]: any = useState({
    sdate: moment().format("YYYY-MM-DD"),
    edate: moment().format("YYYY-MM-DD"),
    vid: "",
    status: ""
  })
  const [optionAlert, setOptionAlert]: any = useState({
    show: false,
    action: {
      id: "",
      name: ""
    },
    type: "success",
    title: "",
    description: {
      visible: false,
      type: "",
      content: "",
      contentLists: [],

    }
  })

  const [dataForm, setDataForm]: any = useState({
    "dlv_doc_no": "",
    "route_id": "",
    "ts_gate_in": "",
    "ts_gate_out": "",
    "mileage_start": "",
    "mileage_end": "",
    "service_info": "",
    "tasks": [],
    "cost_items": []
  })

  const gridRef: any = useRef(null);
  const inputRef: any = useRef([]);
  createMultipleRef(inputRef, 2)

  let columns = [
    {
      dataField: 'trans_date',
      caption: "DATE",
      allowFiltering: false,
      fixed: true
    },
    {
      dataField: 'trans_id',
      caption: "TRANSACTION_ID",
      allowFiltering: true,
      allowSearch: true,
      fixed: true
    },
    {
      dataField: 'driver_name',
      caption: "DRIVER_NAME"
    },
    {
      dataField: 'licenseplate',
      caption: "LICENSE_PLATE_NO"
    },
    {
      dataField: 'mileage',
      caption: "MILEAGE"
    },
    {
      dataField: 'ref_cost',
      caption: "REF_COST_CENTER"
    },
    {
      dataField: 'pont_val',
      caption: "POINT"
    },
    {
      dataField: 'point_extra',
      caption: "POINT_EXTRA"
    },
    {
      dataField: 'card_no',
      caption: "CARD_NO"
    },
    {
      dataField: 'cust_name',
      caption: "CUSTOMER_NAME"
    },
    {
      dataField: 'branch_name',
      caption: "BRANCH_NAME"
    },
    {
      dataField: 'business_type',
      caption: "BUSINESS_TYPE"
    },
    {
      dataField: 'product_type',
      caption: "PRODUCT_TYPE"
    },
    {
      dataField: 'unit_price',
      caption: "UNIT_PRICE"
    },
    {
      dataField: 'litre',
      caption: "LITRE"
    },
    {
      dataField: 'amount',
      caption: "AMOUNT"
    },
    {
      dataField: 'status',
      caption: "STATUS"
    },
    {
      dataField: 'tax_inv',
      caption: "เลขที่ใบกำกับ"
    },
    {
      dataField: 'remark_cust',
      caption: "REMARK (CUSTOMER)"
    },
    {
      dataField: 'remark_void',
      caption: "REMARK (VOIDID)"
    },
    {
      dataField: 'trxdate',
      caption: "วันที่ทำรายการ"
    },
    {
      dataField: 'doc_no',
      caption: "เลขที่เอกสาร"
    }
  ]

  useEffect(() => {
    if (firstRender || !isEmpty(getAccountAuth)) {
      CommonService.getMasterData(auth, "vehicle")
        .then((res: any) => {
          let vehicle = res.result.vehicle.map((item) => ({ label: item.licenseplate, value: item.vehicle_id }))
          setMasterOptions({ ...masterOptions, vehicle })
        })
        .catch((err) => {
          setOptionAlert({
            ...optionAlert,
            type: "danger",
            show: true,
            action: {
              id: "",
              name: ""
            },
            title: "ผิดพลาด",
            description: { visible: true, content: `Load master data : ${err}` }
          })
        })
    }
    else
      firstRender = true
  }, [getAccountAuth])

  //#region 

  const searchFlclist = (isReloadCurrent = false, reloadByRouteID = "") => {
    setLoading({ ...loading, visible: true, tip: 'กำลังโหลด...' })
    setFilterCurrent(filter)
    let data = isReloadCurrent ? filterCurrent : filter
    DeliveryCostService.getFlclist(auth, data)
      .then((res: any) => {
        let { code, message, result, sys_message } = res
        if (code === 200) {
          setDataSource(result)
          if (reloadByRouteID !== "") {
            let data = result.find((item) => item.route_id === reloadByRouteID)
            if (data) setDataForm(data)
          }
        }
        else {
          setOptionAlert({
            ...optionAlert,
            type: "danger",
            show: true,
            action: {
              id: "",
              name: ""
            },
            title: "ผิดพลาด",
            description: { visible: true, type: "text", content: `${message} ${sys_message}` }
          })
        }
        setLoading({ ...loading, visible: false })
      })
      .catch((err) => {
        setOptionAlert({
          ...optionAlert,
          type: "danger",
          show: true,
          action: {
            id: "",
            name: ""
          },
          title: "ผิดพลาด",
          description: { visible: true, type: "text", content: `${err}` }
        })
        setLoading({ ...loading, visible: false })
      })
  }

  const handleFileChange = (e) => {
    if (!e.target.files) return;

    setLoading({ ...loading, visible: true, tip: 'กำลังอัปโหลด...' })
    let isError = false
    const fileLists = Array.from(e.target?.files)
    fileLists.map((file: any) => {
      let nameSplite = file.name.split('.')
      let fileType = nameSplite[(nameSplite.length - 1)]

      setFileUploadName(file.name)
      if (fileType !== "xlsx") {
      }
    })

    if (!isError) {
      const data = new FormData();
      fileLists.forEach((file: any, i) => {
        data.append('files', file, file.name);
      });
      fetch(`${BASE_URL}/dlv_cost/upload_fleet_card`, {
        method: 'POST',
        body: data,
      })
        .then((res) => res.json())
        .then((data) => {
          let { code, message, sys_message } = data
          if (code === 200) {
            setOptionAlert({
              ...optionAlert,
              type: "success",
              show: true,
              action: {
                id: "",
                name: ""
              },
              title: "อัปโหลดสำเร็จ",
              description: { visible: false, type: "text", content: " ", contentLists: [] }
            })
          }
          else {
            setOptionAlert({
              ...optionAlert,
              type: "danger",
              show: true,
              action: {
                id: "",
                name: ""
              },
              title: "อัปโหลดผิดพลาด",
              description: { visible: true, type: "text", content: `${message} ${sys_message}` }
            })
          }
          setLoading({ ...loading, visible: false })
        })
        .catch((err) => {
          setOptionAlert({
            ...optionAlert,
            type: "danger",
            show: true,
            action: {
              id: "",
              name: ""
            },
            title: "อัปโหลดผิดพลาด",
            description: { visible: true, type: "text", content: `${err}` }
          })
          setLoading({ ...loading, visible: false })
        })
    }
    else {
      setLoading({ ...loading, visible: false })
    }
  }

  //#endregion
  return (
    <div className="space-y-3 p-4">
      <Card title="ข้อมูลการเติมน้ำมัน (Fleet Card)">
        <FormLoading loading={loading.visible} >
          <div className="flex flex-row mb-2">
            <div className="flex flex-row space-x-2">
              <div className="flex flex-row">
                <label className="text-sm font-medium text-gray-700 mt-3 mr-2 text-start">
                  วันที่ :
                </label>
                <div className="mt-1 sm:col-span-2 sm:mt-0">
                  <RangePicker
                    allowClear={false}
                    defaultValue={[dayjs(filter.sdate, dateFormat), dayjs(filter.edate, dateFormat)]}
                    style={{ marginTop: 4 }}
                    onChange={(d: any) => {
                      if (d) {
                        setFilter({
                          ...filter,
                          sdate: d[0].format("YYYY-MM-DD"),
                          edate: d[1].format("YYYY-MM-DD")
                        })
                      }
                      else {
                        setFilter({
                          ...filter,
                          sdate: "",
                          edate: ""
                        })
                      }
                    }}
                  />
                </div>
              </div>

              <div className="flex flex-row">
                <label className="text-sm font-medium text-gray-700 mt-3 mr-2 text-start">
                  ทะเบียน :
                </label>
                <div className="mt-1 sm:col-span-2 sm:mt-0">
                  <Select
                    allowClear={true}
                    showSearch={true}
                    defaultValue={filter.vid}
                    placeholder="ทะเบียน"
                    width={150}
                    options={masterOptions.vehicle}
                    onChange={(value) => setFilter({ ...filter, vid: value })}
                  />
                </div>
              </div>

              <div className="flex flex-row">
                <label className="text-sm font-medium text-gray-700 mt-3 text-start"> </label>
                <div className="mt-1 sm:col-span-2 sm:mt-0 pt-1">
                  <Button
                    text="ค้นหา"
                    icon={<SearchIcon />}
                    size="md"
                    type="white"
                    title="ค้นหา"
                    onClick={() => {
                      if (!loading.visible) {
                        searchFlclist()
                      }
                    }}
                  />
                </div>
              </div>

              <div className="flex flex-row">
                <label className="text-sm font-medium text-gray-700 mt-3 text-start"> </label>
                <div className="mt-1 sm:col-span-2 sm:mt-0 pt-1">
                  <input
                    className='hidden'
                    type="file"
                    ref={inputUploadRef}
                    onChange={handleFileChange}
                  />
                  <Button
                    text="อัปโหลด"
                    className="-ml-1.5"
                    icon={<UploadIcon />}
                    size="md"
                    type="primary"
                    width="w-full"
                    iconPosition='start'
                    onClick={() => {
                      inputUploadRef.current.click()
                    }}
                  />
                </div>
              </div>

              <div className="flex flex-row">
                <label className="text-sm font-medium text-gray-700 mt-3 text-start"> </label>
                <div className="mt-1 sm:col-span-2 sm:mt-0 pt-1">
                  <input
                    className='hidden'
                    type="file"
                    ref={inputUploadRef}
                    onChange={handleFileChange}
                  />
                  <Button
                    text="Excel"
                    icon={<DownloadIcon />}
                    size="md"
                    type="success"
                    width="w-full"
                    iconPosition='start'
                    onClick={() => window.open(`https://prod-coldchain-public.s3.ap-southeast-1.amazonaws.com/fleetcard/fleet_card_template.xlsx`)}
                  />
                </div>
              </div>
            </div>
          </div>

          <DataGrid
            ref={(ref) => gridRef.current = ref}
            dataSource={dataSource}
            keyExpr={'no'}
            allowColumnReordering={true}
            hoverStateEnabled={true}
            columnAutoWidth={true}
            showBorders={true}
          >
            <Scrolling columnRenderingMode="virtual" />
            <SearchPanel visible={false} />
            <Paging defaultPageSize={10} />
            <Pager
              showInfo={true}
              showPageSizeSelector={true}
              allowedPageSizes={[10, 20, 50]}
            />
            {
              columns.map((column) => <Column
                key={column.dataField}
                fixed={column.fixed}
                allowSearch={column.allowSearch}
                allowFiltering={column?.allowFiltering || false}
                dataField={column.dataField}
                caption={column.caption}
              >
              </Column>)
            }
          </DataGrid>
        </FormLoading>
      </Card >

      <ModalAlert
        {...optionAlert}
        onOk={() => {
          setOptionAlert({ ...optionAlert, show: false })
        }}
        onCancel={() => {
          setOptionAlert({ ...optionAlert, show: false })
        }}
      />
    </div>
  )
}))

export default FleetCard