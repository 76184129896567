import { action, configure, observable, toJS, computed } from 'mobx';
import { persist } from 'mobx-persist'

configure({ enforceActions: 'always' });

export default class CommonStore {
  @persist('object') @observable isRefreshData: boolean = false
  @persist('object') @observable defaultDeliveryDate: string = ""
  @persist('object') @observable dataPlanEdit: any = {}

  @computed
  get getIsRefreshData() {
    return toJS(this.isRefreshData)
  }

  @computed
  get getDefaultDeliveryDate() {
    return toJS(this.defaultDeliveryDate)
  }
  @computed
  get getDataPlanEdit() {
    return toJS(this.dataPlanEdit)
  }

  @action.bound
  setRouteStore(objValue: any) {
    for (const [key, value] of Object.entries(objValue)) {
      this[key] = value
    }
  }

  @action
  reset() {
    this.isRefreshData = false
    this.defaultDeliveryDate = ""
  }
}