import { Fragment, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XIcon } from '@heroicons/react/outline'
import { Button, FormLoading } from '../../components'
import './style.css'

declare const ButtonTypes: ["primary", "success", "danger", "warning", "info", "white", "circle"];
export declare type ButtonType = typeof ButtonTypes[number];
export declare type SizeType = 'sm' | 'md' | 'lg';
export declare type IconPosition = 'start' | 'end';
export declare type roundedType = 'rounded' | 'full';
export declare type shapeType = 'circle';

type Props = {
    id?: string
    children: React.ReactNode
    customHeader?: React.ReactNode
    extra?: React.ReactNode;
    footer?: React.ReactNode;
    hiddenFooter?: boolean;
    title?: object | string | {
        text: string;
        description?: string;
    };
    buttonOk?: object | string | {
        text: string;
    };
    secondary?: boolean;
    shape?: shapeType;
    maskClosable?: boolean;
    rounded?: roundedType
    size?: SizeType
    width?: string
    contentPadding?: string
    icon?: React.ReactNode;
    iconPosition?: IconPosition;
    style?: React.CSSProperties;
    open: boolean;
    loading?: boolean;
    shake?: boolean;
    okDisabled?: boolean;
    cancelDisabled?: boolean;
    appendButton?: React.ReactNode;
    onClick?: React.MouseEventHandler<HTMLElement>;
    onClosed?: React.MouseEventHandler<HTMLElement>;
    onOk?: React.MouseEventHandler<HTMLElement>;
    // onCancel?: (e: React.MouseEvent<HTMLElement>) => void;
}

export default (props: Props) => {
    let {
        title,
        buttonOk = {
            text: "บันทึก"
        },
        children,
        customHeader,
        footer,
        hiddenFooter = false,
        maskClosable = false,
        open,
        width = 'sm:w-full sm:max-w-lg',
        contentPadding = 'px-4 pt-5 mb-4 sm:p-6 sm:pb-4',
        icon,
        loading = false,
        shake = false,
        okDisabled = false,
        cancelDisabled = false,
        appendButton,
        extra,
        onClosed,
        onOk
    }: any = props

    const cancelButtonRef = useRef(null)
    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={onClosed}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-50"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-0 overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-200"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <div className={`${shake && 'box-shake'} relative transform overflow-hidden rounded-lg bg-white text-left dark:bg-gray-800 shadow-xl transition-all sm:my-8 ${width}`}>
                                <div className={`absolute top-0 right-0 hidden pt-2 pr-2 sm:block ${maskClosable && 'sr-only'}`}>
                                    <button
                                        type="button"
                                        className="rounded-md bg-whitetext-gray-400 hover:text-gray-500"
                                        onClick={() => onClosed(false)}
                                    >
                                        <span className="sr-only">Close</span>
                                        <XIcon className="h-5 w-5" aria-hidden="true" />
                                    </button>
                                </div>

                                {customHeader ? <div className="px-4 py-2 sm:px-6">
                                    {customHeader}
                                </div>
                                    : (title?.text || title) && <div className="px-4 py-2 sm:px-6 border-b border-solid border-gray-200 dark:border-gray-600">
                                        <div className="-ml-4 -mt-4 flex flex-wrap items-center justify-between sm:flex-nowrap">
                                            <div className="ml-4 mt-4">
                                                <h3 className="flex flex-row text-md font-medium leading-6 text-gray-900 dark:text-white">{icon} {title?.text || title}</h3>
                                                {
                                                    title?.description && <p className="mt-1 text-sm text-gray-500 dark:text-gray-500">
                                                        {title.description}
                                                    </p>
                                                }
                                            </div>
                                            {extra && <div className="ml-4 mt-4 mr-2 flex flex-shrink-0">{extra}</div>}
                                        </div>
                                    </div>
                                }

                                <FormLoading loading={loading} tip="">
                                    <div className={`bg-white dark:bg-gray-800 ${contentPadding}`}>
                                        {children}
                                    </div>

                                    <div className={`bg-gray-50 dark:bg-gray-800 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6 ${hiddenFooter && 'sr-only'}`}>
                                        {
                                            footer ? footer
                                                : <>
                                                    <Button
                                                        text={buttonOk.text}
                                                        type="primary"
                                                        width="w-20"
                                                        disabled={okDisabled}
                                                        onClick={onOk}
                                                    />
                                                    <Button
                                                        text="ยกเลิก"
                                                        type="white"
                                                        width="w-20"
                                                        disabled={cancelDisabled}
                                                        onClick={() => onClosed(false)}
                                                    />
                                                    {appendButton}

                                                </>
                                        }
                                    </div>
                                </FormLoading>
                            </div>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}