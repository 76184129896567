import axios from 'axios'
import { BASE_URL } from '../AppConfig'
import IAuth from '../interfaces/Common/IAuth'

interface ITracking {
  delivery_date: string
  loc_receipt_id?: string
  shipto_id?: string
  qno?: string
  job_type_id?: string
  vid?: string,
  route_id?: string
  service_id?: string
  status?: string
}

interface IComment {
  route_id: string
  drop_id: string
  remark?: string
}


export default {
  getRoute: async function (auth: IAuth, data: ITracking) {
    let prmList: any = []
    for (const key in data) {
      if (data[key] !== "") prmList.push(`${key}=${data[key]}`)
    }
    let param = prmList.join('&');

    try {
      const response = await axios.get(`${BASE_URL}/track/list?${param}`, {
        headers: { action_user_id: auth?.action_user_id, token_id: auth?.token_id }
      })
      return response.data
    } catch (error) {
      throw error
    }
  },

  getDash: async function (auth: IAuth, delivery_date) {
    try {
      const response = await axios.get(`${BASE_URL}/track/dash?delivery_date=${delivery_date}`, {
        headers: { action_user_id: auth?.action_user_id, token_id: auth?.token_id }
      })
      return response.data
    } catch (error) {
      throw error
    }
  },

  addComment: async function (auth: IAuth, data: IComment) {
    const response = await axios.put(`${BASE_URL}/track/comment`, data,
      {
        headers: { action_user_id: auth?.action_user_id, token_id: auth?.token_id }
      })
    return response
  }
}