
import React from "react";
import { inject, observer } from 'mobx-react';
import { PlusIcon } from "@heroicons/react/solid";
import { Button } from '../../../components'
import DataGrid, { Paging, Column, MasterDetail, Pager } from 'devextreme-react/data-grid';

const DataTable = inject("causeReasonStore")(observer((props) => {
  let causeReasonStore = props.causeReasonStore
  let { setCauseStore } = causeReasonStore // ACTIONS
  let { dataSource } = props // STATE

  const DetailTemplate = (props) => {
    let { cause_id, reasons } = props?.data?.data
    let dataSource = reasons || []

    return <div className="flex flex-col space-y-2 lg:w-3/5 sm:w-full">
      <div className="flex flex-row">
        <div className="flex-1 master-detail-caption ">
          ข้้อมูลสาเหตุ
        </div>

        <div className="flex flex-1 justify-end">
          <Button
            text="เพิ่มข้อมูล"
            icon={<PlusIcon />}
            size="sm"
            iconPosition='start'
            style={{ marginRight: 0 }}
            onClick={() => {
              setCauseStore({
                openReason: true,
                dataReasonEdit: { cause_id }
              })
            }}
          />
        </div>
      </div>

      <div className="flex-1">
        <React.Fragment>
          <DataGrid
            dataSource={dataSource}
            showBorders={true}
            columnAutoWidth={true}
          >
            <Column
              dataField="no"
              caption="ลำดับ"
              width={50}
              alignment="center"
            />
            <Column dataField="reason_name" caption="สาเหตุ" />
            <Column dataField="reason_status_name" caption="สถานะ" />
            <Column dataField="updated_by" caption="แก้ไขโดย" />
            <Column
              fixed={true}
              caption="แก้ไข"
              type="buttons"
              alignment="center"
              width={80}
              buttons={[
                {
                  hint: "แก้ไข",
                  icon: "fa fa-pencil-square-o",
                  visible: true,
                  onClick: (e) => {
                    e.cancel = "true"
                    setCauseStore({
                      openReason: true,
                      dataReasonEdit: e.row.data
                    })
                  }
                }
              ]} />
          </DataGrid>
        </React.Fragment>
      </div>
    </div>
  }

  return (<div>
    <DataGrid
      dataSource={dataSource}
      keyExpr="id"
      showBorders={true}
      columnAutoWidth={true}
      hoverStateEnabled={true}
    >
      <Paging defaultPageSize={10} />
      <Pager
        showInfo={true}
        showPageSizeSelector={true}
        allowedPageSizes={[10, 25, 50]}
      />
      <Column
        dataField="no"
        caption="ลำดับ"
        width={50}
        alignment="center"
      />
      <Column dataField="cause_name" caption="หน่วยงานต้นเหตุ" />
      <Column dataField="reason_count" caption="จำนวนสาเหตุ" />
      <Column dataField="status_name" caption="สถานะ" />
      <Column dataField="updated_by" caption="แก้ไขโดย" />
      <Column
        fixed={true}
        caption="แก้ไข"
        type="buttons"
        alignment="center"
        width={80}
        buttons={[
          {
            hint: "แก้ไข",
            icon: "fa fa-pencil-square-o",
            visible: true,
            onClick: (e) => {
              e.cancel = "true"
              setCauseStore({
                openCause: true,
                dataCauseEdit: e.row.data
              })
            }
          }
        ]} />
      <MasterDetail
        enabled={true}
        component={DetailTemplate}
      />
    </DataGrid>
  </div>
  )
}))
export default DataTable