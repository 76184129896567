import axios from 'axios'
import { BASE_URL } from '../AppConfig'
import IAuth from '../interfaces/Common/IAuth'

interface IDash {
  sdate?: string
  edate?: string
  view_type: number
  year?: number
  month?: number
}

export default {
  getDash: async function (auth: IAuth, data: IDash) {
    try {
      let prmList: any = []
      for (const key in data) {
        if (data[key] !== "") prmList.push(`${key}=${data[key]}`)
      }
      let param = prmList.join('&');

      const response = await axios.get(`${BASE_URL}/dash/get?${param}`, {
        headers: { action_user_id: auth?.action_user_id, token_id: auth?.token_id }
      })
      return response.data
    } catch (error) {
      throw error
    }
  }
}