import React, { useState, useMemo, useEffect } from 'react'
import {
  MenuIcon, PhotographIcon, XIcon, DocumentReportIcon, HomeIcon,
  CogIcon, GlobeAltIcon, StarIcon, MenuAlt1Icon, InformationCircleIcon,
  ArrowsExpandIcon, MapIcon, PencilIcon, LocationMarkerIcon, ArrowLeftIcon,
  ExclamationIcon, CheckCircleIcon, XCircleIcon, CheckIcon, SearchIcon, MoonIcon
} from '@heroicons/react/outline'


type Props = {
  iconName: string
  height?: number
  width?: number
  marginRight?: number
  color?: string
  className?: string
};

const Icon = ({
  iconName,
  height = 4,
  width = 4,
  marginRight = 1,
  color = "text-gray-400",
  className
}: Props) => {

  const IconChooser = () => {
    let icon: any
    let classIcon = `h-${height} w-${width} ${color} ${className} mr-${marginRight}`
    switch (iconName) {
      case "InformationCircleIcon":
        icon = <InformationCircleIcon className={classIcon} aria-hidden="true" />
        break;
      case "ArrowsExpandIcon":
        icon = <ArrowsExpandIcon className={classIcon} aria-hidden="true" />
        break;
      case "MapIcon":
        icon = <MapIcon className={classIcon} aria-hidden="true" />
        break;
      case "PencilIcon":
        icon = <PencilIcon className={classIcon} aria-hidden="true" />
        break;
      case "LocationMarkerIcon":
        icon = <LocationMarkerIcon className={classIcon} aria-hidden="true" />
        break;
      case "HomeIcon":
        icon = <HomeIcon className={classIcon} aria-hidden="true" />
        break;
      case "CogIcon":
        icon = <CogIcon className={classIcon} aria-hidden="true" />
        break;
      case "ArrowLeftIcon":
        icon = <ArrowLeftIcon className={classIcon} aria-hidden="true" />
        break;
      case "ExclamationIcon":
        icon = <ExclamationIcon className={classIcon} aria-hidden="true" />
        break;
      case "CheckCircleIcon":
        icon = <CheckCircleIcon className={classIcon} aria-hidden="true" />
        break;
      case "XCircleIcon":
        icon = <XCircleIcon className={classIcon} aria-hidden="true" />
        break;
      case "CheckIcon":
        icon = <CheckIcon className={classIcon} aria-hidden="true" />
        break;
      case "SearchIcon":
        icon = <SearchIcon className={classIcon} aria-hidden="true" />
        break;
      case "MoonIcon":
        icon = <MoonIcon className={classIcon} aria-hidden="true" />
        break;
    }
    return icon
  }

  return IconChooser()

}

export default Icon