import { useState, useRef, useEffect } from 'react'
import { inject, observer } from 'mobx-react';
import { createMultipleRef, getValueInputRef, setOptions } from '../../../helpers'
import { Input, Card, Button, ModalAlert, Select, FormLoading } from '../../../components'
import { CommonService, CustomerUserService } from "../../../services";
import { PlusIcon, SearchIcon } from '@heroicons/react/outline';
import ModalForm from './ModalForm';
import DataTable from './DataTable';
import { get, isEmpty } from 'lodash'

let firstRender = false
const CustomerUser = inject("authenStore")(observer((props) => {
  let authenStore = props.authenStore
  let { getAccountAuth } = authenStore // STATE
  let auth = { action_user_id: getAccountAuth?.user_id, token_id: getAccountAuth?.access_token }

  const [masterOptions, setMasterOptions] = useState({
    customer: [],
    shipto: [],
    common_status: []
  })

  const [loading, setLoading]: any = useState(false)
  const [dataSource, setDataSource]: any = useState([])
  const [open, setOpen]: any = useState(false)
  const [dataEdit, setDataEdit]: any = useState({})
  const [filterCurrent, setFilterCurrent]: any = useState({})
  const [filter, setFilter]: any = useState({
    cust_id: "",
    shipto_id: "",
    username: ""
  })
  const [optionAlert, setOptionAlert]: any = useState({
    show: false,
    action: {
      id: "",
      name: ""
    },
    type: "success",
    title: "",
    description: {
      visible: false,
      type: "",
      content: "",
      contentLists: [],

    }
  })

  const [dataForm, setDataForm]: any = useState({
    "dlv_doc_no": "",
    "route_id": "",
    "ts_gate_in": "",
    "ts_gate_out": "",
    "mileage_start": "",
    "mileage_end": "",
    "service_info": "",
    "tasks": [],
    "cost_items": []
  })

  const inputRef: any = useRef([]);
  createMultipleRef(inputRef, 1)

  useEffect(() => {
    if (firstRender || !isEmpty(getAccountAuth)) {
      searchCustomerUser()
      CommonService.getMasterData(auth, "customer,shipto,common_status")
        .then((res: any) => {
          let customer = res.result.customer.map((item) => ({ label: item.cust_name, value: item.cust_id }))
          let shipto = res.result.shipto.map((item) => ({ label: item.shipto_name, value: item.shipto_id }))
          let common_status = res.result.common_status.map((item) => ({ label: item.status_name, value: item.status_code }))

          setMasterOptions({
            ...masterOptions,
            customer,
            shipto,
            common_status
          })
        })
        .catch((err) => {
          setOptionAlert({
            ...optionAlert,
            type: "danger",
            show: true,
            action: {
              id: "",
              name: ""
            },
            title: "ผิดพลาด",
            description: { visible: true, content: `Load master data : ${err}` }
          })
        })
    }
    else
      firstRender = true
  }, [getAccountAuth])

  //#region 

  const searchCustomerUser = (isReloadCurrent = false, reloadByRouteID = "") => {
    setLoading(true)

    let formData = getValueInputRef(inputRef)
    setFilterCurrent(filter)

    let data: any = ""
    if (isReloadCurrent) {
      data = filterCurrent
    }
    else {
      data = filter
      data.username = get(formData, 'username', "")
    }

    CustomerUserService.getCustomerUser(auth, data)
      .then((res: any) => {
        let { code, message, result, sys_message } = res
        if (code === 200) {
          setDataSource(result)
          if (reloadByRouteID !== "") {
            let data = result.find((item) => item.route_id === reloadByRouteID)
            if (data) setDataForm(data)
          }
        }
        else {
          setOptionAlert({
            ...optionAlert,
            type: "danger",
            show: true,
            action: {
              id: "",
              name: ""
            },
            title: "ผิดพลาด",
            description: { visible: true, type: "text", content: `${message} ${sys_message}` }
          })
        }
        setLoading(false)
      })
      .catch((err) => {
        console.log("err  :", err)
        setLoading(false)
        setOptionAlert({
          ...optionAlert,
          type: "danger",
          show: true,
          action: {
            id: "",
            name: ""
          },
          title: "ผิดพลาด",
          description: { visible: true, type: "text", content: `${err}` }
        })
      })
  }

  const deleteCustomerUser = (id) => {
    CustomerUserService.deleteCustomerUser(auth, id)
      .then((res: any) => {
        if (res.code === 200) {
          setOptionAlert(setOptions({
            defaultOption: optionAlert,
            show: true,
            title: "ลบข้อมูลสำเร็จ"
          }))
          searchCustomerUser()
        }
      })
      .catch((err) => {
        setOptionAlert(setOptions({
          defaultOption: optionAlert,
          show: true,
          type: "danger",
          title: "ผิดพลาด",
          description: { visible: true, content: `${err}` }
        }))
      })
  }
  //#endregion

  return (
    <div className="space-y-3 p-4">
      <Card
        title="Customer User"
        extra={<Button
          text="เพิ่มข้อมูล"
          icon={<PlusIcon />}
          iconPosition='start'
          onClick={() => setOpen(true)}
        />}
      >
        <div className="mt-5 md:col-span-2 md:mt-0">
          <FormLoading loading={loading} >
            <div className="flex flex-col mb-4 space-y-1">
              <div className="space-y-1">
                <div className="flex flex-row">
                  <div className="flex-1 sm:grid sm:grid-cols-3 sm:items-end sm:gap-2">
                    <label className="block text-xs font-medium text-gray-700 mb-1 text-right">
                      ลูกค้า :
                    </label>
                    <div className="mt-1 sm:col-span-2 sm:mt-0">
                      <Select
                        showSearch={true}
                        allowClear={true}
                        defaultValue={filter.cust_id}
                        placeholder="ลูกค้า"
                        options={masterOptions.customer}
                        onChange={(value) => setFilter({ ...filter, cust_id: value })}
                      />
                    </div>
                  </div>

                  <div className="flex-1 sm:grid sm:grid-cols-3 sm:items-end sm:gap-2">
                    <label className="block text-xs font-medium text-gray-700 mb-1 text-right">
                      สาขา :
                    </label>
                    <div className="mt-1 sm:col-span-2 sm:mt-0">
                      <Select
                        showSearch={true}
                        allowClear={true}
                        defaultValue={filter.shipto_id}
                        placeholder="สาขา"
                        options={masterOptions.shipto}
                        onChange={(value) => setFilter({ ...filter, shipto_id: value })}
                      />
                    </div>
                  </div>

                  <div className="flex-1 sm:grid sm:grid-cols-3 sm:items-end sm:gap-2">
                    <label className="block text-xs font-medium text-gray-700 mb-1 text-right">
                      ชื่อผู้ใช้งาน :
                    </label>
                    <div className="mt-1 sm:col-span-2 sm:mt-0">
                      <Input
                        id="username"
                        label="ชื่อผู้ใช้งาน"
                        placeholder="ชื่อผู้ใช้งาน"
                        hiddenLabel={true}
                        onRef={(ref) => inputRef.current[0] = ref}
                      />
                    </div>
                  </div>

                  <div className="sm:grid sm:grid-cols-3 sm:items-end sm:gap-2">
                    <label className="block text-xs font-medium text-gray-700 mb-1 text-right">
                    </label>
                    <div className="flex flex-row mt-1 sm:col-span-2 sm:mt-0 space-x-2">
                      <button
                        type="button"
                        title="ค้นหา"
                        className={`flex flex-row ${loading.visible && 'cursor-not-allowed'} items-center rounded border border-transparent bg-blue-100 mt-0.5 px-2.5 py-1.5 text-xs font-medium text-blue-700 hover:bg-blue-200`}
                        onClick={() => searchCustomerUser()}>
                        <div className="flex flex-row-reverse">
                          <span>ค้นหา</span>
                          <SearchIcon className="w-4 h-4 mr-1" />
                        </div>
                      </button>

                    </div>
                  </div>
                </div>
              </div>
            </div>

            <DataTable
              dataSource={dataSource}
              onEdit={(data) => {
                setOpen(true)
                setDataEdit(data)
              }}
              onDelete={(data) => {
                setOptionAlert({
                  ...optionAlert,
                  action: {
                    id: data?.id,
                    name: 'delete'
                  },
                  type: "confirm",
                  title: "ยืนยัน",
                  show: true,
                  description: {
                    visible: true,
                    type: "text",
                    content: "คุณแน่ใจที่จะลบข้อมูล ?",
                    contentLists: []
                  }
                })
              }}
            />
          </FormLoading>
        </div>

        <ModalForm
          auth={auth}
          open={open}
          data={dataEdit}
          mode={isEmpty(dataEdit) ? "add" : "edit"}
          masterOptions={masterOptions}
          onClosed={(reload) => {
            setOpen(false)
            setDataEdit({})
            reload && searchCustomerUser(true)
          }}
        />
      </Card >

      <ModalAlert
        {...optionAlert}
        onOk={() => {
          let { action } = optionAlert

          if (action.name === "delete")
            deleteCustomerUser(action.id)

          setOptionAlert({ ...optionAlert, show: false })
        }}
        onCancel={() => {
          setOptionAlert({ ...optionAlert, show: false })
        }}
      />
    </div>
  )
}))

export default CustomerUser