import { useEffect, useState } from 'react'
import { inject, observer } from 'mobx-react';
import FormMaster from '../FormMaster'
import { CommonService } from "../../../services";
import { isEmpty } from 'lodash';

let firstRender = false
const CostType = inject("authenStore")(observer((props) => {
  let authenStore = props.authenStore
  let { getAccountAuth } = authenStore // STATE
  let auth = { action_user_id: getAccountAuth?.user_id, token_id: getAccountAuth?.access_token }

  const [masterOptions, setMasterOptions] = useState({
    common_status: [],
    cost_group: []
  })

  useEffect(() => {
    if (firstRender || !isEmpty(getAccountAuth)) {
      CommonService.getMasterData(auth, "common_status,cost_group")
        .then((res: any) => {
          let common_status = res.result.common_status.map((item) => ({ label: item.status_name, value: item.status_code }))
          let cost_group = res.result.cost_group.map((item) => ({ label: item.cost_group_name, value: item.cost_group_id }))
          setMasterOptions({
            ...masterOptions,
            common_status,
            cost_group
          })
        })
        .catch((err) => {
          console.log(`Load master data : ${err}`)
        })
    }
    else
      firstRender = true
  }, [getAccountAuth])

  return <FormMaster
    title="ข้อมูลประเภทค่าใช้จ่าย"
    tableConfig={{
      key: "id",
      apiPath: "cost_item/list",
      allowDelete: {
        visible: true,
        key: "cost_id",
        apiPath: "cost_item/delete",
        paramType: 'param'
      },
      optionDeleteFag: true,
      defaultColumn: [],
      columns: [
        {
          dataField: 'no',
          caption: "ลำดับ",
          width: 60,
          alignment: "center",
          allowFiltering: false
        },
        {
          dataField: 'code',
          caption: "รหัสประเภท",
          allowFiltering: true
        },
        {
          dataField: 'cost_name',
          caption: "ชื่อประเภทค่าใช้จ่าย",
          allowFiltering: true
        },
        {
          dataField: 'cost_group_name',
          caption: "กลุ่มค่าใช้จ่าย",
          allowFiltering: true
        },
        {
          dataField: 'status',
          caption: "สถานะ",
          allowFiltering: true,
          cellRender: (e) => e.value === "Y" ? 'ใช้งาน' : 'ไม่ใช้งาน'
        },
        {
          dataField: 'updated_by',
          caption: "แก้ไขโดย"
        }
      ],
      exportExcel: {
        fileName: 'ข้อมูลประเภทค่าใช้จ่าย',
        columnConfig: [
          { label: 'ลำดับ', value: 'no' },
          { label: 'รหัสประเภท', value: 'code' },
          { label: 'ชื่อประเภทค่าใช้จ่าย', value: 'cost_name' },
          { label: 'กลุ่มค่าใช้จ่าย', value: 'cost_group_name' },
          { label: 'สถานะ', value: (col) => (col.status === "Y" ? 'ใช้งาน' : 'ไม่ใช้งาน') },
          { label: 'แก้ไขโดย', value: 'updated_by' }
        ]
      }
    }}
    formData={{
      cost_name: "",
      code: "",
      cost_group_id: "",
      status: "Y"
    }}
    modalConfig={{
      visible: true,
      title: "ประเภทค่าใช้จ่าย",
      required: ["code", "cost_name", "cost_group_id"],
      fieldTypeNumber: [],
      paramName: "cost_id",
      apiPath: {
        "get": '',
        "post": 'cost_item/add',
        "put": 'cost_item/update'
      },
      elements: [
        {
          fieldName: "code",
          label: "รหัสประเภทค่าใช้จ่าย",
          placeholder: "รหัสประเภทค่าใช้จ่าย",
          required: true,
          inputType: "text",
          isOneRow: true
        },
        {
          fieldName: "cost_name",
          label: "ชื่อประเภทค่าใช้จ่าย",
          placeholder: "ชื่อประเภทค่าใช้จ่าย",
          required: true,
          inputType: "text",
          isOneRow: true
        },
        {
          showSearch: false,
          fieldName: "cost_group_id",
          required: true,
          label: "กลุ่มค่าใช้จ่าย",
          placeholder: "กลุ่มค่าใช้จ่าย",
          inputType: "select",
          isOneRow: true,
          options: masterOptions.cost_group
        },
        {
          showSearch: false,
          fieldName: "status",
          label: "สถานะ",
          inputType: "select",
          defaultValue: "Y",
          isOneRow: true,
          options: masterOptions.common_status
        }
      ]
    }}
  />
}))

export default CostType