import { isEmpty } from "lodash"

interface IValue {
  value: string | number
  message: string
}

interface ISchema {
  id: number,
  min?: IValue,
  max?: IValue,
  required?: IValue
}

type Props = {
  schema: ISchema[]
  inputRef: any
}

const makeMessage = (message) => ({ isError: true, message })
export function validateInput(arg: Props) {
  let result: any = {}
  arg?.schema.forEach(item => {
    let { id } = item
    let elmInput = arg.inputRef?.current.find((ref) => ref.id === id)

    if (elmInput) {
      let value = elmInput?.value
      if (item?.required && isEmpty(value.trim()))
        result[id] = makeMessage(item.required.message)
      else if (item?.min && (value.trim()).length < item?.min?.value)
        result[id] = makeMessage(item.min.message)
      else if (item?.max && (value.trim()).length < item?.max?.value)
        result[id] = makeMessage(item.max.message)
    }
  })
  return result
}