import axios from 'axios'
import { BASE_URL } from '../AppConfig'
import IAuth from '../interfaces/Common/IAuth'

interface IReport {
  sdate: string
  edate: string
  report_id?: string
  center_id?: string
  type?: string,
  ref_id1?: string
  status_list?: string

}

interface ITemp {
  sdate: string
  edate: string
  mid: number
}

export default {
  getReport: async function (auth: IAuth, data: IReport) {
    let prmList: any = []
    for (const key in data) {
      if (data[key] !== "") prmList.push(`${key}=${data[key]}`)
    }
    let param = prmList.join('&');

    try {
      const response = await axios.get(`${BASE_URL}/report/get_report?${param}`, {
        headers: { action_user_id: auth?.action_user_id, token_id: auth?.token_id }
      })
      return response.data
    } catch (error) {
      throw error
    }
  },

  getTempReport: async function (auth: IAuth, data: ITemp) {
    try {
      const response = await axios.get(`${BASE_URL}/gps/get?sdate=${data.sdate}&edate=${data.edate}&mid=${data.mid}`, {
        headers: { action_user_id: auth?.action_user_id, token_id: auth?.token_id }
      })
      return response.data
    } catch (error) {
      throw error
    }
  }
}