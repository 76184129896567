import { useState, useRef, useEffect } from 'react'
import { inject, observer } from 'mobx-react';
import { createMultipleRef } from '../../helpers'
import { Card, Button, ModalAlert, Select, FormLoading } from '../../components'
import { CommonService, MaintenanceService } from "../../services";
import { PlusIcon, SearchIcon } from '@heroicons/react/outline';
import { DatePicker } from 'antd';
import dayjs from 'dayjs';
import moment from 'moment'
import ModalForm from './ModalForm';
import AlertMaintanance from './AlertMaintanance';
import DataTable from './DataTable';
import { isEmpty } from 'lodash'

const { RangePicker } = DatePicker;
const dateFormat = 'YYYY-MM-DD';
let firstRender = false
const Maintanance = inject("authenStore")(observer((props) => {
  let authenStore = props.authenStore
  let { getAccountAuth } = authenStore // STATE
  let auth = { action_user_id: getAccountAuth?.user_id, token_id: getAccountAuth?.access_token }

  const [masterOptions, setMasterOptions] = useState({
    vehicle: [],
    ma_item: [],
    ma_type: [],
    ma_status: [],
    alert_status: [],
    garage: []
  })
  const [loading, setLoading]: any = useState(false)
  const [dataSource, setDataSource]: any = useState([])
  const [open, setOpen]: any = useState(false)
  const [firstTime, setFirtTime]: any = useState()
  const [dataEdit, setDataEdit]: any = useState({})
  const [filterCurrent, setFilterCurrent]: any = useState({})
  const [filter, setFilter]: any = useState({
    sdate: moment().format("YYYY-MM-DD"),
    edate: moment().format("YYYY-MM-DD"),
    vid: "",
    item_id: "",
    ma_status: "",
    alert_status: ""
  })
  const [optionAlert, setOptionAlert]: any = useState({
    show: false,
    action: {
      id: "",
      name: ""
    },
    type: "success",
    title: "",
    description: {
      visible: false,
      type: "",
      content: "",
      contentLists: [],

    }
  })

  const [dataForm, setDataForm]: any = useState({
    "dlv_doc_no": "",
    "route_id": "",
    "ts_gate_in": "",
    "ts_gate_out": "",
    "mileage_start": "",
    "mileage_end": "",
    "service_info": "",
    "tasks": [],
    "cost_items": []
  })

  const inputRef: any = useRef([]);
  createMultipleRef(inputRef, 2)

  useEffect(() => {
    if (firstRender || !isEmpty(getAccountAuth)) {
      CommonService.getMasterData(auth, "vehicle,ma_item,ma_type,ma_status,alert_status,garage")
        .then((res: any) => {
          let vehicle = res.result.vehicle.map((item) => ({ label: item.licenseplate, value: item.vehicle_id }))
          let ma_item = res.result.ma_item.map((item) => ({ label: item.ma_item_name, value: item.ma_item_id }))
          let ma_type = res.result.ma_type.map((item) => ({ label: item.desc, value: item.id }))
          let ma_status = res.result.ma_status.map((item) => ({ label: item.desc, value: item.code }))
          let alert_status = res.result.alert_status.map((item) => ({ label: item.desc, value: item.code }))
          let garage = res.result.garage.map((item) => ({ label: item.garage_name, value: item.garage_id }))

          setMasterOptions({
            ...masterOptions,
            vehicle,
            ma_item,
            ma_type,
            ma_status,
            alert_status,
            garage
          })
        })
        .catch((err) => {
          setOptionAlert({
            ...optionAlert,
            type: "danger",
            show: true,
            action: {
              id: "",
              name: ""
            },
            title: "ผิดพลาด",
            description: { visible: true, content: `Load master data : ${err}` }
          })
        })
    }
    else
      firstRender = true
  }, [getAccountAuth])

  //#region 

  const searchMaintenance = (isReloadCurrent = false, reloadByRouteID = "") => {
    setLoading(true)
    setFilterCurrent(filter)
    let data = isReloadCurrent ? filterCurrent : filter
    MaintenanceService.getMaintenance(auth, data)
      .then((res: any) => {
        let { code, message, result, sys_message } = res
        if (code === 200) {
          setDataSource(result)
          if (reloadByRouteID !== "") {
            let data = result.find((item) => item.route_id === reloadByRouteID)
            if (data) setDataForm(data)
          }
        }
        else {
          setOptionAlert({
            ...optionAlert,
            type: "danger",
            show: true,
            action: {
              id: "",
              name: ""
            },
            title: "ผิดพลาด",
            description: { visible: true, type: "text", content: `${message} ${sys_message}` }
          })
        }
        setLoading(false)
      })
      .catch((err) => {
        console.log("err  :", err)
        setLoading(false)
        setOptionAlert({
          ...optionAlert,
          type: "danger",
          show: true,
          action: {
            id: "",
            name: ""
          },
          title: "ผิดพลาด",
          description: { visible: true, type: "text", content: `${err}` }
        })
      })
  }
  //#endregion

  return (
    <div className="space-y-3 p-4">
      <Card
        title="ซ่อมบำรุง"
        extra={<Button
          text="เพิ่มข้อมูล"
          icon={<PlusIcon />}
          iconPosition='start'
          onClick={() => setOpen(true)}
        />}
      >
        <div className="mt-5 md:col-span-2 md:mt-0">
          <FormLoading loading={loading} >
            <div className="flex flex-col mb-4 space-y-1">
              <div className="space-y-1">
                <div className="flex flex-row">
                  <div className="flex-1 sm:grid sm:grid-cols-3 sm:items-end sm:gap-2">
                    <label className="block text-xs font-medium text-gray-700 mb-1 text-right">
                      วันที่ :
                    </label>
                    <div className="mt-1 sm:col-span-2 sm:mt-0">
                      <RangePicker
                        allowClear={false}
                        defaultValue={[dayjs(filter.sdate, dateFormat), dayjs(filter.edate, dateFormat)]}
                        style={{ marginTop: 4, width: '100%' }}
                        onChange={(d: any) => {
                          if (d) {
                            setFilter({
                              ...filter,
                              sdate: d[0].format("YYYY-MM-DD"),
                              edate: d[1].format("YYYY-MM-DD")
                            })
                          }
                          else {
                            setFilter({
                              ...filter,
                              sdate: "",
                              edate: ""
                            })
                          }
                        }}
                      />
                    </div>
                  </div>

                  <div className="flex-1 sm:grid sm:grid-cols-3 sm:items-end sm:gap-2">
                    <label className="block text-xs font-medium text-gray-700 mb-1 text-right">
                      ทะเบียน :
                    </label>
                    <div className="mt-1 sm:col-span-2 sm:mt-0">
                      <Select
                        allowClear={true}
                        showSearch={true}
                        defaultValue={filter.vid}
                        placeholder="ทะเบียน"
                        options={masterOptions.vehicle}
                        onChange={(value) => setFilter({ ...filter, vid: value })}
                      />
                    </div>
                  </div>

                  <div className="flex-1 sm:grid sm:grid-cols-3 sm:items-end sm:gap-2">
                    <label className="block text-xs font-medium text-gray-700 mb-1 text-right">
                      รายการซ่อมบำรุง :
                    </label>
                    <div className="mt-1 sm:col-span-2 sm:mt-0">
                      <Select
                        allowClear={true}
                        showSearch={true}
                        defaultValue={filter.item_id}
                        placeholder="รายการซ่อมบำรุง"
                        options={masterOptions.ma_item}
                        onChange={(value) => setFilter({ ...filter, item_id: value })}
                      />
                    </div>
                  </div>
                </div>

                <div className="flex flex-row">
                  <div className="flex-1 sm:grid sm:grid-cols-3 sm:items-end sm:gap-2">
                    <label className="block text-xs font-medium text-gray-700 mb-1 text-right">
                      สถานะซ่อมบำรุง :
                    </label>
                    <div className="mt-1 sm:col-span-2 sm:mt-0">
                      <Select
                        allowClear={true}
                        defaultValue={filter.ma_status}
                        placeholder="สถานะซ่อมบำรุง"
                        options={masterOptions.ma_status}
                        onChange={(value) => setFilter({ ...filter, ma_status: value })}
                      />
                    </div>
                  </div>

                  <div className="flex-1 sm:grid sm:grid-cols-3 sm:items-end sm:gap-2">
                    <label className="block text-xs font-medium text-gray-700 mb-1 text-right">
                      สถานะการแจ้งเตือน :
                    </label>
                    <div className="mt-1 sm:col-span-2 sm:mt-0">
                      <Select
                        allowClear={true}
                        defaultValue={filter.alert_status}
                        placeholder="สถานะการแจ้งเตือน"
                        options={masterOptions.alert_status}
                        onChange={(value) => setFilter({ ...filter, alert_status: value })}
                      />
                    </div>
                  </div>

                  <div className="flex-1 sm:grid sm:grid-cols-3 sm:items-end sm:gap-2">
                    <label className="block text-xs font-medium text-gray-700 mb-1 text-right">
                    </label>
                    <div className="flex flex-row mt-1 sm:col-span-2 sm:mt-0 space-x-2">
                      <button
                        type="button"
                        title="ค้นหา"
                        className={`flex flex-row ${loading.visible && 'cursor-not-allowed'} items-center rounded border border-transparent bg-blue-100 mt-0.5 px-2.5 py-1.5 text-xs font-medium text-blue-700 hover:bg-blue-200`}
                        onClick={() => searchMaintenance()}>
                        <div className="flex flex-row-reverse">
                          <span>แสดงรายละเอียด</span>
                          <SearchIcon className="w-4 h-4 mr-1" />
                        </div>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <DataTable
              dataSource={dataSource}
              onEdit={(data) => {
                setOpen(true)
                setDataEdit(data)
              }}
            />
          </FormLoading>
        </div>

        <ModalForm
          auth={auth}
          open={open}
          data={dataEdit}
          mode={isEmpty(dataEdit) ? "add" : "edit"}
          masterOptions={masterOptions}
          onClosed={(reload) => {
            setOpen(false)
            setDataEdit({})
            reload && searchMaintenance(true)
          }}
        />

        {!firstTime && <AlertMaintanance auth={auth} onClosed={() => setFirtTime(true)} />}
      </Card >

      <ModalAlert
        {...optionAlert}
        onOk={() => {
          setOptionAlert({ ...optionAlert, show: false })
        }}
        onCancel={() => {
          setOptionAlert({ ...optionAlert, show: false })
        }}
      />
    </div>
  )
}))

export default Maintanance