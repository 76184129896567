import React, { useEffect, useState, useRef } from 'react'
import RouteService from "../../services/RouteService";
import { ModalAlert } from '../../components'
import GoogleMapReact from "google-map-react"
import { GOOGLE_MAP_KEY } from '../../AppConfig'
import { MarkerScale } from '../../helpers/markerScale';
import { get, isEmpty } from 'lodash'
import IAuth from '../../interfaces/Common/IAuth'
import MapControl from './MapControl'
import { Checkbox } from 'antd'
import { setOverlayImageMaps } from "../../components/GoogleMap/GeoServer"

type Props = {
  deliveryDate: string // YYYY-MM-DD
  routeId: number;
  show?: boolean;
  showHistoryLine?: boolean;
  isGeom?: number;
  isAuto?: string;
  height?: string | number
  isFocusMarker?: boolean
  auth: IAuth,
  multiRoutes: any
  showGeoserver: boolean
  mapLoaded?: (value: any) => void;
}

let polylines: any = [], polylineHistory: any = [],
  markerPolylines: any = [], markerCurrent: any = [], markerRoute: any = []
let locationAll: any = []

const areEqual = (prevProps, nextProps) => {
  if (nextProps.deliveryDate !== "" && nextProps.routeId !== "") {
    return false
  }

  return false
};

const MultiRoute = React.memo((props: Props) => {
  let {
    mapLoaded,
    deliveryDate = "",
    routeId = 0,
    show = true,
    showHistoryLine = false,
    isGeom = 1,
    isAuto = "",
    height = '60vh',
    isFocusMarker = false,
    auth,
    multiRoutes = [],
    showGeoserver = false
  }: any = props

  const mapRef: any = useRef()
  const [map, setMap]: any = useState(null)
  const [loading, setLoading] = useState(false)

  const [optionAlert, setOptionAlert]: any = useState({
    show: false,
    action: {
      id: "",
      name: ""
    },
    type: "success",
    title: "",
    description: {
      visible: false,
      type: "",
      content: "",
      contentLists: [],
    }
  })

  useEffect(() => {
    locationAll.length = 0
    if (deliveryDate !== "" && routeId !== "" && multiRoutes !== "") {
      // getRoute()
      getMultiRoute()
      showHistoryLine && getHistory(routeId)
    }
    else {
      clearMapObject()
    }

  }, [deliveryDate, routeId, multiRoutes])

  const clearMapObject = () => {
    markerRoute.forEach(item => item.setMap(null))
    markerCurrent.forEach(item => item.setMap(null))
    markerPolylines.forEach(item => item.setMap(null))
    polylines.forEach(item => item.setMap(null))
    polylineHistory.forEach(item => item.setMap(null))
  }

  // const getRoute = () => {
  //   clearMapObject()
  //   RouteService.getRoute(auth, {
  //     delivery_date: deliveryDate,
  //     route_id: routeId,
  //     is_geom: isGeom,
  //     is_auto: isAuto
  //   })
  //     .then((res: any) => {
  //       let { code, message, result } = res
  //       if (code === 200) {
  //         if (result.length > 0) createGeoLine(result[0])
  //       }
  //       else {
  //         setOptionAlert({
  //           ...optionAlert,
  //           type: "danger",
  //           show: true,
  //           action: {
  //             id: "",
  //             name: ""
  //           },
  //           title: "ดึงข้อมูลเส้นทางผิดพลาด",
  //           description: { visible: true, type: "text", content: `${message}` }
  //         })
  //       }
  //       setLoading(false)
  //     })
  //     .catch((err) => {
  //       setLoading(false)
  //       setOptionAlert({
  //         ...optionAlert,
  //         type: "danger",
  //         show: true,
  //         action: {
  //           id: "",
  //           name: ""
  //         },
  //         title: "ดึงข้อมูลเส้นทางผิดพลาด",
  //         description: { visible: true, type: "text", content: `${err}` }
  //       })
  //     })
  // }

  const getMultiRoute = () => {
    clearMapObject()
    // console.log("#getMultiRoute : ", multiRoutes)
    let options = multiRoutes.map((item) => ({
      delivery_date: deliveryDate,
      route_id: item.route_id,
      is_geom: isGeom,
      is_auto: isAuto
    }))
    // console.log("options : ", options)

    RouteService.getMultiRoute(auth, options)
      .then((response: any) => {
        // console.log("GET MULTI ROUTE : ", response)

        response.forEach(res => {
          let { status, data } = res
          if (status === 200) {
            if (data?.result.length > 0) {
              // console.log("info : ", data?.result[0])
              let options = multiRoutes.find((item) => item.route_id === data?.result[0]?.route_id)
              createGeoLine(data?.result[0], options?.lineColor)
            }

          }
        });

        setLoading(false)
      })
      .catch((err) => {
        setLoading(false)
        setOptionAlert({
          ...optionAlert,
          type: "danger",
          show: true,
          action: {
            id: "",
            name: ""
          },
          title: "ดึงข้อมูลเส้นทางผิดพลาด",
          description: { visible: true, type: "text", content: `${err}` }
        })
      })
  }

  const getHistory = (routeId) => {
    polylineHistory.forEach(item => item.setMap(null))
    RouteService.getRouteHistory(auth, routeId)
      .then((res: any) => {
        let { code, message, result } = res
        if (code === 200) {
          if (result?.gps.length > 0) {
            createHistoryLine(result)
          }
        }
        else {
          setOptionAlert({
            ...optionAlert,
            type: "danger",
            show: true,
            action: {
              id: "",
              name: ""
            },
            title: "ดึงข้อมูลเส้นทางย้อนหลังผิดพลาด",
            description: { visible: true, type: "text", content: `${message}` }
          })
        }
        setLoading(false)
      })
      .catch((err) => {
        setLoading(false)
        setOptionAlert({
          ...optionAlert,
          type: "danger",
          show: true,
          action: {
            id: "",
            name: ""
          },
          title: "ดึงข้อมูลเส้นทางย้อนหลังผิดพลาด",
          description: { visible: true, type: "text", content: `${err}` }
        })
      })
  }

  const createGeoLine = (data, lineColor) => {
    // console.log(">> createGeoLine : ", data)
    if (mapRef.current) {
      try {
        const mRoute: any = new window.google.maps.Marker({
          position: { lat: data?.loc_receipt_lat, lng: data?.loc_receipt_lon },
          map: mapRef.current,
          icon: data?.icon_url
        });
        markerRoute.push(mRoute);

        if (data?.loc_receipt_lat !== "") {
          locationAll.push({ lat: data?.loc_receipt_lat, lng: data?.loc_receipt_lon })
        }

        data.drops.forEach(drop => {
          if (!isEmpty(drop?.geom)) {
            let coordinates = drop?.geom?.coordinates.map((item) => {
              locationAll.push({ lat: item[1], lng: item[0] })
              return ({ lat: item[1], lng: item[0] })
            })
            const polyLine: any = new window.google.maps.Polyline({
              path: coordinates,
              geodesic: true,
              strokeColor: lineColor,
              strokeOpacity: 0.8,
              strokeWeight: 4,
              // fillOpacity: 0.35,
              draggable: false,
              visible: true,
              // radius: 30000,
              zIndex: 1
            });

            polylines.push(polyLine);
            polyLine.setMap(mapRef.current);
          }

          let dropLocaation = { lat: drop?.lat, lng: drop?.lon }
          const marker: any = new window.google.maps.Marker({
            position: dropLocaation,
            map: mapRef.current,
            icon: drop?.icon_url
          });

          locationAll.push(dropLocaation)
          markerPolylines.push(marker);

          const contentString = `<b>${drop?.shipto_name} : ${drop?.start_time} - ${drop?.end_time} </b>`
          const infowindow: any = new google.maps.InfoWindow({
            content: contentString
          });

          // infowindow.open({
          //   anchor: marker,
          //   map: mapRef.current
          // });

          marker.addListener("click", () => {
            infowindow.open({
              anchor: marker,
              map: mapRef.current
            });
          });
        })

        fitBounds()

      } catch (error) {
        console.log("createGeoLine : ", error)
      }
    }
  }

  const createHistoryLine = (data) => {
    if (mapRef.current) {
      let { gps, base_icon_path, route_color, route_size } = data
      // let locationAll: any = []
      let coordinates = gps.map((item) => {
        locationAll.push({ lat: item?.lat, lng: item?.lon })
        return ({ lat: item?.lat, lng: item?.lon })
      })
      const polyLine: any = new window.google.maps.Polyline({
        path: coordinates,
        geodesic: true,
        strokeColor: route_color,
        strokeOpacity: 0.8,
        strokeWeight: route_size,
        draggable: false,
        visible: true,
        zIndex: 1
      });

      polylineHistory.push(polyLine);
      polyLine.setMap(mapRef.current);
      fitBounds()
    }
  }

  const fitBounds = () => {
    if (!isFocusMarker && mapRef.current) {
      // fitBounds     
      let bounds: any = new window.google.maps.LatLngBounds();
      locationAll.forEach(item => {
        bounds.extend({ lat: item?.lat, lng: item?.lng })
      });
      mapRef.current.fitBounds(bounds)
    }
  }

  // console.log("multiRoutes : ", multiRoutes)
  return <>
    <div style={{ height, width: '100%' }}>

      <GoogleMapReact
        bootstrapURLKeys={{ key: `${GOOGLE_MAP_KEY}&libraries=drawing,geometry` }}
        defaultCenter={{ lat: 13.788500, lng: 100.610055 }}
        defaultZoom={5}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ map }) => {
          mapRef.current = map
          setMap(map)
          mapLoaded && mapLoaded(map)
          showGeoserver && setOverlayImageMaps(map)
        }}
      >
      </GoogleMapReact>
    </div>

    <ModalAlert
      {...optionAlert}
      onOk={async () => {
        setOptionAlert({ ...optionAlert, show: false })
      }}
      onCancel={() => {
        setOptionAlert({ ...optionAlert, show: false })
      }}
    />
  </>
}, areEqual);

export default MultiRoute