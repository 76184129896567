import { action, configure, observable, toJS, computed } from 'mobx';
import { persist } from 'mobx-persist'

configure({ enforceActions: 'always' });

export default class CommonStore {
  @persist('list') @observable queueData = []
  @persist('list') @observable routeData = []
  @persist('list') @observable routeDrop = []
  @persist('list') @observable shippointDrop = []
  @persist('object') @observable focusDrop = { lat: "", lon: "" }
  @persist('object') @observable focusDropRoute = { lat: "", lon: "" }
  @observable checkModelInfo = {
    status: "",
    matrix: []
  }

  @computed
  get getQueueData() {
    return toJS(this.queueData)
  }

  @computed
  get getRouteData() {
    return toJS(this.routeData)
  }

  @computed
  get getFocusDrop() {
    return toJS(this.focusDrop)
  }

  @computed
  get getFocusDropRoute() {
    return toJS(this.focusDropRoute)
  }

  @computed
  get getRouteDrop() {
    return toJS(this.routeDrop)
  }

  @computed
  get getShippointDrop() {
    return toJS(this.shippointDrop)
  }

  @computed
  get getCheckModelInfo() {
    return toJS(this.checkModelInfo)
  }

  @action.bound
  setRouteAutoStore(objValue: any) {
    for (const [key, value] of Object.entries(objValue)) {
      this[key] = value
    }
  }

  @action
  reset() {
    this.queueData = []
  }
}