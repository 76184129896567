
import { Card } from '../../components'

const Worksheet = () => {
  return (
    <div className="space-y-3 p-3">
      <Card
        title="เปิดใบงาน"
      >
        <div className="space-y-4" style={{ height: 'calc(100vh - 178px)' }}>

        </div>
      </Card>
    </div>
  )
}

export default Worksheet