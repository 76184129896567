
import { useState, useRef, useEffect } from 'react'
import { createMultipleRef, getValueInputRef } from '../../helpers'
import { Input, Modal, ModalAlert, Select, FormLoading, DatePicker } from '../../components'
import DataGrid, { Editing, Lookup, Paging, Column, RequiredRule } from 'devextreme-react/data-grid';
import { PlusIcon } from '@heroicons/react/outline';
import { MaintenanceService } from '../../services';
import { InputNumber } from 'antd';
import { isEmpty, get } from 'lodash'

const ModalForm = (props) => {
  let { masterOptions, open, data, auth } = props // STATES
  let { onClosed } = props // ACTIONS

  const [loading, setLoading]: any = useState({ visible: false, tip: 'กำลังบันทึก...' })
  const [validation, setValidation]: any = useState({})
  const [shakeIsWrong, setShakeIsWrong] = useState(false)
  const [optionAlert, setOptionAlert]: any = useState({
    show: false,
    action: {
      id: "",
      name: ""
    },
    type: "success",
    title: "",
    description: {
      visible: false,
      type: "",
      content: "",
      contentLists: [],

    }
  })
  const [dataForm, setDataForm]: any = useState({
    "mode": "add",
    "ma_doc_no": "",
    "vid": "",
    "trans_date": "",
    "mileage": "",
    "in_date": "",
    "out_date": "",
    "garage_id": "",
    "ma_status": "",
    "ma_items": []
  })

  const gridRef: any = useRef(null);
  const inputRef: any = useRef([]);
  createMultipleRef(inputRef, 2)

  useEffect(() => {
    if (!isEmpty(data)) {
      let dataForm = {
        "mode": "update",
        "ma_doc_no": data?.ma_doc_no,
        "vid": data?.vid,
        "trans_date": data?.trans_date,
        "mileage": data?.mileage,
        "in_date": data?.in_date,
        "out_date": data?.out_date,
        "garage_id": data?.garage_id,
        "ma_status": data?.ma_status,
        "total_cost": data?.ma_cost,
        "ma_items": [
          {
            "no": 1,
            "ma_type_id": data?.ma_type_id,
            "ma_item_id": data?.ma_item_id,
            "ma_cause": data?.ma_cause,
            "ma_desc": data?.ma_desc,
            "ma_cost": data?.ma_cost,
            "status": data?.ma_status,
          }
        ]
      }
      setDataForm(dataForm)
    }
  }, [open, data])

  const initialData = () => {
    setValidation({})
    setDataForm({
      "mode": "add",
      "ma_doc_no": "",
      "vid": "",
      "trans_date": "",
      "mileage": "",
      "in_date": "",
      "out_date": "",
      "garage_id": "",
      "ma_status": "",
      "ma_items": []
    })
  }

  const onSave = () => {
    let validateCheck = {}, message = { isError: true, message: "โปรดระบุ" }
    let formData = getValueInputRef(inputRef)

    if (formData.ma_doc_no.trim() === "")
      validateCheck['ma_doc_no'] = message
    if (dataForm.vid.length === 0 || dataForm.vid === "")
      validateCheck['vid'] = message
    if (dataForm.trans_date === "")
      validateCheck['trans_date'] = message
    if (dataForm.mileage === "")
      validateCheck['mileage'] = message
    if (dataForm.in_date === "")
      validateCheck['in_date'] = message
    if (dataForm.out_date === "")
      validateCheck['out_date'] = message
    if (dataForm.garage_id.length === 0 || dataForm.garage_id === "")
      validateCheck['garage_id'] = message
    if (dataForm.ma_status.length === 0 || dataForm.ma_status === "")
      validateCheck['ma_status'] = message
    if (dataForm.ma_items.length === 0)
      validateCheck['ma_items'] = message

    if (isEmpty(validateCheck)) {
      setValidation({})
      setOptionAlert({
        ...optionAlert,
        action: {
          id: dataForm.id,
          name: "update"
        },
        type: "confirm",
        title: "ยืนยัน",
        show: true,
        description: {
          visible: true,
          type: "text",
          content: "คุณแน่ใจที่จะบันทึกข้อมูล ?",
          contentLists: []
        }
      })
    }
    else {
      setValidation(validateCheck)
      setShakeIsWrong(true)
      setTimeout(() => setShakeIsWrong(false), 100);
    }
  }

  const saveData = () => {
    let formData = getValueInputRef(inputRef)
    let ma_items = dataForm.ma_items.map((item) => {
      delete item.id
      delete item.no
      return item
    })

    let data = {
      "ma_doc_no": formData.ma_doc_no,
      "vid": dataForm.vid,
      "trans_date": dataForm.trans_date,
      "mileage": dataForm.mileage,
      "in_date": dataForm.in_date,
      "out_date": dataForm.out_date,
      "garage_id": dataForm.garage_id,
      "ma_status": dataForm.ma_status,
      "ma_items": ma_items
    }

    setLoading({ ...loading, visible: true, tip: 'กำลังบันทึก...' })
    if (dataForm.mode === "add") {
      MaintenanceService.addMaintenance(auth, data)
        .then((res: any) => {
          let { code, message, sys_message } = res.data
          if (code === 200) {
            setOptionAlert({
              ...optionAlert,
              type: "success",
              show: true,
              action: {
                id: "",
                name: ""
              },
              title: "บันทึกข้อมูลสำเร็จ",
              description: { visible: false, type: "text", content: " ", contentLists: [] }
            })
            initialData()
            onClosed(true)
          }
          else {
            setOptionAlert({
              ...optionAlert,
              type: "danger",
              show: true,
              action: {
                id: "",
                name: ""
              },
              title: "ผิดพลาด",
              description: { visible: true, type: "text", content: `${message} ${sys_message}` }
            })
          }
          setLoading({ ...loading, visible: false })
        })
        .catch((err) => {
          setOptionAlert({
            ...optionAlert,
            type: "danger",
            show: true,
            action: {
              id: "",
              name: ""
            },
            title: "ผิดพลาด",
            description: { visible: true, type: "text", content: `${err}` }
          })
          setLoading({ ...loading, visible: false })
        })
    }
    else {
      MaintenanceService.updateMaintenance(auth, data)
        .then((res: any) => {
          let { code, message, sys_message } = res.data

          if (code === 200) {
            setOptionAlert({
              ...optionAlert,
              type: "success",
              show: true,
              action: {
                id: "",
                name: ""
              },
              title: "บันทึกข้อมูลสำเร็จ",
              description: { visible: false, type: "text", content: " ", contentLists: [] }
            })
            initialData()
            onClosed(true)
          }
          else {
            setOptionAlert({
              ...optionAlert,
              type: "danger",
              show: true,
              action: {
                id: "",
                name: ""
              },
              title: "ผิดพลาด",
              description: { visible: true, type: "text", content: `${message} ${sys_message}` }
            })
          }
          setLoading({ ...loading, visible: false })
        })
        .catch((err) => {
          setOptionAlert({
            ...optionAlert,
            type: "danger",
            show: true,
            action: {
              id: "",
              name: ""
            },
            title: "ผิดพลาด",
            description: { visible: true, type: "text", content: `${err}` }
          })
          setLoading({ ...loading, visible: false })
        })
    }
  }

  const labelError = (fieldName) => {
    if (get(validation, fieldName)) {
      if (validation[fieldName].isError)
        return <p className="text-xs italic text-danger-600">
          {validation[fieldName].message}
        </p>
      else
        return null
    }
  }

  return (<>
    <Modal
      title={{ text: `รายละเอียดค่าซ่อม/บำรุงรักษา` }}
      open={open}
      maskClosable={true}
      shake={shakeIsWrong}
      width={"w-3/4"}
      onClosed={() => {
        onClosed()
        initialData()
      }}
      onOk={() => onSave()}
    >
      <FormLoading loading={loading.visible} tip={loading.tip}>
        <div className="flex flex-col -my-4 lg:space-y-0 sm:space-y-2">
          <div className="flex lg:flex-row  sm:flex-col sm:space-y-2">
            <div className="flex-1 sm:grid sm:grid-cols-3 sm:items-end sm:gap-2">
              <label className="block text-xs font-medium text-gray-700 mb-1 text-right">
                หมายเลขเอกสาร<span className="text-red-500">* </span> :{labelError("ma_doc_no")}
              </label>
              <div className="mt-1 sm:col-span-2 sm:mt-0">
                <Input
                  id="ma_doc_no"
                  label="หมายเลขเอกสาร"
                  placeholder="หมายเลขเอกสาร"
                  hiddenLabel={true}
                  defaultValue={dataForm.ma_doc_no}
                  validate={validation?.ma_doc_no}
                  onRef={(ref) => inputRef.current[0] = ref}
                />
              </div>
            </div>

            <div className="flex-1 sm:grid sm:grid-cols-3 sm:items-end sm:gap-2">
              <label className="block text-xs font-medium text-gray-700 mb-1 text-right">
                ทะเบียน<span className="text-red-500">* </span> :{labelError("vid")}
              </label>
              <div className="mt-1 sm:col-span-2 sm:mt-0">
                <Select
                  showSearch={true}
                  hiddenLabel={true}
                  defaultValue={dataForm.vid}
                  placeholder="ทะเบียน"
                  validate={validation['vid']}
                  options={masterOptions.vehicle}
                  onChange={(value) => setDataForm({ ...dataForm, vid: value })}
                />
              </div>
            </div>
          </div>

          <div className="flex lg:flex-row sm:flex-col sm:space-y-2">
            <div className="flex-1 sm:grid sm:grid-cols-3 sm:items-end sm:gap-2">
              <label className="block text-xs font-medium text-gray-700 mb-1 text-right">
                วันที่แจ้งซ่อม<span className="text-red-500">* </span> :{labelError("trans_date")}
              </label>
              <div className="mt-1 sm:col-span-2 sm:mt-0">
                <DatePicker
                  id="trans_date"
                  placeholder="วันที่แจ้งซ่อม"
                  allowClear={false}
                  hiddenLabel={true}
                  defaultValue={dataForm.trans_date}
                  validate={validation['trans_date']}
                  onChange={(d) => {
                    if (d)
                      setDataForm({ ...dataForm, trans_date: d.format("YYYY-MM-DD") })
                    else
                      setDataForm({ ...dataForm, trans_date: "" })
                  }}
                />
              </div>
            </div>

            <div className="flex-1 sm:grid sm:grid-cols-3 sm:items-end sm:gap-2">
              <label className="block text-xs font-medium text-gray-700 mb-1 text-right">
                เลขไมล์<span className="text-red-500">* </span> :{labelError("mileage")}
              </label>
              <div className="mt-1 sm:col-span-2 sm:mt-0">
                <InputNumber
                  min={0}
                  value={dataForm.mileage}
                  placeholder="เลขไมล์"
                  style={{ width: "100%" }}
                  onChange={(value) => setDataForm({ ...dataForm, mileage: value })}
                />
              </div>
            </div>
          </div>

          <div className="flex lg:flex-row sm:flex-col sm:space-y-2">
            <div className="flex-1 sm:grid sm:grid-cols-3 sm:items-end sm:gap-2">
              <label className="block text-xs font-medium text-gray-700 mb-1 text-right">
                วันที่เข้าซ่อม<span className="text-red-500">* </span> :{labelError("in_date")}
              </label>
              <div className="mt-1 sm:col-span-2 sm:mt-0">
                <DatePicker
                  id="in_date"
                  placeholder="วันที่เข้าซ่อม"
                  allowClear={false}
                  hiddenLabel={true}
                  defaultValue={dataForm.in_date}
                  validate={validation['in_date']}
                  onChange={(d) => {
                    if (d)
                      setDataForm({ ...dataForm, in_date: d.format("YYYY-MM-DD") })
                    else
                      setDataForm({ ...dataForm, in_date: "" })
                  }}
                />
              </div>
            </div>

            <div className="flex-1 sm:grid sm:grid-cols-3 sm:items-end sm:gap-2">
              <label className="block text-xs font-medium text-gray-700 mb-1 text-right">
                วันที่ออก<span className="text-red-500">* </span> :{labelError("out_date")}
              </label>
              <div className="mt-1 sm:col-span-2 sm:mt-0">
                <DatePicker
                  id="out_date"
                  placeholder="วันที่ออก"
                  allowClear={false}
                  hiddenLabel={true}
                  defaultValue={dataForm.out_date}
                  validate={validation['out_date']}
                  onChange={(d) => {
                    if (d)
                      setDataForm({ ...dataForm, out_date: d.format("YYYY-MM-DD") })
                    else
                      setDataForm({ ...dataForm, out_date: "" })
                  }}
                />
              </div>
            </div>
          </div>

          <div className="flex lg:flex-row sm:flex-col sm:space-y-2">
            <div className="flex-1 sm:grid sm:grid-cols-3 sm:items-end sm:gap-2">
              <label className="block text-xs font-medium text-gray-700 mb-1 text-right">
                อู่<span className="text-red-500">* </span> :{labelError("garage_id")}
              </label>
              <div className="mt-1 sm:col-span-2 sm:mt-0">
                <Select
                  showSearch={true}
                  hiddenLabel={true}
                  defaultValue={dataForm.garage_id}
                  placeholder="อู่"
                  validate={validation['garage_id']}
                  options={masterOptions.garage}
                  onChange={(value) => setDataForm({ ...dataForm, garage_id: value })}
                />
              </div>
            </div>

            <div className="flex-1 sm:grid sm:grid-cols-3 sm:items-end sm:gap-2">
              <label className="block text-xs font-medium text-gray-700 mb-1 text-right">
                สถานะการซ่อม<span className="text-red-500">* </span> :{labelError("ma_status")}
              </label>
              <div className="mt-1 sm:col-span-2 sm:mt-0">
                <Select
                  hiddenLabel={true}
                  defaultValue={dataForm.ma_status}
                  placeholder="สถานะการซ่อม"
                  validate={validation['ma_status']}
                  options={masterOptions.ma_status}
                  onChange={(value) => setDataForm({ ...dataForm, ma_status: value })}
                />
              </div>
            </div>
          </div>

          <div className="flex lg:flex-row  sm:flex-col sm:space-y-2 pt-2">
            <div className="flex-1 sm:grid sm:grid-cols-3 sm:items-end sm:gap-2">
              <label className="block text-xs font-medium text-gray-700 mb-1 text-right">
                รายละเอียด<span className="text-red-500">* </span> :{labelError("product_list")}
              </label>
              <div className="flex flex-row mt-1 sm:col-span-2 sm:mt-0">
                <button
                  type="button"
                  className="-mb-1 inline-flex items-center px-1.5 py-1.5 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50"
                  title="Refresh"
                  onClick={() => {
                    gridRef.current.instance.addRow()
                  }}
                >
                  <PlusIcon className="w-4 h-4" />
                </button>
                <p className={`text-sm italic text-danger-600 mt-1 ml-1 ${!validation?.ma_items?.isError && 'sr-only'}`}>{`(ระบุอย่างน้อย 1 รายการ)`}</p>
              </div>
            </div>
            <div className="flex-1 sm:grid sm:grid-cols-3 sm:items-end sm:gap-2" />
          </div>

          <div className="flex lg:flex-row sm:flex-col sm:space-y-2 pt-2">
            <div className="flex-1 sm:grid sm:grid-cols-6 sm:items-end sm:gap-2">
              <label className="block text-xs font-medium text-gray-700 mb-1 text-right">
              </label>
              <div className="mt-1 sm:col-span-5 sm:mt-0">
                <DataGrid
                  ref={(ref) => gridRef.current = ref}
                  dataSource={dataForm?.ma_items}
                  keyExpr="no"
                  height={200}
                  allowColumnReordering={true}
                  columnAutoWidth={true}
                  showBorders={true}
                  onSaved={(e) => {
                    let items = e.component.getDataSource().items().map((item, index) => ({
                      ...item, no: (index + 1),
                      ma_cause: get(item, 'ma_cause', ""),
                      ma_desc: get(item, 'ma_desc', "")
                    }))
                    let total_cost = 0
                    items.forEach(item => total_cost += item?.ma_cost)
                    setDataForm({ ...dataForm, ma_items: items, total_cost })
                  }}
                >
                  <Paging enabled={true} />
                  <Editing
                    mode="row"
                    allowUpdating={true}
                    allowDeleting={true}
                    allowAdding={false} />

                  <Column
                    dataField="no"
                    caption="ลำดับ"
                    width={50}
                    alignment="center"
                    allowEditing={false}
                  />
                  <Column
                    dataField="ma_type_id"
                    caption="ประเภท"
                    width={"20%"}
                  >
                    <RequiredRule />
                    <Lookup dataSource={masterOptions.ma_type} valueExpr="value" displayExpr="label" />
                  </Column>
                  <Column
                    dataField="ma_item_id"
                    caption="รายการ"
                    width={"20%"}
                  >
                    <RequiredRule />
                    <Lookup dataSource={masterOptions.ma_item} valueExpr="value" displayExpr="label" />
                  </Column>
                  <Column
                    dataField="ma_cause"
                    caption="อาการ"
                    width={"20%"}
                    dataType="text"
                  >
                  </Column>
                  <Column
                    dataField="ma_desc"
                    caption="รายละเอียด"
                    width={"20%"}
                    dataType="text"
                  >
                  </Column>
                  <Column
                    dataField="status"
                    caption="สถานะการซ่อม"
                    width={"20%"}
                  >
                    <RequiredRule />
                    <Lookup dataSource={masterOptions.ma_status} valueExpr="value" displayExpr="label" />
                  </Column>
                  <Column
                    dataField="ma_cost"
                    caption="ค่าใช้จ่าย"
                    dataType="number"
                  >
                    <RequiredRule />
                  </Column>
                </DataGrid>
              </div>
            </div>
          </div>

          <div className="flex flex-row-reverse pt-2">
            <div className="flex flex-row-reverse  sm:grid sm:grid-cols-3 sm:items-end sm:gap-2">
              <label className="block text-xs font-medium text-gray-700 mb-1 text-right">
                รวมค่าใช้จ่าย
              </label>
              <div className="mt-1 sm:col-span-2 sm:mt-0">
                <InputNumber
                  placeholder="รวมค่าใช้จ่าย"
                  style={{ width: "100%" }}
                  disabled={true}
                  value={dataForm.total_cost}
                />
              </div>
            </div>
          </div>
        </div>
      </FormLoading>
    </Modal>

    <ModalAlert
      {...optionAlert}
      onOk={() => {
        let { action } = optionAlert
        if (action.name === "update") saveData()
        setOptionAlert({ ...optionAlert, show: false })
      }}
      onCancel={() => {
        setOptionAlert({ ...optionAlert, show: false })
      }}
    />
  </>
  )
}

export default ModalForm