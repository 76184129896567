
import { useState, useRef, useEffect } from 'react'
import { inject, observer } from 'mobx-react';
import { createMultipleRef, getValueInputRef } from '../../../helpers'
import { Input, InputTextarea, Modal, ModalAlert, Select, FormLoading } from '../../../components'
import { CommonService, PictureService } from "../../../services";
import { isEmpty } from 'lodash'

let firstRender = false
const ModalPictureForm = inject("authenStore")(observer((props) => {
  let authenStore = props.authenStore
  let { getAccountAuth } = authenStore // STATE
  let auth = { action_user_id: getAccountAuth?.user_id, token_id: getAccountAuth?.access_token }

  let { open, data } = props // STATES
  let { onClosed } = props // ACTIONS

  const [loading, setLoading]: any = useState({ visible: false, tip: 'กำลังบันทึก...' })
  const [validation, setValidation]: any = useState({})
  const [shakeIsWrong, setShakeIsWrong] = useState(false)
  const [masterOptions, setMasterOptions] = useState({
    pic_type: [],
    status: [],
  })
  const [optionAlert, setOptionAlert]: any = useState({
    show: false,
    action: {
      id: "",
      name: ""
    },
    type: "success",
    title: "",
    description: {
      visible: false,
      type: "",
      content: "",
      contentLists: []
    }
  })

  const [dataForm, setDataForm]: any = useState({
    "mode": "add",
    "pic_id": "",
    "pic_code": "",
    "pic_name": "",
    "pic_desc": "",
    "pic_type_code": "",
    "status": "Y"
  })

  const inputRef: any = useRef([]);
  createMultipleRef(inputRef, 3)

  useEffect(() => {
    if (!isEmpty(data)) {
      let dataForm = {
        "mode": "update",
        "pic_id": data.id,
        "pic_code": data.pic_code,
        "pic_name": data.pic_name,
        "pic_desc": data.pic_desc,
        "pic_type_code": data.pic_type_code,
        "status": data.status
      }
      setDataForm(dataForm)
    }

    if (firstRender || !isEmpty(getAccountAuth)) {
      CommonService.getMasterData(auth, "pic_type,common_status")
        .then((res: any) => {
          let pic_type = res.result.pic_type.map((item) => ({ label: item.pic_type_name, value: item.pic_type_code }))
          let status = res.result.common_status.map((item) => ({ label: item.status_name, value: item.status_code }))
          setMasterOptions({
            ...masterOptions,
            pic_type,
            status
          })
        })
        .catch((err) => {
          setOptionAlert({
            ...optionAlert,
            type: "danger",
            show: true,
            action: {
              id: "",
              name: ""
            },
            title: "ผิดพลาด",
            description: { visible: true, content: `Load master data : ${err}` }
          })
        })
    }
    else
      firstRender = true
  }, [open, data, getAccountAuth])

  const initialData = () => {
    setValidation({})
    setDataForm({
      "mode": "add",
      "pic_id": "",
      "pic_code": "",
      "pic_name": "",
      "pic_desc": "",
      "pic_type_code": "",
      "status": "Y"
    })
  }

  const onSave = () => {
    let validateCheck = {}, message = { isError: true, message: "โปรดระบุ" }
    let formData = getValueInputRef(inputRef)

    if (formData.pic_code.trim() === "")
      validateCheck['pic_code'] = message
    if (formData.pic_name.trim() === "")
      validateCheck['pic_name'] = message
    if (dataForm.status.length === 0 || dataForm.status === "")
      validateCheck['status'] = message
    if (dataForm.pic_type_code.length === 0 || dataForm.pic_type_code === "")
      validateCheck['pic_type_code'] = message
    if (isEmpty(validateCheck)) {
      setValidation({})
      setOptionAlert({
        ...optionAlert,
        action: {
          id: dataForm.id,
          name: "save"
        },
        type: "confirm",
        title: "ยืนยัน",
        show: true,
        description: {
          visible: true,
          type: "text",
          content: "คุณแน่ใจที่จะบันทึกข้อมูล ?",
          contentLists: []
        }
      })
    }
    else {
      setValidation(validateCheck)
      setShakeIsWrong(true)
      setTimeout(() => setShakeIsWrong(false), 100);
    }
  }

  const saveData = () => {
    let formData = getValueInputRef(inputRef)

    let data: any = {
      "pic_code": formData.pic_code,
      "pic_name": formData.pic_name,
      "pic_desc": formData.pic_desc,
      "pic_type_code": dataForm.pic_type_code,
      "status": dataForm.status
    }
    if (dataForm.mode === "update") data.pic_id = dataForm.pic_id

    setLoading({ ...loading, visible: true, tip: 'กำลังบันทึก...' })
    if (dataForm.mode === "add") {
      PictureService.addPicture(auth, data)
        .then((res: any) => {
          let { code, message, sys_message } = res.data

          if (code === 200) {
            setOptionAlert({
              ...optionAlert,
              type: "success",
              show: true,
              action: {
                id: "",
                name: ""
              },
              title: "บันทึกข้อมูลสำเร็จ",
              description: { visible: false, type: "text", content: " ", contentLists: [] }
            })
            initialData()
            onClosed(true)
          }
          else {
            setOptionAlert({
              ...optionAlert,
              type: "danger",
              show: true,
              action: {
                id: "",
                name: ""
              },
              title: "ผิดพลาด",
              description: { visible: true, type: "text", content: `${message} ${sys_message}` }
            })
          }
          setLoading({ ...loading, visible: false })
        })
        .catch((err) => {
          setOptionAlert({
            ...optionAlert,
            type: "danger",
            show: true,
            action: {
              id: "",
              name: ""
            },
            title: "ผิดพลาด",
            description: { visible: true, type: "text", content: `${err}` }
          })
          setLoading({ ...loading, visible: false })
        })
    }
    else {
      PictureService.updatePicture(auth, data)
        .then((res: any) => {
          let { code, message, sys_message } = res.data

          if (code === 200) {
            setOptionAlert({
              ...optionAlert,
              type: "success",
              show: true,
              action: {
                id: "",
                name: ""
              },
              title: "บันทึกข้อมูลสำเร็จ",
              description: { visible: false, type: "text", content: " ", contentLists: [] }
            })
            initialData()
            onClosed(true)
          }
          else {
            setOptionAlert({
              ...optionAlert,
              type: "danger",
              show: true,
              action: {
                id: "",
                name: ""
              },
              title: "ผิดพลาด",
              description: { visible: true, type: "text", content: `${message} ${sys_message}` }
            })
          }
          setLoading({ ...loading, visible: false })
        })
        .catch((err) => {
          setOptionAlert({
            ...optionAlert,
            type: "danger",
            show: true,
            action: {
              id: "",
              name: ""
            },
            title: "ผิดพลาด",
            description: { visible: true, type: "text", content: `${err}` }
          })
          setLoading({ ...loading, visible: false })
        })
    }
  }

  return (<>
    <Modal
      title={{ text: `${dataForm.mode === "add" ? 'เพิ่ม' : 'แก้ไข'}ข้อมูล` }}
      open={open}
      maskClosable={true}
      shake={shakeIsWrong}
      onClosed={() => {
        initialData()
        onClosed()
      }}
      onOk={() => onSave()}
    >
      <FormLoading loading={loading.visible} tip={loading.tip}>
        <div className='md:space-y-2'>
          <div className='flex-1'>
            <Input
              id="pic_code"
              label="Picture Code"
              required={true}
              maxLength={5}
              placeholder="Picture Code"
              defaultValue={dataForm.pic_code}
              validate={validation?.pic_code}
              onRef={(ref) => inputRef.current[0] = ref}
            />
          </div>

          <div className='flex-1'>
            <Input
              id="pic_name"
              label="Picture Name"
              required={true}
              placeholder="Picture Name"
              defaultValue={dataForm.pic_name}
              validate={validation?.pic_name}
              onRef={(ref) => inputRef.current[1] = ref}
            />
          </div>

          <div className='flex-1'>
            <InputTextarea
              id="pic_desc"
              label="Picture Desc"
              placeholder="Picture Desc"
              defaultValue={dataForm.pic_desc}
              validate={validation?.pic_desc}
              onRef={(ref) => inputRef.current[2] = ref}
            />
          </div>

          <div className='flex-1'>
            <Select
              showSearch={true}
              label="Picture Type"
              required={true}
              placeholder="Picture Type"
              validate={validation?.pic_type_code}
              onChange={(select) => setDataForm({ ...dataForm, pic_type_code: select })}
              defaultValue={dataForm.pic_type_code === "" ? [] : [dataForm.pic_type_code]}
              options={masterOptions.pic_type}
            />
          </div>

          <div className='flex-1'>
            <Select
              label="Status"
              required={true}
              placeholder="Status"
              validate={validation?.status}
              onChange={(select) => setDataForm({ ...dataForm, status: select })}
              defaultValue={dataForm.status === "" ? [] : [dataForm.status]}
              options={masterOptions.status}
            />
          </div>
        </div>
      </FormLoading>
    </Modal>

    <ModalAlert
      {...optionAlert}
      onOk={() => {
        let { type, action } = optionAlert
        if (action.name === "save") saveData()
        setOptionAlert({ ...optionAlert, show: false })
      }}
      onCancel={() => {
        setOptionAlert({ ...optionAlert, show: false })
      }}
    />
  </>
  )
}))

export default ModalPictureForm