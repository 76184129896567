
import { useState, useEffect } from 'react'
import { inject, observer } from 'mobx-react';
import { setOptions } from '../../../helpers'
import { Modal, Button, ModalAlert, FormLoading } from '../../../components'
import { PictureService } from "../../../services";
import DataGrid, { Column, Scrolling } from 'devextreme-react/data-grid';
import { PlusIcon } from '@heroicons/react/outline';
import { isEmpty } from 'lodash'
import ModalPictureForm from './ModalPictureForm';

let firstRender = false
const ModalManagement = inject("pictureStore", "authenStore")(observer((props) => {
  let authenStore = props.authenStore
  let { getAccountAuth } = authenStore // STATE
  let auth = { action_user_id: getAccountAuth?.user_id, token_id: getAccountAuth?.access_token }


  let { masterOptions, open } = props // STATES
  let { onClosed } = props // ACTIONS
  const [loading, setLoading]: any = useState({ visible: false, tip: 'กำลังบันทึก...' })
  const [dataSource, setDataSource]: any = useState([])
  const [shakeIsWrong, setShakeIsWrong] = useState(false)
  const [dataEdit, setDataEdit]: any = useState({})
  const [openForm, setOpenForm]: any = useState(false)
  const [optionAlert, setOptionAlert]: any = useState({
    show: false,
    action: {
      id: "",
      name: ""
    },
    type: "success",
    title: "",
    description: {
      visible: false,
      type: "",
      content: "",
      contentLists: []
    }
  })

  useEffect(() => {
    if (firstRender || !isEmpty(getAccountAuth)) {
      getPicture()
    }
    else
      firstRender = true
  }, [open, getAccountAuth])

  const getPicture = () => {
    PictureService.getPicture(auth)
      .then((res: any) => {
        let { code, message, result, sys_message } = res
        if (code === 200) {
          setDataSource(result)
        }
        else {
          setOptionAlert({
            ...optionAlert,
            type: "danger",
            show: true,
            action: {
              id: "",
              name: ""
            },
            title: "ดึงข้อมูลผิดพลาด",
            description: { visible: true, type: "text", content: `${message} ${sys_message}` }
          })
          setLoading(false)
        }
      })
      .catch((err) => {
        setOptionAlert({
          ...optionAlert,
          type: "danger",
          show: true,
          action: {
            id: "",
            name: ""
          },
          title: "ดึงข้อมูลผิดพลาด",
          description: { visible: true, type: "text", content: `${err}` }
        })
      })
  }

  const deletePicture = (id) => {
    PictureService.deletePicture(auth, id)
      .then((res: any) => {
        if (res.code === 200) {
          setOptionAlert(setOptions({
            defaultOption: optionAlert,
            show: true,
            title: "ลบข้อมูลสำเร็จ"
          }))
          getPicture()
        }
      })
      .catch((err) => {
        setOptionAlert(setOptions({
          defaultOption: optionAlert,
          show: true,
          type: "danger",
          title: "ผิดพลาด",
          description: { visible: true, content: `${err}` }
        }))
      })
  }

  return (<>
    <Modal
      title={{ text: `จัดการภาพถ่าย` }}
      open={open}
      maskClosable={true}
      shake={shakeIsWrong}
      width={"w-3/5"}
      onClosed={() => { onClosed() }}
      extra={<Button
        text="เพิ่มข้อมูล"
        icon={<PlusIcon />}
        iconPosition='start'
        onClick={() => setOpenForm(true)}
      />}
      footer={<Button
        text="ปิด"
        width="w-20"
        type="white"
        onClick={() => onClosed()}
      />}
    >
      <FormLoading loading={loading.visible} tip={loading.tip}>
        <DataGrid
          dataSource={dataSource}
          keyExpr="id"
          height="calc(60vh)"
          showBorders={true}
          columnAutoWidth={true}
          hoverStateEnabled={true}
        >
          <Scrolling columnRenderingMode="virtual" />
          <Column
            dataField="no"
            caption="ลำดับ"
            width={50}
            alignment="center"
          />
          <Column dataField="pic_code" caption="Picture code" />
          <Column dataField="pic_type_name" caption="Picture Type" />
          <Column dataField="pic_desc" caption="Picture Desc" />
          <Column dataField="status_name" caption="Status" />
          <Column
            fixed={true}
            caption="แก้ไข/ลบ"
            type="buttons"
            width={80}
            buttons={[
              {
                hint: "แก้ไข",
                icon: "fa fa-pencil-square-o",
                visible: true,
                onClick: (e) => {
                  e.cancel = "true"
                  let data = e.row.data
                  setDataEdit(data)
                  setOpenForm(true)
                }
              },
              {
                hint: "ลบ",
                icon: "trash",
                visible: true,
                onClick: (e) => {
                  e.cancel = "true"
                  let data = e.row.data

                  setOptionAlert(setOptions({
                    defaultOption: alert,
                    action: {
                      id: data.id,
                      name: 'delete'
                    },
                    show: true,
                    type: "confirm",
                    title: "ยืนยัน",
                    description: {
                      visible: true,
                      content: "คุณแน่ใจที่จะลบข้อมูล ?"
                    }
                  }))
                }
              }
            ]} />
        </DataGrid>
      </FormLoading>

      <ModalPictureForm
        auth={auth}
        open={openForm}
        data={dataEdit}
        mode={isEmpty(dataEdit) ? "add" : "edit"}
        masterOptions={masterOptions}
        onClosed={(reload) => {
          setOpenForm(false)
          setDataEdit({})
          reload && getPicture()
        }}
      />
    </Modal>

    <ModalAlert
      {...optionAlert}
      onOk={() => {
        let { type, action } = optionAlert
        if (action.name === "delete")
          deletePicture(action.id)
        setOptionAlert({ ...optionAlert, show: false })
      }}
      onCancel={() => {
        setOptionAlert({ ...optionAlert, show: false })
      }}
    />
  </>
  )
}))

export default ModalManagement