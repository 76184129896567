import { action, configure, observable, toJS, computed } from 'mobx';
import { persist } from 'mobx-persist'

configure({ enforceActions: 'always' });

export default class CommonStore {
  @persist('object') @observable rememberMe = { username: '', password: '' }
  @persist('object') @observable token: string = ""
  @persist('object') @observable currentMenu: number = 1
  @persist('object') @observable agreementAccept: boolean = false
  @persist('object') @observable expandSidebar: boolean = false
  @persist('object') @observable breadcrumbs: any = {
    path: "",
    mainMenu: "แดชบอร์ด",
    subMenu: ["sub 1"]
  }
  @persist @observable displayLanguage: string = 'th'
  @observable currentMenuTest: number = 1

  @computed
  get getExpandSidebar() {
    return toJS(this.expandSidebar)
  }

  @computed
  get getRememberMe() {
    return toJS(this.rememberMe)
  }

  @computed
  get getBreadcrumbs() {
    return toJS(this.breadcrumbs)
  }

  @computed
  get getCurrentMenu() {
    return toJS(this.currentMenu)
  }
  @action
  signinSuccess(token: string) {
    this.token = token
  }

  @action.bound
  setValues(objValue: any) {
    for (const [key, value] of Object.entries(objValue)) {
      this[key] = value
    }
  }

  @action.bound
  setCommonStore(objValue: any) {
    for (const [key, value] of Object.entries(objValue)) {
      this[key] = value
    }
  }

  @action
  clearStored() {
    // this.token = ""
  }
}