
import React, { useState, useEffect } from 'react'
import { inject, observer } from 'mobx-react';
import { Modal, Button } from '../../components'
import DataGrid, { Paging, Column, Scrolling, MasterDetail } from 'devextreme-react/data-grid';
import { MaintenanceService } from "../../services";
import { isEmpty } from 'lodash';

let firstRender = false
const AlertMaintanance = inject("authenStore")(observer((props) => {
  let { onClosed } = props // ACTIONS
  let authenStore = props.authenStore
  let { getAccountAuth } = authenStore // STATE
  let auth = { action_user_id: getAccountAuth?.user_id, token_id: getAccountAuth?.access_token }

  const [dataSource, setDataSource] = useState([])
  const [open, setOpen] = useState(false)
  useEffect(() => {
    if (firstRender || !isEmpty(getAccountAuth)) {
      MaintenanceService.getMaintenanceAlert(auth)
        .then((res: any) => {
          let { code, result } = res
          if (code === 200 && result.length > 0) {
            setDataSource(result)
            setOpen(true)
          }
        })
        .catch((err) => {
          console.log(err)
          setDataSource([])
          setOpen(false)
        })
    }
    else
      firstRender = true
  }, [getAccountAuth])

  return (<>
    <Modal
      title={{ text: `แจ้งเตือนซ่อมบำรุง` }}
      open={open}
      maskClosable={true}
      width={"w-3/4"}
      buttonOk={{ text: "ตกลง" }}
      onClosed={() => { }}
      footer={<Button
        text="ปิด"
        width="w-20"
        onClick={() => onClosed()}
      />}
    >
      <DataGrid
        dataSource={dataSource}
        keyExpr="vid"
        height="calc(60vh)"
        showBorders={true}
        columnAutoWidth={true}
        hoverStateEnabled={true}
      >
        <Scrolling columnRenderingMode="virtual" />
        <Paging enabled={false} />
        <Column
          dataField="no"
          caption="ลำดับ"
          width={50}
          alignment="center"
        />
        <Column dataField="vehicle_name" caption="ชื่อรถ" />
        <Column dataField="licenseplate" caption="ทะเบียน" />
        <Column dataField="current_mileage" caption="เลขไมล์ปัจจุบัน" />
        <MasterDetail
          enabled={true}
          component={DetailTemplate}
        />
      </DataGrid>
    </Modal>
  </>
  )
}))

const DetailTemplate = (props) => {
  let dataSource = props?.data?.data?.items[0] || []

  return <React.Fragment>
    <DataGrid
      dataSource={dataSource}
      showBorders={true}
      columnAutoWidth={true}
      width={'100%'}
    >
      <Column
        dataField="no"
        caption="ลำดับ"
        width={50}
        alignment="center"
      />
      <Column dataField="ma_item_name" caption="รายการ" />
      <Column dataField="warning_mileage" caption="กำหนดเตือนเลขไมล์" />
      <Column dataField="warning_mileage" caption="กำหนดวันที่แจ้งเตือน	" />
      <Column dataField="warning_status_name" caption="สถานะนการเตือน" />
    </DataGrid>
  </React.Fragment>
}

export default AlertMaintanance