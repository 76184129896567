
import { useEffect, useState } from "react";
import { inject, observer } from 'mobx-react';
import { DashbaordService } from "../../services";
import { Card, Button, FormLoading, Select, ModalAlert } from '../../components'
import {
  Chart,
  Series,
  ArgumentAxis,
  CommonSeriesSettings,
  Export,
  Legend,
  ValueAxis,
  Point,
  Tooltip
} from 'devextreme-react/chart';
import PieChart, {
  Legend as PLegend,
  Series as PSeries,
  Tooltip as PTooltip,
  Title,
  Label,
  Font,
  Connector,
} from 'devextreme-react/pie-chart';
import { DatePicker } from 'antd';
import { ChartPieIcon } from "@heroicons/react/solid";
import dayjs from 'dayjs';
import moment from 'moment'
import { isEmpty } from "lodash";

const { RangePicker } = DatePicker;
const dateFormat = 'YYYY-MM-DD', monthFormat = 'MM/YYYY';
let intervalUpdate: any = null
let filterCurrents: any = {}
const Dashboard = inject("authenStore")(observer((props) => {
  let authenStore = props.authenStore
  let { getAccountAuth } = authenStore // STATE
  let auth = { action_user_id: getAccountAuth?.user_id, token_id: getAccountAuth?.access_token }

  const VIEWTYPE = [
    {
      value: 1,
      label: "ช่วงวันที่"
    },
    {
      value: 2,
      label: "เดือน/ปี"
    },
    {
      value: 3,
      label: "ปี"
    }
  ]
  const [loading, setLoading] = useState(false)
  const [dataDash, setDataDash]: any = useState({
    stats: [
      { name: 'จำนวนงาน', field: "total_route", val: 0 },
      { name: 'จำนวนใบงาน', field: "total_qno", val: 0 },
      { name: 'จำนวนพนักงาน', field: "total_service", val: 0 },
      { name: 'จำนวนรับคืน', field: "job_ret_back", val: 0 }
    ],
    pieChart: [
      {
        region: 'ระหว่างจัดส่ง',
        field: "job_assign",
        val: 0,
      },
      {
        region: 'จัดส่งสำเร็จ',
        field: "job_success",
        val: 0,
      },
      {
        region: 'จัดส่งไม่สำเร็จ',
        field: "job_fail",
        val: 0,
      }
    ],
    jobs: []
  })
  const [filter, setFilter]: any = useState({
    sdate: moment().format("YYYY-MM-DD"),
    edate: moment().format("YYYY-MM-DD"),
    view_type: 1,
    year: moment().format("YYYY"),
    month: moment().format("MM")
  })
  const [optionAlert, setOptionAlert]: any = useState({
    show: false,
    action: {
      id: "",
      name: ""
    },
    type: "success",
    title: "",
    description: {
      visible: false,
      type: "",
      content: "",
      contentLists: []
    }
  })

  useEffect(() => {
    return () => {
      clearTimeout(intervalUpdate)
    }
  }, [])

  const getDash = (isReloadCurrent = false) => {
    clearTimeout(intervalUpdate)
    if (!isReloadCurrent) setLoading(true)

    let filterData: any = {
      view_type: filter.view_type
    }

    if (isReloadCurrent) {
      filterData = isReloadCurrent ? filterCurrents : filter
    }
    else {
      if (filter.view_type === 1) {
        filterData.sdate = filter.sdate
        filterData.edate = filter.edate
      }
      else if (filter.view_type === 2) {
        filterData.month = filter.month
        filterData.year = filter.year
      }
      else if (filter.view_type === 3) {
        filterData.year = filter.year
      }
      filterCurrents = filterData
    }

    DashbaordService.getDash(auth, filterData)
      .then((res: any) => {
        let { code, message, result, sys_message } = res
        if (code === 200) {
          if (!isEmpty(result)) {
            let {
              total_route, total_qno, total_service, job_ret_back,
              job_assign, job_success, job_fail, jobs,
            } = result
            let _stats = [...dataDash.stats]
            _stats[0].val = total_route
            _stats[1].val = total_qno
            _stats[2].val = total_service
            _stats[3].val = job_ret_back

            let _pieChart = [...dataDash.pieChart]
            _pieChart[0].val = job_assign
            _pieChart[1].val = job_success
            _pieChart[2].val = job_fail

            setDataDash({
              ...dataDash,
              stats: _stats,
              pieChart: _pieChart,
              jobs,
            })
          }
          intervalUpdate = setTimeout(() => getDash(true), 60000);
        }
        else {
          setOptionAlert({
            ...optionAlert,
            type: "danger",
            show: true,
            action: {
              id: "",
              name: ""
            },
            title: "ผิดพลาด",
            description: { visible: true, type: "text", content: `${message} ${sys_message}` }
          })
        }
        if (!isReloadCurrent) setLoading(false)
      })
      .catch((err) => {
        console.log("err  :", err)
        if (!isReloadCurrent) setLoading(false)
        setOptionAlert({
          ...optionAlert,
          type: "danger",
          show: true,
          action: {
            id: "",
            name: ""
          },
          title: "ผิดพลาด",
          description: { visible: true, type: "text", content: `${err}` }
        })
      })
  }

  const customizePoint = (point) => {
    let color = {}
    switch (point.argument) {
      case "ระหว่างจัดส่ง":
        color = { color: 'rgb(2, 114, 183)' }
        break;
      case "จัดส่งสำเร็จ":
        color = { color: 'rgb(255 129 0)' }
        break;
      case "จัดส่งไม่สำเร็จ":
        color = { color: 'rgb(173, 173, 178)' }
        break;
    }
    return color
  }

  const customizeTooltip = (arg) => {
    return {
      text: `${arg.argumentText} : ${arg.value}`,
    };
  }

  return <div className="space-y-3 p-3">
    <Card
      title="แดชบอร์ด"
      extra={<div className="flex flex-row space-x-2">
        <label className="flex items-end text-xs font-medium text-gray-700 mb-1 text-right">
          รูปแบบ :
        </label>
        <Select
          hiddenLabel={true}
          defaultValue={filter.view_type}
          width={200}
          placeholder="รูปแบบ"
          options={VIEWTYPE}
          onChange={(value) => setFilter({ ...filter, view_type: value })}
        />
        {
          filter.view_type === 1 && <RangePicker
            allowClear={false}
            defaultValue={[dayjs(filter.sdate, dateFormat), dayjs(filter.edate, dateFormat)]}
            style={{ marginTop: 4 }}
            onChange={(d: any) => {
              if (d) {
                setFilter({
                  ...filter,
                  sdate: d[0].format("YYYY-MM-DD"),
                  edate: d[1].format("YYYY-MM-DD")
                })
              }
              else {
                setFilter({
                  ...filter,
                  sdate: "",
                  edate: ""
                })
              }
            }}
          />
        }

        {
          filter.view_type === 2 && <DatePicker
            allowClear={false}
            defaultValue={dayjs(`${filter.month}/${filter.year}`, monthFormat)}
            style={{ marginTop: 4 }}
            format={monthFormat}
            onChange={(date, dateString) => {
              let spDate = dateString.split('/')
              setFilter({
                ...filter,
                month: spDate[0],
                year: spDate[1]
              })
            }}
            picker="month" />
        }

        {
          filter.view_type === 3 && <DatePicker
            defaultValue={dayjs(filter.year, "YYYY")}
            onChange={(date, dateString) => setFilter({ ...filter, year: dateString })}
            style={{ marginTop: 4 }}
            picker="year" />
        }

        <Button
          text="แสดง"
          icon={<ChartPieIcon />}
          iconPosition='start'
          style={{ marginTop: 4 }}
          onClick={() => getDash()}
        />
      </div>}
    >
      <FormLoading loading={loading}>
        <div className="bg-white -my-3">
          <div className="space-y-6">
            <div className="flex justify-center ">
              <div className="pt-2 w-1/2">
                <dl className="grid grid-cols-1 border gap-0.5 overflow-hidden rounded-md text-center sm:grid-cols-2 lg:grid-cols-4 p-1" style={{ backgroundColor: 'rgb(4 114 183)' }}>
                  {dataDash.stats.map((stat, idx) => (
                    <div key={stat.name} className={`flex flex-col ${idx !== 0 ? 'border-l' : ''} bg-white/5  border-white/10 py-0.5`}>
                      <dt className="text-xs font-semibold leading-6 text-gray-200">{stat.name}</dt>
                      <dd className="order-first text-lg font-semibold tracking-tight text-white">{stat.val}</dd>
                    </div>
                  ))}
                </dl>
              </div>
            </div>

            <div className="flex lg:flex-row  md:flex-col sm:flex-col">
              <div className="shrink-0 mr-3 lg:w-3/5 p-2  border-2">
                <Chart
                  palette="Violet"
                  dataSource={dataDash.jobs}
                  style={{ height: 400 }}
                  animation={false}
                >
                  <Title text="งานทั้งหมด">
                    <Font size={18} weight={500} family="IBM Plex Sans Thai" />
                  </Title>
                  <CommonSeriesSettings
                    argumentField="series_name"
                    type={"line"}
                  >
                    <Point visible={true} size={10} />
                  </CommonSeriesSettings>
                  <Series
                    valueField={"total_job"}
                    name={"จำนวนงาน"}
                    color="rgb(31 58 138)"
                  />
                  <ArgumentAxis
                    minVisualRangeLength={{ minutes: 10 }}
                    position={"buttom"} />
                  <ValueAxis
                    visualRange={[0, 100]}
                    tickInterval={10}
                  >
                    <Title text="จำนวนงาน" >
                      <Font size={14} weight={500} family="IBM Plex Sans Thai" />
                    </Title>
                  </ValueAxis>
                  <Legend visible={false} />
                  <Export enabled={false} />

                  <Tooltip enabled={true} />
                </Chart>
              </div>

              <div className="shrink-0 mr-3 lg:w-2/5 border-2">
                <PieChart
                  id="pie"
                  type="doughnut"
                  palette="Soft Pastel"
                  innerRadius={0.6}
                  animation={false}
                  resolveLabelOverlapping="shift"
                  dataSource={dataDash.pieChart}
                  customizePoint={(e) => customizePoint(e)}
                >
                  <Title text="สถานะงาน">
                    <Font size={18} weight={500} family="IBM Plex Sans Thai" />
                  </Title>
                  <PSeries argumentField="region">
                    <Label
                      visible={true}
                      position={"inside"}
                    >
                      <Connector visible={true} />
                      <Font size={14} weight={900} />
                    </Label>
                  </PSeries>
                  <Export enabled={false} />
                  <PLegend
                    margin={8}
                    verticalAlignment="bottom"
                    horizontalAlignment="center"
                    itemTextPosition="right"
                  >
                    <Font size={12} weight={500} family="IBM Plex Sans Thai" />
                  </PLegend>
                  <PTooltip
                    enabled={true}
                    customizeTooltip={(e) => customizeTooltip(e)}
                  >
                  </PTooltip>
                </PieChart>
              </div>
            </div>
          </div>
        </div>
      </FormLoading>
    </Card>
    <ModalAlert
      {...optionAlert}
      onOk={() => {
        setOptionAlert({ ...optionAlert, show: false })
      }}
      onCancel={() => {
        setOptionAlert({ ...optionAlert, show: false })
      }}
    />
  </div>
}))

export default Dashboard