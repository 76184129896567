import axios from 'axios'
import { BASE_URL } from '../AppConfig'
import IAuth from '../interfaces/Common/IAuth'

interface IMaintSearch {
  sdate: string
  edate: string
  vid?: string
  item_id?: string
  ma_status?: string
  alert_status?: string
}

export default {
  //#region Job Type Picture
  getJobTyePicture: async function (auth: IAuth) {
    try {
      const response = await axios.get(`${BASE_URL}/get_job_type_pic`, {
        headers: { action_user_id: auth?.action_user_id, token_id: auth?.token_id }
      })
      return response.data
    } catch (error) {
      throw error
    }
  },

  saveJobTyePicture: async function (auth: IAuth, data) {
    const response = await axios.put(`${BASE_URL}/set_job_type_pic`, data,
      {
        headers: { action_user_id: auth?.action_user_id, token_id: auth?.token_id }
      })
    return response
  },
  //#endregion

  //#region Picture Management
  getPictureType: async function (auth: IAuth) {
    try {
      const response = await axios.get(`${BASE_URL}/pic_type/list`, {
        headers: { action_user_id: auth?.action_user_id, token_id: auth?.token_id }
      })
      return response.data
    } catch (error) {
      throw error
    }
  },

  getPicture: async function (auth: IAuth) {
    try {
      const response = await axios.get(`${BASE_URL}/pic/list`, {
        headers: { action_user_id: auth?.action_user_id, token_id: auth?.token_id }
      })
      return response.data
    } catch (error) {
      throw error
    }
  },

  addPicture: async function (auth: IAuth, data) {
    const response = await axios.post(`${BASE_URL}/pic/add`, data,
      {
        headers: { action_user_id: auth?.action_user_id, token_id: auth?.token_id }
      })
    return response
  },

  updatePicture: async function (auth: IAuth, data) {
    const response = await axios.put(`${BASE_URL}/pic/update`, data,
      {
        headers: { action_user_id: auth?.action_user_id, token_id: auth?.token_id }
      })
    return response
  },

  deletePicture: async function (auth: IAuth, pic_id) {
    try {
      const response = await axios.delete(`${BASE_URL}/pic/delete?pic_id=${pic_id}`, {
        headers: {
          action_user_id: auth?.action_user_id, token_id: auth?.token_id
        }
      })
      return response.data
    } catch (error) {
      throw error
    }
  }
  //#endregion
}