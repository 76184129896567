import { t } from '../../helpers'
import { InputNumber } from 'antd'

export declare type IconPosition = 'start' | 'end';
export declare type roundedType = 'rounded' | 'full';
export declare type shapeType = 'circle';
export declare type SizeType = 'sm' | 'md' | 'lg';

type Props = {
    id?: string
    icon?: React.ReactNode;
    iconPosition?: IconPosition;
    label?: string
    labelHelp?: string
    labelOptional?: string
    disabled?: boolean
    defaultValue?: string
    backgroundColor?: string
    hiddenLabel?: boolean
    maxLength?: number
    min?: number
    max?: number
    required?: boolean
    size?: SizeType
    placeholder?: string
    validate?: { isError: boolean; message: string };
    width?: number | string
    onRef?: (ref: any) => void;
    onChange?: (value: any) => void;
}


export default (props: Props) => {
    let {
        id,
        icon,
        iconPosition = "start",
        label,
        labelHelp,
        labelOptional,
        disabled = false,
        defaultValue = "",
        backgroundColor = "",
        hiddenLabel = false,
        required = false,
        size = "md",
        maxLength = "",
        min,
        max,
        placeholder = "",
        validate = { isError: false, message: "" },
        width = "full",
        onRef,
        onChange
    }: any = props

    return (
        <div>
            <div className={`flex justify-between ${hiddenLabel && 'sr-only'}`}>
                <label htmlFor="email" className={`flex flex-row text-sm font-medium text-gray-700 dark:text-gray-200`}>
                    {t(label)}<span className={`text-red-500 ${!required && 'sr-only'}`}>*</span>

                    <p className={`text-sm italic  ml-2
                     ${validate.isError ? 'text-danger-600' : 'text-gray-500'}
                     ${!validate.isError && 'sr-only'}`}
                    >
                        {`${validate.isError && validate.message}`}
                    </p>
                </label>
            </div>
            <div className={`${icon && 'relative'} mt-1`}>
                <div className={`pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3 ${icon && iconPosition === "start" ? '' : 'sr-only'}`}>
                    {icon}
                </div>
                <InputNumber
                    min={min}
                    max={max}
                    value={defaultValue}
                    placeholder={placeholder}
                    required={required}
                    status={validate.isError ? "error" : ""}
                    style={{ width: "100%", margin: '-4px 0px -4px', backgroundColor }}
                    onChange={onChange}
                />
                <div className={`pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3 ${icon && iconPosition === "end" ? '' : 'sr-only'}`}>
                    {icon}
                </div>
            </div>
        </div>
    )
}