import { action, configure, observable, toJS, computed } from 'mobx';
import { persist } from 'mobx-persist'

configure({ enforceActions: 'always' });

export default class PriceModelStore {
  // @persist('list') @observable queueData = []
  // @persist('object') @observable focusDrop = { lat: "", lon: "" }
  @persist('object') @observable openModalPriceCopy: boolean = false
  @persist('object') @observable openModalPriceSet: boolean = false
  @persist('object') @observable openModalPriceModel: boolean = false
  @persist('object') @observable priceModelData = {}

  @computed
  get getPriceModelData() {
    return toJS(this.priceModelData)
  }

  @action.bound
  setPriceModeStore(objValue: any) {
    for (const [key, value] of Object.entries(objValue)) {
      this[key] = value
    }
  }

  @action
  reset() {
    this.openModalPriceCopy = false
    this.openModalPriceSet = false
    this.openModalPriceModel = false
  }
}