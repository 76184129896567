import { inject, observer } from 'mobx-react';

const NotificationHistory = inject("commonStore")(observer((props) => {
  return (
    <div className="flex justify-center" style={{ height: "calc(100vh - 80px)" }}>
      <div className="grid grid-cols-1 w-2/4 place-content-center">

      </div>
    </div>
  )
}))

export default NotificationHistory