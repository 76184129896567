import { useState, useRef, useEffect } from 'react'
import { inject, observer } from 'mobx-react';
import { Input, Card, Button, ModalAlert, FormLoading } from '../../components'
import { VehicleService } from '../../services';
import DataGrid, { SearchPanel, Scrolling, Item, Paging, Pager, Column, Toolbar, HeaderFilter } from 'devextreme-react/data-grid';
import { RefreshIcon, SearchIcon } from '@heroicons/react/outline';
import moment from 'moment'
import { isEmpty } from 'lodash';

let firstRender = false
const FuelPrice = inject("authenStore")(observer((props) => {
  let authenStore = props.authenStore
  let { getAccountAuth } = authenStore // STATE
  let auth = { action_user_id: getAccountAuth?.user_id, token_id: getAccountAuth?.access_token }

  const [loading, setLoading]: any = useState(false)
  const [dataSource, setDataSource]: any = useState([])
  const [optionAlert, setOptionAlert]: any = useState({
    show: false,
    action: {
      id: "",
      name: ""
    },
    type: "success",
    title: "",
    description: {
      visible: false,
      type: "",
      content: "",
      contentLists: [],

    }
  })

  const gridRef: any = useRef(null);
  let columns = [
    {
      dataField: 'effective_date',
      caption: "วันที่มีผลบังคับใช้"
    },
    {
      dataField: 'fuel_name',
      caption: "ชนิดน้ำมัน"
    },
    {
      dataField: 'price',
      caption: "ราคา"
    },
    {
      dataField: 'updated_date',
      caption: "วันที่นำเข้า"
    }
  ]

  //#region 

  useEffect(() => {
    if (firstRender || !isEmpty(getAccountAuth))
      getFuelPrice()
    else
      firstRender = true
  }, [getAccountAuth])

  const getFuelPrice = () => {
    setLoading(true)
    VehicleService.getFuelPrice(auth)
      .then((res: any) => {
        let { code, message, result, sys_message } = res
        if (code === 200) {
          setDataSource(result)
        }
        else {
          setOptionAlert({
            ...optionAlert,
            type: "danger",
            show: true,
            action: {
              id: "",
              name: ""
            },
            title: "ผิดพลาด",
            description: { visible: true, type: "text", content: `${message} ${sys_message}` }
          })
        }
        setLoading(false)
      })
      .catch((err) => {
        setLoading(false)
        setOptionAlert({
          ...optionAlert,
          type: "danger",
          show: true,
          action: {
            id: "",
            name: ""
          },
          title: "ผิดพลาด",
          description: { visible: true, type: "text", content: `${err}` }
        })
      })
  }
  //#endregion


  return (
    <div className="space-y-3 p-4">
      <Card title="ราคาน้ำมัน">
        <div className="mt-5 md:col-span-2 md:mt-0">
          <FormLoading loading={loading} >
            <div className="flex flex-row mb-2">
              <div className="flex flex-row space-x-2">
                <div className="flex flex-row">
                  <label className="text-sm font-medium text-gray-700 mt-3 mr-2 text-start">
                    ราคา ณ วันที่ : {moment().format('DD/MM/YYYY')}
                  </label>
                </div>

                <div className="flex flex-row">
                  <label className="text-sm font-medium text-gray-700 mt-3 text-start"> </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0 pt-1">
                    <Button
                      text="ค้นหา"
                      icon={<SearchIcon />}
                      size="sm"
                      type="white"
                      title="ค้นหา"
                      onClick={() => !loading && getFuelPrice()}
                    />
                  </div>
                </div>
              </div>

            </div>
            <DataGrid
              dataSource={dataSource}
              keyExpr={'id'}
              showBorders={true}
              columnAutoWidth={true}
              hoverStateEnabled={true}
            >
              <Scrolling columnRenderingMode="virtual" />
              <SearchPanel visible={false} />
              <Paging defaultPageSize={10} />
              <Pager
                visible={false}
                showInfo={true}
                showPageSizeSelector={true}
                allowedPageSizes={[10, 20, 50]}
              />
              <HeaderFilter visible={true} />
              {
                columns.map((column) => <Column
                  key={column.dataField}
                  allowFiltering={false}
                  dataField={column.dataField}
                  caption={column.caption}
                />)
              }

              <Toolbar visible={false}>
                <Item location="after">
                  <button
                    type="button"
                    className="-mb-1 inline-flex items-center px-1.5 py-1.5 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50"
                    title="Refresh"
                    onClick={() => { }}
                  >
                    <RefreshIcon className="w-4 h-4" />
                  </button>
                </Item>

                <Item location="after">
                  <button
                    type="button"
                    className="-mb-1 inline-flex items-center px-1.5 py-1.5 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50"
                    title="Export"
                  >
                    <img className="h-4 w-4" src="/Images/excel.png" />
                  </button>
                </Item>

                <Item location="after">
                  <Input
                    icon={<SearchIcon className="w-5 h-5 text-gray-500" />}
                    placeholder="ค้นหา..."
                    onChange={(e) => gridRef.current.instance.searchByText(e.target.value)}
                  />
                </Item>
              </Toolbar>
            </DataGrid>
          </FormLoading>
        </div>
      </Card >

      <ModalAlert
        {...optionAlert}
        onOk={() => {
          setOptionAlert({ ...optionAlert, show: false })
        }}
        onCancel={() => {
          setOptionAlert({ ...optionAlert, show: false })
        }}
      />
    </div>
  )
}))

export default FuelPrice