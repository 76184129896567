import axios from 'axios'
import { BASE_URL } from '../AppConfig'
import IAuth from '../interfaces/Common/IAuth'

export default {
  getDocuments: async function () {
    try {
      const response = await axios.get(`${BASE_URL}/queue_imp/documents`)
      return response.data
    } catch (error) {
      throw error
    }
  },

  getExcelTemplate: async function (auth: IAuth) {
    try {
      const response = await axios.get(`${BASE_URL}/queue_imp/excel`,
        {
          headers: {
            'content-type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            action_user_id: auth?.action_user_id,
            token_id: auth?.token_id
          }
        })
      return response.data
    } catch (error) {
      throw error
    }
  },


  getListImport: async function (auth: IAuth, imp_id) {
    try {
      const response = await axios.get(`${BASE_URL}/queue_imp/list?imp_id=${imp_id}`, {
        headers: { action_user_id: auth?.action_user_id, token_id: auth?.token_id }
      })
      return response.data
      // const response = await axios.get(`${BASE_URL}/queue_imp/list?imp_id=${imp_id}`,
      //   {
      //     headers: {
      //       'content-type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      //     }
      //   })
      // return response.data
    } catch (error) {
      throw error
    }
  },

  uploadExcel: async function (auth: IAuth, data) {
    const response = await axios.post(`${BASE_URL}/queue_imp/upload`, {
      employee_code: data.employee_code,
      username: data.username,
      password: data.password,
      first_name: data.first_name,
      last_name: data.last_name,
      email: data.email,
      phone_no: data.phone_no,
      position: data.position,
      user_status: data.user_status,
      user_group_id: data.user_group_id
    },
      {
        headers: { action_user_id: auth?.action_user_id, token_id: auth?.token_id }
      })
    return response
  },

  importQueue: async function (auth: IAuth, data) {
    const response = await axios.post(`${BASE_URL}/queue_imp/import`, {
      queues: data
    },
      {
        headers: { action_user_id: auth?.action_user_id, token_id: auth?.token_id }
      })
    return response
  },

  updateImport: async function (auth: IAuth, data) {
    const response = await axios.put(`${BASE_URL}/queue_imp/update`, {
      product_id: data.product_id,
      po_no: data.po_no,
      cust_id: data.cust_id,
      loc_receipt_id: data.loc_receipt_id,
      center_id: data.center_id,
      shipment_type: data.shipment_type,
      loc_dest_id: data.loc_dest_id,
      job_type_id: data.job_type_id,
      unit_id: data.unit_id,
      bill_cust_id: data.bill_cust_id,
      delivery_date: data.delivery_date,
      vid: data.vid,
      qty: data.qty,
      service_id: data.service_id,
      q_import_id: data.q_import_id,
      logistic_type_id: data.logistic_type_id,
      ref_order_no: data.ref_order_no,
      track_no: data.track_no
    },
      {
        headers: { action_user_id: auth?.action_user_id, token_id: auth?.token_id }
      })
    return response
  },


  deleteQueue: async function (auth: IAuth, q_import_id) {
    try {
      const response = await axios.delete(`${BASE_URL}/queue_imp/delete?q_import_id=${q_import_id}`, {
        headers: {
          action_user_id: auth?.action_user_id, token_id: auth?.token_id
        }
      })
      return response.data
    } catch (error) {
      throw error
    }
  },

}