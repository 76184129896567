import { useTranslation } from 'react-i18next'
declare const ButtonTypes: ["primary", "success", "danger", "warning", "info", "white", "circle"];
export declare type ButtonType = typeof ButtonTypes[number];
export declare type SizeType = 'sm' | 'md' | 'lg';
export declare type IconPosition = 'start' | 'end';
export declare type roundedType = 'rounded' | 'full';
export declare type shapeType = 'circle';

type Props = {
    id?: string
    type?: ButtonType;
    className?: any;
    secondary?: boolean;
    shape?: shapeType;
    text?: string
    title?: string
    rounded?: roundedType
    disabled?: boolean
    size?: SizeType
    loading?: boolean
    width?: number | string
    hidden?: boolean
    icon?: React.ReactNode;
    iconPosition?: IconPosition;
    iconOnly?: React.ReactNode;
    style?: React.CSSProperties;
    onClick?: React.MouseEventHandler<HTMLElement>;
}

export default (props: Props) => {
    const { t } = useTranslation()
    let {
        id,
        type = "primary",
        className = "",
        secondary = false,
        shape,
        text,
        title,
        rounded = "rounded",
        disabled = false,
        size = "md",
        loading = false,
        width = "w-auto",
        hidden = false,
        icon,
        iconPosition = "start",
        iconOnly,
        style,
        onClick
    }: any = props

    return (
        <div className={`${hidden && 'sr-only'} ${className}`}>
            <button
                id={id}
                type="button"
                style={style}
                title={title}
                className={`
                    inline-flex 
                    justify-center
                    align-middle
                    ${disabled && 'cursor-not-allowed'}
                    ${rounded === 'full' || shape === "circle" ? "rounded-full" : rounded}
                    mr-1
                    border ${type === "white" ? 'border-gray-300' : 'border-transparent'} 
                    ${type === "primary" ? 'bg-primary-500 hover:bg-primary-700 '
                        : type === "success" ? 'bg-success-500 hover:bg-success-700'
                            : type === "danger" ? 'bg-danger-500 hover:bg-danger-700'
                                : type === "warning" ? 'bg-warning-500 hover:bg-warning-700'
                                    : type === "info" ? 'bg-info-500 hover:bg-info-700'
                                        : type === "white" ? 'bg-white hover:bg-gray-200'
                                            : 'bg-primary-500 hover:bg-primary-700'
                    }
                    ${size === "sm" ? `${shape === "circle" ? "p-1" : "px-2 py-1"} text-xs`
                        : size === "md" ? `${shape === "circle" ? "p-2" : "px-2 py-1.5"} text-xs`
                            : size === "lg" ? `${shape === "circle" ? "p-3" : "px-2.5 py-1.5"} text-sm`
                                : `${shape === "circle" ? "p-3" : "px-2 py-1.5"} text-xs`
                    }
                    border ${type === "white" ? 'text-gray-700' : 'text-white'} 
                    font-medium  shadow-sm
                    ${width}
                `
                }
                onClick={(e) => !disabled && onClick && onClick(e)}
            >
                {
                    shape === "circle" ? icon
                        : <>
                            {
                                loading ? <div className="flex flex-row align-middle">
                                    <svg className={`animate-spin h-5 w-5 mr-1 ${type === "white" ? 'text-gray-400' : 'text-white'}`} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                    </svg>
                                    <span
                                        className={` ${size === "sm" ? 'mt-1' : size === "lg" ? 'mt-0' : 'mt-1'} `}>{t(text)}</span>
                                </div> :
                                    <>
                                        {
                                            iconOnly ? <div className="mt-0.5 h-4 w-4">{iconOnly}</div>
                                                : <>
                                                    {icon && iconPosition === "start" && <div className="-ml-0.5 mr-2 h-4 w-4">{icon}</div>}
                                                    <span className='mt-0.5'>{t(text)}</span>
                                                    {icon && iconPosition === "end" && <div className="ml-2 -mr-0.5 h-4 w-4">{icon}</div>}
                                                </>
                                        }
                                    </>
                            }
                        </>
                }
            </button>
        </div>
    )
}