import axios from 'axios'
import { BASE_URL } from '../AppConfig'
import IAuth from '../interfaces/Common/IAuth'

interface IMaintSearch {
  sdate: string
  edate: string
  vid?: string
  item_id?: string
  ma_status?: string
  alert_status?: string
}

export default {

  //#region Maintenance Master
  getMaintainVehicle: async function (auth: IAuth) {
    try {
      const response = await axios.get(`${BASE_URL}/maintain_vehicle/list`, {
        headers: { action_user_id: auth?.action_user_id, token_id: auth?.token_id }
      })
      return response.data
    } catch (error) {
      throw error
    }
  },

  updateMaintainVehicleStatus: async function (auth: IAuth, data) {
    const response = await axios.put(`${BASE_URL}/maintain_vehicle/status`, {
      vehicle_id: data.vehicle_id,
      ma_item_id: data.ma_item_id,
      status: data.status
    },
      {
        headers: { action_user_id: auth?.action_user_id, token_id: auth?.token_id }
      })
    return response
  },

  updateDriver: async function (auth: IAuth, data) {
    const response = await axios.put(`${BASE_URL}/service/update`, {
      service_id: data.service_id,
      sub_id: data.sub_id,
      citizen_id: data.citizen_id,
      service_code: data.service_code,
      code_ref: data.code_ref,
      first_name: data.first_name,
      last_name: data.last_name,
      nick_name: data.nick_name,
      position: data.position,
      department: data.department,
      phone_no: data.phone_no,
      status: data.status,
      start_date: data.start_date
    },
      {
        headers: { action_user_id: auth?.action_user_id, token_id: auth?.token_id }
      })
    return response
  },
  //#endregion

  //#region Maintenance
  getMaintenanceAlert: async function (auth: IAuth) {
    try {
      const response = await axios.get(`${BASE_URL}/maintenance/alert`, {
        headers: { action_user_id: auth?.action_user_id, token_id: auth?.token_id }
      })
      return response.data
    } catch (error) {
      throw error
    }
  },

  getMaintenance: async function (auth: IAuth, data: IMaintSearch) {
    try {
      let prmList: any = []
      for (const key in data) {
        if (data[key] !== "") prmList.push(`${key}=${data[key]}`)
      }
      let param = prmList.join('&');
      const response = await axios.get(`${BASE_URL}/maintenance/list?${param}`, {
        headers: { action_user_id: auth?.action_user_id, token_id: auth?.token_id }
      })
      // const response = await axios.get(`${BASE_URL}/maintenance/list`)
      return response.data
    } catch (error) {
      throw error
    }
  },

  addMaintenance: async function (auth: IAuth, data) {
    const response = await axios.post(`${BASE_URL}/maintenance/add`, data,
      {
        headers: { action_user_id: auth?.action_user_id, token_id: auth?.token_id }
      })
    return response
  },


  updateMaintenance: async function (auth: IAuth, data) {
    const response = await axios.put(`${BASE_URL}/maintenance/update`, data,
      {
        headers: { action_user_id: auth?.action_user_id, token_id: auth?.token_id }
      })
    return response
  },
  //#endregion
}