import axios from 'axios'
import { BASE_URL } from '../AppConfig'
import IAuth from '../interfaces/Common/IAuth'
import { isEmpty } from '../helpers/isEmpty'

interface IPriceModel {
  cust_id: number
  shipment_type: number
}

interface IPriceModelAdd {
  cust_price_id?: number
  cust_id: number
  zone_id?: number
  admin_level1?: number
  admin_level2?: number
  logistic_type_id: number
  product_id?: number
  unit_id?: number
  max_item?: number
  min_item?: number
  activate_date?: number
  status: number
  prices?: number
}

interface IPrice {
  cust_id: number
  logistic_type_id: number
  shipto_id: number
  product_id?: string
}


export default {
  getPriceModel: async function (auth: IAuth, data: IPriceModel) {
    try {
      const response = await axios.get(`${BASE_URL}/price_model/list?cust_id=${data.cust_id}&logistic_type=${data.shipment_type}`, {
        headers: { action_user_id: auth?.action_user_id, token_id: auth?.token_id }
      })
      return response.data
    } catch (error) {
      throw error
    }
  },

  savePriceModel: async function (auth: IAuth, data: IPriceModelAdd) {
    const response = await axios.post(`${BASE_URL}/price_model/add`, {
      cust_id: data.cust_id,
      zone_id: data.zone_id,
      admin_level1: data.admin_level1,
      admin_level2: data.admin_level2,
      logistic_type_id: data.logistic_type_id,
      product_id: data.product_id,
      unit_id: data.unit_id,
      max_item: data.max_item,
      min_item: data.min_item,
      activate_date: data.activate_date,
      status: data.status,
      prices: data.prices
    },
      {
        headers: { action_user_id: auth?.action_user_id, token_id: auth?.token_id }
      })
    return response
  },

  updatePriceModel: async function (auth: IAuth, data: IPriceModelAdd) {
    const response = await axios.put(`${BASE_URL}/price_model/update`, {
      cust_price_id: data.cust_price_id,
      cust_id: data.cust_id,
      zone_id: data.zone_id,
      admin_level1: data.admin_level1,
      admin_level2: data.admin_level2,
      logistic_type_id: data.logistic_type_id,
      product_id: data.product_id,
      unit_id: data.unit_id,
      max_item: data.max_item,
      min_item: data.min_item,
      activate_date: data.activate_date,
      status: data.status,
      prices: data.prices
    },
      {
        headers: { action_user_id: auth?.action_user_id, token_id: auth?.token_id }
      })
    return response
  },


  deletePriceModel: async function (auth: IAuth, cust_price_id) {
    try {
      const response = await axios.delete(`${BASE_URL}/price_model/delete`, {
        headers: {
          action_user_id: auth?.action_user_id, token_id: auth?.token_id
        },
        data: { cust_price_id }
      })
      return response.data
    } catch (error) {
      throw error
    }
  },

  savePriceCopy: async function (auth: IAuth, data) {
    const response = await axios.post(`${BASE_URL}/price_model/copy`, {
      from_cust: data.from_cust,
      to_cust: data.to_cust,
    },
      {
        headers: { action_user_id: auth?.action_user_id, token_id: auth?.token_id }
      })
    return response
  },

  getPriceSet: async function (auth: IAuth, cust_id = "", logistic_type_id = "") {
    try {
      let param = "";
      if (cust_id !== "") {
        param = `?cust_id=${cust_id}`
      }

      if (param === "") {
        if (logistic_type_id !== "") param += `?logistic_type_id=${logistic_type_id}`
      }
      else {
        if (logistic_type_id !== "") param += `&logistic_type_id=${logistic_type_id}`
      }

      const response = await axios.get(`${BASE_URL}/price_model/price_set_list${param}`, {
        headers: { action_user_id: auth?.action_user_id, token_id: auth?.token_id }
      })
      return response.data
    } catch (error) {
      throw error
    }
  },

  savePriceSet: async function (auth: IAuth, data) {
    const response = await axios.post(`${BASE_URL}/price_model/price_set_add`, {
      cust_id: data.cust_id,
      price_set_name: data.price_set_name,
      logistic_type_id: data.logistic_type_id
    },
      {
        headers: { action_user_id: auth?.action_user_id, token_id: auth?.token_id }
      })
    return response
  },

  getPrice: async function (auth: IAuth, data: IPrice) {
    try {
      let param = `?cust_id=${data.cust_id}&shipto_id=${data.shipto_id}&logistic_type=${data.logistic_type_id}`
      if (data.product_id !== "") param += `&product_id=${data.product_id}`

      const response = await axios.get(`${BASE_URL}/price_model/price${param}`, {
        headers: { action_user_id: auth?.action_user_id, token_id: auth?.token_id }
      })
      return response.data
    } catch (error) {
      throw error
    }
  },

  setDefault: async function (auth: IAuth, data) {
    const response = await axios.put(`${BASE_URL}/price_model/set_default`, {
      cust_id: data.cust_id,
      price_set_id: data.price_set_id,
      logistic_type_id: data.logistic_type_id
    },
      {
        headers: { action_user_id: auth?.action_user_id, token_id: auth?.token_id }
      })
    return response
  },
}