
import { useState, useEffect } from "react";
import './style.css'
import { inject, observer } from 'mobx-react';
import { Card, Button, Modal, Select, FormLoading, DatePicker, ModalAlert } from '../../components'
import { InputNumber } from 'antd';
import DataGrid, { SearchPanel, Scrolling, Paging, Pager, Column, Toolbar, HeaderFilter } from 'devextreme-react/data-grid';
import { CommonService, RouteService, RouteAutoService } from "../../services";
import moment from 'moment'
import { get, isEmpty } from 'lodash';
import ModalPlan from "../Plan/ModalPlan";
import Map from './Map'
import ModalCheck from "./ModalCheck";

let firstRender = false, isFirstLoad = false
const RouteSuggestion = inject("routeStore", "routeAutoStore", "authenStore", "commonStore")(observer((props) => {
  let { authenStore, routeStore, routeAutoStore, commonStore } = props
  let { setRouteStore } = routeStore // ACTIONS
  let { getAccountAuth } = authenStore // STATE
  let { setRouteAutoStore, getCheckModelInfo } = routeAutoStore // ACTIONS
  let { getExpandSidebar } = commonStore
  let expand = getExpandSidebar
  let auth = { action_user_id: getAccountAuth?.user_id, token_id: getAccountAuth?.access_token }

  const [loadingForm, setLoadingForm] = useState(false)
  const [loadingCond, setLoadingCond] = useState(false)
  const [loading, setLoading] = useState({ visible: false, tip: "กำลังโหลด" })
  const [queueData, setQueueData]: any = useState([])
  const [routeData, setRouteData]: any = useState([])
  const [shakeIsWrong, setShakeIsWrong] = useState(false)
  const [routeDrop, setRouteDrop]: any = useState({
    route_id: "",
    delivery_date: "",
    route_name: "",
    drops: [],
    multiRoutes: []
  })
  const [masterOptions, setMasterOptions] = useState({
    vehicle: [],
    service: [],
    subcontract: [],
    routeAutoFilter: []
  })
  const [validation, setValidation]: any = useState({})
  const [dataForm, setDataForm]: any = useState({
    show: false,
    route_id: "",
    vid: "",
    sub_id: "",
    service_id: ""
  })
  const [condForm, setCondForm]: any = useState({
    show: false,
    number_vehicle: 0,
    max_distance: 0,
    max_drop: 0,
    rest_time: 0,
    cond_id: 0,
    max_item: 0
  })
  const [condCurrent, setCondCurrent]: any = useState({
    number_vehicle: 0,
    max_distance: 0,
    max_drop: 0,
    rest_time: 0,
    cond_id: 0,
    max_item: 0
  })

  const [openPlan, setOpenPlan] = useState({
    show: false,
    action: "update"
  })
  const [filter, setFilter]: any = useState({
    delivery_date: moment().format("YYYY-MM-DD"),
    // delivery_date: "2024-04-18",
    filter_id: ""

  })
  const [filterCurrent, setFilterCurrent]: any = useState({
    delivery_date: moment().format("YYYY-MM-DD"),
    filter_id: ""
  })
  const [openModal, setOpenModal]: any = useState(false)
  const [optionAlert, setOptionAlert]: any = useState({
    show: false,
    action: {
      id: "",
      name: ""
    },
    type: "success",
    title: "",
    description: {
      visible: false,
      type: "",
      content: "",
      contentLists: [],

    }
  })

  const [multiRoute, setMultiRoute]: any = useState([])

  useEffect(() => {
    if (firstRender || !isEmpty(getAccountAuth)) {
      getCond()
      getShippoint()
      CommonService.getMasterData(auth, "vehicle,service,subcontract,route_auto_filter")
        .then((res: any) => {
          let vehicle = res.result.vehicle.map((item) => ({ label: item.licenseplate, value: item.vehicle_id }))
          let service = res.result.service.map((item) => ({ label: item.service_name, value: item.service_id }))
          let subcontract = res.result.subcontract.map((item) => ({ label: item.sub_name, value: item.sub_id }))
          let routeAutoFilter = res.result.route_auto_filter.map((item) => ({ label: item.desc, value: item.id }))

          setMasterOptions({
            ...masterOptions,
            vehicle,
            service,
            subcontract,
            routeAutoFilter
          })
        })
        .catch((err) => {
          setOptionAlert({
            ...optionAlert,
            type: "danger",
            show: true,
            action: {
              id: "",
              name: ""
            },
            title: "ผิดพลาด",
            description: { visible: true, content: `Load master data : ${err}` }
          })
        })
    }
    else firstRender = true
  }, [getAccountAuth])

  useEffect(() => { }, [getCheckModelInfo.stats])


  const searchDetail = async (isReload = false) => {
    setRouteAutoStore({ routeDrop: [], queueData: [] })
    setRouteDrop({
      route_id: "",
      delivery_date: "",
      route_name: "",
      drops: [],
      multiRoutes: []
    })
    setMultiRoute([])
    setLoading({ ...loading, visible: true, tip: 'กำลังโหลด...' })
    await getQueue(isReload)
    await getRoute(isReload)
    await checkModel(isReload)
    setTimeout(() => setLoading({ ...loading, visible: false, tip: 'กำลังโหลด...' }), 600);
  }

  const getQueue = (isReload = false) => {
    let delivery_date = "", filter_id = ""
    if (isReload) {
      delivery_date = filterCurrent.delivery_date
      filter_id = filterCurrent.filter_id
    }
    else {
      delivery_date = filter.delivery_date
      filter_id = filter.filter_id
    }

    setFilterCurrent({ ...filterCurrent, delivery_date, filter_id })
    RouteService.getQueue(auth, { delivery_date, filter_id })
      .then((res: any) => {
        let { code, message, sys_message, result } = res
        if (code === 200) {
          setQueueData(result)
          setRouteAutoStore({ queueData: result })
        }
        else {
          setOptionAlert({
            ...optionAlert,
            type: "danger",
            show: true,
            action: {
              id: "",
              name: ""
            },
            title: "ผิดพลาด",
            description: { visible: true, type: "text", content: `${message} ${sys_message}` }
          })
        }
      })
      .catch((err) => {
        setOptionAlert({
          ...optionAlert,
          type: "danger",
          show: true,
          action: {
            id: "",
            name: ""
          },
          title: "ผิดพลาด",
          description: { visible: true, type: "text", content: `${err}` }
        })
      })
  }

  const getCond = () => {
    RouteAutoService.getCond(auth)
      .then((res: any) => {
        let { code, message, sys_message, result } = res
        if (code === 200) {
          let { number_vehicle, max_distance, max_drop, rest_time, cond_id, max_item } = result
          setCondForm({
            ...condForm,
            show: false,
            number_vehicle,
            max_distance,
            max_drop,
            rest_time,
            cond_id,
            max_item
          })

          setCondCurrent({
            ...condForm,
            number_vehicle,
            max_distance,
            max_drop,
            rest_time,
            cond_id,
            max_item
          })
        }
        else {
          setOptionAlert({
            ...optionAlert,
            type: "danger",
            show: true,
            action: {
              id: "",
              name: ""
            },
            title: "ผิดพลาด",
            description: { visible: true, type: "text", content: `${message} ${sys_message}` }
          })
        }
      })
      .catch((err) => {
        setOptionAlert({
          ...optionAlert,
          type: "danger",
          show: true,
          action: {
            id: "",
            name: ""
          },
          title: "ผิดพลาด",
          description: { visible: true, type: "text", content: `${err}` }
        })
      })
  }

  const getRoute = (isReload = false) => {
    let delivery_date = isReload ? filterCurrent.delivery_date : filter.delivery_date

    setFilterCurrent({ ...filterCurrent, delivery_date })
    RouteService.getRoute(auth, { delivery_date, is_auto: "Y" })
      .then((res: any) => {
        let { code, message, sys_message, result } = res
        if (code === 200) {
          setRouteData(result)
          setRouteAutoStore({ routeData: result })
        }
        else {
          setOptionAlert({
            ...optionAlert,
            type: "danger",
            show: true,
            action: {
              id: "",
              name: ""
            },
            title: "ดึงข้อมูลผิดพลาด",
            description: { visible: true, type: "text", content: `${message} ${sys_message}` }
          })
        }

      })
      .catch((err) => {
        setOptionAlert({
          ...optionAlert,
          type: "danger",
          show: true,
          action: {
            id: "",
            name: ""
          },
          title: "ดึงข้อมูลผิดพลาด",
          description: { visible: true, type: "text", content: `webapi/route/list : ${err}` }
        })
      })
  }

  const checkModel = (isReload = false) => {
    RouteAutoService.routeAutoCheckModel(auth,
      {
        status: "Y",
        delivery_date: filter.delivery_date,
        ...condCurrent
      })
      .then((res: any) => {
        let { code, message, sys_message, result } = res?.data
        if (code === 200) {
          setRouteAutoStore({
            checkModelInfo: {
              status: result?.status || "",
              matrix: result?.matrix || []
            }
          })
        }
        else {
          setRouteAutoStore({
            checkModelInfo: {
              status: result?.status || "",
              matrix: result?.matrix || []
            }
          })
          setOptionAlert({
            ...optionAlert,
            type: "danger",
            show: true,
            action: {
              id: "",
              name: ""
            },
            title: "ผิดพลาด",
            description: { visible: true, type: "text", content: `${message} ${sys_message}` }
          })
          setLoading({ ...loading, visible: false })
        }
      })
      .catch((err) => {
        setLoading({ ...loading, visible: false })
        setOptionAlert({
          ...optionAlert,
          type: "danger",
          show: true,
          action: {
            id: "",
            name: ""
          },
          title: "ผิดพลาด",
          description: { visible: true, type: "text", content: `${err}` }
        })
      })

  }

  const clearData = () => {
    setDataForm({
      show: false,
      route_id: "",
      vid: "",
      sub_id: "",
      service_id: ""
    })
  }

  const onSave = () => {
    let validateCheck = {}, message = { isError: true, message: "โปรดระบุ" }

    if (dataForm.vid.length === 0 || dataForm.vid === "")
      validateCheck['vid'] = message
    if (dataForm.sub_id.length === 0 || dataForm.sub_id === "")
      validateCheck['sub_id'] = message
    if (dataForm.service_id.length === 0 || dataForm.service_id === "")
      validateCheck['service_id'] = message

    if (isEmpty(validateCheck)) {
      setValidation({})
      setOptionAlert({
        ...optionAlert,
        action: {
          id: dataForm.id,
          name: "save"
        },
        type: "confirm",
        title: "ยืนยัน",
        show: true,
        description: {
          visible: true,
          type: "text",
          content: "คุณแน่ใจที่จะบันทึกข้อมูล ?",
          contentLists: []
        }
      })
    }
    else {
      setValidation(validateCheck)
      setShakeIsWrong(true)
      setTimeout(() => setShakeIsWrong(false), 100);
    }
  }

  const getShippoint = () => {
    RouteAutoService.getShippoint(auth)
      .then((res: any) => {
        let { code, message, sys_message, result } = res
        if (code === 200) {
          setRouteAutoStore({ shippointDrop: result })
        }
        else {
          setOptionAlert({
            ...optionAlert,
            type: "danger",
            show: true,
            action: {
              id: "",
              name: ""
            },
            title: "ผิดพลาด",
            description: { visible: true, type: "text", content: `${message} ${sys_message}` }
          })
        }
      })
      .catch((err) => {
        setOptionAlert({
          ...optionAlert,
          type: "danger",
          show: true,
          action: {
            id: "",
            name: ""
          },
          title: "ผิดพลาด",
          description: { visible: true, type: "text", content: `${err}` }
        })
      })
  }

  const saveData = () => {
    setLoadingForm(true)
    RouteService.routeAssign(auth, {
      route_id: dataForm.route_id,
      vid: dataForm.vid,
      sub_id: dataForm.sub_id,
      service_id: dataForm.service_id,
    })
      .then((res: any) => {
        let { code, message, sys_message } = res.data
        if (code === 200) {
          setOptionAlert({
            ...optionAlert,
            type: "success",
            show: true,
            action: {
              id: "",
              name: ""
            },
            title: "บันทึกข้อมูลสำเร็จ",
            description: { visible: false, type: "text", content: " ", contentLists: [] }
          })
          clearData()
          searchDetail(true)
        }
        else {
          setOptionAlert({
            ...optionAlert,
            type: "danger",
            show: true,
            action: {
              id: "",
              name: ""
            },
            title: "ผิดพลาด",
            description: { visible: true, type: "text", content: `${message} ${sys_message}` }
          })
        }
        setLoadingForm(false)
      })
      .catch((err) => {
        setOptionAlert({
          ...optionAlert,
          type: "danger",
          show: true,
          action: {
            id: "",
            name: ""
          },
          title: "ผิดพลาด",
          description: { visible: true, type: "text", content: `${err}` }
        })
        setLoadingForm(false)
      })
  }

  const routeExecute = (isReload = false) => {
    setLoading({ ...loading, visible: true, tip: 'กำลังประมวลผล...' })
    let delivery_date = isReload ? filterCurrent.delivery_date : filter.delivery_date
    setFilterCurrent({ ...filterCurrent, delivery_date })

    RouteAutoService.routeAutoCheckModel(auth,
      {
        status: "Y",
        delivery_date,
        ...condCurrent
      })
      .then((res: any) => {
        let { code, message, sys_message, result } = res?.data
        if (code === 200) {
          let { flag_model, build_model_id } = result
          setRouteAutoStore({
            checkModelInfo: {
              status: result?.status || "",
              matrix: result?.matrix || []
            }
          })

          if (flag_model)
            routeExecute()
          else
            checkBuildStatus(build_model_id)
        }
        else {
          setRouteAutoStore({
            checkModelInfo: {
              status: result?.status || "",
              matrix: result?.matrix || []
            }
          })
          setOptionAlert({
            ...optionAlert,
            type: "danger",
            show: true,
            action: {
              id: "",
              name: ""
            },
            title: "ผิดพลาด",
            description: { visible: true, type: "text", content: `${message} ${sys_message}` }
          })
          setLoading({ ...loading, visible: false })
        }
      })
      .catch((err) => {
        setLoading({ ...loading, visible: false })
        setOptionAlert({
          ...optionAlert,
          type: "danger",
          show: true,
          action: {
            id: "",
            name: ""
          },
          title: "ผิดพลาด",
          description: { visible: true, type: "text", content: `${err}` }
        })
      })

    const routeExecute = () => {
      RouteAutoService.routeAutoExecute(auth,
        {
          status: "Y",
          delivery_date,
          ...condCurrent
        })
        .then((res: any) => {
          let { code, message, sys_message, result } = res?.data
          if (code === 200) {
            searchDetail(isReload)
            setLoading({ ...loading, visible: false })
          }
          else {
            setOptionAlert({
              ...optionAlert,
              type: "danger",
              show: true,
              action: {
                id: "",
                name: ""
              },
              title: "ผิดพลาด",
              description: { visible: true, type: "text", content: `${message} ${sys_message}` }
            })
            setLoading({ ...loading, visible: false })
          }
        })
        .catch((err) => {
          setLoading({ ...loading, visible: false })
          setOptionAlert({
            ...optionAlert,
            type: "danger",
            show: true,
            action: {
              id: "",
              name: ""
            },
            title: "ผิดพลาด",
            description: { visible: true, type: "text", content: `${err}` }
          })
        })
    }

    const checkBuildStatus = (build_model_id) => {
      RouteAutoService.checkBuildStatus(auth, build_model_id)
        .then((res: any) => {
          let { code, message, sys_message, result } = res
          if (code === 200) {

            if (result?.build_status === "Y")
              routeExecute()
            else {
              searchDetail(isReload)
              setLoading({ ...loading, visible: false })
            }
          }
          else {
            setOptionAlert({
              ...optionAlert,
              type: "danger",
              show: true,
              action: {
                id: "",
                name: ""
              },
              title: "ผิดพลาด",
              description: { visible: true, type: "text", content: `${message} ${sys_message}` }
            })
            setLoading({ ...loading, visible: false })
          }
        })
        .catch((err) => {
          setLoading({ ...loading, visible: false })
          setOptionAlert({
            ...optionAlert,
            type: "danger",
            show: true,
            action: {
              id: "",
              name: ""
            },
            title: "ผิดพลาด",
            description: { visible: true, type: "text", content: `${err}` }
          })
        })
    }
  }

  const setCond = () => {
    setLoadingCond(true)
    let { number_vehicle, max_distance, max_drop, rest_time, cond_id, max_item } = condForm
    RouteAutoService.setCond(auth, {
      number_vehicle,
      max_distance,
      max_drop,
      rest_time,
      cond_id,
      max_item
    })
      .then((res: any) => {
        let { code, message, sys_message } = res.data
        if (code === 200) {
          setOptionAlert({
            ...optionAlert,
            type: "success",
            show: true,
            action: {
              id: "",
              name: ""
            },
            title: "บันทึกข้อมูลสำเร็จ",
            description: { visible: false, type: "text", content: " ", contentLists: [] }
          })
          getCond()
        }
        else {
          setOptionAlert({
            ...optionAlert,
            type: "danger",
            show: true,
            action: {
              id: "",
              name: ""
            },
            title: "ผิดพลาด",
            description: { visible: true, type: "text", content: `${message} ${sys_message}` }
          })
        }
        setLoadingCond(false)
      })
      .catch((err) => {
        setLoadingCond(false)
        setOptionAlert({
          ...optionAlert,
          type: "danger",
          show: true,
          action: {
            id: "",
            name: ""
          },
          title: "ผิดพลาด",
          description: { visible: true, type: "text", content: `${err}` }
        })
      })
  }

  const deleteRoute = (route_ids) => {
    RouteService.deleteRoute(auth, route_ids)
      .then((res: any) => {
        let { code, message, sys_message } = res
        if (code === 200) {
          setOptionAlert({
            ...optionAlert,
            type: "success",
            show: true,
            action: {
              id: "",
              name: ""
            },
            title: "ลบข้อมูลสำเร็จ",
            description: { visible: false, type: "text", content: " ", contentLists: [] }
          })
          searchDetail(true)
        }
        else {
          setOptionAlert({
            ...optionAlert,
            type: "danger",
            show: true,
            action: {
              id: "",
              name: ""
            },
            title: "ผิดพลาด",
            description: { visible: true, type: "text", content: `${message} ${sys_message}` }
          })
        }
      })
      .catch((err) => {

      })
  }

  const setHeaderRoute = () => {
    let total_drop = 0
    routeData.map((dt) => total_drop += dt.total_drop)

    if (routeData.length === 0)
      return `ผลลัพธ์ (${routeData.length} เส้นทาง)`
    else
      return `ผลลัพธ์ (${routeData.length} เส้นทาง รวม ${total_drop} จุดจัดส่ง)`
  }

  const handleMultiRoute = (info) => {
    let { route_id, route_name }: any = info
    // console.log("multiRoute : ", multiRoute)

    const randomColor = () => {
      const getRandomRgbColor = () => {
        let r, g, b;
        do {
          r = Math.floor(Math.random() * 256);
          g = Math.floor(Math.random() * 256);
          b = Math.floor(Math.random() * 256);
        } while (isGrayOrWhite(r, g, b));
        return `rgb(${r}, ${g}, ${b})`;
      }

      const isGrayOrWhite = (r, g, b) => {
        const brightness = (r + g + b) / 3;
        return (Math.abs(r - g) < 30 && Math.abs(g - b) < 30 && Math.abs(b - r) < 30) || brightness > 200;
      }

      let isDup = false, lineColor = ""
      do {
        let genColor = getRandomRgbColor()
        let found = multiRoute.find((item) => item.lineColor === genColor)
        if (!found) {
          lineColor = genColor
          isDup = false
        }
        else {
          isDup = true
        }
      } while (isDup);

      return lineColor
    }

    let routeActive: any = []
    if (multiRoute.some(item => item.route_id === route_id)) {
      const newRoute = multiRoute.filter(item => item.route_id !== route_id);
      setMultiRoute(newRoute)
      routeActive = newRoute
    }
    else {
      let newRoute = [...multiRoute, {
        route_id,
        route_name,
        lineColor: randomColor()
      }]
      setMultiRoute(newRoute)
      routeActive = newRoute
    }
    return routeActive
  }

  return (
    <div className="space-y-3 p-3">
      <Card
        title="แนะนำเส้นทาง"
        contentWithoutPadding={true}
        extra={<div className="flex flex-row mb-2">
          <div className="flex flex-row space-x-2">
            {
              getCheckModelInfo.status !== "" && <div className="flex flex-row">
                <label className="text-sm font-medium text-gray-700 mt-3 mr-2 text-start">
                  สถานะการคำนวณ :  {["P", "N"].includes(getCheckModelInfo.status) ? <span className="text-red-600 bg-red-100 hover:bg-red-200 rounded px-2 cursor-pointer" onClick={() => setOpenModal(true)}>ไม่พร้อม</span> : <span className="text-green-600 bg-green-100 hover:bg-green-200 rounded  px-2 cursor-pointer" onClick={() => setOpenModal(true)}>พร้อม</span>}
                </label>
              </div>
            }

            <div className="flex flex-row">
              <label className="text-sm font-medium text-gray-700 mt-3 mr-2 text-start">
                ตรวจสอบข้อมูล :
              </label>
              <div className="mt-1 sm:col-span-2 sm:mt-0">
                <Select
                  allowClear={true}
                  showSearch={true}
                  defaultValue={filter.filter_id}
                  placeholder="ตรวจสอบข้อมูล"
                  width={150}
                  options={masterOptions.routeAutoFilter}
                  onChange={(value) => setFilter({ ...filter, filter_id: value })}
                />
              </div>
            </div>

            <div className="flex flex-row">
              <label className="text-sm font-medium text-gray-700 mt-3 mr-2 text-start">
                วันที่จัดส่ง :
              </label>
              <div className="mt-1 sm:col-span-2 sm:mt-0">
                <DatePicker
                  allowClear={false}
                  placeholder="วันที่จัดส่งสินค้า"
                  defaultValue={filter.delivery_date}
                  onChange={(d) => {
                    if (d) {
                      setFilter({ ...filter, delivery_date: d.format("YYYY-MM-DD") })
                    }
                    else {
                      setFilter({ ...filter, delivery_date: "" })
                    }
                  }}
                />
              </div>
            </div>

            <div className="flex flex-row">
              <label className="text-sm font-medium text-gray-700 mt-3 text-start"> </label>
              <div className="mt-1 sm:col-span-2 sm:mt-0 pt-1">
                <Button
                  text="แสดงรายละเอียด"
                  className="-ml-1.5"
                  size="md"
                  type="primary"
                  width="w-full"
                  iconPosition='start'
                  onClick={() => searchDetail()}
                />
              </div>
            </div>

            <div className="flex flex-row">
              <label className="text-sm font-medium text-gray-700 mt-3 text-start"> </label>
              <div className="mt-1 sm:col-span-2 sm:mt-0 pt-1">
                <Button
                  text="คำนวณเส้นทาง"
                  className="-ml-1.5"
                  size="md"
                  type="primary"
                  width="w-full"
                  iconPosition='start'
                  onClick={() => routeExecute(isFirstLoad ? true : false)}
                />
              </div>
            </div>

            <div className="flex flex-row">
              <label className="text-sm font-medium text-gray-700 mt-3 text-start"> </label>
              <div className="mt-1 sm:col-span-2 sm:mt-0 pt-1">
                <Button
                  text="กำหนดเงื่อนไข"
                  className="-ml-1.5"
                  size="md"
                  type="primary"
                  width="w-full"
                  iconPosition='start'
                  onClick={() => setCondForm({ ...condForm, show: true })}
                />
              </div>
            </div>
          </div>
        </div>
        }>
        <FormLoading loading={loading.visible} tip={loading.tip}>
          <div className="p-4">
            <div className="flex flex-row">
              {/* <div className="shrink-0 pr-2 dv-grid-left" style={{ width: 420 }}> */}
              {/* <SplitterLayout secondaryInitialSize={(window.innerWidth / 2) - (expand ? 140 : 48)}> */}
              <div className="shrink-0 pr-2 dv-grid-left">
                <div className="flex-1 flex-row space-x-2  border-b-2 border-gray-200 mb-2">
                  <span className="inline-flex items-center rounded py-0.5 text-sm font-medium text-blue-800">
                    {`ใบงาน (${queueData.length} จุดจัดส่ง)`}
                  </span>
                </div>
                <DataGrid
                  dataSource={queueData}
                  keyExpr={'id'}
                  showBorders={true}
                  columnAutoWidth={true}
                  hoverStateEnabled={true}
                  height={444}
                  style={{ zoom: 0.9 }}
                  onRowClick={(e) => {
                    let { id, lat, lon }: any = e.data
                    setRouteAutoStore({ focusDrop: { id, lat, lon } })
                  }}
                >
                  <Scrolling columnRenderingMode="virtual" />
                  <SearchPanel visible={false} />
                  <Paging enabled={false} />
                  <HeaderFilter visible={true} />
                  <Column
                    key="shipto_name"
                    allowFiltering={false}
                    dataField="shipto_name"
                    caption="ปลายทาง/สาขา"
                  />

                  <Column
                    key="total_qty"
                    allowFiltering={false}
                    dataField="total_qty"
                    caption="จำนวน"
                  />

                  <Column
                    key="total_dimension"
                    allowFiltering={false}
                    dataField="total_dimension"
                    caption="ปริมาตร (ลบ.ม.)"
                  />

                  <Column
                    key="total_weight"
                    allowFiltering={false}
                    dataField="total_weight"
                    caption="น้ำหนัก (กก.)"
                  />

                  <Column
                    key="zone_name"
                    allowFiltering={false}
                    dataField="zone_name"
                    caption="โซน"
                  />

                  <Toolbar visible={false} />
                </DataGrid>
              </div>
              <div className="flex-1 w-80">
                <div className="flex-1 flex-row space-x-2  border-b-2 border-gray-200 mb-2">
                  <span className="inline-flex items-center rounded py-0.5 text-sm font-medium text-blue-800">
                    {`แผนที่`}
                  </span>
                </div>
                <div style={{ height: 400, width: '100%' }}>
                  <Map showGeoserver={true} />
                </div>
              </div>
              {/* </SplitterLayout> */}
              <div className="shrink-0 w-72 px-2">
                <div className="flex-1 flex-row space-x-2  border-b-2 border-gray-200 mb-2">
                  <span className="inline-flex items-center rounded py-0.5 text-sm font-medium text-blue-800">
                    {setHeaderRoute()}
                  </span>
                </div>
                <div className="overflow-y-auto space-y-2" style={{ height: 400 }}>
                  {
                    routeData.map((dt, idx) => {
                      let { no, route_id, delivery_date, route_name, total_dimension, total_weight, total_drop, total_distance, total_time, drops, status, total_qty }: any = dt

                      let tabColor = "", checked = false, isActiveInfo = false
                      let selRoute = multiRoute.find((item) => item.route_id === route_id)
                      if (selRoute) {
                        tabColor = selRoute?.lineColor
                        checked = true
                      }
                      if (routeDrop?.route_id === route_id) isActiveInfo = true

                      return <div
                        key={`route_${idx}`}
                        style={{ backgroundColor: isActiveInfo ? '#e2fbff' : "" }}
                        className={`divide-y divide-gray-400 rounded-lg border border-gray-400  cursor-pointer hover:bg-gray-100`} >
                        <div className="group flex items-center justify-between space-x-3  p-2 py-0.5 border-transparent"
                          onClick={() => {
                            let multiRoutes = handleMultiRoute(dt)
                            // console.log("> multiRoutes : ", multiRoutes)

                            setRouteDrop({
                              route_id,
                              route_name,
                              delivery_date,
                              drops,
                              multiRoutes
                            })

                            setRouteAutoStore({
                              routeDrop: {
                                route_id,
                                route_name,
                                delivery_date,
                                drops,
                                multiRoutes
                              },
                              focusDrop: { lat: "", lon: "" },
                              focusDropRoute: { lat: "", lon: "" }
                            })
                          }}>
                          <span className="flex min-w-0 flex-1 items-center space-x-3">
                            <span className="block min-w-0 flex-1 ">
                              <span className="flex flex-row truncate text-xs">
                                <input
                                  type="checkbox"
                                  className="h-4 w-4 rounded mr-1"
                                  style={{ backgroundColor: tabColor }}
                                  checked={checked}
                                  onClick={() => handleMultiRoute(dt)}
                                /> ลำดับ : <span className="ml-1 font-semibold">{no}</span></span>
                              <span className="flex flex-row truncate text-xs">เส้นทาง :<span className="ml-1 font-semibold truncate" title={route_name}>{route_name}</span></span>
                              <span className="flex flex-row truncate text-xs">น้ำหนักรวม :<span className="ml-1 font-semibold truncate">{total_weight} กก.</span></span>
                              <span className="flex flex-row truncate text-xs">ระยะทาง :<span className="ml-1 font-semibold truncate">{total_distance} กม.</span></span>
                            </span>
                          </span>

                          <span className="flex min-w-0 flex-1 items-center space-x-3">
                            <span className="block min-w-0 flex-1 ">
                              {/* <span className="flex flex-row truncate text-xs mt-4" /> */}
                              <span className="flex flex-row truncate text-xs">จำนวน :<span className="ml-1 font-semibold truncate">{total_qty}</span></span>
                              <span className="flex flex-row truncate text-xs">ปริมาตรรวม :<span className="ml-1 font-semibold truncate">{total_dimension} ลบ.ม.</span></span>
                              <span className="flex flex-row truncate text-xs">จุดจัดส่ง :<span className="ml-1 font-semibold truncate">{total_drop}</span></span>
                              <span className="flex flex-row truncate text-xs">เวลา :<span className="ml-1 font-semibold truncate">{total_time} ชม.</span></span>
                            </span>
                          </span>
                        </div>

                        {
                          status === "10" && <div className="flex flex-row">
                            <div className="flex-1">
                              <button
                                type="button"
                                className=" inline-flex justify-center align-middle bg-success-600 hover:bg-success-700 px-2 py-1 text-xs 
rounded-bl-lg text-white font-medium shadow-sm w-full"
                                onClick={(e) => setDataForm({ ...dataForm, show: true, route_id })}
                              >
                                <span className='mt-0.5'>ยืนยัน</span>
                              </button>
                            </div>

                            <div className="flex-1">
                              <button
                                type="button"
                                className=" inline-flex justify-center align-middle bg-warning-500 hover:bg-warning-700 px-2 py-1 text-xs  text-white font-medium shadow-sm w-full"
                                onClick={(e) => {
                                  setOpenPlan({ show: true, action: "update" })
                                  setRouteStore({ dataPlanEdit: dt })
                                }}
                              >
                                <span className='mt-0.5'>แก้ไข</span>
                              </button>
                            </div>

                            <div className="flex-1">
                              <button
                                type="button"
                                className=" inline-flex justify-center align-middle bg-danger-500 hover:bg-danger-700 px-2 py-1 text-xs 
rounded-br-lg text-white font-medium shadow-sm w-full"
                                onClick={() => {
                                  setOptionAlert({
                                    ...optionAlert,
                                    action: {
                                      id: dt?.route_id,
                                      name: 'deleteRoute'
                                    },
                                    type: "confirm",
                                    title: "ยืนยัน",
                                    show: true,
                                    description: {
                                      visible: true,
                                      type: "text",
                                      content: "คุณแน่ใจที่จะลบข้อมูล ?",
                                      contentLists: []
                                    }
                                  })
                                }}
                              >
                                <span className='mt-0.5'>ลบ</span>
                              </button>
                            </div>
                          </div>
                        }
                      </div>
                    })
                  }
                </div>
              </div>
            </div>

            <div className="flex flex-col mt-4">
              <div className="flex-1 border-b-2 border-gray-200 mb-2">
                <span className="inline-flex items-center rounded py-0.5 text-sm font-medium text-blue-800">
                  {`รายละเอียดเส้นทาง ${routeDrop.route_name}`}
                </span>
              </div>
              <div className="flex-1">
                <DataGrid
                  dataSource={routeDrop.drops}
                  keyExpr={'route_id'}
                  showBorders={true}
                  columnAutoWidth={true}
                  hoverStateEnabled={true}
                  height={"100%"}
                  style={{ zoom: 0.9 }}
                  onRowClick={(e) => {
                    let { id, lat, lon }: any = e.data
                    setRouteAutoStore({ focusDropRoute: { lat, lon } })
                  }}
                >
                  <Scrolling columnRenderingMode="virtual" />
                  <SearchPanel visible={false} />
                  <Pager visible={false} />
                  <HeaderFilter visible={true} />
                  <Column
                    key="seq"
                    allowFiltering={false}
                    dataField="seq"
                    caption="ลำดับจุดจัดส่ง"
                    width="100"
                    alignment="center"
                  />

                  <Column
                    key="shipto_name"
                    allowFiltering={false}
                    dataField="shipto_name"
                    caption="ปลายทาง/สาขา"
                  />

                  <Column
                    key="zone_name"
                    allowFiltering={false}
                    dataField="zone_name"
                    caption="โซน"
                  />

                  <Column
                    key="delivery_date"
                    allowFiltering={false}
                    dataField="delivery_date"
                    caption="วันที่จัดส่ง"
                  />

                  <Column
                    key="zone_name"
                    allowFiltering={false}
                    dataField="zone_name"
                    caption="ช่วงเวลาส่ง"
                    cellRender={(e) => {
                      let dt = e.data
                      return `${dt.start_time}-${dt.end_time}`
                    }}
                  />

                  <Column
                    key="way"
                    allowFiltering={false}
                    dataField="way"
                    caption="ระยะทาง (A -> B)"
                  />

                  <Column
                    key="way_time"
                    allowFiltering={false}
                    dataField="way_time"
                    caption="เวลาเดินทาง"
                  />

                  <Column
                    key="total_dimension"
                    allowFiltering={false}
                    dataField="total_dimension"
                    caption="ปริมาตร"
                  />

                  <Column
                    key="total_weight"
                    allowFiltering={false}
                    dataField="total_weight"
                    caption="น้ำหนัก"
                  />

                  <Column
                    key="total_product"
                    allowFiltering={false}
                    dataField="total_product"
                    caption="จำนวนสินค้า"
                  />

                  <Column
                    key="remark"
                    allowFiltering={false}
                    dataField="remark"
                    caption="รายละเอียด"
                  />

                  <Toolbar visible={false} />
                </DataGrid>
              </div>
            </div>
            {/* <div className="space-y-4" style={{ height: 'calc(100vh - 178px)' }}>

        </div> */}
          </div>
        </FormLoading>
      </Card >

      <ModalPlan
        auth={auth}
        open={openPlan.show}
        action={openPlan.action}
        onReloadData={(reloadQueue) => searchDetail(reloadQueue)}
        onClosed={() => setOpenPlan({ ...openPlan, show: false })}
      />

      <Modal
        title={{ text: "ระบุรายละเอียดเพิ่มเติม" }}
        open={dataForm.show}
        maskClosable={true}
        contentPadding="p-0"
        shake={shakeIsWrong}
        onClosed={() => clearData()}
        onOk={() => onSave()}
      >
        <FormLoading loading={loadingForm} tip="กำลังบันทึก...">
          <div className="space-y-2" style={{ padding: 20 }}>
            <div className="flex flex-col space-y-2">
              <div className='flex-1'>
                <Select
                  allowClear={false}
                  showSearch={true}
                  required={true}
                  defaultValue={dataForm.vid}
                  label="ทะเบียน"
                  placeholder="ทะเบียน"
                  validate={validation['vid']}
                  options={masterOptions.vehicle}
                  onChange={(vid) => setDataForm({ ...dataForm, vid })}
                />
              </div>

              <div className='flex-1'>
                <Select
                  allowClear={false}
                  showSearch={true}
                  required={true}
                  defaultValue={dataForm.sub_id}
                  label="ผู้รับจ้างช่วง"
                  placeholder="ผู้รับจ้างช่วง"
                  validate={validation['sub_id']}
                  options={masterOptions.subcontract}
                  onChange={(sub_id) => setDataForm({ ...dataForm, sub_id })}
                />
              </div>

              <div className='flex-1'>
                <Select
                  allowClear={false}
                  showSearch={true}
                  required={true}
                  defaultValue={dataForm.service_id}
                  label="พนักงานขับรถ"
                  placeholder="พนักงานขับรถ"
                  validate={validation['service_id']}
                  options={masterOptions.service}
                  onChange={(service_id) => setDataForm({ ...dataForm, service_id })}
                />
              </div>
            </div>
          </div>
        </FormLoading>
      </Modal>

      <Modal
        title={{ text: "กำหนดเงื่อนไข" }}
        open={condForm.show}
        maskClosable={true}
        contentPadding="p-0"
        width="lg:w-2/5"
        shake={shakeIsWrong}
        onClosed={() => {
          let { number_vehicle, max_distance, max_drop, rest_time, cond_id } = condCurrent
          setCondForm({ ...condForm, show: false, number_vehicle, max_distance, max_drop, rest_time, cond_id })
        }}
        onOk={() => setOptionAlert({
          ...optionAlert,
          action: {
            id: "",
            name: "setcond"
          },
          type: "confirm",
          title: "ยืนยัน",
          show: true,
          description: {
            visible: true,
            type: "text",
            content: "คุณแน่ใจที่จะบันทึกข้อมูล ?",
            contentLists: []
          }
        })}
      >
        <FormLoading loading={loadingCond} tip="กำลังบันทึก...">
          <div className="space-y-2" style={{ padding: 20 }}>
            <div className="flex flex-col space-y-2">
              <div className="flex-1 sm:grid sm:grid-cols-4 sm:items-end sm:gap-2">
                <label className="block text-sm font-medium text-gray-700 mb-1 text-right">
                  จำนวนรถ
                </label>
                <div className="mt-1 sm:col-span-2 sm:mt-0">
                  <InputNumber
                    min={0}
                    value={condForm.number_vehicle}
                    placeholder="จำนวนรถ"
                    style={{ width: "100%" }}
                    onChange={(value) => setCondForm({ ...condForm, number_vehicle: value | 0 })}
                  />
                </div>
              </div>

              <div className="flex-1 sm:grid sm:grid-cols-4 sm:items-end sm:gap-2">
                <label className="block text-sm font-medium text-gray-700 lg:mb-6  text-right">
                  ระยะทางสูงสุด
                </label>
                <div className="mt-1 sm:col-span-2 sm:mt-0">
                  <div className="flex flex-col">
                    <InputNumber
                      min={0}
                      value={condForm.max_distance}
                      placeholder="ระยะทางสูงสุด"
                      style={{ width: "100%" }}
                      onChange={(value) => setCondForm({ ...condForm, max_distance: value | 0 })}
                    />
                    <span className="text-xs mt-1">{`(ระยะทางระหว่างจุดจัดส่ง ไม่เกินจากที่กำหนด)`}</span>
                  </div>
                </div>
                <label className="block text-sm font-medium text-gray-700 mb-6">
                  กิโลเมตร
                </label>
              </div>

              <div className="flex-1 sm:grid sm:grid-cols-4 sm:items-end sm:gap-2">
                <label className="block text-sm font-medium text-gray-700 mb-1 text-right">
                  จำนวนงานสูงสุด
                </label>
                <div className="mt-1 sm:col-span-2 sm:mt-0">
                  <InputNumber
                    min={0}
                    value={condForm.max_drop}
                    placeholder="จำนวนงานสูงสุด"
                    style={{ width: "100%" }}
                    onChange={(value) => setCondForm({ ...condForm, max_drop: value | 0 })}
                  />
                </div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  ต่อเที่ยว
                </label>
              </div>

              <div className="flex-1 sm:grid sm:grid-cols-4 sm:items-end sm:gap-2">
                <label className="block text-sm font-medium text-gray-700 mb-1 text-right">
                  เวลาหยุดพัก
                </label>
                <div className="mt-1 sm:col-span-2 sm:mt-0">
                  <InputNumber
                    min={0}
                    value={condForm.rest_time}
                    placeholder="เวลาหยุดพัก"
                    style={{ width: "100%" }}
                    onChange={(value) => setCondForm({ ...condForm, rest_time: value | 0 })}
                  />
                </div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  เวลาหยุดพักของ พขร.
                </label>
              </div>

              <div className="flex-1 sm:grid sm:grid-cols-4 sm:items-end sm:gap-2">
                <label className="block text-sm font-medium text-gray-700 mb-1 text-right">
                  จำนวนต่อเที่ยว
                </label>
                <div className="mt-1 sm:col-span-2 sm:mt-0">
                  <InputNumber
                    min={0}
                    value={condForm.max_item}
                    placeholder="จำนวนต่อเที่ยว"
                    style={{ width: "100%" }}
                    onChange={(value) => setCondForm({ ...condForm, max_item: value | 0 })}
                  />
                </div>
              </div>
            </div>
          </div>
        </FormLoading>
      </Modal>

      <ModalCheck open={openModal} onClosed={() => setOpenModal(false)} />
      <ModalAlert
        {...optionAlert}
        onOk={() => {
          let { action } = optionAlert

          if (action.name === "save") saveData()
          if (action.name === "setcond") setCond()
          if (action.name === "deleteRoute") deleteRoute(action.id)

          setOptionAlert({ ...optionAlert, show: false })
        }}
        onCancel={() => {
          setOptionAlert({ ...optionAlert, show: false })
        }}
      />
    </div >
  )
}))

export default RouteSuggestion