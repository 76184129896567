
import { useState, useRef, useEffect } from 'react'
import { createMultipleRef, getValueInputRef, generateString } from '../../../helpers'
import { Input, Modal, Button, ModalAlert, Select, FormLoading, DatePicker } from '../../../components'
import { CustomerUserService } from '../../../services';
import { isEmpty } from 'lodash'
import { KeyIcon } from '@heroicons/react/solid';

const ModalForm = (props) => {
  let { masterOptions, open, data, auth } = props // STATES
  let { onClosed } = props // ACTIONS

  const [loading, setLoading]: any = useState({ visible: false, tip: 'กำลังบันทึก...' })
  const [validation, setValidation]: any = useState({})
  const [shakeIsWrong, setShakeIsWrong] = useState(false)
  const [optionAlert, setOptionAlert]: any = useState({
    show: false,
    action: {
      id: "",
      name: ""
    },
    type: "success",
    title: "",
    description: {
      visible: false,
      type: "",
      content: "",
      contentLists: []
    }
  })

  const [dataForm, setDataForm]: any = useState({
    "mode": "add",
    "cust_id": "",
    "shipto_id": "",
    "username": "",
    "passwd": "",
    "status": "Y",
    "expire_date": ""
  })

  const inputRef: any = useRef([]);
  createMultipleRef(inputRef, 1)

  useEffect(() => {
    if (!isEmpty(data)) {
      let dataForm = {
        "mode": "update",
        "id": data?.id,
        "cust_id": data?.cust_id,
        "shipto_id": data?.shipto_id,
        "username": data?.username,
        "passwd": data?.passwd,
        "status": data?.status,
        "expire_date": data?.expire_date
      }
      setDataForm(dataForm)
    }
  }, [open, data])

  const initialData = () => {
    setValidation({})
    setDataForm({
      "mode": "add",
      "cust_id": "",
      "shipto_id": "",
      "username": "",
      "passwd": "",
      "status": "Y",
      "expire_date": ""
    })
  }

  const onSave = () => {
    let validateCheck = {}, message = { isError: true, message: "โปรดระบุ" }
    let formData = getValueInputRef(inputRef)

    if (dataForm.cust_id.length === 0 || dataForm.cust_id === "")
      validateCheck['cust_id'] = message
    if (formData.username.trim() === "")
      validateCheck['username'] = message
    if (dataForm.passwd.trim() === "")
      validateCheck['passwd'] = message
    if (dataForm.status.length === 0 || dataForm.status === "")
      validateCheck['status'] = message

    if (isEmpty(validateCheck)) {
      setValidation({})
      setOptionAlert({
        ...optionAlert,
        action: {
          id: dataForm.id,
          name: "save"
        },
        type: "confirm",
        title: "ยืนยัน",
        show: true,
        description: {
          visible: true,
          type: "text",
          content: "คุณแน่ใจที่จะบันทึกข้อมูล ?",
          contentLists: []
        }
      })
    }
    else {
      setValidation(validateCheck)
      setShakeIsWrong(true)
      setTimeout(() => setShakeIsWrong(false), 100);
    }
  }

  const saveData = () => {
    let formData = getValueInputRef(inputRef)

    let data: any = {
      "cust_id": dataForm.cust_id,
      "shipto_id": dataForm.shipto_id,
      "username": formData.username,
      "passwd": dataForm.passwd,
      "expire_date": dataForm.expire_date,
      "status": dataForm.status
    }

    if (dataForm.mode === "update") data.id = dataForm?.id

    setLoading({ ...loading, visible: true, tip: 'กำลังบันทึก...' })
    if (dataForm.mode === "add") {
      CustomerUserService.addCustomerUser(auth, data)
        .then((res: any) => {
          let { code, message, sys_message } = res.data

          if (code === 200) {
            setOptionAlert({
              ...optionAlert,
              type: "success",
              show: true,
              action: {
                id: "",
                name: ""
              },
              title: "บันทึกข้อมูลสำเร็จ",
              description: { visible: false, type: "text", content: " ", contentLists: [] }
            })
            initialData()
            onClosed(true)
          }
          else {
            setOptionAlert({
              ...optionAlert,
              type: "danger",
              show: true,
              action: {
                id: "",
                name: ""
              },
              title: "ผิดพลาด",
              description: { visible: true, type: "text", content: `${message} ${sys_message}` }
            })
          }
          setLoading({ ...loading, visible: false })
        })
        .catch((err) => {
          setOptionAlert({
            ...optionAlert,
            type: "danger",
            show: true,
            action: {
              id: "",
              name: ""
            },
            title: "ผิดพลาด",
            description: { visible: true, type: "text", content: `${err}` }
          })
          setLoading({ ...loading, visible: false })
        })
    }
    else {
      CustomerUserService.updateCustomerUser(auth, data)
        .then((res: any) => {
          let { code, message, sys_message } = res.data

          if (code === 200) {
            setOptionAlert({
              ...optionAlert,
              type: "success",
              show: true,
              action: {
                id: "",
                name: ""
              },
              title: "บันทึกข้อมูลสำเร็จ",
              description: { visible: false, type: "text", content: " ", contentLists: [] }
            })
            initialData()
            onClosed(true)
          }
          else {
            setOptionAlert({
              ...optionAlert,
              type: "danger",
              show: true,
              action: {
                id: "",
                name: ""
              },
              title: "ผิดพลาด",
              description: { visible: true, type: "text", content: `${message} ${sys_message}` }
            })
          }
          setLoading({ ...loading, visible: false })
        })
        .catch((err) => {
          setOptionAlert({
            ...optionAlert,
            type: "danger",
            show: true,
            action: {
              id: "",
              name: ""
            },
            title: "ผิดพลาด",
            description: { visible: true, type: "text", content: `${err}` }
          })
          setLoading({ ...loading, visible: false })
        })
    }
  }

  return (<>
    <Modal
      title={{ text: `${dataForm.mode === "add" ? 'เพิ่ม' : 'แก้ไข'}ผู้ใช้งาน` }}
      open={open}
      maskClosable={true}
      shake={shakeIsWrong}
      onClosed={() => {
        onClosed()
        initialData()
      }}
      onOk={() => onSave()}
    >
      <FormLoading loading={loading.visible} tip={loading.tip}>
        <div className="flex flex-col space-y-2">
          <div className='flex-1'>
            <Select
              showSearch={true}
              label="ลูกค้า"
              placeholder="ลูกค้า"
              required={true}
              onChange={(select) => setDataForm({ ...dataForm, cust_id: select })}
              defaultValue={dataForm.cust_id}
              validate={validation?.cust_id}
              options={masterOptions.customer}
            />
          </div>

          <div className='flex-1'>
            <Select
              showSearch={true}
              allowClear={true}
              label="สาขา"
              placeholder="สาขา"
              onChange={(select) => setDataForm({ ...dataForm, shipto_id: select })}
              defaultValue={dataForm.shipto_id}
              options={masterOptions.shipto}
            />
          </div>

          <div className='flex-1'>
            <Input
              id="username"
              label="ชื่อผู้ใช้งาน"
              placeholder="ชื่อผู้ใช้งาน"
              required={true}
              defaultValue={dataForm.username}
              validate={validation?.username}
              onRef={(ref) => inputRef.current[0] = ref}
            />
          </div>

          <div className='flex flex-row'>
            <div className="flex-1">
              <Input
                id="passwd"
                label="รหัสผ่าน"
                placeholder="รหัสผ่าน"
                disabled={true}
                required={true}
                defaultValue={dataForm.passwd}
                validate={validation?.passwd}
              />
            </div>

            <Button
              text="สร้าง"
              style={{ margin: '24px 0px 0px 4px' }}
              icon={<KeyIcon />}
              iconPosition='start'
              onClick={() => {
                let passwd = generateString(5)
                setDataForm({ ...dataForm, passwd })
              }}
            />
          </div>

          <div className='flex-1'>
            <Select
              label="สถานะ"
              placeholder="สถานะ"
              required={true}
              onChange={(select) => setDataForm({ ...dataForm, status: select })}
              defaultValue={dataForm.status}
              validate={validation?.status}
              options={masterOptions.common_status}
            />
          </div>

          <div className='flex-1'>
            <DatePicker
              id="expire_date"
              label="วันหมดอายุ"
              placeholder="วันหมดอายุ"
              defaultValue={dataForm.expire_date}
              onChange={(d) => {
                if (d)
                  setDataForm({ ...dataForm, expire_date: d.format("YYYY-MM-DD") })
                else
                  setDataForm({ ...dataForm, expire_date: "" })
              }}
            />
          </div>
        </div>
      </FormLoading>
    </Modal>

    <ModalAlert
      {...optionAlert}
      onOk={() => {
        let { type, action } = optionAlert
        if (action.name === "save") saveData()
        setOptionAlert({ ...optionAlert, show: false })
      }}
      onCancel={() => {
        setOptionAlert({ ...optionAlert, show: false })
      }}
    />
  </>
  )
}

export default ModalForm