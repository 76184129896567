import { useEffect, useState } from 'react'
import { inject, observer } from 'mobx-react';
import FormMaster from '../FormMaster'
import { CommonService } from "../../../services";
import { isEmpty } from 'lodash';

let firstRender = false
const MaintenanceTypeUnit = inject("authenStore")(observer((props) => {
  let authenStore = props.authenStore
  let { getAccountAuth } = authenStore // STATE
  let auth = { action_user_id: getAccountAuth?.user_id, token_id: getAccountAuth?.access_token }

  const [optionStatus, setOptionStatus] = useState([])

  useEffect(() => {
    if (firstRender || !isEmpty(getAccountAuth)) {
      CommonService.getMasterData(auth, "common_status")
        .then((res: any) => {
          let common_status = res.result.common_status.map((item) => ({ label: item.status_name, value: item.status_code }))
          setOptionStatus(common_status)
        })
        .catch((err) => {
          console.log(`Load master data : ${err}`)
        })
    }
    else
      firstRender = true
  }, [getAccountAuth])

  return <FormMaster
    title="ข้อมูลประเภทการซ่อมบำรุง"
    tableConfig={{
      key: "id",
      apiPath: "maintain/list",
      allowDelete: {
        visible: true,
        key: "ma_item_id",
        apiPath: "maintain/delete",
        paramType: "param"
      },
      optionDeleteFag: true,
      defaultColumn: [],
      columns: [
        {
          dataField: 'no',
          caption: "ลำดับ",
          width: 60,
          alignment: "center",
          allowFiltering: false
        },
        {
          dataField: 'ma_item_code',
          caption: "รหัส",
          allowFiltering: true
        },
        {
          dataField: 'ma_item_name',
          caption: "ชื่อประเภท",
          allowFiltering: true
        },
        {
          dataField: 'target_km',
          caption: "ระยะทางที่เปลี่ยน(กิโลเมตร)",
          alignment: "start"
        },
        {
          dataField: 'warning_target_km',
          caption: "ระยะทางที่แจ้งเตือนล่วงหน้า(กิโลเมตร)",
          alignment: "start"
        },
        {
          dataField: 'target_day',
          caption: "ระยะเวลาที่ต้องเปลี่ยน(วัน)",
          alignment: "start"
        },
        {
          dataField: 'warning_target_day',
          caption: "ระยะเวลาที่แจ้งเตือนล่วงหน้า(วัน)",
          alignment: "start"
        },
        {
          dataField: 'status_name',
          caption: "สถานะ",
          allowFiltering: true
        },
        {
          dataField: 'updated_by',
          caption: "แก้ไขโดย"
        }
      ],
      exportExcel: {
        fileName: 'ข้อมูลประเภทการซ่อมบำรุง',
        columnConfig: [
          { label: 'ลำดับ', value: 'no' },
          { label: 'รหัส', value: 'ma_item_code' },
          { label: 'ชื่อประเภท', value: 'ma_item_name' },
          { label: 'ระยะทางที่เปลี่ยน(กิโลเมตร)', value: 'target_km' },
          { label: 'ระยะทางที่แจ้งเตือนล่วงหน้า(กิโลเมตร)', value: 'warning_target_km' },
          { label: 'ระยะเวลาที่ต้องเปลี่ยน(วัน)', value: 'target_day' },
          { label: 'ระยะเวลาที่แจ้งเตือนล่วงหน้า(วัน)', value: 'warning_target_day' },
          { label: 'สถานะ', value: 'status_name' },
          { label: 'แก้ไขโดย', value: 'updated_by' }
        ]
      }
    }}
    formData={{
      ma_item_code: "",
      ma_item_name: "",
      target_km: "",
      warning_target_km: "",
      target_day: "",
      warning_target_day: "",
      update_mode: "",
      status: "Y"
    }}
    modalConfig={{
      visible: true,
      title: "ประเภทการซ่อมบำรุง",
      required: ["ma_item_code", "ma_item_name", "update_mode"],
      fieldTypeNumber: [],
      paramName: "ma_item_id",
      apiPath: {
        "get": '',
        "post": 'maintain/add',
        "put": 'maintain/update'
      },
      elements: [
        {
          fieldName: "ma_item_code",
          label: "รหัส",
          placeholder: "รหัส",
          required: true,
          inputType: "text"
        },
        {
          fieldName: "ma_item_name",
          label: "ชื่อประเภท",
          placeholder: "ชื่อประเภท",
          required: true,
          inputType: "text"
        },
        {
          fieldName: "target_km",
          label: "ระยะทางที่เปลี่ยน(กิโลเมตร)",
          placeholder: "ระยะทางที่เปลี่ยน(กิโลเมตร)",
          min: 0,
          inputType: "number",
          isOneRow: true
        },
        {
          fieldName: "warning_target_km",
          label: "ระยะทางที่แจ้งเตือนล่วงหน้า(กิโลเมตร) ",
          placeholder: "ระยะทางที่แจ้งเตือนล่วงหน้า(กิโลเมตร) ",
          min: 0,
          inputType: "number",
          isOneRow: true
        },
        {
          fieldName: "target_day",
          label: "ระยะเวลาที่ต้องเปลี่ยน(วัน)",
          placeholder: "ระยะเวลาที่ต้องเปลี่ยน(วัน)",
          min: 0,
          inputType: "number",
          isOneRow: true
        },
        {
          fieldName: "warning_target_day",
          label: "ระยะเวลาที่แจ้งเตือนล่วงหน้า(วัน)",
          placeholder: "ระยะเวลาที่แจ้งเตือนล่วงหน้า(วัน)",
          min: 0,
          inputType: "number",
          isOneRow: true
        },
        {
          fieldName: "update_mode",
          label: "การปรับปรุงข้อมูล",
          required: true,
          placeholder: "การปรับปรุงข้อมูล",
          inputType: "select",
          allowClear: true,
          options: [
            {
              label: "ปรับปรุงรถทั้งหมด",
              value: 1
            },
            {
              label: "ปรับปรุงรถเฉพาะรถใหม่",
              value: 2
            }
          ]
        },
        {
          showSearch: false,
          fieldName: "status",
          label: "สถานะ",
          placeholder: "สถานะ",
          inputType: "select",
          defaultValue: "Y",
          options: optionStatus
        }
      ]
    }}
  />
}))

export default MaintenanceTypeUnit