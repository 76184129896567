import axios from 'axios'
import { BASE_URL } from '../AppConfig'
import IAuth from '../interfaces/Common/IAuth'

export default {
  //#region USERS
  addShippoint: async function (auth: IAuth, data) {
    const response = await axios.post(`${BASE_URL}/shippoint/add`, {
      shippoint_code: data.shippoint_code,
      shippoint_name: data.shippoint_name,
      lat: data.lat,
      lon: data.lon,
      address: data.address,
      village: data.village,
      soi: data.soi,
      road: data.road,
      admin_level1_id: data.admin_level1_id,
      admin_level2_id: data.admin_level2_id,
      admin_level3_id: data.admin_level3_id,
      post_code: data.post_code,
      tel: data.tel,
      email: data.email,
      contact_name: data.contact_name,
      ref_code: data.ref_code,
      dtopen: data.dtopen,
      dtclose: data.dtclose,
      pickup_mins: data.pickup_mins,
      pickdown_mins: data.pickdown_mins,
      // status: data.status
    },
      {
        headers: { action_user_id: auth?.action_user_id, token_id: auth?.token_id }
      })
    return response
  },

  updateShippoint: async function (auth: IAuth, data) {
    const response = await axios.put(`${BASE_URL}/shippoint/update`, {
      shipping_id: data.shipping_id,
      shippoint_code: data.shippoint_code,
      shippoint_name: data.shippoint_name,
      lat: data.lat,
      lon: data.lon,
      address: data.address,
      village: data.village,
      soi: data.soi,
      road: data.road,
      admin_level1_id: data.admin_level1_id,
      admin_level2_id: data.admin_level2_id,
      admin_level3_id: data.admin_level3_id,
      post_code: data.post_code,
      tel: data.tel,
      email: data.email,
      contact_name: data.contact_name,
      ref_code: data.ref_code,
      dtopen: data.dtopen,
      dtclose: data.dtclose,
      pickup_mins: data.pickup_mins,
      pickdown_mins: data.pickdown_mins,
      // status: data.status
    },
      {
        headers: { action_user_id: auth?.action_user_id, token_id: auth?.token_id }
      })
    return response
  },
  //#endregion
}