import { useEffect, useState } from 'react'
import { Modal, Button, ModalAlert, RouteOnMap } from '../../components'
import './style.css'

const MapRoute = (props) => {
  let { data, onClosed, auth } = props

  const [routeInfo, setRouteInfo] = useState({
    route_id: 0,
    delivery_date: ""
  })
  const [optionAlert, setOptionAlert]: any = useState({
    show: false,
    action: {
      id: "",
      name: ""
    },
    type: "success",
    title: "",
    description: {
      visible: false,
      type: "",
      content: "",
      contentLists: []
    }
  })

  useEffect(() => {
    setRouteInfo({
      ...routeInfo,
      route_id: data.route_id,
      delivery_date: data.delivery_date
    })
  }, [data])

  return <>
    <Modal
      title={{ text: "เส้นทางการจัดส่ง" }}
      open={data.show}
      maskClosable={true}
      contentPadding="p-0"
      onClosed={() => { }}
      width="w-1/2"
      footer={<Button
        text="ปิด"
        width="w-20"
        onClick={() => onClosed()}
      />}
    >
      <div style={{ height: '60vh', width: '100%' }}>
        {
          data.show && <RouteOnMap
            auth={auth}
            deliveryDate={routeInfo.delivery_date}
            routeId={routeInfo.route_id}
          />
        }
      </div>
    </Modal>

    <ModalAlert
      {...optionAlert}
      onOk={async () => {
        setOptionAlert({ ...optionAlert, show: false })
      }}
      onCancel={() => {
        setOptionAlert({ ...optionAlert, show: false })
      }}
    />
  </>
}

export default MapRoute