import axios from 'axios'
import { BASE_URL } from '../AppConfig'
import IAuth from '../interfaces/Common/IAuth'

interface IAlert {
  sdate: string
  edate: string
  func_name?: string
}

export default {
  getMobileLog: async function (auth: IAuth, data: IAlert) {
    let prmList: any = []
    for (const key in data) {
      if (data[key] !== "") prmList.push(`${key}=${data[key]}`)
    }
    let param = prmList.join('&');

    try {
      const response = await axios.get(`${BASE_URL}/mobile_log/list?${param}`, {
        headers: { action_user_id: auth?.action_user_id, token_id: auth?.token_id }
      })
      return response.data
    } catch (error) {
      throw error
    }
  },


  execute: async function (auth: IAuth, id: number) {
    const response = await axios.post(`${BASE_URL}/mobile_log/reproc`, {
      id
    },
      {
        headers: { action_user_id: auth?.action_user_id, token_id: auth?.token_id }
      })
    return response
  },
}