
import { useState, useRef, useEffect } from 'react'
import { createMultipleRef } from '../../../helpers'
import { Modal, ModalAlert, FormLoading } from '../../../components'
import { PictureService } from '../../../services';
import DataGrid, { Column, Scrolling } from 'devextreme-react/data-grid';
import { Checkbox } from 'antd';

const ModalForm = (props) => {
  let { open, dataEdit, auth } = props // STATES
  let { onClosed, onChange } = props // ACTIONS

  const [loading, setLoading]: any = useState({ visible: false, tip: 'กำลังบันทึก...' })
  const [shakeIsWrong, setShakeIsWrong] = useState(false)
  const [optionAlert, setOptionAlert]: any = useState({
    show: false,
    action: {
      id: "",
      name: ""
    },
    type: "success",
    title: "",
    description: {
      visible: false,
      type: "",
      content: "",
      contentLists: [],

    }
  })

  const inputRef: any = useRef([]);
  createMultipleRef(inputRef, 2)
  useEffect(() => { }, [open])

  const saveData = () => {
    let pics = dataEdit?.pics.map((item) => ({
      "pic_code": item?.pic_code,
      "is_require": item?.is_require,
      "status": item?.status,
    }))
    let body: any = {
      "job_type_code": dataEdit?.job_type_code,
      "pics": pics
    }

    setLoading({ ...loading, visible: true, tip: 'กำลังบันทึก...' })
    PictureService.saveJobTyePicture(auth, body)
      .then((res: any) => {
        let { code, message, sys_message } = res.data

        if (code === 200) {
          setOptionAlert({
            ...optionAlert,
            type: "success",
            show: true,
            action: {
              id: "",
              name: ""
            },
            title: "บันทึกข้อมูลสำเร็จ",
            description: { visible: false, type: "text", content: " ", contentLists: [] }
          })
          onClosed(true)
        }
        else {
          setOptionAlert({
            ...optionAlert,
            type: "danger",
            show: true,
            action: {
              id: "",
              name: ""
            },
            title: "ผิดพลาด",
            description: { visible: true, type: "text", content: `${message} ${sys_message}` }
          })
        }
        setLoading({ ...loading, visible: false })
      })
      .catch((err) => {
        setOptionAlert({
          ...optionAlert,
          type: "danger",
          show: true,
          action: {
            id: "",
            name: ""
          },
          title: "ผิดพลาด",
          description: { visible: true, type: "text", content: `${err}` }
        })
        setLoading({ ...loading, visible: false })
      })

  }
  return (<>
    <Modal
      title={{ text: `ตั้งค่ารูปภาพ` }}
      open={open}
      maskClosable={true}
      shake={shakeIsWrong}
      width={"w-2/4"}
      onClosed={() => {
        onClosed()
      }}
      onOk={() => {
        setOptionAlert({
          ...optionAlert,
          action: {
            id: "",
            name: "save"
          },
          type: "confirm",
          title: "ยืนยัน",
          show: true,
          description: {
            visible: true,
            type: "text",
            content: "คุณแน่ใจที่จะบันทึกข้อมูล ?",
            contentLists: []
          }
        })
      }}
    >
      <FormLoading loading={loading.visible} tip={loading.tip}>
        <DataGrid
          dataSource={dataEdit.pics}
          showBorders={true}
          columnAutoWidth={true}
          hoverStateEnabled={true}
        >
          <Scrolling columnRenderingMode="virtual" />
          <Column
            dataField="status"
            caption="Choose"
            width={100}
            alignment="center"
            cellRender={(e) => {
              let { status, pic_code } = e.data
              return <Checkbox
                checked={status === "Y" ? true : false}
                onChange={() => {
                  let { pics } = dataEdit
                  let rowIndex = pics.findIndex((item) => item.pic_code === pic_code)
                  let dtChange: any = { ...dataEdit }
                  dtChange.pics[rowIndex].status = status === "Y" ? "N" : "Y"

                  onChange(dtChange)
                }}></Checkbox>
            }}
          />
          <Column
            dataField="is_require"
            caption="Require"
            width={100}
            alignment="center"
            cellRender={(e) => {
              let { is_require, pic_code } = e.data
              return <Checkbox
                checked={is_require === "Y" ? true : false}
                onChange={() => {
                  let { pics } = dataEdit
                  let rowIndex = pics.findIndex((item) => item.pic_code === pic_code)
                  let dtChange: any = { ...dataEdit }
                  dtChange.pics[rowIndex].is_require = is_require === "Y" ? "N" : "Y"
                  onChange(dtChange)
                }}></Checkbox>
            }}
          />
          <Column dataField="pic_code" caption="Picture code" />
          <Column dataField="pic_desc" caption="Picture Desc" />
          <Column dataField="pic_type_name" caption="Picture Type Name" />
        </DataGrid>
      </FormLoading>
    </Modal>

    <ModalAlert
      {...optionAlert}
      onOk={() => {
        let { action } = optionAlert
        if (action.name === "save") saveData()
        setOptionAlert({ ...optionAlert, show: false })
      }}
      onCancel={() => {
        setOptionAlert({ ...optionAlert, show: false })
      }}
    />
  </>
  )
}

export default ModalForm