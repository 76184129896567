import { useState, useRef, useEffect } from 'react'
import { inject, observer } from 'mobx-react';
import { createMultipleRef, setOptions } from '../../../helpers'
import { Input, Card, Button, ModalAlert, Select, FormLoading, ExportExcel } from '../../../components'
import { CommonService, PriceModelService } from "../../../services";
import DataGrid, { SearchPanel, Scrolling, Item, Paging, Pager, Column, Toolbar, HeaderFilter } from 'devextreme-react/data-grid';
import { RefreshIcon, SearchIcon } from '@heroicons/react/outline';
import ModalForm from './ModalForm';
import ModalPriceCopy from './ModalPriceCopy';
import ModalPriceSet from './ModalPriceSet';
import ModalPriceModel from './ModalPriceModel';
import { get, isEmpty } from 'lodash';

const dateFormat = 'YYYY-MM-DD';
let firstRender = false
let defaultColumnExport = [
  { label: 'ลำดับ', value: 'no' },
  { label: 'โซน', value: 'zone_name' },
  { label: 'จังหวัด', value: 'admin_level1_name' },
  { label: 'อำเภอ', value: 'admin_level2_name' },
  { label: 'หน่วยสินค้า', value: 'unit_name' },
  { label: 'จำนวนต่ำสุด', value: 'min_item' },
  { label: 'จำนวนสูงสุด', value: 'max_item' },
  { label: 'ประเภทการขนส่ง', value: 'logistic_type_name' },
  { label: 'วันที่บังคับใช้งาน', value: 'activate_date' },
  { label: 'สถานะ', value: 'status_name' },
  { label: 'แก้ไขโดย', value: 'updated_by' },
  { label: 'วันที่แก้ไข', value: 'updated_date' }
]

const PriceModel = inject("authenStore", "priceModelStore")(observer((props) => {
  let authenStore = props.authenStore
  let priceModelStore = props.priceModelStore
  let { setPriceModeStore } = priceModelStore // STATE
  let { getAccountAuth } = authenStore // STATE
  let auth = { action_user_id: getAccountAuth?.user_id, token_id: getAccountAuth?.access_token }

  const [masterOptions, setMasterOptions] = useState({
    customer: [],
    logistic_type: [],
    product: [],
    product_unit: [],
    common_status: [],
    province: [],
    distince: [],
    zone_set: [],
    routeAutoFilter: []
  })
  const [loading, setLoading]: any = useState(false)
  const [dataSource, setDataSource]: any = useState([])
  const [dataExport, setDataExport]: any = useState([])
  const [open, setOpen]: any = useState(false)
  const [openModalDoc, setOpenModalDoc]: any = useState(false)
  const [filterCurrent, setFilterCurrent]: any = useState({})
  const [filter, setFilter]: any = useState({
    cust_id: "",
    shipment_type: ""
  })
  const [optionAlert, setOptionAlert]: any = useState({
    show: false,
    action: {
      id: "",
      name: ""
    },
    type: "success",
    title: "",
    description: {
      visible: false,
      type: "",
      content: "",
      contentLists: []
    }
  })
  const [columns, setColumns]: any = useState([
    {
      dataField: 'no',
      caption: "ลำดับ",
      width: 90,
      alignment: "center"
    },
    {
      dataField: 'zone_name',
      caption: "โซน"
    },
    {
      dataField: 'admin_level1_name',
      caption: "จังหวัด"
    },
    {
      dataField: 'admin_level2_name',
      caption: "อำเภอ"
    },
    {
      dataField: 'unit_name',
      caption: "หน่วยสินค้า"
    },
    {
      dataField: 'min_item',
      caption: "จำนวนต่ำสุด"
    },
    {
      dataField: 'max_item',
      caption: "จำนวนสูงสุด"
    },
    {
      dataField: 'logistic_type_name',
      caption: "ประเภทการขนส่ง"
    },
    {
      dataField: 'activate_date',
      caption: "วันที่บังคับใช้งาน"
    },
    {
      dataField: 'status_name',
      caption: "สถานะ"
    },
    {
      dataField: 'updated_by',
      caption: "แก้ไขโดย"
    },
    {
      dataField: 'updated_date',
      caption: "วันที่แก้ไข"
    }
  ])
  const [columnExport, setColumnExport]: any = useState(defaultColumnExport)

  let defaultColumns = [{
    dataField: 'no',
    caption: "ลำดับ",
    width: 90,
    alignment: "center"
  },
  {
    dataField: 'zone_name',
    caption: "โซน",
    allowFiltering: true
  },
  {
    dataField: 'admin_level1_name',
    caption: "จังหวัด",
    allowFiltering: true
  },
  {
    dataField: 'admin_level2_name',
    caption: "อำเภอ",
    allowFiltering: true
  },
  {
    dataField: 'unit_name',
    caption: "หน่วยสินค้า",
    allowFiltering: true
  },
  {
    dataField: 'min_item',
    caption: "จำนวนต่ำสุด"
  },
  {
    dataField: 'max_item',
    caption: "จำนวนสูงสุด"
  },
  {
    dataField: 'logistic_type_name',
    caption: "ประเภทการขนส่ง",
    allowFiltering: true
  },
  {
    dataField: 'activate_date',
    caption: "วันที่บังคับใช้งาน"
  },
  {
    dataField: 'status_name',
    caption: "สถานะ",
    width: 100,
    allowFiltering: true
  },
  {
    dataField: 'updated_by',
    caption: "แก้ไขโดย",
    allowFiltering: true
  },
  {
    dataField: 'updated_date',
    caption: "วันที่แก้ไข"
  }]


  const [dataForm, setDataForm]: any = useState({
    "dlv_doc_no": "",
    "route_id": "",
    "ts_gate_in": "",
    "ts_gate_out": "",
    "mileage_start": "",
    "mileage_end": "",
    "service_info": "",
    "tasks": [],
    "cost_items": []
  })

  const gridRef: any = useRef(null);
  const inputRef: any = useRef([]);
  createMultipleRef(inputRef, 2)

  useEffect(() => {
    return () => {
      setPriceModeStore({
        openModalPriceSet: false,
        openModalPriceCopy: false,
        openModalPriceModel: false,
        priceModelData: {}
      })
    }
  }, [])

  useEffect(() => {
    if (firstRender || !isEmpty(getAccountAuth)) {
      CommonService.getMasterData(auth, "customer,logistic_type,product,product_unit,common_status,admin_level1,zone_set,route_auto_filter")
        .then((res: any) => {
          let customer = res.result.customer.map((item) => ({ label: item.cust_name, value: item.cust_id }))
          let logistic_type = res.result.logistic_type.map((item) => ({ label: item.logistic_type_name, value: item.logistic_type_id }))
          let product = res.result.product.map((item) => ({ label: item.product_name, value: item.product_id, product_code: item.product_code }))
          let product_unit = res.result.product_unit.map((item) => ({ label: item.unit_name, value: item.unit_id }))
          let common_status = res.result.common_status.map((item) => ({ label: item.status_name, value: item.status_code }))
          let province = res.result.admin_level1.map((item) => ({ label: item.admin_level1_name, value: item.admin_level1_id }))
          let zone_set = res.result.zone_set.map((item) => ({ label: item.zone_set_name, value: item.zone_set_code }))
          let routeAutoFilter = res.result.route_auto_filter.map((item) => ({ label: item.desc, value: item.id }))

          let _cust_id = "", _shipment_type = ""
          if (customer.length > 0)
            _cust_id = customer[0].value
          if (logistic_type.length > 0)
            _shipment_type = logistic_type[0].value

          let defaultFilter = {
            cust_id: _cust_id,
            shipment_type: _shipment_type
          }
          setFilter(defaultFilter)
          setFilterCurrent(defaultFilter)

          setMasterOptions({
            ...masterOptions,
            customer,
            logistic_type,
            product,
            product_unit,
            common_status,
            province,
            zone_set,
            routeAutoFilter
          })
        })
        .catch((err) => {
          setOptionAlert({
            ...optionAlert,
            type: "danger",
            show: true,
            action: {
              id: "",
              name: ""
            },
            title: "ผิดพลาด",
            description: { visible: true, content: `Load master data : ${err}` }
          })
        })
    }
    else
      firstRender = true
  }, [getAccountAuth])


  const onChangeProvince = (id_ref1) => { // GET MASTER DISTRICT
    if (id_ref1) {
      CommonService.getMasterData(auth, "admin_level2", id_ref1)
        .then((res: any) => {
          let distince = res.result.admin_level2.map((item) => ({ label: item.admin_level2_name, value: item.admin_level2_id }))
          setMasterOptions({ ...masterOptions, distince })
        })
        .catch((err) => {
        })
    }
    else {
      setMasterOptions({
        ...masterOptions,
        distince: []
      })
    }
  }
  //#region 

  const searchData = (isReloadCurrent = false) => {
    let data = isReloadCurrent ? filterCurrent : filter
    if (data.cust_id === "" || data.shipment_type === "") return

    setLoading(true)
    setFilterCurrent(filter)
    setColumns(defaultColumns)
    setColumnExport(defaultColumnExport)
    PriceModelService.getPriceModel(auth, data)
      .then((res: any) => {
        let { code, message, result, sys_message } = res
        if (code === 200) {
          let { items, price_set } = result

          if (price_set.length > 0) {
            let _columns: any = [...defaultColumns], columnIndex = 4
            price_set.forEach((column) => {
              _columns.splice(columnIndex, 0, {
                dataField: 'values',
                caption: column.price_set_name,
                cellRender: (e) => {
                  let values = e.value

                  try {
                    let foundValue = values.find((v) => v.price_set_id === column.price_set_id)
                    if (foundValue)
                      return `${foundValue.price_value}`
                    else
                      return ""

                  } catch (err) {
                    console.log(`${err}`)
                  }

                }
              })
              columnIndex++
            })

            //#region FOR EXPORT
            let dataExport = items.map((item) => {
              let { values } = item
              let columnPriceSet = {}, columnNo = 1

              price_set.forEach(price => {
                let foundValue = values.find((v) => v.price_set_id === price.price_set_id)
                let value = ""
                if (foundValue) {
                  value = foundValue.price_value
                }
                columnPriceSet[`coumn_${columnNo}`] = value
                columnNo++
              });

              return {
                ...item,
                ...columnPriceSet
              }
            })

            let columnExport: any = [...defaultColumnExport], columnExportIndex = 4, columnNo = 1
            price_set.forEach((column) => {
              columnExport.splice(columnExportIndex, 0, {
                label: column?.price_set_name,
                value: `coumn_${columnNo}`
              },
              )
              columnNo++
              columnExportIndex++
            })
            setDataExport(dataExport)
            setColumnExport(columnExport)
            //#endregion

            setColumns(_columns)
          }
          setDataSource(items)
        }
        else {
          setOptionAlert({
            ...optionAlert,
            type: "danger",
            show: true,
            action: {
              id: "",
              name: ""
            },
            title: "ผิดพลาด",
            description: { visible: true, type: "text", content: `${message} ${sys_message}` }
          })
        }
        setLoading(false)
      })
      .catch((err) => {
        setLoading(false)
        setOptionAlert({
          ...optionAlert,
          type: "danger",
          show: true,
          action: {
            id: "",
            name: ""
          },
          title: "ผิดพลาด",
          description: { visible: true, type: "text", content: `${err}` }
        })
      })
  }

  const deletePriceModel = (id) => {
    PriceModelService.deletePriceModel(auth, id)
      .then((res: any) => {
        if (res.code === 200) {
          setOptionAlert(setOptions({
            defaultOption: optionAlert,
            show: true,
            title: "ลบข้อมูลสำเร็จ"
          }))
          searchData(true)
        }
      })
      .catch((err) => {
        setOptionAlert(setOptions({
          defaultOption: optionAlert,
          show: true,
          type: "danger",
          title: "ผิดพลาด",
          description: { visible: true, content: `${err}` }
        }))
      })
  }
  //#endregion

  return (
    <div className="space-y-3 p-4">
      <Card
        title="รายได้จากค่าขนส่ง"
      >
        <div className="mt-5 md:col-span-2 md:mt-0">
          <FormLoading loading={loading} >
            <div className="flex flex-row mb-2">
              <div className="w-3/4">
                <div className="flex space-x-2">
                  <div className="flex flex-row">
                    <label className="text-sm font-medium text-gray-700 mt-3 mr-2 text-start">
                      ลูกค้า :
                    </label>
                    <div className="mt-1 sm:col-span-2 sm:mt-0">
                      <Select
                        showSearch={true}
                        defaultValue={filter.cust_id}
                        placeholder="ลูกค้า"
                        width={220}
                        options={masterOptions.customer}
                        onChange={(value) => setFilter({ ...filter, cust_id: value })}
                      />
                    </div>
                  </div>

                  <div className="flex flex-row">
                    <label className="text-sm font-medium text-gray-700 mt-3 mr-2 text-start">
                      ประเภทการขนส่ง :
                    </label>
                    <div className="mt-1 sm:col-span-2 sm:mt-0">
                      <Select
                        defaultValue={filter.shipment_type}
                        placeholder="ประเภทการขนส่ง"
                        width={150}
                        options={masterOptions.logistic_type}
                        onChange={(value) => setFilter({ ...filter, shipment_type: value })}
                      />
                    </div>
                  </div>

                  <div className="flex flex-row">
                    <label className="text-sm font-medium text-gray-700 mt-3 text-start"> </label>
                    <div className="mt-1 sm:col-span-2 sm:mt-0 pt-1">
                      <Button
                        text="ค้นหา"
                        icon={<SearchIcon />}
                        size="md"
                        type="primary"
                        title="ค้นหา"
                        onClick={() => {
                          if (!loading) {
                            searchData()
                          }
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="w-1/4">
                <div className='flex justify-end'>
                  <div className="flex flex-row">
                    <label className="text-sm font-medium text-gray-700 mt-3 text-start"> </label>
                    <div className="mt-1 sm:col-span-2 sm:mt-0 pt-1">
                      <Button
                        text="คัดลอกราคา"
                        size="md"
                        type="primary"
                        title="คัดลอกราคา"
                        onClick={() => setPriceModeStore({ openModalPriceCopy: true })}
                      />
                    </div>
                  </div>

                  <div className="flex flex-row">
                    <label className="text-sm font-medium text-gray-700 mt-3 text-start"> </label>
                    <div className="mt-1 sm:col-span-2 sm:mt-0 pt-1">
                      <Button
                        text="เพิ่มรายการ"
                        size="md"
                        type="primary"
                        title="เพิ่มรายการ"
                        onClick={() => setPriceModeStore({ openModalPriceModel: true })}
                      />
                    </div>
                  </div>

                  <div className="flex flex-row">
                    <label className="text-sm font-medium text-gray-700 mt-3 text-start"> </label>
                    <div className="mt-1 sm:col-span-2 sm:mt-0 pt-1">
                      <Button
                        text="เพิ่มหมวดราคา"
                        size="md"
                        type="primary"
                        title="เพิ่มหมวดราคา"
                        onClick={() => setPriceModeStore({ openModalPriceSet: true })}
                      />
                    </div>
                  </div>

                  {/* <div className="flex flex-row">
                    <label className="text-sm font-medium text-gray-700 mt-3 text-start"> </label>
                    <div className="mt-1 sm:col-span-2 sm:mt-0 pt-1">
                      <Input
                        icon={<SearchIcon className="w-5 h-5 text-gray-500" />}
                        placeholder="ค้นหา..."
                        onChange={(e) => {
                          gridRef.current.instance.searchByText(e.target.value)
                        }}
                      />
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
            <DataGrid
              ref={(ref) => gridRef.current = ref}
              dataSource={dataSource}
              keyExpr={'id'}
              showBorders={true}
              columnAutoWidth={true}
              hoverStateEnabled={true}

            >
              <SearchPanel visible={true} />
              <Paging defaultPageSize={10} />
              <Pager
                showInfo={true}
                showPageSizeSelector={true}
                allowedPageSizes={[10, 20, 50]}
              />
              <HeaderFilter visible={true} />
              {
                columns.map((column) => <Column
                  key={column.dataField}
                  width={column.width}
                  allowFiltering={get(column, 'allowFiltering', false)}
                  dataField={column.dataField}
                  dataType={column.dataType}
                  caption={column.caption}
                  alignment={column.alignment}
                  cellRender={column.cellRender}
                />)
              }

              <Column
                fixed={true}
                caption="แก้ไข/ลบ"
                type="buttons"
                width={80}
                buttons={[
                  {
                    hint: "แก้ไข",
                    icon: "fa fa-pencil-square-o",
                    visible: true,
                    onClick: (e) => {
                      e.cancel = "true"
                      let data = e.row.data
                      setPriceModeStore({
                        priceModelData: {
                          "cust_price_id": data.id,
                          "cust_id": data.cust_id,
                          "zone_id": data.zone_id,
                          "admin_level1": data.admin_level1,
                          "admin_level2": data.admin_level2,
                          "logistic_type_id": data.logistic_type_id,
                          "product_id": data.product_id,
                          "unit_id": data.unit_id,
                          "max_item": data.max_item,
                          "min_item": data.min_item,
                          "activate_date": data.activate_date,
                          "status": data.status,
                          "prices": data.values
                        }
                      }
                      )
                      setPriceModeStore({ openModalPriceModel: true })
                    }
                  },
                  {
                    hint: "ลบ",
                    icon: "trash",
                    visible: true,
                    onClick: (e) => {
                      e.cancel = "true"
                      let data = e.row.data

                      setOptionAlert(setOptions({
                        defaultOption: alert,
                        action: {
                          id: data.id,
                          name: 'delete'
                        },
                        show: true,
                        type: "confirm",
                        title: "ยืนยัน",
                        description: {
                          visible: true,
                          content: "คุณแน่ใจที่จะลบข้อมูล ?"
                        }
                      }))
                    }
                  }
                ]} />
              <Toolbar visible={true}>
                <Item location="after">
                  <ExportExcel
                    filename={"รายได้จากค่าขนส่ง"}
                    data={dataExport}
                    columnConfig={columnExport}
                  />
                </Item>

                <Item location="after">
                  <Input
                    icon={<SearchIcon className="w-5 h-5 text-gray-500" />}
                    placeholder="ค้นหา..."
                    onChange={(e) => gridRef.current.instance.searchByText(e.target.value)}
                  />
                </Item>
              </Toolbar>
            </DataGrid>
          </FormLoading>
        </div>

        <ModalForm
          auth={auth}
          open={open}
          data={dataForm}
          onClosed={(reload) => {
            setOpen(false)
            reload && searchData(true)
          }}
          masterOptions={masterOptions}
        />

        <ModalPriceCopy
          auth={auth}
          masterOptions={masterOptions}
          onReload={() => searchData(true)}
        />

        <ModalPriceModel
          auth={auth}
          masterOptions={masterOptions}
          defaultCustomer={filter.cust_id}
          onReload={() => searchData(true)}
          onChangeProvince={(id) => onChangeProvince(id)}
        />

        <ModalPriceSet
          auth={auth}
          masterOptions={masterOptions}
          onReload={() => searchData(true)}
        />
      </Card >

      <ModalAlert
        {...optionAlert}
        onOk={() => {
          let { type, action } = optionAlert
          if (action.name === "delete") {
            deletePriceModel(action.id)
          }
          setOptionAlert({ ...optionAlert, show: false })
        }}
        onCancel={() => {
          setOptionAlert({ ...optionAlert, show: false })
        }}
      />
    </div>
  )
}))

export default PriceModel