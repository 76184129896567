import { t } from '../../helpers'
import { HomeIcon } from '@heroicons/react/outline'

export declare type IconPosition = 'start' | 'end';
export declare type roundedType = 'rounded' | 'full';
export declare type shapeType = 'circle';
export declare type SizeType = 'sm' | 'md' | 'lg';

type Props = {
    id?: string
    icon?: React.ReactNode;
    iconPosition?: IconPosition;
    label?: string
    labelHelp?: string
    labelOptional?: string
    disabled?: boolean
    defaultValue?: string
    hiddenLabel?: boolean
    maxLength?: number
    required?: boolean
    rows?: number
    size?: SizeType
    placeholder?: string
    validate?: { isError: boolean; message: string };
    width?: number | string
    onRef?: (ref: any) => void;
    onChange?: (value: any) => void;
}

export default (props: Props) => {
    let {
        id,
        icon,
        iconPosition = "start",
        label,
        labelHelp,
        labelOptional,
        disabled = false,
        defaultValue = "",
        hiddenLabel = false,
        required = false,
        rows = 4,
        size = "md",
        maxLength = "",
        placeholder = "",
        validate = { isError: false, message: "" },
        width = "w-full",
        onRef,
        onChange
    }: any = props

    return (
        <div>
            <div className={`flex justify-between ${hiddenLabel && 'sr-only'}`}>
                <label htmlFor="email" className={`flex flex-row text-sm font-medium text-gray-700 dark:text-gray-200`}>
                    {t(label)}<span className={`text-red-500 ${!required && 'sr-only'}`}>*</span>

                    <p className={`text-sm italic  ml-2
                     ${validate.isError ? 'text-danger-600' : 'text-gray-500'}
                     ${!validate.isError && 'sr-only'}`}
                    >
                        {`${validate.isError && validate.message}`}
                    </p>
                </label>
                <span className={`text-sm text-gray-500 ${!labelOptional && 'sr-only'}`}>
                    {labelOptional}
                </span>
            </div>
            <div className={`${icon && 'relative'} mt-1`}>
                <div className={`pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3 ${icon && iconPosition === "start" ? '' : 'sr-only'}`}>
                    {icon}
                </div>
                {/* <input
                    type="text"
                    name="email"
                    id="email"
                    disabled={disabled}
                    maxLength={maxLength}
                    className={`
                        block 
                        w-${width}
                        ${size === "sm" ? 'h-8' : size === "lg" ? 'h-12' : 'h-auto'}
                        rounded-md 
                        ${validate.isError ?
                            'border-danger-400'
                            : 'border-gray-300'}
                        shadow-sm 
                        focus:border-primary-500 
                        focus:ring-primary-500 
                        sm:text-sm
                        ${icon && iconPosition === "start" ? 'pl-10' : iconPosition === "end" ? 'pr-10' : ''}
                        ${disabled && 'bg-gray-50 text-gray-500 cursor-not-allowed'}
                    `}
                    placeholder={t(placeholder)}
                /> */}
                <textarea
                    id={id}
                    ref={(ref) => onRef && onRef(ref)}
                    rows={rows}
                    disabled={disabled}
                    maxLength={maxLength}
                    placeholder={t(placeholder)}
                    defaultValue={defaultValue}
                    onChange={onChange}
                    className={`
                        block 
                        ${width}
                        rounded-md
                        ${validate.isError ?
                            'border-danger-400'
                            : 'border-gray-300'} 
                        border-gray-300 shadow-sm 
                        focus:border-primary-500 focus:ring-primary-500 sm:text-sm
                        dark:bg-gray-800 dark:text-gray-200
                    `}
                />
                <div className={`pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3 ${icon && iconPosition === "end" ? '' : 'sr-only'}`}>
                    {icon}
                </div>
            </div>
        </div>
    )
}