const LAYER_NAME = "v_mzone";
const URL = "https://gis-cc.we-io.com/geoserver/cc/wms?";

export function setOverlayImageMaps(map) {
  try {
    if (map) {
      map.overlayMapTypes.clear()

      let imageConfig = {
        getTileUrl: function (coord, zoom) {
          return `${URL}service=WMS&version=1.1.0&request=GetMap&layers=${LAYER_NAME}&bbox=97.34363676%2C5.61303783%2C105.63696467999999%2C20.46478572&width=428&height=768&srs=EPSG%3A4326&styles=&format=application/openlayers`

        },
        tileSize: new window.google.maps.Size(256, 256),
        opacity: 0.7,
        isPng: true
      }
      let overlayMap = new window.google.maps.ImageMapType(imageConfig);

      map.overlayMapTypes.push(overlayMap)

      return map
    }
  } catch (error) {
    console.log("ERROR => setOverlayImageMaps : ", error)
  }
}