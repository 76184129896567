
import { useEffect, useState } from "react";
import { inject, observer } from 'mobx-react';
import { Card, Button, DatePicker, Modal, FormLoading, ModalAlert } from '../../components'
import { SearchIcon } from '@heroicons/react/outline'
import { Select, Input, Empty, FloatButton } from 'antd';
import DataGrid, { Column } from 'devextreme-react/data-grid';
import './style.css'
import JobTracking from "./JobTracking";
import { CommonService, TrackingService } from "../../services";
import moment from 'moment';
import { get, isEmpty } from "lodash";

const { TextArea } = Input;
let timeoutOnKey: any = null, firstRender = false
const Tracking = inject("authenStore")(observer((props) => {
  let authenStore = props.authenStore
  let { getAccountAuth } = authenStore // STATE
  let auth = { action_user_id: getAccountAuth?.user_id, token_id: getAccountAuth?.access_token }


  const [showMap, setShowMap] = useState(false)
  const [loading, setLoading] = useState(false)
  const [trackingData, setTrackingData]: any = useState([])
  const [jobSelect, setJobSelect]: any = useState(null)
  // const [loading, setLoading] = useState({ visible: false, tip: "กำลังโหลด" })
  const [modalInfo, setModalInfo]: any = useState({
    open: false,
    title: "",
    name: "",
    icon: '',
    info: {},
    doc_no: ""
  })
  const [filterCurrent, setFilterCurrent]: any = useState({})
  const [filter, setFilter]: any = useState({
    delivery_date: moment().format("YYYY-MM-DD"),
    // delivery_date: "2023-04-22",
    loc_receipt_id: "",
    shipto_id: "",
    qno: "",
    doc_no: "",
    job_type_id: "",
    route_id: "",
    vid: "",
    service_id: "",
    cust_id: "",
    queue_status_id: "",
    route_status_id: "",
    status: ""
  })
  const defaultStates = [
    { name: 'จำนวนเที่ยว', stat: 0 },
    { name: 'รับงานแล้ว', stat: 0 },
    { name: 'จุดจัดส่งทั้งหมด', stat: 0 },
    { name: 'จัดส่งเสร็จ', stat: 0 },
    { name: 'ยังไม่จัดส่ง', stat: 0 }
  ]
  const [stats, setStates] = useState([
    { name: 'จำนวนเที่ยว', stat: 0 },
    { name: 'รับงานแล้ว', stat: 0 },
    { name: 'จุดจัดส่งทั้งหมด', stat: 0 },
    { name: 'จัดส่งเสร็จ', stat: 0 },
    { name: 'ยังไม่จัดส่ง', stat: 0 }
  ])

  const [masterOptions, setMasterOptions] = useState({
    customer: [],
    shipto: [],
    service: [],
    vehicle: [],
    job_type: [],
    track_status: []
  })
  const [optionAlert, setOptionAlert]: any = useState({
    show: false,
    action: {
      id: "",
      name: ""
    },
    type: "success",
    title: "",
    description: {
      visible: false,
      type: "",
      content: "",
      contentLists: [],

    }
  })


  let Jobs = ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""]

  // const [columnConfig, setColumnConfig] :any =useState[]
  let columns: any = [
    {
      dataField: 'pic_url',
      caption: "ภาพถ่าย",
      // width: 90,
      alignment: "center",
      cellRender: (e) => {
        return <div className="flex justify-center">
          <img
            src={e.value}
            className="w-36 h-36 mt-0.5 cursor-pointer"
          />
        </div>
      }
    },
    {
      dataField: 'pic_name',
      caption: "ชื่อไฟล์ภาพ",
      alignment: "center"
    },
    {
      dataField: 'upload_date',
      caption: "เวลา",
      alignment: "center"
    },
    {
      dataField: 'lat',
      caption: "พิกัด",
      alignment: "center",
      cellRender: (e) => `${e.data.lat} ${e.data.lat !== "" ? ", " : ""} ${e.data.lon}`
    }
  ]

  let columnProduct: any = [
    {
      dataField: 'product_code',
      caption: "รหัสสินค้า"
    },
    {
      dataField: 'product_name',
      caption: "ชื่อสินค้า"
    },
    {
      dataField: 'unit_name',
      caption: "หน่วยสินค้า"
    },
    {
      dataField: 'qty',
      caption: "จำนวนสินค้า"
    },
    {
      dataField: 'ret_qty',
      caption: "จำนวนสินค้าเพิ่ม/ลด"
    },
    {
      dataField: 'reason_name',
      caption: "สาเหตุเพิ่ม/ลด"
    }
  ]

  useEffect(() => {
    if (firstRender || !isEmpty(getAccountAuth)) {
      CommonService.getMasterData(auth, "service,customer,shipto,vehicle,job_type,track_status")
        .then((res: any) => {

          let customer = res.result.customer.map((item) => ({ label: item.cust_name, value: item.cust_id }))
          let shipto = res.result.shipto.map((item) => ({ label: item.shipto_name, value: item.shipto_id }))
          let service = res.result.service.map((item) => ({ label: item.service_name, value: item.service_id }))
          let vehicle = res.result.vehicle.map((item) => ({ label: item.licenseplate, value: item.vehicle_id }))
          let job_type = res.result.job_type.map((item) => ({ label: item.job_Type_name, value: item.job_type_id }))
          let track_status = res.result.track_status.map((item) => ({ label: item.status_name, value: item.status_id }))

          setMasterOptions({
            ...masterOptions,
            customer,
            shipto,
            service,
            vehicle,
            job_type,
            track_status
          })
        })
        .catch((err) => {

        })
    }
    else
      firstRender = true
  }, [getAccountAuth])

  const searchTracking = (isReloadCurrent = false) => {
    setLoading(true)
    getDash(filter.delivery_date)
    setFilterCurrent(filter)
    console.log("filter: ", filter)
    TrackingService.getRoute(auth, filter)
      .then((res: any) => {
        let { code, message, result, sys_message } = res
        if (code === 200) {
          setTrackingData(result)
        }
        else {
          setOptionAlert({
            ...optionAlert,
            type: "danger",
            show: true,
            action: {
              id: "",
              name: ""
            },
            title: "ผิดพลาด",
            description: { visible: true, type: "text", content: `${code} : ${sys_message}` }
          })
        }
        setLoading(false)
      })
      .catch((err) => {
        setLoading(false)
        setOptionAlert({
          ...optionAlert,
          type: "danger",
          show: true,
          action: {
            id: "",
            name: ""
          },
          title: "ผิดพลาด",
          description: { visible: true, type: "text", content: `${err}` }
        })
      })
  }


  const getDash = (delivery_date) => {
    TrackingService.getDash(auth, delivery_date)
      .then((res: any) => {
        let { code, message, result, sys_message } = res
        if (code === 200) {
          let { queue_total, queue_complete, queue_uncomplte, route_total, route_pickup } = result
          let newStats = [...stats]
          newStats[0].stat = route_total
          newStats[1].stat = route_pickup
          newStats[2].stat = queue_total
          newStats[3].stat = queue_complete
          newStats[4].stat = queue_uncomplte
          setStates(newStats)
        }
        else {
          setStates(defaultStates)
          setOptionAlert({
            ...optionAlert,
            type: "danger",
            show: true,
            action: {
              id: "",
              name: ""
            },
            title: "ผิดพลาด",
            description: { visible: true, type: "text", content: `/track/dash : ${message} : ${sys_message}` }
          })
        }
      })
      .catch((err) => {
        setStates(defaultStates)
        setOptionAlert({
          ...optionAlert,
          type: "danger",
          show: true,
          action: {
            id: "",
            name: ""
          },
          title: "ผิดพลาด",
          description: { visible: true, type: "text", content: `/track/dash : ${err}` }
        })
      })
  }

  const saveComment = () => {
    TrackingService.addComment(auth, {
      route_id: modalInfo?.info?.route_id,
      drop_id: modalInfo?.info.drop_id || 0,
      remark: modalInfo?.info?.remark
    })
      .then((res: any) => {
        let { code, message, sys_message } = res.data
        if (code === 200) {
          setOptionAlert({
            ...optionAlert,
            type: "success",
            show: true,
            action: {
              id: "",
              name: ""
            },
            title: "บันทึกสำเร็จ",
            description: { visible: false, type: "text", content: " ", contentLists: [] }
          })
          searchTracking(true)
          setModalInfo({ ...modalInfo, open: false })
        }
        else {
          setOptionAlert({
            ...optionAlert,
            type: "danger",
            show: true,
            action: {
              id: "",
              name: ""
            },
            title: "บันทึกไม่สำเร็จ",
            description: { visible: true, type: "text", content: `${message} ${sys_message !== "" && ` : ${sys_message}`}` }
          })
        }
        setLoading(false)
      })
      .catch((err) => {
        setOptionAlert({
          ...optionAlert,
          type: "danger",
          show: true,
          action: {
            id: "",
            name: ""
          },
          title: "บันทึกไม่สำเร็จ",
          description: { visible: true, type: "text", content: `${err}` }
        })
        setLoading(false)
      })
  }

  const setDataSource = () => {
    let dataSource: any = []
    if (modalInfo?.info) {
      let imageFirst = modalInfo.info?.pics?.filter((item) => item?.pic_type === 1)
      // let image = modalInfo.info?.pics?.filter((item) => item?.pic_type === 2)
      let image = modalInfo.info?.pics?.filter((item) => [2, 3, 4, 5].includes(item?.pic_type))
      let imageSignature = modalInfo.info?.pics?.filter((item) => item?.pic_type === 3)

      if (modalInfo.name === "camera")
        !isEmpty(image) && dataSource.push(...image)
      else if (modalInfo.name === "camera-first-drop")
        !isEmpty(imageFirst) && dataSource.push(...imageFirst)
      else
        !isEmpty(imageSignature) && dataSource.push(...imageSignature)
    }
    return dataSource
  }

  const getProduct = () => {
    let { queues } = modalInfo?.info
    let totalItems: any = []
    queues.forEach(dt => totalItems.push(...dt?.items));

    let dataSource = totalItems.filter(item => item.ret_qty !== 0)
    return dataSource
  }

  const setQno = (queues) => {
    let qno: any = []
    queues.forEach(item => qno.push(item?.qno));

    let label = qno.join(', ');
    return label
  }

  return (
    <div className="space-y-3 p-3">
      <Card title="ติดตามงาน">
        <div className="bg-white -my-3">
          <div className="space-y-1">
            <div className="flex flex-row">
              <div className="flex-1 sm:grid sm:grid-cols-3 sm:items-end sm:gap-2">
                <label className="block text-xs font-medium text-gray-700 mb-1 text-right">
                  วันที่จัดส่งสินค้า :
                </label>
                <div className="mt-1 sm:col-span-2 sm:mt-0">
                  <DatePicker
                    allowClear={false}
                    placeholder="วันที่จัดส่งสินค้า"
                    defaultValue={filter.delivery_date}
                    onChange={(d) => {
                      if (d) {
                        setFilter({ ...filter, delivery_date: d.format("YYYY-MM-DD") })
                      }
                      else {
                        setFilter({ ...filter, delivery_date: "" })
                      }
                    }}
                  />
                </div>
              </div>

              <div className="flex-1 sm:grid sm:grid-cols-3 sm:items-end sm:gap-2">
                <label className="block text-xs font-medium text-gray-700 mb-1 text-right">
                  รหัส/ทะเบียน :
                </label>
                <div className="mt-1 sm:col-span-2 sm:mt-0">
                  <Select
                    showSearch={true}
                    allowClear={true}
                    style={{ width: '100%' }}
                    defaultValue={filter.vid === "" ? [] : filter.vid}
                    optionFilterProp="children"
                    placeholder="รหัส/ทะเบียน"
                    autoFocus={true}
                    options={masterOptions.vehicle}
                    onChange={(select) => setFilter({ ...filter, vid: select || "" })}
                  />
                </div>
              </div>

              <div className="flex-1 sm:grid sm:grid-cols-3 sm:items-end sm:gap-2">
                <label className="block text-xs font-medium text-gray-700 mb-1 text-right">
                  พนักงานขับ :
                </label>
                <div className="mt-1 sm:col-span-2 sm:mt-0">
                  <Select
                    showSearch={true}
                    allowClear={true}
                    style={{ width: '100%' }}
                    defaultValue={filter.service_id === "" ? [] : filter.service_id}
                    optionFilterProp="children"
                    placeholder="พนักงานขับ"
                    autoFocus={true}
                    options={masterOptions.service}
                    onChange={(select) => setFilter({ ...filter, service_id: select || "" })}
                  />
                </div>
              </div>

              <div className="flex-1 sm:grid sm:grid-cols-3 sm:items-end sm:gap-2">
                <label className="block text-xs font-medium text-gray-700 mb-1 text-right">
                  สถานะ :
                </label>
                <div className="mt-1 sm:col-span-2 sm:mt-0">
                  <Select
                    showSearch={true}
                    allowClear={true}
                    style={{ width: '100%' }}
                    defaultValue={filter.status === "" ? [] : filter.status}
                    optionFilterProp="children"
                    placeholder="สถานะ"
                    autoFocus={true}
                    options={masterOptions.track_status}
                    onChange={(select) => setFilter({ ...filter, status: select || "" })}
                  />
                </div>
              </div>
            </div>

            <div className="flex flex-row pb-2">
              <div className="flex-1 sm:grid sm:grid-cols-3 sm:items-end sm:gap-2">
                <label className="block text-xs font-medium text-gray-700 mb-1 text-right">
                  ประเภทงาน :
                </label>
                <div className="mt-1 sm:col-span-2 sm:mt-0">
                  <Select
                    showSearch={true}
                    allowClear={true}
                    style={{ width: '100%' }}
                    defaultValue={filter.job_type_id === "" ? [] : filter.job_type_id}
                    optionFilterProp="children"
                    placeholder="ประเภทงาน"
                    autoFocus={true}
                    options={masterOptions.job_type}
                    onChange={(select) => setFilter({ ...filter, job_type_id: select || "" })}
                  />
                </div>
              </div>

              <div className="flex-1 sm:grid sm:grid-cols-3 sm:items-end sm:gap-2">
                <label className="block text-xs font-medium text-gray-700 mb-1 text-right">
                  ชื่อลูกค้า :
                </label>
                <div className="mt-1 sm:col-span-2 sm:mt-0">
                  <Select
                    showSearch={true}
                    allowClear={true}
                    style={{ width: '100%' }}
                    defaultValue={filter.cust_id === "" ? [] : filter.cust_id}
                    optionFilterProp="children"
                    placeholder="ชื่อลูกค้า"
                    autoFocus={true}
                    options={masterOptions.customer}
                    onChange={(select) => setFilter({ ...filter, cust_id: select || "" })}
                  />
                </div>
              </div>

              <div className="flex-1 sm:grid sm:grid-cols-3 sm:items-end sm:gap-2">
                <label className="block text-xs font-medium text-gray-700 mb-1 text-right">
                  ปลายทาง :
                </label>
                <div className="mt-1 sm:col-span-2 sm:mt-0">
                  <Select
                    showSearch={true}
                    allowClear={true}
                    style={{ width: '100%' }}
                    defaultValue={filter.shipto_id === "" ? [] : filter.shipto_id}
                    optionFilterProp="children"
                    placeholder="ปลายทาง"
                    autoFocus={true}
                    options={masterOptions.shipto}
                    onChange={(select) => setFilter({ ...filter, shipto_id: select || "" })}
                  />
                </div>
              </div>

              <div className="flex-1 sm:grid sm:grid-cols-3 sm:items-end sm:gap-2">
                <label className="block text-xs font-medium text-gray-700 mb-1 text-right">
                </label>
                <div className="flex flex-row mt-1 sm:col-span-2 sm:mt-0 space-x-2">
                  <button
                    type="button"
                    title="ค้นหา"
                    className={`flex flex-row ${loading && 'cursor-not-allowed'} items-center rounded border border-transparent bg-blue-100 mt-0.5 px-2.5 py-1.5 text-xs font-medium text-blue-700 hover:bg-blue-200`}
                    onClick={() => {
                      if (!loading) {
                        searchTracking()
                      }
                    }}
                  >
                    <div className="flex flex-row-reverse">
                      <span>ค้นหา</span>
                      <SearchIcon className="w-4 h-4 mr-1" />
                    </div>
                  </button>

                  {/* <img
                    src="/Images/google_maps.png"
                    className="w-9 h-8 mt-0.5 cursor-pointer"
                    onClick={() => setShowMap(true)}
                  /> */}
                </div>
              </div>
            </div>

            <div className="pt-2 border-t border-dashed">
              <dl className="grid grid-cols-1 border gap-0.5 overflow-hidden rounded-md text-center sm:grid-cols-2 lg:grid-cols-5">
                {stats.map((stat) => (
                  <div key={stat.name} className="flex flex-col bg-white/5 border-l border-white/10 py-0.5">
                    <dt className="text-xs font-semibold leading-6 text-gray-900">{stat.name}</dt>
                    <dd className="order-first text-xs font-semibold tracking-tight text-gray-500">{stat.stat}</dd>
                  </div>
                ))}
              </dl>
            </div>
          </div>
        </div>

        <Modal
          title={{ text: modalInfo.title }}
          open={modalInfo.open}
          maskClosable={true}
          // width="w-1/2"
          width={modalInfo.name === "note" ? "lg:w-1/3 sm:w-3/4" : "lg:w-3/4 sm:w-3/4"}
          loading={false}
          onClosed={() => {

          }}
          icon={modalInfo.icon}
          onOk={() => { }}
          footer={<>
            {
              modalInfo.name === "note" && <Button
                text="บันทึก"
                width="w-20"
                type="primary"
                onClick={() => saveComment()}
              />
            }

            <Button
              text="ปิด"
              width="w-20"
              type={modalInfo.name === "note" ? 'white' : 'primary'}
              onClick={() => setModalInfo({ ...modalInfo, open: false })}
            />
          </>}
        >
          <div className="flex flex-col -my-4">
            <div className={`flex-1 ${modalInfo.name === "note" && 'sr-only'} mb-4`}>
              <div className="flex flex-row">
                <div className="flex flex-1 flex-col">
                  <div className="flex flex-1 flex-row  sm:items-start">
                    <span className="flex-1 text-sm font-semibold leading-2">
                      เลขที่แผนงาน :
                    </span>
                    <span className="flex-1 flex-row text-sm">{modalInfo?.doc_no}</span>
                  </div>

                  <div className="flex flex-1 flex-row  sm:items-start">
                    <span className="flex-1 text-sm font-semibold leading-2">
                      เลขที่ใบส่งสินค้า :
                    </span>
                    <span className="flex-1 flex-row text-sm">{setQno(get(modalInfo, 'info.queues', []))}</span>
                  </div>

                  <div className="flex flex-1 flex-row  sm:items-start">
                    <span className="flex-1 text-sm font-semibold leading-2">
                      วันที่จัดส่ง :
                    </span>
                    <span className="flex-1 flex-row text-sm">{modalInfo?.info?.delivery_date}</span>
                  </div>

                  <div className="flex flex-1 flex-row  sm:items-start">
                    <span className="flex-1 text-sm font-semibold leading-2">
                      ชื่อลูกค้า :
                    </span>
                    <span className="flex-1 flex-row text-sm">{modalInfo?.info?.cust_name}</span>
                  </div>

                  <div className="flex flex-1 flex-row  sm:items-start">
                    <span className="flex-1 text-sm font-semibold leading-2">
                      สาขา/สถานที่ปลายทาง :
                    </span>
                    <span className="flex-1 flex-row text-sm">{modalInfo?.info?.shipto_name}</span>
                  </div>
                </div>

                {modalInfo.name === "camera" ? <>
                  <div className="flex flex-1 flex-col">
                    <div className="flex flex-1 flex-row  sm:items-start ">
                      <span className="flex-1 text-sm font-semibold leading-2">
                        สถานะ :
                      </span>
                      <span className="flex-1 flex-row text-sm">{modalInfo?.info?.status_name}</span>
                    </div>

                    <div className="flex flex-1 flex-row  sm:items-start ">
                      <span className="flex-1 text-sm font-semibold leading-2">
                        เวลาเข้างาน :
                      </span>
                      <span className="flex-1 flex-row text-sm">{modalInfo?.info?.ts_check_in}</span>
                    </div>

                    <div className="flex flex-1 flex-row  sm:items-start ">
                      <span className="flex-1 text-sm font-semibold leading-2">
                        เวลาเปิดงาน :
                      </span>
                      <span className="flex-1 flex-row text-sm">{modalInfo?.info?.ts_close}</span>
                    </div>

                    <div className="flex flex-1 flex-row  sm:items-start ">
                      <span className="flex-1 text-sm font-semibold leading-2">
                        เลขไมล์ :
                      </span>
                      <span className="flex-1 flex-row text-sm">{modalInfo?.info?.mileage_in}</span>
                    </div>

                    {
                      modalInfo?.info?.close_status === "F" && <>
                        <div className="flex flex-1 flex-row  sm:items-start ">
                          <span className="flex-1 text-sm font-semibold leading-2">
                            หน่วยงานต้นเหตุ :
                          </span>
                          <span className="flex-1 flex-row text-sm">{modalInfo?.info?.cause_name}</span>
                        </div>

                        <div className="flex flex-1 flex-row  sm:items-start ">
                          <span className="flex-1 text-sm font-semibold leading-2">
                            สาเหตุ :
                          </span>
                          <span className="flex-1 flex-row text-sm">{modalInfo?.info?.reason_name}</span>
                        </div>
                      </>
                    }

                    <div className="flex flex-1 flex-row  sm:items-start ">
                      <span className="flex-1 text-sm font-semibold leading-2">
                        พิกัดปิดงาน :
                      </span>
                      <span className="flex-1 flex-row text-sm">{`${modalInfo?.info?.close_lat}, ${modalInfo?.info?.close_lon}`}</span>
                    </div>
                  </div>
                </>
                  : <div className="flex flex-1 flex-col" />
                }
              </div>
            </div>

            <div className="flex-1">
              {
                modalInfo.name === "note" ? <TextArea
                  rows={10}
                  defaultValue={modalInfo?.info?.remark}
                  onChange={(e) => {
                    clearTimeout(timeoutOnKey)
                    timeoutOnKey = setTimeout(() => {
                      setModalInfo({
                        ...modalInfo,
                        info: {
                          ...modalInfo?.info,
                          remark: e.target.value
                        }
                      })
                    }, 500);
                  }}

                />
                  : <DataGrid
                    dataSource={setDataSource()}
                    keyExpr="id"
                    showBorders={true}
                    height={300}
                  >
                    {
                      columns.map((column) => <Column
                        key={column.dataField}
                        dataField={column.dataField}
                        caption={column.caption}
                        alignment={column.alignment}
                        cellRender={column.cellRender}
                      />)
                    }
                  </DataGrid>
              }


              {
                modalInfo.name === "camera" && <div className="mt-6">
                  <span className="block text-sm font-semibold leading-2">
                    ข้อมูลสินค้า :
                  </span>
                  <DataGrid
                    dataSource={getProduct()}
                    keyExpr="item_id"
                    showBorders={true}
                    columnAutoWidth={true}
                    height={150}
                  >
                    {
                      columnProduct.map((column) => <Column
                        key={column.dataField}
                        dataField={column.dataField}
                        caption={column.caption}
                        alignment={column.alignment}
                        cellRender={column.cellRender}
                      />)
                    }
                  </DataGrid>
                </div>
              }

            </div>
          </div>
        </Modal>
      </Card>
      <FormLoading loading={loading}>
        {
          trackingData.length === 0 ? <div className="bg-white rounded-md p-4 space-y-2">
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          </div>
            : <div className="bg-white rounded-md p-4 space-y-2">
              {
                showMap ? <JobTracking
                  showMap={showMap}
                  trackingAll={trackingData}
                  onJobSelect={(job) => setJobSelect(job)}
                  onOpenModal={(option) => setModalInfo({
                    ...modalInfo,
                    open: true,
                    title: option.title,
                    name: option.name,
                    icon: option.icon,
                    info: option.info,
                    doc_no: option?.doc_no
                  })}
                  onChangeView={(isShow) => setShowMap(isShow)}
                />
                  : trackingData.map((data, index) => {
                    // if (index === 0) 
                    return <JobTracking
                      auth={auth}
                      jobDetail={data}
                      showMap={showMap}
                      onJobSelect={(job) => setJobSelect(job)}
                      onOpenModal={(option) => setModalInfo({
                        ...modalInfo,
                        open: true,
                        title: option.title,
                        name: option.name,
                        icon: option.icon,
                        info: option.info,
                        doc_no: option?.doc_no
                      })}
                      onChangeView={(isShow) => setShowMap(isShow)}
                    />
                  }
                  )
              }
            </div>
        }
      </FormLoading>

      <ModalAlert
        {...optionAlert}
        onOk={async () => {
          let { type, action } = optionAlert
          setOptionAlert({ ...optionAlert, show: false })
        }}
        onCancel={() => {
          setOptionAlert({ ...optionAlert, show: false })
        }}
      />

      <FloatButton.BackTop type="primary" />
    </div>
  )
}))

export default Tracking

