import { useEffect } from 'react'
import { useNavigate, useLocation } from "react-router-dom";
import { inject, observer } from 'mobx-react';
import { HomeIcon, ChevronRightIcon } from '@heroicons/react/outline'
import { useTranslation } from 'react-i18next'

const Breadcrumbs = inject("commonStore")(observer((props) => {
  let commonStore = props.commonStore
  let { getBreadcrumbs } = commonStore // STATES
  let { getCurrentMenuID, setCommonStore } = commonStore // ACTIONS
  let { mainMenu, subMenu } = getBreadcrumbs

  const { t } = useTranslation()
  const location = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
  }, [getCurrentMenuID])

  const onActiveMenu = () => {
    setCommonStore({
      breadcrumbs: {
        ...getCurrentMenuID,
        mainMenu: "หนักหลัก",
        subMenu: []
      }
    })
    navigate("/home-page")
  }

  return (
    <nav className="flex ml-2">
      <ol role="list" className="flex items-center space-x-2">
        <li>
          <div>
            <a onClick={() => onActiveMenu()} className="cursor-pointer text-gray-400 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-300">
              <HomeIcon className="h-4 w-4 flex-shrink-0" aria-hidden="true" />
            </a>
          </div>
        </li>
        {
          location.pathname !== "/home-page" ? <>
            <li key={mainMenu}>
              <div className="flex items-center">
                <ChevronRightIcon className="h-3 w-4 flex-shrink-0 text-gray-400" aria-hidden="true" />
                <a
                  className="ml-2 text-xs2 font-medium text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-300"
                >
                  {t(mainMenu)}
                </a>
              </div>
            </li>
            {
              subMenu.map((pageName) => (
                <li key={pageName}>
                  <div className="flex items-center">
                    <ChevronRightIcon className="h-4 w-4 flex-shrink-0 text-gray-400" aria-hidden="true" />
                    <a
                      className="ml-2 text-xs2 font-medium text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-300"
                    >
                      {t(pageName)}
                    </a>
                  </div>
                </li>
              ))}
          </>
            : <li>
              <div>
                <a onClick={() => onActiveMenu()} className="cursor-pointer text-gray-400 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-300">
                  <div className="flex items-center">
                    <ChevronRightIcon className="h-3 w-4 flex-shrink-0 text-gray-400" aria-hidden="true" />
                    <a
                      className="ml-2 text-xs2 font-medium text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-300"
                    >
                      หนัาหลัก
                    </a>
                  </div>
                </a>
              </div>
            </li>
        }
      </ol>
    </nav>
  )
}))

export default Breadcrumbs;