import React, { useEffect, useState } from 'react'
import { useNavigate } from "react-router-dom";
import 'react-notifications-component/dist/theme.css'
import Header from "./Header";
import Sidebar from './Sidebar'
import './styles/content.css'
import { inject, observer } from 'mobx-react';
import Authentication from '../Authentication';

export declare type ContentSize = 'default' | 'medium' | 'full';

const MainLayout = inject("authenStore", "commonStore")(observer((props) => {
  let {
    component,
    contentSize = 'default',
    subMenuId = 0,
    fullScreen = false,
    classContent
  } = props
  let commonStore = props['commonStore']
  let authenStore = props['authenStore']
  let { setCommonStore, getExpandSidebar } = commonStore // STATE
  let { getAccountAuth } = authenStore // STATE
  let expand = getExpandSidebar
  const navigate = useNavigate();
  let auth = getAccountAuth

  const [sidebarOpen, setSidebarOpen] = useState(true)

  useEffect(() => {
  }, [getAccountAuth])

  return (
    <Authentication>
      <div>
        <Sidebar
          expand={expand}
          open={sidebarOpen}
          onClose={() => setSidebarOpen(false)}
        />
        <div className={`flex flex-col ${expand ? 'md:pl-60' : 'md:pl-14'} content-overflow-y`}>
          <div>
            <Header
              onExpand={() => setCommonStore({ expandSidebar: !expand })}
              onOpen={(open) => setSidebarOpen(open)}
            />
            <div className={`${!fullScreen && 'flex justify-center'}`}>
              <div className={`${!fullScreen && 'm-6'} ${classContent} w-full`}>
                {component}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Authentication>
  )
}))

export default MainLayout;