
const PageNotFound = (props) => {
    return (
        <div className="min-h-full pt-16 pb-12 flex flex-col">
            <main className="flex-grow flex flex-col justify-center max-w-7xl w-full mx-auto px-4 sm:px-6 lg:px-8">
                <div className="flex-shrink-0 flex justify-center">
                </div>
                <div className="py-16">
                    <div className="text-center">
                        <p className="text-3xl font-semibold text-red-500  uppercase tracking-wide">404</p>
                        <h1 className="mt-2 text-4xl font-extrabold text-gray-900 dark:text-white tracking-tight sm:text-5xl ">Page not found.</h1>
                        <p className="mt-2 text-base text-gray-500 dark:text-gray-400">Sorry, we couldn’t find the page you’re looking for.</p>
                        <div className="mt-6">
                            <a href="/home-page" className="text-base font-medium text-primary-600 hover:text-primary-700">
                                Go back home<span aria-hidden="true"> &rarr;</span>
                            </a>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    )
}

export default PageNotFound