import axios from 'axios'
import { BASE_URL } from '../AppConfig'
import IAuth from '../interfaces/Common/IAuth'

interface Izone {
  zone_set?: string
  code?: string
}


export default {
  //#region USERS
  getUsers: async function (auth: IAuth) {
    try {
      const response = await axios.get(`${BASE_URL}/user/list`, {
        headers: { action_user_id: auth?.action_user_id, token_id: auth?.token_id }
      })
      return response.data
    } catch (error) {
      throw error
    }
  },

  addUser: async function (auth: IAuth, data) {
    const response = await axios.post(`${BASE_URL}/user/add`, {
      employee_code: data.employee_code,
      username: data.username,
      password: data.password,
      first_name: data.first_name,
      last_name: data.last_name,
      email: data.email,
      phone_no: data.phone_no,
      position: data.position,
      user_status: data.user_status,
      user_group_id: data.user_group_id,
      role_level: data.role_level
    },
      {
        headers: { action_user_id: auth?.action_user_id, token_id: auth?.token_id }
      })
    return response
  },

  updateUser: async function (auth: IAuth, data) {
    const response = await axios.put(`${BASE_URL}/user/update`, {
      user_id: data.user_id,
      employee_code: data.employee_code,
      first_name: data.first_name,
      last_name: data.last_name,
      email: data.email,
      phone_no: data.phone_no,
      position: data.position,
      user_status: data.user_status,
      user_group_id: data.user_group_id,
      role_level: data.role_level
    },
      {
        headers: { action_user_id: auth?.action_user_id, token_id: auth?.token_id }
      })
    return response
  },

  deleteUser: async function (auth: IAuth, user_id) {
    try {
      const response = await axios.delete(`${BASE_URL}/user/delete`, {
        headers: {
          action_user_id: auth?.action_user_id, token_id: auth?.token_id
        },
        data: { user_id }
      })
      return response.data
    } catch (error) {
      throw error
    }
  },

  changePessword: async function (auth: IAuth, data) {
    const response = await axios.put(`${BASE_URL}/user/changepass`, {
      user_id: auth?.action_user_id,
      current_password: data.current_password,
      new_password: data.new_password,
      confirm_new_password: data.confirm_new_password,
    },
      {
        headers: { action_user_id: auth?.action_user_id, token_id: auth?.token_id }
      })
    return response
  },

  resetPassword: async function (auth: IAuth, user_id) {
    const response = await axios.post(`${BASE_URL}/user/reset`, { user_id },
      {
        headers: { action_user_id: auth?.action_user_id, token_id: auth?.token_id }
      })
    return response
  },
  //#endregion

  //#region USER GROUP
  getUserGroup: async function (auth: IAuth) {
    try {
      const response = await axios.get(`${BASE_URL}/usrgroup/list`, {
        headers: { action_user_id: auth?.action_user_id, token_id: auth?.token_id }
      })
      return response.data
    } catch (error) {
      throw error
    }
  },

  addUserGroup: async function (auth: IAuth, data) {
    const response = await axios.post(`${BASE_URL}/usrgroup/add`, {
      group_name: data.group_name,
      description: data.description,
      group_status: data.group_status
    },
      {
        headers: { action_user_id: auth?.action_user_id, token_id: auth?.token_id }
      })
    return response
  },

  updateUserGroup: async function (auth: IAuth, data) {
    const response = await axios.put(`${BASE_URL}/usrgroup/update`, {
      group_id: data.group_id,
      group_name: data.group_name,
      description: data.description,
      group_status: data.group_status
    },
      {
        headers: { action_user_id: auth?.action_user_id, token_id: auth?.token_id }
      })
    return response
  },

  deleteUserGroup: async function (auth: IAuth, group_id) {
    try {
      const response = await axios.delete(`${BASE_URL}/usrgroup/delete`, {
        headers: {
          action_user_id: auth?.action_user_id, token_id: auth?.token_id
        },
        data: { group_id }
      })
      return response.data
    } catch (error) {
      throw error
    }
  },
  //#endregion

  //#region ROLES
  getRoleByGroup: async function (auth: IAuth, group_id) {
    try {
      const response = await axios.get(`${BASE_URL}/role/list?group_id=${group_id}`, {
        headers: { action_user_id: auth?.action_user_id, token_id: auth?.token_id }
      })
      return response.data
    } catch (error) {
      throw error
    }
  },

  updateRoles: async function (auth: IAuth, data) {
    try {
      const response = await axios.put(`${BASE_URL}/role/update`, data,
        {
          headers: { action_user_id: auth?.action_user_id, token_id: auth?.token_id }
        })
      return response.data
    } catch (error) {
      throw error
    }
  },
  //#endregion

  getZone: async function (auth: IAuth, data: Izone) {
    let prmList: any = []
    for (const key in data) {
      if (data[key] !== "") prmList.push(`${key}=${data[key]}`)
    }
    let param = prmList.join('&');
    try {
      const response = await axios.get(`${BASE_URL}/zone/list${param !== "" ? `?${param}` : param}`, {
        headers: { action_user_id: auth?.action_user_id, token_id: auth?.token_id }
      })
      return response.data
    } catch (error) {
      throw error
    }
  }
}