
import DataGrid, { Paging, Column, Pager } from 'devextreme-react/data-grid';

const DataTable = (props) => {
  let { dataSource } = props // STATE
  let { onEdit, onDelete } = props // ACTION

  return (<div className="">
    <DataGrid
      dataSource={dataSource}
      keyExpr="id"
      showBorders={true}
      columnAutoWidth={true}
      hoverStateEnabled={true}
    >
      <Paging defaultPageSize={10} />
      <Pager
        showInfo={true}
        showPageSizeSelector={true}
        allowedPageSizes={[10, 25, 50]}
      />
      <Column dataField="cust_name" caption="ชื่อลูกค้า" />
      <Column dataField="shipto_name" caption="สาขา" />
      <Column dataField="username" caption="ชื่อผู้ใช้งาน" />
      <Column dataField="created_date" caption="วันที่สร้าง" />
      <Column dataField="status_name" caption="สถานะ" />
      <Column
        fixed={true}
        caption="แก้ไข/ลบ"
        type="buttons"
        alignment="center"
        width={80}
        buttons={[
          {
            hint: "แก้ไข",
            icon: "fa fa-pencil-square-o",
            visible: true,
            onClick: (e) => {
              e.cancel = "true"
              onEdit(e.row.data)
            }
          },
          {
            hint: "ลบ",
            icon: "trash",
            visible: true,
            onClick: (e) => {
              e.cancel = "true"
              let data = e.row.data
              onDelete(data)
            }
          }
        ]} />
    </DataGrid>
  </div>
  )
}

export default DataTable