
import { inject, observer } from 'mobx-react';
import DataGrid, { Paging, Column, Pager } from 'devextreme-react/data-grid';

const DataTable = inject("pictureStore")(observer((props) => {
  let { dataSource } = props // STATE
  let { onEdit } = props // ACTION

  return (<div className="">
    <DataGrid
      dataSource={dataSource}
      keyExpr="no"
      height="calc(100vh - 285px)"
      showBorders={true}
      columnAutoWidth={true}
      hoverStateEnabled={true}
    >
      <Paging defaultPageSize={20} />
      <Pager
        showInfo={true}
        showPageSizeSelector={true}
        allowedPageSizes={[10, 20, 50]}
      />
      <Column
        dataField="no"
        caption="ลำดับ"
        width={50}
        alignment="center"
      />
      <Column dataField="job_type_code" caption="Job Type" />
      <Column dataField="job_type_name" caption="Job Type Name" />
      <Column dataField="pic_count" caption="Number of Images" />
      <Column
        fixed={true}
        caption="Setting Images"
        type="buttons"
        width={120}
        buttons={[
          {
            hint: "แก้ไข",
            icon: "fa fa-pencil-square-o",
            visible: true,
            onClick: (e) => {
              e.cancel = "true"
              let data = e.row.data
              onEdit(data)
            }
          }
        ]} />

    </DataGrid>
  </div>
  )
}))

export default DataTable