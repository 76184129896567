
import { useEffect, useState, useRef } from "react";
import { inject, observer } from 'mobx-react';
import { Card, Button, ModalAlert, FormLoading, Input } from '../../components'
import { SearchIcon } from '@heroicons/react/outline'
import { DatePicker } from 'antd';
import { UploadQueueService, QueueService } from "../../services";
import { CloudDownloadIcon, PlusIcon, SaveIcon, TrashIcon, UploadIcon } from "@heroicons/react/solid";
import DataGrid, { Paging, Column, Scrolling } from 'devextreme-react/data-grid';
import './style.css'
import ModalQueue from '../Plan/ModalQueue'
import ModalEditUpload from './ModalEditUpload'
import TableUpload from "./TableUpload";
import TableQueue from "./TableQueue";
import moment from 'moment'
import { BASE_URL } from '../../AppConfig'
import { isEmpty } from 'lodash'

const UploadJob = inject("authenStore", "commonStore")(observer((props) => {
  let authenStore = props.authenStore
  let { getAccountAuth } = authenStore // STATE
  let auth = { action_user_id: getAccountAuth?.user_id, token_id: getAccountAuth?.access_token }

  const inputRef: any = useRef(null);
  const [importReady, setImportReady] = useState(false)
  const [openAddQueue, setOpenAddQueue] = useState(false)
  const [openEditQueue, setOpenEditQueue] = useState({
    show: false,
    data: {}
  })
  const [loading, setLoading] = useState({ visible: false, tip: "กำลังโหลด" })
  const [fileUploadName, setFileUploadName] = useState("")
  const [documents, setDocuments]: any = useState([])
  const [showTableName, setShowTableName] = useState("upload")
  const [dataUpload, setDataUpload]: any = useState([])
  const [dataQueue, setDataQueue]: any = useState([])
  const [dataEdit, setDataEdit]: any = useState({})
  const [dateSearch, setDateSearch]: any = useState("")
  const [queueSelected, setQueueSelected]: any = useState([])
  const [queueDateCurrent, setQueueDateCurrent]: any = useState("")
  const [refNo, setRefNo]: any = useState("")
  const [optionAlert, setOptionAlert]: any = useState({
    show: false,
    action: {
      id: "",
      name: ""
    },
    type: "success",
    title: "",
    description: {
      visible: false,
      type: "",
      content: "",
      contentLists: [],

    }
  })


  useEffect(() => {

    getDocuments()
  }, [])

  const initialData = () => {
    setQueueSelected("")
    setDataEdit({})
  }


  const getDocuments = () => {
    UploadQueueService.getDocuments()
      .then((res: any) => {
        setDocuments(res.result)
      })
      .catch((err) => {

      })
  }


  const handleFileChange = (e) => {
    if (!e.target.files) return;

    setShowTableName("upload")
    setDataQueue([])
    // VALIDATE FILE UPLOAD
    let isError = false
    const fileLists = Array.from(e.target?.files)
    fileLists.map((file: any) => {
      let nameSplite = file.name.split('.')
      let fileType = nameSplite[(nameSplite.length - 1)]
      setFileUploadName(file.name)
      if (!["xls", "xlsx"].includes(fileType)) {
        setOptionAlert({
          ...optionAlert,
          type: "danger",
          show: true,
          action: {
            id: "",
            name: ""
          },
          title: "อัปโหลดไฟล์ผิดพลาด",
          description: { visible: true, type: "text", content: `สามารถอัปโหลดไฟล์เฉพาะ Excel  เท่านั้น` }
        })

        isError = true
      }
    })

    if (!isError) {
      setLoading({ ...loading, visible: true, tip: 'กำลังตรวจสอบข้อมูล' })

      const data = new FormData();
      fileLists.forEach((file: any, i) => {
        data.append('files', file, file.name);
      });
      fetch(`${BASE_URL}/queue_imp/upload`, {
        method: 'POST',
        body: data,
      })
        .then((res) => res.json())
        .then((data) => {
          let { message, sys_message } = data
          let { import_ready, records } = data?.result
          if (data.code === 200) {
            setImportReady(import_ready === 1 && records.length > 0 ? true : false)
            setDataUpload(data.result)
          }
          else {
            setOptionAlert({
              ...optionAlert,
              type: "danger",
              show: true,
              action: {
                id: "",
                name: ""
              },
              title: "ผิดพลาด",
              description: { visible: true, type: "text", content: `${message} ${sys_message}` }
            })
          }
          setLoading({ ...loading, visible: false, tip: '' })

          // document.getElementById('your_input_id').value=''
          // $('#input-upload').value('')
        })
        .catch((err) => {
          setOptionAlert({
            ...optionAlert,
            type: "danger",
            show: true,
            action: {
              id: "",
              name: ""
            },
            title: "ผิดพลาด",
            description: { visible: true, type: "text", content: `${err}` }
          })
          setLoading({ ...loading, visible: false, tip: '' })
        })
    }
  }

  const importData = () => {
    setLoading({ ...loading, visible: true, tip: 'กำลังนำเข้า...' })

    UploadQueueService.importQueue(auth, dataUpload.records)
      .then((res: any) => {
        let { code, message } = res.data
        if (code === 200) {
          setOptionAlert({
            ...optionAlert,
            type: "success",
            show: true,
            action: {
              id: "",
              name: ""
            },
            title: "นำเข้าข้อมูลสำเร็จ",
            description: { visible: false, type: "text", content: " ", contentLists: [] }
          })
          setDataUpload([])
        }
        else {
          setOptionAlert({
            ...optionAlert,
            type: "danger",
            show: true,
            action: {
              id: "",
              name: ""
            },
            title: "นำเข้าข้อมูลไม่สำเร็จ",
            description: { visible: true, type: "text", content: message }
          })
        }
        setLoading({ ...loading, visible: false, tip: '' })
      })
      .catch((err) => {
        console.log("err  :", err)
        setOptionAlert({
          ...optionAlert,
          type: "danger",
          show: true,
          action: {
            id: "",
            name: ""
          },
          title: "นำเข้าข้อมูลไม่สำเร็จ",
          description: { visible: true, type: "text", content: `${err}` }
        })
        setLoading({ ...loading, visible: false, tip: '' })
      })
  }


  const getImportList = () => {
    UploadQueueService.getListImport(auth, dataUpload.imp_id)
      .then((res: any) => {
        if (res.code === 200) {
          setImportReady(res.result?.import_ready === 1 ? true : false)
          setDataUpload(res.result)
        }
        else {
          // setOptionAlert({
          //   ...optionAlert,
          //   type: "danger",
          //   show: true,
          //   action: {
          //     id: "",
          //     name: ""
          //   },
          //   title: "อัปโหลดไม่สำเร็จ",
          //   description: { visible: true, type: "text", content: `${res?.message}` }
          // })
        }

      })
      .catch((err) => {
        console.log("err  :", err)


      })
  }

  const deleteUpload = (id) => {
    UploadQueueService.deleteQueue(auth, id)
      .then((res: any) => {
        let { code, message } = res
        if (code === 200) {
          setOptionAlert({
            ...optionAlert,
            type: "success",
            show: true,
            action: {
              id: "",
              name: ""
            },
            title: "ลบข้อมูลสำเร็จ",
            description: { visible: false, type: "text", content: " ", contentLists: [] }
          })
          getImportList()
        }
        else {
          setOptionAlert({
            ...optionAlert,
            type: "danger",
            show: true,
            action: {
              id: "",
              name: ""
            },
            title: "ผิดพลาด",
            description: { visible: true, type: "text", content: message }
          })
        }

      })
      .catch((err) => {
        setOptionAlert({
          ...optionAlert,
          type: "danger",
          show: true,
          action: {
            id: "",
            name: ""
          },
          title: "ผิดพลาด",
          description: { visible: true, type: "text", content: `${err}` }
        })
      })
  }


  //#region QUEUE MANAMENT
  const deleteQueue = (qSelected) => {
    let qids = ""
    if (qSelected.length > 0) qids = qSelected.join(',')

    QueueService.deleteQueue(auth, qids)
      .then((res: any) => {
        let { code, message } = res
        if (code === 200) {
          setOptionAlert({
            ...optionAlert,
            type: "success",
            show: true,
            action: {
              id: "",
              name: ""
            },
            title: "ลบข้อมูลสำเร็จ",
            description: { visible: false, type: "text", content: " ", contentLists: [] }
          })
          searchQueue()
        }
        else {
          setOptionAlert({
            ...optionAlert,
            type: "danger",
            show: true,
            action: {
              id: "",
              name: ""
            },
            title: "ลบข้อมูลสำเร็จ",
            description: { visible: true, type: "text", content: message }
          })
        }
        initialData()
      })
      .catch((err) => {

      })
  }

  const searchQueue = (date = "") => {
    // setLoading({ ...loading, visible: true, tip: 'กำลังโหลด...' })
    // setTimeout(() => setLoading({ ...loading, visible: false, tip: '' }), 1000)
    // setDataQueue(DATA_QUEUE)
    setLoading({ ...loading, visible: true, tip: 'กำลังโหลด...' })
    setShowTableName("queue")
    setDataUpload([])


    let delivery_date = date === "" ? dateSearch : date
    setQueueDateCurrent(delivery_date)
    QueueService.getQueue(auth, { delivery_date, id_refno: refNo })
      .then((res: any) => {
        let { code, message, result, sys_message } = res
        if (code === 200) {
          setDataQueue(result)
        }
        else {
          setOptionAlert({
            ...optionAlert,
            type: "danger",
            show: true,
            action: {
              id: "",
              name: ""
            },
            title: "ผิดพลาด",
            description: { visible: true, type: "text", content: `${message} ${sys_message}` }
          })
        }
        setLoading({ ...loading, visible: false, tip: 'กำลังโหลด...' })
      })
      .catch((err) => {
        console.log("err  :", err)
        setLoading({ ...loading, visible: false, tip: 'กำลังโหลด...' })
        setOptionAlert({
          ...optionAlert,
          type: "danger",
          show: true,
          action: {
            id: "",
            name: ""
          },
          title: "ผิดพลาด",
          description: { visible: true, type: "text", content: `${err}` }
        })
      })
  }

  //#endregion
  return (
    <div className="space-y-3 p-3">
      <Card title="อัปโหลดงาน"  >
        <FormLoading loading={loading.visible} tip={loading.tip}>
          <div className="flex flex-row" style={{ height: 'calc(100vh - 178px)' }}>
            <div className="shrink-0 h-16 w-72 p-2 flex-col space-y-2">
              <div className="flex flex-row">
                <span className="inline-flex items-start text-sm font-normal mr-1">
                  <input
                    type="text"
                    placeholder="เลือกไฟล์"
                    className={`
                        block  
                        h-7
                        rounded-md  
                        shadow-sm 
                        focus:border
                        border-gray-300 placeholder-gray-400
                        bg-gray-100
                        sm:text-sm
                        truncate
                    `}
                    value={fileUploadName}
                    disabled
                  />
                  <input
                    id="input-upload"
                    className='hidden'
                    type="file"
                    ref={inputRef}
                    onChange={handleFileChange}
                  />
                </span>

                <Button
                  iconOnly={<UploadIcon />}
                  size="sm"
                  type="white"
                  title="อัปโหลด"
                  onClick={() => inputRef.current.click()}
                />

                <Button
                  iconOnly={<CloudDownloadIcon />}
                  size="sm"
                  type="white"
                  title="ดาวน์โหลดเทมเพลต"
                  // onClick={() => downloadFileTemplate()}
                  onClick={() => window.open(`${BASE_URL}/queue_imp/excel`)}
                />
              </div>
              <DataGrid
                dataSource={documents}
                keyExpr="id"
                height={'calc(100vh - 230px)'}
                // width={200}
                allowColumnReordering={true}
                hoverStateEnabled={true}
                columnAutoWidth={true}
                wordWrapEnabled={true}
                showBorders={true}
              >
                <Scrolling columnRenderingMode="virtual" />
                <Paging enabled={false} />
                <Column
                  dataField="upload_date"
                  caption="วันที่"
                  cellRender={(e) => moment(e.value).format('DD/MM/YYYY')}
                />
                <Column dataField="filename" caption="ชื่อไฟล์" />
              </DataGrid>
            </div>

            <div className={`flex-1 w-96 flex-col overflow-auto border-l border-bold pt-2 px-2 space-y-2`}>
              <div className="flex flex-row">
                <div className="flex-1 flex flex-row">
                  <span className="inline-flex items-center text-sm font-normal mr-2">
                    วันที่ :
                  </span>

                  <DatePicker
                    allowClear={false}
                    style={{ width: 150, height: 28, marginRight: 4 }}
                    placeholder="วันที่"
                    // defaultValue={dataForm.delivery_date}
                    onChange={(d) => {
                      if (d)
                        setDateSearch(d.format("YYYY-MM-DD"))
                      else
                        setDateSearch("")
                    }}
                  />

                  <div style={{ marginTop: -4, marginRight: 4 }}>
                    <Input
                      label="เลขที่ใบสั่งงาน"
                      placeholder="เลขที่ใบสั่งงาน"
                      hiddenLabel={true}
                      size="xs"
                      onChange={(e) => setRefNo(e.target.value)}
                    />
                  </div>

                  <Button
                    iconOnly={<SearchIcon />}
                    size="sm"
                    type="white"
                    title="ค้นหา"
                    onClick={() => {
                      if (dateSearch === "") {
                        setOptionAlert({
                          ...optionAlert,
                          action: {
                            id: "",
                            name: ""
                          },
                          type: "info",
                          title: "โปรดระบุวันที่",
                          show: true,
                          description: {
                            visible: false,
                            type: "text",
                            content: "",
                            contentLists: []
                          }
                        })
                      }
                      else {
                        searchQueue()
                      }
                    }}
                  />

                  <Button
                    text="เพิ่มข้อมููลใบส่งสินค้า"
                    icon={<PlusIcon />}
                    size="sm"
                    type="white"
                    iconPosition='start'
                    onClick={() => setOpenAddQueue(true)}
                  />

                  {
                    showTableName === "queue" && <Button
                      text="ลบข้อมูลใบส่งสินค้า"
                      icon={<TrashIcon />}
                      size="sm"
                      type="white"
                      iconPosition='start'
                      onClick={() => {
                        if (queueSelected.length === 0) {
                          setOptionAlert({
                            ...optionAlert,
                            action: {
                              id: "",
                              name: ""
                            },
                            type: "info",
                            title: "โปรดเลือกคิวที่ต้องการลบ",
                            show: true,
                            description: {
                              visible: false,
                              type: "text",
                              content: "",
                              contentLists: []
                            }
                          })
                        }
                        else {
                          setOptionAlert({
                            ...optionAlert,
                            action: {
                              id: "",
                              name: 'deleteQueue'
                            },
                            type: "confirm",
                            title: "ยืนยัน",
                            show: true,
                            description: {
                              visible: true,
                              type: "text",
                              content: "คุณแน่ใจที่จะลบข้อมูล ?",
                              contentLists: []
                            }
                          })
                        }
                      }
                      }
                    />
                  }

                </div>
                <div className={`flex-1 flex flex-row-reverse ${!importReady && 'sr-only'}`}>
                  <Button
                    text="นำเข้า"
                    icon={<SaveIcon />}
                    size="sm"
                    width="w-20"
                    type="primary"
                    onClick={() => setOptionAlert({
                      ...optionAlert,
                      action: {
                        id: "",
                        name: 'import'
                      },
                      type: "confirm",
                      title: "ยืนยัน",
                      show: true,
                      description: {
                        visible: true,
                        type: "text",
                        content: "คุณแน่ใจที่จะบันทึกข้อมูล ?",
                        contentLists: []
                      }
                    })}
                  />
                </div>
              </div>


              {
                showTableName === "upload" ? <TableUpload
                  dataSource={dataUpload.records}
                  optionAlert={openEditQueue}
                  setOpenEditQueue={(data) => setOpenEditQueue({
                    ...openEditQueue,
                    show: true,
                    data
                  })}
                  onDelete={(id) => setOptionAlert({
                    ...optionAlert,
                    action: {
                      id,
                      name: 'delete'
                    },
                    type: "confirm",
                    title: "ยืนยัน",
                    show: true,
                    description: {
                      visible: true,
                      type: "text",
                      content: "คุณแน่ใจที่จะลบข้อมูล ?",
                      contentLists: []
                    }
                  })}
                />
                  : <TableQueue
                    dataSource={dataQueue}
                    optionAlert={openEditQueue}
                    height={'calc(100vh - 230px)'}
                    onSelectionChanged={(select) => setQueueSelected(select)}
                    onEdit={(data) => {
                      data.actionBy = "queue"
                      data.actionPage = 'upload'
                      setDataEdit(data)
                      setOpenAddQueue(true)
                    }}
                  />
              }

            </div>
          </div>
        </FormLoading>
      </Card>
      <ModalQueue
        auth={auth}
        open={openAddQueue}
        mode={isEmpty(dataEdit) ? "add" : "edit"}
        dataEdit={dataEdit}
        onClosed={(reload, delivery_date) => {
          if (delivery_date === queueDateCurrent) searchQueue(delivery_date)
          setOpenAddQueue(false)
          setDataEdit({})
        }}
      />
      <ModalEditUpload
        auth={auth}
        open={openEditQueue.show}
        data={openEditQueue.data}
        onClosed={(reload) => {
          reload && getImportList()
          setOpenEditQueue({ ...openEditQueue, data: {}, show: false })
        }}
      />

      <ModalAlert
        {...optionAlert}
        onOk={async () => {
          let { action } = optionAlert
          if (action.name === "import") {
            importData()
          }
          else if (action.name === "delete") {
            deleteUpload(action.id)
          }
          else if (action.name === "deleteQueue") {
            deleteQueue(queueSelected)
          }
          setOptionAlert({ ...optionAlert, show: false })
        }}
        onCancel={() => {
          setOptionAlert({ ...optionAlert, show: false })
        }}
      />
    </div>
  )
}))

export default UploadJob