import { useState, useEffect } from 'react'
import { inject, observer } from 'mobx-react';
import { Card, Button, ModalAlert, FormLoading } from '../../../components'
import { PictureService } from "../../../services";
import ModalJobTypePicture from './ModalJobTypePicture';
import ModalManagement from './ModalManagement';
import DataTable from './DataTable';
import { isEmpty } from 'lodash'
import { CogIcon } from '@heroicons/react/solid';

let firstRender = false
const Picture = inject("authenStore")(observer((props) => {
  let authenStore = props.authenStore
  let { getAccountAuth } = authenStore // STATE
  let auth = { action_user_id: getAccountAuth?.user_id, token_id: getAccountAuth?.access_token }

  const [masterOptions, setMasterOptions] = useState({
    vehicle: [],
    ma_item: [],
    ma_type: [],
    ma_status: [],
    alert_status: [],
    garage: []
  })

  const [loading, setLoading]: any = useState(false)
  const [dataSource, setDataSource]: any = useState([])
  const [dataEditPic, seEditPic]: any = useState([])
  const [openManage, setOpenManage]: any = useState(false)
  const [openJobManage, setOpenJobManage]: any = useState(false)
  const [dataEdit, setDataEdit]: any = useState({})
  const [optionAlert, setOptionAlert]: any = useState({
    show: false,
    action: {
      id: "",
      name: ""
    },
    type: "success",
    title: "",
    description: {
      visible: false,
      type: "",
      content: "",
      contentLists: [],

    }
  })

  useEffect(() => {
    if (firstRender || !isEmpty(getAccountAuth)) {
      getJobTyePicture()
    }
    else
      firstRender = true
  }, [getAccountAuth])


  const getJobTyePicture = () => {
    PictureService.getJobTyePicture(auth)
      .then((res: any) => {
        let { code, message, result, sys_message } = res
        if (code === 200) {
          setDataSource(result)
        }
        else {
          setOptionAlert({
            ...optionAlert,
            type: "danger",
            show: true,
            action: {
              id: "",
              name: ""
            },
            title: "ผิดพลาด",
            description: { visible: true, type: "text", content: `${message} ${sys_message}` }
          })
          setLoading(false)
        }
      })
      .catch((err) => {
        console.log("err  :", err)
        setOptionAlert({
          ...optionAlert,
          type: "danger",
          show: true,
          action: {
            id: "",
            name: ""
          },
          title: "ผิดพลาด",
          description: { visible: true, type: "text", content: `${err}` }
        })
      })
  }

  return (<>
    <Card
      title="ตั้งค่าภาพถ่าย"
      extra={<Button
        text="จัดการภาพถ่าย"
        icon={<CogIcon />}
        iconPosition='start'
        onClick={() => {
          setOpenManage(true)
        }}
      />}
    >
      <div className="mt-5 md:col-span-2 md:mt-0">
        <FormLoading loading={loading} >
          <DataTable
            dataSource={dataSource}
            onEdit={(data) => {
              setOpenJobManage(true)
              seEditPic(data)
            }}
          />
        </FormLoading>
      </div>

      <ModalManagement
        auth={auth}
        open={openManage}
        data={dataEdit}
        mode={isEmpty(dataEdit) ? "add" : "edit"}
        masterOptions={masterOptions}
        onClosed={(reload) => {
          setOpenManage(false)
          reload && getJobTyePicture()
        }}
      />
      <ModalJobTypePicture
        auth={auth}
        open={openJobManage}
        dataEdit={dataEditPic}
        mode={isEmpty(dataEdit) ? "add" : "edit"}
        masterOptions={masterOptions}
        onChange={(data) => seEditPic(data)}
        onClosed={(reload) => {
          setOpenJobManage(false)
          setDataEdit({})
          reload && getJobTyePicture()
        }}
      />
    </Card >

    <ModalAlert
      {...optionAlert}
      onOk={() => {
        setOptionAlert({ ...optionAlert, show: false })
      }}
      onCancel={() => {
        setOptionAlert({ ...optionAlert, show: false })
      }}
    />
  </>
  )
}))

export default Picture