import { useEffect, useState, useRef } from 'react'
import { inject, observer } from 'mobx-react';
import FormMaster from '../FormMaster'
import { CommonService, DocumentService } from "../../../services";
import { Modal, Button, ModalAlert, FormLoading } from '../../../components'
import DataGrid, { Paging, Column, Scrolling, Editing, Selection, RequiredRule } from 'devextreme-react/data-grid';
import { isEmpty, get } from 'lodash';
import { CogIcon, PlusIcon } from '@heroicons/react/outline';

let firstRender = false
const Document = inject("authenStore", "masterStore")(observer((props) => {
  let authenStore = props.authenStore
  let masterStore = props.masterStore
  let { getAccountAuth } = authenStore // STATE
  let { setMasterStore } = masterStore
  let auth = { action_user_id: getAccountAuth?.user_id, token_id: getAccountAuth?.access_token }

  const gridRef: any = useRef(null);
  const [modal, setModal]: any = useState({
    show: false,
    data: {},
    docs: []
  })
  const [loading, setLoading]: any = useState(false)
  const [loadingDoc, setLoadingDoc] = useState({ visible: false, tip: "กำลังโหลด" })
  const [openModalDoc, setOpenModalDoc]: any = useState(false)
  const [docSelected, setDocSelected]: any = useState([])
  const [docLists, setDocLists]: any = useState([])
  const [masterOptions, setMasterOptions]: any = useState({ doc: [] })
  const [optionAlert, setOptionAlert]: any = useState({
    show: false,
    status: "",
    type: "success",
    title: "",
    description: {
      visible: false,
      type: "",
      content: "",
      contentLists: [],

    }
  })

  useEffect(() => {
    if (firstRender || !isEmpty(getAccountAuth)) {
      CommonService.getMasterData(auth, "doc")
        .then((res: any) => {
          let doc = res.result.doc.map((item) => ({ doc_name: item.doc_name, doc_code: item.doc_code }))
          setMasterOptions({ ...masterOptions, doc })
        })
        .catch((err) => {
          setOptionAlert({
            ...optionAlert,
            type: "danger",
            show: true,
            action: {
              id: "",
              name: ""
            },
            title: "ผิดพลาด",
            description: { visible: true, content: `Load master data : ${err}` }
          })
        })
    }
    else
      firstRender = true
  }, [getAccountAuth])

  useEffect(() => {
    openModalDoc && getDocs()
  }, [openModalDoc])

  const clearForm = () => {
    setDocSelected([])
    setModal({
      show: false,
      data: {},
      docs: []
    })
  }

  //#region  MANAMGE DOCUMENT
  const getDocs = () => {
    setLoadingDoc({ ...loading, visible: true, tip: "กำลังโหลด..." })
    DocumentService.getDocs(auth)
      .then((res: any) => {
        let { code, message, result, sys_message } = res?.data
        if (code === 200) {
          setDocLists(result)
        }
        else {
          setDocLists([])
          setOptionAlert({
            ...optionAlert,
            type: "danger",
            show: true,
            action: {
              id: "",
              name: ""
            },
            title: "ผิดพลาด",
            description: { visible: true, type: "text", content: `${message} ${sys_message}` }
          })
        }
        setLoadingDoc({ ...loading, visible: false })
      })
      .catch((err) => {
        setDocLists([])
        setLoadingDoc({ ...loading, visible: false })
        setOptionAlert({
          ...optionAlert,
          type: "danger",
          show: true,
          action: {
            id: "",
            name: ""
          },
          title: "ผิดพลาด",
          description: { visible: true, type: "text", content: `${err}` }
        })
      })
  }

  const manageDoc = (action = "", data) => {
    setLoadingDoc({ ...loading, visible: true, tip: "กำลังบันทึก..." })

    if (action === "insert") {
      DocumentService.addDoc(auth, {
        doc_code: data?.doc_code,
        doc_name: data?.doc_name,
      })
        .then((res: any) => {
          let { code, message, sys_message } = res.data
          if (code === 200) {
            setOptionAlert({
              ...optionAlert,
              type: "success",
              show: true,
              status: "",
              title: "บันทึกข้อมูลสำเร็จ",
              description: { visible: false, type: "text", content: " ", contentLists: [] }
            })
            getDocs()
          }
          else {
            setOptionAlert({
              ...optionAlert,
              type: "danger",
              show: true,
              status: "",
              title: "ผิดพลาด",
              description: { visible: true, type: "text", content: `${message} ${sys_message}` }
            })
          }
          setLoadingDoc({ ...loading, visible: false })
        })
        .catch((err) => {
          setOptionAlert({
            ...optionAlert,
            type: "danger",
            show: true,
            action: {
              id: "",
              name: ""
            },
            title: "ผิดพลาด",
            description: { visible: true, type: "text", content: `${err}` }
          })
          setLoadingDoc({ ...loading, visible: false })
        })
    }
    else if (action === "update") {
      DocumentService.updateDoc(auth, {
        doc_id: data?.doc_id,
        doc_code: data?.doc_code,
        doc_name: data?.doc_name,
      })
        .then((res: any) => {
          let { code, message, sys_message } = res.data
          if (code === 200) {
            setOptionAlert({
              ...optionAlert,
              type: "success",
              show: true,
              status: "",
              title: "บันทึกข้อมูลสำเร็จ",
              description: { visible: false, type: "text", content: " ", contentLists: [] }
            })
            getDocs()
          }
          else {
            setOptionAlert({
              ...optionAlert,
              type: "danger",
              show: true,
              status: "",
              title: "ผิดพลาด",
              description: { visible: true, type: "text", content: `${message} ${sys_message}` }
            })
          }
          setLoadingDoc({ ...loading, visible: false })
        })
        .catch((err) => {
          setOptionAlert({
            ...optionAlert,
            type: "danger",
            show: true,
            action: {
              id: "",
              name: ""
            },
            title: "ผิดพลาด",
            description: { visible: true, type: "text", content: `${err}` }
          })
          setLoadingDoc({ ...loading, visible: false })
        })
    }
  }

  const deleteDoc = (doc_id) => {
    DocumentService.deleteDoc(auth, doc_id)
      .then((res: any) => {
        let { code, message, sys_message } = res
        if (code === 200) {
          setOptionAlert({
            ...optionAlert,
            type: "success",
            show: true,
            action: {
              id: "",
              name: ""
            },
            title: "ลบข้อมูลสำเร็จ",
            description: { visible: false, type: "text", content: " ", contentLists: [] }
          })
          getDocs()
        }
        else {
          setOptionAlert({
            ...optionAlert,
            type: "danger",
            show: true,
            action: {
              id: "",
              name: ""
            },
            title: "ผิดพลาด",
            description: { visible: true, type: "text", content: `${message} ${sys_message}` }
          })
        }
      })
      .catch((err) => {
        setOptionAlert({
          ...optionAlert,
          type: "danger",
          show: true,
          action: {
            id: "",
            name: ""
          },
          title: "ผิดพลาด",
          description: { visible: true, type: "text", content: `${err}` }
        })
      })
  }
  //#endregion

  const updateDocSet = () => {
    setLoading(true)
    let { data } = modal
    DocumentService.updateDocSet(auth, {
      func_id: data?.id,
      doc_list: docSelected
    })
      .then((res: any) => {
        let { code, message, sys_message } = res.data
        if (code === 200) {
          setOptionAlert({
            ...optionAlert,
            type: "success",
            show: true,
            status: "",
            title: "บันทึกข้อมูลสำเร็จ",
            description: { visible: false, type: "text", content: " ", contentLists: [] }
          })
          setMasterStore({ isRefreshData: true })
          clearForm()
        }
        else {
          setOptionAlert({
            ...optionAlert,
            type: "danger",
            show: true,
            status: "",
            title: "ผิดพลาด",
            description: { visible: true, type: "text", content: `${message} ${sys_message}` }
          })
        }
        setLoading(false)
      })
      .catch((err) => {
        setOptionAlert({
          ...optionAlert,
          type: "danger",
          show: true,
          action: {
            id: "",
            name: ""
          },
          title: "ผิดพลาด",
          description: { visible: true, type: "text", content: `${err}` }
        })
        setLoading(false)
      })
  }

  const onSave = () => {
    setOptionAlert({
      ...optionAlert,
      type: "confirm",
      title: "ยืนยัน",
      show: true,
      description: {
        visible: true,
        type: "text",
        content: "คุณแน่ใจที่จะบันทึกข้อมูล ?",
        contentLists: []
      }
    })
  }

  return <>
    <FormMaster
      title="ตั้งค่าเอกสาร"
      cardExtra={<Button
        text="จัดการเอกสาร"
        icon={<CogIcon />}
        iconPosition='start'
        onClick={() => setOpenModalDoc(true)}
      />}
      tableConfig={{
        key: "id",
        hiddenAddButton: true,
        apiPath: "doc_set/list",
        allowDelete: {
          visible: false,
          key: "",
          apiPath: ""
        },
        optionDeleteFag: true,
        defaultColumn: [],
        columns: [
          {
            dataField: 'no',
            caption: "ลำดับ",
            width: 60,
            alignment: "center",
            allowFiltering: false
          },
          {
            dataField: 'func_name',
            caption: "หน้าจอ"
          },
          {
            dataField: 'doc_count',
            caption: "จำนวนเอกสาร"
          },
          {
            dataField: 'updated_by',
            caption: "แก้ไขโดย"
          }
        ],
        exportExcel: {
          fileName: 'จัดการเอกสาร',
          columnConfig: [
            { label: 'ลำดับ', value: 'no' },
            { label: 'หน้าจอ', value: 'func_name' },
            { label: 'จำนวนเอกสาร', value: 'doc_count' },
            { label: 'แก้ไขโดย', value: 'updated_by' }
          ]
        }
      }}
      modalConfig={{
        visible: false,
        title: "",
        required: [],
        fieldTypeNumber: [],
        paramName: "",
        apiPath: {
          "get": '',
          "post": '',
          "put": ''
        },
        elements: []
      }}
      onEdit={(data) => {
        let dt = data.modalConfig.dataSelectRow
        const getDocName = (doc_code) => {
          let doc = masterOptions.doc.find((item) => item.doc_code === doc_code)
          if (doc) return doc?.doc_name
        }

        let docLists: any = [], docSelected: any = []
        dt?.doc_list.forEach((doc_code, id) => {
          let doc_name = getDocName(doc_code)
          docLists.push({ id, doc_code, doc_name })
          docSelected.push(doc_code)
        });

        setDocSelected(docSelected)
        setModal({ ...modal, show: true, data: dt, docs: docLists })
      }}
    />

    <Modal
      title={{
        text: "ตั้งค่าเอกสาร",
        description: <>
          หน้าจอ : {modal?.data?.func_name}
        </>
      }}
      open={modal.show}
      maskClosable={true}
      contentPadding="p-0"
      width='lg:w-2/4 sm:4/4'
      onClosed={() => clearForm()}
      onOk={() => onSave()}
    >
      <FormLoading loading={loading} tip="กำลังบันทึก...">
        <div className="space-y-2" style={{ padding: 20 }}>
          <label className="block text-sm font-medium text-gray-700 mb-1">เลือกรายการเอกสาร</label>
          <DataGrid
            dataSource={masterOptions.doc}
            keyExpr="doc_code"
            showBorders={true}
            allowColumnReordering={true}
            columnAutoWidth={false}
            hoverStateEnabled={true}
            height={300}
            defaultSelectedRowKeys={docSelected}
            onSelectionChanged={(e) => setDocSelected(e.selectedRowKeys)}
          >
            <Scrolling columnRenderingMode="virtual" />
            <Selection
              mode="multiple"
              allowSelectAll={true}
              showCheckBoxesMode="always"
            />
            <Paging enabled={false} />
            <Column dataField="doc_code" caption="รหัสเอกสาร" />
            <Column dataField="doc_name" caption="ชื่อเอกสาร" />

          </DataGrid>
        </div>
      </FormLoading>
    </Modal>

    <Modal
      title={{ text: "จัดการเอกสาร" }}
      open={openModalDoc}
      maskClosable={true}
      contentPadding="p-0"
      width='lg:w-2/4 sm:4/4'
      onClosed={() => clearForm()}
      onOk={() => {
        setOptionAlert({
          ...optionAlert,
          action: {
            id: "",
            name: "update_fuel_set"
          },
          type: "confirm",
          title: "ยืนยัน",
          show: true,
          description: {
            visible: true,
            type: "text",
            content: "คุณแน่ใจที่จะบันทึกข้อมูล ?",
            contentLists: []
          }
        })
      }}
      extra={<Button
        text="เพิ่มข้อมูล"
        icon={<PlusIcon />}
        size="sm"
        type="white"
        iconPosition='start'
        onClick={() => gridRef.current.instance.addRow()}
      />}
      footer={<Button
        text="ปิด"
        width="w-20"
        onClick={() => setOpenModalDoc(false)}
      />
      }
    >
      <FormLoading loading={loadingDoc.visible} tip={loadingDoc.tip}>
        <div className="space-y-2" style={{ padding: 20 }}>
          <DataGrid
            ref={(ref) => gridRef.current = ref}
            dataSource={docLists}
            keyExpr="id"
            showBorders={true}
            allowColumnReordering={true}
            columnAutoWidth={false}
            hoverStateEnabled={true}
            height={400}
            onSaving={(e) => {
              if (!isEmpty(e.changes)) {
                let change = e.changes[0]
                let { key, type, data } = change
                if (type === "insert") {
                  manageDoc("insert", data)
                }
                else if (type === "update") {
                  let currentDoc = docLists.find((item) => item.id === key)
                  manageDoc("update", {
                    doc_id: key,
                    doc_code: get(data, 'doc_code', currentDoc?.doc_code),
                    doc_name: get(data, 'doc_name', currentDoc?.doc_name)
                  })
                }
                else if (type === "remove") {
                  deleteDoc(key)
                }

              }
            }}
          >
            <Scrolling columnRenderingMode="virtual" />
            <Paging enabled={false} />
            <Editing
              mode="row"
              allowUpdating={true}
              allowDeleting={true}
              texts={{
                editRow: "แก้ไข",
                deleteRow: "ลบ",
                cancelRowChanges: "ยกเลิก",
                saveRowChanges: "บันทึก"
              }} />
            <Column
              dataField="no"
              caption="ลำดับ"
              width={60}
              alignment="center"
              allowEditing={false}
              allowFiltering={false}
              allowSorting={false}
            />
            <Column dataField="doc_code" caption="รหัสเอกสาร" >
              <RequiredRule />
            </Column>
            <Column dataField="doc_name" caption="ชื่อเอกสาร" >
              <RequiredRule />
            </Column>
          </DataGrid>
        </div>
      </FormLoading>
    </Modal>


    <ModalAlert
      {...optionAlert}
      onOk={() => {
        let { type } = optionAlert
        if (type === "confirm") updateDocSet()

        setOptionAlert({ ...optionAlert, show: false })
      }}
      onCancel={() => {
        setOptionAlert({ ...optionAlert, show: false })
      }}
    />
  </>
}))

export default Document