
import { useState, useRef, useEffect } from 'react'
import { inject, observer } from 'mobx-react';
import { Modal, ModalAlert, FormLoading, DatePicker, InputTextarea } from '../../../components'
import { createMultipleRef, getValueInputRef } from '../../../helpers';
import { CalendarService } from '../../../services';
import { get, isEmpty } from 'lodash'

const ModalCalendar = inject("calendarStore")(observer((props) => {
  let calendarStore = props.calendarStore
  let { setCalendarStore, getCalendarData, openModalCalendar } = calendarStore // STATE

  let { auth } = props // STATES
  let { onReload } = props // ACTIONS

  const [loading, setLoading]: any = useState({ visible: false, tip: 'กำลังบันทึก...' })
  const [validation, setValidation]: any = useState({})
  const [shakeIsWrong, setShakeIsWrong] = useState(false)
  const [optionAlert, setOptionAlert]: any = useState({
    show: false,
    action: {
      id: "",
      name: ""
    },
    type: "success",
    title: "",
    description: {
      visible: false,
      type: "",
      content: "",
      contentLists: [],

    }
  })
  const [dataForm, setDataForm]: any = useState({
    "id": "",
    "timeid": "",
    "time_desc": ""
  })
  const inputRef: any = useRef([]);
  createMultipleRef(inputRef, 1)

  useEffect(() => {
    if (get(getCalendarData, 'id')) {
      let { timeid, time_desc, id } = getCalendarData
      setDataForm({ timeid, time_desc, id })
    }
    else {
      setDataForm({
        "id": "",
        "timeid": "",
        "time_desc": ""
      })
    }
  }, [openModalCalendar, getCalendarData])


  const onSave = () => {
    let validateCheck = {}, message = { isError: true, message: "โปรดระบุ" }
    let formData = getValueInputRef(inputRef)
    if (dataForm.timeid.trim() === "")
      validateCheck['timeid'] = message
    if (formData.time_desc === "")
      validateCheck['time_desc'] = message

    if (isEmpty(validateCheck)) {
      setValidation({})
      setOptionAlert({
        ...optionAlert,
        action: {
          id: dataForm.id,
          name: dataForm.id === "" ? 'create' : 'update'
        },
        type: "confirm",
        title: "ยืนยัน",
        show: true,
        description: {
          visible: true,
          type: "text",
          content: "คุณแน่ใจที่จะบันทึกข้อมูล ?",
          contentLists: []
        }
      })
    }
    else {
      setValidation(validateCheck)
      setShakeIsWrong(true)
      setTimeout(() => setShakeIsWrong(false), 100);
    }
  }

  const saveData = () => {
    let formData = getValueInputRef(inputRef)
    setLoading({ ...loading, visible: true, tip: "กำลังบันทึก..." })

    if (optionAlert.action.name === "create") {
      CalendarService.saveCalendar(auth, {
        timeid: dataForm?.timeid,
        time_desc: formData.time_desc
      })
        .then((res: any) => {
          let { code, message, sys_message } = res.data
          if (code === 200) {
            setOptionAlert({
              ...optionAlert,
              type: "success",
              show: true,
              action: {
                id: "",
                name: "",
                data: {}
              },
              title: "บันทึกข้อมูลสำเร็จ",
              description: { visible: false, type: "text", content: " ", contentLists: [] }
            })
            onReload()
            clearForm()
          }
          else {
            setOptionAlert({
              ...optionAlert,
              type: "danger",
              show: true,
              action: {
                id: "",
                name: "",
                data: {}
              },
              title: "ผิดพลาด",
              description: { visible: true, type: "text", content: `${message} ${sys_message}` }
            })
          }
          setLoading({ ...loading, visible: false })
        })
        .catch((err) => {
          setLoading({ ...loading, visible: false })
          setOptionAlert({
            ...optionAlert,
            type: "danger",
            show: true,
            action: {
              id: "",
              name: "",
              data: {}
            },
            title: "ผิดพลาด",
            description: { visible: true, type: "text", content: `${err}` }
          })
        })
    }
    else {
      CalendarService.updateCalendar(auth, {
        id: dataForm?.id,
        timeid: dataForm?.timeid,
        time_desc: formData.time_desc,
      })
        .then((res: any) => {
          let { code, message, sys_message } = res.data

          if (code === 200) {
            setOptionAlert({
              ...optionAlert,
              type: "success",
              show: true,
              action: {
                id: "",
                name: "",
                data: {}
              },
              title: "บันทึกข้อมูลสำเร็จ",
              description: { visible: false, type: "text", content: " ", contentLists: [] }
            })
            onReload()
            clearForm()
          }
          else {
            setOptionAlert({
              ...optionAlert,
              type: "danger",
              show: true,
              action: {
                id: "",
                name: "",
                data: {}
              },
              title: "ผิดพลาด",
              description: { visible: true, type: "text", content: `${message} ${sys_message}` }
            })
          }
          setLoading({ ...loading, visible: false })
        })
        .catch((err) => {
          setLoading({ ...loading, visible: false })
          setOptionAlert({
            ...optionAlert,
            type: "danger",
            show: true,
            action: {
              id: "",
              name: "",
              data: {}
            },
            title: "ผิดพลาด",
            description: { visible: true, type: "text", content: `${err}` }
          })
        })
    }
  }

  const clearForm = () => {
    setValidation({})
    setDataForm({
      "timeid": "",
      "time_desc": ""
    })
    setCalendarStore({
      openModalCalendar: false,
      calendarData: {}
    })
  }

  return (<>
    <Modal
      title={{ text: `${getCalendarData?.id ? 'แก้ไข' : 'เพิ่ม'}วันหยุด` }}
      open={openModalCalendar}
      maskClosable={true}
      shake={shakeIsWrong}
      width='lg:w-96 '
      okDisabled={loading.visible}
      onClosed={() => {
        clearForm()
      }}
      onOk={() => onSave()}
    >
      <FormLoading loading={loading.visible} tip={loading.tip}>
        <div className="flex flex-col space-y-2">
          <div className='flex-1'>
            <DatePicker
              allowClear={false}
              label="วันที่"
              placeholder="วันที่"
              defaultValue={dataForm.timeid}
              validate={validation?.timeid}
              required={true}
              onChange={(d) => {
                if (d) {
                  setDataForm({ ...dataForm, timeid: d.format("YYYY-MM-DD") })
                }
                else {
                  setDataForm({ ...dataForm, timeid: "" })
                }
              }}
            />
          </div>

          <div className='flex-1'>
            <InputTextarea
              id="time_desc"
              label="รายละเอียด"
              placeholder="รายละเอียด"
              defaultValue={dataForm.time_desc}
              validate={validation?.time_desc}
              required={true}
              onRef={(ref) => inputRef.current[0] = ref}
            />
          </div>
        </div>
      </FormLoading>
    </Modal>

    <ModalAlert
      {...optionAlert}
      onOk={() => {
        let { action } = optionAlert
        if (["create", "update"].includes(action.name)) saveData()

        setOptionAlert({ ...optionAlert, show: false })
      }}
      onCancel={() => {
        setOptionAlert({ ...optionAlert, show: false })
      }}
    />
  </>
  )
}))

export default ModalCalendar