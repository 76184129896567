import { useState, useEffect } from 'react'
import { inject, observer } from 'mobx-react';
import { Card, Button, ModalAlert, FormLoading } from '../../../components'
import { CommonService, CauseReasonService } from "../../../services";
import { PlusIcon } from '@heroicons/react/outline';
import ModalCause from './ModalCause';
import ModalReason from './ModalReason';
import DataTable from './DataTable';
import { isEmpty } from 'lodash'

let firstRender = false
const CauseReason = inject("causeReasonStore", "authenStore")(observer((props) => {
  let causeReasonStore = props.causeReasonStore
  let authenStore = props.authenStore
  let { getAccountAuth } = authenStore // STATE
  let auth = { action_user_id: getAccountAuth?.user_id, token_id: getAccountAuth?.access_token }

  let { setCauseStore } = causeReasonStore // ACTIONS

  const [masterOptions, setMasterOptions] = useState({
    common_status: []
  })

  const [loading, setLoading]: any = useState(false)
  const [dataSource, setDataSource]: any = useState([])
  const [open, setOpen]: any = useState(false)
  const [openReason, setOpenReason]: any = useState(false)
  const [dataEdit, setDataEdit]: any = useState({})
  const [dataReasonEdit, setDataReasonEdit]: any = useState({})
  const [optionAlert, setOptionAlert]: any = useState({
    show: false,
    action: {
      id: "",
      name: ""
    },
    type: "success",
    title: "",
    description: {
      visible: false,
      type: "",
      content: "",
      contentLists: [],

    }
  })

  useEffect(() => {
    if (firstRender || !isEmpty(getAccountAuth)) {
      getCauseList()
      CommonService.getMasterData(auth, "common_status")
        .then((res: any) => {
          let common_status = res.result.common_status.map((item) => ({ label: item.status_name, value: item.status_code }))

          setMasterOptions({ ...masterOptions, common_status })
        })
        .catch((err) => {
          setOptionAlert({
            ...optionAlert,
            type: "danger",
            show: true,
            action: {
              id: "",
              name: ""
            },
            title: "ผิดพลาด",
            description: { visible: true, content: `Load master data : ${err}` }
          })
        })
    }
    else
      firstRender = true
  }, [getAccountAuth])
  //#region Call services

  //#endregion

  //#region 

  const getCauseList = (isReloadCurrent = false, reloadByRouteID = "") => {
    setLoading(true)
    setDataEdit({})
    setDataReasonEdit({})

    CauseReasonService.getCause(auth)
      .then((res: any) => {
        let { code, message, result, sys_message } = res
        if (code === 200) {
          setDataSource(result)

        }
        else {
          setOptionAlert({
            ...optionAlert,
            type: "danger",
            show: true,
            action: {
              id: "",
              name: ""
            },
            title: "ผิดพลาด",
            description: { visible: true, type: "text", content: `${message} ${sys_message}` }
          })
        }
        setLoading(false)
      })
      .catch((err) => {
        setLoading(false)
        setOptionAlert({
          ...optionAlert,
          type: "danger",
          show: true,
          action: {
            id: "",
            name: ""
          },
          title: "ผิดพลาด",
          description: { visible: true, type: "text", content: `${err}` }
        })
      })
  }
  //#endregion

  return (
    <div className="space-y-3 p-4">
      <Card
        title="ข้อมูลต้นเหตุ-สาเหตุ"
        extra={<Button
          text="เพิ่มข้อมูล"
          icon={<PlusIcon />}
          iconPosition='start'
          onClick={() => {
            setCauseStore({ openCause: true })
          }}
        />}
      >
        <div className="mt-5 md:col-span-2 md:mt-0">
          <FormLoading loading={loading} >
            <DataTable dataSource={dataSource} />
          </FormLoading>
        </div>

        <ModalCause
          auth={auth}
          open={open}
          data={dataEdit}
          mode={isEmpty(dataEdit) ? "add" : "edit"}
          masterOptions={masterOptions}
          onReload={() => getCauseList(true)}
        />

        <ModalReason
          auth={auth}
          open={openReason}
          data={dataReasonEdit}
          mode={isEmpty(dataReasonEdit) ? "add" : "edit"}
          masterOptions={masterOptions}
          onReload={() => getCauseList(true)}
        />
      </Card >

      <ModalAlert
        {...optionAlert}
        onOk={() => {
          setOptionAlert({ ...optionAlert, show: false })
        }}
        onCancel={() => {
          setOptionAlert({ ...optionAlert, show: false })
        }}
      />
    </div>
  )
}))

export default CauseReason