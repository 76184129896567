import { useRef, Fragment, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { createMultipleRef, getValueInputRef } from '../helpers'
import { inject, observer } from 'mobx-react';
import UserService from "../services/UserService";
import { Transition, Menu } from '@headlessui/react'
import { Modal, InputPassword, FormLoading, ModalAlert } from '../components'
import Breadcrumbs from './Breadcrumbs';
import {
  BellIcon,
  LogoutIcon,
  MenuIcon
} from '@heroicons/react/outline'
import { KeyIcon } from '@heroicons/react/solid';
import { isEmpty } from 'lodash'

const Header = inject("authenStore", "commonStore")(observer((props) => {
  const navigate = useNavigate()
  let authenStore = props.authenStore
  let { setCommonValue, getAccountAuth } = authenStore
  let auth = { action_user_id: getAccountAuth?.user_id, token_id: getAccountAuth?.access_token }

  let { onOpen, onExpand } = props // ACTION
  const [loading, setLoading] = useState(false)
  const [shakeIsWrong, setShakeIsWrong]: any = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [validation, setValidation]: any = useState({})
  const [optionAlert, setOptionAlert]: any = useState({
    show: false,
    action: {
      id: "",
      name: "",
      data: {}
    },
    type: "success",
    title: "",
    description: {
      visible: false,
      type: "",
      content: "",
      contentLists: []
    }
  })

  const inputRef: any = useRef([]);
  createMultipleRef(inputRef, 2)

  const onSave = () => {
    let formData = getValueInputRef(inputRef)
    let validation: any = {}
    if (formData.passwordold === "") {
      validation.passwordold = { isError: true, message: "โปรดระบุ" }
    }
    if (formData.passwordnew === "") {
      validation.passwordnew = { isError: true, message: "โปรดระบุ" }
    }
    if (formData.passwordconfirm === "") {
      validation.passwordconfirm = { isError: true, message: "โปรดระบุ" }
    }
    else if (formData.passwordconfirm !== formData.passwordnew) {
      validation.passwordconfirm = { isError: true, message: "รหัสผ่านไม่ตรงกัน" }
    }

    if (isEmpty(validation)) {
      setValidation({})
      setOptionAlert({
        ...optionAlert,
        type: "confirm",
        title: "ยืนยัน",
        show: true,
        description: {
          visible: true,
          type: "text",
          content: "คุณแน่ใจที่จะบันทึกข้อมูล ?",
          contentLists: []
        }
      })
    }
    else {
      setValidation(validation)
      setShakeIsWrong(true)
      setTimeout(() => setShakeIsWrong(false), 100);
    }
  }

  const updatePassword = () => {
    let formData = getValueInputRef(inputRef)
    UserService.changePessword(auth, {
      current_password: formData.passwordold,
      new_password: formData.passwordnew,
      confirm_new_password: formData.passwordconfirm,
    })
      .then((res: any) => {
        let { code, message, sys_message } = res?.data
        if (code === 200) {
          setOptionAlert({
            ...optionAlert,
            type: "success",
            show: true,
            action: {
              id: "",
              name: "",
              data: {}
            },
            title: "บันทึกข้อมูลสำเร็จ",
            description: { visible: false, type: "text", content: " ", contentLists: [] }
          })
          setShowModal(false)
        }
        else {
          setOptionAlert({
            ...optionAlert,
            type: "danger",
            show: true,
            action: {
              id: "",
              name: "",
              data: {}
            },
            title: "ผิดพลาด",
            description: { visible: true, type: "text", content: `${message} ${sys_message}` }
          })
        }
        setLoading(false)
      })
      .catch((err) => {
        setLoading(false)
        setOptionAlert({
          ...optionAlert,
          type: "danger",
          show: true,
          action: {
            id: "",
            name: "",
            data: {}
          },
          title: "ผิดพลาด",
          description: { visible: true, type: "text", content: `${err}` }
        })
      })
  }
  return (
    <>
      <div className="flex flex-col sticky top-0 z-10 h-16 flex-shrink-0 bg-white shadow">
        <div className="flex flex-1 justify-between px-4">
          <div className="flex flex-1">
            <div className="relative inset-y-0 left-0 flex items-center">
              <MenuIcon
                className="h-6 w-6 cursor-pointer text-blue-700 hover:text-blue-400"
                onClick={() => {
                  onOpen(true)
                  onExpand()
                }}
              />
            </div>
          </div>
          <div className="ml-4 flex items-center md:ml-6">
            {/* <button
              type="button"
              className="rounded-full bg-white p-1 text-gray-400 hover:text-gray-500"
            >
              <span className="sr-only">View notifications</span>
              <BellIcon className="h-6 w-6" aria-hidden="true" />
            </button> */}

            {/* Profile dropdown */}
            <Menu as="div" className="relative ml-3 z-50">
              <div>
                <Menu.Button className="flex max-w-xs items-center rounded-full bg-white text-sm">
                  <span className="inline-block h-8 w-8 overflow-hidden rounded-full bg-gray-100">
                    <svg className="h-full w-full text-primary-300" fill="currentColor" viewBox="0 0 24 24">
                      <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                    </svg>
                  </span>
                </Menu.Button>
              </div>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute right-0 mt-2 w-64 origin-top-right rounded-md border bg-white dark:bg-gray-800 py-1 shadow-lg">
                  <div className="flex flex-col items-center justify-center space-y-2">
                    <span className="flex h-10 w-10 overflow-hidden rounded-full bg-gray-100 shadow-md">
                      <svg className="h-full w-full text-primary-300" fill="currentColor" viewBox="0 0 24 24">
                        <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                      </svg>
                    </span>
                    <div className="flex-1">
                      <div className="flex items-center">
                        <h3 className="text-sm font-medium text dark:text-white">{getAccountAuth?.user_fullname}</h3>
                      </div>
                    </div>
                  </div>
                  <div className="w-full border-t border-gray-300" />
                  <div className='mt-2'>
                    <Menu.Item>
                      <a
                        type='button'
                        className='cursor-pointer flex flex-row px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-white dark:hover:bg-gray-300 dark:hover:text-gray-600'
                        onClick={() => setShowModal(true)}
                      >
                        <KeyIcon className='w-5 h-5 mr-2' />เปลี่ยนรหัสผ่าน
                      </a>
                    </Menu.Item>

                    <Menu.Item>
                      <a
                        className='cursor-pointer flex flex-row px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-white dark:hover:bg-gray-300 dark:hover:text-gray-600'
                        onClick={() => {
                          navigate("/")
                          setCommonValue({ accountAuth: {} })
                        }}
                      >
                        <LogoutIcon className='w-5 h-5 mr-2' />ออกจากระบบ
                      </a>
                    </Menu.Item>
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
        </div>
        <div className="flex flex-row border-t border-gray-200 p-1 bg-white">
          <Breadcrumbs />
        </div>
      </div>

      <Modal
        title={{ text: "เปลี่ยนรหัสผ่าน" }}
        open={showModal}
        maskClosable={true}
        contentPadding="p-0"
        shake={shakeIsWrong}
        onClosed={() => !loading && setShowModal(false)}
        onOk={() => !loading && onSave()}
      >
        <FormLoading loading={loading} tip="กำลังบันทึก...">
          <div className="flex flex-col space-y-2" style={{ padding: 20 }}>
            <InputPassword
              id="passwordold"
              label="รหัสผ่านปัจจุบัน"
              placeholder="รหัสผ่านปัจจุบัน"
              hiddenIcon={true}
              required={true}
              validate={validation?.passwordold}
              onRef={(ref) => inputRef.current[0] = ref}
            />

            <InputPassword
              id="passwordnew"
              label="รหัสผ่านใหม่"
              placeholder="รหัสผ่านใหม่"
              hiddenIcon={true}
              required={true}
              validate={validation?.passwordnew}
              onRef={(ref) => inputRef.current[1] = ref}
            />

            <InputPassword
              id="passwordconfirm"
              label="ยืนยันรหัสผ่าน"
              placeholder="ยืนยันรหัสผ่าน"
              hiddenIcon={true}
              required={true}
              validate={validation?.passwordconfirm}
              onRef={(ref) => inputRef.current[2] = ref}
            />
          </div>
        </FormLoading>
      </Modal>

      <ModalAlert
        {...optionAlert}
        onOk={() => {
          let { type } = optionAlert
          if (type === "confirm") updatePassword()
          setOptionAlert({ ...optionAlert, show: false })
        }}
        onCancel={() => setOptionAlert({ ...optionAlert, show: false })}
      />
    </>
  )
}))

export default Header;