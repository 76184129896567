import { useState, useRef, useEffect } from 'react'
import { inject, observer } from 'mobx-react';
import { CommonService, UserService } from "../../services";
import { createMultipleRef, validateInput, getValueInputRef, setOptions } from '../../helpers'
import { Card, Modal, Button, Input, InputPassword, ModalAlert, Select, FormLoading, ExportExcel } from '../../components'
import DataGrid, { SearchPanel, Toolbar, Item, Paging, Pager, Column, HeaderFilter } from 'devextreme-react/data-grid';
import { PlusIcon, RefreshIcon, SearchIcon } from '@heroicons/react/outline';
import { isEmpty } from 'lodash'

let firstRender = false
const Users = inject("authenStore")(observer((props) => {
  let authenStore = props.authenStore
  let { getAccountAuth } = authenStore // STATE
  let auth = { action_user_id: getAccountAuth?.user_id, token_id: getAccountAuth?.access_token }

  const [optionStatus, setOptionStatus] = useState([])
  const [optionRoleLevel, setOptionRoleLevel] = useState([])
  const [loadingTable, setLoadingTable]: any = useState(false)
  const [loadingSaving, setLoadingSaving]: any = useState(false)
  const [dataSource, setDatasource]: any = useState([])
  const [validation, setValidation]: any = useState({})
  const [open, setOpen]: any = useState(false)
  const [userGroupList, setUserGroupList]: any = useState([])
  const [shakeIsWrong, setShakeIsWrong] = useState(false)
  const [optionAlert, setOptionAlert]: any = useState({
    show: false,
    action: {
      id: "",
      name: ""
    },
    type: "success",
    title: "",
    description: {
      visible: false,
      type: "",
      content: "",
      contentLists: []
    }
  })

  const [dataForm, setDataForm]: any = useState({
    "id": "",
    "employee_code": "",
    "username": "",
    "password": "",
    "first_name": "",
    "last_name": "",
    "email": "",
    "phone_no": "",
    "position": "",
    "user_status": "Y",
    "user_group_id": "",
    "role_level": ""
  })

  const gridRef: any = useRef(null);
  const inputRef: any = useRef([]);
  createMultipleRef(inputRef, 7)

  let columns = [
    {
      dataField: 'no',
      caption: "ลำดับ",
      width: 90,
      alignment: "center"
    },
    {
      dataField: 'username',
      caption: "ชื่อล็อคอิน",
      allowFiltering: true
    },
    {
      dataField: 'first_name',
      caption: "ชื่อผู้ใช้งาน",
      allowFiltering: true
    },
    {
      dataField: 'group_name',
      caption: "กลุ่มผู้ใช้งาน",
      allowFiltering: true
    },
    {
      dataField: 'position',
      caption: "ตำแหน่ง",
      allowFiltering: true
    },
    {
      dataField: 'email',
      caption: "อีเมล"
    },
    {
      dataField: 'status_name',
      caption: "สถานะ",
      width: 100,
      allowFiltering: true
    },
    {
      dataField: 'updated_by',
      caption: "แก้ไขโดย"
    }
  ]

  let validateSchema: any = [
    {
      id: 'username',
      required: { value: true, message: "โปรดระบุ" },
    },
    {
      id: 'password',
      required: { value: true, message: "โปรดระบุ" },
    }
  ]

  useEffect(() => {
    if (firstRender || !isEmpty(getAccountAuth)) {
      getUsers()
      CommonService.getMasterData(auth, "common_status,role_level")
        .then((res: any) => {
          let options = res.result.common_status.map((item) => ({ label: item.status_name, value: item.status_code }))
          let role_level = res.result.role_level.map((item) => ({ label: item.level_name, value: item.role_level }))

          setOptionStatus(options)
          setOptionRoleLevel(role_level)
        })
        .catch((err) => {
          setOptionAlert(setOptions({
            defaultOption: optionAlert,
            show: true,
            type: "danger",
            title: "ผิดพลาด",
            description: { visible: true, content: `Load master data : ${err}` }
          }))
        })
    }
    else
      firstRender = true
  }, [getAccountAuth])

  const initialData = () => {
    setValidation({})
    setDataForm({
      "id": "",
      "employee_code": "",
      "username": "",
      "password": "",
      "first_name": "",
      "last_name": "",
      "email": "",
      "phone_no": "",
      "position": "",
      "user_status": "Y",
      "user_group_id": "",
      "role_level": ""
    })
  }

  //#region Call services

  const getUsers = () => {
    setLoadingTable(true)
    UserService.getUsers(auth)
      .then((res: any) => {
        setDatasource(res?.result || [])
        setLoadingTable(false)
      })
      .catch((err) => {
        setDatasource([])
        setLoadingTable(false)
      })

    UserService.getUserGroup(auth)
      .then((res: any) => {
        let userGroup = res?.result.map((item) => ({ value: item.id, label: item.group_name }))
        setUserGroupList(userGroup || [])
      })
      .catch((err) => {
        setUserGroupList([])
      })
  }

  const deleteUser = (id) => {
    UserService.deleteUser(auth, id)
      .then((res: any) => {
        let { code, message } = res
        if (code === 200) {
          setOptionAlert(setOptions({
            defaultOption: optionAlert,
            show: true,
            title: "ลบข้อมูลสำเร็จ"
          }))
          getUsers()
        }
        else {
          setOptionAlert(setOptions({
            defaultOption: optionAlert,
            show: true,
            type: "danger",
            title: "ผิดพลาด",
            description: { visible: true, content: message }
          }))
        }
      })
      .catch((err) => {
        setOptionAlert(setOptions({
          defaultOption: optionAlert,
          show: true,
          type: "danger",
          title: "ผิดพลาด",
          description: { visible: true, content: `${err}` }
        }))
      })
  }

  const resetPassword = (id) => {
    UserService.resetPassword(auth, id)
      .then((res: any) => {
        let { code, message } = res.data
        if (code === 200) {
          setOptionAlert(setOptions({
            defaultOption: optionAlert,
            show: true,
            title: "รีเซตรหัสผ่านสำเร็จ"
          }))
        } else {
          setOptionAlert(setOptions({
            defaultOption: optionAlert,
            show: true,
            type: "danger",
            title: "ผิดพลาด",
            description: { visible: true, content: message }
          }))
        }
      })
      .catch((err) => {
        setOptionAlert(setOptions({
          defaultOption: optionAlert,
          show: true,
          type: "danger",
          title: "ผิดพลาด",
          description: { visible: true, content: `${err}` }
        }))
      })
  }

  const saveData = () => {
    let formData = getValueInputRef(inputRef)
    setLoadingSaving(true)
    if (optionAlert.action.name === "create") {
      UserService.addUser(auth, {
        employee_code: formData.employee_code,
        username: formData.username,
        password: formData.password,
        first_name: formData.first_name,
        last_name: formData.last_name,
        email: formData.email,
        phone_no: formData.phone_no,
        position: formData.position,
        user_status: dataForm.user_status,
        user_group_id: dataForm.user_group_id,
        role_level: dataForm.role_level
      })
        .then((res: any) => {
          let { code, message } = res.data
          if (code === 200) {
            setOptionAlert(setOptions({
              defaultOption: optionAlert,
              show: true,
              title: "บันทึกข้อมูลสำเร็จ"
            }))
            getUsers()
            initialData()
            setOpen(false)
          }
          else {
            setOptionAlert(setOptions({
              defaultOption: optionAlert,
              show: true,
              type: "danger",
              title: "ผิดพลาด",
              description: { visible: true, content: message }
            }))
          }
          setLoadingSaving(false)
        })
        .catch((err) => {
          setLoadingSaving(false)
          setOptionAlert(setOptions({
            defaultOption: optionAlert,
            show: true,
            type: "danger",
            title: "ผิดพลาด",
            description: { visible: true, content: `${err}` }
          }))
        })
    }
    else {
      UserService.updateUser(auth, {
        user_id: dataForm.id,
        employee_code: formData.employee_code,
        first_name: formData.first_name,
        last_name: formData.last_name,
        email: formData.email,
        phone_no: formData.phone_no,
        position: formData.position,
        user_status: dataForm.user_status,
        user_group_id: dataForm.user_group_id,
        role_level: dataForm.role_level
      })
        .then((res: any) => {
          let { code, message } = res.data
          setOpen(false)
          if (code === 200) {
            setOptionAlert(setOptions({
              defaultOption: optionAlert,
              show: true,
              title: "บันทึกข้อมูลสำเร็จ"
            }))
            getUsers()
          }
          else {
            setOptionAlert(setOptions({
              defaultOption: optionAlert,
              show: true,
              type: "danger",
              title: "ผิดพลาด",
              description: { visible: true, content: message }
            }))
          }
          initialData()
          setLoadingSaving(false)
        })
        .catch((err) => {
          setOptionAlert(setOptions({
            defaultOption: optionAlert,
            show: true,
            type: "danger",
            title: "ผิดพลาด",
            description: { visible: true, content: `${err}` }
          }))
          setLoadingSaving(false)
        })
    }
  }
  //#endregion

  //#region 
  const onSave = () => {
    let validation = validateInput({
      schema: validateSchema,
      inputRef
    })
    if (dataForm.user_group_id === "") {
      validation.user_group_id = { isError: true, message: "โปรดระบุ" }
    }
    if (dataForm.role_level === "") {
      validation.role_level = { isError: true, message: "โปรดระบุ" }
    }

    if (isEmpty(validation)) {
      setValidation({})
      setOptionAlert(setOptions({
        defaultOption: alert,
        action: {
          id: dataForm.id,
          name: dataForm.id === "" ? 'create' : 'update'
        },
        show: true,
        type: "confirm",
        title: "ยืนยัน",
        description: {
          visible: true,
          content: "คุณแน่ใจที่จะบันทึกข้อมูล ?"
        }
      }))

    }
    else {
      setValidation(validation)
      setShakeIsWrong(true)
      setTimeout(() => setShakeIsWrong(false), 100);
    }
  }
  //#endregion

  return (
    <>
      <Card
        title="ข้อมูลผู้ใช้งาน"
        extra={<Button
          text="เพิ่มข้อมูล"
          icon={<PlusIcon />}
          iconPosition='start'
          onClick={() => setOpen(true)}
        />}
      >
        <div className="mt-5 md:col-span-2 md:mt-0">
          <FormLoading loading={loadingTable} tip="">
            <DataGrid
              ref={(ref) => gridRef.current = ref}
              dataSource={dataSource}
              keyExpr={'id'}
              showBorders={true}
              wordWrapEnabled={false}
              hoverStateEnabled={true}
              columnAutoWidth={true}
            >
              <SearchPanel visible={true} />
              <Paging defaultPageSize={10} />
              <Pager
                showInfo={true}
                showPageSizeSelector={true}
                allowedPageSizes={[10, 20, 50]}
              />
              <HeaderFilter visible={true} />
              {
                columns.map((column) => <Column
                  key={column.dataField}
                  width={column.width}
                  allowFiltering={column.allowFiltering || false}
                  dataField={column.dataField}
                  caption={column.caption}
                  alignment={column.alignment}
                />)
              }
              <Column
                fixed={true}
                caption="แก้ไข/ลบ"
                type="buttons"
                width={80}
                buttons={[
                  {
                    hint: "แก้ไข",
                    icon: "fa fa-pencil-square-o",
                    visible: true,
                    onClick: (e) => {
                      e.cancel = "true"
                      let data = e.row.data
                      setDataForm({
                        "id": data.id,
                        "employee_code": data?.employee_code,
                        "username": data.username,
                        "first_name": data.first_name,
                        "last_name": data.last_name,
                        "email": data.email,
                        "phone_no": data.phone_no,
                        "position": data.position,
                        "user_status": data.user_status,
                        "user_group_id": data.group_id,
                        "role_level": data.role_level
                      })
                      setOpen(true)
                    }
                  },
                  {
                    hint: "ลบ",
                    icon: "trash",
                    visible: true,
                    onClick: (e) => {
                      e.cancel = "true"
                      let data = e.row.data

                      setOptionAlert(setOptions({
                        defaultOption: alert,
                        action: {
                          id: data.id,
                          name: 'delete'
                        },
                        show: true,
                        type: "confirm",
                        title: "ยืนยัน",
                        description: {
                          visible: true,
                          content: "คุณแน่ใจที่จะลบข้อมูล ?"
                        }
                      }))
                    }
                  }
                ]} />
              <Toolbar>
                <Item location="after">
                  <button
                    type="button"
                    className="-mb-1 inline-flex items-center px-1.5 py-1.5 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50"
                    title="Refresh"
                    onClick={() => getUsers()}
                  >
                    <RefreshIcon className="w-4 h-4" />
                  </button>
                </Item>

                <Item location="after">
                  <ExportExcel
                    filename="ข้อมูลผู้ใช้งาน"
                    data={dataSource}
                    columnConfig={[
                      { label: 'ลำดับ', value: 'no' },
                      { label: 'ชื่อล็อคอิน', value: 'username' },
                      { label: 'ชื่อผู้ใช้งาน', value: 'first_name' },
                      { label: 'กลุ่มผู้ใช้งาน', value: 'group_name' },
                      { label: 'ตำแหน่ง', value: 'position' },
                      { label: 'อีเมล', value: 'email' },
                      { label: 'สถานะ', value: 'status_name' },
                      { label: 'แก้ไขโดย', value: 'updated_by' }
                    ]}
                  />
                </Item>

                <Item location="after">
                  <Input
                    icon={<SearchIcon className="w-5 h-5 text-gray-500" />}
                    placeholder="ค้นหา..."
                    onChange={(e) => gridRef.current.instance.searchByText(e.target.value)}
                  />
                </Item>
              </Toolbar>
            </DataGrid>
          </FormLoading>
        </div>

        <Modal
          title={{ text: `${dataForm.id === "" ? 'เพิ่ม' : 'แก้ไข'}ผู้ใช้งาน` }}
          open={open}
          maskClosable={true}
          shake={shakeIsWrong}
          loading={loadingSaving}
          appendButton={dataForm.id !== "" ? <Button
            text="Reset รหัสผ่าน"
            type="white"
            width="w-auto"
            onClick={() => setOptionAlert(setOptions({
              defaultOption: alert,
              action: {
                id: dataForm.id,
                name: "reset-pass"
              },
              show: true,
              type: "confirm",
              title: "ยืนยัน",
              description: {
                visible: true,
                content: "คุณแน่ใจที่จะรีเซตรหัสผ่าน ?"
              }
            }))}
          /> : null}
          onClosed={() => {
            setOpen(false)
            initialData()
          }}
          onOk={() => onSave()}
        >
          <div className='md:space-y-2'>
            <div className="flex md:flex-row sm:flex-col xs:flex-col md:space-x-4">
              <div className='flex-1'>
                <Select
                  showSearch={true}
                  label="กลุ่มผู้ใช้งาน"
                  required={true}
                  placeholder="กลุ่มผู้ใช้งาน"
                  validate={validation?.user_group_id}
                  onChange={(select) => setDataForm({ ...dataForm, user_group_id: select })}
                  defaultValue={dataForm.user_group_id === "" ? [] : [dataForm.user_group_id]}
                  options={userGroupList}
                />
              </div>

              <div className='flex-1'>
                <Select
                  label="สถานะ"
                  placeholder="สถานะ"
                  onChange={(select) => setDataForm({ ...dataForm, user_status: select })}
                  defaultValue={[dataForm.user_status]}
                  options={optionStatus}
                />
              </div>
            </div>

            <div className="flex md:flex-row sm:flex-col xs:flex-col md:space-x-4">
              <div className='flex-1'>
                <Input
                  id="first_name"
                  label="ชื่อ"
                  placeholder="ชื่อ"
                  defaultValue={dataForm.first_name}
                  validate={validation?.first_name}
                  onRef={(ref) => inputRef.current[0] = ref}
                />
              </div>

              <div className='flex-1'>
                <Input
                  id="last_name"
                  label="นามสกุล"
                  placeholder="นามสกุล"
                  defaultValue={dataForm.last_name}
                  onRef={(ref) => inputRef.current[1] = ref}
                />
              </div>
            </div>

            <div className="flex md:flex-row sm:flex-col xs:flex-col md:space-x-4">
              <div className='flex-1'>
                <Input
                  id="employee_code"
                  label="รหัสพนักงาน"
                  placeholder="รหัสพนักงาน"
                  defaultValue={dataForm.employee_code}
                  onRef={(ref) => inputRef.current[2] = ref}
                />
              </div>

              <div className='flex-1'>
                <Input
                  id="position"
                  label="ตำแหน่ง"
                  placeholder="ตำแหน่ง"
                  defaultValue={dataForm.position}
                  validate={validation?.position}
                  onRef={(ref) => inputRef.current[3] = ref}
                />
              </div>
            </div>

            <div className="flex md:flex-row sm:flex-col xs:flex-col md:space-x-4">
              <div className='flex-1'>
                <Input
                  id="username"
                  label="ชื่อล็อคอิน"
                  placeholder="ชื่อล็อคอิน"
                  disabled={dataForm.id === "" ? false : true}
                  required={dataForm.id === "" ? true : false}
                  defaultValue={dataForm.username}
                  validate={validation?.username}
                  onRef={(ref) => inputRef.current[4] = ref}
                />
              </div>

              <div className='flex-1'>
                {
                  dataForm.id === "" ? <InputPassword
                    id="password"
                    label="รหัสผ่าน"
                    placeholder="รหัสผ่าน"
                    hiddenIcon={true}
                    required={true}
                    defaultValue={dataForm.password}
                    validate={validation?.password}
                    onRef={(ref) => inputRef.current[5] = ref}
                  /> : <Input
                    id="phone_no"
                    label="เบอร์โทร"
                    placeholder="เบอร์โทร"
                    defaultValue={dataForm.phone_no}
                    onRef={(ref) => inputRef.current[6] = ref}
                  />
                }
              </div>
            </div>

            <div className="flex md:flex-row sm:flex-col xs:flex-col md:space-x-4">
              <div className='flex-1'>
                <Input
                  id="email"
                  label="อีเมล"
                  placeholder="อีเมล"
                  defaultValue={dataForm.email}
                  validate={validation?.email}
                  onRef={(ref) => inputRef.current[7] = ref}
                />
              </div>

              <div className='flex-1'>
                {
                  dataForm.id === "" ? <Input
                    id="phone_no"
                    label="เบอร์โทร"
                    placeholder="เบอร์โทร"
                    defaultValue={dataForm.phone_no}
                    onRef={(ref) => inputRef.current[6] = ref}
                  /> :
                    <Select
                      showSearch={true}
                      label="ระดับผู้ใช้งาน"
                      required={true}
                      placeholder="ระดับผู้ใช้งาน"
                      validate={validation?.role_level}
                      onChange={(select) => setDataForm({ ...dataForm, role_level: select })}
                      defaultValue={dataForm.role_level === "" ? [] : [dataForm.role_level]}
                      options={optionRoleLevel}
                    />
                }
              </div>
            </div>

            {
              dataForm.id === "" && <>
                <div className="flex md:flex-row sm:flex-col xs:flex-col md:space-x-4">
                  <div className='flex-1'>
                    <Select
                      showSearch={true}
                      label="ระดับผู้ใช้งาน"
                      required={true}
                      placeholder="ระดับผู้ใช้งาน"
                      validate={validation?.role_level}
                      onChange={(select) => setDataForm({ ...dataForm, role_level: select })}
                      defaultValue={dataForm.role_level === "" ? [] : [dataForm.role_level]}
                      options={optionRoleLevel}
                    />
                  </div>
                  <div className='flex-1'></div>
                </div>
              </>
            }


          </div>
        </Modal>
      </Card >

      <ModalAlert
        {...optionAlert}
        onOk={() => {
          let { action } = optionAlert
          if (["create", "update"].includes(action.name))
            saveData()
          else if (action.name === "delete")
            deleteUser(action.id)
          else if (action.name === "reset-pass")
            resetPassword(action.id)

          setOptionAlert(setOptions({
            defaultOption: alert,
            show: false
          }))
        }}
        onCancel={() => {
          setOptionAlert(setOptions({
            defaultOption: alert,
            show: false
          }))
        }}
      />
    </>
  )
}))

export default Users