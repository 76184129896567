import { useEffect, useState, useRef } from 'react'
import { inject, observer } from 'mobx-react';
import FormMaster from '../FormMaster'
import { createMultipleRef, getValueInputRef } from '../../../helpers'
import { Modal, Button, Input, Select, ModalAlert, FormLoading, DatePicker } from '../../../components'
import { CommonService, DriverService } from "../../../services";
import { get, isEmpty } from 'lodash'
import DataGrid, { Editing, Paging, Column } from 'devextreme-react/data-grid';
import { Image } from 'antd';
import { UploadIcon } from "@heroicons/react/solid";
import { BASE_URL } from '../../../AppConfig'

let doc_id_upload = "", firstRender = false
const Driver = inject("masterStore", "authenStore")(observer((props) => {
  let authenStore = props.authenStore
  let { getAccountAuth } = authenStore // STATE
  let auth = { action_user_id: getAccountAuth?.user_id, token_id: getAccountAuth?.access_token }

  let masterStore = props.masterStore
  let { setMasterStore } = masterStore
  const [modalConfig, setModalConfig]: any = useState({
    show: false,
    action: "",
    id: ""
  })
  const gridRef: any = useRef(null);
  const [loading, setLoading]: any = useState(false)
  const [loadingUpload, setLoadingUpload]: any = useState(false)
  const [loadingUploadDoc, setLoadingUploadDoc]: any = useState(false)
  const [optionStatus, setOptionStatus] = useState([])
  const [optionSubcontract, setOptionSubcontract] = useState([])
  const [validation, setValidation]: any = useState({})
  const [shakeIsWrong, setShakeIsWrong] = useState(false)
  const [fileUploadName, setFileUploadName] = useState("")
  const [uploadInfo, setUploadInfo]: any = useState({
    service_id: "",
    sub_id: "",
    doc_id: "",
  })
  const [dataForm, setDataForm]: any = useState({
    id: "",
    sub_id: "",
    picture_profile: "",
    citizen_id: "",
    service_code: "",
    code_ref: "",
    first_name: "",
    last_name: "",
    nick_name: "",
    position: "",
    department: "",
    phone_no: "",
    law_cards: [],
    docs: [],
    status: "Y",
    start_date: ""
  })

  const [optionAlert, setOptionAlert]: any = useState({
    show: false,
    action: {
      id: "",
      name: ""
    },
    type: "success",
    title: "",
    description: {
      visible: false,
      type: "",
      content: "",
      contentLists: [],

    }
  })

  const inputUploadRef: any = useRef(null);
  const inputUploadDocRef: any = useRef(null);
  const inputRef: any = useRef([]);
  createMultipleRef(inputRef, 7)

  useEffect(() => {
    if (firstRender || !isEmpty(getAccountAuth)) {
      CommonService.getMasterData(auth, "common_status,subcontract")
        .then((res: any) => {
          let common_status = res.result.common_status.map((item) => ({ label: item.status_name, value: item.status_code }))
          let subcontract = res.result.subcontract.map((item) => ({ label: item.sub_name, value: `${item.sub_id}` }))

          setOptionStatus(common_status)
          setOptionSubcontract(subcontract)
        })
        .catch((err) => {
          setOptionAlert({
            ...optionAlert,
            type: "danger",
            show: true,
            action: {
              id: "",
              name: ""
            },
            title: "ผิดพลาด",
            description: { visible: true, content: `Load master data : ${err}` }
          })
        })
    }
    else
      firstRender = true
  }, [getAccountAuth])

  //#region 
  const onSave = () => {
    let validateCheck = {}, message = { isError: true, message: "โปรดระบุ" }
    let formData = getValueInputRef(inputRef)

    if (formData.citizen_id.trim() === "")
      validateCheck['citizen_id'] = message
    if (formData.first_name.trim() === "")
      validateCheck['first_name'] = message
    if (formData.last_name.trim() === "")
      validateCheck['last_name'] = message
    if (formData.phone_no.trim() === "")
      validateCheck['phone_no'] = message
    if (dataForm.sub_id.length === 0 || dataForm.sub_id === "")
      validateCheck['sub_id'] = message

    if (isEmpty(validateCheck)) {
      setValidation({})
      setOptionAlert({
        ...optionAlert,
        action: {
          id: dataForm.id,
          name: dataForm.id === "" ? 'create' : 'update'
        },
        type: "confirm",
        title: "ยืนยัน",
        show: true,
        description: {
          visible: true,
          type: "text",
          content: "คุณแน่ใจที่จะบันทึกข้อมูล ?",
          contentLists: []
        }
      })
    }
    else {
      setValidation(validateCheck)
      setShakeIsWrong(true)
      setTimeout(() => setShakeIsWrong(false), 100);
    }
  }

  const saveData = () => {
    let formData = getValueInputRef(inputRef)

    if (optionAlert.action.name === "create") {
      DriverService.addDriver(auth, {
        sub_id: dataForm.sub_id,
        citizen_id: formData.citizen_id,
        service_code: formData.service_code,
        code_ref: formData.code_ref,
        first_name: formData.first_name,
        last_name: formData.last_name,
        nick_name: formData.nick_name,
        position: formData.position,
        department: formData.department,
        phone_no: formData.phone_no,
        status: dataForm.status,
        start_date: dataForm.start_date
      })
        .then((res: any) => {
          let { code, message, sys_message } = res.data
          if (code === 200) {
            setOptionAlert({
              ...optionAlert,
              type: "success",
              show: true,
              action: {
                id: "",
                name: ""
              },
              title: "บันทึกข้อมูลสำเร็จ",
              description: { visible: false, type: "text", content: " ", contentLists: [] }
            })
            setMasterStore({ isRefreshData: true })
            clearDataForm()
            setModalConfig({ ...modalConfig, show: false })
          }
          else {
            setOptionAlert({
              ...optionAlert,
              type: "danger",
              show: true,
              action: {
                id: "",
                name: ""
              },
              title: "ผิดพลาด",
              description: { visible: true, type: "text", content: `${message} ${sys_message}` }
            })
          }
          setLoading(false)

        })
        .catch((err) => {
          setOptionAlert({
            ...optionAlert,
            type: "danger",
            show: true,
            action: {
              id: "",
              name: ""
            },
            title: "ผิดพลาด",
            description: { visible: true, type: "text", content: `${err}` }
          })
          setLoading(false)
        })
    }
    else {
      DriverService.updateDriver(auth, {
        service_id: dataForm.id,
        sub_id: dataForm.sub_id,
        citizen_id: formData.citizen_id,
        service_code: formData.service_code,
        code_ref: formData.code_ref,
        first_name: formData.first_name,
        last_name: formData.last_name,
        nick_name: formData.nick_name,
        position: formData.position,
        department: formData.department,
        phone_no: formData.phone_no,
        status: dataForm.status,
        start_date: dataForm.start_date
      })
        .then((res: any) => {
          let { code, message, sys_message } = res.data
          if (code === 200) {
            setOptionAlert({
              ...optionAlert,
              type: "success",
              show: true,
              action: {
                id: "",
                name: ""
              },
              title: "บันทึกข้อมูลสำเร็จ",
              description: { visible: false, type: "text", content: " ", contentLists: [] }
            })
            setMasterStore({ isRefreshData: true })
            clearDataForm()
            setModalConfig({ ...modalConfig, show: false })
          }
          else {
            setOptionAlert({
              ...optionAlert,
              type: "danger",
              show: true,
              action: {
                id: "",
                name: ""
              },
              title: "ผิดพลาด",
              description: { visible: true, type: "text", content: `${message} ${sys_message}` }
            })
          }
          setLoading(false)
        })
        .catch((err) => {
          setOptionAlert({
            ...optionAlert,
            type: "danger",
            show: true,
            action: {
              id: "",
              name: ""
            },
            title: "ผิดพลาด",
            description: { visible: true, type: "text", content: `${err}` }
          })
          setLoading(false)
        })
    }

  }

  const getDriverInfo = (id) => {
    DriverService.getDriver(auth, id)
      .then((res: any) => {
        let { code, result } = res
        if (code === 200) {
          let dt = result[0]

          setUploadInfo({
            service_id: dt.id,
            sub_id: dt.sub_id
          })
          setDataForm({
            ...dataForm,
            id: dt.id,
            sub_id: [`${dt.sub_id}`],
            citizen_id: dt.citizen_id,
            service_code: dt.service_code,
            code_ref: dt.code_ref,
            first_name: dt.first_name,
            last_name: dt.last_name,
            nick_name: dt.nick_name,
            position: dt.position,
            department: dt.department,
            phone_no: dt.phone_no,
            status: dt.status,
            start_date: dt.start_date,
            picture_profile: dt.picture_profile,
            law_cards: dt.law_cards,
            docs: dt.docs
          })
        }
      })
      .catch((err) => { })
  }
  //#endregion

  const resetPassword = () => {
    setLoading(true)
    DriverService.resetPassword(auth, dataForm?.id)
      .then((res: any) => {
        let { code, message, sys_message } = res?.data
        if (code === 200) {
          setOptionAlert({
            ...optionAlert,
            type: "success",
            show: true,
            action: {
              id: "",
              name: ""
            },
            title: "บันทึกข้อมูลสำเร็จ",
            description: { visible: false, type: "text", content: " ", contentLists: [] }
          })
        }
        else {
          setOptionAlert({
            ...optionAlert,
            type: "danger",
            show: true,
            action: {
              id: "",
              name: ""
            },
            title: "ผิดพลาด",
            description: { visible: true, type: "text", content: `${message} ${sys_message}` }
          })
        }
        setLoading(false)
      })
      .catch((err) => {
        setLoading(false)
        setOptionAlert({
          ...optionAlert,
          type: "danger",
          show: true,
          action: {
            id: "",
            name: ""
          },
          title: "ผิดพลาด",
          description: { visible: true, type: "text", content: `${err}` }
        })
      })
  }

  const handleFileChange = (e) => {
    if (!e.target.files) return;

    // VALIDATE FILE UPLOAD
    let isError = false
    const fileLists = Array.from(e.target?.files)

    let { service_id, sub_id } = uploadInfo
    //qwe
    if (!isError) {
      setLoadingUpload(true)

      const data = new FormData();
      fileLists.forEach((file: any, i) => {
        data.append('files', file, file.name);
      });
      fetch(`${BASE_URL}/service/upload_pic?service_id=${service_id}&sub_id=${sub_id}`, {
        method: 'PUT',
        body: data,
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.code === 200) {
            getDriverInfo(service_id)
          }
          setLoadingUpload(false)
        })
        .catch((err) => {
          setLoadingUpload(false)
        })
    }
  }

  const handleFileDocChange = (e) => {
    if (!e.target.files) return;

    // VALIDATE FILE UPLOAD
    let isError = false
    const fileLists = Array.from(e.target?.files)
    fileLists.map((file: any) => { setFileUploadName(file.name) })
    let { service_id } = uploadInfo
    //qwe
    if (!isError) {
      setLoadingUploadDoc(true)

      const data = new FormData();
      fileLists.forEach((file: any, i) => {
        data.append('files', file, file.name);
      });
      fetch(`${BASE_URL}/service/upload_doc?service_id=${service_id}&doc_id=${doc_id_upload}`, {
        method: 'PUT',
        body: data,
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.code === 200) {
            getDriverInfo(service_id)
          }
          setLoadingUploadDoc(false)
        })
        .catch((err) => {
          setLoadingUploadDoc(false)
        })
    }
  }

  const clearDataForm = () => {
    setValidation({})
    setDataForm({
      id: "",
      sub_id: "",
      picture_profile: "",
      citizen_id: "",
      service_code: "",
      code_ref: "",
      first_name: "",
      last_name: "",
      nick_name: "",
      position: "",
      department: "",
      phone_no: "",
      law_cards: [],
      docs: [],
      status: "Y"
    })
    setUploadInfo({
      service_id: "",
      sub_id: "",
      doc_id: "",
    })
    doc_id_upload = ""
  }

  const labelError = (fieldName) => {
    if (get(validation, fieldName)) {
      if (validation[fieldName].isError)
        return <p className="text-xs italic text-danger-600">
          {validation[fieldName].message}
        </p>
      else
        return null
    }
  }

  return <>
    <FormMaster
      title="ข้อมูลพนักงานขับรถ"
      tableConfig={{
        key: "id",
        apiPath: "service/list",
        allowDelete: {
          visible: true,
          key: "service_id",
          apiPath: "service/delete",
          paramType: 'body'
        },
        optionDeleteFag: true,
        defaultColumn: [],
        columns: [
          {
            dataField: 'no',
            caption: "ลำดับ",
            width: 60,
            alignment: "center",
            allowFiltering: false
          },
          {
            dataField: 'service_code',
            caption: "รหัสพนักงาน",
            allowFiltering: true
          },
          {
            dataField: 'first_name',
            caption: "ชื่อพนักงาน",
            allowFiltering: true,
            cellRender: (e) => `${e.data.first_name}  ${e.data.last_name}`
          },
          {
            dataField: 'sub_name',
            caption: "ผู้รับจ้างช่วง",
            allowFiltering: true
          },
          {
            dataField: 'phone_no',
            caption: "เบอร์โทร"
          },
          {
            dataField: 'status_name',
            caption: "สถานะ",
            allowFiltering: true
          },
          {
            dataField: 'updated_by',
            caption: "แก้ไขโดย"
          }
        ],
        exportExcel: {
          fileName: 'ข้อมูลพนักงานขับรถ',
          columnConfig: [
            { label: 'ลำดับ', value: 'no' },
            { label: 'รหัสพนักงาน', value: 'service_code' },
            { label: 'ชื่อพนักงาน', value: (col) => (`${col.first_name} ${col.last_name}`) },
            { label: 'ผู้รับจ้างช่วง', value: 'sub_name' },
            { label: 'สถานะ', value: 'phone_no' },
            { label: 'แก้ไขโดย', value: 'updated_by' }
          ]
        }

      }}
      formData={{
        citizen_id: "",
        service_code: "",
        code_ref: "",
        first_name: "",
        last_name: "",
        position: "",
        department: "",
        phone_no: "",
        status: ""
      }}
      modalConfig={{
        visible: false,
        title: "พนักงานขับรถ",
        required: [],
        fieldTypeNumber: [],
        paramName: "carrier_id",
        apiPath: {
          "get": '',
          "post": 'service/add',
          "put": 'service/update'
        },
        elements: [
          {
            fieldName: "carrier_name",
            label: "รหัสพนักงาน",
            placeholder: "รหัสพนักงาน",
            required: true,
            inputType: "text"
          },
          {
            fieldName: "contract_name",
            label: "ชื่อพนักงาน",
            placeholder: "ชื่อผู้ติดต่อ",
            required: true,
            inputType: "text"
          },
          {
            fieldName: "phone",
            label: "เลขบัตรประชาชน",
            placeholder: "เลขบัตรประชาชน",
            required: true,
            inputType: "text"
          },
          {
            fieldName: "address",
            label: "เบอร์โทร",
            placeholder: "เบอร์โทร",
            required: true,
            inputType: "text"
          },
          {
            fieldName: "latlng",
            label: "เลขที่อ้างอิง",
            placeholder: "เลขที่อ้างอิง",
            required: true,
            inputType: "text"
          },
          {
            fieldName: "latlng",
            label: "สถานะ",
            placeholder: "สถานะ",
            required: true,
            inputType: "text"
          }
        ]
      }}
      onAdd={(data) => {
        setModalConfig(data.modalConfig)
      }}
      onEdit={(data) => {
        setModalConfig(data.modalConfig)

        let dt = data.modalConfig.dataSelectRow
        setUploadInfo({
          service_id: dt.id,
          sub_id: dt.sub_id
        })
        setDataForm({
          ...dataForm,
          id: dt.id,
          sub_id: [`${dt.sub_id}`],
          citizen_id: dt.citizen_id,
          service_code: dt.service_code,
          code_ref: dt.code_ref,
          first_name: dt.first_name,
          last_name: dt.last_name,
          nick_name: dt.nick_name,
          position: dt.position,
          department: dt.department,
          phone_no: dt.phone_no,
          status: dt.status,
          start_date: dt.start_date,
          picture_profile: dt.picture_profile,
          law_cards: dt.law_cards,
          docs: dt.docs
        })
      }}
    />


    <Modal
      title={{ text: `${modalConfig.action === 'add' ? 'เพิ่ม' : 'แก้ไข'}พนักงานขับรถ` }}
      open={modalConfig.show}
      // open={false}
      maskClosable={true}
      width='lg:w-2/3'
      shake={shakeIsWrong}
      onClosed={() => { }}
      onOk={() => { }}
      footer={<>
        <Button
          text={"บันทึก"}
          type="primary"
          width="w-20"
          onClick={() => onSave()}
        />
        {
          dataForm?.id !== "" && <Button
            text="Reset Password"
            type="info"
            width="w-30"
            onClick={() => {
              setOptionAlert({
                ...optionAlert,
                action: {
                  id: dataForm.id,
                  name: "resetpass"
                },
                type: "confirm",
                title: "ยืนยัน",
                show: true,
                description: {
                  visible: true,
                  type: "text",
                  content: "คุณแน่ใจที่จะบันทึกข้อมูล ?",
                  contentLists: []
                }
              })
            }}
          />
        }

        <Button
          text="ยกเลิก"
          type="white"
          width="w-20"
          onClick={() => {
            clearDataForm()
            setModalConfig({ ...modalConfig, show: false })
          }}
        />
      </>}
    >
      <div className='flex flex-col space-y-2'>
        <div className="flex flex-row">
          <div className="shrink-0 h-16 w-56 pr-10">
            <FormLoading loading={loadingUpload} tip="กำลังอัปโหลด">
              {
                dataForm.picture_profile === "" ?
                  <Image
                    width={"100%"}
                    preview={false}
                    src="/Images/avatar-placeholder.jpeg"
                  /> :
                  <Image
                    width={"100%"}
                    preview={false}
                    src={dataForm.picture_profile}
                  />
              }
            </FormLoading>

            <span className="inline-flex items-start text-sm font-normal">
              <input
                className='hidden'
                type="file"
                ref={inputUploadRef}
                onChange={handleFileChange}
              />

            </span>
            <Button
              text="อัปโหลด"
              icon={<UploadIcon />}
              size="sm"
              type="white"
              width="w-full"
              iconPosition='start'
              disabled={modalConfig.action === "add" ? true : false}
              onClick={() => inputUploadRef.current.click()}
            />

          </div>
          <div className="flex-1">

            <div className='flex-1 md:space-y-0'>
              <div className="flex lg:flex-row sm:flex-col space-x-4">
                <div className="flex-1 sm:grid sm:grid-cols-3 sm:items-end sm:gap-2">
                  <label className="block text-xs font-medium text-gray-700 mb-1 text-left">
                    รหัสพนักงาน :
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <Input
                      id="service_code"
                      label="รหัสพนักงาน"
                      placeholder="รหัสพนักงาน"
                      hiddenLabel={true}
                      defaultValue={dataForm.service_code}
                      onRef={(ref) => inputRef.current[0] = ref}
                    />
                  </div>
                </div>

                <div className="flex-1 sm:grid sm:grid-cols-3 sm:items-end sm:gap-2">
                  <label className="block text-xs font-medium text-gray-700 mb-1 text-left">
                    เลขบัตรประชาชน<span className="text-red-500">* </span> :{labelError("citizen_id")}
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <Input
                      id="citizen_id"
                      label="เลขบัตรประชาชน"
                      placeholder="เลขบัตรประชาชน"
                      hiddenLabel={true}
                      validate={validation['citizen_id']}
                      defaultValue={dataForm.citizen_id}
                      onRef={(ref) => inputRef.current[1] = ref}
                    />
                  </div>
                </div>
              </div>

              <div className="flex lg:flex-row sm:flex-col sm:space-y-2 space-x-4">
                <div className="flex-1 sm:grid sm:grid-cols-3 sm:items-end sm:gap-2">
                  <label className="block text-xs font-medium text-gray-700 mb-1 text-left">
                    ชื่อ<span className="text-red-500">*</span> :{labelError("first_name")}
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <Input
                      id="first_name"
                      label="ชื่อ"
                      placeholder="ชื่อ"
                      hiddenLabel={true}
                      validate={validation['first_name']}
                      defaultValue={dataForm.first_name}
                      onRef={(ref) => inputRef.current[2] = ref}
                    />
                  </div>
                </div>

                <div className="flex-1 sm:grid sm:grid-cols-3 sm:items-end sm:gap-2">
                  <label className="block text-xs font-medium text-gray-700 mb-1 text-left">
                    นามสกุล<span className="text-red-500">*</span> :{labelError("last_name")}
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <Input
                      id="last_name"
                      label="นามสกุล"
                      placeholder="นามสกุล"
                      validate={validation['last_name']}
                      hiddenLabel={true}
                      defaultValue={dataForm.last_name}
                      onRef={(ref) => inputRef.current[3] = ref}
                    />
                  </div>
                </div>
              </div>

              <div className="flex lg:flex-row sm:flex-col sm:space-y-2 space-x-4">
                <div className="flex-1 sm:grid sm:grid-cols-3 sm:items-end sm:gap-2">
                  <label className="block text-xs font-medium text-gray-700 mb-1 text-left">
                    ชื่อเล่น :
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <Input
                      id="nick_name"
                      label="ชื่อเล่น"
                      placeholder="ชื่อเล่น"
                      hiddenLabel={true}
                      defaultValue={dataForm.nick_name}
                      onRef={(ref) => inputRef.current[4] = ref}
                    />
                  </div>
                </div>

                <div className="flex-1 sm:grid sm:grid-cols-3 sm:items-end sm:gap-2">
                  <label className="block text-xs font-medium text-gray-700 mb-1 text-left">
                    เบอร์โทร<span className="text-red-500">*</span> :{labelError("phone_no")}
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <Input
                      id="phone_no"
                      label="เบอร์โทร"
                      placeholder="เบอร์โทร"
                      hiddenLabel={true}
                      validate={validation['phone_no']}
                      defaultValue={dataForm.phone_no}
                      onRef={(ref) => inputRef.current[5] = ref}
                    />
                  </div>
                </div>
              </div>

              <div className="flex lg:flex-row sm:flex-col sm:space-y-2 space-x-4">
                <div className="flex-1 sm:grid sm:grid-cols-3 sm:items-end sm:gap-2">
                  <label className="block text-xs font-medium text-gray-700 mb-1 text-left">
                    สังกัดผู้รับจ้างช่วง<span className="text-red-500">*</span> :{labelError("sub_id")}
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <Select
                      showSearch={true}
                      label="สังกัดผู้รับจ้างช่วง"
                      placeholder="สังกัดผู้รับจ้างช่วง"
                      hiddenLabel={true}
                      validate={validation['sub_id']}
                      defaultValue={dataForm.sub_id}
                      options={optionSubcontract}
                      onChange={(value) => setDataForm({ ...dataForm, sub_id: value })}
                    />
                  </div>
                </div>

                <div className="flex-1 sm:grid sm:grid-cols-3 sm:items-end sm:gap-2">
                  <label className="block text-xs font-medium text-gray-700 mb-1 text-left">
                    วันที่เริ่มงาน :
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <DatePicker
                      id="start_date"
                      label="วันที่เริ่มงาน"
                      placeholder="วันที่เริ่มงาน"
                      hiddenLabel={true}
                      defaultValue={dataForm.start_date}
                      onChange={(d) => {
                        if (d)
                          setDataForm({ ...dataForm, start_date: d.format("YYYY-MM-DD") })
                        else
                          setDataForm({ ...dataForm, start_date: "" })
                      }}
                    />

                  </div>
                </div>
              </div>

              <div className="flex lg:flex-row sm:flex-col sm:space-y-2 space-x-4">
                <div className="flex-1 sm:grid sm:grid-cols-3 sm:items-end sm:gap-2">
                  <label className="block text-xs font-medium text-gray-700 mb-1 text-left">
                    ตำแหน่ง :
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <Input
                      id="position"
                      label="ตำแหน่ง"
                      placeholder="ตำแหน่ง"
                      hiddenLabel={true}
                      defaultValue={dataForm.position}
                      onRef={(ref) => inputRef.current[6] = ref}
                    />
                  </div>
                </div>

                <div className="flex-1 sm:grid sm:grid-cols-3 sm:items-end sm:gap-2">
                  <label className="block text-xs font-medium text-gray-700 mb-1 text-left">
                    แผนก :
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <Input
                      id="department"
                      label="แผนก"
                      placeholder="แผนก"
                      hiddenLabel={true}
                      defaultValue={dataForm.department}
                      onRef={(ref) => inputRef.current[7] = ref}
                    />
                  </div>
                </div>
              </div>

              <div className="flex lg:flex-row sm:flex-col sm:space-y-2 space-x-4">
                <div className="flex-1 sm:grid sm:grid-cols-3 sm:items-end sm:gap-2">
                  <label className="block text-xs font-medium text-gray-700 mb-1 text-left">
                    เลขที่อ้างอิง :
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <Input
                      id="code_ref"
                      label="เลขที่อ้างอิง"
                      placeholder="เลขที่อ้างอิง"
                      hiddenLabel={true}
                      defaultValue={dataForm.code_ref}
                      onRef={(ref) => inputRef.current[8] = ref}
                    />
                  </div>
                </div>

                <div className="flex-1 sm:grid sm:grid-cols-3 sm:items-end sm:gap-2">
                  <label className="block text-xs font-medium text-gray-700 mb-1 text-left">
                    สถานะ :
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <Select
                      label="สถานะ"
                      placeholder="สถานะ"
                      hiddenLabel={true}
                      defaultValue={dataForm.status}
                      options={optionStatus}
                      onChange={(value) => setDataForm({ ...dataForm, status: value })}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='flex-1 md:space-y-2'>
          <label className={`flex flex-row text-sm font-medium text-gray-700 dark:text-gray-200`}>ข้อมูลใบขับขี่</label>
          <DataGrid
            ref={(ref) => gridRef.current = ref}
            dataSource={dataForm.law_cards}
            keyExpr="card_id"
            height={'auto'}
            allowColumnReordering={true}
            columnAutoWidth={true}
            showBorders={true}
          >
            <Paging enabled={false} />
            <Editing
              mode="row"
              allowUpdating={false}
              allowDeleting={false}
              allowAdding={false} />

            <Column dataField="card_type_name" caption="ประเภทบัตร" />
            <Column dataField="card_id" caption="รหัสบัตร" />
            <Column dataField="card_branch_name" caption="สถานที่ออกบัตร" />
            <Column dataField="expire_date" caption="วันหมดอายุ" />
          </DataGrid>
        </div>

        <div className='flex-1 md:space-y-2'>
          <label className={`flex flex-row text-sm font-medium text-gray-700 dark:text-gray-200 mt-2`}>ข้อมูลเอกสาร</label>
          <DataGrid
            ref={(ref) => gridRef.current = ref}
            dataSource={dataForm.docs}
            keyExpr="doc_id"
            height={'auto'}
            allowColumnReordering={true}
            columnAutoWidth={true}
            showBorders={true}
          >
            <Paging enabled={false} />
            <Editing
              mode="row"
              allowUpdating={false}
              allowDeleting={false}
              allowAdding={false} />

            <Column dataField="no" caption="ลำดับ" />
            <Column dataField="doc_name" caption="รายการ" />
            <Column
              dataField="filename"
              caption="ไฟล์"
              cellRender={(e) => {
                return <a href={e.data.file_url} target="_blank">{e.value}</a>
              }}
            />
            <Column
              dataField="doc_id"
              caption="อัปโหลด"
              alignment="center"
              cellRender={(e) => {
                doc_id_upload = e.value
                return <div>
                  <input
                    className='hidden'
                    type="file"
                    ref={inputUploadDocRef}
                    onChange={handleFileDocChange}
                  />

                  <Button
                    text="อัปโหลด"
                    icon={<UploadIcon />}
                    size="sm"
                    type="white"
                    width="w-full"
                    iconPosition='start'
                    loading={loadingUploadDoc}
                    disabled={modalConfig.action === "add" ? true : false}
                    onClick={() => inputUploadDocRef.current.click()}
                  />
                </div>
              }}
            />
          </DataGrid>
        </div>

      </div>
    </Modal>

    <ModalAlert
      {...optionAlert}
      onOk={() => {
        let { type, action } = optionAlert
        if (["create", "update"].includes(action.name)) {
          setLoading(true)
          saveData()
        }
        else if (action.name === "resetpass") {
          resetPassword()
        }

        setOptionAlert({ ...optionAlert, show: false })
      }}
      onCancel={() => {
        setOptionAlert({ ...optionAlert, show: false })
      }}
    />
  </>
}))

export default Driver