import axios from 'axios'
import { BASE_URL } from '../AppConfig'
import IAuth from '../interfaces/Common/IAuth'

interface ICustomer {
  cust_id?: string
  shipto_id?: string
  username?: string
}

export default {
  //#region CAUSE MANAGE
  getCause: async function (auth: IAuth) {
    try {
      const response = await axios.get(`${BASE_URL}/cause/list`, {
        headers: { action_user_id: auth?.action_user_id, token_id: auth?.token_id }
      })
      // const response = await axios.get(`${BASE_URL}/maintenance/list`)
      return response.data
    } catch (error) {
      throw error
    }
  },

  addCause: async function (auth: IAuth, data) {
    const response = await axios.post(`${BASE_URL}/cause/add`, {
      cause_name: data.cause_name,
      status: data.status
    },
      {
        headers: { action_user_id: auth?.action_user_id, token_id: auth?.token_id }
      })
    return response
  },


  updateCause: async function (auth: IAuth, data) {
    const response = await axios.put(`${BASE_URL}/cause/update`, {
      cause_id: data.cause_id,
      cause_name: data.cause_name,
      status: data.status
    },
      {
        headers: { action_user_id: auth?.action_user_id, token_id: auth?.token_id }
      })
    return response
  },
  //#endregion

  //#region REASON MANAGE
  addReason: async function (auth: IAuth, data) {
    const response = await axios.post(`${BASE_URL}/reason/add`, {
      cause_id: data.cause_id,
      reason_name: data.reason_name,
      status: data.status
    },
      {
        headers: { action_user_id: auth?.action_user_id, token_id: auth?.token_id }
      })
    return response
  },

  updateReason: async function (auth: IAuth, data) {
    const response = await axios.put(`${BASE_URL}/reason/update`, {
      reason_id: data.reason_id,
      reason_name: data.reason_name,
      status: data.status
    },
      {
        headers: { action_user_id: auth?.action_user_id, token_id: auth?.token_id }
      })
    return response
  },

  //#endregion
}