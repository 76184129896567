
import { useState, useRef, useEffect } from 'react'
import { createMultipleRef } from '../../helpers'
import { Input, Modal, Button, ModalAlert, FormLoading } from '../../components'
import DataGrid, { Paging, Column } from 'devextreme-react/data-grid';
import { DeliveryCostService } from '../../services';
import { UploadIcon } from '@heroicons/react/solid';
import { BASE_URL } from '../../AppConfig'

let doc_id_upload = "", dtUpload = {
  dlv_cost_id: "",
  doc_id: "",
  route_id: ""
}
const ModalDoc = (props) => {
  let { open, data, auth } = props // STATES
  let { onClosed, reloadData } = props // ACTIONS


  const inputUploadDocRef: any = useRef(null);
  const [fileUploadName, setFileUploadName] = useState("")
  const [uploadInfo, setUploadInfo]: any = useState({
    service_id: "",
    sub_id: "",
    doc_id: "",
  })

  const [loading, setLoading]: any = useState({ visible: false, tip: 'กำลังบันทึก...' })
  const [validation, setValidation]: any = useState({})
  const [optionAlert, setOptionAlert]: any = useState({
    show: false,
    action: {
      id: "",
      name: ""
    },
    type: "success",
    title: "",
    description: {
      visible: false,
      type: "",
      content: "",
      contentLists: [],

    }
  })
  const [dataForm, setDataForm]: any = useState({
    "dlv_doc_no": "",
    "route_id": "",
    "ts_gate_in": "",
    "ts_gate_out": "",
    "mileage_start": "",
    "mileage_end": "",
    "service_info": "",
    "tasks": [],
    "cost_items": []
  })


  const gridRef: any = useRef(null);
  const inputRef: any = useRef([]);
  createMultipleRef(inputRef, 2)

  useEffect(() => {
    if (data) {
      setDataForm(data)
    }
  }, [open, data])

  const initialData = () => {
    setValidation({})
    setDataForm({
      "dlv_doc_no": "",
      "route_id": "",
      "ts_gate_in": "",
      "ts_gate_out": "",
      "mileage_start": "",
      "mileage_end": "",
      "service_info": "",
      "tasks": [],
      "cost_items": []
    })
  }

  const saveData = () => {
    let data = {
      "route_id": dataForm?.route_id,
      "ts_gate_in": dataForm?.ts_gate_in,
      "ts_gate_out": dataForm?.ts_gate_out,
      "mileage_start": dataForm?.mileage_start,
      "mileage_end": dataForm?.mileage_end,
      "service_info": `${dataForm?.service_infos}`,
      "tasks": dataForm?.drops,
      "cost_items": dataForm?.detail,
      docs: dataForm.docs
    }

    setLoading({ ...loading, visible: true, tip: 'กำลังบันทึก...' })
    DeliveryCostService.updateDeliveryCost(auth, data)
      .then((res: any) => {
        let { code, message, sys_message } = res.data

        if (code === 200) {
          setOptionAlert({
            ...optionAlert,
            type: "success",
            show: true,
            action: {
              id: "",
              name: ""
            },
            title: "บันทึกข้อมูลสำเร็จ",
            description: { visible: false, type: "text", content: " ", contentLists: [] }
          })
          initialData()
          onClosed(true)
        }
        else {
          setOptionAlert({
            ...optionAlert,
            type: "danger",
            show: true,
            action: {
              id: "",
              name: ""
            },
            title: "ผิดพลาด",
            description: { visible: true, type: "text", content: `${message} ${sys_message}` }
          })
        }
        setLoading({ ...loading, visible: false })
      })
      .catch((err) => {
        setOptionAlert({
          ...optionAlert,
          type: "danger",
          show: true,
          action: {
            id: "",
            name: ""
          },
          title: "ผิดพลาด",
          description: { visible: true, type: "text", content: `${err}` }
        })
        setLoading({ ...loading, visible: false })
      })
  }

  const handleFileDocChange = (e) => {
    if (!e.target.files) return;

    setLoading({ ...loading, visible: true, tip: 'กำลังอัปโหลด...' })
    // VALIDATE FILE UPLOAD
    let isError = false
    const fileLists = Array.from(e.target?.files)
    fileLists.map((file: any) => {
      let nameSplite = file.name.split('.')
      let fileType = nameSplite[(nameSplite.length - 1)]

      setFileUploadName(file.name)
      if (fileType !== "xlsx") {
        setOptionAlert({
          ...optionAlert,
          type: "danger",
          show: true,
          action: {
            id: "",
            name: ""
          },
          title: "ผิดพลาด",
          description: { visible: true, content: `สามารถอัปโหลดไฟล์ Excel เท่านั้น` }
        })
      }
    })

    //qwe
    if (!isError) {
      let fileName = fileLists.map((file: any) => file.name).join(', ')

      const data = new FormData();
      fileLists.forEach((file: any, i) => {
        data.append('files', file, file.name);
      });
      fetch(`${BASE_URL}/dlv_cost/upload_doc?dlv_cost_id=${dtUpload?.dlv_cost_id}&doc_id=${dtUpload?.doc_id}&route_id=${dtUpload?.route_id}`, {
        method: 'POST',
        body: data,
      })
        .then((res) => res.json())
        .then((data) => {
          let { code, message, sys_message } = data
          if (code !== 200) {
            setOptionAlert({
              ...optionAlert,
              type: "danger",
              show: true,
              action: {
                id: "",
                name: ""
              },
              title: "อัปโหลดผิดพลาด",
              description: { visible: true, type: "text", content: `${message} ${sys_message}` }
            })
          }
          reloadData(dataForm?.route_id)
          setLoading({ ...loading, visible: false })
        })
        .catch((err) => {
          setOptionAlert({
            ...optionAlert,
            type: "danger",
            show: true,
            action: {
              id: "",
              name: ""
            },
            title: "อัปโหลดผิดพลาด",
            description: { visible: true, type: "text", content: `${err}` }
          })
          setLoading({ ...loading, visible: false })
        })
    }
    else {
      setLoading({ ...loading, visible: false })
    }
  }

  const removeItem = () => {
    const idx = dataForm.docs.findIndex((obj) => obj.doc_id === optionAlert.action.id);
    let docs = dataForm.docs.splice(idx, 1);
    setDataForm({ ...dataForm, docs })
  }

  return (<>
    <Modal
      title={{ text: `เอกสารค่าใช้จ่าย` }}
      open={open}
      maskClosable={true}
      width={"w-2/4"}
      buttonOk={{ text: "ตกลง" }}
      onClosed={() => {
        onClosed()
        initialData()
      }}
      onOk={() => {
        onClosed(true)
        initialData()
      }}
    >
      <FormLoading loading={loading.visible} tip={loading.tip}>
        <div className="flex flex-col -my-4  space-y-4">
          <div className="flex lg:flex-row  sm:flex-col sm:space-y-2">
            <div className="flex-1 sm:grid sm:grid-cols-3 sm:items-end sm:gap-2">
              <label className="block text-xs font-medium text-gray-700 mb-1 text-right">
                เลขที่เอกสาร :
              </label>
              <div className="mt-1 sm:col-span-2 sm:mt-0">
                <Input
                  id="first_name"
                  label="เลขที่เอกสาร"
                  placeholder="เลขที่เอกสาร"
                  hiddenLabel={true}
                  disabled={true}
                  defaultValue={dataForm.dlv_doc_no}
                />
              </div>
            </div>
            <div className="flex-1 sm:grid sm:grid-cols-3 sm:items-end sm:gap-2">
              <label className="block text-xs font-medium text-gray-700 mb-1 text-right">
                ทะเบียนรถ :
              </label>
              <div className="mt-1 sm:col-span-2 sm:mt-0">
                <Input
                  id="first_name"
                  label="ทะเบียนรถ"
                  placeholder="ทะเบียนรถ"
                  hiddenLabel={true}
                  disabled={true}
                  defaultValue={dataForm.licenseplate}
                />
              </div>
            </div>
          </div>

          <div className="">
            <DataGrid
              ref={(ref) => gridRef.current = ref}
              dataSource={dataForm.docs}
              keyExpr="doc_id"
              allowColumnReordering={true}
              columnAutoWidth={true}
              showBorders={true}
              onSaved={(e) => {
                setDataForm({ ...dataForm, products: e.component.getDataSource().items() })
              }}
            >
              <Paging enabled={true} />
              <Column dataField="no" caption="ลำดับ" width={60} alignment="center" allowFiltering={false} />
              <Column dataField="doc_name" caption="รายการ" width={"50%"} />
              <Column
                dataField="filename"
                caption="ไฟล์"
                width={150}
                cellRender={(e) => {
                  return <a href={e.data.doc_url} target="_blank">{e.value}</a>
                }}
              />
              <Column
                dataField="doc_id"
                caption="อัปโหลด"
                width={100}
                alignment="center"
                cellRender={(e) => {
                  let data = e.data
                  doc_id_upload = e.value
                  return <div>
                    <input
                      className='hidden'
                      type="file"
                      ref={inputUploadDocRef}
                      onChange={handleFileDocChange}
                    />

                    <Button
                      text="อัปโหลด"
                      icon={<UploadIcon />}
                      size="sm"
                      type="white"
                      width="w-full"
                      iconPosition='start'
                      onClick={() => {
                        dtUpload = {
                          dlv_cost_id: data.dlv_doc_id,
                          doc_id: data.doc_id,
                          route_id: dataForm?.route_id
                        }
                        inputUploadDocRef.current.click()
                      }}
                    />
                  </div>
                }}
              />
            </DataGrid>
          </div>
        </div>
      </FormLoading>
    </Modal>

    <ModalAlert
      {...optionAlert}
      onOk={() => {
        let { action } = optionAlert
        if (action.name === "update") saveData()
        if (action.name === "delete") removeItem()
        setOptionAlert({ ...optionAlert, show: false })
      }}
      onCancel={() => {
        setOptionAlert({ ...optionAlert, show: false })
      }}
    />
  </>
  )
}

export default ModalDoc