
import { useState } from "react";
import { inject, observer } from 'mobx-react';
import { Button } from '../../components'
import { PlusIcon, TrashIcon } from "@heroicons/react/solid";
import ModalQueue from "./ModalQueue";
import ModalPlan from "./ModalPlan";
import TableQueue from "../UploadJob/TableQueue";
import TablePlan from "./TablePlan";
import './style.css'
import { isEmpty } from 'lodash'
import SplitterLayout from 'react-splitter-layout';

const JobPlan = inject("routeStore", "commonStore")(observer((props) => {
  let { routeStore, commonStore } = props
  let { setRouteStore } = routeStore // ACTIONS
  let { getExpandSidebar } = commonStore
  let expand = getExpandSidebar
  let { queueDateCurrent, setQueueSelected, onDeleteQueue, setRouteSelected, onDeleteRoute, onReloadQueue, onReloadQueueCurrent } = props
  let { dataQueue, dataRoute, onReloadData, auth } = props // STATE

  const [openQueue, setOpenQueue] = useState(false)
  const [openPlan, setOpenPlan] = useState({
    show: false,
    action: "create"
  })
  const [dataEdit, setDataEdit]: any = useState({})

  return <>
    <div style={{ height: 'calc(100vh - 360px)', border: '1px solid #d5d5d5', borderRadius: 3 }}>
      <SplitterLayout secondaryInitialSize={(window.innerWidth / 2) - (expand ? 140 : 48)}>
        <div>
          <div className="p-2 space-y-2 ">
            <div className="flex flex-wrap">
              <div className="flex-1 flex-row space-x-2">
                <span className="inline-flex items-center rounded bg-blue-100 px-2.5 py-0.5 text-sm font-medium text-blue-800">
                  ข้อมูลใบส่งสินค้า
                </span>
                <span className="inline-flex items-center text-sm font-normal">
                  {`จำนวน ${dataQueue.length} รายการ`}
                </span>
              </div>

              <div className="flex-1 flex  flex-row-reverse">
                <Button
                  text="เพิ่ม"
                  icon={<PlusIcon />}
                  size="sm"
                  type="white"
                  iconPosition='start'
                  onClick={() => setOpenQueue(true)}
                />

                <Button
                  text="ลบ"
                  icon={<TrashIcon />}
                  size="sm"
                  type="white"
                  iconPosition='start'
                  onClick={() => onDeleteQueue()}
                />
              </div>
            </div>

            <TableQueue
              dataSource={dataQueue}
              height="calc(100vh - 415px)"
              onSelectionChanged={(select) => setQueueSelected(select)}
              onEdit={(data) => {
                data.actionBy = 'queue'
                data.actionPage = 'upload'
                setDataEdit(data)
                setOpenQueue(true)
              }}
            />
          </div>
        </div>

        <div>
          <div className="p-2 space-y-2">
            <div className="flex flex-wrap">
              <div className="flex-1 flex-row space-x-2">
                <span className="inline-flex items-center rounded bg-success-100 px-2.5 py-0.5 text-sm font-medium text-success-800">
                  ข้อมูลใบ Jobs
                </span>
                <span className="inline-flex items-center text-sm font-normal">
                  {`จำนวน ${dataRoute.length} รายการ`}
                </span>
              </div>

              <div className="flex-1 flex flex-row-reverse">
                <Button
                  text="เพิ่ม"
                  icon={<PlusIcon />}
                  size="sm"
                  type="white"
                  iconPosition='start'
                  onClick={() => setOpenPlan({ show: true, action: "create" })}
                />

                <Button
                  text="ลบ"
                  icon={<TrashIcon />}
                  size="sm"
                  type="white"
                  iconPosition='start'
                  onClick={() => onDeleteRoute()}
                />
              </div>
            </div>

            <div>
              <TablePlan
                auth={auth}
                dataSource={dataRoute}
                height="calc(100vh - 415px)"
                onSelectionChanged={(select) => setRouteSelected(select)}
                onEdit={(data) => {
                  setOpenPlan({ show: true, action: "update" })
                  setRouteStore({ dataPlanEdit: data })
                }}
                onEditQueue={(data) => {
                  data.actionBy = 'plan'
                  data.actionPage = 'plan'
                  setDataEdit(data)
                  setOpenQueue(true)
                }}
              />
            </div>
          </div>
        </div>
      </SplitterLayout>

      <ModalQueue
        auth={auth}
        action={openPlan.action}
        open={openQueue}
        mode={isEmpty(dataEdit) ? "add" : "edit"}
        dataEdit={dataEdit}
        onReloadQueueCurrent={() => onReloadQueueCurrent()}
        onClosed={(reload, delivery_date) => {
          if (delivery_date === queueDateCurrent) onReloadQueue(delivery_date)
          setOpenQueue(false)
          setDataEdit({})
        }}
      />

      <ModalPlan
        auth={auth}
        open={openPlan.show}
        action={openPlan.action}
        onReloadData={(reloadQueue) => onReloadData(reloadQueue)}
        onClosed={() => setOpenPlan({ ...openPlan, show: false })}
      />
    </div>
  </>
}))

export default JobPlan