import { ExclamationIcon } from '@heroicons/react/solid'
import Icon from '../Icons'
import { Fragment, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'

export declare type AlertType = 'success' | 'danger' | 'warning' | 'info' | 'confirm';

type Props = {
  type?: AlertType | string
  title?: string
  description?: {
    visible: boolean // list, text
    type: string // list, text
    content?: string
    contentLists?: any //  array[]
  },
  messageLists?: any,
  show: boolean,
  onOk?: (e: React.MouseEvent<HTMLButtonElement>) => void
  onClose?(show: boolean): void
  onCancel?: (e: React.MouseEvent<HTMLButtonElement>) => void
}

export default ({
  type = "info",
  title = "",
  description = {
    visible: false,
    type: "list",
    content: "",
    contentLists: []
  },
  messageLists = [],
  show = false,
  onOk,
  onClose,
  onCancel
}: Props) => {

  const cancelButtonRef = useRef(null)

  return (
    <Transition.Root show={show} as={Fragment}>
      <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" initialFocus={cancelButtonRef} onClose={() => onClose && onClose(false)}>
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              <div className="sm:flex sm:items-start">
                <div className={`mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full 
                  ${type === "primary" ? "bg-primary-100"
                    : type === "success" ? " bg-success-100"
                      : type === "danger" ? " bg-danger-100"
                        : type === "warning" ? " bg-warning-100"
                          : type === "info" ? " bg-info-100"
                            : type === "confirm" ? " bg-warning-100" : null} 
                  sm:mx-0 sm:h-10 sm:w-10`}>
                  <Icon
                    className={`h-6 w-6 ml-1 
                          ${type === "primary" ? "text-primary-600"
                        : type === "success" ? " text-success-600"
                          : type === "danger" ? " text-danger-600"
                            : type === "warning" ? " text-warning-600"
                              : type === "info" ? " text-info-600"
                                : type === "confirm" ? " text-warning-600" : null}
                    `}
                    iconName={
                      type === "info" ? "InformationCircleIcon"
                        : type === "success" ? "CheckCircleIcon"
                          : type === "danger" ? "XCircleIcon"
                            : type === "warning" ? "ExclamationIcon" : "ExclamationIcon"}
                  />
                </div>
                <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">

                  {
                    description.visible ? <> <Dialog.Title as="h3" className="text-lg leading-6 font-medium">{title}</Dialog.Title>
                      <div className="mt-2">
                        <p className="text-sm text-gray-600">
                          {
                            description.type === "list" ? <ul role="list" className="list-disc pl-5 space-y-1">
                              {
                                description.contentLists.map((text, idx) => {
                                  return <li key={idx}>{text}</li>
                                })
                              }
                            </ul>
                              : description.content
                          }
                        </p>
                      </div>
                    </>
                      : <div className="mt-2">
                        <label className="text-md">{title}</label>
                      </div>
                  }

                </div>
              </div>
              <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                <button
                  type="button"
                  className={`w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 
                    ${type === "primary" ? "bg-primary-600"
                      : type === "success" ? " bg-success-600"
                        : type === "danger" ? " bg-danger-600"
                          : type === "warning" ? " bg-warning-600"
                            : type === "info" ? " bg-info-600"
                              : type === "confirm" ? " bg-primary-600" : null} 
                    text-base font-medium text-white 
                    ${type === "primary" ? "hover:bg-primary-700"
                      : type === "success" ? "hover:bg-success-700"
                        : type === "danger" ? " hover:bg-danger-700"
                          : type === "warning" ? " hover:bg-warning-700"
                            : type === "info" ? " hover:bg-info-700"
                              : type === "confirm" ? " hover:bg-primary-700" : null} 
                    focus:outline-none focus:ring-2 focus:ring-offset-2 
                    ${type === "primary" ? "focus:ring-primary-500"
                      : type === "success" ? "focus:ring-success-500"
                        : type === "danger" ? " focus:ring-danger-500"
                          : type === "warning" ? " focus:ring-warning-500"
                            : type === "info" ? " focus:ring-info-500"
                              : type === "confirm" ? " focus:ring-primary-500" : null} 
                    sm:ml-3 sm:w-auto sm:text-sm`}
                  onClick={onOk}
                >
                  ตกลง
                </button>
                {
                  type === "confirm" && <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-danger-500 sm:mt-0 sm:w-auto sm:text-sm"
                    onClick={onCancel}
                    ref={cancelButtonRef}
                  >
                    ยกเลิก
                  </button>
                }

              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}