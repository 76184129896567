import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { inject, observer } from 'mobx-react';
import { isEmpty } from "lodash";

const PageNotFound = inject("authenStore", "commonStore")(observer((props) => {
    const navigate = useNavigate()
    let authenStore = props.authenStore
    let { getAccountAuth } = authenStore

    useEffect(() => {
    }, [getAccountAuth])

    const routeToPage = () => {
        if (isEmpty(getAccountAuth))
            navigate('/')
        else
            navigate('/home-page')
    }

    return (
        <div className="min-h-full pt-16 pb-12 flex flex-col">
            <main className="flex-grow flex flex-col justify-center max-w-7xl w-full mx-auto px-4 sm:px-6 lg:px-8">
                <div className="flex-shrink-0 flex justify-center">
                </div>
                <div className="py-16">
                    <div className="text-center">
                        <p className="text-3xl font-semibold text-red-500  uppercase tracking-wide">401</p>
                        <h1 className="mt-2 text-4xl font-extrabold text-gray-900 dark:text-white tracking-tight sm:text-5xl ">Access denied.</h1>
                        <p className="mt-2 text-base text-gray-500 dark:text-gray-400">You are not authorized to access this page.</p>
                        <div className="mt-6">
                            <a
                                onClick={() => routeToPage()}
                                className="cursor-pointer text-base font-medium text-primary-600 hover:text-primary-700">
                                Go back home<span aria-hidden="true"> &rarr;</span>
                            </a>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    )
}))
export default PageNotFound