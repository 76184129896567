import { Fragment, useState, useMemo } from 'react'
import { CheckIcon, ChevronDownIcon, XCircleIcon, SearchIcon } from '@heroicons/react/outline'
import { Combobox, Transition, Switch } from '@headlessui/react'
import { useTranslation } from 'react-i18next'
import { Select } from 'antd';
import './style.css'

declare type IconPosition = 'start' | 'end';
declare type SizeType = 'small' | 'middle' | 'large';
declare type RawValue = string | number;
interface OptionValue {
    value?: RawValue;
    label?: RawValue;
    icon?: React.ReactNode;
    options?: OptionValue[]
}

type Props = {
    allowClear?: boolean,
    id?: string
    icon?: React.ReactNode;
    iconPosition?: IconPosition;
    label?: string
    labelHelp?: string
    labelOptional?: string
    disabled?: boolean
    // defaultValue?: string[] | number[];
    defaultValue?: string[] | number[];
    hiddenLabel?: boolean
    options?: OptionValue[];
    required?: boolean
    multiple?: boolean
    size?: SizeType
    showSearch?: boolean
    selectAll?: boolean
    // search?: boolean
    placeholder?: string
    // type: SelectType
    type?: string
    validate?: { isError: boolean; message: string };
    width?: string | number
    onChange?: (value: any) => void;
}

export default (props: Props) => {
    const { t } = useTranslation()
    let {
        allowClear = false,
        id,
        icon,
        iconPosition = "start",
        label,
        labelHelp,
        labelOptional,
        disabled = false,
        defaultValue = [],
        hiddenLabel = false,
        required = false,
        multiple = false,
        size = "middle",
        type,
        // search = false,
        options = [],
        showSearch = false,
        selectAll = false,
        placeholder = "โปรดระบุ",
        validate = { isError: false, message: "" },
        width = "100%",
        onChange
    }: any = props
    const handleSelectChange = (value: string) => onChange && onChange(value ? value : "")

    return (
        <div>
            <div className={`flex justify-between ${hiddenLabel && 'sr-only'}`}>
                <label htmlFor="email" className={`flex flex-row text-sm font-medium text-gray-700 dark:text-gray-200`}>
                    {t(label)}<span className={`text-red-500 ${!required && 'sr-only'}`}>*</span>

                    <p className={`text-sm italic  ml-2
                     ${validate.isError ? 'text-danger-600' : 'text-gray-500'}
                     ${!validate.isError && 'sr-only'}`}
                    >
                        {`${validate.isError && validate.message}`}
                    </p>
                </label>
            </div>
            <div className={`${icon && 'relative'} mt-1`}>
                <div className={`pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3 ${icon && iconPosition === "start" ? '' : 'sr-only'}`}>
                    {icon}
                </div>
                <div className={`${validate.isError && 'select-placeholder-error'} select-custom block text-sm font-medium text-gray-700  dark:text-gray-200`}>
                    <Select
                        showSearch={showSearch}
                        allowClear={allowClear}
                        mode={multiple && 'multiple'}
                        style={{ width }}
                        value={defaultValue === "" ? [] : defaultValue}
                        placeholder={placeholder}
                        optionFilterProp="children"
                        maxTagCount="responsive"
                        size={size}
                        onChange={handleSelectChange}
                        status={validate.isError ? "error" : ""}
                        disabled={disabled}
                        filterOption={(input, option) =>
                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                        }
                        options={[...options]}
                    />
                </div>
                {/* <style>
                    {`
                        .ant-select-selection-search-input::placeholder {
                            color: red !important;
                        }
                        `}
                </style> */}
            </div>
        </div>
    )
}