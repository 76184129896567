import { useEffect, useState } from 'react'
import { inject, observer } from 'mobx-react';
import FormMaster from '../FormMaster'
import { CommonService, MaintenanceService } from "../../../services";
import { Button, ModalAlert } from '../../../components'
import { Select, Checkbox, FloatButton } from 'antd';
import { isEmpty } from 'lodash';

let firstRender = false
const MaintenanceTypeUnit = inject("masterStore", "authenStore")(observer((props) => {
  let masterStore = props.masterStore
  let authenStore = props.authenStore
  let { getAccountAuth } = authenStore // STATE
  let auth = { action_user_id: getAccountAuth?.user_id, token_id: getAccountAuth?.access_token }

  let { setMasterStore } = masterStore
  const [optionStatus, setOptionStatus] = useState([])
  const [optionVehicle, setOptionVehicle] = useState([])
  const [midChecked, setMidChecked]: any = useState("")
  const [vehicleId, setVehicleId]: any = useState("")
  const [optionAlert, setOptionAlert]: any = useState({
    show: false,
    status: "",
    type: "success",
    title: "",
    description: {
      visible: false,
      type: "",
      content: "",
      contentLists: []
    }
  })

  useEffect(() => {
    if (firstRender || !isEmpty(getAccountAuth)) {
      CommonService.getMasterData(auth, "common_status,vehicle")
        .then((res: any) => {
          let common_status = res.result.common_status.map((item) => ({ label: item.status_name, value: item.status_code }))
          let vehicle = res.result.vehicle.map((item) => ({ label: item.licenseplate, value: item.vehicle_id }))
          setOptionStatus(common_status)
          setOptionVehicle(vehicle)
        })
        .catch((err) => {
          console.log(`Load master data : ${err}`)
        })
    }
    else
      firstRender = true
  }, [getAccountAuth])

  const updateMaintainVehicleStatus = () => {
    MaintenanceService.updateMaintainVehicleStatus(auth, {
      vehicle_id: vehicleId,
      ma_item_id: midChecked,
      status: optionAlert.status
    })
      .then((res: any) => {
        let { code, message } = res.data
        if (code === 200) {
          setOptionAlert({
            ...optionAlert,
            type: "success",
            show: true,
            status: "",
            title: "บันทึกข้อมูลสำเร็จ",
            description: { visible: false, type: "text", content: " ", contentLists: [] }
          })

          setMasterStore({
            isRefreshData: true,
            modalConfig: {
              show: false,
              action: "",
              id: ""
            }
          })
          setMidChecked("")
        }
        else {
          setOptionAlert({
            ...optionAlert,
            type: "danger",
            show: true,
            status: "",
            title: "ผิดพลาด",
            description: { visible: true, type: "text", content: message }
          })
        }
      })
      .catch((err) => {
        setOptionAlert({
          ...optionAlert,
          type: "danger",
          show: true,
          status: "",
          title: "ผิดพลาด",
          description: { visible: true, type: "text", content: `${err}` }
        })
      })
  }

  const onSave = (status) => {
    setOptionAlert({
      ...optionAlert,
      status,
      type: "confirm",
      title: "ยืนยัน",
      show: true,
      description: {
        visible: true,
        type: "text",
        content: "คุณแน่ใจที่จะบันทึกข้อมูล ?",
        contentLists: []
      }
    })
  }

  return <>
    <ModalAlert
      {...optionAlert}
      onOk={() => {
        if (optionAlert.status !== "") updateMaintainVehicleStatus()

        setOptionAlert({ ...optionAlert, show: false })
      }}
      onCancel={() => {
        setOptionAlert({ ...optionAlert, show: false })
      }}
    />

    <FormMaster
      title="ข้อมูลประเภทการซ่อมบำรุง (รายคัน)"
      tableConfig={{
        screenName: "maintenance",
        vehicleId: vehicleId,
        hiddenAddButton: true,
        hiddenToolbar: true,
        key: "id",
        apiPath: "maintain_vehicle/list",
        allowDelete: {
          visible: false,
          key: "ma_item_id",
          apiPath: "maintain_vehicle/delete",
          paramType: "param"
        },
        optionDeleteFag: true,
        defaultColumn: [],
        columns: [
          {
            dataField: 'no',
            caption: "ลำดับ",
            width: 60,
            alignment: "center",
            allowFiltering: false
          },
          {
            dataField: 'licenseplate',
            caption: "ทะเบียน",
            allowFiltering: true
          },
          {
            dataField: 'ma_item_code',
            caption: "รหัส",
            allowFiltering: true
          },
          {
            dataField: 'ma_item_name',
            caption: "ชื่อประเภท",
            allowFiltering: true
          },
          {
            dataField: 'target_km',
            caption: "ระยะทางที่เปลี่ยน(กิโลเมตร)",
            alignment: "start"
          },
          {
            dataField: 'warning_target_km',
            caption: "ระยะทางที่แจ้งเตือนล่วงหน้า(กิโลเมตร)",
            alignment: "start"
          },
          {
            dataField: 'target_day',
            caption: "ระยะเวลาที่ต้องเปลี่ยน(วัน)",
            alignment: "start"
          },
          {
            dataField: 'warning_target_day',
            caption: "ระยะเวลาที่แจ้งเตือนล่วงหน้า(วัน)",
            alignment: "start"
          },
          {
            dataField: 'status_name',
            caption: "สถานะ",
            allowFiltering: true
          },
          {
            dataField: 'updated_by',
            caption: "แก้ไขโดย"
          },
          {
            dataField: 'status_name',
            caption: "เลือก",
            alignment: "center",
            cellRender: (e) => {
              return <Checkbox
                checked={e.data.id === midChecked}
                onChange={(checked) => {
                  setMidChecked(e.data.id)
                  setVehicleId(e.data.vehicle_id)
                }}></Checkbox>
            }
          }
        ]
      }}
      tableHeaderCustom={<div className="flex flex-row mb-2">
        <div className="flex-1 flex-row space-x-2">
          <div className="flex flex-1 flex-row">
            <label className="text-sm font-medium text-gray-700 mt-3 mr-2 text-start">
              ทะเบียน :
            </label>
            <div className="mt-1 sm:col-span-2 sm:mt-0">
              <Select
                showSearch={true}
                allowClear={true}
                style={{ width: 200 }}
                value={vehicleId === "" ? [] : vehicleId}
                optionFilterProp="children"
                placeholder="ทะเบียน"
                autoFocus={true}
                onChange={(value) => value ? setVehicleId(value) : setVehicleId("")}
                options={optionVehicle}
              />
            </div>
          </div>
        </div>

        <div className="flex-1 flex flex-row-reverse">
          <Button
            text="ใช้งาน"
            size="sm"
            disabled={(midChecked === "") ? true : false}
            onClick={() => onSave("Y")}
          />

          <Button
            text="ไม่ใช้งาน"
            size="sm"
            disabled={(midChecked === "") ? true : false}
            onClick={() => onSave("N")}
          />
        </div>
      </div>}
      formData={{
        vehicle_id: "",
        ma_item_id: "",
        ma_item_code: "",
        ma_item_name: "",
        target_km: "",
        warning_target_km: "",
        target_day: "",
        warning_target_day: "",
        status: "Y"
      }}
      modalConfig={{
        screenName: "maintenance",
        visible: true,
        title: "ประเภทการซ่อมบำรุง",
        required: ["ma_item_code", "ma_item_name"],
        fieldTypeNumber: [],
        paramName: "ma_item_id",
        apiPath: {
          "get": '',
          "post": 'maintain_vehicle/add',
          "put": 'maintain_vehicle/update'
        },
        elements: [
          {
            fieldName: "ma_item_code",
            label: "รหัส",
            placeholder: "รหัส",
            required: true,
            inputType: "text"
          },
          {
            fieldName: "ma_item_name",
            label: "ชื่อประเภท",
            placeholder: "ชื่อประเภท",
            required: true,
            inputType: "text"
          },
          {
            fieldName: "target_km",
            label: "ระยะทางที่เปลี่ยน(กิโลเมตร)",
            placeholder: "ระยะทางที่เปลี่ยน(กิโลเมตร)",
            min: 0,
            inputType: "number",
            isOneRow: true
          },
          {
            fieldName: "warning_target_km",
            label: "ระยะทางที่แจ้งเตือนล่วงหน้า(กิโลเมตร) ",
            placeholder: "ระยะทางที่แจ้งเตือนล่วงหน้า(กิโลเมตร) ",
            min: 0,
            inputType: "number",
            isOneRow: true
          },
          {
            fieldName: "target_day",
            label: "ระยะเวลาที่ต้องเปลี่ยน(วัน)",
            placeholder: "ระยะเวลาที่ต้องเปลี่ยน(วัน)",
            min: 0,
            inputType: "number",
            isOneRow: true
          },
          {
            fieldName: "warning_target_day",
            label: "ระยะเวลาที่แจ้งเตือนล่วงหน้า(วัน)",
            placeholder: "ระยะเวลาที่แจ้งเตือนล่วงหน้า(วัน)",
            min: 0,
            inputType: "number",
            isOneRow: true
          },
          {
            showSearch: false,
            fieldName: "status",
            label: "สถานะ",
            placeholder: "สถานะ",
            inputType: "select",
            defaultValue: "Y",
            options: optionStatus
          },
          {}
        ]
      }}
    />
    <FloatButton.BackTop type="primary" />
  </>
}))

export default MaintenanceTypeUnit