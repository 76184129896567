
import { useState, useRef, useEffect } from 'react'
import { createMultipleRef } from '../../../helpers'
import { Input, Modal, Button, ModalAlert, Select, FormLoading } from '../../../components'
import DataGrid, { Editing, Paging, Column } from 'devextreme-react/data-grid';
import { PlusIcon } from '@heroicons/react/outline';
import { DeliveryCostService } from '../../../services';
import { TimePicker, InputNumber } from 'antd';
import dayjs from 'dayjs';
import { isEmpty, get } from 'lodash'

const format = 'HH:mm';
const ModalForm = (props) => {
  let { masterOptions, open, data, auth } = props // STATES
  let { onClosed } = props // ACTIONS

  const [loading, setLoading]: any = useState({ visible: false, tip: 'กำลังบันทึก...' })
  const [validation, setValidation]: any = useState({})
  const [shakeIsWrong, setShakeIsWrong] = useState(false)
  const [optionAlert, setOptionAlert]: any = useState({
    show: false,
    action: {
      id: "",
      name: ""
    },
    type: "success",
    title: "",
    description: {
      visible: false,
      type: "",
      content: "",
      contentLists: [],

    }
  })
  const [costInput, setCostInput]: any = useState({
    cost: {
      cost_id: "",
      desc: "",
      cost_value: 0,
    },
    fuel: {
      fuel_id: "",
      cost_qty: 0,
      cost_per_unit: 0,
      cost_value: 0,
    }
  })

  const [dataForm, setDataForm]: any = useState({
    "delivery_date": "",
    "dlv_doc_no": "",
    "route_id": "",
    "round_no": "",
    "ts_gate_in": "",
    "ts_gate_out": "",
    "mileage_start": "",
    "mileage_end": "",
    "service_info": "",
    "ts_gate_net": "",
    "mileage_net": "",
    "detail": [],
    "tasks": [],
    "cost_items": [],
    "driver_1": "",
    "driver_2": "",
    "total_cost": 0
  })

  const gridRef: any = useRef(null);
  const inputRef: any = useRef([]);
  createMultipleRef(inputRef, 2)

  useEffect(() => {
    if (data) {
      let [driver_1, driver_2] = data?.service_infos || []
      let dataForm = {
        ...data,
        driver_1: driver_1 || "",
        driver_2: driver_2 || ""
      }
      setDataForm(dataForm)
    }
  }, [open, data])

  const initialData = () => {
    setValidation({})
    setDataForm({
      "delivery_date": "",
      "dlv_doc_no": "",
      "route_id": "",
      "round_no": "",
      "ts_gate_in": "",
      "ts_gate_out": "",
      "mileage_start": "",
      "mileage_end": "",
      "service_info": "",
      "ts_gate_net": "",
      "mileage_net": "",
      "detail": [],
      "tasks": [],
      "cost_items": [],
      "driver_1": "",
      "driver_2": "",
      "total_cost": 0
    })
    setCostInput({
      cost: {
        cost_id: "",
        desc: "",
        cost_value: 0,
      },
      fuel: {
        fuel_id: "",
        cost_qty: 0,
        cost_per_unit: 0,
        cost_value: 0,
      }
    })
  }

  const onSave = () => {
    let validateCheck = {}, message = { isError: true, message: "โปรดระบุ" }

    if (dataForm.round_no === "")
      validateCheck['round_no'] = message
    if (dataForm.ts_gate_in === "")
      validateCheck['ts_gate_in'] = message
    if (dataForm.ts_gate_out === "")
      validateCheck['ts_gate_out'] = message
    if (dataForm.mileage_start === "")
      validateCheck['mileage_start'] = message
    if (dataForm.mileage_end === "")
      validateCheck['mileage_end'] = message
    if (dataForm.driver_1.length === 0 || dataForm.driver_1 === "")
      validateCheck['driver_1'] = message

    if (isEmpty(validateCheck)) {
      setValidation({})
      setOptionAlert({
        ...optionAlert,
        action: {
          id: dataForm.id,
          name: "update"
        },
        type: "confirm",
        title: "ยืนยัน",
        show: true,
        description: {
          visible: true,
          type: "text",
          content: "คุณแน่ใจที่จะบันทึกข้อมูล ?",
          contentLists: []
        }
      })
    }
    else {
      setValidation(validateCheck)
      setShakeIsWrong(true)
      setTimeout(() => setShakeIsWrong(false), 100);
    }
  }

  const saveData = () => {
    let serviceList: any = []
    if (dataForm.driver_1 !== "") serviceList.push(dataForm.driver_1)
    if (dataForm.driver_2 !== "") serviceList.push(dataForm.driver_2)

    let tasks = dataForm.drops.map((item) => ({
      "drop_id": item.drop_id,
      "ts_gate_in": item.ts_gate_in,
      "ts_gate_out": item.ts_gate_out,
      "mileage_start": item.mileage_start,
      "mileage_end": item.mileage_end
    }))

    let data = {
      "delivery_date": dataForm.delivery_date,
      "dlv_doc_no": dataForm.dlv_doc_no,
      "route_id": dataForm.route_id,
      "round_no": dataForm.round_no,
      "ts_gate_in": dataForm.ts_gate_in,
      "ts_gate_out": dataForm.ts_gate_out,
      "mileage_start": dataForm.mileage_start,
      "mileage_end": dataForm.mileage_end,
      "service_info": JSON.stringify(serviceList),
      "tasks": tasks,
      "cost_items": [...dataForm.detail]
    }

    setLoading({ ...loading, visible: true, tip: 'กำลังบันทึก...' })
    DeliveryCostService.updateDeliveryCost(auth, data)
      .then((res: any) => {
        let { code, message, sys_message } = res.data
        if (code === 200) {
          setOptionAlert({
            ...optionAlert,
            type: "success",
            show: true,
            action: {
              id: "",
              name: ""
            },
            title: "บันทึกข้อมูลสำเร็จ",
            description: { visible: false, type: "text", content: " ", contentLists: [] }
          })
          initialData()
          onClosed(true)
        }
        else {
          setOptionAlert({
            ...optionAlert,
            type: "danger",
            show: true,
            action: {
              id: "",
              name: ""
            },
            title: "ผิดพลาด",
            description: { visible: true, type: "text", content: `${message} ${sys_message}` }
          })
        }
        setLoading({ ...loading, visible: false })
      })
      .catch((err) => {
        setOptionAlert({
          ...optionAlert,
          type: "danger",
          show: true,
          action: {
            id: "",
            name: ""
          },
          title: "ผิดพลาด",
          description: { visible: true, type: "text", content: `${err}` }
        })
        setLoading({ ...loading, visible: false })
      })
  }

  const addCostDetail = (cost_type) => {
    const cost = costInput.cost
    const fuel = costInput.fuel
    let detailLast = [...dataForm?.detail]

    let data = {
      cost_type,
      cost_id: "",
      cost_per_unit: "",
      cost_qty: "",
      cost_value: "",
      desc: "",
    }

    const isRequried = (name) => {
      setOptionAlert({
        ...optionAlert,
        action: {
          id: "",
          name: ""
        },
        type: "info",
        title: `โปรดระบุ${name}`,
        show: true,
        description: {
          visible: false,
          type: "text",
          content: "",
          contentLists: []
        }
      })
    }

    let isRequire = false
    if (cost_type === 1) {
      data = {
        ...data,
        cost_id: cost.cost_id,
        desc: cost.desc,
        cost_value: cost.cost_value
      }
      if (cost.cost_id === "") {
        isRequried("ค่าใช้จ่าย")
        isRequire = true
      }
    }
    else {
      data = {
        ...data,
        cost_id: fuel.fuel_id,
        cost_qty: fuel.cost_qty,
        cost_per_unit: fuel.cost_per_unit,
        cost_value: fuel.cost_value
      }
      if (fuel.fuel_id === "") {
        isRequried("ค่าเชื้อเพลิง")
        isRequire = true
      }
    }

    if (isRequire) return

    detailLast.push(data)

    let no = 1, total_cost = 0
    let detailNew = detailLast.map((item) => {
      item.no = no
      no++
      total_cost += item.cost_value
      return item
    })

    setDataForm({
      ...dataForm,
      detail: detailNew,
      total_cost
    })
  }

  const labelError = (fieldName) => {
    if (get(validation, fieldName)) {
      if (validation[fieldName].isError)
        return <p className="text-xs italic text-danger-600">
          {validation[fieldName].message}
        </p>
      else
        return null
    }
  }

  return (<>
    <Modal
      title={{ text: `บักทึกค่าใช้จ่าย` }}
      open={open}
      maskClosable={true}
      shake={shakeIsWrong}
      width={"w-3/4"}
      onClosed={() => {
        onClosed()
        initialData()
      }}
      onOk={() => onSave()}
    >
      <FormLoading loading={loading.visible} tip={loading.tip}>
        <div>
          <div>
            <span className="inline-flex items-center rounded bg-blue-100 px-4 py-0.5 mb-4 text-sm font-semibold text-blue-800">
              คน/รถ
            </span>

            <div className="flex flex-col -my-4 lg:space-y-0 sm:space-y-2">
              <div className="flex lg:flex-row  sm:flex-col sm:space-y-2">
                <div className="flex-1 sm:grid sm:grid-cols-3 sm:items-end sm:gap-2">
                  <label className="block text-xs font-medium text-gray-700 mb-1 text-right">
                    เลขที่ใบงาน :
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <Input
                      label="เลขที่ใบงาน"
                      placeholder="เลขที่ใบงาน"
                      hiddenLabel={true}
                      disabled={true}
                      defaultValue={dataForm.doc_no}
                    />
                  </div>
                </div>
                <div className="flex-1 sm:grid sm:grid-cols-3 sm:items-end sm:gap-2" />
                <div className="flex-1 sm:grid sm:grid-cols-3 sm:items-end sm:gap-2" />
              </div>

              <div className="flex lg:flex-row sm:flex-col sm:space-y-2">
                <div className="flex-1 sm:grid sm:grid-cols-3 sm:items-end sm:gap-2">
                  <label className="block text-xs font-medium text-gray-700 mb-1 text-right">
                    วันที่ :
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <Input
                      label="วันที่จัดส่ง"
                      placeholder="วันที่จัดส่ง"
                      hiddenLabel={true}
                      disabled={true}
                      defaultValue={dataForm.delivery_date}
                    />
                  </div>
                </div>
                <div className="flex-1 sm:grid sm:grid-cols-3 sm:items-end sm:gap-2">
                  <label className="block text-xs font-medium text-gray-700 mb-1 text-right">
                    ทะเบียน :
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <Input
                      label="ทะเบียน"
                      placeholder="ทะเบียน"
                      hiddenLabel={true}
                      disabled={true}
                      defaultValue={dataForm.licenseplate}
                    />
                  </div>
                </div>
                <div className="flex-1 sm:grid sm:grid-cols-3 sm:items-end sm:gap-2">
                  <label className="block text-xs font-medium text-gray-700 mb-1 text-right">
                    รอบที่ :
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <Input
                      label="รอบที่"
                      placeholder="รอบที่"
                      hiddenLabel={true}
                      disabled={true}
                      defaultValue={dataForm.round_no}
                    />
                  </div>
                </div>
              </div>

              <div className="flex lg:flex-row sm:flex-col sm:space-y-2">
                <div className="flex-1 sm:grid sm:grid-cols-3 sm:items-end sm:gap-2">
                  <label className="block text-xs font-medium text-gray-700 mb-1 text-right">
                    เวลาออก<span className="text-red-500">* </span> :{labelError("ts_gate_out")}
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <TimePicker
                      style={{ width: "100%" }}
                      placeholder="เวลาออก"
                      value={dataForm.ts_gate_out && dayjs(dataForm.ts_gate_out, format)}
                      status={validation['ts_gate_out']?.isError ? 'error' : ''}
                      onChange={(time) => {
                        let timeValue = time ? time?.format(format) : ""
                        setDataForm({ ...dataForm, ts_gate_out: timeValue })
                      }}
                      format={format} />
                  </div>
                </div>
                <div className="flex-1 sm:grid sm:grid-cols-3 sm:items-end sm:gap-2">
                  <label className="block text-xs font-medium text-gray-700 mb-1 text-right">
                    เวลาเข้า<span className="text-red-500">* </span> :{labelError("ts_gate_in")}
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <TimePicker
                      style={{ width: "100%" }}
                      placeholder="เวลาเข้า"
                      value={dataForm.ts_gate_in && dayjs(dataForm.ts_gate_in, format)}
                      status={validation['ts_gate_in']?.isError ? 'error' : ''}
                      onChange={(time) => {
                        let timeValue = time ? time?.format(format) : ""
                        setDataForm({ ...dataForm, ts_gate_in: timeValue })
                      }}
                      format={format} />
                  </div>
                </div>
                <div className="flex-1 sm:grid sm:grid-cols-3 sm:items-end sm:gap-2">
                  <label className="block text-xs font-medium text-gray-700 mb-1 text-right">
                    {`รวมเวลา(ชั่วโมง)`} :
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <Input
                      id="first_name"
                      label="รวมเวลา(ชั่วโมง)"
                      placeholder="รวมเวลา(ชั่วโมง)"
                      disabled={true}
                      hiddenLabel={true}
                      defaultValue={dataForm.ts_gate_net}
                    />
                  </div>
                </div>
              </div>

              <div className="flex lg:flex-row sm:flex-col sm:space-y-2">
                <div className="flex-1 sm:grid sm:grid-cols-3 sm:items-end sm:gap-2">
                  <label className="block text-xs font-medium text-gray-700 mb-1 text-right">
                    เลขไมล์ออก<span className="text-red-500">* </span> :{labelError("mileage_start")}
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <InputNumber
                      min={0}
                      value={dataForm.mileage_start}
                      placeholder="เลขไมล์ออก"
                      style={{ width: "100%" }}
                      status={validation['mileage_start']?.isError ? 'error' : ''}
                      onChange={(number) => setDataForm({ ...dataForm, mileage_start: number })}
                    />
                  </div>
                </div>
                <div className="flex-1 sm:grid sm:grid-cols-3 sm:items-end sm:gap-2">
                  <label className="block text-xs font-medium text-gray-700 mb-1 text-right">
                    เลขไมล์เข้า<span className="text-red-500">* </span> :{labelError("mileage_end")}
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <InputNumber
                      min={0}
                      value={dataForm.mileage_end}
                      placeholder="เลขไมล์เข้า"
                      style={{ width: "100%" }}
                      status={validation['mileage_end']?.isError ? 'error' : ''}
                      onChange={(number) => setDataForm({ ...dataForm, mileage_end: number })}
                    />
                  </div>
                </div>
                <div className="flex-1 sm:grid sm:grid-cols-3 sm:items-end sm:gap-2">
                  <label className="block text-xs font-medium text-gray-700 mb-1 text-right">
                    ระยะทาง :
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <Input
                      id="first_name"
                      label="ระยะทาง"
                      placeholder="ระยะทาง"
                      hiddenLabel={true}
                      disabled={true}
                      defaultValue={dataForm.mileage_net}
                    />
                  </div>
                </div>
              </div>

              <div className="flex lg:flex-row sm:flex-col sm:space-y-2">
                <div className="flex-1 sm:grid sm:grid-cols-3 sm:items-end sm:gap-2">
                  <label className="block text-xs font-medium text-gray-700 mb-1 text-right">
                    พนักงานขับ<span className="text-red-500">* </span> :{labelError("driver_1")}
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <Select
                      showSearch={true}
                      hiddenLabel={true}
                      defaultValue={dataForm.driver_1}
                      placeholder="พนักงานขับ"
                      validate={validation['driver_1']}
                      options={masterOptions.service}
                      onChange={(value) => setDataForm({ ...dataForm, driver_1: value })}
                    />
                  </div>
                </div>
                <div className="flex-1 sm:grid sm:grid-cols-3 sm:items-end sm:gap-2">
                  <label className="block text-xs font-medium text-gray-700 mb-1 text-right">
                    พนักงานขับ 2 :
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <Select
                      showSearch={true}
                      hiddenLabel={true}
                      defaultValue={dataForm.driver_2}
                      placeholder="พนักงานขับ 2"
                      options={masterOptions.service}
                      onChange={(value) => setDataForm({ ...dataForm, driver_2: value })}
                    />
                  </div>
                </div>
                <div className="flex-1 sm:grid sm:grid-cols-3 sm:items-end sm:gap-2">
                </div>
              </div>

              <div className="pt-2">
                <DataGrid
                  ref={(ref) => gridRef.current = ref}
                  dataSource={dataForm.drops}
                  keyExpr="drop_id"
                  showBorders={true}
                  onSaved={(e) => {
                    let items = e.component.getDataSource().items().map((item, index) => ({
                      ...item, no: (index + 1),
                      ma_cause: get(item, 'ma_cause', ""),
                      ma_desc: get(item, 'ma_desc', "")
                    }))
                  }}
                >
                  <Paging enabled={false} />
                  <Column
                    dataField="no"
                    caption="ลำดับ"
                    width={60}
                    alignment="center"
                    allowEditing={false}
                    allowFiltering={false}
                    allowSorting={false}
                  />
                  <Column
                    dataField="shipto_name"
                    caption="ปลายทาง/ชื่อลูกค้า"
                    allowEditing={false}
                    allowSorting={false}
                    cellRender={(e) => {
                      let info = e.data
                      return `${info.shipto_name} : ${info.cust_name}`
                    }}
                  />
                  <Column
                    dataField="mileage_end"
                    caption="เลขไมล์เข้า"
                    cellRender={(e) => {
                      let dt = e.data
                      let dtSource: any = { ...dataForm }
                      let dropIndex = dtSource?.drops.findIndex((item) => item.no === dt.no)

                      return <InputNumber
                        min={0}
                        value={dt.mileage_end}
                        placeholder="เลขไมล์เข้า"
                        style={{ width: "100%" }}
                        onChange={(number) => {
                          dtSource.drops[dropIndex].mileage_end = number
                          setDataForm(dtSource)
                        }}
                      />
                    }}
                  />

                  <Column
                    dataField="ts_gate_in"
                    caption="เวลาเข้า"
                    allowSorting={false}
                    cellRender={(e) => {
                      let dt = e.data
                      let dtSource: any = { ...dataForm }
                      let dropIndex = dtSource?.drops.findIndex((item) => item.no === dt.no)

                      return <TimePicker
                        style={{ width: "100%" }}
                        placeholder="เวลาเข้า"
                        value={dt.ts_gate_in && dayjs(dt.ts_gate_in, format)}
                        onChange={(time) => {
                          let timeValue = time ? time?.format(format) : ""
                          dtSource.drops[dropIndex].ts_gate_in = timeValue
                          setDataForm(dtSource)
                        }}
                        format={format} />
                    }} />
                  <Column
                    dataField="ts_gate_out"
                    caption="เวลาออก"
                    allowSorting={false}
                    cellRender={(e) => {
                      let dt = e.data
                      let dtSource: any = { ...dataForm }
                      let dropIndex = dtSource?.drops.findIndex((item) => item.no === dt.no)

                      return <TimePicker
                        style={{ width: "100%" }}
                        placeholder="เวลาออก"
                        value={dt.ts_gate_out && dayjs(dt.ts_gate_out, format)}
                        onChange={(time) => {
                          let timeValue = time ? time?.format(format) : ""
                          dtSource.drops[dropIndex].ts_gate_out = timeValue
                          setDataForm(dtSource)
                        }}
                        format={format} />
                    }} />
                </DataGrid>
              </div>
            </div>
          </div>
          <div className="flex flex-row w-full border-t mt-8 border-gray-300" />

          <div className='mt-2'>
            <span className="inline-flex items-center rounded bg-blue-100 px-4 py-0.5 mb-4 text-sm font-semibold text-blue-800">
              ค่าใช้จ่าย
            </span>

            <div className="flex flex-col -my-4 lg:space-y-0 sm:space-y-2">
              <div className="flex lg:flex-row  sm:flex-col sm:space-y-2">
                <div className="w-1/4 sm:grid sm:grid-cols-3 sm:items-end sm:gap-2">
                  <label className="block text-xs font-medium text-gray-700 mb-1 text-right">
                    ค่าใช้จ่าย :
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <Select
                      showSearch={true}
                      hiddenLabel={true}
                      defaultValue={costInput.cost.cost_id}
                      placeholder="ค่าใช้จ่าย"
                      options={masterOptions.cost_item}
                      onChange={(value) => setCostInput({ ...costInput, cost: { ...costInput.cost, cost_id: value } })}
                    />
                  </div>
                </div>

                <div className="w-2/4  sm:grid sm:grid-cols-6 sm:items-end sm:gap-2">
                  <label className="block text-xs font-medium text-gray-700 mb-1 text-right">
                    รายละเอียด :
                  </label>
                  <div className="mt-1 sm:col-span-5 sm:mt-0">
                    <Input
                      id="first_name"
                      label="รายละเอียด"
                      placeholder="รายละเอียด"
                      hiddenLabel={true}
                      defaultValue={costInput.cost.desc}
                      onChange={(e) => setCostInput({ ...costInput, cost: { ...costInput.cost, desc: e.target.value } })}
                    />
                  </div>
                </div>

                <div className="w-1/4  sm:grid sm:grid-cols-3 sm:items-end sm:gap-2">
                  <label className="block text-xs font-medium text-gray-700 mb-1 text-right">
                    จำนวนเงิน :
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <InputNumber
                      min={0}
                      defaultValue={costInput.cost.cost_value}
                      placeholder="จำนวนเงิน"
                      style={{ width: "100%" }}
                      onChange={(value) => setCostInput({ ...costInput, cost: { ...costInput.cost, cost_value: value } })}
                    />
                  </div>
                </div>

                <div className="flex flex-row ml-2">
                  <label className="text-sm font-medium text-gray-700 mt-3 text-start"> </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0 pt-1  -ml-1">
                    <Button
                      text="เพิ่ม"
                      icon={<PlusIcon />}
                      size="md"
                      type="primary"
                      iconPosition='start'
                      onClick={() => addCostDetail(1)}
                    />
                  </div>
                </div>
              </div>

              <div className="flex lg:flex-row  sm:flex-col sm:space-y-2">
                <div className="flex-1 sm:grid sm:grid-cols-3 sm:items-end sm:gap-2">
                  <label className="block text-xs font-medium text-gray-700 mb-1 text-right">
                    ค่าเชื้อเพลิง :
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <Select
                      showSearch={true}
                      hiddenLabel={true}
                      defaultValue={costInput.fuel.fuel_id}
                      placeholder="ค่าเชื้อเพลิง"
                      options={masterOptions.fuel_item}
                      onChange={(value) => {
                        let costInfo = masterOptions.fuel_item.find((item) => item.value === value)
                        let cost_value = (costInput.fuel.cost_qty * (costInfo ? costInfo.fuel_price : 0))
                        setCostInput({
                          ...costInput,
                          fuel: {
                            ...costInput.fuel,
                            fuel_id: value,
                            cost_per_unit: costInfo ? costInfo.fuel_price : 0,
                            cost_value
                          }
                        })
                      }} />
                  </div>
                </div>

                <div className="flex-1 sm:grid sm:grid-cols-3 sm:items-end sm:gap-2">
                  <label className="block text-xs font-medium text-gray-700 mb-1 text-right">
                    จำนวนลิตร :
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <InputNumber
                      min={0}
                      value={costInput.fuel.cost_qty}
                      placeholder="จำนวนลิตร"
                      style={{ width: "100%" }}
                      onChange={(value) => {
                        let cost_value = (value * costInput.fuel.cost_per_unit)
                        setCostInput({
                          ...costInput,
                          fuel: {
                            ...costInput.fuel,
                            cost_qty: value,
                            cost_value
                          }
                        })
                      }}
                    />
                  </div>
                </div>

                <div className="flex-1 sm:grid sm:grid-cols-3 sm:items-end sm:gap-2">
                  <label className="block text-xs font-medium text-gray-700 mb-1 text-right">
                    ราคา/หน่วย :
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <InputNumber
                      min={0}
                      value={costInput.fuel.cost_per_unit}
                      placeholder="ราคา/หน่วย"
                      style={{ width: "100%" }}
                      onChange={(value) => {
                        let cost_value = (costInput.fuel.cost_qty * value)
                        setCostInput({
                          ...costInput,
                          fuel: {
                            ...costInput.fuel,
                            cost_per_unit: value,
                            cost_value
                          }
                        })
                      }}
                    />
                  </div>
                </div>

                <div className="flex-1 sm:grid sm:grid-cols-3 sm:items-end sm:gap-2">
                  <label className="block text-xs font-medium text-gray-700 mb-1 text-right">
                    จำนวนเงิน :
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <InputNumber
                      min={0}
                      value={costInput.fuel.cost_value}
                      placeholder="จำนวนเงิน"
                      style={{ width: "100%" }}
                      onChange={(value) => setCostInput({ ...costInput, fuel: { ...costInput.fuel, cost_value: value } })}
                    />
                  </div>
                </div>

                <div className="flex flex-row ml-2">
                  <label className="text-sm font-medium text-gray-700 mt-3 text-start"> </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0 pt-1  -ml-1">
                    <Button
                      text="เพิ่ม"
                      icon={<PlusIcon />}
                      size="md"
                      type="primary"
                      iconPosition='start'
                      onClick={() => addCostDetail(2)}
                    />
                  </div>
                </div>
              </div>

              <div className="pt-2">
                <DataGrid
                  ref={(ref) => gridRef.current = ref}
                  dataSource={dataForm.detail}
                  height={150}
                  columnAutoWidth={true}
                  showBorders={true}
                >
                  <Paging enabled={false} />
                  <Editing
                    mode="row"
                    allowUpdating={true}
                    allowDeleting={true}
                    allowAdding={false} />

                  <Column dataField="no" caption="ลำดับ" width={60} alignment="center" allowFiltering={false} allowSorting={false} />
                  <Column
                    caption="รายการ"
                    width={"30%"}
                    allowSorting={false}
                    cellRender={(e) => {
                      let info = e.data
                      let resultName = ""
                      if (info?.cost_type === 1) {
                        let cost = masterOptions.cost_item.find((e) => e.value === info.cost_id)
                        resultName = cost ? cost.label : ""
                      }
                      else {
                        let fuel = masterOptions.fuel_item.find((e) => e.value === info.cost_id)
                        resultName = fuel ? fuel.label : ""
                      }
                      return resultName
                    }}
                  />
                  <Column
                    caption="รายละเอียด"
                    width={"40%"}
                    allowSorting={false}
                    cellRender={(e) => {
                      let info = e.data
                      let resultName = ""
                      if (info?.cost_type === 1)
                        resultName = info?.desc
                      else
                        resultName = `จำนวน ${info?.cost_qty | 0} ลิตร / ${info?.cost_per_unit | 0} บาท`

                      return resultName
                    }}
                  />
                  <Column dataField="cost_value" caption="จำนวนเงิน" allowSorting={false} />
                  <Column
                    caption="ลบ"
                    type="buttons"
                    width={50}
                    allowSorting={false}
                    buttons={[
                      {
                        hint: "ลบ",
                        icon: "trash",
                        visible: true,
                        onClick: (e) => {
                          e.cancel = "true"
                          let dt = e.row.data
                          let detailLast = [...dataForm?.detail]
                          let detail = detailLast.filter(item => item.no !== dt.no)

                          let no = 1, total_cost = 0
                          let detailSort = detail.map((item) => {
                            item.no = no
                            no++
                            total_cost += item.cost_value
                            return item
                          })

                          setDataForm({
                            ...dataForm,
                            detail: detailSort,
                            total_cost
                          })
                        }
                      }
                    ]} />
                </DataGrid>
              </div>

              <div className="flex flex-row-reverse -my-4 lg:space-y-0 sm:space-y-2 pt-2">
                <div className="flex flex-row-reverse sm:grid sm:grid-cols-3 sm:items-end sm:gap-2">
                  <label className="block text-xs font-medium text-gray-700 mb-1 text-right">
                    รวม :
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0 flex flex-row">
                    <InputNumber
                      min={0}
                      className="bg-gray-50 text-gray-500 cursor-not-allowed"
                      value={dataForm.total_cost}
                      disabled={true}
                      style={{ width: "100%" }}
                    />
                    <label className="text-xs font-medium text-gray-700 ml-2 mt-5">บาท</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </FormLoading>
    </Modal>

    <ModalAlert
      {...optionAlert}
      onOk={() => {
        let { action } = optionAlert
        if (action.name === "update") saveData()
        setOptionAlert({ ...optionAlert, show: false })
      }}
      onCancel={() => {
        setOptionAlert({ ...optionAlert, show: false })
      }}
    />
  </>
  )
}

export default ModalForm