import { Input, Button } from '../../../components'

const FormData = (props) => {
  let { onOpenForm } = props

  return <>
    <div className="px-1 py-1 dark:bg-gray-800 border-b">
      <div className="-ml-4 -mt-4 flex flex-wrap items-center justify-between sm:flex-nowrap">
        <div className="ml-4 mt-4">
          <h3 className="text-md font-medium leading-6 text-gray-900 dark:text-white">เพิ่มคลัง/สาขา</h3>
        </div>
        <div className="ml-4 mt-4 flex flex-shrink-0">
          <Button
            text="ยกเลิก"
            width="w-20"
            type="white"
            iconPosition='start'
            onClick={() => onOpenForm(false)}
            style={{ marginBottom: 4 }}
          />

          <Button
            text="บันทึก"
            width="w-20"
            iconPosition='start'
            onClick={() => onOpenForm(false)}
            style={{ marginBottom: 4 }}
          />
        </div>
      </div>
    </div>
    <div className="mt-4 -mx-2"
      style={{
        height: 'calc(100vh - 160px)',
        overflow: 'scroll',
        overflowX: 'hidden',
        overflowY: 'auto',
        position: 'relative'
      }}
    >
      <div className="flex flex-col space-y-2 mx-6">
        <Input
          label="รหัส"
          placeholder="รหัส"
          required={true}
        />

        <Input
          label="ชื่อคลัง/สาขา"
          placeholder="ชื่อคลัง/สาขา"
          required={true}
        />

        <Input
          label="ที่อยู่"
          placeholder="ที่อยู่"
        />

        <Input
          label="ชื่อผู้ติดต่อ"
          placeholder="ชื่อผู้ติดต่อ"
        />

        <Input
          label="เบอร์โทร"
          placeholder="เบอร์โทร"
        />

        <Input
          label="รูปไอคอน"
          placeholder="รูปไอคอน"
        />

        <Input
          label="เวลเปิด/ปิด"
          placeholder="เวลเปิด/ปิด"
        />

        <Input
          label="รายละเอียด"
          placeholder="รายละเอียด"
        />
        <Input
          label="ประเภทคลังสินค้า"
          placeholder="ประเภทคลังสินค้า"
          required={true}
        />
        <Input
          label="พิกัด"
          placeholder="พิกัด"
          required={true}
        />
        <Input
          label="สถานะ"
          placeholder="สถานะ"
        />
      </div>
    </div>
  </>
}

export default FormData