import { useState, useRef, useEffect, Fragment } from 'react'
import { inject, observer } from 'mobx-react';
import { createMultipleRef } from '../../helpers'
import { Card, Button, Input, ModalAlert, FormLoading } from '../../components'
import { UserService } from "../../services";
import { isEmpty } from 'lodash'
import { menuConfig } from '../../layout/MenuConfig'
import DataGrid, { SearchPanel, Toolbar, Item, Pager, Column, HeaderFilter } from 'devextreme-react/data-grid';
import { SearchIcon } from '@heroicons/react/outline';
import './style.css'

let firstRender = false
const Roles = inject("authenStore")(observer((props) => {
  let authenStore = props.authenStore
  let { getAccountAuth } = authenStore // STATE
  let auth = { action_user_id: getAccountAuth?.user_id, token_id: getAccountAuth?.access_token }

  const [dataSource, setDatasource]: any = useState([])
  const [roleSetting, setRoleSetting]: any = useState({
    visible: false,
    groupId: "",
    groupName: "",
    roles: []
  })
  const [loading, setLoading]: any = useState({ visible: false, tip: 'กำลังโหลด...' })
  const [optionAlert, setOptionAlert]: any = useState({
    show: false,
    action: {
      id: "",
      name: ""
    },
    type: "success",
    title: "",
    description: {
      visible: false,
      type: "",
      content: "",
      contentLists: [],
    }
  })


  const gridRef: any = useRef(null);
  const inputRef: any = useRef([]);
  createMultipleRef(inputRef, 4)

  useEffect(() => {
    if (firstRender || !isEmpty(getAccountAuth)) {
      getUserGroup()
    }
    else
      firstRender = true
  }, [getAccountAuth])

  const getUserGroup = () => {
    UserService.getUserGroup(auth)
      .then((res: any) => {
        setDatasource(res?.result || [])
      })
      .catch((err) => {
        setDatasource([])
      })
  }

  const getPermissionByGroup = (id, group_name) => {
    setLoading({ ...loading, visible: true, tip: "กำลังโหลด..." })
    UserService.getRoleByGroup(auth, id)
      .then((res: any) => {
        let { code, result, message, sys_message } = res
        if (code === 200) {
          setRoleSetting({
            visible: true,
            groupId: id,
            groupName: group_name,
            roles: result
          })
        }
        else {
          setRoleSetting({
            visible: false,
            groupId: "",
            groupName: "",
            roles: []
          })

          setOptionAlert({
            ...optionAlert,
            type: "danger",
            show: true,
            action: {
              id: "",
              name: ""
            },
            title: "ผิดพลาด",
            description: { visible: true, type: "text", content: `${message} ${sys_message}` }
          })
        }
        setLoading({ ...loading, visible: false })
      })
      .catch((err) => {
        setLoading({ ...loading, visible: false })
        setRoleSetting({
          visible: false,
          groupId: "",
          groupName: "",
          roles: []
        })
        setOptionAlert({
          ...optionAlert,
          type: "danger",
          show: true,
          action: {
            id: "",
            name: ""
          },
          title: "ผิดพลาด",
          description: { visible: true, type: "text", content: `${err}` }
        })
      })
  }

  const updateRoles = () => {
    setLoading({ ...loading, visible: true, tip: "กำลังบันทึก..." })
    let data = {
      roles: roleSetting.roles
    }
    UserService.updateRoles(auth, data)
      .then((res: any) => {
        let { code, message, sys_message } = res

        if (code === 200) {
          setOptionAlert({
            ...optionAlert,
            type: "success",
            show: true,
            action: {
              id: "",
              name: ""
            },
            title: "บันทึกข้อมูลสำเร็จ",
            description: { visible: false, type: "text", content: " ", contentLists: [] }
          })
        }
        else {
          setOptionAlert({
            ...optionAlert,
            type: "danger",
            show: true,
            action: {
              id: "",
              name: ""
            },
            title: "ผิดพลาด",
            description: { visible: true, type: "text", content: `${message} ${sys_message}` }
          })
        }
        setLoading({ ...loading, visible: false })
      })
      .catch((err) => {
        setLoading({ ...loading, visible: false })
        setOptionAlert({
          ...optionAlert,
          type: "danger",
          show: true,
          action: {
            id: "",
            name: ""
          },
          title: "ผิดพลาด",
          description: { visible: true, type: "text", content: `${err}` }
        })
      })
  }

  const checkAllow = (id, isSubMenu = false) => {
    let allowSetting = {}
    if (isSubMenu) {
      allowSetting = roleSetting?.roles.find((item) => item.permission_code == id)
    }
    else {
      allowSetting = roleSetting?.roles.find((item) => item.main_code == id)
    }
    return allowSetting
  }

  const onCheckChange = (id, fieldName, checked, isSubMenu = false) => {
    let rolesCurrent = { ...roleSetting }
    let findIndex = -1
    if (isSubMenu)
      findIndex = rolesCurrent?.roles.findIndex((item) => item.permission_code == id)
    else
      findIndex = rolesCurrent?.roles.findIndex((item) => item.main_code == id)

    if (findIndex >= 0) {
      rolesCurrent.roles[findIndex][fieldName] = checked
    }
    setRoleSetting(rolesCurrent)
  }

  return (
    <>
      <Card title="กำหนดสิทธิ์การใช้งาน">
        <FormLoading loading={loading.visible} tip={loading.tip}>
          <div className="flex flex-row">
            <div className="flex-2 w-1/4 p-2">
              <div className="mt-5 md:col-span-2 md:-mt-2">
                <DataGrid
                  ref={(ref) => gridRef.current = ref}
                  // dataSource={customers}
                  dataSource={dataSource}
                  keyExpr={'id'}
                  // defaultColumns={defaultColumn}
                  height="calc(100vh - 219px)"
                  showBorders={true}
                  columnAutoWidth={true}
                  hoverStateEnabled={true}
                >
                  <SearchPanel visible={true} />
                  <Pager visible={false} />
                  <HeaderFilter visible={true} />

                  <Column
                    dataField={"group_name"}
                    caption={"กลุ่มผู้ใช้งาน"}
                    // width={200}
                    allowFiltering={true}
                  />
                  <Column
                    dataField={"user_count"}
                    caption={"จำนวนผู้ใช้งาน"}
                    // dataType="text"
                    width={120}
                    // minWidth={50}s
                    allowFiltering={false}
                  />
                  <Column
                    fixed={true}
                    caption="ตั้งค่า"
                    type="buttons"
                    width={50}
                    buttons={[
                      {
                        hint: "ตั้งค่า",
                        icon: "preferences",
                        visible: true,
                        onClick: (e) => {
                          e.cancel = "true"
                          let data = e.row.data
                          getPermissionByGroup(data.id, data.group_name)
                        }
                      }
                    ]} />
                  <Toolbar>
                    <Item location="start">
                      <Input
                        icon={<SearchIcon className="w-5 h-5 text-gray-500" />}
                        placeholder="ค้นหา..."
                        onChange={(e) => {
                          gridRef.current.instance.searchByText(e.target.value)
                        }}
                      />
                    </Item>
                  </Toolbar>
                </DataGrid>
              </div>
            </div>
            <div className="flex-1 w-3/4 -2 -my-2 -mx-4 sm:-mx-6 lg:-mx-8" >
              <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8 mt-2">

                {
                  roleSetting.visible && <div className="flex flex-row">
                    <div className="flex-1">
                      <span className="text-sm font-semibold rounded bg-blue-100 text-blue-800 px-2.5 py-0.5">กลุ่มผู้ใช้งาน : {roleSetting.groupName}</span>
                    </div>
                    <div className="flex flex-row-reverse mb-2">
                      <Button
                        text="บันทึก"
                        width="w-20"
                        // disabled={roleSetting.visible ?  false : true}
                        onClick={() =>
                          setOptionAlert({
                            ...optionAlert,
                            type: "confirm",
                            action: {
                              id: "",
                              name: 'save'
                            },
                            title: "ยืนยัน",
                            show: true,
                            description: { visible: true, type: "text", content: "คุณแน่ใจที่จะบันทึกข้อมูล ?", contentLists: [] }
                          })}
                      />
                      <Button
                        text="คืนค่า"
                        type="white"
                        width="w-20"
                        disabled={roleSetting.visible ? false : true}
                        onClick={() => getPermissionByGroup(roleSetting.groupId, roleSetting.groupName)}
                      />
                    </div>
                  </div>
                }

                <div className={`${roleSetting.length === 0 ? 'scrollbar-content' : 'scrollbar-content-setting'} shadow ring-1 ring-black ring-opacity-5 md:rounded-sm`}>
                  <table className="min-w-full">
                    <thead className="bg-white">
                      <tr className='divide-x divide-gray-400'>
                        <th scope="col" className="py-3.5 pl-4 pr-3 text-center text-sm font-semibold text-gray-900 sm:pl-6">
                          เมนูการใช้งาน
                        </th>
                        <th scope="col" className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900 min-w-24 max-w-48">
                          เปิดใช้
                        </th>
                        {/* <th scope="col" className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900 min-w-24 max-w-48">
                          เพิ่ม
                        </th>
                        <th scope="col" className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900 min-w-24 max-w-48">
                          แก้ไข
                        </th>
                        <th scope="col" className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900 min-w-24 max-w-48">
                          ลบ
                        </th>
                        <th scope="col" className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900 min-w-24 max-w-48">
                          Export
                        </th>
                        <th scope="col" className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900 min-w-24 max-w-48">
                          Print
                        </th> */}
                      </tr>
                    </thead>
                    <tbody className="bg-white">
                      {menuConfig.map((menu) => {
                        let mainMeunuId = menu.id

                        let allowSetting: any = checkAllow(mainMeunuId)

                        return <Fragment key={menu.name}>
                          <tr className="border-t border-gray-200 z-50">
                            <th
                              colSpan={7}
                              scope="colgroup"
                              className="bg-gray-50 px-4 py-2 text-left text-sm font-semibold text-gray-900 sm:px-6"
                            >
                              {menu.name}
                            </th>
                          </tr>
                          {menu?.subMenu ? menu.subMenu.map((menu, index) => {
                            let subMunuId = menu.id
                            let allowSetting: any = checkAllow(menu.id, true)

                            return <tr
                              key={menu.name}
                              className={`divide-x divide-gray-200 border-t ${index === 0 ? 'border-gray-300' : 'border-gray-200'}`}
                            >
                              <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                <span className='ml-6'>{menu.name}</span>
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-center">
                                <input
                                  id="comments"
                                  name="comments"
                                  type="checkbox"
                                  checked={allowSetting?.is_read ? true : false}
                                  disabled={roleSetting.visible ? false : true}
                                  onChange={(e) => onCheckChange(subMunuId, "is_read", e.target.checked, true)}
                                  className={`h-6 w-6 rounded border-gray-300 text-primary-600  ${roleSetting.visible ? 'cursor-pointer' : 'cursor-not-allowed bg-gray-100'}`}
                                />
                              </td>
                              {/* <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-center">
                                <input
                                  id="comments"
                                  name="comments"
                                  type="checkbox"
                                  checked={allowSetting?.is_created ? true : false}
                                  disabled={roleSetting.visible ? false : true}
                                  onChange={(e) => onCheckChange(subMunuId, "is_created", e.target.checked, true)}
                                  className={`h-6 w-6 rounded border-gray-300 text-primary-600  ${roleSetting.visible ? 'cursor-pointer' : 'cursor-not-allowed bg-gray-100'}`}

                                />
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-center">
                                <input
                                  id="comments"
                                  name="comments"
                                  type="checkbox"
                                  checked={allowSetting?.is_updated ? true : false}
                                  disabled={roleSetting.visible ? false : true}
                                  onChange={(e) => onCheckChange(subMunuId, "is_updated", e.target.checked, true)}
                                  className={`h-6 w-6 rounded border-gray-300 text-primary-600  ${roleSetting.visible ? 'cursor-pointer' : 'cursor-not-allowed bg-gray-100'}`}

                                />
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-center">
                                <input
                                  id="comments"
                                  name="comments"
                                  type="checkbox"
                                  checked={allowSetting?.is_deleted ? true : false}
                                  disabled={roleSetting.visible ? false : true}
                                  onChange={(e) => onCheckChange(subMunuId, "is_deleted", e.target.checked, true)}
                                  className={`h-6 w-6 rounded border-gray-300 text-primary-600  ${roleSetting.visible ? 'cursor-pointer' : 'cursor-not-allowed bg-gray-100'}`}

                                />
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-center">
                                <input
                                  id="comments"
                                  name="comments"
                                  type="checkbox"
                                  checked={allowSetting?.is_export ? true : false}
                                  disabled={roleSetting.visible ? false : true}
                                  onChange={(e) => onCheckChange(subMunuId, "is_export", e.target.checked, true)}
                                  className={`h-6 w-6 rounded border-gray-300 text-primary-600  ${roleSetting.visible ? 'cursor-pointer' : 'cursor-not-allowed bg-gray-100'}`}

                                />
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-center">
                                <input
                                  id="comments"
                                  name="comments"
                                  type="checkbox"
                                  checked={allowSetting?.is_print ? true : false}
                                  disabled={roleSetting.visible ? false : true}
                                  onChange={(e) => onCheckChange(subMunuId, "is_print", e.target.checked, true)}
                                  className={`h-6 w-6 rounded border-gray-300 text-primary-600  ${roleSetting.visible ? 'cursor-pointer' : 'cursor-not-allowed bg-gray-100'}`}

                                />
                              </td> */}
                            </tr>
                          })
                            : <tr
                              key={menu.name}
                              className={`divide-x divide-gray-200 border-gray-200 border-t`}
                            >
                              <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                <span className='ml-6'>{menu.name}</span>
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-center">
                                <input
                                  id="comments"
                                  name="comments"
                                  type="checkbox"
                                  checked={allowSetting?.is_read ? true : false}
                                  onChange={(e) => onCheckChange(mainMeunuId, "is_read", e.target.checked)}
                                  className={`h-6 w-6 rounded border-gray-300 text-primary-600  ${roleSetting.visible ? 'cursor-pointer' : 'cursor-not-allowed bg-gray-100'}`}
                                />
                              </td>
                              {/* <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-center">
                                <input
                                  id="comments"
                                  name="comments"
                                  type="checkbox"
                                  checked={allowSetting?.is_created ? true : false}
                                  onChange={(e) => onCheckChange(mainMeunuId, "is_created", e.target.checked)}
                                  disabled={roleSetting.visible ? false : true}
                                  className={`h-6 w-6 rounded border-gray-300 text-primary-600  ${roleSetting.visible ? 'cursor-pointer' : 'cursor-not-allowed bg-gray-100'}`}

                                />
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-center">
                                <input
                                  id="comments"
                                  name="comments"
                                  type="checkbox"
                                  checked={allowSetting?.is_updated ? true : false}
                                  onChange={(e) => onCheckChange(mainMeunuId, "is_updated", e.target.checked)}
                                  disabled={roleSetting.visible ? false : true}
                                  className={`h-6 w-6 rounded border-gray-300 text-primary-600  ${roleSetting.visible ? 'cursor-pointer' : 'cursor-not-allowed bg-gray-100'}`}

                                />
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-center">
                                <input
                                  id="comments"
                                  name="comments"
                                  type="checkbox"
                                  checked={allowSetting?.is_deleted ? true : false}
                                  onChange={(e) => onCheckChange(mainMeunuId, "is_deleted", e.target.checked)}
                                  disabled={roleSetting.visible ? false : true}
                                  className={`h-6 w-6 rounded border-gray-300 text-primary-600  ${roleSetting.visible ? 'cursor-pointer' : 'cursor-not-allowed bg-gray-100'}`}

                                />
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-center">
                                <input
                                  id="comments"
                                  name="comments"
                                  type="checkbox"
                                  checked={allowSetting?.is_export ? true : false}
                                  onChange={(e) => onCheckChange(mainMeunuId, "is_export", e.target.checked)}
                                  disabled={roleSetting.visible ? false : true}
                                  className={`h-6 w-6 rounded border-gray-300 text-primary-600  ${roleSetting.visible ? 'cursor-pointer' : 'cursor-not-allowed bg-gray-100'}`}

                                />
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-center">
                                <input
                                  id="comments"
                                  name="comments"
                                  type="checkbox"
                                  checked={allowSetting?.is_print ? true : false}
                                  onChange={(e) => onCheckChange(mainMeunuId, "is_print", e.target.checked)}
                                  disabled={roleSetting.visible ? false : true}
                                  className={`h-6 w-6 rounded border-gray-300 text-primary-600  ${roleSetting.visible ? 'cursor-pointer' : 'cursor-not-allowed bg-gray-100'}`}
                                />
                              </td> */}
                            </tr>
                          }
                        </Fragment>
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </FormLoading>
      </Card >

      <ModalAlert
        {...optionAlert}
        onOk={(show) => {
          let { action } = optionAlert
          if (action.name === "save") updateRoles()

          setOptionAlert({ ...optionAlert, show: false })
        }}
        onCancel={() => {
          setOptionAlert({ ...optionAlert, show: false })
        }}
      />
    </>
  )
}))

export default Roles