import { useEffect, useState, useRef } from 'react'
import { inject, observer } from 'mobx-react';
import FormMaster from '../FormMaster'
import { CommonService } from "../../../services";
import { Modal, Button } from '../../../components'
import GoogleMapReact from "google-map-react"
import { GOOGLE_MAP_KEY } from '../../../AppConfig'
import './style.css'
import moment from 'moment';
import { isEmpty } from 'lodash';

const Marker: any = ({ children }) => children;
let firstRender = false
const Device = inject("authenStore")(observer((props) => {
  let authenStore = props.authenStore
  let { getAccountAuth } = authenStore // STATE
  let auth = { action_user_id: getAccountAuth?.user_id, token_id: getAccountAuth?.access_token }

  const mapRef: any = useRef()
  const [map, setMap]: any = useState(null)
  const [modalMap, setModalMap] = useState({
    show: false,
    info: {
      lat: 13.788042,
      lon: 100.606507,
      "speed": 90,
      "course": 100,
      "utc_ts": "2023-04-06 15:59:50.621790",
      "batt_level": 90,
      "disk_space": 39.23,
      "gps_status": 2,
      "service_name": "DRIVER NAME"
    }
  })
  const [masterOptions, setMasterOptions] = useState({
    common_status: [],
    service: [],
    subcontract: []
  })

  useEffect(() => { createMarker(modalMap.info.lat, modalMap.info.lon) }, [map])

  useEffect(() => {
    if (firstRender || !isEmpty(getAccountAuth)) {
      CommonService.getMasterData(auth, "common_status,service,subcontract")
        .then((res: any) => {
          let common_status = res.result.common_status.map((item) => ({ label: item.status_name, value: item.status_code }))
          let service = res.result.service.map((item) => ({ label: item.service_name, value: item.service_id }))
          let subcontract = res.result.subcontract.map((item) => ({ label: item.sub_name, value: item.sub_id }))
          setMasterOptions({
            ...masterOptions,
            common_status,
            service,
            subcontract
          })
        })
        .catch((err) => {
          console.log(`Load master data : ${err}`)
        })
    }
    else
      firstRender = true
  }, [getAccountAuth])

  const createMarker = (lat = 0, lng = 0) => {
    try {
      if (map) {
        const marker: any = new window.google.maps.Marker({
          position: { lat, lng },
          map
        })

        if (map) {
          map.panTo({ lat, lng })
          if (map.zoom < 15) {
            setTimeout(() => {
              map.setZoom(10)
            }, 800);
          }
        }

      }
    } catch (error) {

    }
  }

  return <>
    <FormMaster
      title="ข้อมูลทะเบียนอุปกรณ์"
      tableConfig={{
        key: "id",
        apiPath: "device/list",
        allowDelete: {
          visible: true,
          key: "device_id",
          apiPath: "device/delete",
          paramType: 'param'
        },
        optionDeleteFag: true,
        defaultColumn: [],
        columns: [
          {
            dataField: 'no',
            caption: "ลำดับ",
            width: 60,
            alignment: "center"
          },
          {
            dataField: 'device_name',
            caption: "ชื่ออุปกรณ์",
            allowFiltering: true
          },
          {
            dataField: 'imei',
            caption: "เลข IMEI อุปกรณ์",
            allowFiltering: true
          },
          {
            dataField: 'device_brand',
            caption: "ยี่ห้อ",
            allowFiltering: true
          },
          {
            dataField: 'device_model',
            caption: "รุ่น",
            allowFiltering: true
          },
          {
            dataField: 'service_name',
            caption: "พนักงานขับรถ",
            allowFiltering: true
          },
          {
            dataField: 'sub_name',
            caption: "สังกัดผู้รับจ้างช่วง",
            allowFiltering: true
          },
          {
            dataField: 'loc',
            caption: "GPS Status",
            alignment: "center",
            width: 90,
            allowSorting: false,
            cellRender: (e) => {
              let status = e.displayValue.gps_status
              if ([1, 2].includes(status)) {
                let statusColor = status === 1 ? 'text-success-500' : 'text-gray-500'
                let info = e.displayValue
                info.service_name = e.data.service_name
                return <i
                  className={`fa fa-circle ${statusColor} cursor-pointer status-zoom`}
                  onClick={() => {
                    setModalMap({ ...modalMap, show: true, info: e.displayValue })
                    setTimeout(() => {
                      createMarker(info.lat, info.lon)
                    }, 500);
                  }}
                />
              }
              return null
            }
          },
          {
            dataField: 'status_name',
            caption: "สถานะ",
            allowFiltering: true
          },
          {
            dataField: 'updated_by',
            caption: "แก้ไขโดย"
          }
        ],
        exportExcel: {
          fileName: 'ข้อมูลทะเบียนอุปกรณ์',
          columnConfig: [
            { label: 'ลำดับ', value: 'no' },
            { label: 'ชื่ออุปกรณ์', value: 'device_name' },
            { label: 'เลข IMEI อุปกรณ์', value: 'imei' },
            { label: 'ยี่ห้อ', value: 'device_brand' },
            { label: 'รุ่น', value: 'device_model' },
            { label: 'พนักงานขับรถ', value: 'service_name' },
            { label: 'สังกัดผู้รับจ้างช่วง', value: 'sub_name' },
            { label: 'สถานะ', value: 'status_name' },
            { label: 'แก้ไขโดย', value: 'updated_by' }
          ]
        }
      }}
      formData={{
        sub_id: "",
        service_id: "",
        device_name: "",
        imei: "",
        device_brand: "",
        device_model: "",
        os_info: "",
        status: "Y"
      }}
      modalConfig={{
        visible: true,
        title: "ทะเบียนอุปกรณ์",
        required: ["device_name", "imei"],
        fieldTypeNumber: [],
        paramName: "device_id",
        width: "w-2/4",
        apiPath: {
          "get": "",
          "post": "device/add",
          "put": "device/update"
        },
        elements: [
          {
            fieldName: "device_name",
            label: "ชื่ออุปกรณ์",
            placeholder: "ชื่ออุปกรณ์",
            required: true,
            inputType: "text"
          },
          {
            fieldName: "imei",
            label: "เลข IMEI อุปกรณ์",
            placeholder: "เลข IMEI อุปกรณ์",
            required: true,
            inputType: "text"
          },
          {
            fieldName: "device_brand",
            label: "ยี่ห้อ",
            placeholder: "ยี่ห้อ",
            inputType: "text"
          },
          {
            fieldName: "device_model",
            label: "รุ่น",
            placeholder: "รุ่น",
            inputType: "text"
          },
          {
            fieldName: "os_info",
            label: "ค่า parameter ของ OS ของ Andriod ",
            placeholder: "ค่า parameter ของ OS ของ Andriod ",
            inputType: "text"
          },
          {
            fieldName: "service_id",
            label: "พนักงานขับรถ",
            placeholder: "พนักงานขับรถ",
            inputType: "select",
            allowClear: true,
            options: masterOptions.service
          },
          {
            fieldName: "sub_id",
            label: "ผู้รับจ้างช่วง",
            placeholder: "ผู้รับจ้างช่วง",
            inputType: "select",
            allowClear: true,
            options: masterOptions.subcontract
          },
          {
            showSearch: false,
            fieldName: "status",
            label: "สถานะ",
            inputType: "select",
            defaultValue: "Y",
            options: masterOptions.common_status
          }
        ]
      }}
    />
    <Modal
      title={{ text: "พิกัดอุปกรณ์" }}
      open={modalMap.show}
      maskClosable={true}
      contentPadding="p-0"
      onClosed={() => { }}
      footer={<Button
        text="ปิด"
        width="w-20"
        onClick={() => setModalMap({ ...modalMap, show: false })}
      />}
    >
      <div style={{ height: 300, width: '100%' }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: `${GOOGLE_MAP_KEY}&libraries=drawing,geometry` }}
          defaultCenter={{ lat: 13.788500, lng: 100.610055 }}
          defaultZoom={5}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={({ map }) => {
            mapRef.current = map
            setMap(map)
          }}
        >
          <Marker
            key={"maker-evnet"}
            lat={modalMap.info.lat}
            lng={modalMap.info.lon}
          >
            <div className='box-info'>
              <div className="flex flex-row text-center">
                <div className=" flex flex-1">
                  <i className="fa fa-user" style={{ margin: 3, fontSize: 13 }} />
                  <span className="box-info-text">พนักงานขับรถ</span>
                </div>

                <div className=" flex flex-1" >
                  <span className="box-info-text">{modalMap.info.service_name}</span>
                </div>
              </div>

              <div className="flex flex-row text-center">
                <div className=" flex flex-1" >
                  <i className="fa fa-clock-o" style={{ margin: 3, fontSize: 13 }} />
                  <span className="box-info-text">เวลา</span>
                </div>

                <div className=" flex flex-1" >
                  <span className="box-info-text">{moment(modalMap.info.utc_ts).format("DD/MM/YYYY HH:mm:ss")}</span>
                </div>
              </div>

              <div className="flex flex-row text-center">
                <div className=" flex flex-1" >
                  <i className="fa fa-map-marker" style={{ margin: '3px 3px 3px 5px', fontSize: 13 }} />
                  <span className="box-info-text">พิกัด</span>
                </div>

                <div className="flex flex-1" >
                  <span className="box-info-text">{`${modalMap.info.lat}, ${modalMap.info.lon}`}</span>
                </div>
              </div>
            </div>
          </Marker>

        </GoogleMapReact>
      </div>
    </Modal>

  </>
}))
export default Device