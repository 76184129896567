
import { useState, useEffect } from 'react'
import { inject, observer } from 'mobx-react';
import { Modal, ModalAlert, FormLoading, Select, Input, Card } from '../../components'
import DataGrid, { Scrolling, Column, Selection, Paging } from 'devextreme-react/data-grid';
import { CommonService, PriceModelService } from '../../services';
import { isEmpty } from 'lodash'
import { Checkbox } from 'antd';

const ModalPriceSet = inject("priceModelStore")(observer((props) => {
  let priceModelStore = props.priceModelStore
  let { setPriceModeStore } = priceModelStore // STATE

  let { auth, open, masterOptions, data, defaultSelected, productForm } = props // STATES
  let { onReload, onClosed, onSelected } = props // ACTIONS

  const [loading, setLoading]: any = useState({ visible: false, tip: 'กำลังบันทึก...' })
  const [validation, setValidation]: any = useState({})
  const [priceSetList, setPriceList]: any = useState([])
  const [infoPrice, setInfoPrice]: any = useState({
    cust_name: "",
    zone_name: "",
    privince: "",
    district: "",
    logistic_type: ""
  })
  const [priceSelect, setPriceSelect]: any = useState({})
  const [shakeIsWrong, setShakeIsWrong] = useState(false)
  const [optionAlert, setOptionAlert]: any = useState({
    show: false,
    action: {
      id: "",
      name: ""
    },
    type: "success",
    title: "",
    description: {
      visible: false,
      type: "",
      content: "",
      contentLists: [],

    }
  })

  useEffect(() => {
    if (open) {
      let { cust_id, logistic_type_id, shipto_id } = data
      if (cust_id !== "" && logistic_type_id !== "" && shipto_id !== "") {
        let product_id = logistic_type_id === 2 ? productForm?.product_id : ""
        getPrice(cust_id, logistic_type_id, shipto_id, product_id)
        // getPrice(16, 2, 37, product_id)
      }
      // console.log("data : ", data)
    }
  }, [open, data])


  const onSave = () => {
    let validateCheck = {}, message = { isError: true, message: "โปรดระบุ" }

    if (isEmpty(priceSelect)) validateCheck['price_set_name'] = message

    if (isEmpty(validateCheck)) {
      setValidation({})
      onSelected(priceSelect)
      clearDataForm()
    }
    else {
      setValidation(validateCheck)
      setShakeIsWrong(true)
      setTimeout(() => setShakeIsWrong(false), 100);
    }
  }

  const clearDataForm = () => {
    if (defaultSelected.price_set_id === "") setPriceSelect({})
    setValidation({})
    setInfoPrice({
      cust_name: "",
      zone_name: "",
      privince: "",
      district: "",
      logistic_type: ""
    })
  }

  const getPrice = (cust_id, logistic_type_id, shipto_id, product_id) => {
    setLoading({ ...loading, visible: true, tip: "กำลังโหลด..." })
    PriceModelService.getPrice(auth, { cust_id, logistic_type_id, shipto_id, product_id })
      .then((res: any) => {
        let { code, result, message, sys_message } = res
        if (code === 200) {
          let { cust_id, zone_name, logistic_type, admin_level1, admin_level2 } = result
          setPriceList(result.prices)
          let customer = masterOptions.customer.find((dt) => dt.value === cust_id)
          let logistic = masterOptions.logistic_type.find((dt) => dt.value === logistic_type)
          let province = masterOptions.province.find((dt) => dt.value === admin_level1)

          const getDistinceAndMapping = async () => {
            if (admin_level1) {
              CommonService.getMasterData(auth, "admin_level2", admin_level1)
                .then((res: any) => {
                  let distince = res.result.admin_level2.map((item) => ({ label: item.admin_level2_name, value: item.admin_level2_id }))
                  let distince_name = distince.find((dt) => dt.value === admin_level2)

                  setInfoPrice({
                    ...infoPrice,
                    cust_name: customer ? customer.label : "",
                    zone_name,
                    province: province ? province.label : "",
                    district: distince_name ? distince_name.label : "",
                    logistic_type: logistic ? logistic.label : "",
                  })

                  setPriceSelect({ price_set_id: defaultSelected.price_set_id })
                })
                .catch((err) => {
                  setInfoPrice({
                    ...infoPrice,
                    cust_name: customer ? customer.label : "",
                    zone_name,
                    province: province ? province.label : "",
                    logistic_type: logistic ? logistic.label : "",
                  })
                })
            }
          }
          getDistinceAndMapping()
        }
        else {
          setOptionAlert({
            ...optionAlert,
            type: "danger",
            show: true,
            action: {
              id: "",
              name: ""
            },
            title: "ผิดพลาด",
            description: { visible: true, type: "text", content: `${message} ${sys_message}` }
          })
        }
        setLoading({ ...loading, visible: false })
      })
      .catch((err) => {
        setLoading({ ...loading, visible: false })
        setOptionAlert({
          ...optionAlert,
          type: "danger",
          show: true,
          action: {
            id: "",
            name: ""
          },
          title: "ผิดพลาด",
          description: { visible: true, type: "text", content: `${err}` }
        })
      })
  }

  return (<>
    <Modal
      title={{ text: `เลือกราคา` }}
      open={open}
      maskClosable={true}
      buttonOk={{ text: "ตกลง" }}
      shake={shakeIsWrong}
      // onClosed={() => setPriceModeStore({ open: false })}
      width="lg:w-1/2 sm:w-full sm:mx-20"
      onClosed={() => {
        onClosed()
        clearDataForm()
      }}
      onOk={() => onSave()}
    >
      <FormLoading loading={loading.visible} tip={loading.tip}>
        <div className="flex flex-col space-y-2">
          <div className='flex-1'>
            <div className="flex-1 sm:grid sm:grid-cols-4 sm:items-end sm:gap-2">
              <label className="block text-sm font-medium text-gray-700 mb-1 text-left">
                ลูกค้า :
              </label>
              <div className="mt-1 sm:col-span-3 sm:mt-0">
                <Input
                  placeholder="ลูกค้า"
                  hiddenLabel={true}
                  disabled={true}
                  defaultValue={infoPrice.cust_name}
                />
              </div>
            </div>

            <div className="flex-1 sm:grid sm:grid-cols-4 sm:items-end sm:gap-2">
              <label className="block text-sm font-medium text-gray-700 mb-1 text-left">
                โซน :
              </label>
              <div className="mt-1 sm:col-span-3 sm:mt-0">
                <Input
                  placeholder="โซน"
                  hiddenLabel={true}
                  disabled={true}
                  defaultValue={infoPrice.zone_name}
                />
              </div>
            </div>

            <div className="flex-1 sm:grid sm:grid-cols-4 sm:items-end sm:gap-2">
              <label className="block text-sm font-medium text-gray-700 mb-1 text-left">
                จังหวัด :
              </label>
              <div className="mt-1 sm:col-span-3 sm:mt-0">
                <Input
                  placeholder="จังหวัด"
                  hiddenLabel={true}
                  disabled={true}
                  defaultValue={infoPrice.province}
                />
              </div>
            </div>

            <div className="flex-1 sm:grid sm:grid-cols-4 sm:items-end sm:gap-2">
              <label className="block text-sm font-medium text-gray-700 mb-1 text-left">
                อำเภอ :
              </label>
              <div className="mt-1 sm:col-span-3 sm:mt-0">
                <Input
                  placeholder="อำเภอ"
                  hiddenLabel={true}
                  disabled={true}
                  defaultValue={infoPrice.district}
                />
              </div>
            </div>

            <div className="flex-1 sm:grid sm:grid-cols-4 sm:items-end sm:gap-2">
              <label className="block text-sm font-medium text-gray-700 mb-1 text-left">
                ประเภทการขนส่ง :
              </label>
              <div className="mt-1 sm:col-span-3 sm:mt-0">
                <Input
                  placeholder="ประเภทการขนส่ง"
                  hiddenLabel={true}
                  disabled={true}
                  defaultValue={infoPrice.logistic_type}
                />
              </div>
            </div>

          </div>

          <div className='flex-1'>
            <label className={`flex flex-row text-sm font-medium text-gray-700`}>
              ช่วงราคา <span className="text-red-500">*</span> <p className={`text-sm italic text-danger-600 ml-1 ${!validation?.price_set_name && 'sr-only'}`}>โปรดระบุ</p>
            </label>

            <DataGrid
              dataSource={priceSetList}
              keyExpr="price_set_id"
              allowColumnReordering={true}
              columnAutoWidth={true}
              hoverStateEnabled={true}
              showBorders={true}
              height={320}
            >
              <Scrolling columnRenderingMode="virtual" />
              <Paging defaultPageSize={100} />
              <Column
                dataField="seq"
                caption="ลำดับ"
                width={60}
                alignment="center"
                allowSorting={false}
              />

              <Column
                dataField="price_set_name"
                caption="ชื่อราคา"
                alignment="center"
                allowSorting={false} />

              <Column
                dataField="price_value"
                caption="ราคา"
                alignment="center"
                allowSorting={false} />

              <Column
                dataField="price_set_id"
                caption="เลือกราคา"
                alignment="center"
                allowSorting={false}
                width={80}
                cellRender={(e) => {
                  return <Checkbox
                    checked={e.value === priceSelect?.price_set_id}
                    onChange={() => setPriceSelect(e.data)} />
                }} />
            </DataGrid>
          </div>
        </div>
      </FormLoading>
    </Modal>

    <ModalAlert
      {...optionAlert}
      onOk={() => {
        let { action } = optionAlert
        // if (action.name === "save") saveData()
        setOptionAlert({ ...optionAlert, show: false })
      }}
      onCancel={() => {
        setOptionAlert({ ...optionAlert, show: false })
      }}
    />
  </>
  )
}))

export default ModalPriceSet