import { useState } from 'react';
import { t } from '../../helpers'
import { LockClosedIcon, EyeIcon, EyeOffIcon } from '@heroicons/react/outline'

export declare type IconPosition = 'start' | 'end';
export declare type roundedType = 'rounded' | 'full';
export declare type shapeType = 'circle';
export declare type SizeType = 'sm' | 'md' | 'lg';

type Props = {
    id?: string
    icon?: React.ReactNode;
    label?: string
    disabled?: boolean
    defaultValue?: string
    hiddenIcon?: boolean
    hiddenIconEye?: boolean
    hiddenLabel?: boolean
    maxLength?: number
    required?: boolean
    size?: SizeType
    placeholder?: string
    validate?: { isError: boolean; message: string };
    width?: number | string
    onRef?: (ref: any) => void;
    onChange?: (value: any) => void;
    onKeyDown?: (value: any) => void;
}

export default (props: Props) => {
    let {
        id,
        icon = <LockClosedIcon className="h-5 w-5 text-gray-400" />,
        label,
        disabled = false,
        defaultValue = "",
        hiddenIcon = false,
        hiddenIconEye = false,
        hiddenLabel = false,
        required = false,
        size = "sm",
        maxLength = "",
        placeholder = "",
        validate = { isError: false, message: "" },
        width = "full",
        onRef,
        onChange,
        onKeyDown
    }: any = props

    const [passwordVisible, setPasswordVisible] = useState(false);

    return (
        <div>
            <div className={`flex justify-between ${hiddenLabel && 'sr-only'}`}>
                <label htmlFor="email" className={`flex flex-row text-sm font-medium text-gray-700 dark:text-gray-200`}>
                    {t(label)}<span className={`text-red-500 ${!required && 'sr-only'}`}>*</span>

                    <p className={`text-sm italic  ml-2
                     ${validate.isError ? 'text-danger-600' : 'text-gray-500'}
                     ${!validate.isError && 'sr-only'}`}
                    >
                        {`${validate.isError && validate.message}`}
                    </p>
                </label>
            </div>
            <div className={`${icon && 'relative'} mt-1`}>
                <div className={`pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3 ${!icon || hiddenIcon && 'sr-only'}`}>
                    {icon}
                </div>
                <input
                    id={id}
                    type={`${passwordVisible ? 'text' : 'password'}`}
                    onChange={onChange}
                    onKeyDown={onKeyDown}
                    ref={(ref) => onRef && onRef(ref)}
                    disabled={disabled}
                    maxLength={maxLength}
                    defaultValue={defaultValue}
                    className={`
                        block 
                        w-${width}
                        ${size === "sm" ? 'h-8' : size === "lg" ? 'h-12' : 'h-auto'}
                        rounded-md 
                        ${validate.isError ?
                            'border-danger-400'
                            : 'border-gray-300'}
                        shadow-sm 
                        focus:border-primary-500 
                        focus:ring-primary-500 
                        sm:text-sm
                        ${icon && !hiddenIcon && 'pl-10'}
                        ${disabled && 'bg-gray-50 text-gray-500 cursor-not-allowed'}
                        dark:bg-gray-800 dark:text-gray-200
                    `}
                    placeholder={t(placeholder)}
                />
                <div
                    className={`cursor-pointer absolute inset-y-0 right-0 flex items-center pr-3 ${hiddenIconEye && 'sr-only'}`}
                    onClick={() => setPasswordVisible(!passwordVisible)}
                >
                    {passwordVisible ? <EyeIcon className="h-5 w-5 text-gray-400" /> : <EyeOffIcon className="h-5 w-5 text-gray-400" />}
                </div>
            </div>
        </div>
    )
}