import axios from 'axios'
import { BASE_URL } from '../AppConfig'
import IAuth from '../interfaces/Common/IAuth'

interface ICustomer {
  cust_id?: string
  shipto_id?: string
  username?: string
}

export default {
  getCustomerUser: async function (auth: IAuth, data: ICustomer) {
    try {
      let prmList: any = []
      for (const key in data) {
        if (data[key] !== "") prmList.push(`${key}=${data[key]}`)
      }
      let param = prmList.join('&');
      if (param !== "") param = `?${param}`

      const response = await axios.get(`${BASE_URL}/cust_auth/list${param}`, {
        headers: { action_user_id: auth?.action_user_id, token_id: auth?.token_id }
      })
      // const response = await axios.get(`${BASE_URL}/maintenance/list`)
      return response.data
    } catch (error) {
      throw error
    }
  },

  addCustomerUser: async function (auth: IAuth, data) {
    const response = await axios.post(`${BASE_URL}/cust_auth/add`, {
      cust_id: data.cust_id,
      shipto_id: data.shipto_id,
      username: data.username,
      passwd: data.passwd,
      expire_date: data.expire_date,
      status: data.status
    },
      {
        headers: { action_user_id: auth?.action_user_id, token_id: auth?.token_id }
      })
    return response
  },


  updateCustomerUser: async function (auth: IAuth, data) {
    const response = await axios.put(`${BASE_URL}/cust_auth/update`, {
      cust_id: data.cust_id,
      shipto_id: data.shipto_id,
      username: data.username,
      passwd: data.passwd,
      expire_date: data.expire_date,
      status: data.status,
      cust_user_id: data.id
    },
      {
        headers: { action_user_id: auth?.action_user_id, token_id: auth?.token_id }
      })
    return response
  },

  deleteCustomerUser: async function (auth: IAuth, cust_user_id) {
    try {
      const response = await axios.delete(`${BASE_URL}/cust_auth/delete?cust_user_id=${cust_user_id}`, {
        headers: {
          action_user_id: auth?.action_user_id, token_id: auth?.token_id
        }
      })
      return response.data
    } catch (error) {
      throw error
    }
  },
}