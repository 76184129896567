
import React from "react";
import DataGrid, { Paging, Column, Scrolling, MasterDetail, Selection } from 'devextreme-react/data-grid';

const TableQueue = (props) => {
  let { dataSource } = props // STATE
  let { onSelectionChanged, onEdit, height } = props // ACTION

  return (
    <DataGrid
      dataSource={dataSource}
      keyExpr="id"
      height={height}
      showBorders={true}
      allowColumnReordering={true}
      columnAutoWidth={true}
      hoverStateEnabled={true}
      onSelectionChanged={(e) => onSelectionChanged(e.selectedRowKeys)}
    >
      <Scrolling columnRenderingMode="virtual" />
      <Selection
        mode="multiple"
        selectAllMode="allPages"
        showCheckBoxesMode="always"
      />
      <Paging enabled={false} />
      <Column
        dataField="no"
        caption="ลำดับ"
        width={50}
        alignment="center"
      />
      <Column dataField="qno" caption="เลขที่ใบส่งสินค้า" />
      <Column dataField="po_no" caption="SO No." />
      <Column dataField="gq_no" caption="GQ No." />
      <Column dataField="cust_code" caption="รหัสลูกค้า" />
      <Column dataField="cust_name" caption="ชื่อลูกค้า" />
      <Column dataField="shippoint_name" caption="คลัง/ต้นทาง" />
      <Column dataField="shipto_name" caption="สาขา/ปลายทาง" />
      <Column dataField="job_type_name" caption="ประเภทงาน" />
      <Column dataField="delivery_date" caption="วันที่ส่งสินค้า" />
      <Column dataField="job_date" caption="วันที่รับงาน" />
      <Column dataField="center_name" caption="หน่วยงาน" />
      <Column dataField="shipment_type_name" caption="ประเภทเที่ยว" />
      <Column dataField="status_name" caption="สถานะ" />
      <Column dataField="ref_order_no" caption="Ref Order No" />
      <Column dataField="track_no" caption="Track No" />
      <Column dataField="updated_by" caption="แก้ไขโดย" />
      <Column
        fixed={true}
        caption="แก้ไข"
        type="buttons"
        alignment="center"
        width={50}
        cellRender={(e) => {
          let { status } = e.data
          if (status !== "99") {
            return <i
              className="fa fa-pencil-square-o cursor-pointer"
              onClick={() => {
                onEdit(e.row.data)
              }}
            />
          }
          else {
            return ""
          }
        }}
      />
      <MasterDetail
        enabled={true}
        component={DetailTemplate}
      />
    </DataGrid>
  )
}

const DetailTemplate = (props) => {
  let dataSource = props?.data?.data?.products

  return <React.Fragment>
    <div className="master-detail-caption">
      รายละเอียดสินค้า
    </div>
    <DataGrid
      dataSource={dataSource}
      showBorders={true}
      columnAutoWidth={true}
      width={600}
    >
      <Column dataField="product_code" caption="รหัสสินค้า" width={150} />
      <Column dataField="product_name" caption="ชื่อสินค้า" width={200} />
      <Column dataField="product_qty" caption="จำนวน" width={100} />
      <Column dataField="unit_name" caption="หน่วย" width={100} />

    </DataGrid>
  </React.Fragment>
}

export default TableQueue