
import React, { useState } from "react";
import DataGrid, { Paging, Column, Scrolling, MasterDetail, Selection } from 'devextreme-react/data-grid';
import MapRoute from "./MapRoute";

const TablePlan = (props) => {
  let { dataSource, auth } = props // STATE
  let { onSelectionChanged, onEdit, onEditQueue, height } = props // ACTION

  const [isShowMapRoute, setIsShowMapRoute] = useState({
    show: false,
    route_id: "",
    delivery_date: ""
  })


  const DetailTemplate = (props) => {
    let dataSource = props?.data?.data?.drops

    return <React.Fragment>
      <div className="master-detail-caption">
        ข้อมูลจุดจัดส่ง
      </div>
      <DataGrid
        dataSource={dataSource}
        showBorders={true}
        columnAutoWidth={true}
        width={'100%'}
      >
        <Column
          dataField="no"
          caption="ลำดับ"
          width={50}
          alignment="center"
        />
        <Column dataField="shipto_name" caption="สาขาปลายทาง" width={150} />
        <Column dataField="cust_name" caption="ลูกค้า" width={200} />
        <Column
          dataField="start_time"
          caption="ช่วงเวลา"
          width={150}
          cellRender={(e) => (`${e.data.start_time} - ${e.data.end_time}`)}
        />
        <Column dataField="way" caption="ระยะทาง(กม.)" width={100} />
        <Column dataField="way_time" caption="ระยะเวลา(ชม.)" width={100} />
        <Column dataField="price_value" caption="ค่าขนส่ง(บาท)" width={100} />
        <Column dataField="remark" caption="หมายเหตุ" width={100} />
        <MasterDetail
          enabled={true}
          component={DetailQueueTemplate}
        />
      </DataGrid>
    </React.Fragment>
  }

  const DetailQueueTemplate = (props) => {
    let dataSource = props?.data?.data?.queues
    let delivery_date = props?.data?.data?.delivery_date

    return <React.Fragment>
      <div className="master-detail-caption">
        ข้อมูลใบส่งสินค้า
      </div>
      <DataGrid
        dataSource={dataSource}
        showBorders={true}
        columnAutoWidth={true}
        width={'70%'}
      >
        <Column
          dataField="no"
          caption="ลำดับ"
          width={50}
          alignment="center"
        />
        <Column dataField="qno" caption="เลขที่ใบงาน" />
        <Column dataField="price_value" caption="ค่าขนส่ง(บาท)" width={100} />
        <Column dataField="status_name" caption="สถานะ" width={100} />
        <Column
          fixed={true}
          caption="แก้ไข"
          type="buttons"
          alignment="center"
          width={50}
          cellRender={(e) => {
            let { status } = e.data

            if (status !== "99") {
              return <i
                className="fa fa-pencil-square-o cursor-pointer"
                onClick={() => {
                  let data = e.row.data
                  data.delivery_date = delivery_date
                  onEditQueue(data)
                }}
              />
            }
            else {
              return ""
            }
          }}
        />
        <MasterDetail
          enabled={true}
          component={DetailProducTemplate}
        />
      </DataGrid>
    </React.Fragment>
  }

  const DetailProducTemplate = (props) => {
    let dataSource = props?.data?.data?.items

    return <React.Fragment>
      <div className="master-detail-caption">
        รายละเอียดสินค้า
      </div>
      <DataGrid
        dataSource={dataSource}
        showBorders={true}
        columnAutoWidth={true}
      >
        <Column dataField="product_code" caption="รหัสสินค้า" width={150} />
        <Column dataField="product_name" caption="ชื่อสินค้า" />
        <Column dataField="qty" caption="จำนวน" width={100} />
        <Column dataField="unit_name" caption="หน่วย" width={100} />
        <Column dataField="price_unit_value" caption="ราคา/หน่วย" width={100} />
        <Column dataField="price_value" caption="ค่าขนส่ง(บาท)" width={100} />
      </DataGrid>
    </React.Fragment>
  }

  return (<div className="">
    <DataGrid
      dataSource={dataSource}
      keyExpr="route_id"
      height={height}
      showBorders={true}
      columnAutoWidth={true}
      hoverStateEnabled={true}
      onSelectionChanged={(e) => onSelectionChanged(e.selectedRowKeys)}
    >
      <Scrolling columnRenderingMode="virtual" />
      <Selection
        mode="multiple"
        selectAllMode="allPages"
        showCheckBoxesMode="always"
      />
      <Paging enabled={false} />
      <Column
        dataField="no"
        caption="ลำดับ"
        width={50}
        alignment="center"
      />
      <Column dataField="doc_no" caption="เลขที่แผนงาน" />
      <Column dataField="licenseplate" caption="ทะเบียน" />
      <Column dataField="service_name" caption="พนักงาน" />
      <Column dataField="loc_receipt_name" caption="คลัง/สถานที่ต้นทาง" />
      <Column dataField="job_type_name" caption="ประเภทงาน" />
      <Column dataField="total_drop" caption="จำนวนปลายทาง" />
      <Column dataField="total_distance" caption="ระยะทางรวม" />
      <Column dataField="total_time" caption="เวลารวม" />
      <Column dataField="total_weight" caption="น้ำหนักรวม" />
      <Column dataField="total_dimension" caption="ปริมาตร" />
      <Column dataField="price_value" caption="ค่าขนส่ง" />
      <Column dataField="status_name" caption="สถานะ" />
      <Column dataField="updated_by" caption="แก้ไขโดย" />
      <Column
        fixed={true}
        dataField="doc_url"
        caption="เอกสาร"
        alignment="center"
        type="buttons"
        width={60}
        buttons={[
          {
            hint: "เอกสาร",
            icon: "fa fa-file-text-o",
            visible: true,
            onClick: (e) => {
              let value = e.row.data.doc_url
              e.cancel = "true"
              if (value !== "") {
                window.open(value, '_blank')
              }
            }
          }
        ]}
      />
      <Column
        fixed={true}
        dataField="route_id"
        caption="แผนที่"
        alignment="center"
        type="buttons"
        width={50}
        buttons={[
          {
            hint: "แผนที่",
            icon: "fa fa-map-o",
            color: 'red',
            visible: true,
            onClick: (e) => {
              e.cancel = "true"
              setIsShowMapRoute({
                ...isShowMapRoute,
                show: true,
                route_id: e.row.data.route_id,
                delivery_date: e.row.data.delivery_date
              })
            }
          }
        ]} />
      <Column
        fixed={true}
        caption="แก้ไข"
        type="buttons"
        alignment="center"
        width={50}
        buttons={[
          {
            hint: "แก้ไข",
            icon: "fa fa-pencil-square-o",
            visible: true,
            onClick: (e) => {
              e.cancel = "true"
              onEdit(e.row.data)
            }
          }
        ]} />
      <MasterDetail
        enabled={true}
        component={DetailTemplate}
      />
    </DataGrid>

    <MapRoute
      auth={auth}
      data={isShowMapRoute}
      onClosed={() => setIsShowMapRoute({
        ...isShowMapRoute,
        route_id: "",
        delivery_date: "",
        show: false
      })}
    />
  </div>
  )
}

export default TablePlan