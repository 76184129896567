import { useEffect, useState } from 'react'
import { inject, observer } from 'mobx-react';
import { Input, InputNumber, InputRange, Modal, ModalAlert, Select, InputTextarea, DatePicker } from '../../../components'
import { get } from 'lodash'
import { BASE_URL } from '../../../AppConfig'
import axios from 'axios'
import './style.css'

export interface IColumn {
  dataField: string;
  caption: string;
  allowFiltering?: boolean;
}

const FormModal = inject("masterStore", "authenStore")(observer((props) => {
  let masterStore = props.masterStore
  let authenStore = props.authenStore
  let { getAccountAuth } = authenStore // STATE
  let auth = { action_user_id: getAccountAuth?.user_id, token_id: getAccountAuth?.access_token }
  let { getModalConfig, setMasterStore } = masterStore
  let { show, action } = getModalConfig
  let { formData, modalConfig, fieldTypeNumber = [] } = props

  const [data, setData]: any = useState({})
  const [validation, setValidation]: any = useState({})
  const [shakeIsWrong, setShakeIsWrong] = useState(false)
  const [optionAlert, setOptionAlert]: any = useState({
    show: false,
    action: {
      id: "",
      name: ""
    },
    type: "success",
    title: "",
    description: {
      visible: false,
      type: "",
      content: "",
      contentLists: []
    }
  })

  useEffect(() => {
    modalConfig?.elements.forEach(item => {
      if (get(item, 'defaultValue')) {
        if (getModalConfig?.action === "add")
          mappingData(item.fieldName, item.defaultValue)
        else
          mappingData(item.fieldName, "")
      }
    });
  }, [])

  useEffect(() => {
    let defaultData = { ...getModalConfig.dataSelectRow }

    //#region CUSTOM DATA SOME SCREEN 
    if (get(getModalConfig, 'dataSelectRow')) {
      if (modalConfig?.screenName === "vehicle") {
        if (defaultData.driver_ids !== "") {
          let idsSplit = defaultData.driver_ids.split("|")
          let idsLists = idsSplit.map((value) => parseInt(value))
          defaultData.driver_ids = idsLists
        }
      }
    }
    //#endregion 
    if (modalConfig?.screenName === "subcontract" && getModalConfig.action === "add") {
      let { elements } = modalConfig
      defaultData.log_vat = elements[10]?.defaultValue
    }

    setData(defaultData)

  }, [getModalConfig])

  useEffect(() => { }, [data])

  const createFormInput = () => {
    let elmInput: any = [], elmRow: any = []
    const createFlexRow: any = (elm, key) => {
      return <div key={`key-${key}`} className='flex md:flex-row sm:flex-col xs:flex-col md:space-x-4'>
        {elm}
      </div>
    }

    modalConfig.elements.forEach(item => {
      let show = true
      if (get(item, 'showUpdateOnly') && getModalConfig.action === "add") show = false

      if (show) {
        if (item.isOneRow) {
          if (elmRow.length > 0) {
            elmInput.push(createFlexRow(elmRow, item.fieldName))
            elmRow = []
          }
          elmInput.push(createFlexRow(createInputControl(item), item.fieldName))
        }
        else {
          if (elmRow.length < 2) {
            elmRow.push(createInputControl(item))
          }
          else {
            elmInput.push(createFlexRow(elmRow, item.fieldName))
            elmRow = []
            elmRow.push(createInputControl(item))
          }
        }
      }
    })

    if (elmRow.length > 0) elmInput.push(createFlexRow(elmRow))

    return elmInput
  }

  const createInputControl = (item) => {
    let {
      fieldName,
      inputType,
      label = "",
      placeholder = "",
      options = [],
      multiple = false,
      allowClear = false,
      showSearch = true,
      defaultValue = "",
      min,
      max,
      required = false,
      maxLength,
      rows,
      disabled = false
    } = item
    let elmInput: any = null

    switch (inputType) {
      case "text":
        elmInput = <Input
          id={fieldName}
          label={label}
          placeholder={placeholder}
          required={required}
          validate={validation[fieldName]}
          defaultValue={data[fieldName]}
          maxLength={maxLength}
          disabled={disabled}
          onChange={(e) => mappingData(fieldName, e.target.value)}
        />
        break;
      case "number":
        elmInput = <InputNumber
          id={fieldName}
          label={label}
          placeholder={placeholder}
          min={min}
          max={max}
          defaultValue={data[fieldName]}
          required={required}
          validate={validation[fieldName]}
          onChange={(value) => mappingData(fieldName, value)}
        />
        break;
      case "range":
        elmInput = <InputRange
          id={fieldName}
          label={label}
          placeholder={placeholder}
          required={required}
          validate={validation[fieldName]}
          valueStart={""}
          valueEnd={""}
          maxLength={maxLength}
          disabled={disabled}
          onChange={(value, field) => {
            // mappingData(fieldName, e.target.value)
          }}
        />
        break;
      case "text-area":
        elmInput = <InputTextarea
          id={fieldName}
          label={label}
          placeholder={placeholder}
          required={required}
          validate={validation[fieldName]}
          defaultValue={data[fieldName]}
          maxLength={maxLength}
          rows={rows}
          disabled={disabled}
          onChange={(e) => mappingData(fieldName, e.target.value)}
        />
        break;
      case "select":
        elmInput = <Select
          showSearch={showSearch}
          allowClear={allowClear}
          multiple={multiple}
          label={label}
          required={required}
          validate={validation[fieldName]}
          placeholder={placeholder}
          onChange={(value) => {
            if (value) {
              mappingData(fieldName, value)
            }
            else {
              mappingData(fieldName, '')
            }
          }}
          defaultValue={data[fieldName] || defaultValue}
          options={options}
        />
        break;
      case "date":
        elmInput = <DatePicker
          allowClear={allowClear}
          placeholder={placeholder}
          label={label}
          required={required}
          validate={validation[fieldName]}
          defaultValue={data[fieldName]}
          onChange={(d) => {
            if (d)
              mappingData(fieldName, d.format("YYYY-MM-DD"))
            else
              mappingData(fieldName, "")
          }}
        />
        break;
    }
    return <div className='flex-1'>{elmInput}</div>
  }

  const mappingData = (fieldName, value) => {
    if (modalConfig?.screenName === "product") {
      if (["dim_w", "dim_d", "dim_h"].includes(fieldName)) {
        let dimension = 0
        let dim_w = fieldName === "dim_w" ? value : get(data, 'dim_w', 0)
        let dim_d = fieldName === "dim_d" ? value : get(data, 'dim_d', 0)
        let dim_h = fieldName === "dim_h" ? value : get(data, 'dim_h', 0)

        dimension = (dim_w * 0.01) * (dim_d * 0.01) * (dim_h * 0.01)
        setData({ ...data, [fieldName]: value, dimension })
      }
      else {
        setData({ ...data, [fieldName]: value })
      }
    }
    else {
      setData({ ...data, [fieldName]: value })
    }
  }

  const onCheckRequired = () => {
    let isValid = true
    if (modalConfig.required.length > 0) {
      let validateCheck = {}
      modalConfig.required.forEach(item => {
        if (get(data, [item], "") === "") {
          isValid = false
          validateCheck[item] = { isError: true, message: "โปรดระบุ" }
        }
      })
      setValidation(validateCheck)
    }

    return isValid
  }


  //#region SERVICES

  const createData = async () => {
    fieldTypeNumber.forEach(field => {
      body[field] = parseInt(body[field])
    })

    let body: any = {}
    Object.entries(formData).forEach((entry) => {
      const [key, value] = entry;
      body[key] = get(data, [key], value)
    });

    //#region CUSTOM DATA SOME SCREEN 
    if (modalConfig?.screenName === "vehicle") {
      if (body.driver_ids.length > 0) {
        let idsConcate = body.driver_ids.join('|');
        body.driver_ids = idsConcate
      }
    }
    if (modalConfig?.screenName === "product") {
      if (data?.supplier_code !== "") {
        let supplier = modalConfig?.masterData?.supplier.find((item) => item?.value === data?.supplier_code)
        body.supplier_name = supplier?.label || ""
      }
    }
    //#endregion

    await axios.post(`${BASE_URL}/${modalConfig?.apiPath['post']}`,
      body,
      {
        headers: auth
      }
    )
      .then((response) => {
        if (response?.status === 200 && response?.data.code === 200) {
          setOptionAlert({
            ...optionAlert,
            type: "success",
            show: true,
            action: {
              id: "",
              name: ""
            },
            title: "บันทึกข้อมูลสำเร็จ",
            description: { visible: false, type: "text", content: " ", contentLists: [] }
          })

          onCancel(true)
        }
        else {
          setOptionAlert({
            ...optionAlert,
            type: "danger",
            show: true,
            action: {
              id: "",
              name: ""
            },
            title: "ผิดพลาด",
            description: { visible: true, type: "text", content: `${response?.data?.message}` }
          })
        }
      })
      .catch((error) => {
        setOptionAlert({
          ...optionAlert,
          type: "danger",
          show: true,
          action: {
            id: "",
            name: ""
          },
          title: "ผิดพลาด",
          description: { visible: true, type: "text", content: `${error}` }
        })
      })
  }

  const updateData = async () => {
    let body: any = {}

    Object.entries(formData).forEach((entry) => {
      const [key, value] = entry;
      body[key] = get(data, [key], value)
    });
    body[modalConfig.paramName] = data?.id

    //#region CUSTOM DATA SOME SCREEN 
    if (modalConfig?.screenName === "vehicle") {
      if (body.driver_ids.length > 0) {
        let idsConcate = body.driver_ids.join('|');
        body.driver_ids = idsConcate
      }
      else {
        body.driver_ids = ""
      }
    }

    if (modalConfig?.screenName === "maintenance") {
      body.vehicle_id = getModalConfig.dataSelectRow.vehicle_id
    }

    if (modalConfig?.screenName === "rating") {
      body.rate_id = data?.rate_id
    }

    if (modalConfig?.screenName === "item_reason") {
      body.item_reason_id = data?.item_reason_id
    }

    if (modalConfig?.screenName === "product") {
      if (data?.supplier_code !== "") {
        let supplier = modalConfig?.masterData?.supplier.find((item) => item?.value === data?.supplier_code)
        body.supplier_name = supplier?.label || ""
      }
    }
    //#endregion

    await axios.put(`${BASE_URL}/${modalConfig?.apiPath['put']}`, body,
      {
        headers: auth
      }
    )
      .then((response) => {
        if (response?.status === 200 && response?.data.code === 200) {
          setOptionAlert({
            ...optionAlert,
            type: "success",
            show: true,
            action: {
              id: "",
              name: ""
            },
            title: "แก้ไขข้อมูลสำเร็จ",
            description: { visible: false, type: "text", content: " ", contentLists: [] }
          })
          onCancel(true)
        }
        else {
          setOptionAlert({
            ...optionAlert,
            type: "danger",
            show: true,
            action: {
              id: "",
              name: ""
            },
            title: "แก้ไขข้อมูลไม่สำเร็จ",
            description: { visible: true, type: "text", content: `${response?.data?.message}` }
          })
        }
      })
      .catch((error) => {
        setOptionAlert({
          ...optionAlert,
          type: "danger",
          show: true,
          action: {
            id: "",
            name: ""
          },
          title: "แก้ไขข้อมูลไม่สำเร็จ",
          description: { visible: true, type: "text", content: `${error}` }
        })
      })
  }
  //#endregion


  const onCancel = (isRefreshData) => {
    setValidation({})
    setMasterStore({
      isRefreshData,
      modalConfig: {
        show: false,
        action,
        id: ""
      }
    })
  }

  return <>
    <ModalAlert
      {...optionAlert}
      onOk={async () => {
        let { action } = optionAlert
        if (["insert", "update"].includes(action.name)) {
          if (optionAlert.action.name === "insert")
            createData()
          else
            updateData()
        }

        setOptionAlert({ ...optionAlert, show: false })
      }}
      onCancel={() => {
        setOptionAlert({ ...optionAlert, show: false })
      }}
    />
    <Modal
      title={{ text: `${action === "add" ? 'เพิ่ม' : 'แก้ไข'}${modalConfig.title}` }}
      open={show}
      maskClosable={true}
      shake={shakeIsWrong}
      width={modalConfig?.width}
      onClosed={() => onCancel(false)}
      onOk={async () => {
        let validateIsPass = await onCheckRequired()

        if (validateIsPass) {
          setOptionAlert({
            ...optionAlert,
            action: {
              id: 0,
              name: getModalConfig.action === "add" ? 'insert' : 'update'
            },
            type: "confirm",
            title: "ยืนยัน",
            show: true,
            description: {
              visible: true,
              type: "text",
              content: "คุณแน่ใจที่จะบันทึกข้อมูล ?",
              contentLists: []
            }
          })
        }
        else {
          setShakeIsWrong(true)
          setTimeout(() => setShakeIsWrong(false), 100);
        }
      }}
    >
      <div className='md:space-y-2'>
        {createFormInput()}
      </div>
    </Modal>
  </>
}))

export default FormModal