import axios from 'axios'
import { BASE_URL } from '../AppConfig'
import IAuth from '../interfaces/Common/IAuth'

export default {

  //#region DOCUMENT
  updateDocSet: async function (auth: IAuth, data) {
    const response = await axios.post(`${BASE_URL}/doc_set/update`, {
      func_id: data.func_id,
      doc_list: data.doc_list
    },
      {
        headers: { action_user_id: auth?.action_user_id, token_id: auth?.token_id }
      })
    return response
  },
  //#endregion

  //#region MANAGE DOCUMENT
  getDocs: async function (auth: IAuth) {
    const response = await axios.get(`${BASE_URL}/doc/list`,
      {
        headers: { action_user_id: auth?.action_user_id, token_id: auth?.token_id }
      })
    return response
  },

  addDoc: async function (auth: IAuth, data) {
    const response = await axios.post(`${BASE_URL}/doc/add`, data,
      {
        headers: { action_user_id: auth?.action_user_id, token_id: auth?.token_id }
      })
    return response
  },


  updateDoc: async function (auth: IAuth, data) {
    const response = await axios.put(`${BASE_URL}/doc/update`, data,
      {
        headers: { action_user_id: auth?.action_user_id, token_id: auth?.token_id }
      })
    return response
  },

  deleteDoc: async function (auth: IAuth, doc_id) {
    try {
      const response = await axios.delete(`${BASE_URL}/doc/delete?doc_id=${doc_id}`, {
        headers: {
          action_user_id: auth?.action_user_id, token_id: auth?.token_id
        }
      })
      return response.data
    } catch (error) {
      throw error
    }
  },
  //#endregion

}