import { useEffect, useState } from 'react'
import { inject, observer } from 'mobx-react';
import FormMaster from '../FormMaster'
import { CommonService } from "../../../services";
import { isEmpty } from 'lodash';

let firstRender = false
const Product = inject("authenStore")(observer((props) => {
  let authenStore = props.authenStore
  let { getAccountAuth } = authenStore // STATE
  let auth = { action_user_id: getAccountAuth?.user_id, token_id: getAccountAuth?.access_token }

  const [masterOptions, setMasterOptions] = useState({
    common_status: [],
    product_unit: [],
    product_type: [],
    product_group: [],
    vehicle_status: [],
    supplier: [],
    customer: []
  })

  useEffect(() => {
    if (firstRender || !isEmpty(getAccountAuth)) {
      CommonService.getMasterData(auth, "common_status,product_unit,product_type,product_group,supplier,customer")
        .then((res: any) => {
          let common_status = res.result.common_status.map((item) => ({ label: item.status_name, value: item.status_code }))
          let product_unit = res.result.product_unit.map((item) => ({ label: item.unit_name, value: item.unit_id }))
          let product_type = res.result.product_type.map((item) => ({ label: item.product_type_name, value: item.product_type_id }))
          let product_group = res.result?.product_group.map((item) => ({ label: item.group_name, value: item.group_id }))
          let supplier = res.result?.supplier.map((item) => ({ label: item.supplier_name, value: item.supplier_code }))
          let customer = res.result?.customer.map((item) => ({ label: item.cust_name, value: item.cust_id }))

          setMasterOptions({
            ...masterOptions,
            common_status,
            product_unit,
            product_type,
            product_group,
            supplier,
            customer
          })
        })
        .catch((err) => {
          console.log(`Load master data : ${err}`)
        })
    }
    else
      firstRender = true
  }, [getAccountAuth])

  return <FormMaster
    title="ข้อมูลสินค้า"
    tableConfig={{
      key: "id",
      apiPath: "product/list",
      allowDelete: {
        visible: true,
        key: "product_id",
        apiPath: "product/delete",
        paramType: "param"
      },
      optionDeleteFag: true,
      defaultColumn: [],
      columns: [
        {
          dataField: 'no',
          caption: "ลำดับ",
          width: 60,
          alignment: "center",
          allowFiltering: false
        },
        {
          dataField: 'product_code',
          caption: "รหัสสินค้า",
          allowFiltering: true
        },
        {
          dataField: 'product_name',
          caption: "ชื่อสินค้า",
          allowFiltering: true
        },
        {
          dataField: 'material_code',
          caption: "รหัสวัสดุ",
          allowFiltering: true
        },
        {
          dataField: 'type_name',
          caption: "ประเภทสินค้า",
          allowFiltering: true
        },
        {
          dataField: 'group_name',
          caption: "กลุ่มสินค้า",
          allowFiltering: true
        },
        {
          dataField: 'unit_name',
          caption: "หน่วยสินค้า",
          allowFiltering: true
        },
        {
          dataField: 'dim_w',
          caption: "กว้าง (ซม.)"
        },
        {
          dataField: 'dim_d',
          caption: "ยาว (ซม.)"
        },
        {
          dataField: 'dim_h',
          caption: "สูง (ซม.)"
        },
        {
          dataField: 'weight',
          caption: "น้ำหนัก (กก.)"
        },
        {
          dataField: 'dimension',
          caption: "ปริมาตร (ลบ.ม.)"
        },
        {
          dataField: 'cust_code',
          caption: "รหัสลูกค้า"
        },
        {
          dataField: 'cust_name',
          caption: "ชื่อลูกค้า"
        },
        {
          dataField: 'status_name',
          caption: "สถานะ",
          allowFiltering: true
        },
        {
          dataField: 'updated_by',
          caption: "แก้ไขโดย"
        }
      ],
      exportExcel: {
        fileName: 'ข้อมูลสินค้า',
        columnConfig: [
          { label: 'ลำดับ', value: 'no' },
          { label: 'รหัสสินค้า', value: 'product_code' },
          { label: 'ชื่อสินค้า', value: 'product_name' },
          { label: 'รหัสวัสดุ', value: 'material_code' },
          { label: 'ประเภทสินค้า', value: 'type_name' },
          { label: 'กลุ่มสินค้า', value: 'group_name' },
          { label: 'หน่วยสินค้า', value: 'unit_name' },
          { label: 'กว้าง (ซม.)', value: 'dim_w' },
          { label: 'ยาว (ซม.)', value: 'dim_d' },
          { label: 'สูง (ซม.', value: 'dim_h' },
          { label: 'น้ำหนัก (กก.)', value: 'weight' },
          { label: 'ปริมาตร (ลบ.ม.)', value: 'dimension' },
          { label: 'รหัสลูกค้า', value: 'cust_code' },
          { label: 'ชื่อลูกค้า', value: 'cust_name' },
          { label: 'สถานะ', value: 'status_name' },
          { label: 'แก้ไขโดย', value: 'updated_by' }
        ]
      }
    }}
    formData={{
      group_id: "",
      type_id: "",
      product_code: "",
      material_code: "",
      description: "",
      product_name: "",
      dim_h: "",
      dim_w: "",
      dim_d: "",
      weight: "",
      unit_id: "",
      dimension: "",
      supplier_code: "",
      supplier_name: "",
      cust_id: "",
      status: "Y"
    }}
    modalConfig={{
      screenName: "product",
      visible: true,
      title: "สินค้า",
      required: ["product_code", "product_name", "type_id", "group_id", "unit_id"],
      fieldTypeNumber: [],
      paramName: "product_id",
      masterData: { supplier: masterOptions.supplier },
      apiPath: {
        "get": '',
        "post": 'product/add',
        "put": 'product/update'
      },
      elements: [
        {
          fieldName: "product_code",
          label: "รหัสสินค้า",
          placeholder: "รหัสสินค้า",
          required: true,
          inputType: "text"
        },
        {
          fieldName: "material_code",
          label: "รหัสพัสดุ",
          placeholder: "รหัสพัสดุ",
          required: false,
          inputType: "text"
        },
        {
          fieldName: "product_name",
          label: "ชื่อสินค้า",
          placeholder: "ชื่อสินค้า",
          required: true,
          inputType: "text"
        },
        {
          fieldName: "type_id",
          label: "ประเภทสินค้า",
          placeholder: "ประเภทสินค้า",
          inputType: "select",
          required: true,
          options: masterOptions.product_type
        },
        {
          fieldName: "group_id",
          label: "กลุ่มสินค้า",
          placeholder: "กลุ่มสินค้า",
          inputType: "select",
          required: true,
          options: masterOptions.product_group
        },
        {
          fieldName: "unit_id",
          label: "หน่วยสินค้า",
          placeholder: "หน่วยสินค้า",
          inputType: "select",
          required: true,
          options: masterOptions.product_unit
        },
        {
          fieldName: "dim_w",
          label: "กว้าง (เซนติเมตร)",
          placeholder: "กว้าง (เซนติเมตร)",
          inputType: "number"
        },
        {
          fieldName: "dim_d",
          label: "ยาว (เซนติเมตร)",
          placeholder: "ยาว (เซนติเมตร)",
          inputType: "number"
        },
        {
          fieldName: "dim_h",
          label: "สูง (เซนติเมตร)",
          placeholder: "สูง (เซนติเมตร)",
          inputType: "number"
        },
        {
          fieldName: "weight",
          label: "น้ำหนัก (กิโลกรัม)",
          placeholder: "น้ำหนัก (กิโลกรัม)",
          inputType: "number"
        },
        {
          fieldName: "dimension",
          label: "ปริมาตร (ลูกบาศก์เมตร)",
          placeholder: "ปริมาตร (ลูกบาศก์เมตร)",
          inputType: "number"
        },
        {
          showSearch: false,
          fieldName: "status",
          label: "สถานะ",
          inputType: "select",
          defaultValue: "Y",
          options: masterOptions.common_status
        },
        {
          fieldName: "supplier_code",
          allowClear: true,
          label: "Supplier",
          placeholder: "Supplier",
          inputType: "select",
          options: masterOptions.supplier
        },
        {
          fieldName: "cust_id",
          allowClear: true,
          label: "ลูกค้า",
          placeholder: "ลูกค้า",
          inputType: "select",
          options: masterOptions.customer
        }
      ]
    }}
  />
}))

export default Product