import { action, configure, observable, toJS, computed } from 'mobx';
import { persist } from 'mobx-persist'

configure({ enforceActions: 'always' });

export default class CommonStore {
  @persist('object') @observable isRefreshData: boolean = false
  @persist('object') @observable openCause: boolean = false
  @persist('object') @observable openReason: boolean = false
  @persist('object') @observable dataCauseEdit: any = {}
  @persist('object') @observable dataReasonEdit: any = {}

  @computed
  get getIsRefreshData() {
    return toJS(this.isRefreshData)
  }

  @computed
  get getOpenCause() {
    return toJS(this.openCause)
  }

  @computed
  get getOpenReason() {
    return toJS(this.openReason)
  }

  @computed
  get getDataCauseEdit() {
    return toJS(this.dataCauseEdit)
  }

  @computed
  get getDataReasonEdit() {
    return toJS(this.dataReasonEdit)
  }

  @action.bound
  setCauseStore(objValue: any) {
    for (const [key, value] of Object.entries(objValue)) {
      this[key] = value
    }
  }

  @action
  reset() {
    this.isRefreshData = false
    this.dataCauseEdit = ""
    this.dataReasonEdit = ""
  }
}