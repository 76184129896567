
import { useState, useEffect } from "react";
import './style.css'
import { inject, observer } from 'mobx-react';
import { RouteOnMap, MultiRoute } from '../../components'
import { MarkerScale } from '../../helpers/markerScale';

let markers: any = [], markerShippoint: any = [], infowindow: any = []
const Map = inject("routeStore", "routeAutoStore", "authenStore")(observer((props) => {
  let { authenStore, routeAutoStore, showGeoserver } = props
  let { getQueueData, getRouteDrop, getFocusDrop, getShippointDrop, getFocusDropRoute } = routeAutoStore // ACTIONS
  let { getAccountAuth } = authenStore // STATE
  let auth = { action_user_id: getAccountAuth?.user_id, token_id: getAccountAuth?.access_token }

  const [map, setMap]: any = useState(null)

  useEffect(() => {
    if (getQueueData.length > 0) {
      markers.forEach(item => item.marker.setMap(null))
      infowindow.length = 0
      getQueueData.forEach(data => createMarker(data));
    }
    else {
      markers.forEach(item => item.marker.setMap(null))
    }
  }, [getQueueData])

  useEffect(() => {
    if (getShippointDrop.length > 0) {
      markerShippoint.forEach(item => item.setMap(null))

      getShippointDrop.forEach(data => {
        createMarkerShippoint(data)
      });
    }
    else {
      markerShippoint.forEach(item => item.setMap(null))
    }
  }, [getShippointDrop])

  useEffect(() => { }, [getRouteDrop])

  useEffect(() => {
    let { id, lat, lon } = getFocusDrop

    if (lat !== "") {
      fitBound([{ lat, lon }])
      if (infowindow) {
        let foundInfo = infowindow.find((info) => info.id === id)
        if (foundInfo) {
          let foundMarker = markers.find((marker) => marker.id === id)
          foundInfo.info.open({
            anchor: foundMarker?.marker,
            map
          });
        }
      }
    }
  }, [getFocusDrop])

  useEffect(() => {
    let { lat, lon } = getFocusDropRoute
    if (lat !== "") { fitBound([{ lat, lon }]) }
  }, [getFocusDropRoute])

  const fitBound = (data) => {
    if (map) {
      let bounds: any = new window.google.maps.LatLngBounds();
      data.forEach(queue => {
        bounds.extend({ lat: queue?.lat, lng: queue?.lon })
      });
      map.fitBounds(bounds)
    }
  }

  const createMarker = (data) => {
    try {
      if (map) {
        const marker: any = new window.google.maps.Marker({
          position: { lat: data?.lat, lng: data?.lon },
          map,
          icon: MarkerScale(data?.icon_url)
        })
        markers.push({
          id: data?.id,
          marker: marker
        })


        const contentString = `
        สาขา/ปลาย : <b>${data?.shipto_name}</b> </br>
        ชื่อลูกค้า : <b>${data?.cust_name}</b> </br>
        เบอร์โทร : <b>${data?.ship_tel}</b> </br>
        ปริมาตรสินค้า : <b>${data?.total_weight}</b> </br>
        น้ำหนักรวม : <b>${data?.total_dimension}</b> </br>
        โซน : <b>${data?.zone_name}</b> 
        `

        const _infowindow: any = new google.maps.InfoWindow({
          content: contentString
        });

        infowindow.push({
          id: data?.id,
          info: _infowindow
        })

        marker.addListener("click", () => {
          _infowindow.open({
            anchor: marker,
            map
          });
        });
      }
    } catch (error) {
      console.log("createMarker : ", error)
    }
  }

  const createMarkerShippoint = (data) => {
    try {
      if (map) {
        const marker: any = new window.google.maps.Marker({
          position: { lat: data?.lat, lng: data?.lon },
          map,
          icon: MarkerScale('/Images/ic-warehouse.png')
        })
        markerShippoint.push(marker)

        const contentString = `
        คลัง/จุดเริ่มต้น : <b>${data?.shippoint_name}</b> </br>
        เวลาเปิด : <b>${data?.dtopen}</b> </br>
        เวลาปิด : <b>${data?.dtclose}</b> </br>
        ผู้ติดต่อ : <b>${data?.contact_name}</b> </br>
        เบอร์โทร : <b>${data?.tel}</b> 
        `
        const infowindow: any = new google.maps.InfoWindow({
          content: contentString
        });

        marker.addListener("click", () => {
          infowindow.open({
            anchor: marker,
            map
          });
        });
      }
    } catch (error) {
      console.log("createMarkerShippoint : ", error)
    }
  }
  return (<MultiRoute
    height={400}
    show={true}
    // showHistoryLine={true}
    isAuto="Y"
    isFocusMarker={getFocusDrop.lat !== "" ? true : false}
    mapLoaded={(map) => setMap(map)}
    deliveryDate={getRouteDrop?.delivery_date}
    routeId={getRouteDrop?.route_id}
    multiRoutes={getRouteDrop?.multiRoutes}
    auth={auth}
    showGeoserver={showGeoserver}
  />)
}))

export default Map