
import { useState } from "react";
import { Button, RouteOnMap } from '../../components'
import { AnnotationIcon, CameraIcon, PencilAltIcon } from "@heroicons/react/solid";
import DataGrid, { Column } from 'devextreme-react/data-grid';
import './style.css'

const JobTracking = (props) => {
  let { onOpenModal, onChangeView, onJobSelect, showMap, jobDetail, trackingAll, auth } = props
  const [jobSelect, setJobSelect]: any = useState(null)
  let columns = [
    {
      dataField: 'seq',
      caption: "จุดจัดส่ง",
      width: 70,
      alignment: "center"
    },
    {
      dataField: 'shipto_name',
      caption: "สาขา/ปลายทาง",
      alignment: "center"
    },
    {
      dataField: 'time',
      caption: "เวลา",
      alignment: "center",
      cellRender: (e) => {
        let data = e.data
        return `${data?.start_time}-${data?.end_time}`
      }
    },
    {
      dataField: 'ts_check_in',
      caption: "เวลาเริ่มงาน",
      alignment: "center"
    },
    {
      dataField: 'ts_close',
      caption: "เวลาปิดงาน",
      alignment: "center"
    },
    {
      dataField: 'mileage_in',
      caption: "เลขไมล์",
      alignment: "center"
    }
  ]

  const setJobInfo = (data) => {
    let info: any = { ...data }, newTrack: any = []
    let no = 1
    data?.drops.map((dt) => {
      newTrack.push({
        no,
        shipto_name: dt.shipto_name,
        time: dt.end_time
      })
      no++
    })
    info.track = newTrack

    setJobSelect(info)
  }

  const iconColor = (info, iconType) => {
    let defaultColor = "gray"

    if (iconType === "remark" && info?.remark !== "") {
      defaultColor = "green"
    }
    else if (iconType === "camera") {
      // let image = info?.pics?.find((item) => item?.pic_type === 2)
      let image = info?.pics?.find((item) => ([2, 3, 4, 5].includes(item?.pic_type)))
      if (image) defaultColor = "green"
    }
    else if (iconType === "camera_drop") {
      let image = info?.pics?.find((item) => item?.pic_type === 1)
      if (image) defaultColor = "green"
    }
    else if (iconType === "signature") {
      let image = info?.pics?.find((item) => item?.pic_type === 3)
      if (image) defaultColor = "green"
    }

    return defaultColor
  }

  return <div className="flex flex-row">
    <div className="shrink-0 h-16 w-64 mr-3">
      <div className={`space-y-2 ${showMap && 'overflow-y-scroll'}`} style={{ height: showMap && 'calc(100vh - 340px)' }}>
        {
          showMap ? trackingAll.map((info, index) => <button
            type="button"
            className={`group h-20 flex w-full items-center justify-between space-x-3 rounded-lg border border-gray-400 p-2 text-left ${info.no === jobSelect?.no ? 'bg-success-100' : 'bg-gray-100 hover:bg-gray-50'}  shadow-sm`}
            onClick={() => {
              onChangeView(true)
              setJobInfo(info)
            }}
          >
            <span className="flex min-w-0 flex-1 items-center space-x-3">
              <span className="block min-w-0 flex-1 ">
                <span className="flex flex-row truncate text-xs"><span className="mr-5 font-semibold">ทะเบียน</span> : {info?.licenseplate}</span>
                <span className="flex flex-row truncate text-xs"><span className="mr-3.5 font-semibold">พนักงาน</span> : {info?.service_name}</span>
                <span className="flex flex-row truncate text-xs"><span className="mr-2 font-semibold">เวลา GPS</span> : {info?.gps?.gpsdate}</span>
                <span className="flex flex-row truncate text-xs"><span className="mr-3.5 font-semibold">เบอร์โทร</span> : {info?.service_tel}</span>
              </span>
            </span>
            <span className="flex flex-col space-y-1 h-10 w-10 flex-shrink-0 items-center justify-start">
              <span className="text-xs text-primary-600">{info?.gps?.temp}°C</span>
              <img
                src="/Images/google_maps.png"
                className="image-zoom w-5 h-4 mt-0.5 cursor-pointer hover:z"
                onClick={() => {
                  onChangeView(true)
                  setJobInfo(info)
                }}
              />
            </span>
          </button>)
            : <button
              type="button"
              className={`group h-20 flex w-full items-center justify-between space-x-3 rounded-lg border border-gray-400 p-2 text-left ${jobDetail.no === jobSelect?.no ? 'bg-success-100' : 'bg-gray-100 hover:bg-gray-50'}  shadow-sm`}
              onClick={() => {
                onChangeView(true)
                setJobInfo(jobDetail)
              }}
            >
              <span className="flex min-w-0 flex-1 items-center space-x-3">
                <span className="block min-w-0 flex-1 ">
                  <span className="flex flex-row truncate text-xs "><span className="mr-5 font-semibold">ทะเบียน</span> : {jobDetail?.licenseplate}</span>
                  <span className="flex flex-row truncate text-xs"><span className="mr-3.5 font-semibold">พนักงาน</span> : {jobDetail?.service_name}</span>
                  <span className="flex flex-row truncate text-xs"><span className="mr-2 font-semibold">เวลา GPS</span> : {jobDetail?.gps?.gpsdate}</span>
                  <span className="flex flex-row truncate text-xs"><span className="mr-3.5 font-semibold">เบอร์โทร</span> : {jobDetail?.service_tel}</span>
                </span>
              </span>
              <span className="flex flex-col space-y-1 h-10 w-10 flex-shrink-0 items-center justify-start">
                <span className="text-xs text-primary-600">{jobDetail?.gps?.temp}°C</span>
                <img
                  src="/Images/google_maps.png"
                  className="image-zoom w-5 h-4 mt-0.5 cursor-pointer hover:z"
                  onClick={() => {
                    onChangeView(true)
                    setJobInfo(jobDetail)
                  }}
                />
              </span>
            </button>
        }
      </div>
    </div>
    {
      showMap &&
      <div className={`flex-1 w-96 overflow-auto border-l border-dashed`}>
        {/* <div className={`flex-1 w-96 overflow-auto border-l border-dashed ${!showMap && 'sr-only'}`}> */}
        <div className="flex flex-row ml-2">
          <div className="shrink-0 h-16 w-2/5">
            <div className="flex flex-col">
              <div className="flex-row mb-3">
                <Button
                  text="แสดงแบบใบส่งสินค้า"
                  type="white"
                  icon={<div className="w-5 h-4  divide-y divide-gray-400 rounded-t-md border border-gray-400 bg-gray-100">
                    <div className="px-4 py-0.5 text-center" />
                    <div className="borderp-2" />
                  </div>}
                  onClick={() => onChangeView(false)}
                />
              </div>
              <div className="flex-row">
                <div className="grid grid-cols-3 sm:items-start ">
                  <span className="block text-sm font-semibold leading-2">
                    เลขที่แผนงาน
                  </span>
                  <span className="flex-1 flex-row truncate text-sm col-span-2">{jobSelect?.doc_no}</span>
                </div>
              </div>

              <div className="flex-row">
                <div className="grid grid-cols-3 sm:items-start ">
                  <span className="block text-sm font-semibold leading-2">
                    ทะเบียน
                  </span>
                  <span className="flex-1 flex-row truncate text-sm col-span-2">{jobSelect?.licenseplate}</span>
                </div>
              </div>

              <div className="flex-row">
                <div className="grid grid-cols-3 sm:items-start ">
                  <span className="block text-sm font-semibold leading-2">
                    พนักงาน
                  </span>
                  <span className="flex-1 flex-row truncate text-sm col-span-2">{jobSelect?.service_name}</span>
                </div>
              </div>

              <div className="flex-row">
                <div className="grid grid-cols-3 sm:items-start ">
                  <span className="block text-sm font-semibold leading-2">
                    ระยะทาง
                  </span>
                  <span className="flex-1 flex-row truncate text-sm col-span-2">{jobSelect?.total_distance}</span>
                </div>
              </div>

              <div className="flex-row">
                <div className="grid grid-cols-3 sm:items-start ">
                  <span className="block text-sm font-semibold leading-2">
                    เวลารวม
                  </span>
                  <span className="flex-1 flex-row truncate text-sm col-span-2">{jobSelect?.total_time}</span>
                </div>
              </div>

              <div className="flex-row">
                <div className="grid grid-cols-3 sm:items-start ">
                  <span className="block text-sm font-semibold leading-2">
                    วันที่จัดส่ง
                  </span>
                  <span className="flex-1 flex-row truncate text-sm col-span-2">{jobSelect?.delivery_date}</span>
                </div>
              </div>

              <div className="flex-row">
                <div className="grid grid-cols-3 sm:items-start ">
                  <span className="block text-sm font-semibold leading-2">
                    เลขไมล์เข้ารับงาน
                  </span>
                  <span className="flex-1 flex-row truncate text-sm col-span-2">{jobSelect?.mileage_start}</span>
                </div>
              </div>

              <div className="flex-row">
                <div className="grid grid-cols-3 sm:items-start ">
                  <span className="block text-sm font-semibold leading-2">
                    เลขไมล์ออกงาน
                  </span>
                  <span className="flex-1 flex-row truncate text-sm col-span-2">{jobSelect?.mileage_end}</span>
                </div>
              </div>

              <div className="flex-row">
                <div className="grid grid-cols-3 sm:items-start ">
                  <span className="block text-sm font-semibold leading-2">
                    เวลาเข้างาน
                  </span>
                  <span className="flex-1 flex-row truncate text-sm col-span-2">{jobSelect?.ts_gate_in}</span>
                </div>
              </div>

              <div className="flex-row">
                <div className="grid grid-cols-3 sm:items-start ">
                  <span className="block text-sm font-semibold leading-2">
                    เวลาออกงาน
                  </span>
                  <span className="flex-1 flex-row truncate text-sm col-span-2">{jobSelect?.ts_gate_out}</span>
                </div>
              </div>

              <div className="flex-row mt-2 pr-2">
                <DataGrid
                  dataSource={jobSelect ? jobSelect.drops : []}
                  keyExpr="seq"
                  showBorders={true}
                  columnAutoWidth={true}
                >
                  {
                    columns.map((column) => <Column
                      key={column.dataField}
                      width={column.width}
                      dataField={column.dataField}
                      caption={column.caption}
                      alignment={column.alignment}
                      cellRender={column.cellRender}
                    />)
                  }
                </DataGrid>
              </div>
            </div>
          </div>

          <div className="flex-1">
            <RouteOnMap
              auth={auth}
              height="calc(100vh - 340px)"
              show={showMap}
              showHistoryLine={true}
              dataInfo={jobSelect}
              deliveryDate={jobSelect?.delivery_date}
              routeId={jobSelect?.route_id}
            />
          </div>
        </div>
      </div>
    }
    <div className={`flex-1 w-96 overflow-auto ${showMap && 'sr-only'}`}>
      <div className="overflow-auto">
        <div className="mx-auto bg-white shadow-xl min-w-0">
          <div className="overflow-x-auto flex space-x-2 ">
            {/* <div className={`flex-none  first:pl-6 mb-2 last:pr-6 divide-y divide-gray-400 rounded-t-lg ${cardStatusColor(jobDetail?.status)} border border-gray-400`}> */}
            <div className={`flex-none  first:pl-6 mb-2 last:pr-6 divide-y divide-gray-400 rounded-t-lg border border-gray-400`} style={{ backgroundColor: jobDetail?.color_code }}>
              <div className="px-4 py-1 dark:bg-gray-800 text-center">
                <h3 title={`${jobDetail?.loc_receipt_name}`} className="text-xs font-medium text-gray-900">{jobDetail?.loc_receipt_name}</h3>
              </div>
              <div className="group flex w-48 items-center justify-between space-x-3 borderp-2 text-left py-0.5 px-1">
                <span className="flex min-w-0 flex-1 items-center space-x-3">
                  <span className="block min-w-0 flex-1 ">
                    <span className="flex flex-row truncate text-xs "><span className="mr-2 font-semibold">คาดหมาย</span> : {jobDetail?.eta_time}</span>
                    <span className="flex flex-row truncate text-xs"><span className="mr-6 font-semibold">รับงาน </span> : {jobDetail?.ts_gate_in}</span>
                    <span className="flex flex-row truncate text-xs"><span className="mr-4 font-semibold">ออกงาน </span> : {jobDetail?.ts_gate_out}</span>
                  </span>
                </span>
                <span className="space-y-1 h-10 w-12 flex-shrink-0 grid justify-items-end">
                  <AnnotationIcon
                    className="h-5 w-5 cursor-pointer icon-zoom"
                    style={{ color: iconColor(jobDetail, "remark") }}
                    onClick={() => onOpenModal({
                      title: "หมายเหตุ",
                      name: "note",
                      icon: <AnnotationIcon className="h-5 w-5 mr-1 text-gray-500" />,
                      info: jobDetail,
                      doc_no: ""
                    })}
                  />
                  <div className="flex flex-row space-x-1">
                    <CameraIcon
                      className="h-5 w-5 cursor-pointer icon-zoom"
                      style={{ color: iconColor(jobDetail, "camera_drop") }}
                      onClick={() => onOpenModal({
                        title: "ภาพถ่ายจากการจัดส่งสินค้า",
                        name: "camera-first-drop",
                        icon: <CameraIcon className="h-5 w-5 mr-1 text-gray-500" />,
                        info: jobDetail,
                        doc_no: jobDetail?.doc_no
                      })}
                    />
                  </div>
                </span>
              </div>
            </div>

            {
              jobDetail?.drops.map((info) => {
                return <div className={`flex-none  first:pl-6 mb-2 last:pr-6 divide-y divide-gray-400 rounded-t-lg border border-gray-400`} style={{ backgroundColor: info?.color_code }}>
                  <div className="px-4 py-1 dark:bg-gray-800 text-center truncate">
                    <h3 title={`${info?.shipto_name} ${info?.start_time}-${info?.end_time}`} className="text-xs font-medium text-gray-900 truncate w-36">{info?.shipto_name} {`${info?.start_time}-${info?.end_time}`}</h3>
                  </div>
                  <div className="group flex w-48 items-center justify-between space-x-3 borderp-2 text-left py-0.5 px-1">
                    <span className="flex min-w-0 flex-1 items-center space-x-3">
                      <span className="block min-w-0 flex-1 ">
                        <span className="flex flex-row truncate text-xs"><span className="mr-2 font-semibold">คาดหมาย</span> : {info?.eta_time}</span>
                        <span className="flex flex-row truncate text-xs"><span className="mr-5 font-semibold">เข้างาน </span> : {info?.ts_check_in}</span>
                        <span className="flex flex-row truncate text-xs"><span className="mr-5.5 font-semibold">ปิดงาน</span> : {info?.ts_close}</span>
                      </span>
                    </span>
                    <span className="space-y-1 h-10 w-12 flex-shrink-0 grid justify-items-end">
                      <AnnotationIcon
                        className={`h-5 w-5 cursor-pointer icon-zoom`}
                        style={{ color: iconColor(info, "remark") }}
                        onClick={() => onOpenModal({
                          title: "หมายเหตุ",
                          name: "note",
                          icon: <AnnotationIcon className="h-5 w-5 mr-1 text-gray-500" />,
                          info,
                          doc_no: ""
                        })}
                      />
                      <div className="flex flex-row space-x-1">
                        <PencilAltIcon
                          className="h-5 w-5 cursor-pointer icon-zoom"
                          style={{ color: iconColor(info, "signature") }}
                          onClick={() => onOpenModal({
                            title: "ลายเซนต์ลูกค้า",
                            name: "signature",
                            icon: <PencilAltIcon className="h-5 w-5 mr-1 text-gray-500" />,
                            info,
                            doc_no: jobDetail?.doc_no
                          })}
                        />
                        <CameraIcon
                          className="h-5 w-5 cursor-pointer icon-zoom"
                          style={{ color: iconColor(info, "camera") }}
                          onClick={() => onOpenModal({
                            title: "ภาพถ่ายจากการจัดส่งสินค้า",
                            name: "camera",
                            icon: <CameraIcon className="h-5 w-5 mr-1 text-gray-500" />,
                            info,
                            doc_no: jobDetail?.doc_no
                          })}
                        />
                      </div>
                    </span>
                  </div>
                </div>
              })
            }
          </div>
        </div>
      </div>
    </div>
  </div>
}

export default JobTracking