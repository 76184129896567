import { Provider as Providers } from 'mobx-react';
import { create } from 'mobx-persist';

// Stores
import AuthenStore from './AuthenStore';
import CommonStore from './CommonStore';
import MasterStore from './MasterStore';
import RouteStore from './RouteStore';
import PictureStore from './PictureStore';
import CauseReasonStore from './CauseReasonStore';
import RouteAutoStore from './RouteAutoStore';
import PriceModelStore from './PriceModelStore';
import CalendarStore from './CalendarStore';

class RootStore {
  public authenStore: AuthenStore
  public commonStore: CommonStore
  public masterStore: MasterStore
  public routeStore: RouteStore
  public pictureStore: PictureStore
  public causeReasonStore: CauseReasonStore
  public routeAutoStore: RouteAutoStore
  public priceModelStore: PriceModelStore
  public calendarStore: CalendarStore

  constructor() {
    const hydrate = create({})
    this.authenStore = new AuthenStore()
    this.commonStore = new CommonStore()
    this.masterStore = new MasterStore()
    this.routeStore = new RouteStore()
    this.pictureStore = new PictureStore()
    this.causeReasonStore = new CauseReasonStore()
    this.routeAutoStore = new RouteAutoStore()
    this.priceModelStore = new PriceModelStore()
    this.calendarStore = new CalendarStore()

    hydrate('commonStore', this.commonStore)
    hydrate('authenStore', this.authenStore)
    hydrate('masterStore', this.masterStore)
    hydrate('masterStore', this.masterStore)
    hydrate('routeStore', this.routeStore)
    hydrate('pictureStore', this.pictureStore)
    hydrate('causeReasonStore', this.causeReasonStore)
    hydrate('routeAutoStore', this.routeAutoStore)
    hydrate('priceModelStore', this.priceModelStore)
    hydrate('calendarStore', this.calendarStore)
  }
}
const rootStore = new RootStore()

const Provider = (props) => {

  return (<Providers
    rootStore={rootStore}
    authenStore={rootStore.authenStore}
    commonStore={rootStore.commonStore}
    masterStore={rootStore.masterStore}
    routeStore={rootStore.routeStore}
    pictureStore={rootStore.pictureStore}
    causeReasonStore={rootStore.causeReasonStore}
    routeAutoStore={rootStore.routeAutoStore}
    priceModelStore={rootStore.priceModelStore}
    calendarStore={rootStore.calendarStore}
  >
    {props.children}
  </Providers>)
}

export default Provider