import axios from 'axios'
import { BASE_URL } from '../AppConfig'
import IAuth from '../interfaces/Common/IAuth'

export default {
  //#region USERS
  addShipto: async function (auth: IAuth, data) {
    const response = await axios.post(`${BASE_URL}/shipto/add`, {
      cust_id: data.cust_id,
      shipto_name: data.shipto_name,
      shipto_code: data.shipto_code,
      lat: data.lat,
      lon: data.lon,
      village: data.village,
      soi: data.soi,
      road: data.road,
      admin_level1_id: data.admin_level1_id,
      admin_level2_id: data.admin_level2_id,
      admin_level3_id: data.admin_level3_id,
      post_code: data.post_code,
      tel: data.tel,
      // taxid: data.taxid,
      ref_code: data.ref_code,
      dtopen: data.dtopen,
      dtclose: data.dtclose,
      pickup_mins: data.pickup_mins,
      pickdown_mins: data.pickdown_mins,
      status: data.status
    },
      {
        headers: { action_user_id: auth?.action_user_id, token_id: auth?.token_id }
      })
    return response
  },

  updateShipto: async function (auth: IAuth, data) {
    const response = await axios.put(`${BASE_URL}/shipto/update`, {
      shipto_id: data.shipto_id,
      cust_id: data.cust_id,
      shipto_name: data.shipto_name,
      shipto_code: data.shipto_code,
      lat: data.lat,
      lon: data.lon,
      address: data.address,
      village: data.village,
      soi: data.soi,
      road: data.road,
      admin_level1_id: data.admin_level1_id,
      admin_level2_id: data.admin_level2_id,
      admin_level3_id: data.admin_level3_id,
      post_code: data.post_code,
      tel: data.tel,
      // taxid: data.taxid,
      ref_code: data.ref_code,
      dtopen: data.dtopen,
      dtclose: data.dtclose,
      pickup_mins: data.pickup_mins,
      pickdown_mins: data.pickdown_mins,
      status: data.status
    },
      {
        headers: { action_user_id: auth?.action_user_id, token_id: auth?.token_id }
      })
    return response
  },
  //#endregion
}