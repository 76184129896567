
import { XIcon, CheckIcon } from "@heroicons/react/solid";
import DataGrid, { Paging, Column, Scrolling } from 'devextreme-react/data-grid';

const TableUpload = (props) => {
  let { dataSource } = props // STATE
  let { setOpenEditQueue, onDelete } = props // ACTION

  return (
    <DataGrid
      dataSource={dataSource}
      keyExpr="id"
      height={'calc(100vh - 230px)'}
      showBorders={true}
      allowColumnReordering={true}
      columnAutoWidth={true}
      columnMinWidth={100}
      hoverStateEnabled={true}
      onRowPrepared={(e: any) => {
        if (e.rowType === "data" && e.data.valid_message !== "") {
          e.rowElement.style.backgroundColor = "#ffbdbd"
        }
      }}
    >
      <Scrolling columnRenderingMode="virtual" />
      <Paging enabled={false} />
      <Column dataField="no" caption="ลำดับ" />
      <Column dataField="po_no" caption="PO No." />
      <Column dataField="gq_no" caption="D/O No." />
      <Column dataField="cust_code" caption="รหัสลูกค้า" />
      <Column dataField="cust_name" caption="ชื่อลูกค้า" />
      <Column dataField="loc_receipt_name" caption="คลัง/ต้นทาง" />
      <Column dataField="loc_dest_name" caption="สาขา/ปลายทาง" />
      <Column dataField="job_type_name" caption="ประเภทงาน" />
      <Column dataField="logistic_type_name" caption="ประเภทการขนส่ง" />
      <Column dataField="delivery_date" caption="วันที่ส่งสินค้า" />
      <Column dataField="qno" caption="เลขที่ใบงาน" />
      <Column dataField="job_date" caption="วันที่รับงาน" />
      <Column dataField="center_name" caption="หน่วยงาน" />
      <Column dataField="shipment_type_name" caption="ประเภทเที่ยว" />
      <Column dataField="product_code" caption="รหัสสินค้า" />
      <Column dataField="product_name" caption="ชื่อสินค้า" />
      <Column dataField="ref_order_no" caption="Ref Order No" />
      <Column dataField="track_no" caption="Track No" />
      <Column dataField="total_qty" caption="จำนวน" />
      <Column dataField="unit_name" caption="หน่วย" />
      <Column dataField="valid_message" caption="ความผิดพลาด" />
      <Column
        fixed={true}
        fixedPosition="right"
        alignment="center"
        width={80}
        dataField="valid_message"
        caption="ตรวจสอบ"
        cellRender={(e) => {
          return <div className="flex justify-center">
            {e.value === "" ? <CheckIcon className="w-5 h-5 text-success-500" /> : <XIcon className="w-5 h-5 text-danger-500" />}
          </div>
        }}
      />
      <Column
        fixed={true}
        type="buttons"
        width={80}
        buttons={[
          {
            hint: "แก้ไข",
            icon: "fa fa-pencil-square-o",
            visible: true,
            onClick: (e) => {
              e.cancel = "true"
              setOpenEditQueue(e.row.data)
            }
          },
          {
            hint: "ลบ",
            icon: "trash",
            visible: true,
            onClick: (e) => {
              e.cancel = "true"
              onDelete(e.row.data.id)
            }
          }
        ]} />
    </DataGrid>
  )
}

export default TableUpload