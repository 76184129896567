
import { useState, useRef, useEffect } from 'react'
import { inject, observer } from 'mobx-react';
import { Modal, ModalAlert, FormLoading, Select, Button } from '../../../components'
import DataGrid, { Scrolling, Column, Paging, Editing } from 'devextreme-react/data-grid';
import { PriceModelService } from '../../../services';
import { isEmpty } from 'lodash'
import { PlusIcon } from '@heroicons/react/solid';
import { Checkbox } from "antd";

let lengthOfDefault = 0
const ModalPriceSet = inject("priceModelStore")(observer((props) => {
  let priceModelStore = props.priceModelStore
  let { setPriceModeStore, openModalPriceSet } = priceModelStore // STATE

  let { auth, masterOptions } = props // STATES
  let { onReload } = props // ACTIONS

  const gridRef: any = useRef(null);
  const [loading, setLoading]: any = useState({ visible: false, tip: 'กำลังบันทึก...' })
  const [validation, setValidation]: any = useState({})
  const [priceSetList, setPriceSetList]: any = useState([])
  const [shakeIsWrong, setShakeIsWrong] = useState(false)
  const [optionAlert, setOptionAlert]: any = useState({
    show: false,
    action: {
      id: "",
      name: "",
      price_set_id: "",
      logistic_type_id: ""
    },
    type: "success",
    title: "",
    description: {
      visible: false,
      type: "",
      content: "",
      contentLists: [],

    }
  })
  const [dataForm, setDataForm]: any = useState({
    "cust_id": "",
    "price_set_name": "",
    "logistic_type_id": ""
  })

  useEffect(() => {
    // openModalPriceSet && getPriceSet()
    setPriceSetList([])
  }, [openModalPriceSet])


  const onSave = () => {
    let validateCheck = {}, message = { isError: true, message: "โปรดระบุ" }

    if (dataForm.cust_id.length === 0 || dataForm.cust_id === "")
      validateCheck['cust_id'] = message
    if (dataForm.logistic_type_id.length === 0 || dataForm.logistic_type_id === "")
      validateCheck['logistic_type_id'] = message

    if (isEmpty(validateCheck)) {
      setValidation({})

      let dataNew = priceSetList.filter((dt) => dt.type === "new")
      if (dataNew.length === 0) {
        setOptionAlert({
          ...optionAlert,
          action: {
            id: "",
            name: ""
          },
          type: "info",
          title: "ข้อมูลปัจจุบันยังไม่ถูกแก้ไข",
          show: true,
          description: {
            visible: false,
            type: "text",
            content: "",
            contentLists: []
          }
        })
      }
      else {
        setOptionAlert({
          ...optionAlert,
          action: {
            id: dataForm.id,
            name: "save"
          },
          type: "confirm",
          title: "ยืนยัน",
          show: true,
          description: {
            visible: true,
            type: "text",
            content: "คุณแน่ใจที่จะบันทึกข้อมูล ?",
            contentLists: []
          }
        })
      }
    }
    else {
      setValidation(validateCheck)
      setShakeIsWrong(true)
      setTimeout(() => setShakeIsWrong(false), 100);
    }
  }

  const handleGetPriceSet = (cust_id, logistic_type_id) => {
    if (cust_id !== "" && logistic_type_id !== "") getPriceSet(cust_id, logistic_type_id)
  }

  const getPriceSet = (cust_id = "", logistic_type_id = "") => {
    setLoading({ ...loading, visible: true, tip: "กำลังโหลด..." })
    PriceModelService.getPriceSet(auth, cust_id, logistic_type_id)
      .then((res: any) => {
        let { code, result, message, sys_message } = res
        if (code === 200) {
          let data = result.map((dt, idx) => ({ ...dt, no: (idx + 1), type: 'default' }))
          lengthOfDefault = data.length
          setPriceSetList(data)
        }
        else {
          setOptionAlert({
            ...optionAlert,
            type: "danger",
            show: true,
            action: {
              id: "",
              name: ""
            },
            title: "ผิดพลาด",
            description: { visible: true, type: "text", content: `${message} ${sys_message}` }
          })
        }
        setLoading({ ...loading, visible: false })
      })
      .catch((err) => {
        setLoading({ ...loading, visible: false })
        setOptionAlert({
          ...optionAlert,
          type: "danger",
          show: true,
          action: {
            id: "",
            name: ""
          },
          title: "ผิดพลาด",
          description: { visible: true, type: "text", content: `${err}` }
        })
      })
  }

  const saveData = async () => {
    setLoading({ ...loading, visible: true, tip: 'กำลังบันทึก...' })
    let dataNew = priceSetList.filter((dt) => dt.type === "new")

    const fetchPromiseAll = async (list) => {
      const promises = list.map(async (dt) => {
        await saveRecord(dt.price_set_name)
      })
      await Promise.all(promises)
    }

    await fetchPromiseAll(dataNew)

    setLoading({ ...loading, visible: false })
    setPriceModeStore({ openModalPriceSet: false })
    // clearForm()
    // onReload()
  }


  const saveRecord = (price_set_name) => {
    PriceModelService.savePriceSet(auth, { cust_id: dataForm.cust_id, price_set_name, logistic_type_id: dataForm.logistic_type_id })
      .then((res: any) => {
        let { code, message, sys_message } = res.data

        if (code === 200) {
          setOptionAlert({
            ...optionAlert,
            type: "success",
            show: true,
            action: {
              id: "",
              name: "save"
            },
            title: "บันทึกข้อมูลสำเร็จ",
            description: { visible: false, type: "text", content: " ", contentLists: [] }
          })
          // setPriceModeStore({ openModalPriceSet: false })
          // clearForm()
          // onReload()
        }
        else {
          setOptionAlert({
            ...optionAlert,
            type: "danger",
            show: true,
            action: {
              id: "",
              name: ""
            },
            title: "ผิดพลาด",
            description: { visible: true, type: "text", content: `${message} ${sys_message}` }
          })
        }
        // setLoading({ ...loading, visible: false })
      })
      .catch((err) => {
        setOptionAlert({
          ...optionAlert,
          type: "danger",
          show: true,
          action: {
            id: "",
            name: ""
          },
          title: "ผิดพลาด",
          description: { visible: true, type: "text", content: `${err}` }
        })
        setLoading({ ...loading, visible: false })
      })
  }

  const setDefault = () => {
    setLoading({ ...loading, visible: true, tip: "กำลังบันทึก..." })
    let { price_set_id, logistic_type_id } = optionAlert.action
    PriceModelService.setDefault(auth, { cust_id: dataForm.cust_id, price_set_id, logistic_type_id })
      .then((res: any) => {
        let { code, message, sys_message } = res.data
        if (code === 200) {
          setOptionAlert({
            ...optionAlert,
            type: "success",
            show: true,
            action: {
              id: "",
              name: ""
            },
            title: "บันทึกข้อมูลสำเร็จ",
            description: { visible: false, type: "text", content: " ", contentLists: [] }
          })
          getPriceSet(dataForm.cust_id, logistic_type_id)
        }
        else {
          setOptionAlert({
            ...optionAlert,
            type: "danger",
            show: true,
            action: {
              id: "",
              name: ""
            },
            title: "ผิดพลาด",
            description: { visible: true, type: "text", content: `${message} ${sys_message}` }
          })
        }
        setLoading({ ...loading, visible: false })
      })
      .catch((err) => {
        setOptionAlert({
          ...optionAlert,
          type: "danger",
          show: true,
          action: {
            id: "",
            name: ""
          },
          title: "ผิดพลาด",
          description: { visible: true, type: "text", content: `${err}` }
        })
        setLoading({ ...loading, visible: false })
      })
  }

  const clearForm = () => {
    lengthOfDefault = 0
    setValidation({})
    setDataForm({
      "cust_id": "",
      "price_set_name": "",
      "logistic_type_id": ""
    })
  }

  const handleOnAdd = () => {
    let validateCheck = {}, message = { isError: true, message: "โปรดระบุ" }

    if (dataForm.cust_id.length === 0 || dataForm.cust_id === "")
      validateCheck['cust_id'] = message
    if (dataForm.logistic_type_id.length === 0 || dataForm.logistic_type_id === "")
      validateCheck['logistic_type_id'] = message

    if (isEmpty(validateCheck)) {
      gridRef.current.instance.addRow()
    }
    else {
      setValidation(validateCheck)
      setShakeIsWrong(true)
      setTimeout(() => setShakeIsWrong(false), 100);
    }
  }

  return (<>
    <Modal
      title={{ text: `เพิ่มหมวดราคา` }}
      open={openModalPriceSet}
      maskClosable={true}
      shake={shakeIsWrong}
      width='lg:w-2/6 md:w-3/6'
      // okDisabled={priceSetList.length === lengthOfDefault}
      onClosed={() => {
        setPriceModeStore({ openModalPriceSet: false })
        clearForm()
      }}
      onOk={() => onSave()}
      extra={<Button
        text="เพิ่มข้อมูล"
        icon={<PlusIcon />}
        size="sm"
        type="white"
        iconPosition='start'
        // onClick={() => gridRef.current.instance.addRow()}
        onClick={() => handleOnAdd()}
      />}
    >
      <FormLoading loading={loading.visible} tip={loading.tip}>
        <div className="flex flex-col space-y-2">
          <div className='flex-1'>
            <Select
              showSearch={true}
              label="ลูกค้า"
              required={true}
              placeholder="ลูกค้า"
              validate={validation?.cust_id}
              onChange={(select) => {
                setDataForm({ ...dataForm, cust_id: select })
                // getPriceSet(select)
                handleGetPriceSet(select, dataForm.logistic_type_id)
              }}
              defaultValue={dataForm.cust_id === "" ? [] : dataForm.cust_id}
              options={masterOptions.customer}
            />
          </div>

          <div className='flex-1'>
            <Select
              showSearch={true}
              label="ประเภทการขนส่ง"
              required={true}
              placeholder="ประเภทการขนส่ง"
              validate={validation?.logistic_type_id}
              onChange={(select) => {
                setDataForm({ ...dataForm, logistic_type_id: select })
                // getPriceSet(select)
                handleGetPriceSet(dataForm.cust_id, select)
              }}
              defaultValue={dataForm.logistic_type_id === "" ? [] : dataForm.logistic_type_id}
              options={masterOptions.logistic_type}
            />
          </div>

          <div className='flex-1'>
            <label className={`flex flex-row text-sm font-medium text-gray-700`}>
              หมวดราคา
            </label>

            <DataGrid
              ref={(ref) => gridRef.current = ref}
              dataSource={priceSetList}
              keyExpr="id"
              allowColumnReordering={true}
              columnAutoWidth={true}
              hoverStateEnabled={true}
              showBorders={true}
              height={450}
              onSaved={(e) => {
                let dataSave = e.component.getDataSource().items()
                let dataRunning = dataSave.map((item, idx) => {
                  let type = item.type
                  return ({
                    ...item,
                    id: (idx + 1),
                    no: (idx + 1),
                    type: type === "default" ? type : 'new'
                  })
                })
                setPriceSetList(dataRunning)
              }}
            >
              <Scrolling columnRenderingMode="virtual" />
              <Paging enabled={false} />
              {
                priceSetList.length !== lengthOfDefault && <Editing
                  mode="row"
                  allowDeleting={(e) => (e.row.data.type === "new")}
                  texts={{
                    deleteRow: "ลบ",
                    editRow: "แก้ไข",
                    cancelRowChanges: "ยกเลิก",
                    saveRowChanges: "บันทึก"
                  }} />
              }

              <Column
                dataField="no"
                caption="ลำดับ"
                width={60}
                alignment="center"
                allowEditing={false}
                allowSorting={false}
              />

              <Column
                dataField="price_set_name"
                caption="ชื่อราคา"
                alignment="center"
                allowSorting={false} />

              <Column
                dataField="price_type"
                caption="ค่าเริ่มต้น"
                alignment="center"
                width={80}
                allowSorting={false}
                cellRender={(e) => {
                  let { id, logistic_type_id, type } = e.data
                  if (type === "default")
                    return <Checkbox
                      checked={e.value === 1}
                      onClick={(e) => {
                        setOptionAlert({
                          ...optionAlert,
                          action: {
                            id: dataForm.id,
                            name: "set_default",
                            price_set_id: id,
                            logistic_type_id
                          },
                          type: "confirm",
                          title: "ยืนยัน",
                          show: true,
                          description: {
                            visible: true,
                            type: "text",
                            content: "คุณแน่ใจที่จะบันทึกข้อมูล ?",
                            contentLists: []
                          }
                        })
                      }}
                    />
                  else
                    return null
                }}
              />
            </DataGrid>
          </div>
        </div>
      </FormLoading>
    </Modal>

    <ModalAlert
      {...optionAlert}
      onOk={() => {
        let { action, type } = optionAlert
        if (action.name === "save") saveData()
        if (action.name === "set_default") setDefault()
        if (action.name === "save" && type === "success") {
          clearForm()
          onReload()
        }

        setOptionAlert({ ...optionAlert, show: false })
      }}
      onCancel={() => {
        setOptionAlert({ ...optionAlert, show: false })
      }}
    />
  </>
  )
}))

export default ModalPriceSet