import React, { useState, useRef } from 'react'
import { inject, observer } from 'mobx-react';
import { Card, Button } from '../../../components'
import { createMultipleRef } from '../../../helpers'
import { PlusIcon } from '@heroicons/react/outline';
import { FloatButton } from 'antd';
import Table from './Table'
import FormModal from './FormModal'

export interface CardTabListType {
  key: string;
  tab: React.ReactNode;
  disabled?: boolean;
}

const FormMaster = inject("masterStore")(observer((props) => {
  let masterStore = props.masterStore
  let { setMasterStore } = masterStore
  let {
    title,
    tableConfig,
    tableHeaderCustom,
    formData,
    modalConfig = {
      visible: true,
      isCustom: false,
      title: "",
      width: 450,
      apiPath: "",
      elements: [],
      required: []
    },
    onAdd,
    onEdit,
    cardExtra = null,
  } = props

  const inputRef: any = useRef([]);
  createMultipleRef(inputRef, 4)

  const [openModal, setOpen]: any = useState(false)

  return <div className="space-y-2">
    <Card
      title={title}
      extra={<><Button
        hidden={tableConfig?.hiddenAddButton}
        text="เพิ่มข้อมูล"
        icon={<PlusIcon />}
        iconPosition='start'
        onClick={() => {
          if (modalConfig?.visible) {
            setMasterStore({
              modalConfig: {
                show: true,
                action: "add",
                id: "",
                isCustom: modalConfig.isCustom
              }
            })
          }
          else {
            onAdd({
              modalConfig: {
                show: true,
                action: "add",
                id: "",
                isCustom: modalConfig.isCustom
              }
            })
          }

        }}
      />
        {cardExtra}
      </>}
    >
      <div className="mt-5 md:col-span-2 md:mt-0">
        <Table
          modalVisible={modalConfig?.visible}
          tableConfig={tableConfig}
          tableHeaderCustom={tableHeaderCustom}
          onEdit={(data) => onEdit(data)}
        />
      </div>
      {
        modalConfig.visible && <FormModal
          open={openModal}
          formData={formData}
          modalConfig={modalConfig}
          onClosed={() => setOpen(false)}
        />
      }

    </Card>
    <FloatButton.BackTop type="primary" />
  </div>
}))

export default FormMaster
