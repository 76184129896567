import axios from 'axios'
import { BASE_URL } from '../AppConfig'
import IAuth from '../interfaces/Common/IAuth'

interface IQueue {
  delivery_date: string
  qid?: string
  id_refno?: string
}

export default {
  addQueue: async function (auth: IAuth, data) {
    const response = await axios.post(`${BASE_URL}/queue/add`, {
      cust_id: data.cust_id,
      po_no: data.po_no,
      gq_no: data.gq_no,
      logistic_type_id: data.logistic_type_id,
      bill_cust_id: data.bill_cust_id,
      job_type_id: data.job_type_id,
      loc_receipt_id: data.loc_receipt_id,
      loc_dest_id: data.loc_dest_id,
      delivery_date: data.delivery_date,
      remark: data.remark,
      center_id: data.center_id,
      shipment_type: data.shipment_type,
      products: data.products,
      price_set_id: data.price_set_id,
      price_set_value: data.price_set_value,
      ref_order_no: data.ref_order_no,
      track_no: data.track_no

    },
      {
        headers: { action_user_id: auth?.action_user_id, token_id: auth?.token_id }
      })
    return response
  },

  updateQueue: async function (auth: IAuth, data) {
    const response = await axios.put(`${BASE_URL}/queue/update`, {
      q_id: data.q_id,
      po_no: data.po_no,
      gq_no: data.gq_no,
      cust_id: data.cust_id,
      logistic_type_id: data.logistic_type_id,
      bill_cust_id: data.bill_cust_id,
      job_type_id: data.job_type_id,
      loc_receipt_id: data.loc_receipt_id,
      loc_dest_id: data.loc_dest_id,
      delivery_date: data.delivery_date,
      remark: data.remark,
      center_id: data.center_id,
      shipment_type: data.shipment_type,
      products: data.products,
      price_set_id: data.price_set_id,
      price_set_value: data.price_set_value,
      ref_order_no: data.ref_order_no,
      track_no: data.track_no
    },
      {
        headers: { action_user_id: auth?.action_user_id, token_id: auth?.token_id }
      })
    return response
  },

  deleteQueue: async function (auth: IAuth, qids) {
    try {
      const response = await axios.delete(`${BASE_URL}/queue/delete?qids=${qids}`, {
        headers: {
          action_user_id: auth?.action_user_id, token_id: auth?.token_id
        }
      })
      return response.data
    } catch (error) {
      throw error
    }
  },

  getQueue: async function (auth: IAuth, data: IQueue) {
    try {
      let prmList: any = []
      for (const key in data) {
        if (data[key] !== "") prmList.push(`${key}=${data[key]}`)
      }
      let param = prmList.join('&');

      const response = await axios.get(`${BASE_URL}/queue/list?${param}`, {
        headers: { action_user_id: auth?.action_user_id, token_id: auth?.token_id }
      })
      return response.data
    } catch (error) {
      throw error
    }
  },

  cancelQueue: async function (auth: IAuth, qids) {
    const response = await axios.put(`${BASE_URL}/queue/cancel?qids=${qids}`,
      {
        headers: { action_user_id: auth?.action_user_id, token_id: auth?.token_id }
      })
    return response
  }
}