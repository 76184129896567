import axios from 'axios'
import { BASE_URL } from '../AppConfig'
import IAuth from '../interfaces/Common/IAuth'

export default {
  //#region USERS
  addDriver: async function (auth: IAuth, data) {
    const response = await axios.post(`${BASE_URL}/service/add`, {
      sub_id: data.sub_id,
      citizen_id: data.citizen_id,
      service_code: data.service_code,
      code_ref: data.code_ref,
      first_name: data.first_name,
      last_name: data.last_name,
      nick_name: data.nick_name,
      position: data.position,
      department: data.department,
      phone_no: data.phone_no,
      status: data.status,
      start_date: data.start_date
    },
      {
        headers: { action_user_id: auth?.action_user_id, token_id: auth?.token_id }
      })
    return response
  },

  updateDriver: async function (auth: IAuth, data) {
    const response = await axios.put(`${BASE_URL}/service/update`, {
      service_id: data.service_id,
      sub_id: data.sub_id,
      citizen_id: data.citizen_id,
      service_code: data.service_code,
      code_ref: data.code_ref,
      first_name: data.first_name,
      last_name: data.last_name,
      nick_name: data.nick_name,
      position: data.position,
      department: data.department,
      phone_no: data.phone_no,
      status: data.status,
      start_date: data.start_date
    },
      {
        headers: { action_user_id: auth?.action_user_id, token_id: auth?.token_id }
      })
    return response
  },

  getDriver: async function (auth: IAuth, id: number) {
    try {
      const response = await axios.get(`${BASE_URL}/service/list?service_id=${id}`, {
        headers: { action_user_id: auth?.action_user_id, token_id: auth?.token_id }
      })
      return response.data
    } catch (error) {
      throw error
    }
  },

  resetPassword: async function (auth: IAuth, service_id: number) {
    const response = await axios.put(`${BASE_URL}/service/resetpwd`, {
      service_id
    },
      {
        headers: { action_user_id: auth?.action_user_id, token_id: auth?.token_id }
      })
    return response
  },


  //#endregion
}