import { useState, useRef, useEffect } from 'react'
import { inject, observer } from 'mobx-react';
import { createMultipleRef, setOptions } from '../../helpers'
import { Input, Card, Button, ModalAlert, Select, FormLoading } from '../../components'
import { CommonService, DeliveryCostService } from "../../services";
import DataGrid, { SearchPanel, Scrolling, Item, Paging, Pager, Column, Toolbar, HeaderFilter, Selection } from 'devextreme-react/data-grid';
import { RefreshIcon, SearchIcon, CheckIcon } from '@heroicons/react/outline';
import { DatePicker, Checkbox } from 'antd';
import dayjs from 'dayjs';
import moment from 'moment'
import ModalForm from './ModalForm';
import ModalDoc from './ModalDoc';
import { DocumentAddIcon, PencilAltIcon, XIcon } from '@heroicons/react/solid';
import { isEmpty, cloneDeep } from 'lodash';

const { RangePicker } = DatePicker;
const dateFormat = 'YYYY-MM-DD';
let firstRender = false
const DeliveryCost = inject("authenStore")(observer((props) => {
  let authenStore = props.authenStore
  let { getAccountAuth } = authenStore // STATE
  let auth = { action_user_id: getAccountAuth?.user_id, token_id: getAccountAuth?.access_token }

  const [masterOptions, setMasterOptions] = useState({
    service: [],
    vehicle: [],
    cost_status: [],
    cost_item: [],
    fuel_item: [],
    route_status: [],
    round: [{ label: "1", value: 1 }, { label: "2", value: 2 }, { label: "3", value: 3 }]
  })
  const [loading, setLoading]: any = useState(false)
  const [dataSource, setDataSource]: any = useState([])
  const [open, setOpen]: any = useState(false)
  const [openModalDoc, setOpenModalDoc]: any = useState(false)
  const [filterCurrent, setFilterCurrent]: any = useState({})
  const [selectRowKey, setSelectRowKey]: any = useState([])
  const [filter, setFilter]: any = useState({
    sdate: moment().format("YYYY-MM-DD"),
    edate: moment().format("YYYY-MM-DD"),
    vid: "",
    status: "",
    doc_no: "",
    job_status: ""
  })
  const [optionAlert, setOptionAlert]: any = useState({
    show: false,
    action: {
      id: "",
      name: ""
    },
    type: "success",
    title: "",
    description: {
      visible: false,
      type: "",
      content: "",
      contentLists: [],

    }
  })

  const [dataForm, setDataForm]: any = useState({
    "dlv_doc_no": "",
    "route_id": "",
    "ts_gate_in": "",
    "ts_gate_out": "",
    "mileage_start": "",
    "mileage_end": "",
    "service_info": "",
    "tasks": [],
    "cost_items": []
  })

  const gridRef: any = useRef(null);
  const inputRef: any = useRef([]);
  createMultipleRef(inputRef, 2)
  let columns = [
    {
      dataField: 'no',
      caption: "ลำดับ",
      width: 90,
      alignment: "center"
    },
    {
      dataField: 'dlv_doc_no',
      caption: "เลขที่เอกสาร"
    },
    {
      dataField: 'delivery_date',
      caption: "วันที่"
    },
    {
      dataField: 'doc_no',
      caption: "เลขแผนงาน"
    },
    {
      dataField: 'job_status_name',
      caption: "สถานะแผนงาน",
      alignment: "center"
    },
    {
      dataField: 'round_no',
      caption: "รอบที่",
      alignment: "center",
      width: 80
    },
    {
      dataField: 'licenseplate',
      caption: "ทะเบียน"
    },
    {
      dataField: 'vehicle_name',
      caption: "เบอร์รถ"
    },
    {
      dataField: 'total_cost',
      caption: "รวมค่าใช้จ่าย"
    },
    {
      dataField: 'approved_name',
      caption: "Approve by"
    },
    {
      dataField: 'status_name',
      caption: "สถานะ"
    },
    {
      dataField: 'total_doc',
      caption: "จำนวนเอกสาร"
    },
    {
      dataField: 'updated_date',
      caption: "แก้ไขล่าสุด"
    },
    {
      dataField: 'updated_name',
      caption: "ผู้แก้ไข"
    }
  ]

  useEffect(() => {
    if (firstRender || !isEmpty(getAccountAuth)) {
      CommonService.getMasterData(auth, "service,vehicle,cost_status,cost_item,fuel_item,route_status")
        .then((res: any) => {
          let service = res.result.service.map((item) => ({ label: item.service_name, value: item.service_id }))
          let vehicle = res.result.vehicle.map((item) => ({ label: item.licenseplate, value: item.vehicle_id }))
          let cost_status = res.result.cost_status.map((item) => ({ label: item.status_name, value: item.status_code }))
          let cost_item = res.result.cost_item.map((item) => ({ label: item.cost_name, value: item.cost_id }))
          let fuel_item = res.result.fuel_item.map((item) => ({ label: item.fuel_name, value: item.fuel_id, fuel_price: item.fuel_price }))
          let route_status = res.result.route_status.map((item) => ({ label: item.status_name, value: item.status_code }))

          setMasterOptions({
            ...masterOptions,
            service,
            vehicle,
            cost_status,
            cost_item,
            fuel_item,
            route_status
          })
        })
        .catch((err) => {
          setOptionAlert({
            ...optionAlert,
            type: "danger",
            show: true,
            action: {
              id: "",
              name: ""
            },
            title: "ผิดพลาด",
            description: { visible: true, content: `Load master data : ${err}` }
          })
        })
    }
    else
      firstRender = true
  }, [getAccountAuth])

  const clearCost = (id) => {
    DeliveryCostService.clearCost(auth, id)
      .then((res: any) => {
        let { code, message, sys_message } = res
        if (code === 200) {
          setOptionAlert({
            ...optionAlert,
            type: "success",
            show: true,
            action: {
              id: "",
              name: ""
            },
            title: "ยกเลิกสำเร็จ",
            description: { visible: false, type: "text", content: " ", contentLists: [] }
          })
          searchDailyCost(true)
        }
        else {
          setOptionAlert({
            ...optionAlert,
            type: "danger",
            show: true,
            action: {
              id: "",
              name: ""
            },
            title: "ยกเลิกไม่สำเร็จ",
            description: { visible: true, type: "text", content: `${message} ${sys_message}` }
          })
        }
      })
      .catch((err) => {
        setOptionAlert({
          ...optionAlert,
          type: "danger",
          show: true,
          action: {
            id: "",
            name: ""
          },
          title: "ยกเลิกไม่สำเร็จ",
          description: { visible: true, type: "text", content: `${err}` }
        })
      })
  }
  //#region 

  const searchDailyCost = (isReloadCurrent = false, reloadByRouteID = "") => {
    setLoading(true)
    setFilterCurrent(filter)
    let data = isReloadCurrent ? filterCurrent : filter
    DeliveryCostService.getDeliveryCost(auth, data)
      .then((res: any) => {
        let { code, message, result, sys_message } = res
        if (code === 200) {
          setDataSource(result)
          if (reloadByRouteID !== "") {
            let data = result.find((item) => item.route_id === reloadByRouteID)
            if (data) setDataForm(data)
          }
        }
        else {
          setOptionAlert({
            ...optionAlert,
            type: "danger",
            show: true,
            action: {
              id: "",
              name: ""
            },
            title: "ผิดพลาด",
            description: { visible: true, type: "text", content: `${message} ${sys_message}` }
          })
        }
        setLoading(false)
      })
      .catch((err) => {
        setLoading(false)
        setOptionAlert({
          ...optionAlert,
          type: "danger",
          show: true,
          action: {
            id: "",
            name: ""
          },
          title: "ผิดพลาด",
          description: { visible: true, type: "text", content: `${err}` }
        })
      })
  }
  //#endregion


  const approveDailyCost = () => {
    setLoading(true)
    setFilterCurrent(filter)
    let route_list = selectRowKey.join('|');

    DeliveryCostService.approveDeliveryCost(auth, { route_list })
      .then((res: any) => {
        let { code, message, result, sys_message } = res?.data
        if (code === 200) {
          setSelectRowKey([])
          searchDailyCost(true)
        }
        else {
          setOptionAlert({
            ...optionAlert,
            type: "danger",
            show: true,
            action: {
              id: "",
              name: ""
            },
            title: "ผิดพลาด",
            description: { visible: true, type: "text", content: `${message} ${sys_message}` }
          })
        }
        setLoading(false)
      })
      .catch((err) => {
        setLoading(false)
        setOptionAlert({
          ...optionAlert,
          type: "danger",
          show: true,
          action: {
            id: "",
            name: ""
          },
          title: "ผิดพลาด",
          description: { visible: true, type: "text", content: `${err}` }
        })
      })
  }

  const handleChecked = (route_id, checked) => {
    let _selectRowKey = cloneDeep(selectRowKey)

    if (checked) {
      _selectRowKey.push(route_id)
    }
    else {
      _selectRowKey = _selectRowKey.filter(id => id !== route_id);
    }
    setSelectRowKey(_selectRowKey)
  }

  return (
    <div className="space-y-3 p-4">
      <Card title="บันทึกค่าใช้จ่าย">
        <div className="mt-5 md:col-span-2 md:mt-0">
          <FormLoading loading={loading} >
            <div className="flex flex-row mb-2">
              <div className="flex flex-row flex-wrap space-x-2">
                <div className="flex flex-row">
                  <label className="text-sm font-medium text-gray-700 mt-3 mr-2 text-start">
                    วันที่ :
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <RangePicker
                      allowClear={false}
                      defaultValue={[dayjs(filter.sdate, dateFormat), dayjs(filter.edate, dateFormat)]}
                      style={{ marginTop: 4 }}
                      onChange={(d: any) => {
                        if (d) {
                          setFilter({
                            ...filter,
                            sdate: d[0].format("YYYY-MM-DD"),
                            edate: d[1].format("YYYY-MM-DD")
                          })
                        }
                        else {
                          setFilter({
                            ...filter,
                            sdate: "",
                            edate: ""
                          })
                        }
                      }}
                    />
                  </div>
                </div>

                <div className="flex flex-row">
                  <label className="text-sm font-medium text-gray-700 mt-3 mr-2 text-start">
                    สถานะแผนงาน :
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <Select
                      allowClear={true}
                      showSearch={true}
                      defaultValue={filter.job_status}
                      placeholder="สถานะแผนงาน"
                      width={150}
                      options={masterOptions.route_status}
                      onChange={(value) => setFilter({ ...filter, job_status: value })}
                    />
                  </div>
                </div>

                <div className="flex flex-row">
                  <label className="text-sm font-medium text-gray-700 mt-3 mr-2 text-start">
                    เลขที่แผนงาน :
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <Input
                      label="เลขที่แผนงาน"
                      placeholder="เลขที่แผนงาน"
                      hiddenLabel={true}
                      onChange={(e) => setFilter({ ...filter, doc_no: e.target.value })}
                    />
                  </div>
                </div>

                <div className="flex flex-row">
                  <label className="text-sm font-medium text-gray-700 mt-3 mr-2 text-start">
                    ทะเบียน :
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <Select
                      allowClear={true}
                      showSearch={true}
                      defaultValue={filter.vid}
                      placeholder="ทะเบียน"
                      width={150}
                      options={masterOptions.vehicle}
                      onChange={(value) => setFilter({ ...filter, vid: value })}
                    />
                  </div>
                </div>

                <div className="flex flex-row">
                  <label className="text-sm font-medium text-gray-700 mt-3 mr-2 text-start">
                    สถานะ :
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <Select
                      allowClear={true}
                      defaultValue={filter.status}
                      placeholder="สถานะ"
                      width={150}
                      options={masterOptions.cost_status}
                      onChange={(value) => setFilter({ ...filter, status: value })}
                    />
                  </div>
                </div>

                <div className="flex flex-row">
                  <label className="text-sm font-medium text-gray-700 mt-3 text-start"> </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0 pt-1">
                    <Button
                      text="ค้นหา"
                      icon={<SearchIcon />}
                      size="md"
                      type="white"
                      title="ค้นหา"
                      onClick={() => {
                        if (!loading) {
                          searchDailyCost()
                        }
                      }}
                    />
                  </div>
                </div>

                {
                  getAccountAuth.role_level < 5 && <div className="flex flex-row">
                    <label className="text-sm font-medium text-gray-700 mt-3 text-start"> </label>
                    <div className="mt-1 sm:col-span-2 sm:mt-0 pt-1">
                      <button
                        type="button"
                        title="Approve"
                        className={`flex flex-row  items-center rounded border border-transparent bg-success-100 mt-0.5 px-2.5 py-1.5 text-xs font-medium text-success-700 hover:bg-success-200`}
                        onClick={() => {
                          if (selectRowKey.length === 0) {
                            setOptionAlert(setOptions({
                              defaultOption: alert,
                              action: {
                                id: "",
                                name: ''
                              },
                              show: true,
                              type: "info",
                              title: "โปรดเลือกรายการ",
                              description: {
                                visible: false,
                                content: "คุณแน่ใจที่จะ Approve ?"
                              }
                            }))
                          }
                          else {
                            setOptionAlert(setOptions({
                              defaultOption: alert,
                              action: {
                                id: "",
                                name: 'approve'
                              },
                              show: true,
                              type: "confirm",
                              title: "ยืนยัน",
                              description: {
                                visible: true,
                                content: "คุณแน่ใจที่จะ Approve ?"
                              }
                            }))
                          }
                        }}
                      >
                        <div className="flex flex-row-reverse">
                          <span>Approve</span>
                          <CheckIcon className="w-4 h-4 mr-1" />
                        </div>
                      </button>
                    </div>
                  </div>
                }
              </div>

            </div>
            <DataGrid
              ref={(ref) => gridRef.current = ref}
              dataSource={dataSource}
              keyExpr={'route_id'}
              showBorders={true}
              columnAutoWidth={true}
              hoverStateEnabled={true}
            // onSelectionChanged={(e) => setSelectRowKey(e.selectedRowKeys)}
            >
              <Scrolling columnRenderingMode="virtual" />
              {/* <Selection
                mode="multiple"
                selectAllMode="allPages"
                showCheckBoxesMode="always"
              /> */}
              <SearchPanel visible={false} />
              <Paging defaultPageSize={10} />
              <Pager
                showInfo={true}
                showPageSizeSelector={true}
                allowedPageSizes={[10, 20, 50]}
              />
              <HeaderFilter visible={true} />
              <Column
                // fixed={true}
                // fixedPosition="right"
                allowFiltering={false}
                dataField="status"
                caption=""
                alignment="center"
                // width={90}
                cellRender={(e) => {
                  let { route_id } = e.data
                  if (e.value === "") {
                    return <Checkbox defaultChecked={false} disabled />
                  }
                  else if (e.data.is_approved === "Y") {
                    return <div className='text-center ml-2'>
                      <CheckIcon className="w-5 h-5 text-success-500" />
                    </div>
                  }
                  else {
                    return <Checkbox onChange={(e) => handleChecked(route_id, e.target.checked)} />
                  }

                }}
              />

              {
                columns.map((column) => <Column
                  key={column.dataField}
                  width={column.width}
                  dataField={column.dataField}
                  caption={column.caption}
                  alignment={column.alignment}
                  allowFiltering={false}
                />)
              }

              <Column
                fixed={true}
                fixedPosition="right"
                allowFiltering={false}
                dataField="docs"
                caption="เอกสาร"
                alignment="center"
                width={90}
                cellRender={(e) => {
                  return <Button
                    text="จัดการ"
                    icon={<DocumentAddIcon />}
                    size="sm"
                    type="primary"
                    iconPosition='start'
                    onClick={() => {
                      e.cancel = "true"
                      let data = e.data
                      setOpenModalDoc(true)
                      setDataForm(data)
                    }}
                  />
                }}
              />

              <Column
                fixed={true}
                fixedPosition="right"
                allowFiltering={false}
                dataField="docs"
                caption="แก้ไข"
                alignment="center"
                width={100}
                cellRender={(e) => {
                  e.cancel = "true"
                  let data = e.data
                  return <Button
                    text="ค่าใช้จ่าย"
                    icon={<PencilAltIcon />}
                    size="sm"
                    type="primary"
                    iconPosition='start'
                    onClick={() => {
                      setOpen(true)
                      setDataForm(data)
                    }}
                  />
                }}
              />

              <Column
                fixed={true}
                fixedPosition="right"
                allowFiltering={false}
                dataField="docs"
                caption="ล้าง"
                alignment="center"
                width={90}
                cellRender={(e) => {
                  e.cancel = "true"
                  let data = e.data
                  return <Button
                    text="ยกเลิก"
                    icon={<XIcon />}
                    size="sm"
                    type="danger"
                    iconPosition='start'
                    onClick={() => setOptionAlert(setOptions({
                      defaultOption: alert,
                      action: {
                        id: data.route_id,
                        name: 'delete'
                      },
                      show: true,
                      type: "confirm",
                      title: "ยืนยัน",
                      description: {
                        visible: true,
                        content: "คุณแน่ใจที่จะยกเลิก ?"
                      }
                    }))}
                  />
                }}
              />

              <Toolbar visible={false}>
                <Item location="after">
                  <button
                    type="button"
                    className="-mb-1 inline-flex items-center px-1.5 py-1.5 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50"
                    title="Refresh"
                    onClick={() => { }}
                  >
                    <RefreshIcon className="w-4 h-4" />
                  </button>
                </Item>

                <Item location="after">
                  <button
                    type="button"
                    className="-mb-1 inline-flex items-center px-1.5 py-1.5 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50"
                    title="Export"
                  >
                    <img className="h-4 w-4" src="/Images/excel.png" />
                  </button>
                </Item>

                <Item location="after">
                  <Input
                    icon={<SearchIcon className="w-5 h-5 text-gray-500" />}
                    placeholder="ค้นหา..."
                    onChange={(e) => gridRef.current.instance.searchByText(e.target.value)}
                  />
                </Item>
              </Toolbar>
            </DataGrid>
          </FormLoading>
        </div>

        <ModalForm
          auth={auth}
          open={open}
          data={dataForm}
          onClosed={(reload) => {
            setOpen(false)
            reload && searchDailyCost(true)
          }}
          masterOptions={masterOptions}
        />

        <ModalDoc
          auth={auth}
          open={openModalDoc}
          data={dataForm}
          reloadData={(route_id) => searchDailyCost(true, route_id)}
          onClosed={(reload) => {
            setOpenModalDoc(false)
            reload && searchDailyCost(true)
          }}
          masterOptions={masterOptions}
        />
      </Card >

      <ModalAlert
        {...optionAlert}
        onOk={() => {
          let { type, action } = optionAlert
          if (action.name === "delete") {
            clearCost(action.id)
          }
          if (action.name === "approve") {
            approveDailyCost()
          }
          setOptionAlert({ ...optionAlert, show: false })
        }}
        onCancel={() => {
          setOptionAlert({ ...optionAlert, show: false })
        }}
      />
    </div>
  )
}))

export default DeliveryCost