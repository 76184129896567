import * as React from 'react'
import { BrowserRouter as Router, Route, Routes } from "react-router-dom"
import './App.css'

// COMMON PAGES
import MainLayout from "./layout/MainLayout"
import Signin from './pages/Signin'
import PageUnauthorized from './pages/Errors/401'
import PageNotFound from './pages/Errors/404'

//#region PAGES
import HomePage from './pages/HomePage'
import Dashboard from './pages/Dashboard'
import UploadJob from './pages/UploadJob'
import Worksheet from './pages/Worksheet'
import RouteSuggestion from './pages/RouteSuggestion'
import Plan from './pages/Plan'
import Tracking from './pages/Tracking'
import DeliveryCost from './pages/DeliveryCost'
import FleetCard from './pages/FleetCard'
import FuelPrice from './pages/FuelPrice'
import Cost from './pages/Cost'
import Maintanance from './pages/Maintanance'
import Alert from './pages/Alert'
import NotificationHistory from './pages/NotificationHistory'

//#region REPORT
import Reports from './pages/Reports/GeneralReport'
import Temperature from './pages/Reports/Temperature'
//#endregion

//#region MASTER DATA
import Subcontract from './pages/MasterData/Subcontract'
import Device from './pages/MasterData/Device'
import Driver from './pages/MasterData/Driver'
import CostType from './pages/MasterData/CostType'
import FuelType from './pages/MasterData/FuelType'
import Garage from './pages/MasterData/Garage'
import MaintenanceType from './pages/MasterData/MaintenanceType'
import MaintenanceTypeUnit from './pages/MasterData/MaintenanceTypeUnit'
import Product from './pages/MasterData/Product'
import ProductGroup from './pages/MasterData/ProductGroup'
import ProductType from './pages/MasterData/ProductType'
import ProductUnit from './pages/MasterData/ProductUnit'
import Stored from './pages/MasterData/Stored'
import Shippoint from './pages/MasterData/Shippoint'
import Vehicle from './pages/MasterData/Vehicle'
import VehicleGroup from './pages/MasterData/VehicleGroup'
import VehicleType from './pages/MasterData/VehicleType'
import Zone from './pages/MasterData/Zone'
import Customer from './pages/MasterData/Customer'
import JobType from './pages/MasterData/JobType'
import ShipTo from './pages/MasterData/ShipTo'
import Picture from './pages/MasterData/Picture'
import CustomerUser from './pages/MasterData/CustomerUser'
import CauseReason from './pages/MasterData/CauseReason'
import Rating from './pages/MasterData/Rating'
import CauseOfProduct from './pages/MasterData/CauseOfProduct'
import Document from './pages/MasterData/Document'
import Price from './pages/MasterData/Price'
import PriceModel from './pages/MasterData/PriceModel'
import Calendar from './pages/MasterData/Calendar'
//#endregion

//#region USER MANAGEMENT
import UserGroup from './pages/UserGroup'
import Users from './pages/Users'
import Roles from './pages/Roles'
import MobileLog from './pages/MobileLog'
//#endregion

//#endregion

export default class App extends React.Component<{}, {}> {

  render() {
    return (
      <Router>
        <Routes>
          <Route path='/' element={<Signin />} />
          <Route path='/home-page' element={<MainLayout component={<HomePage />} />} />
          <Route path='/dashboard' element={<MainLayout fullScreen={true} component={<Dashboard />} />} />
          <Route path='/worksheet' element={<MainLayout fullScreen={true} component={<Worksheet />} />} />
          <Route path='/route-suggestion' element={<MainLayout fullScreen={true} component={<RouteSuggestion />} />} />
          <Route path='/upload' element={<MainLayout fullScreen={true} component={<UploadJob />} />} />
          <Route path='/plan' element={<MainLayout fullScreen={true} component={<Plan />} />} />
          <Route path='/tracking' element={<MainLayout fullScreen={true} component={<Tracking />} />} />
          <Route path='/delivery-cost' element={<MainLayout fullScreen={true} component={<DeliveryCost />} />} />
          <Route path='/fleet-card' element={<MainLayout fullScreen={true} component={<FleetCard />} />} />
          <Route path='/fuel-price' element={<MainLayout fullScreen={true} component={<FuelPrice />} />} />
          <Route path='/cost' element={<MainLayout fullScreen={true} component={<Cost />} />} />
          <Route path='/maintanance' element={<MainLayout fullScreen={true} component={<Maintanance />} />} />
          <Route path='/alert' element={<MainLayout fullScreen={true} component={<Alert />} />} />
          <Route path='/notification' element={<MainLayout fullScreen={true} component={<NotificationHistory />} />} />

          {/* REPORT */}
          <Route path='/reports' element={<MainLayout fullScreen={true} component={<Reports />} />} />
          <Route path='/temperature' element={<MainLayout fullScreen={true} component={<Temperature />} />} />

          {/* MASTER DATA */}
          <Route path='/subcontract' element={<MainLayout component={<Subcontract />} />} />
          <Route path='/device' element={<MainLayout component={<Device />} />} />
          <Route path='/driver' element={<MainLayout component={<Driver />} />} />
          <Route path='/cost-type' element={<MainLayout component={<CostType />} />} />
          <Route path='/fuel-type' element={<MainLayout component={<FuelType />} />} />
          <Route path='/garage' element={<MainLayout component={<Garage />} />} />
          <Route path='/maintenance-type' element={<MainLayout component={<MaintenanceType />} />} />
          <Route path='/maintenance-type-unit' element={<MainLayout component={<MaintenanceTypeUnit />} />} />
          <Route path='/product' element={<MainLayout component={<Product />} />} />
          <Route path='/product-group' element={<MainLayout component={<ProductGroup />} />} />
          <Route path='/product-type' element={<MainLayout component={<ProductType />} />} />
          <Route path='/product-unit' element={<MainLayout component={<ProductUnit />} />} />
          <Route path='/stored' element={<MainLayout component={<Stored />} fullScreen={true} classContent="bg-white" />} />
          <Route path='/shippoint' element={<MainLayout component={<Shippoint />} />} />
          <Route path='/vehicle' element={<MainLayout component={<Vehicle />} />} />
          <Route path='/vehicle-group' element={<MainLayout component={<VehicleGroup />} />} />
          <Route path='/vehicle-type' element={<MainLayout component={<VehicleType />} />} />
          <Route path='/zone' element={<MainLayout component={<Zone />} />} />
          <Route path='/customer' element={<MainLayout component={<Customer />} />} />
          <Route path='/job-type' element={<MainLayout component={<JobType />} />} />
          <Route path='/ship-to' element={<MainLayout component={<ShipTo />} />} />
          <Route path='/picture' element={<MainLayout component={<Picture />} />} />
          <Route path='/customer-user' element={<MainLayout fullScreen={true} component={<CustomerUser />} />} />
          <Route path='/cause-reason' element={<MainLayout fullScreen={true} component={<CauseReason />} />} />
          <Route path='/rating' element={<MainLayout component={<Rating />} />} />
          <Route path='/cause-of-product' element={<MainLayout component={<CauseOfProduct />} />} />
          <Route path='/documents' element={<MainLayout component={<Document />} />} />
          <Route path='/price' element={<MainLayout component={<Price />} />} />
          <Route path='/price-model' element={<MainLayout fullScreen={true} component={<PriceModel />} />} />
          <Route path='/calendar' element={<MainLayout fullScreen={true} component={<Calendar />} />} />

          {/* USERS PERMISSTION */}
          <Route path='/user-group' element={<MainLayout contentSize="full" component={<UserGroup />} subMenuId={6} />} />
          <Route path='/users' element={<MainLayout contentSize="full" component={<Users />} subMenuId={6} />} />
          <Route path='/roles' element={<MainLayout contentSize="full" component={<Roles />} subMenuId={6} />} />
          <Route path='/mobile-log' element={<MainLayout fullScreen={true} component={<MobileLog />} />} />

          {/* ERRORS */}
          <Route path='/AccessDenied' element={<PageUnauthorized />} />
          <Route path='*' element={<PageNotFound />} />
        </Routes>
      </Router >
    )
  }
}