import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './AppRoute';
import reportWebVitals from './reportWebVitals';
import Provider from './stores';
import 'font-awesome/css/font-awesome.min.css'
import 'devextreme/dist/css/dx.light.css';
import { ConfigProvider } from 'antd';
import thTH from 'antd/locale/th_TH';
import { th_TH } from './components/Localization/th';
import { locale, loadMessages } from 'devextreme/localization';

locale("th");
loadMessages(th_TH);

ReactDOM.render(
  <Suspense fallback={null}>
    <Provider>
      <React.StrictMode>
        <ConfigProvider locale={thTH}>
          <App />
        </ConfigProvider>
      </React.StrictMode>
    </Provider>
  </Suspense>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();