import { useEffect, useState } from 'react'
import { inject, observer } from 'mobx-react';
import FormMaster from '../FormMaster'
import { CommonService } from "../../../services";
import { isEmpty } from 'lodash';

let firstRender = false
const Price = inject("authenStore")(observer((props) => {
  let authenStore = props.authenStore
  let { getAccountAuth } = authenStore // STATE
  let auth = { action_user_id: getAccountAuth?.user_id, token_id: getAccountAuth?.access_token }

  const [masterOptions, setMasterOptions] = useState({
    common_status: [],
    customer: [],
    product_unit: []
  })

  useEffect(() => {
    if (firstRender || !isEmpty(getAccountAuth)) {
      CommonService.getMasterData(auth, "common_status,customer,product_unit")
        .then((res: any) => {
          let common_status = res.result.common_status.map((item) => ({ label: item.status_name, value: item.status_code }))
          let customer = res.result.customer.map((item) => ({ label: item.cust_name, value: item.cust_id }))
          let product_unit = res.result.product_unit.map((item) => ({ label: item.unit_name, value: item.unit_id }))

          setMasterOptions({
            ...masterOptions,
            common_status,
            customer,
            product_unit
          })
        })
        .catch((err) => {
          console.log(`Load master data : ${err}`)
        })
    }
    else
      firstRender = true
  }, [getAccountAuth])

  return <FormMaster
    title="ตั้งราคาค่าขนส่ง"
    tableConfig={{
      key: "id",
      apiPath: "price/list",
      allowDelete: {
        visible: true,
        key: "price_id",
        apiPath: "price/delete",
        paramType: "param"
      },
      optionDeleteFag: true,
      defaultColumn: [],
      columns: [
        {
          dataField: 'no',
          caption: "ลำดับ",
          width: 60,
          alignment: "center",
          allowFiltering: false
        },
        {
          dataField: 'cust_name',
          caption: "ลูกค้า",
          allowFiltering: true
        },
        {
          dataField: 'price',
          caption: "ราคาต่อหน่วย"
        },
        {
          dataField: 'unit_name',
          caption: "หน่วยสินค้า",
          allowFiltering: true
        },
        {
          dataField: 'min_item',
          caption: "จำนวนต่ำสุด"
        },
        {
          dataField: 'max_item',
          caption: "จำนวนสูงสุด"
        },
        {
          dataField: 'active_date',
          caption: "วันที่บังคับใช้งาน",
          allowFiltering: true
        },
        {
          dataField: 'status_name',
          caption: "สถานะ",
          allowFiltering: true
        },
        {
          dataField: 'updated_by',
          caption: "แก้ไขโดย"
        },
        {
          dataField: 'updated_date',
          caption: "วันที่แก้ไข"
        }
      ],
      exportExcel: {
        fileName: 'ตั้งราคาค่าขนส่ง',
        columnConfig: [
          { label: 'ลำดับ', value: 'no' },
          { label: 'ลูกค้า', value: 'cust_name' },
          { label: 'ลูกค้า', value: 'price' },
          { label: 'หน่วยสินค้า', value: 'unit_name' },
          { label: 'จำนวนต่ำสุด', value: 'min_item' },
          { label: 'จำนวนสูงสุด', value: 'max_item' },
          { label: 'วันที่บังคับใช้งาน', value: 'active_date' },
          { label: 'สถานะ', value: 'status_name' },
          { label: 'แก้ไขโดย', value: 'updated_by' },
          { label: 'วันที่แก้ไข', value: 'updated_date' }
        ]
      }
    }}
    formData={{
      cust_id: "",
      price: "",
      unit_id: "",
      max_item: "",
      min_item: "",
      active_date: "",
      status: "Y"
    }}
    modalConfig={{
      screenName: "price",
      visible: true,
      title: "ราคาค่าขนส่ง",
      required: ["cust_id", "price", "unit_id", "max_item", "min_item", "active_date"],
      fieldTypeNumber: [],
      paramName: "price_id",
      apiPath: {
        "get": '',
        "post": 'price/add',
        "put": 'price/update'
      },
      elements: [
        {
          fieldName: "cust_id",
          label: "ลูกค้า",
          placeholder: "ลูกค้า",
          inputType: "select",
          required: true,
          options: masterOptions.customer,
          isOneRow: true
        },
        {
          fieldName: "price",
          label: "ราคาต่อหน่วย",
          placeholder: "ราคาต่อหน่วย",
          required: true,
          inputType: "number",
          isOneRow: true
        },
        {
          fieldName: "min_item",
          label: "จำนวนต่ำสุด",
          placeholder: "จำนวนต่ำสุด",
          required: true,
          inputType: "number",
          isOneRow: true
        },
        {
          fieldName: "max_item",
          label: "จำนวนสูงสุด",
          placeholder: "จำนวนสูงสุด",
          required: true,
          inputType: "number",
          isOneRow: true
        },
        {
          fieldName: "unit_id",
          label: "หน่วยสินค้า",
          placeholder: "หน่วยสินค้า",
          inputType: "select",
          required: true,
          options: masterOptions.product_unit,
          isOneRow: true
        },

        {
          fieldName: "active_date",
          label: "วันที่บังคับใช้งาน",
          placeholder: "วันที่บังคับใช้งาน",
          inputType: "date",
          required: true,
          isOneRow: true
        },
        {
          showSearch: false,
          fieldName: "status",
          label: "สถานะ",
          inputType: "select",
          defaultValue: "Y",
          options: masterOptions.common_status,
          required: true,
          isOneRow: true
        }
      ]
    }}
  />
}))

export default Price