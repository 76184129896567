
import { useState, useEffect } from 'react'
import { inject, observer } from 'mobx-react';
import { Card, Button, ModalAlert, Select, FormLoading, Input } from '../../../components'
import { CommonService, ReportService } from "../../../services";
import DataGrid, { Scrolling, Paging, Pager, Column } from 'devextreme-react/data-grid';
import { SearchIcon } from '@heroicons/react/outline';
import { DatePicker, FloatButton } from 'antd';
import dayjs from 'dayjs';
import moment from 'moment'
import { isEmpty } from 'lodash';

const { RangePicker } = DatePicker;
const dateFormat = 'YYYY-MM-DD';
let firstRender = false
const GeneralReport = inject("authenStore")(observer((props) => {
  let authenStore = props.authenStore
  let { getAccountAuth } = authenStore // STATE
  let auth = { action_user_id: getAccountAuth?.user_id, token_id: getAccountAuth?.access_token }

  const [masterOptions, setMasterOptions] = useState({
    report: [],
    center: [],
    queue_status: []
  })

  const [loading, setLoading] = useState({ visible: false, tip: "กำลังโหลด" })
  const defaultTable = {
    type: "html",
    url: "",
    reportName: "",
    description: "",
    reportTime: "",
    dataSource: [],
    columns: []
  }
  const [tableConfig, setTableConfig]: any = useState({
    type: "html",
    url: "",
    reportName: "",
    description: "",
    reportTime: "",
    dataSource: [],
    columns: []
  })
  const [filter, setFilter]: any = useState({
    sdate: moment().format("YYYY-MM-DD"),
    edate: moment().format("YYYY-MM-DD"),
    report_id: "",
    center_id: "",
    type: "report",
    ref_id1: "",
    status_list: []
  })
  const [optionAlert, setOptionAlert]: any = useState({
    show: false,
    action: {
      id: "",
      name: ""
    },
    type: "success",
    title: "",
    description: {
      visible: false,
      type: "",
      content: "",
      contentLists: [],

    }
  })

  useEffect(() => {
    if (firstRender || !isEmpty(getAccountAuth)) {
      CommonService.getMasterData(auth, "report,center,queue_status")
        .then((res: any) => {
          let report = res.result.report.map((item) => ({ label: item.report_name, value: item.id }))
          let center = res.result.center.map((item) => ({ label: item.ceneter_name, value: item.center_id }))
          let queue_status = res.result.queue_status.map((item) => ({ label: item.status_name, value: item.status_code }))

          setMasterOptions({
            ...masterOptions,
            report,
            center,
            queue_status
          })

          if (report.length > 0) {
            setFilter({
              ...filter,
              report_id: report[0].value
            })
          }
        })
        .catch((err) => {
          setOptionAlert({
            ...optionAlert,
            type: "danger",
            show: true,
            action: {
              id: "",
              name: ""
            },
            title: "ผิดพลาด",
            description: { visible: true, content: `Load master data : ${err}` }
          })
        })
    }
    else
      firstRender = true
  }, [getAccountAuth])

  //#region 

  const searchReports = (type) => {
    setLoading({ ...loading, visible: true })
    let data = { ...filter, type }
    const status_list = data.status_list.join(",");
    data.status_list = status_list

    ReportService.getReport(auth, data)
      .then((res: any) => {
        let { code, message, result, sys_message } = res
        if (code === 200) {
          if (["excel"].includes(type))
            window.open(result?.url)
          else
            setDynamicTable(result)
        }
        else {
          setTableConfig(defaultTable)
          setOptionAlert({
            ...optionAlert,
            type: "danger",
            show: true,
            action: {
              id: "",
              name: ""
            },
            title: "ผิดพลาด",
            description: { visible: true, type: "text", content: `${message} ${sys_message}` }
          })
        }
        setLoading({ ...loading, visible: false })
      })
      .catch((err) => {
        console.log("err  :", err)
        setLoading({ ...loading, visible: false })
        setTableConfig(defaultTable)
        setOptionAlert({
          ...optionAlert,
          type: "danger",
          show: true,
          action: {
            id: "",
            name: ""
          },
          title: "ผิดพลาด",
          description: { visible: true, type: "text", content: `${err}` }
        })
      })
  }
  //#endregion

  const setDynamicTable = (data) => {
    let { report_name, description, report_time, header, body, type, url } = data
    let columns: any = [], reportType = "html"

    if (type === "pdf")
      reportType = "pdf"
    else {
      header.forEach((column, index) => {
        columns.push({
          dataField: `${index}`,
          caption: column
        })
      });
    }
    setTableConfig({
      reportName: report_name,
      description: description,
      reportTime: report_time,
      dataSource: body,
      columns,
      type: reportType,
      url
    })
  }
  return (
    <div className="space-y-3 p-4">
      <Card title="รายงานทั่วไป">
        {/* <div className="mt-5 md:col-span-2 md:mt-0" style={{ height: 'calc(100vh - 185px)' }}> */}
        <div className="mt-5 md:col-span-2 md:mt-0">
          <FormLoading loading={loading.visible} tip={loading.tip} >
            <div className="flex flex-col mb-4 space-y-1">
              <div className="space-y-1">
                <div className="flex flex-row">
                  <div className="flex-1 sm:grid sm:grid-cols-3 sm:items-end sm:gap-2">
                    <label className="block text-xs font-medium text-gray-700 mb-1 text-right">
                      รายงาน :
                    </label>
                    <div className="mt-1 sm:col-span-2 sm:mt-0">
                      <Select
                        allowClear={false}
                        showSearch={true}
                        defaultValue={filter.report_id}
                        placeholder="รายงาน"
                        options={masterOptions.report}
                        onChange={(value) => setFilter({ ...filter, report_id: value })}
                      />
                    </div>
                  </div>

                  <div className="flex-1 sm:grid sm:grid-cols-3 sm:items-end sm:gap-2">
                    <label className="block text-xs font-medium text-gray-700 mb-1 text-right">
                      วันที่ :
                    </label>
                    <div className="mt-1 sm:col-span-2 sm:mt-0">
                      <RangePicker
                        allowClear={false}
                        defaultValue={[dayjs(filter.sdate, dateFormat), dayjs(filter.edate, dateFormat)]}
                        style={{ marginTop: 4, width: '100%' }}
                        onChange={(d: any) => {
                          if (d) {
                            setFilter({
                              ...filter,
                              sdate: d[0].format("YYYY-MM-DD"),
                              edate: d[1].format("YYYY-MM-DD")
                            })
                          }
                          else {
                            setFilter({
                              ...filter,
                              sdate: "",
                              edate: ""
                            })
                          }
                        }}
                      />
                    </div>
                  </div>

                  <div className="flex-1 sm:grid sm:grid-cols-3 sm:items-end sm:gap-2">
                    <label className="block text-xs font-medium text-gray-700 mb-1 text-right">
                      หน่วยงาน :
                    </label>
                    <div className="mt-1 sm:col-span-2 sm:mt-0">
                      <Select
                        allowClear={true}
                        showSearch={true}
                        defaultValue={filter.center_id}
                        placeholder="หน่วยงาน"
                        options={masterOptions.center}
                        onChange={(value) => setFilter({ ...filter, center_id: value })}
                      />
                    </div>
                  </div>
                </div>

                <div className="flex flex-row">
                  <div className="flex-1 sm:grid sm:grid-cols-3 sm:items-end sm:gap-2">
                    <label className="block text-xs font-medium text-gray-700 mb-1 text-right">
                      เลขที่ใบสั่งงาน/แผนงาน :
                    </label>
                    <div className="mt-1 sm:col-span-2 sm:mt-0">
                      <Input
                        label="เลขที่ใบสั่งงาน/แผนงาน"
                        placeholder="เลขที่ใบสั่งงาน/แผนงาน"
                        hiddenLabel={true}
                        onChange={(e) => setFilter({ ...filter, ref_id1: e.target.value })}
                      />
                    </div>
                  </div>

                  <div className="flex-1 sm:grid sm:grid-cols-3 sm:items-end sm:gap-2">
                    <label className="block text-xs font-medium text-gray-700 mb-1 text-right">
                      สถานะ :
                    </label>
                    <div className="mt-1 sm:col-span-2 sm:mt-0">
                      <Select
                        allowClear={true}
                        showSearch={true}
                        multiple={true}
                        defaultValue={filter.status_list}
                        placeholder="สถานะ"
                        options={masterOptions.queue_status}
                        onChange={(value) => setFilter({ ...filter, status_list: value })}
                      />
                    </div>
                  </div>

                  <div className="flex-1 sm:grid sm:grid-cols-3 sm:items-end sm:gap-2">
                    <label className="block text-xs font-medium text-gray-700 mb-1 text-right">
                    </label>
                    <div className="flex flex-row mt-1 sm:col-span-2 sm:mt-0 space-x-2">
                      <button
                        type="button"
                        title="ค้นหา"
                        className={`flex flex-row ${loading.visible && 'cursor-not-allowed'} items-center rounded border border-transparent bg-blue-100 mt-0.5 px-2.5 py-1.5 text-xs font-medium text-blue-700 hover:bg-blue-200`}
                        onClick={() => searchReports("html")}>
                        <div className="flex flex-row-reverse">
                          <span>ค้นหา</span>
                          <SearchIcon className="w-4 h-4 mr-1" />
                        </div>
                      </button>

                      <Button
                        text="Export Excel"
                        iconOnly={<img className="h-4 w-4" src="/Images/excel.png" />}
                        type="white"
                        onClick={() => searchReports("excel")}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className='mt-6'>
              <div className="flex flex-row">
                <div className="flex-1 flex flex-col">
                  <span className="text-sm font-semibold">
                    {tableConfig?.reportName}
                  </span>
                  <span className="flex-1 flex-row text-sm">  {tableConfig?.description}</span>
                </div>

                <div className="flex-1 flex flex-row-reverse">
                  <span className="text-sm font-semibold">
                    {tableConfig?.reportTime}
                  </span>
                </div>
              </div>
              {
                tableConfig.type === "html" ?
                  <DataGrid
                    dataSource={tableConfig?.dataSource}
                    keyExpr="0"
                    showBorders={true}
                    columnAutoWidth={true}
                    hoverStateEnabled={true}
                    height={'calc(100vh - 285px)'}
                  >
                    <Scrolling columnRenderingMode="virtual" />
                    <Paging defaultPageSize={20} />
                    <Pager
                      showInfo={true}
                      showPageSizeSelector={true}
                      allowedPageSizes={[10, 20, 50]}
                    />
                    {
                      tableConfig?.columns.map((column) => <Column
                        key={column.dataField}
                        dataField={column.dataField}
                        caption={column.caption}
                        alignment={column.alignment}
                        cellRender={column.cellRender}
                      />)
                    }
                  </DataGrid>
                  : <object data={tableConfig.url} width="100%" height="842">
                  </object>
              }
            </div>
          </FormLoading>
        </div>
      </Card >

      <ModalAlert
        {...optionAlert}
        onOk={() => {
          setOptionAlert({ ...optionAlert, show: false })
        }}
        onCancel={() => {
          setOptionAlert({ ...optionAlert, show: false })
        }}
      />

      <FloatButton.BackTop type="primary" />
    </div>
  )
}))

export default GeneralReport