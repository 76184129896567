
import { useEffect, useState, useRef } from "react";
import { inject, observer } from 'mobx-react';
import { Button, Select, Modal, FormLoading, InputTextarea, Input, ModalAlert, DatePicker, Card, InputNumber } from '../../components'
import { createMultipleRef, getValueInputRef } from '../../helpers'
import { PlusIcon } from "@heroicons/react/solid";
import DataGrid, { Paging, Column } from 'devextreme-react/data-grid';
import './style.css'
import { CommonService, QueueService } from "../../services";
import ModalPriceSet from "./ModalPriceSet";
import { isEmpty, get } from 'lodash'

let firstRender = false
const ModalQueue = inject("routeStore", "authenStore")(observer((props) => {
  let routeStore = props.routeStore
  let authenStore = props.authenStore
  let { getAccountAuth } = authenStore // STATE
  let auth = { action_user_id: getAccountAuth?.user_id, token_id: getAccountAuth?.access_token }

  let { getDefaultDeliveryDate } = routeStore // ACTIONS
  let { open, onClosed, mode, dataEdit, action, actionBy, onReloadQueueCurrent } = props
  const gridRef: any = useRef(null);
  const [loading, setLoading] = useState({ visible: false, tip: "กำลังโหลด" })
  const [validation, setValidation]: any = useState({})
  const [shakeIsWrong, setShakeIsWrong] = useState(false)
  const [openModalPrice, setOpenModalPrice] = useState(false)
  const [modalInfo, setModalInfo]: any = useState({
    open: false,
    title: "",
    name: "",
    icon: ''
  })
  const [optionAlert, setOptionAlert]: any = useState({
    show: false,
    action: {
      id: "",
      name: ""
    },
    type: "success",
    title: "",
    description: {
      visible: false,
      type: "",
      content: "",
      contentLists: [],

    }
  })
  const [dataForm, setDataForm]: any = useState({
    "id": "",
    "qno": "",
    "cust_id": "",
    "logistic_type_id": "",
    "bill_cust_id": "",
    "job_type_id": "",
    "loc_receipt_id": "",
    "loc_dest_id": "",
    "delivery_date": "",
    "remark": "",
    "po_no": "",
    "gq_no": "",
    "center_id": "",
    "shipment_type_code": "",
    "products": [],
    "price_set": {
      "price_set_id": "",
      "price_set_value": "",
      "price_set_name": ""
    },
    "ref_order_no": "",
    "track_no": ""
  })

  const [productForm, setProductForm]: any = useState({
    "open": false,
    "type": "insert",
    "product_code": "",
    "product_id": "",
    "unit_id": "",
    "product_qty": "",
    "product_name": "",
    "unit_name": "",
    "price_set_id": "",
    "price_set_value": "",
    "price_set_unit_value": "",
    "gq_no": ""
  })
  const [priceSum, setPriceSum] = useState("")

  const [masterOptions, setMasterOptions]: any = useState({
    customer: [],
    shipto: [],
    job_type: [],
    product: [],
    bill_cust: [],
    service: [],
    vehicle: [],
    shipping_point: [],
    product_unit: [],
    logistic_type: [],
    center: [],
    shipment_type: [],
    province: []
  })

  const inputRef: any = useRef([]);
  createMultipleRef(inputRef, 5)

  useEffect(() => {
    if (action === "create") {
      setDataForm({
        ...dataForm,
        delivery_date: getDefaultDeliveryDate
      })
    }
  }, [getDefaultDeliveryDate])

  useEffect(() => {
    if (action === "create") {
      setDataForm({
        ...dataForm,
        delivery_date: getDefaultDeliveryDate
      })
    }
  }, [open])

  useEffect(() => {
    if (!isEmpty(dataEdit)) {
      if (dataEdit.actionBy === "queue") {
        let products = dataEdit.products.map((item, idx) => ({ ...item, id: (idx + 1) }))
        setDataFormInfo(dataEdit, products)
      }
      else {
        getQueueInfo(dataEdit?.q_id, dataEdit?.delivery_date)
      }
    }
  }, [dataEdit, open])

  useEffect(() => {
    if (firstRender || !isEmpty(getAccountAuth)) {
      CommonService.getMasterData(auth, "customer,shipping_point,shipto,job_type,bill_cust,service,vehicle,product_unit,logistic_type,center,shipment_type,admin_level1")
        .then((res: any) => {
          let customer = res.result.customer.map((item) => ({ label: item.cust_name, value: item.cust_id }))
          let shipto = res.result.shipto.map((item) => ({ label: item.shipto_name, value: item.shipto_id }))
          let job_type = res.result.job_type.map((item) => ({ label: item.job_Type_name, value: item.job_type_id }))
          let bill_cust = res.result.bill_cust.map((item) => ({ label: item.bill_cust_name, value: item.bill_cust_id }))
          let service = res.result.service.map((item) => ({ label: item.service_name, value: item.service_id }))
          let vehicle = res.result.vehicle.map((item) => ({ label: item.licenseplate, value: item.vehicle_id }))
          let shipping_point = res.result.shipping_point.map((item) => ({ label: item.shipping_name, value: item.shipping_id }))
          let product_unit = res.result.product_unit.map((item) => ({ label: item.unit_name, value: item.unit_id }))
          let logistic_type = res.result.logistic_type.map((item) => ({ label: item.logistic_type_name, value: item.logistic_type_id }))
          let center = res.result.center.map((item) => ({ label: item.ceneter_name, value: item.center_id }))
          let shipment_type = res.result.shipment_type.map((item) => ({ label: item.desc, value: item.code }))
          let province = res.result.admin_level1.map((item) => ({ label: item.admin_level1_name, value: item.admin_level1_id }))

          setMasterOptions({
            ...masterOptions,
            customer,
            shipto,
            job_type,
            bill_cust,
            service,
            vehicle,
            shipping_point,
            product_unit,
            logistic_type,
            center,
            shipment_type,
            province
          })
        })
        .catch((err) => {
          console.log("ERROR : getMasterData : ", err)
        })
    }
    else
      firstRender = true
  }, [getAccountAuth])

  const getQueueInfo = (qid = "", delivery_date = "") => {
    setLoading({ ...loading, visible: true, tip: 'กำลังโหลด...' })
    QueueService.getQueue(auth, {
      delivery_date,
      qid
    })
      .then((res: any) => {
        let { code, message, sys_message, result } = res
        if (code === 200) {
          if (result.length > 0) {
            let data = result[0]
            let products = data.products.map((item, idx) => ({ ...item, id: (idx + 1) }))
            setDataFormInfo(data, products)
          }
        }
        else {
          setOptionAlert({
            ...optionAlert,
            type: "danger",
            show: true,
            action: {
              id: "",
              name: ""
            },
            title: "ผิดพลาด",
            description: { visible: true, type: "text", content: `${message} ${sys_message}` }
          })
        }
        setLoading({ ...loading, visible: false, tip: 'กำลังโหลด...' })
      })
      .catch((err) => {
        setLoading({ ...loading, visible: false, tip: 'กำลังโหลด...' })
        setOptionAlert({
          ...optionAlert,
          type: "danger",
          show: true,
          action: {
            id: "",
            name: ""
          },
          title: "ผิดพลาด",
          description: { visible: true, type: "text", content: `${err}` }
        })
      })
  }

  const setDataFormInfo = (data, products) => {
    setDataForm({
      ...dataForm,
      id: data.id,
      qno: data.qno,
      po_no: data.po_no,
      gq_no: data.gq_no,
      cust_id: data.cust_id,
      logistic_type_id: data.logistic_type_id,
      bill_cust_id: data.bill_cust_id,
      job_type_id: data.job_type_id,
      loc_receipt_id: data.loc_receipt_id,
      loc_dest_id: data.loc_dest_id,
      delivery_date: data.delivery_date,
      remark: data.remark,
      center_id: data.center_id,
      shipment_type_code: data.shipment_type_code,
      price_set: {
        "price_set_id": data.price_set_id,
        "price_set_value": data.price_set_value,
        "price_set_name": data.price_set_name || ""
      },
      products,
      ref_order_no: data.ref_order_no,
      track_no: data.track_no
    })

    if (dataEdit.logistic_type_id === 2) calPriceSum(products)

    getProductByCust(dataEdit.cust_id, false)
  }

  const getProductByCust = (cust_id = "", isClear = true) => {
    isClear && setDataForm({ ...dataForm, cust_id: "" })
    CommonService.getMasterData(auth, "product", cust_id)
      .then((res: any) => {
        let product = res.result.product.map((item) => ({ label: item.product_name, value: item.product_id, product_code: item.product_code }))
        setMasterOptions({
          ...masterOptions,
          product
        })
      })
      .catch((err) => {
        console.log("ERROR : getMasterData : ", err)
      })
  }

  const initialData = () => {
    setValidation({})
    setDataForm({
      "id": "",
      "qno": "",
      "cust_id": "",
      "logistic_type_id": "",
      "bill_cust_id": "",
      "job_type_id": "",
      "loc_receipt_id": "",
      "loc_dest_id": "",
      "delivery_date": "",
      "remark": "",
      "po_no": "",
      "gq_no": "",
      "center_id": "",
      "shipment_type_code": "",
      "products": [],
      "price_set": {
        "price_set_id": "",
        "price_set_value": "",
        "price_set_name": ""
      },
      "ref_order_no": "",
      "track_no": ""
    })
    clearProductForm()
  }

  const clearProductForm = () => {
    setProductForm({
      "open": false,
      "type": "insert",
      "product_code": "",
      "product_id": "",
      "unit_id": "",
      "product_qty": "",
      "product_name": "",
      "unit_name": "",
      "price_set_id": "",
      "price_set_value": "",
      "gq_no": ""
    })
  }

  const onSave = () => {
    let formData = getValueInputRef(inputRef)
    let validateCheck = {}, message = { isError: true, message: "โปรดระบุ" }

    if (formData.gq_no === "")
      validateCheck['gq_no'] = message
    if (dataForm.delivery_date.trim() === "")
      validateCheck['delivery_date'] = message
    if (dataForm.cust_id.length === 0 || dataForm.cust_id === "")
      validateCheck['cust_id'] = message
    if (dataForm.loc_receipt_id.length === 0 || dataForm.loc_receipt_id === "")
      validateCheck['loc_receipt_id'] = message
    if (dataForm.center_id.length === 0 || dataForm.center_id === "")
      validateCheck['center_id'] = message
    if (dataForm.shipment_type_code.length === 0 || dataForm.shipment_type_code === "")
      validateCheck['shipment_type_code'] = message
    if (dataForm.loc_dest_id.length === 0 || dataForm.loc_dest_id === "")
      validateCheck['loc_dest_id'] = message
    if (dataForm.job_type_id.length === 0 || dataForm.job_type_id === "")
      validateCheck['job_type_id'] = message
    if (dataForm.bill_cust_id.length === 0 || dataForm.bill_cust_id === "")
      validateCheck['bill_cust_id'] = message
    if (dataForm.logistic_type_id.length === 0 || dataForm.logistic_type_id === "")
      validateCheck['logistic_type_id'] = message
    if (dataForm.products.length === 0)
      validateCheck['product_list'] = message

    if (isEmpty(validateCheck)) {
      setValidation({})
      setOptionAlert({
        ...optionAlert,
        action: {
          id: dataForm.id,
          name: dataForm.id === "" ? 'create' : 'update'
        },
        type: "confirm",
        title: "ยืนยัน",
        show: true,
        description: {
          visible: true,
          type: "text",
          content: "คุณแน่ใจที่จะบันทึกข้อมูล ?",
          contentLists: []
        }
      })
    }
    else {
      setValidation(validateCheck)
      setShakeIsWrong(true)
      setTimeout(() => setShakeIsWrong(false), 100);
    }
  }

  const onCancel = () => {
    setOptionAlert({
      ...optionAlert,
      action: {
        id: dataForm.id,
        name: "cancel"
      },
      type: "confirm",
      title: "ยืนยัน",
      show: true,
      description: {
        visible: true,
        type: "text",
        content: "คุณแน่ใจที่จะยกเลิกใบสั่งงาน ?",
        contentLists: []
      }
    })
  }

  const calPriceSum = (products) => {
    let sum = 0
    products.forEach(product => {
      sum += product?.price_set_value
    });
    setPriceSum(`${sum}`)
  }

  const onSaveProduction = () => {
    let { type, product_code, product_id, product_name, unit_id, unit_name, product_qty, price_set_id, price_set_value, price_set_unit_value, gq_no } = productForm
    let validateCheck = {}, message = { isError: true, message: "โปรดระบุ" }

    if (product_id.length === 0 || product_id === "")
      validateCheck['product_id'] = message
    if (unit_id.length === 0 || unit_id === "")
      validateCheck['unit_id'] = message
    if (product_qty === "")
      validateCheck['product_qty'] = message

    if (dataForm.cust_id !== "" && dataForm.logistic_type_id === 2 && dataForm.loc_dest_id !== "") {
      if (price_set_value === "" || price_set_value === null)
        validateCheck['price_set_value'] = message
    }

    if (isEmpty(validateCheck)) {
      setValidation({})
      let products = [...dataForm.products]
      if (type === "insert") {
        let id = (products.length + 1)
        products.push(
          {
            "product_code": product_code,
            "product_id": product_id,
            "unit_id": unit_id,
            "product_qty": product_qty,
            "product_name": product_name,
            "unit_name": unit_name,
            "price_set_id": price_set_id,
            "price_set_unit_value": price_set_unit_value,
            "price_set_value": price_set_value * product_qty,
            "gq_no": gq_no,
            "id": id
          })
      }
      else {
        let index = products.findIndex((product) => product.id === productForm.id)
        if (index >= 0) {
          products[index].product_code = product_code
          products[index].product_id = product_id
          products[index].unit_id = unit_id
          products[index].unit_name = unit_name
          products[index].product_qty = product_qty
          products[index].product_name = product_name
          products[index].price_set_id = price_set_id
          products[index].price_set_unit_value = price_set_unit_value
          products[index].price_set_value = price_set_value * product_qty
          products[index].gq_no = gq_no
        }
      }
      calPriceSum(products)
      clearProductForm()
      setDataForm({ ...dataForm, products })
    }
    else {
      setValidation(validateCheck)
      setShakeIsWrong(true)
      setTimeout(() => setShakeIsWrong(false), 100);
    }
  }

  const saveData = () => {
    let formData = getValueInputRef(inputRef)

    setLoading({ ...loading, visible: true, tip: 'กำลังบันทึก...' })
    let body: any = {
      cust_id: dataForm.cust_id,
      po_no: formData.po_no,
      gq_no: formData.gq_no,
      logistic_type_id: dataForm.logistic_type_id,
      bill_cust_id: dataForm.bill_cust_id,
      job_type_id: dataForm.job_type_id,
      loc_receipt_id: dataForm.loc_receipt_id,
      loc_dest_id: dataForm.loc_dest_id,
      delivery_date: dataForm.delivery_date,
      remark: formData.remark,
      center_id: dataForm.center_id,
      shipment_type: dataForm.shipment_type_code,
      price_set_id: dataForm.price_set.price_set_id,
      price_set_value: dataForm.price_set.price_set_value,
      products: [],
      ref_order_no: formData.ref_order_no,
      track_no: formData.track_no
    }

    let products = dataForm.products.map((item) => (
      {
        price_set_id: item.price_set_id || "",
        price_set_value: item.price_set_value || "",
        price_set_unit_value: item.price_set_unit_value || "",
        product_id: item.product_id,
        qty: item.product_qty,
        unit_id: item.unit_id,
        gq_no: item.gq_no
      }
    ))

    body.products = products

    if (optionAlert.action.name === "update") body.q_id = dataForm.id
    if (optionAlert.action.name === "create") {
      QueueService.addQueue(auth, body)
        .then((res: any) => {
          let { code, message, sys_message } = res.data
          if (code === 200) {
            setOptionAlert({
              ...optionAlert,
              type: "success",
              show: true,
              action: {
                id: "",
                name: ""
              },
              title: "บันทึกข้อมูลสำเร็จ",
              description: { visible: false, type: "text", content: " ", contentLists: [] }
            })
            onClosed(true, dataForm.delivery_date)
            initialData()
          }
          else {
            setOptionAlert({
              ...optionAlert,
              type: "danger",
              show: true,
              action: {
                id: "",
                name: ""
              },
              title: "ผิดพลาด",
              description: { visible: true, type: "text", content: `${message} ${sys_message}` }
            })
          }
          setLoading({ ...loading, visible: false })
        })
        .catch((err) => {
          setOptionAlert({
            ...optionAlert,
            type: "danger",
            show: true,
            action: {
              id: "",
              name: ""
            },
            title: "ผิดพลาด",
            description: { visible: true, type: "text", content: `${err}` }
          })
          setLoading({ ...loading, visible: false })
        })
    }
    else {
      QueueService.updateQueue(auth, body)
        .then((res: any) => {
          let { code, message, sys_message } = res?.data
          if (code === 200) {
            setOptionAlert({
              ...optionAlert,
              type: "success",
              show: true,
              action: {
                id: "",
                name: ""
              },
              title: "บันทึกข้อมูลสำเร็จ",
              description: { visible: false, type: "text", content: " ", contentLists: [] }
            })
            onReloadQueueCurrent?.()
            onClosed(true, dataForm.delivery_date)
            initialData()
          }
          else {
            setOptionAlert({
              ...optionAlert,
              type: "danger",
              show: true,
              action: {
                id: "",
                name: ""
              },
              title: "ผิดพลาด",
              description: { visible: true, type: "text", content: `${message} ${sys_message}` }
            })
          }
          setLoading({ ...loading, visible: false })
        })
        .catch((err) => {
          setOptionAlert({
            ...optionAlert,
            type: "danger",
            show: true,
            action: {
              id: "",
              name: ""
            },
            title: "ผิดพลาด",
            description: { visible: true, type: "text", content: `${err}` }
          })
          setLoading({ ...loading, visible: false })
        })
    }
  }

  const cancelQueue = (qid) => {
    setLoading({ ...loading, visible: true, tip: 'กำลังบันทึก...' })
    QueueService.cancelQueue(auth, qid)
      .then((res: any) => {
        let { code, message, sys_message } = res.data
        if (code === 200) {
          setOptionAlert({
            ...optionAlert,
            type: "success",
            show: true,
            action: {
              id: "",
              name: ""
            },
            title: "ยกเลิกสำเร็จ",
            description: { visible: false, type: "text", content: " ", contentLists: [] }
          })
          onReloadQueueCurrent?.()
          onClosed(true, dataForm.delivery_date)
          initialData()
        }
        else {
          setOptionAlert({
            ...optionAlert,
            type: "danger",
            show: true,
            action: {
              id: "",
              name: ""
            },
            title: "ผิดพลาด",
            description: { visible: true, type: "text", content: `${message} ${sys_message}` }
          })
        }
        setLoading({ ...loading, visible: false })
      })
      .catch((err) => {
        setOptionAlert({
          ...optionAlert,
          type: "danger",
          show: true,
          action: {
            id: "",
            name: ""
          },
          title: "ผิดพลาด",
          description: { visible: true, type: "text", content: `${err}` }
        })
        setLoading({ ...loading, visible: false })
      })
  }

  const labelError = (fieldName) => {
    if (get(validation, fieldName)) {
      if (validation[fieldName].isError)
        return <p className="text-xs italic text-danger-600">
          {validation[fieldName].message}
        </p>
      else
        return null
    }
  }

  return (<>
    <Modal
      title={{ text: mode === 'add' ? "เพิ่มใบส่งสินค้า" : "แก้ไขใบส่งสินค้า" }}
      open={open}
      maskClosable={true}
      width={"w-3/5"}
      loading={false}
      shake={shakeIsWrong}
      onClosed={() => {

      }}
      icon={modalInfo.icon}
      onOk={() => { }}
      footer={<div className="flex flex-row w-full">
        <div className="flex-1 text-left">
          {
            dataEdit.actionPage === "plan" && mode === "edit" && <Button
              text="ยกเลิกใบสั่งงาน"
              width="w-26"
              type="danger"
              onClick={() => onCancel()}
            />
          }

        </div>
        <div className="flex-1 flex flex-row-reverse">
          <Button
            text="บันทึก"
            width="w-20"
            type="primary"
            onClick={() => onSave()}
          />

          <Button
            text="ปิด"
            width="w-20"
            type="white"
            onClick={() => {
              initialData()
              onClosed()
            }}
          />
        </div>
      </div>}
    >
      <FormLoading loading={loading.visible} tip={loading.tip}>
        <div className="flex flex-col -my-4 lg:space-y-0 sm:space-y-2">
          <div className="flex lg:flex-row  sm:flex-col sm:space-y-2">
            <div className="flex-1 sm:grid sm:grid-cols-3 sm:items-end sm:gap-2">
              <label className="block text-xs font-medium text-gray-700 mb-1 text-right">
                เลขที่ใบส่งสินค้า :
              </label>
              <div className="mt-1 sm:col-span-2 sm:mt-0">
                <Input
                  id="first_name"
                  label="เลขที่ใบส่งสินค้า"
                  placeholder="เลขที่ใบส่งสินค้า"
                  hiddenLabel={true}
                  disabled={true}
                  defaultValue={dataForm.qno}
                />
              </div>
            </div>

            <div className="flex-1 sm:grid sm:grid-cols-3 sm:items-end sm:gap-2">
              <label className="block text-xs font-medium text-gray-700 mb-1 text-right">
                วันที่จัดส่ง<span className="text-red-500">* </span> :{labelError("delivery_date")}
              </label>
              <div className="mt-1 sm:col-span-2 sm:mt-0">
                <DatePicker
                  id="delivery_date"
                  placeholder="วันที่จัดส่ง"
                  allowClear={false}
                  hiddenLabel={true}
                  defaultValue={dataForm.delivery_date}
                  validate={validation['delivery_date']}
                  onChange={(d) => {
                    if (d)
                      setDataForm({ ...dataForm, delivery_date: d.format("YYYY-MM-DD") })
                    else
                      setDataForm({ ...dataForm, delivery_date: "" })
                  }}
                />
              </div>
            </div>
          </div>

          <div className="flex lg:flex-row  sm:flex-col sm:space-y-2">
            <div className="flex-1 sm:grid sm:grid-cols-3 sm:items-end sm:gap-2">
              <label className="block text-xs font-medium text-gray-700 mb-1 text-right">
                SO No.
              </label>
              <div className="mt-1 sm:col-span-2 sm:mt-0">
                <Input
                  id="po_no"
                  label="SO No."
                  placeholder="SO No."
                  hiddenLabel={true}
                  defaultValue={dataForm.po_no}
                  onRef={(ref) => inputRef.current[0] = ref}
                />
              </div>
            </div>

            <div className="flex-1 sm:grid sm:grid-cols-3 sm:items-end sm:gap-2">
              <label className="block text-xs font-medium text-gray-700 mb-1 text-right">
                D/O No.<span className="text-red-500">* </span> :{labelError("gq_no")}
              </label>
              <div className="mt-1 sm:col-span-2 sm:mt-0">
                <Input
                  id="gq_no"
                  label="D/O No"
                  placeholder="D/O No"
                  hiddenLabel={true}
                  defaultValue={dataForm.gq_no}
                  validate={validation?.gq_no}
                  onRef={(ref) => inputRef.current[1] = ref}
                />
              </div>
            </div>
          </div>

          <div className="flex lg:flex-row sm:flex-col sm:space-y-2">
            <div className="flex-1 sm:grid sm:grid-cols-3 sm:items-end sm:gap-2">
              <label className="block text-xs font-medium text-gray-700 mb-1 text-right">
                คลัง/ต้นทาง<span className="text-red-500">* </span> :{labelError("loc_receipt_id")}
              </label>
              <div className="mt-1 sm:col-span-2 sm:mt-0">
                <Select
                  showSearch={true}
                  hiddenLabel={true}
                  defaultValue={dataForm.loc_receipt_id}
                  placeholder="คลัง/ต้นทาง"
                  validate={validation['loc_receipt_id']}
                  options={masterOptions.shipping_point}
                  onChange={(value) => setDataForm({ ...dataForm, loc_receipt_id: value })}
                />
              </div>
            </div>

            <div className="flex-1 sm:grid sm:grid-cols-3 sm:items-end sm:gap-2">
              <label className="block text-xs font-medium text-gray-700 mb-1 text-right">
                สาขา/ปลายทาง<span className="text-red-500">* </span> :{labelError("loc_dest_id")}
              </label>
              <div className="mt-1 sm:col-span-2 sm:mt-0">
                <Select
                  showSearch={true}
                  hiddenLabel={true}
                  defaultValue={dataForm.loc_dest_id}
                  placeholder="สาขา/ปลายทาง"
                  validate={validation['loc_dest_id']}
                  options={masterOptions.shipto}
                  onChange={(value) => setDataForm({ ...dataForm, loc_dest_id: value })}
                />
              </div>
            </div>
          </div>

          <div className="flex lg:flex-row sm:flex-col sm:space-y-2">
            <div className="flex-1 sm:grid sm:grid-cols-3 sm:items-end sm:gap-2">
              <label className="block text-xs font-medium text-gray-700 mb-1 text-right">
                ลูกค้า<span className="text-red-500">* </span> :{labelError("cust_id")}
              </label>
              <div className="mt-1 sm:col-span-2 sm:mt-0">
                <Select
                  showSearch={true}
                  hiddenLabel={true}
                  defaultValue={dataForm.cust_id}
                  placeholder="ลูกค้า"
                  validate={validation['cust_id']}
                  options={masterOptions.customer}
                  onChange={(value) => {
                    getProductByCust(value)
                    setDataForm({ ...dataForm, cust_id: value })
                  }}
                />
              </div>
            </div>

            <div className="flex-1 sm:grid sm:grid-cols-3 sm:items-end sm:gap-2">
              <label className="block text-xs font-medium text-gray-700 mb-1 text-right">
                บิลลูกค้า<span className="text-red-500">* </span> :{labelError("bill_cust_id")}
              </label>
              <div className="mt-1 sm:col-span-2 sm:mt-0">
                <Select
                  showSearch={true}
                  hiddenLabel={true}
                  defaultValue={dataForm.bill_cust_id}
                  placeholder="บิลลูกค้า"
                  validate={validation['bill_cust_id']}
                  options={masterOptions.bill_cust}
                  onChange={(value) => setDataForm({ ...dataForm, bill_cust_id: value })}
                />
              </div>
            </div>
          </div>

          <div className="flex lg:flex-row sm:flex-col sm:space-y-2">
            <div className="flex-1 sm:grid sm:grid-cols-3 sm:items-end sm:gap-2">
              <label className="block text-xs font-medium text-gray-700 mb-1 text-right">
                หน่วยงาน<span className="text-red-500">* </span> :{labelError("center_id")}
              </label>
              <div className="mt-1 sm:col-span-2 sm:mt-0">
                <Select
                  showSearch={true}
                  hiddenLabel={true}
                  defaultValue={dataForm.center_id}
                  placeholder="หน่วยงาน"
                  validate={validation['center_id']}
                  options={masterOptions.center}
                  onChange={(value) => setDataForm({ ...dataForm, center_id: value })}
                />
              </div>
            </div>

            <div className="flex-1 sm:grid sm:grid-cols-3 sm:items-end sm:gap-2">
              <label className="block text-xs font-medium text-gray-700 mb-1 text-right">
                ประเภทเที่ยว<span className="text-red-500">* </span> :{labelError("shipment_type_code")}
              </label>
              <div className="mt-1 sm:col-span-2 sm:mt-0">
                <Select
                  showSearch={true}
                  hiddenLabel={true}
                  defaultValue={dataForm.shipment_type_code}
                  placeholder="ประเภทเที่ยว"
                  validate={validation['shipment_type_code']}
                  options={masterOptions.shipment_type}
                  onChange={(value) => setDataForm({ ...dataForm, shipment_type_code: value })}
                />
              </div>
            </div>
          </div>

          <div className="flex lg:flex-row sm:flex-col sm:space-y-2">
            <div className="flex-1 sm:grid sm:grid-cols-3 sm:items-end sm:gap-2">
              <label className="block text-xs font-medium text-gray-700 mb-1 text-right">
                ประเภทงาน<span className="text-red-500">* </span> :{labelError("job_type_id")}
              </label>
              <div className="mt-1 sm:col-span-2 sm:mt-0">
                <Select
                  showSearch={true}
                  hiddenLabel={true}
                  defaultValue={dataForm.job_type_id}
                  placeholder="ประเภทงาน"
                  validate={validation['job_type_id']}
                  options={masterOptions.job_type}
                  onChange={(value) => setDataForm({ ...dataForm, job_type_id: value })}
                />
              </div>
            </div>

            <div className="flex-1 sm:grid sm:grid-cols-3 sm:items-end sm:gap-2">
              <label className="block text-xs font-medium text-gray-700 mb-1 text-right">
                ประเภทการขนส่ง<span className="text-red-500">* </span> :{labelError("job_type_id")}
              </label>
              <div className="mt-1 sm:col-span-2 sm:mt-0">
                <Select
                  showSearch={true}
                  hiddenLabel={true}
                  defaultValue={dataForm.logistic_type_id}
                  placeholder="ประเภทการขนส่ง"
                  validate={validation['logistic_type_id']}
                  options={masterOptions.logistic_type}
                  onChange={(value) => setDataForm({ ...dataForm, logistic_type_id: value })}
                />
              </div>
            </div>
          </div>


          <div className="flex lg:flex-row  sm:flex-col sm:space-y-2">
            <div className="flex-1 sm:grid sm:grid-cols-3 sm:items-end sm:gap-2">
              <label className="block text-xs font-medium text-gray-700 mb-1 text-right">
                Ref Order No :
              </label>
              <div className="mt-1 sm:col-span-2 sm:mt-0">
                <Input
                  id="ref_order_no"
                  label="Ref Order No"
                  placeholder="Ref Order No"
                  hiddenLabel={true}
                  defaultValue={dataForm.ref_order_no}
                  onRef={(ref) => inputRef.current[2] = ref}
                  maxLength={30}
                />
              </div>
            </div>

            <div className="flex-1 sm:grid sm:grid-cols-3 sm:items-end sm:gap-2">
              <label className="block text-xs font-medium text-gray-700 mb-1 text-right">
                Track No :
              </label>
              <div className="mt-1 sm:col-span-2 sm:mt-0">
                <Input
                  id="track_no"
                  label="Track No"
                  placeholder="Track No"
                  hiddenLabel={true}
                  defaultValue={dataForm.track_no}
                  onRef={(ref) => inputRef.current[3] = ref}
                  maxLength={30}
                />
              </div>
            </div>
          </div>

          {
            dataForm.cust_id !== "" && dataForm.logistic_type_id === 1 && dataForm.loc_dest_id !== "" && <div className="flex lg:flex-row sm:flex-col sm:space-y-2 pt-2">
              <div className="flex-1 sm:grid sm:grid-cols-3 sm:items-end sm:gap-2">
                <label className="block text-xs font-medium text-gray-700 mb-1 text-right" >
                  {/* ราคาค่าขนส่ง<span className="text-red-500">* </span> :{labelError("job_type_id")} */}
                  ราคาค่าขนส่ง
                </label>
                <div className="mt-1 sm:col-span-2 sm:mt-0">
                  <div className="flex flex-row">
                    <div className="w-full h-8 mt-1 rounded-md border-gray-300 text-gray-900 font-medium  text-sm bg-gray-50 p-2" style={{ border: '1px solid #d9d9d9' }}>{dataForm.price_set.price_set_value}</div>

                    <Button
                      text="เลือกราคา"
                      width="w-20"
                      type="primary"
                      className="mt-1 ml-1"
                      onClick={() => setOpenModalPrice(true)}
                    />
                  </div>
                </div>
              </div>
              <div className="flex-1  sm:gap-2" >
                {/* {
                  dataForm.price_set.price_set_name !== "" &&
                  <span className="inline-flex rounded bg-blue-100 px-2.5 py-0.5 text-sm font-medium text-blue-800">
                    {dataForm.price_set.price_set_name}
                  </span>
                } */}
              </div>
            </div>
          }

          <div className="flex lg:flex-row  sm:flex-col sm:space-y-2 pt-2">
            <div className="flex-1 sm:grid sm:grid-cols-3 sm:items-end sm:gap-2">
              <label className="block text-xs font-medium text-gray-700 mb-1 text-right">
                รายละเอียดสินค้า<span className="text-red-500">* </span> :{labelError("product_list")}
              </label>
              <div className="mt-1 sm:col-span-2 sm:mt-0">
                <button
                  type="button"
                  className="-mb-1 inline-flex items-center px-1.5 py-1.5 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50"
                  title="Refresh"
                  onClick={() => setProductForm({ ...productForm, open: true, type: 'insert' })}
                >
                  <PlusIcon className="w-4 h-4" />
                </button>
              </div>
            </div>
            <div className="flex-1 sm:grid sm:grid-cols-3 sm:items-end sm:gap-2" />
          </div>

          <div className="flex lg:flex-row sm:flex-col sm:space-y-2 pt-2">
            <div className="flex-1 sm:grid sm:grid-cols-6 sm:items-end sm:gap-2">
              <label className="block text-xs font-medium text-gray-700 mb-1 text-right"></label>
              <div className="mt-1 sm:col-span-5 sm:mt-0">
                {
                  productForm.open ?
                    <Card
                      title={`${productForm.type === "insert" ? 'เพิ่ม' : 'แก้ไข'}รายละเอียดสินค้า`}
                      contentWithoutPadding={true}
                      footer={{
                        visible: true,
                        flexCustom: true,
                        children: <div className='flex justify-end' style={{ height: 30 }} >
                          <Button
                            text="ปิด"
                            width="w-16"
                            type="white"
                            onClick={() => clearProductForm()}
                          />

                          <Button
                            text="ตกลง"
                            width="w-16"
                            onClick={() => onSaveProduction()}
                          />
                        </div>
                      }}
                    >
                      <div className='p-2' >
                        <div className="flex lg:flex-row  sm:flex-col sm:space-y-2">
                          <div className="flex-1 sm:grid sm:grid-cols-3 sm:items-end sm:gap-2">
                            <label className="block text-xs font-medium text-gray-700 mb-1 text-right">
                              GQ No. :
                            </label>
                            <div className="mt-1 sm:col-span-2 sm:mt-0">
                              <Input
                                label="GQ No."
                                placeholder="GQ No."
                                hiddenLabel={true}
                                defaultValue={productForm.gq_no}
                                onChange={(e) => setProductForm({ ...productForm, gq_no: e.target.value })}
                              />
                            </div>
                          </div>
                          <div className="flex-1 sm:grid sm:grid-cols-3 sm:items-end sm:gap-2" />
                        </div>


                        <div className="flex lg:flex-row  sm:flex-col sm:space-y-2">
                          <div className="flex-1 sm:grid sm:grid-cols-3 sm:items-end sm:gap-2">
                            <label className="block text-xs font-medium text-gray-700 mb-1 text-right">
                              รหัสสินค้า :
                            </label>
                            <div className="mt-1 sm:col-span-2 sm:mt-0">
                              <Input
                                label="รหัสสินค้า"
                                placeholder="รหัสสินค้า"
                                hiddenLabel={true}
                                disabled={true}
                                defaultValue={productForm.product_code}
                              />
                            </div>
                          </div>

                          <div className="flex-1 sm:grid sm:grid-cols-3 sm:items-end sm:gap-2">
                            <label className="block text-xs font-medium text-gray-700 mb-1 text-right">
                              สินค้า<span className="text-red-500">* </span> :{labelError("product_id")}
                            </label>
                            <div className="mt-1 sm:col-span-2 sm:mt-0">
                              <Select
                                showSearch={true}
                                hiddenLabel={true}
                                defaultValue={productForm.product_id}
                                placeholder="ชื่อสินค้า"
                                validate={validation['product_id']}
                                options={masterOptions.product}
                                onChange={(value) => {
                                  let product = masterOptions?.product.find((e) => e.value === value)
                                  setProductForm({
                                    ...productForm,
                                    product_id: value,
                                    product_code: product?.product_code,
                                    product_name: product?.label
                                  })
                                }}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="flex lg:flex-row  sm:flex-col sm:space-y-2">
                          <div className="flex-1 sm:grid sm:grid-cols-3 sm:items-end sm:gap-2 mb-1">
                            <label className="block text-xs font-medium text-gray-700 mb-1 text-right">
                              จำนวน<span className="text-red-500">* </span> :{labelError("product_qty")}
                            </label>
                            <div className="mt-1 sm:col-span-2 sm:mt-0">
                              <InputNumber
                                hiddenLabel={true}
                                placeholder="จำนวน"
                                required={true}
                                min={0}
                                validate={validation['product_qty']}
                                defaultValue={productForm.product_qty === "" ? [] : productForm.product_qty}
                                onChange={(value) => setProductForm({ ...productForm, product_qty: value })}
                              />
                            </div>
                          </div>

                          <div className="flex-1 sm:grid sm:grid-cols-3 sm:items-end sm:gap-2">
                            <label className="block text-xs font-medium text-gray-700 mb-1 text-right">
                              หน่วยสินค้า<span className="text-red-500">* </span> :{labelError("unit_id")}
                            </label>
                            <div className="mt-1 sm:col-span-2 sm:mt-0">
                              <Select
                                showSearch={true}
                                hiddenLabel={true}
                                defaultValue={productForm.unit_id}
                                placeholder="หน่วยสินค้า"
                                validate={validation['unit_id']}
                                options={masterOptions.product_unit}
                                onChange={(value) => {
                                  let unit = masterOptions?.product_unit.find((e) => e.value === value)
                                  setProductForm({
                                    ...productForm,
                                    unit_id: value,
                                    unit_name: unit?.label
                                  })
                                }}
                              />
                            </div>
                          </div>
                        </div>
                        {
                          dataForm.cust_id !== "" && dataForm.logistic_type_id === 2 && dataForm.loc_dest_id !== "" &&
                          <div className="flex lg:flex-row  sm:flex-col sm:space-y-2 mt-2">
                            <div className="flex-1 sm:grid sm:grid-cols-3 sm:items-end sm:gap-2">
                              <label className="block text-xs font-medium text-gray-700 mb-1 text-right">
                                ราคาค่าขนส่ง<span className="text-red-500">* </span> :{labelError("price_set_value")}
                              </label>
                              <div className="mt-1 sm:col-span-2 sm:mt-0">
                                <div className="flex flex-row">
                                  <div className="w-32 h-8 mt-1 rounded-md border-gray-300 text-gray-900 font-medium  text-sm bg-gray-50 p-2">{productForm.price_set_unit_value}</div>

                                  <Button
                                    text="เลือกราคา"
                                    width="w-20"
                                    type="primary"
                                    className="mt-1 ml-1"
                                    onClick={() => {
                                      if (productForm.product_id === "") {
                                        setValidation({ product_id: { isError: true, message: "โปรดระบุ" } })
                                        setShakeIsWrong(true)
                                        setTimeout(() => setShakeIsWrong(false), 100);
                                      }
                                      else
                                        setOpenModalPrice(true)
                                    }}
                                  />
                                </div>

                              </div>
                            </div>

                            <div className="flex-1 sm:grid sm:grid-cols-3 sm:items-end sm:gap-2" />
                          </div>
                        }
                      </div>
                    </Card>
                    : <DataGrid
                      dataSource={[...dataForm.products]}
                      keyExpr="id"
                      height={200}
                      allowColumnReordering={true}
                      columnAutoWidth={true}
                      showBorders={true}
                    >
                      <Paging enabled={true} />
                      <Column
                        dataField="product_code"
                        caption="รหัสสินค้า" />

                      <Column
                        dataField="gq_no"
                        caption="GQ No." />

                      <Column
                        dataField="product_name"
                        caption="ชื่อสินค้า" />

                      <Column
                        dataField="product_qty"
                        caption="จำนวน" />

                      <Column
                        dataField="unit_name"
                        caption="หน่วย" />

                      {
                        dataForm.cust_id !== "" && dataForm.logistic_type_id === 2 && dataForm.loc_dest_id !== "" &&
                        <Column
                          dataField="price_set_unit_value"
                          caption="ราคาขนส่ง/หน่วย" />
                      }
                      {
                        dataForm.cust_id !== "" && dataForm.logistic_type_id === 2 && dataForm.loc_dest_id !== "" &&
                        <Column
                          dataField="price_set_value"
                          caption="ราคาขนส่ง" />
                      }

                      <Column
                        fixed={true}
                        caption="แก้ไข/ลบ"
                        type="buttons"
                        width={80}
                        buttons={[
                          {
                            hint: "แก้ไข",
                            icon: "fa fa-pencil-square-o",
                            visible: true,
                            onClick: (e) => {
                              e.cancel = "true"
                              let data = e.row.data
                              setProductForm({
                                ...productForm,
                                "open": true,
                                "type": "edit",
                                "product_code": data.product_code,
                                "product_id": data.product_id,
                                "unit_id": data.unit_id,
                                "product_qty": data.product_qty,
                                "product_name": data.product_name,
                                "unit_name": data.unit_name,
                                "price_set_id": data.price_set_id,
                                "price_set_value": data.price_set_value,
                                "price_set_unit_value": data.price_set_unit_value,
                                "gq_no": data.gq_no,
                                "id": data.id
                              })
                            }
                          },
                          {
                            hint: "ลบ",
                            icon: "trash",
                            visible: true,
                            onClick: (e) => {
                              e.cancel = "true"
                              let data = e.row.data

                              let newData = dataForm.products.filter((dt) => dt.id !== data.id)
                              setDataForm({ ...dataForm, products: newData })
                            }
                          }
                        ]} />
                    </DataGrid>
                }
              </div>
            </div>
          </div>
          {
            dataForm.cust_id !== "" && dataForm.logistic_type_id === 2 && dataForm.loc_dest_id !== "" &&
            <div className="flex justify-end">
              <label className="text-xs font-medium text-gray-700 mt-4 mr-2 text-right">
                รวมค่าขนส่ง :
              </label>
              {/* <Input placeholder="รวมค่าขนส่ง" disabled={true} width="w-40" defaultValue={`${priceSum}`} /> */}
              <div className="w-32 h-8 mt-1 rounded-md border-gray-300 text-gray-900 font-medium  text-sm bg-gray-50 p-2" style={{ border: '1px solid #d9d9d9' }}>{priceSum}</div>
            </div>
          }

          <div className="flex lg:flex-row sm:flex-col sm:space-y-2 pt-2">
            <div className="flex-1 sm:grid sm:grid-cols-6 sm:items-start sm:gap-2">
              <label className="block text-xs font-medium text-gray-700 mb-1 text-right">
                หมายเหตุ :
              </label>
              <div className="mt-1 sm:col-span-5 sm:mt-0">
                <InputTextarea
                  id="remark"
                  placeholder="หมายเหตุ"
                  rows={3}
                  defaultValue={dataForm.remark}
                  onRef={(ref) => inputRef.current[4] = ref}
                />
              </div>
            </div>
          </div>
        </div>
      </FormLoading>

      <ModalPriceSet
        open={openModalPrice}
        masterOptions={masterOptions}
        auth={auth}
        productForm={productForm}
        data={{
          cust_id: dataForm.cust_id,
          logistic_type_id: dataForm.logistic_type_id,
          shipto_id: dataForm.loc_dest_id,
        }}
        defaultSelected={dataForm.price_set}
        onSelected={(data) => {
          if (dataForm.logistic_type_id === 1) {
            let price_set = {
              "price_set_id": data.price_set_id,
              "price_set_value": data.price_value,
              "price_set_name": data.price_set_name
            }
            setDataForm({ ...dataForm, price_set })
          }
          else if (dataForm.logistic_type_id === 2) {
            setProductForm({
              ...productForm,
              price_set_id: data.price_set_id,
              price_set_value: data.price_value,
              price_set_unit_value: data.price_value,
            })
          }
          setOpenModalPrice(false)
        }}
        onClosed={() => setOpenModalPrice(false)}
      />
    </Modal>

    <ModalAlert
      {...optionAlert}
      onOk={() => {
        let { action } = optionAlert
        if (["create", "update"].includes(action.name)) {
          saveData()
        }
        else if (action.name === "cancel") {
          cancelQueue(action.id)
        }
        setOptionAlert({ ...optionAlert, show: false })
      }}
      onCancel={() => {
        setOptionAlert({ ...optionAlert, show: false })
      }}
    />
  </>)
}))

export default ModalQueue