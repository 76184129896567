
import { useState, useRef, useEffect } from 'react'
import { inject, observer } from 'mobx-react';
import { createMultipleRef, getValueInputRef } from '../../../helpers'
import { Input, Modal, ModalAlert, Select, FormLoading } from '../../../components'
import { CauseReasonService } from '../../../services';
import { isEmpty } from 'lodash'

const ModalCause = inject("causeReasonStore")(observer((props) => {
  let causeReasonStore = props.causeReasonStore
  let { getOpenCause, dataCauseEdit, setCauseStore } = causeReasonStore // ACTIONS
  let { masterOptions, auth } = props // STATES
  let { onReload } = props // ACTIONS

  const [loading, setLoading]: any = useState({ visible: false, tip: 'กำลังบันทึก...' })
  const [openModal, setOpenModal]: any = useState(false)
  const [validation, setValidation]: any = useState({})
  const [shakeIsWrong, setShakeIsWrong] = useState(false)
  const [optionAlert, setOptionAlert]: any = useState({
    show: false,
    action: {
      id: "",
      name: ""
    },
    type: "success",
    title: "",
    description: {
      visible: false,
      type: "",
      content: "",
      contentLists: [],

    }
  })

  const [dataForm, setDataForm]: any = useState({
    "mode": "add",
    "cause_id": "",
    "cause_name": "",
    "status": "Y"
  })

  const inputRef: any = useRef([]);
  createMultipleRef(inputRef, 1)


  useEffect(() => {
    let data = dataCauseEdit
    if (!isEmpty(data)) {
      let dataForm = {
        "mode": "update",
        "id": data?.id,
        "cause_id": data?.cause_id,
        "cause_name": data?.cause_name,
        "status": data?.status
      }
      setDataForm(dataForm)
    }
    setOpenModal(getOpenCause)
  }, [getOpenCause, dataCauseEdit])

  const initialData = () => {
    setValidation({})
    setDataForm({
      "mode": "add",
      "cause_id": "",
      "cause_name": "",
      "status": "Y"
    })
  }

  const onSave = () => {
    let validateCheck = {}, message = { isError: true, message: "โปรดระบุ" }
    let formData = getValueInputRef(inputRef)

    if (formData.cause_name.trim() === "")
      validateCheck['cause_name'] = message
    if (dataForm.status.length === 0 || dataForm.status === "")
      validateCheck['status'] = message

    if (isEmpty(validateCheck)) {
      setValidation({})
      setOptionAlert({
        ...optionAlert,
        action: {
          id: dataForm.id,
          name: "save"
        },
        type: "confirm",
        title: "ยืนยัน",
        show: true,
        description: {
          visible: true,
          type: "text",
          content: "คุณแน่ใจที่จะบันทึกข้อมูล ?",
          contentLists: []
        }
      })
    }
    else {
      setValidation(validateCheck)
      setShakeIsWrong(true)
      setTimeout(() => setShakeIsWrong(false), 100);
    }
  }

  const saveData = () => {
    let formData = getValueInputRef(inputRef)

    let data: any = {
      "cause_name": formData.cause_name,
      "status": dataForm.status
    }

    if (dataForm.mode === "update") data.cause_id = dataForm?.id
    setLoading({ ...loading, visible: true, tip: 'กำลังบันทึก...' })
    if (dataForm.mode === "add") {
      CauseReasonService.addCause(auth, data)
        .then((res: any) => {
          let { code, message, sys_message } = res.data

          if (code === 200) {
            setOptionAlert({
              ...optionAlert,
              type: "success",
              show: true,
              action: {
                id: "",
                name: ""
              },
              title: "บันทึกข้อมูลสำเร็จ",
              description: { visible: false, type: "text", content: " ", contentLists: [] }
            })
            initialData()
            onReload()
            onCloseModal()
          }
          else {
            setOptionAlert({
              ...optionAlert,
              type: "danger",
              show: true,
              action: {
                id: "",
                name: ""
              },
              title: "ผิดพลาด",
              description: { visible: true, type: "text", content: `${message} ${sys_message}` }
            })
          }
          setLoading({ ...loading, visible: false })
        })
        .catch((err) => {
          setOptionAlert({
            ...optionAlert,
            type: "danger",
            show: true,
            action: {
              id: "",
              name: ""
            },
            title: "ผิดพลาด",
            description: { visible: true, type: "text", content: `${err}` }
          })
          setLoading({ ...loading, visible: false })
        })
    }
    else {
      CauseReasonService.updateCause(auth, data)
        .then((res: any) => {
          let { code, message, sys_message } = res.data

          if (code === 200) {
            setOptionAlert({
              ...optionAlert,
              type: "success",
              show: true,
              action: {
                id: "",
                name: ""
              },
              title: "บันทึกข้อมูลสำเร็จ",
              description: { visible: false, type: "text", content: " ", contentLists: [] }
            })
            initialData()
            onReload()
            onCloseModal()
          }
          else {
            setOptionAlert({
              ...optionAlert,
              type: "danger",
              show: true,
              action: {
                id: "",
                name: ""
              },
              title: "ผิดพลาด",
              description: { visible: true, type: "text", content: `${message} ${sys_message}` }
            })
          }
          setLoading({ ...loading, visible: false })
        })
        .catch((err) => {
          setOptionAlert({
            ...optionAlert,
            type: "danger",
            show: true,
            action: {
              id: "",
              name: ""
            },
            title: "ผิดพลาด",
            description: { visible: true, type: "text", content: `${err}` }
          })
          setLoading({ ...loading, visible: false })
        })
    }
  }
  const onCloseModal = () => setCauseStore({ openCause: false, dataCauseEdit: {} })
  return (<>
    {
      openModal && <Modal
        title={{ text: `${dataForm.mode === "add" ? 'เพิ่ม' : 'แก้ไข'}หน่วยงานต้นเหตุ` }}
        open={true}
        maskClosable={true}
        shake={shakeIsWrong}
        onClosed={() => {
          onCloseModal()
          initialData()
        }}
        onOk={() => onSave()}
      >
        <FormLoading loading={loading.visible} tip={loading.tip}>
          <div className="flex flex-col space-y-2">
            <div className='flex-1'>
              <Input
                id="cause_name"
                label="หน่วยงานต้นเหตุ"
                placeholder="หน่วยงานต้นเหตุ"
                required={true}
                defaultValue={dataForm.cause_name}
                validate={validation?.cause_name}
                onRef={(ref) => inputRef.current[0] = ref}
              />
            </div>

            <div className='flex-1'>
              <Select
                label="สถานะ"
                placeholder="สถานะ"
                required={true}
                onChange={(select) => setDataForm({ ...dataForm, status: select })}
                defaultValue={dataForm.status}
                validate={validation?.status}
                options={masterOptions.common_status}
              />
            </div>
          </div>
        </FormLoading>
      </Modal>
    }

    <ModalAlert
      {...optionAlert}
      onOk={() => {
        let { type, action } = optionAlert
        if (action.name === "save") saveData()
        setOptionAlert({ ...optionAlert, show: false })
      }}
      onCancel={() => {
        setOptionAlert({ ...optionAlert, show: false })
      }}
    />
  </>
  )
}))

export default ModalCause