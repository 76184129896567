import { useEffect, useState, useRef } from 'react'
import { inject, observer } from 'mobx-react';
import FormMaster from '../FormMaster'
import { createMultipleRef, getValueInputRef } from '../../../helpers'
import { Modal, Input, Select, ModalAlert } from '../../../components'
import { CommonService, ShippointService } from "../../../services";
import { get, isEmpty } from 'lodash'
import { BASE_URL } from '../../../AppConfig'
import { TimePicker, InputNumber } from 'antd';
import dayjs from 'dayjs';

let firstRender = false
const format = 'HH:mm';
const Shipto = inject("masterStore", "authenStore")(observer((props) => {
  let masterStore = props.masterStore
  let authenStore = props.authenStore
  let { getAccountAuth } = authenStore // STATE
  let auth = { action_user_id: getAccountAuth?.user_id, token_id: getAccountAuth?.access_token }

  let { setMasterStore } = masterStore
  const [modalConfig, setModalConfig]: any = useState({
    show: false,
    action: "",
    id: ""
  })
  const [loading, setLoading]: any = useState(false)
  const [optionStatus, setOptionStatus] = useState([])
  const [optionProvince, setOptionProvince] = useState([])
  const [optionDistrict, setOptionDistrict] = useState([])
  const [optionSubDistrict, setOptionSubDistrict] = useState([])
  const [validation, setValidation]: any = useState({})
  const [shakeIsWrong, setShakeIsWrong] = useState(false)
  const [dataForm, setDataForm]: any = useState({
    id: "",
    shippoint_code: "",
    shippoint_name: "",
    lat: "",
    lon: "",
    address: "",
    village: "",
    soi: "",
    road: "",
    admin_level1_id: "",
    admin_level2_id: "",
    admin_level3_id: "",
    post_code: "",
    tel: "",
    email: "",
    contact_name: "",
    ref_code: "",
    dtopen: "",
    dtclose: "",
    pickup_mins: 0,
    pickdown_mins: 0
  })
  const [optionAlert, setOptionAlert]: any = useState({
    show: false,
    action: {
      id: "",
      name: ""
    },
    type: "success",
    title: "",
    description: {
      visible: false,
      type: "",
      content: "",
      contentLists: [],

    }
  })
  const inputRef: any = useRef([]);
  createMultipleRef(inputRef, 7)

  useEffect(() => {
    if (firstRender || !isEmpty(getAccountAuth)) {
      CommonService.getMasterData(auth, "common_status,admin_level1")
        .then((res: any) => {
          let common_status = res.result.common_status.map((item) => ({ label: item.status_name, value: item.status_code }))
          let admin_level1 = res.result.admin_level1.map((item) => ({ label: item.admin_level1_name, value: item.admin_level1_id }))

          setOptionStatus(common_status)
          setOptionProvince(admin_level1)
        })
        .catch((err) => {
          console.log(`Load master data : ${err}`)
        })
    }
    else
      firstRender = true
  }, [getAccountAuth])

  const getAddressMaster = (id_ref1, id_ref2) => { // GET ADDRESS MASTER 
    CommonService.getMasterData(auth, "admin_level1,admin_level2,admin_level3", id_ref1, id_ref2)
      .then((res: any) => {
        let admin_level1 = res.result.admin_level1.map((item) => ({ label: item.admin_level1_name, value: item.admin_level1_id }))
        let admin_level2 = res.result.admin_level2.map((item) => ({ label: item.admin_level2_name, value: item.admin_level2_id }))
        let admin_level3 = res.result.admin_level3.map((item) => ({ label: item.admin_level3_name, value: item.admin_level3_id }))
        setOptionProvince(admin_level1)
        setOptionDistrict(admin_level2)
        setOptionSubDistrict(admin_level3)
      })
      .catch((err) => {
        console.log(`Load master data : ${err}`)
      })
  }

  const onChangeProvince = (id_ref1) => { // GET MASTER DISTRICT
    if (id_ref1) {
      setDataForm({ ...dataForm, admin_level1_id: id_ref1 })
      CommonService.getMasterData(auth, "admin_level2", id_ref1)
        .then((res: any) => {
          let admin_level2 = res.result.admin_level2.map((item) => ({ label: item.admin_level2_name, value: item.admin_level2_id }))
          setOptionDistrict(admin_level2)
        })
        .catch((err) => {
          console.log(`Load master data : ${err}`)
        })
    }
    else {
      setDataForm({ ...dataForm, admin_level1_id: "", admin_level2_id: "", admin_level3_id: "" })
      setOptionDistrict([])
    }
  }

  const onChangeDistrict = (id_ref2) => { // GET MASTER DISTRICT
    if (id_ref2) {
      setDataForm({ ...dataForm, admin_level2_id: id_ref2 })
      CommonService.getMasterData(auth, "admin_level3", dataForm.admin_level1_id, id_ref2)
        .then((res: any) => {
          let admin_level3 = res.result.admin_level3.map((item) => ({ label: item.admin_level3_name, value: item.admin_level3_id }))
          setOptionSubDistrict(admin_level3)
        })
        .catch((err) => {
          console.log(`Load master data : ${err}`)
        })
    }
    else {
      setDataForm({ ...dataForm, admin_level2_id: "", admin_level3_id: "" })
      setOptionSubDistrict([])
    }
  }

  //#region 
  const onSave = () => {
    let validateCheck = {}, message = { isError: true, message: "โปรดระบุ" }
    let formData = getValueInputRef(inputRef)

    if (formData.shippoint_name.trim() === "")
      validateCheck['shippoint_name'] = message
    if (formData.lat.trim() === "")
      validateCheck['lat'] = message
    if (formData.lon.trim() === "")
      validateCheck['lon'] = message

    if (isEmpty(validateCheck)) {
      setValidation({})
      setOptionAlert({
        ...optionAlert,
        action: {
          id: dataForm.id,
          name: dataForm.id === "" ? 'create' : 'update'
        },
        type: "confirm",
        title: "ยืนยัน",
        show: true,
        description: {
          visible: true,
          type: "text",
          content: "คุณแน่ใจที่จะบันทึกข้อมูล ?",
          contentLists: []
        }
      })
    }
    else {
      setValidation(validateCheck)
      setShakeIsWrong(true)
      setTimeout(() => setShakeIsWrong(false), 100);
    }
  }

  const saveData = () => {
    let formData = getValueInputRef(inputRef)

    let body = {
      shippoint_code: formData.shippoint_code,
      shippoint_name: formData.shippoint_name,
      lat: formData.lat,
      lon: formData.lon,
      address: formData.address,
      village: formData.village,
      soi: formData.soi,
      road: formData.road,
      admin_level1_id: dataForm.admin_level1_id === "" ? 0 : dataForm.admin_level1_id,
      admin_level2_id: dataForm.admin_level2_id === "" ? 0 : dataForm.admin_level2_id,
      admin_level3_id: dataForm.admin_level3_id === "" ? 0 : dataForm.admin_level3_id,
      post_code: formData.post_code,
      tel: formData.tel,
      email: formData.email,
      contact_name: formData.contact_name,
      ref_code: formData.ref_code,
      dtopen: dataForm.dtopen,
      dtclose: dataForm.dtclose,
      pickup_mins: dataForm.pickup_mins,
      pickdown_mins: dataForm.pickdown_mins,
      status: dataForm.status
    }
    if (optionAlert.action.name === "create") {
      ShippointService.addShippoint(auth, body)
        .then((res: any) => {
          let { code, message } = res.data
          if (code === 200) {
            setOptionAlert({
              ...optionAlert,
              type: "success",
              show: true,
              action: {
                id: "",
                name: ""
              },
              title: "บันทึกข้อมูลสำเร็จ",
              description: { visible: false, type: "text", content: " ", contentLists: [] }
            })
            setMasterStore({ isRefreshData: true })
            clearDataForm()
            setModalConfig({ ...modalConfig, show: false })
          }
          else {
            setOptionAlert({
              ...optionAlert,
              type: "danger",
              show: true,
              action: {
                id: "",
                name: ""
              },
              title: "ผิดพลาด",
              description: { visible: true, type: "text", content: message }
            })
          }
          setLoading(false)

        })
        .catch((err) => {
          setOptionAlert({
            ...optionAlert,
            type: "danger",
            show: true,
            action: {
              id: "",
              name: ""
            },
            title: "ผิดพลาด",
            description: { visible: true, type: "text", content: `${err}` }
          })
          setLoading(false)
        })
    }
    else {
      ShippointService.updateShippoint(auth, {
        ...body,
        shipping_id: dataForm.id
      })
        .then((res: any) => {
          let { code, message, sys_message } = res.data
          if (code === 200) {
            setOptionAlert({
              ...optionAlert,
              type: "success",
              show: true,
              action: {
                id: "",
                name: ""
              },
              title: "บันทึกข้อมูลสำเร็จ",
              description: { visible: false, type: "text", content: " ", contentLists: [] }
            })
            setMasterStore({ isRefreshData: true })
            clearDataForm()
            setModalConfig({ ...modalConfig, show: false })
          }
          else {
            setOptionAlert({
              ...optionAlert,
              type: "danger",
              show: true,
              action: {
                id: "",
                name: ""
              },
              title: "ผิดพลาด",
              description: { visible: true, type: "text", content: `${message} ${sys_message}` }
            })
          }
          setLoading(false)

        })
        .catch((err) => {
          setOptionAlert({
            ...optionAlert,
            type: "danger",
            show: true,
            action: {
              id: "",
              name: ""
            },
            title: "ผิดพลาด",
            description: { visible: true, type: "text", content: `${err}` }
          })
          setLoading(false)
        })
    }

  }
  //#endregion

  const clearDataForm = () => {
    setValidation({})
    setDataForm({
      id: "",
      shippoint_code: "",
      shippoint_name: "",
      lat: "",
      lon: "",
      address: "",
      village: "",
      soi: "",
      road: "",
      admin_level1_id: "",
      admin_level2_id: "",
      admin_level3_id: "",
      post_code: "",
      tel: "",
      email: "",
      contact_name: "",
      ref_code: "",
      dtopen: "",
      dtclose: "",
      pickup_mins: 0,
      pickdown_mins: 0
    })
  }

  return <>
    <ModalAlert
      {...optionAlert}
      onOk={() => {
        let { action } = optionAlert
        if (["create", "update"].includes(action.name)) {
          setLoading(true)
          saveData()
        }

        setOptionAlert({ ...optionAlert, show: false })
      }}
      onCancel={() => {
        setOptionAlert({ ...optionAlert, show: false })
      }}
    />

    <FormMaster
      title="ข้อมูลคลัง/สาขา"
      tableConfig={{
        key: "id",
        apiPath: "shippoint/list",
        allowDelete: {
          visible: true,
          key: "shipping_id",
          apiPath: "shippoint/delete",
          paramType: "param"
        },
        optionDeleteFag: true,
        defaultColumn: [],
        columns: [
          {
            dataField: 'no',
            caption: "ลำดับ",
            width: 60,
            alignment: "center",
            allowFiltering: false
          },
          {
            dataField: 'shippoint_code',
            caption: "รหัสคลัง/สาขา",
            allowFiltering: true
          },
          {
            dataField: 'shippoint_name',
            caption: "ชื่อคลัง/สาขา",
            allowFiltering: true
          },
          {
            dataField: 'address',
            caption: "ที่อยู่"
          },
          {
            dataField: 'shippoint_name',
            caption: "พิกัด",
            cellRender: (e) => {
              let data = e.row.data
              return `${data.lat}, ${data.lon}`
            }
          },
          {
            dataField: 'contact_name',
            caption: "ผู้ติดต่อ"
          },
          {
            dataField: 'tel',
            caption: "เบอร์โทร"
          },
          {
            dataField: 'updated_by',
            caption: "แก้ไขโดย"
          }
        ],
        exportExcel: {
          fileName: 'ข้อมูลคลัง_สาขา',
          columnConfig: [
            { label: 'ลำดับ', value: 'no' },
            { label: 'รหัสคลัง/สาขา', value: 'shippoint_code' },
            { label: 'ชื่อคลัง/สาขา', value: 'shippoint_name' },
            { label: 'ที่อยู่', value: 'address' },
            { label: 'พิกัด', value: (col) => (`${col.lat}, ${col.lon}`) },
            { label: 'tel', value: 'contact_name' },
            { label: 'เบอร์โทร', value: 'tel' },
            { label: 'แก้ไขโดย', value: 'updated_by' }
          ]
        }
      }}
      formData={{}}
      modalConfig={{
        visible: false,
        title: "คลัง/สาขา",
        required: [],
        fieldTypeNumber: [],
        paramName: "shipping_id",
        apiPath: {
          "get": '',
          "post": 'shippoint/add',
          "put": 'shippoint/update'
        },
        elements: []
      }}
      onAdd={(data) => {
        setModalConfig(data.modalConfig)
      }}
      onEdit={(data) => {
        setModalConfig(data.modalConfig)

        let dt = data.modalConfig.dataSelectRow
        getAddressMaster(dt.admin_level1_id, dt.admin_level2_id)
        setDataForm({
          ...dataForm,
          id: dt.id,
          shippoint_code: dt.shippoint_code,
          shippoint_name: dt.shippoint_name,
          lat: dt.lat,
          lon: dt.lon,
          address: dt.address,
          village: dt.village,
          soi: dt.soi,
          road: dt.road,
          admin_level1_id: dt.admin_level1_id === 0 ? "" : dt.admin_level1_id,
          admin_level2_id: dt.admin_level2_id === 0 ? "" : dt.admin_level2_id,
          admin_level3_id: dt.admin_level3_id === 0 ? "" : dt.admin_level3_id,
          post_code: dt.post_code,
          tel: dt.tel,
          email: dt.email,
          contact_name: dt.contact_name,
          ref_code: dt.ref_code,
          dtopen: dt.dtopen,
          dtclose: dt.dtclose,
          pickup_mins: dt.pickup_mins,
          pickdown_mins: dt.pickdown_mins
        })
      }}
    />

    <Modal
      title={{ text: `${modalConfig.action === 'add' ? 'เพิ่ม' : 'แก้ไข'}คลัง/สาขา` }}
      open={modalConfig.show}
      // open={true}
      maskClosable={true}
      // width='lg:w-2/3'
      shake={shakeIsWrong}
      onClosed={() => {
        clearDataForm()
        setModalConfig({ ...modalConfig, show: false })
      }}
      onOk={() => onSave()}
    >
      <div className='md:space-y-2'>
        <div className='flex md:flex-row sm:flex-col xs:flex-col md:space-x-4'>
          <div className='flex-1'>
            <Input
              id="shippoint_code"
              label="รหัสคลัง/สาขา"
              placeholder="รหัสคลัง/สาขา"
              validate={validation['shippoint_code']}
              defaultValue={dataForm.shippoint_code}
              onRef={(ref) => inputRef.current[0] = ref}
            />
          </div>

          <div className='flex-1'>
            <Input
              id="shippoint_name"
              label="ชื่อคลัง/สาขา"
              placeholder="ชื่อคลัง/สาขา"
              required={true}
              validate={validation['shippoint_name']}
              defaultValue={dataForm.shippoint_name}
              onRef={(ref) => inputRef.current[1] = ref}
            />
          </div>
        </div>

        <div className='flex md:flex-row sm:flex-col xs:flex-col md:space-x-4'>
          <div className='flex-1'>
            <Input
              id="lat"
              label="ละติจูด"
              placeholder="ละติจูด"
              validate={validation['lat']}
              defaultValue={dataForm.lat}
              required={true}
              onRef={(ref) => inputRef.current[2] = ref}
            />
          </div>

          <div className='flex-1'>
            <Input
              id="lon"
              label="ลองติจูด"
              placeholder="ลองติจูด"
              validate={validation['lon']}
              defaultValue={dataForm.lon}
              required={true}
              onRef={(ref) => inputRef.current[3] = ref}
            />
          </div>
        </div>

        <div className='flex md:flex-row sm:flex-col xs:flex-col md:space-x-4'>
          <div className='flex-1'>
            <Input
              id="address"
              label="ที่อยู่"
              placeholder="ที่อยู่"
              validate={validation['address']}
              defaultValue={dataForm.address}
              onRef={(ref) => inputRef.current[4] = ref}
            />
          </div>

          <div className='flex-1'>
            <Input
              id="village"
              label="ชื่อโครงการ/อาคาร"
              placeholder="ชื่อโครงการ/อาคาร"
              validate={validation['village']}
              defaultValue={dataForm.village}
              onRef={(ref) => inputRef.current[5] = ref}
            />
          </div>
        </div>

        <div className='flex md:flex-row sm:flex-col xs:flex-col md:space-x-4'>
          <div className='flex-1'>
            <Input
              id="soi"
              label="ซอย"
              placeholder="ซอย"
              validate={validation['soi']}
              defaultValue={dataForm.soi}
              onRef={(ref) => inputRef.current[6] = ref}
            />
          </div>

          <div className='flex-1'>
            <Input
              id="road"
              label="ถนน"
              placeholder="ถนน"
              validate={validation['road']}
              defaultValue={dataForm.road}
              onRef={(ref) => inputRef.current[7] = ref}
            />
          </div>
        </div>

        <div className='flex md:flex-row sm:flex-col xs:flex-col md:space-x-4'>
          <div className='flex-1'>
            <Select
              allowClear={true}
              showSearch={true}
              label="จังหวัด"
              placeholder="จังหวัด"
              validate={validation['admin_level1_id']}
              defaultValue={dataForm.admin_level1_id}
              options={optionProvince}
              onChange={(value) => onChangeProvince(value)}
            />
          </div>

          <div className='flex-1'>
            <Select
              showSearch={true}
              allowClear={true}
              label="อำเภอ"
              placeholder="อำเภอ"
              validate={validation['admin_level2_id']}
              defaultValue={dataForm.admin_level2_id}
              disabled={dataForm.admin_level1_id === "" ? true : false}
              options={optionDistrict}
              onChange={(value) => onChangeDistrict(value)}
            />
          </div>
        </div>

        <div className='flex md:flex-row sm:flex-col xs:flex-col md:space-x-4'>
          <div className='flex-1'>
            <Select
              showSearch={true}
              allowClear={true}
              label="ตำบล"
              placeholder="ตำบล"
              validate={validation['admin_level3_id']}
              defaultValue={dataForm.admin_level3_id}
              disabled={dataForm.admin_level2_id === "" ? true : false}
              options={optionSubDistrict}
              onChange={(value) => setDataForm({ ...dataForm, admin_level3_id: value ? value : '' })}
            />
          </div>

          <div className='flex-1'>
            <Input
              id="post_code"
              label="รหัสไปรษณีย์"
              placeholder="รหัสไปรษณีย์"
              validate={validation['post_code']}
              defaultValue={dataForm.post_code}
              onRef={(ref) => inputRef.current[8] = ref}
            />
          </div>
        </div>

        <div className='flex md:flex-row sm:flex-col xs:flex-col md:space-x-4'>
          <div className='flex-1'>
            <Input
              id="tel"
              label="เบอร์โทร"
              placeholder="เบอร์โทร"
              validate={validation['tel']}
              defaultValue={dataForm.tel}
              onRef={(ref) => inputRef.current[9] = ref}
            />
          </div>

          <div className='flex-1'>
            <Input
              id="email"
              label="อีเมล"
              placeholder="อีเมล"
              validate={validation['email']}
              defaultValue={dataForm.email}
              onRef={(ref) => inputRef.current[10] = ref}
            />
          </div>
        </div>

        <div className='flex md:flex-row sm:flex-col xs:flex-col md:space-x-4'>
          <div className='flex-1'>
            <Input
              id="contact_name"
              label="ชื่อผู้ติดต่อ"
              placeholder="ชื่อผู้ติดต่อ"
              validate={validation['contact_name']}
              defaultValue={dataForm.contact_name}
              onRef={(ref) => inputRef.current[11] = ref}
            />
          </div>

          <div className='flex-1'>
            <Input
              id="ref_code"
              label="เลขอ้างอิง"
              placeholder="เลขอ้างอิง"
              validate={validation['ref_code']}
              defaultValue={dataForm.ref_code}
              onRef={(ref) => inputRef.current[12] = ref}
            />
          </div>
        </div>

        <div className='flex md:flex-row sm:flex-col xs:flex-col md:space-x-4'>
          <div className='flex-1'>
            <div className={`flex justify-between`}>
              <label className={`flex flex-row text-sm font-medium text-gray-700 dark:text-gray-200`}>
                เวลาเปิด
              </label>
            </div>
            <div className="mt-1">
              <TimePicker
                style={{ width: "100%" }}
                placeholder="เวลาเปิด"
                value={dataForm.dtopen && dayjs(dataForm.dtopen, format)}
                onChange={(time) => {
                  let timeValue = time ? time?.format(format) : ""
                  setDataForm({ ...dataForm, dtopen: timeValue })
                }}
                format={format} />
            </div>
          </div>

          <div className='flex-1'>
            <div className={`flex justify-between`}>
              <label className={`flex flex-row text-sm font-medium text-gray-700 dark:text-gray-200`}>
                เวลาปิด
              </label>
            </div>
            <div className="mt-1">
              <TimePicker
                style={{ width: "100%" }}
                placeholder="เวลาปิด"
                value={dataForm.dtclose && dayjs(dataForm.dtclose, format)}
                onChange={(time) => {
                  let timeValue = time ? time?.format(format) : ""
                  setDataForm({ ...dataForm, dtclose: timeValue })
                }}
                format={format} />
            </div>
          </div>
        </div>

        <div className='flex md:flex-row sm:flex-col xs:flex-col md:space-x-4'>
          <div className='flex-1'>
            <div className={`flex justify-between`}>
              <label className={`flex flex-row text-sm font-medium text-gray-700 dark:text-gray-200`}>
                ระยะเวลาการโหลดสินค้า (นาที)
              </label>
            </div>
            <div className="mt-1">
              <InputNumber
                min={0}
                value={dataForm.pickup_mins}
                placeholder="ระยะเวลาการโหลดสินค้า (นาที)"
                style={{ width: "100%" }}
                onChange={(number) => setDataForm({ ...dataForm, pickup_mins: number })}
              />
            </div>
          </div>

          <div className='flex-1'>
            <div className={`flex justify-between`}>
              <label className={`flex flex-row text-sm font-medium text-gray-700 dark:text-gray-200`}>
                ระยะเวลาการลงสินค้า (นาที)
              </label>
            </div>
            <div className="mt-1">
              <InputNumber
                min={0}
                value={dataForm.pickdown_mins}
                placeholder="ระยะเวลาการลงสินค้า (นาที)"
                style={{ width: "100%" }}
                onChange={(number) => setDataForm({ ...dataForm, pickdown_mins: number })}
              />
            </div>
          </div>
        </div>
      </div>
    </Modal>
  </>
}))

export default Shipto