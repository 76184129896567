import axios from 'axios'
import { BASE_URL } from '../AppConfig'
import IAuth from '../interfaces/Common/IAuth'

export default {
  //#region USERS
  addCustomer: async function (auth: IAuth, data) {
    const response = await axios.post(`${BASE_URL}/customer/add`, {
      cust_code: data.cust_code,
      cust_name: data.cust_name,
      ini_name: data.ini_name,
      address: data.address,
      village: data.village,
      soi: data.soi,
      road: data.road,
      admin_level1_id: data.admin_level1_id,
      admin_level2_id: data.admin_level2_id,
      admin_level3_id: data.admin_level3_id,
      post_code: data.post_code,
      tel: data.tel,
      taxid: data.taxid,
      ref_code: data.ref_code,
      zone_set: data.zone_set
      // status: data.status
    },
      {
        headers: { action_user_id: auth?.action_user_id, token_id: auth?.token_id }
      })
    return response
  },

  updateCustomer: async function (auth: IAuth, data) {
    const response = await axios.put(`${BASE_URL}/customer/update`, {
      cust_id: data.cust_id,
      cust_code: data.cust_code,
      cust_name: data.cust_name,
      ini_name: data.ini_name,
      address: data.address,
      village: data.village,
      soi: data.soi,
      road: data.road,
      admin_level1_id: data.admin_level1_id,
      admin_level2_id: data.admin_level2_id,
      admin_level3_id: data.admin_level3_id,
      post_code: data.post_code,
      tel: data.tel,
      taxid: data.taxid,
      ref_code: data.ref_code,
      zone_set: data.zone_set
      // status: data.status
    },
      {
        headers: { action_user_id: auth?.action_user_id, token_id: auth?.token_id }
      })
    return response
  },
  //#endregion
}