
import { useEffect, useState, useRef } from "react";
import { inject, observer } from 'mobx-react';
import { Button, Input, Modal, Select, DatePicker, ModalAlert } from '../../components'
import { createMultipleRef, getValueInputRef } from '../../helpers'
import { CommonService, UploadQueueService } from "../../services";
import './style.css'
import { get, isEmpty } from 'lodash'

let firstRender = false
const ModalEditUpload = inject("authenStore")(observer((props) => {
  let authenStore = props.authenStore
  let { getAccountAuth } = authenStore // STATE
  let auth = { action_user_id: getAccountAuth?.user_id, token_id: getAccountAuth?.access_token }

  let { open, onClosed, data } = props
  const [loading, setLoading] = useState(false)
  const [shakeIsWrong, setShakeIsWrong] = useState(false)
  const [modalInfo, setModalInfo]: any = useState({
    open: false,
    title: "XXX",
    name: "",
    icon: ''
  })
  const [validation, setValidation]: any = useState({})
  const [masterOptions, setMasterOptions] = useState({
    customer: [],
    shipto: [],
    job_type: [],
    product: [],
    bill_cust: [],
    service: [],
    vehicle: [],
    shipping_point: [],
    product_unit: [],
    center: [],
    shipment_type: [],
    logistic_type: []
  })

  const [optionAlert, setOptionAlert]: any = useState({
    show: false,
    action: {
      id: "",
      name: ""
    },
    type: "success",
    title: "",
    description: {
      visible: false,
      type: "",
      content: "",
      contentLists: [],

    }
  })

  const [dataForm, setDataForm]: any = useState({
    "qno": "",
    "po_no": "",
    "product_id": "",
    "cust_id": "",
    "loc_receipt_id": "",
    "loc_dest_id": "",
    "job_type_id": "",
    "unit_id": "",
    "bill_cust_id": "",
    "delivery_date": "",
    "vid": "",
    "qty": "",
    "service_id": "",
    "q_import_id": "",
    "center_id": "",
    "shipment_type": "",
    "logistic_type_id": "",
    "ref_order_no": "",
    "track_no": ""
  })
  const inputRef: any = useRef([]);
  createMultipleRef(inputRef, 4)

  useEffect(() => {
    if (!isEmpty(data)) {
      setDataForm({
        ...dataForm,
        qno: data.qno,
        product_id: data.product_id === 0 ? "" : data.product_id,
        cust_id: data.cust_id === 0 ? "" : data.cust_id,
        po_no: data.po_no,
        loc_receipt_id: data.loc_receipt_id === 0 ? "" : data.loc_receipt_id,
        loc_dest_id: data.loc_dest_id === 0 ? "" : data.loc_dest_id,
        job_type_id: data.job_type_id === 0 ? "" : data.job_type_id,
        unit_id: data.unit_id === 0 ? "" : data.unit_id,
        bill_cust_id: data.bill_cust_id === 0 ? "" : data.bill_cust_id,
        center_id: data.center_id === 0 ? "" : data.center_id,
        shipment_type: data.shipment_type === 0 ? "" : data.shipment_type,
        delivery_date: data.delivery_date,
        vid: data.vid === 0 ? "" : data.vid,
        qty: data.total_qty,
        service_id: data.driver_id === 0 ? "" : data.driver_id,
        q_import_id: data.id,
        logistic_type_id: (data.logistic_type_id === 0 ? "" : data.logistic_type_id) || "",
        ref_order_no: data.ref_order_no,
        track_no: data.track_no
      })
    }

  }, [data, open])

  useEffect(() => {
    if (firstRender || !isEmpty(getAccountAuth)) {
      CommonService.getMasterData(auth, "customer,shipping_point,shipto,job_type,product,bill_cust,service,vehicle,product_unit,center,shipment_type,logistic_type")
        .then((res: any) => {
          let customer = res.result.customer.map((item) => ({ label: item.cust_name, value: item.cust_id }))
          let shipto = res.result.shipto.map((item) => ({ label: item.shipto_name, value: item.shipto_id }))
          let job_type = res.result.job_type.map((item) => ({ label: item.job_Type_name, value: item.job_type_id }))
          let product = res.result.product.map((item) => ({ label: item.product_name, value: item.product_id }))
          let bill_cust = res.result.bill_cust.map((item) => ({ label: item.bill_cust_name, value: item.bill_cust_id }))
          let service = res.result.service.map((item) => ({ label: item.service_name, value: item.service_id }))
          let vehicle = res.result.vehicle.map((item) => ({ label: item.licenseplate, value: item.vehicle_id }))
          let shipping_point = res.result.shipping_point.map((item) => ({ label: item.shipping_name, value: item.shipping_id }))
          let product_unit = res.result.product_unit.map((item) => ({ label: item.unit_name, value: item.unit_id }))
          let center = res.result.center.map((item) => ({ label: item.ceneter_name, value: item.center_id }))
          let shipment_type = res.result.shipment_type.map((item) => ({ label: item.desc, value: item.code }))
          let logistic_type = res.result.logistic_type.map((item) => ({ label: item.logistic_type_name, value: item.logistic_type_id }))

          setMasterOptions({
            ...masterOptions,
            customer,
            shipto,
            job_type,
            product,
            bill_cust,
            service,
            vehicle,
            shipping_point,
            product_unit,
            center,
            shipment_type,
            logistic_type
          })
        })
        .catch((err) => {
          console.log("LOAD MASTER : ", err)
        })
    }
    else
      firstRender = true
  }, [getAccountAuth])

  const initialData = () => {
    setValidation({})
    setDataForm({
      "qno": "",
      "product_id": "",
      "po_no": "",
      "cust_id": "",
      "loc_receipt_id": "",
      "loc_dest_id": "",
      "job_type_id": "",
      "unit_id": "",
      "bill_cust_id": "",
      "delivery_date": "",
      "vid": "",
      "qty": "",
      "service_id": "",
      "q_import_id": "",
      "center_id": "",
      "shipment_type": "",
      "logistic_type_id": "",
      "ref_order_no": "",
      "track_no": ""
    })
  }

  const onSave = () => {
    let validateCheck = {}, message = { isError: true, message: "โปรดระบุ" }
    let formData = getValueInputRef(inputRef)

    if (formData.po_no === "")
      validateCheck['po_no'] = message
    if (formData.qty.trim() === "")
      validateCheck['qty'] = message
    if (dataForm.delivery_date.trim() === "")
      validateCheck['delivery_date'] = message
    if (dataForm.product_id.length === 0 || dataForm.product_id === "")
      validateCheck['product_id'] = message
    if (dataForm.cust_id.length === 0 || dataForm.cust_id === "")
      validateCheck['cust_id'] = message
    if (dataForm.loc_receipt_id.length === 0 || dataForm.loc_receipt_id === "")
      validateCheck['loc_receipt_id'] = message
    if (dataForm.loc_dest_id.length === 0 || dataForm.loc_dest_id === "")
      validateCheck['loc_dest_id'] = message
    if (dataForm.center_id.length === 0 || dataForm.center_id === "")
      validateCheck['center_id'] = message
    if (dataForm.shipment_type.length === 0 || dataForm.shipment_type === "")
      validateCheck['shipment_type'] = message
    if (dataForm.job_type_id.length === 0 || dataForm.job_type_id === "")
      validateCheck['job_type_id'] = message
    if (dataForm.bill_cust_id.length === 0 || dataForm.bill_cust_id === "")
      validateCheck['bill_cust_id'] = message
    if (dataForm.unit_id.length === 0 || dataForm.unit_id === "")
      validateCheck['unit_id'] = message
    if (dataForm.logistic_type_id.length === 0 || dataForm.logistic_type_id === "")
      validateCheck['logistic_type_id'] = message

    if (isEmpty(validateCheck)) {
      setValidation({})
      setOptionAlert({
        ...optionAlert,
        action: {
          id: dataForm.id,
          name: "create"
        },
        type: "confirm",
        title: "ยืนยัน",
        show: true,
        description: {
          visible: true,
          type: "text",
          content: "คุณแน่ใจที่จะบันทึกข้อมูล ?",
          contentLists: []
        }
      })
    }
    else {
      setValidation(validateCheck)
      setShakeIsWrong(true)
      setTimeout(() => setShakeIsWrong(false), 100);
    }
  }

  const saveData = () => {
    let formData = getValueInputRef(inputRef)
    UploadQueueService.updateImport(auth, {
      po_no: formData.po_no,
      product_id: dataForm.product_id,
      cust_id: dataForm.cust_id,
      loc_receipt_id: dataForm.loc_receipt_id,
      center_id: dataForm.center_id,
      shipment_type: dataForm.shipment_type,
      loc_dest_id: dataForm.loc_dest_id,
      job_type_id: dataForm.job_type_id,
      unit_id: dataForm.unit_id,
      bill_cust_id: dataForm.bill_cust_id,
      delivery_date: dataForm.delivery_date,
      vid: dataForm.vid,
      qty: formData.qty,
      service_id: dataForm.service_id,
      q_import_id: dataForm.q_import_id,
      logistic_type_id: dataForm.logistic_type_id,
      ref_order_no: formData.ref_order_no,
      track_no: formData.track_no
    })
      .then((res: any) => {
        let { code, message } = res.data
        if (code === 200) {
          setOptionAlert({
            ...optionAlert,
            type: "success",
            show: true,
            action: {
              id: "",
              name: ""
            },
            title: "แก้ไขข้อมูลสำเร็จ",
            description: { visible: false, type: "text", content: " ", contentLists: [] }
          })
          initialData()
          onClosed(true)

        }
        else {
          setOptionAlert({
            ...optionAlert,
            type: "danger",
            show: true,
            action: {
              id: "",
              name: ""
            },
            title: "แก้ไขข้อมูลไม่สำเร็จ",
            description: { visible: true, type: "text", content: message }
          })
        }
      })
      .catch((err) => {
        console.log("err  :", err)
        setOptionAlert({
          ...optionAlert,
          type: "danger",
          show: true,
          action: {
            id: "",
            name: ""
          },
          title: "แก้ไขข้อมูลไม่สำเร็จ",
          description: { visible: true, type: "text", content: `${err}` }
        })
        setLoading(false)
      })
  }

  const labelError = (fieldName) => {
    if (get(validation, fieldName)) {
      if (validation[fieldName].isError)
        return <p className="text-xs italic text-danger-600">
          {validation[fieldName].message}
        </p>
      else
        return null
    }
  }


  return (<>
    <ModalAlert
      {...optionAlert}
      onOk={() => {
        let { action } = optionAlert
        if (["create", "update"].includes(action.name)) {
          setLoading(true)
          saveData()
        }

        setOptionAlert({ ...optionAlert, show: false })
      }}
      onCancel={() => {
        setOptionAlert({ ...optionAlert, show: false })
      }}
    />
    <Modal
      title={{ text: "แก้ไขข้อมูล" }}
      open={open}
      maskClosable={true}
      width={"w-3/6"}
      loading={false}
      shake={shakeIsWrong}
      onClosed={() => {

      }}
      icon={modalInfo.icon}
      onOk={() => onSave()}
      footer={<>
        <Button
          text="บันทึก"
          width="w-20"
          type="primary"
          onClick={() => onSave()}
        />

        <Button
          text="ยกเลิก"
          width="w-20"
          type="white"
          onClick={() => {
            initialData()
            onClosed(false)
          }}
        />
      </>}
    >
      <div className="flex flex-col -my-4 lg:space-y-0 sm:space-y-2">
        <div className="flex lg:flex-row  sm:flex-col sm:space-y-2">
          <div className="flex-1 sm:grid sm:grid-cols-3 sm:items-end sm:gap-2">
            <label className="block text-xs font-medium text-gray-700 mb-1 text-right">
              {/* เลขที่ใบงาน<span className="text-red-500">* </span> :{labelError("qno")} */}
              เลขที่ใบงาน :
            </label>
            <div className="mt-1 sm:col-span-2 sm:mt-0">
              <Input
                id="qno"
                label="เลขที่ใบงาน"
                placeholder="เลขที่ใบงาน"
                hiddenLabel={true}
                disabled={true}
                defaultValue={dataForm.qno}
              // validate={validation?.qno}
              // onRef={(ref) => inputRef.current[0] = ref}
              />
            </div>
          </div>
          <div className="flex-1 sm:grid sm:grid-cols-3 sm:items-end sm:gap-2" />
        </div>

        <div className="flex lg:flex-row  sm:flex-col sm:space-y-2">
          <div className="flex-1 sm:grid sm:grid-cols-3 sm:items-end sm:gap-2">
            <label className="block text-xs font-medium text-gray-700 mb-1 text-right">
              PO No.<span className="text-red-500">* </span> :{labelError("po_no")}
            </label>
            <div className="mt-1 sm:col-span-2 sm:mt-0">
              <Input
                id="po_no"
                label="PO No"
                placeholder="PO No"
                hiddenLabel={true}
                defaultValue={dataForm.po_no}
                validate={validation?.po_no}
                onRef={(ref) => inputRef.current[1] = ref}
              />
            </div>
          </div>

          <div className="flex-1 sm:grid sm:grid-cols-3 sm:items-end sm:gap-2">
            <label className="block text-xs font-medium text-gray-700 mb-1 text-right">
              วันที่จัดส่ง<span className="text-red-500">* </span> :{labelError("delivery_date")}
            </label>
            <div className="mt-1 sm:col-span-2 sm:mt-0">
              <DatePicker
                id="delivery_date"
                placeholder="วันที่จัดส่ง"
                allowClear={false}
                hiddenLabel={true}
                defaultValue={dataForm.delivery_date}
                validate={validation['delivery_date']}
                onChange={(d) => {
                  if (d)
                    setDataForm({ ...dataForm, delivery_date: d.format("YYYY-MM-DD") })
                  else
                    setDataForm({ ...dataForm, delivery_date: "" })
                }}
              />
            </div>
          </div>
        </div>

        <div className="flex lg:flex-row sm:flex-col sm:space-y-2">
          <div className="flex-1 sm:grid sm:grid-cols-3 sm:items-end sm:gap-2">
            <label className="block text-xs font-medium text-gray-700 mb-1 text-right">
              คลัง/ต้นทาง<span className="text-red-500">* </span> :{labelError("loc_receipt_id")}
            </label>
            <div className="mt-1 sm:col-span-2 sm:mt-0">
              <Select
                showSearch={true}
                hiddenLabel={true}
                defaultValue={dataForm.loc_receipt_id}
                placeholder="คลัง/ต้นทาง"
                validate={validation['loc_receipt_id']}
                options={masterOptions.shipping_point}
                onChange={(value) => setDataForm({ ...dataForm, loc_receipt_id: value })}
              />
            </div>
          </div>

          <div className="flex-1 sm:grid sm:grid-cols-3 sm:items-end sm:gap-2">
            <label className="block text-xs font-medium text-gray-700 mb-1 text-right">
              สาขา/ปลายทาง<span className="text-red-500">* </span> :{labelError("loc_dest_id")}
            </label>
            <div className="mt-1 sm:col-span-2 sm:mt-0">
              <Select
                showSearch={true}
                hiddenLabel={true}
                defaultValue={dataForm.loc_dest_id}
                placeholder="สาขา/ปลายทาง"
                validate={validation['loc_dest_id']}
                options={masterOptions.shipto}
                onChange={(value) => setDataForm({ ...dataForm, loc_dest_id: value })}
              />
            </div>
          </div>
        </div>

        <div className="flex lg:flex-row sm:flex-col sm:space-y-2">
          <div className="flex-1 sm:grid sm:grid-cols-3 sm:items-end sm:gap-2">
            <label className="block text-xs font-medium text-gray-700 mb-1 text-right">
              ลูกค้า<span className="text-red-500">* </span> :{labelError("cust_id")}
            </label>
            <div className="mt-1 sm:col-span-2 sm:mt-0">
              <Select
                showSearch={true}
                hiddenLabel={true}
                defaultValue={dataForm.cust_id}
                placeholder="ลูกค้า"
                validate={validation['cust_id']}
                options={masterOptions.customer}
                onChange={(value) => setDataForm({ ...dataForm, cust_id: value })}
              />
            </div>
          </div>

          <div className="flex-1 sm:grid sm:grid-cols-3 sm:items-end sm:gap-2">
            <label className="block text-xs font-medium text-gray-700 mb-1 text-right">
              บิลลูกค้า<span className="text-red-500">* </span> :{labelError("bill_cust_id")}
            </label>
            <div className="mt-1 sm:col-span-2 sm:mt-0">
              <Select
                showSearch={true}
                hiddenLabel={true}
                defaultValue={dataForm.bill_cust_id}
                placeholder="บิลลูกค้า"
                validate={validation['bill_cust_id']}
                options={masterOptions.bill_cust}
                onChange={(value) => setDataForm({ ...dataForm, bill_cust_id: value })}
              />
            </div>
          </div>
        </div>

        <div className="flex lg:flex-row sm:flex-col sm:space-y-2">
          <div className="flex-1 sm:grid sm:grid-cols-3 sm:items-end sm:gap-2">
            <label className="block text-xs font-medium text-gray-700 mb-1 text-right">
              หน่วยงาน<span className="text-red-500">* </span> :{labelError("center_id")}
            </label>
            <div className="mt-1 sm:col-span-2 sm:mt-0">
              <Select
                showSearch={true}
                hiddenLabel={true}
                defaultValue={dataForm.center_id}
                placeholder="หน่วยงาน"
                validate={validation['center_id']}
                options={masterOptions.center}
                onChange={(value) => setDataForm({ ...dataForm, center_id: value })}
              />
            </div>
          </div>

          <div className="flex-1 sm:grid sm:grid-cols-3 sm:items-end sm:gap-2">
            <label className="block text-xs font-medium text-gray-700 mb-1 text-right">
              ประเภทเที่ยว<span className="text-red-500">* </span> :{labelError("shipment_type")}
            </label>
            <div className="mt-1 sm:col-span-2 sm:mt-0">
              <Select
                showSearch={true}
                hiddenLabel={true}
                defaultValue={dataForm.shipment_type}
                placeholder="ประเภทเที่ยว"
                validate={validation['shipment_type']}
                options={masterOptions.shipment_type}
                onChange={(value) => setDataForm({ ...dataForm, shipment_type: value })}
              />
            </div>
          </div>
        </div>

        <div className="flex lg:flex-row sm:flex-col sm:space-y-2">
          <div className="flex-1 sm:grid sm:grid-cols-3 sm:items-end sm:gap-2">
            <label className="block text-xs font-medium text-gray-700 mb-1 text-right">
              ประเภทงาน<span className="text-red-500">* </span> :{labelError("job_type_id")}
            </label>
            <div className="mt-1 sm:col-span-2 sm:mt-0">
              <Select
                showSearch={true}
                hiddenLabel={true}
                defaultValue={dataForm.job_type_id}
                placeholder="ประเภทงาน"
                validate={validation['job_type_id']}
                options={masterOptions.job_type}
                onChange={(value) => setDataForm({ ...dataForm, job_type_id: value })}
              />
            </div>
          </div>

          <div className="flex-1 sm:grid sm:grid-cols-3 sm:items-end sm:gap-2">
            <label className="block text-xs font-medium text-gray-700 mb-1 text-right">
              ทะเบียน :
            </label>
            <div className="mt-1 sm:col-span-2 sm:mt-0">
              <Select
                showSearch={true}
                allowClear={true}
                hiddenLabel={true}
                defaultValue={dataForm.vid}
                placeholder="ทะเบียน"
                options={masterOptions.vehicle}
                onChange={(value) => setDataForm({ ...dataForm, vid: value })}
              />
            </div>
          </div>
        </div>

        <div className="flex lg:flex-row sm:flex-col sm:space-y-2">
          <div className="flex-1 sm:grid sm:grid-cols-3 sm:items-end sm:gap-2">
            <label className="block text-xs font-medium text-gray-700 mb-1 text-right">
              สินค้า<span className="text-red-500">* </span> :{labelError("product_id")}
            </label>
            <div className="mt-1 sm:col-span-2 sm:mt-0">
              <Select
                showSearch={true}
                defaultValue={dataForm.product_id}
                hiddenLabel={true}
                placeholder="สินค้า"
                validate={validation['product_id']}
                options={masterOptions.product}
                onChange={(value) => setDataForm({ ...dataForm, product_id: value })}
              />
            </div>
          </div>

          <div className="flex-1 sm:grid sm:grid-cols-3 sm:items-end sm:gap-2">
            <label className="block text-xs font-medium text-gray-700 mb-1 text-right">
              พนักงานขับ :
            </label>
            <div className="mt-1 sm:col-span-2 sm:mt-0">
              <Select
                showSearch={true}
                allowClear={true}
                defaultValue={dataForm.service_id}
                hiddenLabel={true}
                placeholder="พนักงานขับ"
                options={masterOptions.service}
                onChange={(value) => setDataForm({ ...dataForm, service_id: value })}
              />
            </div>
          </div>
        </div>

        <div className="flex lg:flex-row sm:flex-col sm:space-y-2 pt-0.5">
          <div className="flex-1 sm:grid sm:grid-cols-3 sm:items-end sm:gap-2">
            <label className="block text-xs font-medium text-gray-700 mb-1 text-right">
              หน่วยสินค้า<span className="text-red-500">* </span> :{labelError("unit_id")}
            </label>
            <div className="mt-1 sm:col-span-2 sm:mt-0">
              <Select
                showSearch={true}
                allowClear={true}
                defaultValue={dataForm.unit_id}
                hiddenLabel={true}
                placeholder="หน่วยสินค้า"
                validate={validation['unit_id']}
                options={masterOptions.product_unit}
                onChange={(value) => setDataForm({ ...dataForm, unit_id: value })}
              />
            </div>
          </div>

          <div className="flex-1 sm:grid sm:grid-cols-3 sm:items-end sm:gap-2">
            <label className="block text-xs font-medium text-gray-700 mb-1 text-right">
              จำนวน<span className="text-red-500">* </span> :{labelError("qty")}
            </label>
            <div className="mt-1 sm:col-span-2 sm:mt-0">
              <Input
                id="qty"
                label="จำนวน"
                placeholder="จำนวน"
                hiddenLabel={true}
                defaultValue={dataForm.qty}
                validate={validation?.qty}
                onRef={(ref) => inputRef.current[0] = ref}
              />
            </div>
          </div>
        </div>

        <div className="flex lg:flex-row  sm:flex-col sm:space-y-2 pt-2">
          <div className="flex-1 sm:grid sm:grid-cols-3 sm:items-end sm:gap-2">
            <label className="block text-xs font-medium text-gray-700 mb-1 text-right">
              ประเภทการขนส่ง :<span className="text-red-500">* </span> :{labelError("logistic_type_id")}
            </label>
            <div className="mt-1 sm:col-span-2 sm:mt-0">
              <Select
                showSearch={true}
                allowClear={true}
                defaultValue={dataForm.logistic_type_id}
                hiddenLabel={true}
                placeholder="ประเภทการขนส่ง"
                validate={validation['logistic_type_id']}
                options={masterOptions.logistic_type}
                onChange={(value) => setDataForm({ ...dataForm, logistic_type_id: value })}
              />
            </div>
          </div>
          <div className="flex-1 sm:grid sm:grid-cols-3 sm:items-end sm:gap-2">
            <label className="block text-xs font-medium text-gray-700 mb-1 text-right">
              Ref Order No :
            </label>
            <div className="mt-1 sm:col-span-2 sm:mt-0">
              <Input
                id="ref_order_no"
                label="Ref Order No"
                placeholder="Ref Order No"
                hiddenLabel={true}
                defaultValue={dataForm.ref_order_no}
                onRef={(ref) => inputRef.current[2] = ref}
                maxLength={30}
              />
            </div>
          </div>
        </div>

        <div className="flex lg:flex-row  sm:flex-col sm:space-y-2 pt-2">
          <div className="flex-1 sm:grid sm:grid-cols-3 sm:items-end sm:gap-2">
            <label className="block text-xs font-medium text-gray-700 mb-1 text-right">
              Track No :
            </label>
            <div className="mt-1 sm:col-span-2 sm:mt-0">
              <Input
                id="track_no"
                label="Track No"
                placeholder="Track No"
                hiddenLabel={true}
                defaultValue={dataForm.track_no}
                onRef={(ref) => inputRef.current[3] = ref}
                maxLength={30}
              />
            </div>
          </div>
          <div className="flex-1 sm:grid sm:grid-cols-3 sm:items-end sm:gap-2" />
        </div>
      </div>
    </Modal>
  </>
  )
}))

export default ModalEditUpload