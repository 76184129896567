import { useEffect, useState } from 'react'
import { inject, observer } from 'mobx-react';
import FormMaster from '../FormMaster'
import { CommonService } from "../../../services";
import { isEmpty } from 'lodash';

let firstRender = false
const Vehicle = inject("authenStore")(observer((props) => {
  let authenStore = props.authenStore
  let { getAccountAuth } = authenStore // STATE
  let auth = { action_user_id: getAccountAuth?.user_id, token_id: getAccountAuth?.access_token }

  const [masterOptions, setMasterOptions] = useState({
    vehicle_province: [],
    service: [],
    vehicle_type: [],
    vehicle_register_type: [],
    vehicle_status: [],
    vehicle_brand: [],
    vehicle_model: [],
    vehicle_color: [],
    subcontract: [],
    fuel: []
  })

  useEffect(() => {
    if (firstRender || !isEmpty(getAccountAuth)) {
      CommonService.getMasterData(auth, "vehicle_province,service,vehicle_type,vehicle_register_type,vehicle_status,vehicle_brand,vehicle_model,vehicle_color,subcontract,fuel")
        .then((res: any) => {
          let vehicle_province = res.result.vehicle_province.map((item) => ({ label: item.province_name, value: item.province_id }))
          let service = res.result.service.map((item) => ({ label: item.service_name, value: item.service_id }))
          let vehicle_type = res.result.vehicle_type.map((item) => ({ label: item.vehicle_type_name, value: item.vehicle_type_id }))
          let vehicle_register_type = res.result.vehicle_register_type.map((item) => ({ label: item.register_type_name, value: item.register_type_id }))
          let vehicle_status = res.result.vehicle_status.map((item) => ({ label: item.status_name, value: item.status_code }))
          let vehicle_brand = res.result.vehicle_brand.map((item) => ({ label: item.brand_name, value: item.brand_id }))
          let vehicle_model = res.result.vehicle_model.map((item) => ({ label: item.model_name, value: item.model_id }))
          let vehicle_color = res.result.vehicle_color.map((item) => ({ label: item.color_name, value: item.color_id }))
          let subcontract = res.result.subcontract.map((item) => ({ label: item.sub_name, value: item.sub_id }))
          let fuel = res.result.fuel.map((item) => ({ label: item.fuel_name, value: item.fuel_id }))
          // setOptionStatus(common_status)
          setMasterOptions({
            ...masterOptions,
            vehicle_province,
            service,
            vehicle_type,
            vehicle_register_type,
            vehicle_status,
            vehicle_brand,
            vehicle_model,
            vehicle_color,
            subcontract,
            fuel
          })
        })
        .catch((err) => {
          console.log(`Load master data : ${err}`)
        })
    }
    else
      firstRender = true
  }, [getAccountAuth])

  return <FormMaster
    title="ข้อมูลรถ"
    tableConfig={{
      key: "id",
      apiPath: "vehicle/list",
      allowDelete: {
        visible: true,
        key: "vehicle_id",
        apiPath: "vehicle/delete",
        paramType: 'param'
      },
      optionDeleteFag: true,
      defaultColumn: [],
      columns: [
        {
          dataField: 'no',
          caption: "ลำดับ",
          width: 60,
          alignment: "center",
          allowFiltering: false
        },
        {
          dataField: 'licenseplate',
          caption: "ทะเบียนรถ",
          allowFiltering: true
        },
        {
          dataField: 'vehicle_name',
          caption: "เบอร์รถ",
          allowFiltering: true
        },
        {
          dataField: 'license_province_name',
          caption: "จังหวัด",
          allowFiltering: true
        },
        {
          dataField: 'chassis_no',
          caption: "เลขตัวถัง",
          allowFiltering: true
        },
        {
          dataField: 'vehicle_type_name',
          caption: "ประเภทรถ",
          allowFiltering: true
        },
        {
          dataField: 'driver_names',
          caption: "พนักงานขับรถ",
          allowFiltering: true
        },
        {
          dataField: 'status_name',
          caption: "สถานะ",
          allowFiltering: true
        },
        {
          dataField: 'updated_by',
          caption: "แก้ไขโดย"
        }
      ],
      exportExcel: {
        fileName: 'ข้อมูลรถ',
        columnConfig: [
          { label: 'ลำดับ', value: 'no' },
          { label: 'ทะเบียนรถ', value: 'licenseplate' },
          { label: 'เบอร์รถ', value: 'vehicle_name' },
          { label: 'จังหวัด', value: 'license_province_name' },
          { label: 'เลขตัวถัง', value: 'chassis_no' },
          { label: 'ประเภทรถ', value: 'vehicle_type_name' },
          { label: 'พนักงานขับรถ', value: 'driver_names' },
          { label: 'สถานะ', value: 'status_name' },
          { label: 'แก้ไขโดย', value: 'updated_by' }
        ]
      }
    }}
    formData={{
      sub_id: "",
      vehicle_name: "",
      licenseplate: "",
      licenseplate_tail: "",
      license_province_id: "",
      license_tail_province_id: "",
      vehicle_type_id: "",
      register_type_id: "",
      status: "Y",
      chassis_no: "",
      color_id: "",
      brand_id: "",
      model_id: "",
      fuel_id: "",
      driver_ids: ""
    }}
    modalConfig={{
      screenName: "vehicle",
      visible: true,
      title: "รถ",
      required: ["licenseplate", "vehicle_type_id", "sub_id"],
      fieldTypeNumber: [],
      paramName: "vehicle_id",
      // with: "w-2/3", 
      // with: "w-1/2",
      apiPath: {
        "get": '',
        "post": 'vehicle/add',
        "put": 'vehicle/update'
      },
      elements: [
        {
          fieldName: "licenseplate",
          label: "ทะเบียนรถ",
          placeholder: "ทะเบียนรถ",
          required: true,
          inputType: "text"
        },
        {
          fieldName: "license_province_id",
          label: "จังหวัดที่จดทะเบียน",
          placeholder: "จังหวัดที่จดทะเบียน",
          inputType: "select",
          allowClear: true,
          options: masterOptions.vehicle_province
        },
        {
          fieldName: "licenseplate_tail",
          label: "ทะเบียนหาง",
          placeholder: "ทะเบียนหาง",
          required: false,
          inputType: "text"
        },
        {
          fieldName: "license_tail_province_id",
          label: "จังหวัดที่จดทะเบียน",
          placeholder: "จังหวัดที่จดทะเบียน",
          inputType: "select",
          allowClear: true,
          options: masterOptions.vehicle_province
        },
        {
          fieldName: "vehicle_name",
          label: "เบอร์รถ",
          placeholder: "เบอร์รถ",
          required: false,
          inputType: "text"
        },
        {
          fieldName: "chassis_no",
          label: "เลขตัวถัง",
          placeholder: "เลขตัวถัง",
          required: false,
          inputType: "text"
        },
        {
          fieldName: "brand_id",
          label: "ยี่ห้อรถ",
          placeholder: "ยี่ห้อรถ",
          inputType: "select",
          allowClear: true,
          options: masterOptions.vehicle_brand
        },
        {
          fieldName: "model_id",
          label: "รุ่น",
          placeholder: "รุ่น",
          inputType: "select",
          allowClear: true,
          options: masterOptions.vehicle_model
        },
        {
          fieldName: "color_id",
          label: "สีรถ",
          placeholder: "สีรถ",
          inputType: "select",
          allowClear: true,
          options: masterOptions.vehicle_color
        },
        {
          fieldName: "fuel_id",
          label: "ประเภทเชื้อเพลิง",
          placeholder: "ประเภทเชื้อเพลิง",
          inputType: "select",
          allowClear: true,
          options: masterOptions.fuel
        },
        {
          fieldName: "vehicle_type_id",
          label: "ประเภทรถ",
          placeholder: "ประเภทรถ",
          required: true,
          inputType: "select",
          options: masterOptions.vehicle_type
        },
        {
          fieldName: "register_type_id",
          label: "ประเภทรถตามกรมขนส่งทางบก",
          placeholder: "ประเภทรถตามกรมขนส่งทางบก",
          inputType: "select",
          allowClear: true,
          options: masterOptions.vehicle_register_type
        },
        {
          fieldName: "weight",
          showUpdateOnly: true,
          label: "น้ำหนักที่บรรทุก",
          placeholder: "น้ำหนักที่บรรทุก",
          disabled: true,
          inputType: "text"
        },
        {
          fieldName: "dimension",
          showUpdateOnly: true,
          label: "พื้นที่ปริมาตร",
          placeholder: "พื้นที่ปริมาตร",
          disabled: true,
          inputType: "text"
        },
        {
          fieldName: "driver_ids",
          label: "พนักงานขับรถ",
          placeholder: "พนักงานขับรถ",
          inputType: "select",
          multiple: true,
          allowClear: true,
          options: masterOptions.service
        },
        {
          fieldName: "sub_id",
          label: "ผู้รับจ้างช่วง",
          placeholder: "ผู้รับจ้างช่วง",
          required: true,
          inputType: "select",
          options: masterOptions.subcontract
        },
        {
          showSearch: false,
          fieldName: "status",
          label: "สถานะ",
          placeholder: "สถานะ",
          inputType: "select",
          defaultValue: "Y",
          options: masterOptions.vehicle_status
        },
        {}
      ]
    }}
  />
}))

export default Vehicle