
import { useState } from 'react'
import { inject, observer } from 'mobx-react';
import { Modal, ModalAlert, FormLoading, Select } from '../../../components'
import { PriceModelService } from '../../../services';
import { isEmpty } from 'lodash'

const ModalPriceCopy = inject("priceModelStore")(observer((props) => {
  let priceModelStore = props.priceModelStore
  let { setPriceModeStore, openModalPriceCopy } = priceModelStore // STATE

  let { auth, masterOptions } = props // STATES
  let { onReload } = props // ACTIONS

  const [loading, setLoading]: any = useState({ visible: false, tip: 'กำลังบันทึก...' })
  const [validation, setValidation]: any = useState({})
  const [shakeIsWrong, setShakeIsWrong] = useState(false)
  const [optionAlert, setOptionAlert]: any = useState({
    show: false,
    action: {
      id: "",
      name: ""
    },
    type: "success",
    title: "",
    description: {
      visible: false,
      type: "",
      content: "",
      contentLists: [],

    }
  })
  const [dataForm, setDataForm]: any = useState({
    "from_cust": "",
    "to_cust": ""
  })

  const onSave = () => {
    let validateCheck = {}, message = { isError: true, message: "โปรดระบุ" }

    if (dataForm.from_cust.length === 0 || dataForm.from_cust === "")
      validateCheck['from_cust'] = message
    if (dataForm.to_cust.length === 0 || dataForm.to_cust === "")
      validateCheck['to_cust'] = message

    if (isEmpty(validateCheck)) {
      setValidation({})
      setOptionAlert({
        ...optionAlert,
        action: {
          id: dataForm.id,
          name: "save"
        },
        type: "confirm",
        title: "ยืนยัน",
        show: true,
        description: {
          visible: true,
          type: "text",
          content: "คุณแน่ใจที่จะบันทึกข้อมูล ?",
          contentLists: []
        }
      })
    }
    else {
      setValidation(validateCheck)
      setShakeIsWrong(true)
      setTimeout(() => setShakeIsWrong(false), 100);
    }
  }

  const saveData = () => {
    setLoading({ ...loading, visible: true, tip: 'กำลังบันทึก...' })
    PriceModelService.savePriceCopy(auth, {
      from_cust: dataForm.from_cust,
      to_cust: dataForm.to_cust,
    })
      .then((res: any) => {
        let { code, message, sys_message } = res.data

        if (code === 200) {
          setOptionAlert({
            ...optionAlert,
            type: "success",
            show: true,
            action: {
              id: "",
              name: ""
            },
            title: "บันทึกข้อมูลสำเร็จ",
            description: { visible: false, type: "text", content: " ", contentLists: [] }
          })
          setPriceModeStore({ openModalPriceCopy: false })
          clearForm()
          onReload()
        }
        else {
          setOptionAlert({
            ...optionAlert,
            type: "danger",
            show: true,
            action: {
              id: "",
              name: ""
            },
            title: "ผิดพลาด",
            description: { visible: true, type: "text", content: `${message} ${sys_message}` }
          })
        }
        setLoading({ ...loading, visible: false })
      })
      .catch((err) => {
        setOptionAlert({
          ...optionAlert,
          type: "danger",
          show: true,
          action: {
            id: "",
            name: ""
          },
          title: "ผิดพลาด",
          description: { visible: true, type: "text", content: `${err}` }
        })
        setLoading({ ...loading, visible: false })
      })
  }

  const clearForm = () => {
    setValidation({})
    setDataForm({
      "from_cust": "",
      "to_cust": ""
    })
  }

  return (<>
    <Modal
      title={{ text: `คัดลอกเงื่อนไข` }}
      open={openModalPriceCopy}
      maskClosable={true}
      buttonOk={{ text: "ตกลง" }}
      shake={shakeIsWrong}
      onClosed={() => {
        setPriceModeStore({ openModalPriceCopy: false })
        clearForm()
      }}
      onOk={() => onSave()}
    >
      <FormLoading loading={loading.visible} tip={loading.tip}>
        <div className="flex flex-col space-y-2">
          <div className='flex-1'>
            <Select
              showSearch={true}
              label="คัดลอกเงื่อนไขจาก"
              required={true}
              placeholder="คัดลอกเงื่อนไขจาก"
              validate={validation?.from_cust}
              onChange={(select) => setDataForm({ ...dataForm, from_cust: select })}
              defaultValue={dataForm.from_cust === "" ? [] : dataForm.from_cust}
              options={masterOptions.customer}
            />
          </div>

          <div className='flex-1'>
            <Select
              showSearch={true}
              label="นำไปใช้กับลูกค้า"
              required={true}
              placeholder="นำไปใช้กับลูกค้า"
              validate={validation?.to_cust}
              onChange={(select) => setDataForm({ ...dataForm, to_cust: select })}
              defaultValue={dataForm.to_cust === "" ? [] : dataForm.to_cust}
              options={masterOptions.customer}
            />
          </div>
        </div>
      </FormLoading>
    </Modal>

    <ModalAlert
      {...optionAlert}
      onOk={() => {
        let { action } = optionAlert
        if (action.name === "save") saveData()
        setOptionAlert({ ...optionAlert, show: false })
      }}
      onCancel={() => {
        setOptionAlert({ ...optionAlert, show: false })
      }}
    />
  </>
  )
}))

export default ModalPriceCopy