import { useEffect, useState } from 'react'
import { inject, observer } from 'mobx-react';
import FormMaster from '../FormMaster'
import { CommonService } from "../../../services";
import { isEmpty } from 'lodash';

let firstRender = false
const VehicleType = inject("authenStore")(observer((props) => {
  let authenStore = props.authenStore
  let { getAccountAuth } = authenStore // STATE
  let auth = { action_user_id: getAccountAuth?.user_id, token_id: getAccountAuth?.access_token }

  const [optionStatus, setOptionStatus] = useState([])

  useEffect(() => {
    if (firstRender || !isEmpty(getAccountAuth)) {
      CommonService.getMasterData(auth, "common_status")
        .then((res: any) => {
          let options = res.result.common_status.map((item) => ({ label: item.status_name, value: item.status_code }))
          setOptionStatus(options)
        })
        .catch((err) => {
          console.log(`Load master data : ${err}`)
        })
    }
    else
      firstRender = true
  }, [getAccountAuth])

  return <FormMaster
    title="ข้อมูลประเภทรถ"
    tableConfig={{
      key: "id",
      apiPath: "vehicle_type/list",
      allowDelete: {
        visible: true,
        key: "vehicle_type_id",
        apiPath: "vehicle_type/delete",
        paramType: 'body'
      },
      optionDeleteFag: true,
      defaultColumn: [],
      columns: [
        {
          dataField: 'no',
          caption: "ลำดับ",
          width: 60,
          alignment: "center",
          allowFiltering: false
        },
        {
          dataField: 'vehicle_type_code',
          caption: "รหัสประเภทรถ",
          allowFiltering: true
        },
        {
          dataField: 'vehicle_type_name',
          caption: "ชื่อประเภทรถ",
          allowFiltering: true
        },
        {
          dataField: 'weight',
          caption: "น้ำหนักบรรทุก"
        },
        {
          dataField: 'dimension',
          caption: "พื้นที่บรรทุก"
        },
        {
          dataField: 'status_name',
          caption: "สถานะ",
          allowFiltering: true
        },
        {
          dataField: 'updated_by',
          caption: "แก้ไขโดย"
        }
      ],
      exportExcel: {
        fileName: 'ข้อมูลประเภทรถ',
        columnConfig: [
          { label: 'ลำดับ', value: 'no' },
          { label: 'รหัสประเภทรถ', value: 'vehicle_type_code' },
          { label: 'ชื่อประเภทรถ', value: 'vehicle_type_name' },
          { label: 'น้ำหนักบรรทุก', value: 'weight' },
          { label: 'พื้นที่บรรทุก', value: 'dimension' },
          { label: 'สถานะ', value: 'status_name' },
          { label: 'แก้ไขโดย', value: 'updated_by' }
        ]
      }
    }}
    formData={{
      vehicle_type_code: "",
      vehicle_type_name: "",
      dimension: "",
      weight: "",
      status: "Y"
    }}
    modalConfig={{
      visible: true,
      title: "ประเภทรถ",
      required: ["vehicle_type_name"],
      fieldTypeNumber: [],
      paramName: "vehicle_type_id",
      apiPath: {
        "get": '',
        "post": 'vehicle_type/add',
        "put": 'vehicle_type/update'
      },
      elements: [
        {
          fieldName: "vehicle_type_code",
          label: "รหัสประเภทรถ",
          placeholder: "รหัสประเภทรถ",
          required: false,
          inputType: "text",
          isOneRow: true
        },
        {
          fieldName: "vehicle_type_name",
          label: "ชื่อประเภทรถ",
          placeholder: "ชื่อประเภทรถ",
          required: true,
          inputType: "text",
          isOneRow: true
        },
        {
          fieldName: "weight",
          label: "น้ำหนักบรรทุก (ตัน)",
          placeholder: "น้ำหนักบรรทุก (ตัน)",
          required: false,
          inputType: "number",
          isOneRow: true
        },
        {
          fieldName: "dimension",
          label: "พื้นที่บรรทุก (ลูกบาศก์เมตร)",
          placeholder: "พื้นที่บรรทุก (ลูกบาศก์เมตร)",
          required: false,
          inputType: "number",
          isOneRow: true
        },
        {
          showSearch: false,
          fieldName: "status",
          label: "สถานะ",
          inputType: "select",
          defaultValue: "Y",
          options: optionStatus
        },
        // {
        //   fieldName: "description",
        //   label: "คำอธิบาย",
        //   placeholder: "คำอธิบาย",
        //   required: false,
        //   inputType: "text-area",
        //   rows: 2,
        //   isOneRow: true
        // }
      ]
    }}
  />
}))

export default VehicleType