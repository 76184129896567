import { useEffect, useState, useRef } from 'react'
import { inject, observer } from 'mobx-react';
import FormMaster from '../FormMaster'
import { createMultipleRef, getValueInputRef } from '../../../helpers'
import { Modal, Input, Select, ModalAlert } from '../../../components'
import { CommonService, CustomerService } from "../../../services";
import { isEmpty } from 'lodash'

let firstRender = false
const Shipto = inject("masterStore", "authenStore")(observer((props) => {
  let masterStore = props.masterStore
  let authenStore = props.authenStore
  let { getAccountAuth } = authenStore // STATE
  let auth = { action_user_id: getAccountAuth?.user_id, token_id: getAccountAuth?.access_token }

  let { setMasterStore } = masterStore
  const [modalConfig, setModalConfig]: any = useState({
    show: false,
    action: "",
    id: ""
  })

  const [loading, setLoading]: any = useState(false)
  const [optionStatus, setOptionStatus] = useState([])
  const [optionCustomer, setOptionCustomer] = useState([])
  const [optionProvince, setOptionProvince] = useState([])
  const [optionDistrict, setOptionDistrict] = useState([])
  const [zoneSet, setZoneSet] = useState([])
  const [optionSubDistrict, setOptionSubDistrict] = useState([])
  const [validation, setValidation]: any = useState({})
  const [shakeIsWrong, setShakeIsWrong] = useState(false)
  const [dataForm, setDataForm]: any = useState({
    id: "",
    cust_id: "",
    cust_name: "",
    ini_name: "",
    address: "",
    village: "",
    soi: "",
    road: "",
    admin_level1_id: "",
    admin_level2_id: "",
    admin_level3_id: "",
    post_code: "",
    tel: "",
    taxid: "",
    ref_code: "",
    zone_set: ""
  })
  const [optionAlert, setOptionAlert]: any = useState({
    show: false,
    action: {
      id: "",
      name: ""
    },
    type: "success",
    title: "",
    description: {
      visible: false,
      type: "",
      content: "",
      contentLists: [],

    }
  })

  const inputRef: any = useRef([]);
  createMultipleRef(inputRef, 10)

  useEffect(() => {
    if (firstRender || !isEmpty(getAccountAuth)) {
      CommonService.getMasterData(auth, "common_status,customer,admin_level1,zone_set")
        .then((res: any) => {
          let common_status = res.result.common_status.map((item) => ({ label: item.status_name, value: item.status_code }))
          let customer = res.result.customer.map((item) => ({ label: item.cust_name, value: item.cust_id }))
          let admin_level1 = res.result.admin_level1.map((item) => ({ label: item.admin_level1_name, value: item.admin_level1_id }))
          let zone_set = res.result.zone_set.map((item) => ({ label: item.zone_set_name, value: item.zone_set_code }))

          setOptionStatus(common_status)
          setOptionCustomer(customer)
          setOptionProvince(admin_level1)
          setZoneSet(zone_set)
        })
        .catch((err) => {
          setOptionAlert({
            ...optionAlert,
            type: "danger",
            show: true,
            action: {
              id: "",
              name: ""
            },
            title: "ผิดพลาด",
            description: { visible: true, content: `Load master data : ${err}` }
          })
        })
    }
    else
      firstRender = true
  }, [getAccountAuth])

  const getAddressMaster = (id_ref1, id_ref2) => { // GET ADDRESS MASTER 
    CommonService.getMasterData(auth, "admin_level1,admin_level2,admin_level3", id_ref1, id_ref2)
      .then((res: any) => {
        let admin_level1 = res.result.admin_level1.map((item) => ({ label: item.admin_level1_name, value: item.admin_level1_id }))
        let admin_level2 = res.result.admin_level2.map((item) => ({ label: item.admin_level2_name, value: item.admin_level2_id }))
        let admin_level3 = res.result.admin_level3.map((item) => ({ label: item.admin_level3_name, value: item.admin_level3_id }))
        setOptionProvince(admin_level1)
        setOptionDistrict(admin_level2)
        setOptionSubDistrict(admin_level3)
      })
      .catch((err) => {
        setOptionAlert({
          ...optionAlert,
          type: "danger",
          show: true,
          action: {
            id: "",
            name: ""
          },
          title: "ผิดพลาด",
          description: { visible: true, content: `Load master data : ${err}` }
        })
      })
  }

  const onChangeProvince = (id_ref1) => { // GET MASTER DISTRICT
    if (id_ref1) {
      setDataForm({ ...dataForm, admin_level1_id: id_ref1 })
      CommonService.getMasterData(auth, "admin_level2", id_ref1)
        .then((res: any) => {
          let admin_level2 = res.result.admin_level2.map((item) => ({ label: item.admin_level2_name, value: item.admin_level2_id }))
          setOptionDistrict(admin_level2)
        })
        .catch((err) => {
          setOptionAlert({
            ...optionAlert,
            type: "danger",
            show: true,
            action: {
              id: "",
              name: ""
            },
            title: "ผิดพลาด",
            description: { visible: true, content: `Load master data : ${err}` }
          })
        })
    }
    else {
      setDataForm({ ...dataForm, admin_level1_id: "", admin_level2_id: "", admin_level3_id: "" })
      setOptionDistrict([])
    }
  }

  const onChangeDistrict = (id_ref2) => { // GET MASTER DISTRICT
    if (id_ref2) {
      setDataForm({ ...dataForm, admin_level2_id: id_ref2 })
      CommonService.getMasterData(auth, "admin_level3", dataForm.admin_level1_id, id_ref2)
        .then((res: any) => {
          let admin_level3 = res.result.admin_level3.map((item) => ({ label: item.admin_level3_name, value: item.admin_level3_id }))
          setOptionSubDistrict(admin_level3)
        })
        .catch((err) => {

        })
    }
    else {
      setDataForm({ ...dataForm, admin_level2_id: "", admin_level3_id: "" })
      setOptionSubDistrict([])
    }
  }

  //#region 
  const onSave = () => {
    let validateCheck = {}, message = { isError: true, message: "โปรดระบุ" }
    let formData = getValueInputRef(inputRef)

    if (formData.cust_code.trim() === "")
      validateCheck['cust_code'] = message
    if (formData.cust_name.trim() === "")
      validateCheck['cust_name'] = message

    if (isEmpty(validateCheck)) {
      setValidation({})
      setOptionAlert({
        ...optionAlert,
        action: {
          id: dataForm.id,
          name: dataForm.id === "" ? 'create' : 'update'
        },
        type: "confirm",
        title: "ยืนยัน",
        show: true,
        description: {
          visible: true,
          type: "text",
          content: "คุณแน่ใจที่จะบันทึกข้อมูล ?",
          contentLists: []
        }
      })
    }
    else {
      setValidation(validateCheck)
      setShakeIsWrong(true)
      setTimeout(() => setShakeIsWrong(false), 100);
    }
  }

  const saveData = () => {
    let formData = getValueInputRef(inputRef)

    let body = {
      cust_code: formData.cust_code,
      cust_name: formData.cust_name,
      ini_name: formData.ini_name,
      address: formData.address,
      village: formData.village,
      soi: formData.soi,
      road: formData.road,
      admin_level1_id: dataForm.admin_level1_id === "" ? 0 : dataForm.admin_level1_id,
      admin_level2_id: dataForm.admin_level2_id === "" ? 0 : dataForm.admin_level2_id,
      admin_level3_id: dataForm.admin_level3_id === "" ? 0 : dataForm.admin_level3_id,
      post_code: formData.post_code,
      tel: formData.tel,
      taxid: formData.taxid,
      ref_code: formData.ref_code,
      zone_set: dataForm.zone_set,
    }

    if (optionAlert.action.name === "create") {
      CustomerService.addCustomer(auth, body)
        .then((res: any) => {
          let { code, message } = res.data
          if (code === 200) {
            setOptionAlert({
              ...optionAlert,
              type: "success",
              show: true,
              action: {
                id: "",
                name: ""
              },
              title: "บันทึกข้อมูลสำเร็จ",
              description: { visible: false, type: "text", content: " ", contentLists: [] }
            })
            setMasterStore({ isRefreshData: true })
            clearDataForm()
            setModalConfig({ ...modalConfig, show: false })
          }
          else {
            setOptionAlert({
              ...optionAlert,
              type: "danger",
              show: true,
              action: {
                id: "",
                name: ""
              },
              title: "ผิดพลาด",
              description: { visible: true, type: "text", content: message }
            })
          }
          setLoading(false)

        })
        .catch((err) => {
          setOptionAlert({
            ...optionAlert,
            type: "danger",
            show: true,
            action: {
              id: "",
              name: ""
            },
            title: "ผิดพลาด",
            description: { visible: true, type: "text", content: `${err}` }
          })
          setLoading(false)
        })
    }
    else {
      CustomerService.updateCustomer(auth, {
        ...body,
        cust_id: dataForm.id
      })
        .then((res: any) => {
          let { code, message } = res.data
          if (code === 200) {
            setOptionAlert({
              ...optionAlert,
              type: "success",
              show: true,
              action: {
                id: "",
                name: ""
              },
              title: "บันทึกข้อมูลสำเร็จ",
              description: { visible: false, type: "text", content: " ", contentLists: [] }
            })
            setMasterStore({ isRefreshData: true })
            clearDataForm()
            setModalConfig({ ...modalConfig, show: false })
          }
          else {
            setOptionAlert({
              ...optionAlert,
              type: "danger",
              show: true,
              action: {
                id: "",
                name: ""
              },
              title: "ผิดพลาด",
              description: { visible: true, type: "text", content: message }
            })
          }
          setLoading(false)

        })
        .catch((err) => {
          setOptionAlert({
            ...optionAlert,
            type: "danger",
            show: true,
            action: {
              id: "",
              name: ""
            },
            title: "ผิดพลาด",
            description: { visible: true, type: "text", content: `${err}` }
          })
          setLoading(false)
        })
    }
  }
  //#endregion

  const clearDataForm = () => {
    setValidation({})
    setDataForm({
      id: "",
      cust_id: "",
      cust_name: "",
      ini_name: "",
      address: "",
      village: "",
      soi: "",
      road: "",
      admin_level1_id: "",
      admin_level2_id: "",
      admin_level3_id: "",
      post_code: "",
      tel: "",
      taxid: "",
      ref_code: "",
      zone_set: ""
    })
  }

  return <>
    <ModalAlert
      {...optionAlert}
      onOk={() => {
        let { action } = optionAlert
        if (["create", "update"].includes(action.name)) {
          setLoading(true)
          saveData()
        }
        setOptionAlert({ ...optionAlert, show: false })
      }}
      onCancel={() => {
        setOptionAlert({ ...optionAlert, show: false })
      }}
    />

    <FormMaster
      title="ข้อมูลลูกค้า"
      tableConfig={{
        key: "id",
        apiPath: "customer/list",
        allowDelete: {
          visible: true,
          key: "cust_id",
          apiPath: "customer/delete",
          paramType: "param"
        },
        optionDeleteFag: true,
        defaultColumn: [],
        columns: [
          {
            dataField: 'no',
            caption: "ลำดับ",
            width: 60,
            alignment: "center",
            allowFiltering: false
          },
          {
            dataField: 'cust_code',
            caption: "รหัสลูกค้า",
            allowFiltering: true
          },
          {
            dataField: 'cust_name',
            caption: "ชื่อลูกค้า",
            allowFiltering: true
          },
          {
            dataField: 'ini_name',
            caption: "ชื่อย่อ",
            allowFiltering: true
          },
          {
            dataField: 'tel',
            caption: "เบอร์โทร"
          },
          {
            dataField: 'taxid',
            caption: "หมายเลขประจำตัวผู้เสียภาษี",
            allowFiltering: true
          },
          {
            dataField: 'ref_code',
            caption: "เลขอ้างอิง",
            allowFiltering: true
          },
          {
            dataField: 'updated_by',
            caption: "แก้ไขโดย"
          }
        ],
        exportExcel: {
          fileName: 'ข้อมูลลูกค้า',
          columnConfig: [
            { label: 'ลำดับ', value: 'no' },
            { label: 'รหัสลูกค้า', value: 'cust_code' },
            { label: 'ชื่อลูกค้า', value: 'cust_name' },
            { label: 'ชื่อย่อ', value: 'ini_name' },
            { label: 'เบอร์โทร', value: 'tel' },
            { label: 'หมายเลขประจำตัวผู้เสียภาษี', value: 'taxid' },
            { label: 'เลขอ้างอิง', value: 'ref_code' },
            { label: 'แก้ไขโดย', value: 'updated_by' }
          ]
        }
      }}
      formData={{
        cust_id: "",
        shipto_name: "",
        address: "",
        village: "",
        soi: "",
        road: "",
        admin_level1_id: "",
        admin_level2_id: "",
        admin_level3_id: "",
        post_code: "",
        tel: "",
        ref_code: "",
        dtopen: "00:00",
        dtclose: "00:00",
        pickup_mins: "",
        pickdown_mins: ""
      }}
      modalConfig={{
        visible: false,
        title: "ลูกค้า",
        required: [],
        fieldTypeNumber: [],
        paramName: "shipto_id",
        apiPath: {
          "get": '',
          "post": 'shipto/add',
          "put": 'shipto/update'
        },
        elements: []
      }}
      onAdd={(data) => {
        setModalConfig(data.modalConfig)
      }}
      onEdit={(data) => {
        setModalConfig(data.modalConfig)

        let dt = data.modalConfig.dataSelectRow
        getAddressMaster(dt.admin_level1_id, dt.admin_level2_id)
        setDataForm({
          ...dataForm,
          id: dt.id,
          cust_code: dt.cust_code,
          cust_name: dt.cust_name,
          ini_name: dt.ini_name,
          address: dt.address,
          village: dt.village,
          soi: dt.soi,
          road: dt.road,
          admin_level1_id: dt.admin_level1_id === 0 ? "" : dt.admin_level1_id,
          admin_level2_id: dt.admin_level2_id === 0 ? "" : dt.admin_level2_id,
          admin_level3_id: dt.admin_level3_id === 0 ? "" : dt.admin_level3_id,
          post_code: dt.post_code,
          tel: dt.tel,
          taxid: dt.taxid,
          ref_code: dt.ref_code,
          zone_set: dt.zone_set
        })
      }}
    />

    <Modal
      title={{ text: `${modalConfig.action === 'add' ? 'เพิ่ม' : 'แก้ไข'}ลูกค้า` }}
      open={modalConfig.show}
      maskClosable={true}
      shake={shakeIsWrong}
      onClosed={() => {
        clearDataForm()
        setModalConfig({ ...modalConfig, show: false })
      }}
      onOk={() => onSave()}
    >
      <div className='md:space-y-2'>
        <div className='flex md:flex-row sm:flex-col xs:flex-col md:space-x-4'>
          <div className='flex-1'>
            <Input
              id="cust_code"
              label="รหัสลูกค้า"
              placeholder="รหัสลูกค้า"
              required={true}
              validate={validation['cust_code']}
              defaultValue={dataForm.cust_code}
              onRef={(ref) => inputRef.current[0] = ref}
            />
          </div>

          <div className='flex-1'>
            <Input
              id="cust_name"
              label="ชื่อลูกค้า"
              placeholder="ชื่อลูกค้า"
              required={true}
              validate={validation['cust_name']}
              defaultValue={dataForm.cust_name}
              onRef={(ref) => inputRef.current[1] = ref}
            />
          </div>
        </div>
        <div className='flex md:flex-row sm:flex-col xs:flex-col md:space-x-4'>
          <div className='flex-1'>
            <Input
              id="ini_name"
              label="ชื่อย่อ"
              placeholder="ชื่อย่อ"
              defaultValue={dataForm.ini_name}
              onRef={(ref) => inputRef.current[10] = ref}
            />
          </div>

          <div className='flex-1'>
            <Input
              id="address"
              label="ที่อยู่"
              placeholder="ที่อยู่"
              validate={validation['address']}
              defaultValue={dataForm.address}
              onRef={(ref) => inputRef.current[2] = ref}
            />
          </div>
        </div>

        <div className='flex md:flex-row sm:flex-col xs:flex-col md:space-x-4'>
          <div className='flex-1'>
            <Input
              id="village"
              label="ชื่อโครงการ/อาคาร"
              placeholder="ชื่อโครงการ/อาคาร"
              validate={validation['village']}
              defaultValue={dataForm.village}
              onRef={(ref) => inputRef.current[3] = ref}
            />
          </div>

          <div className='flex-1'>
            <Input
              id="soi"
              label="ซอย"
              placeholder="ซอย"
              validate={validation['soi']}
              defaultValue={dataForm.soi}
              onRef={(ref) => inputRef.current[4] = ref}
            />
          </div>
        </div>

        <div className='flex md:flex-row sm:flex-col xs:flex-col md:space-x-4'>
          <div className='flex-1'>
            <Input
              id="road"
              label="ถนน"
              placeholder="ถนน"
              validate={validation['road']}
              defaultValue={dataForm.road}
              onRef={(ref) => inputRef.current[5] = ref}
            />
          </div>

          <div className='flex-1'>
            <Select
              allowClear={true}
              showSearch={true}
              label="จังหวัด"
              placeholder="จังหวัด"
              validate={validation['admin_level1_id']}
              defaultValue={dataForm.admin_level1_id}
              options={optionProvince}
              onChange={(value) => onChangeProvince(value)}
            />
          </div>
        </div>

        <div className='flex md:flex-row sm:flex-col xs:flex-col md:space-x-4'>
          <div className='flex-1'>
            <Select
              showSearch={true}
              allowClear={true}
              label="อำเภอ"
              placeholder="อำเภอ"
              validate={validation['admin_level2_id']}
              defaultValue={dataForm.admin_level2_id}
              disabled={dataForm.admin_level1_id === "" ? true : false}
              options={optionDistrict}
              onChange={(value) => onChangeDistrict(value)}
            />
          </div>

          <div className='flex-1'>
            <Select
              showSearch={true}
              allowClear={true}
              label="ตำบล"
              placeholder="ตำบล"
              validate={validation['admin_level3_id']}
              defaultValue={dataForm.admin_level3_id}
              disabled={dataForm.admin_level2_id === "" ? true : false}
              options={optionSubDistrict}
              onChange={(value) => setDataForm({ ...dataForm, admin_level3_id: value ? value : '' })}
            />
          </div>
        </div>

        <div className='flex md:flex-row sm:flex-col xs:flex-col md:space-x-4'>
          <div className='flex-1'>
            <Input
              id="post_code"
              label="รหัสไปรษณีย์"
              placeholder="รหัสไปรษณีย์"
              validate={validation['post_code']}
              defaultValue={dataForm.post_code}
              onRef={(ref) => inputRef.current[6] = ref}
            />
          </div>

          <div className='flex-1'>
            <Input
              id="tel"
              label="เบอร์โทร"
              placeholder="เบอร์โทร"
              validate={validation['tel']}
              defaultValue={dataForm.tel}
              onRef={(ref) => inputRef.current[7] = ref}
            />
          </div>
        </div>

        <div className='flex md:flex-row sm:flex-col xs:flex-col md:space-x-4'>
          <div className='flex-1'>
            <Input
              id="taxid"
              label="หมายเลขประจำตัวผู้เสียภาษี"
              placeholder="หมายเลขประจำตัวผู้เสียภาษี"
              validate={validation['taxid']}
              defaultValue={dataForm.taxid}
              onRef={(ref) => inputRef.current[8] = ref}
            />
          </div>
          <div className='flex-1'>
            <Input
              id="ref_code"
              label="เลขอ้างอิง"
              placeholder="เลขอ้างอิง"
              validate={validation['ref_code']}
              defaultValue={dataForm.ref_code}
              onRef={(ref) => inputRef.current[9] = ref}
            />
          </div>
        </div>

        <div className='flex md:flex-row sm:flex-col xs:flex-col md:space-x-4'>
          <div className='flex-1'>
            <Select
              showSearch={true}
              allowClear={true}
              label="กลุ่มโซน"
              placeholder="กลุ่มโซน"
              defaultValue={dataForm.zone_set}
              options={zoneSet}
              onChange={(value) => setDataForm({ ...dataForm, zone_set: value ? value : '' })}
            />
          </div>

          <div className='flex-1' />
        </div>
      </div>
    </Modal>
  </>
}))

export default Shipto