import FormMaster from '../FormMaster'
const VehicleGroup = () => {
  return <FormMaster
    title="ข้อมูลกลุ่มรถ"
    tableConfig={{
      key: "id",
      apiPath: "carrier",
      optionDeleteFag: true,
      defaultColumn: ["carrier_name", "contract_name", "phone", "address", "latlng", "status"],
      columns: [
        {
          dataField: 'carrier_name',
          caption: "ชื่อกลุ่มรถ",
          allowFiltering: true
        },
        {
          dataField: 'contract_name',
          caption: "รายการทะเบียนรถ"
        }
      ],
      exportExcel: {
        fileName: 'ข้อมูลกลุ่มรถ',
        columnConfig: [
          { label: 'ลำดับ', value: 'no' },
          { label: 'ชื่อกลุ่มรถ', value: 'carrier_name' },
          { label: 'รายการทะเบียนรถ', value: 'contract_name' }
        ]
      }
    }}
    formData={{
      carrier_code: "",
      carrier_name: "",
      phone: "",
      address: false,
      latlng: false,
      status: "Y"
    }}
    modalConfig={{
      visible: true,
      title: "เพิ่มกลุ่มรถ",
      required: [],
      fieldTypeNumber: ["carrier_country_id"],
      paramName: "carrier_id",
      apiPath: {
        "get": 'carrier?carrier_id={carrier_id}',
        "post": 'carrier',
        "put": 'carrier'
      },
      elements: [
        {
          fieldName: "carrier_name",
          label: "ชื่อกลุ่มรถ",
          placeholder: "ชื่อกลุ่มรถ",
          required: true,
          inputType: "text",
          isOneRow: true
        },
        {
          fieldName: "contract_name",
          label: "ทะเบียนรถที่จัดกลุ่ม",
          placeholder: "ทะเบียนรถที่จัดกลุ่ม",
          required: true,
          inputType: "text",
          isOneRow: true
        }
      ]
    }}
  />
}

export default VehicleGroup