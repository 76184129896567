
import { useEffect, useState } from "react";
import { inject, observer } from 'mobx-react';
import { Card, Button, Modal, FormLoading } from '../../components'
import { SearchIcon } from '@heroicons/react/outline'
import { Input as InputAntd } from 'antd';
import { Input, DatePicker, Select, ModalAlert } from "../../components";
import { CheckIcon } from "@heroicons/react/solid";
import DataGrid, { Column } from 'devextreme-react/data-grid';
import './style.css'
import JobPlan from "./JobPlan";
import { CommonService, QueueService, RouteService } from "../../services";
import moment from 'moment';
import { isEmpty } from 'lodash';

let firstRender = false
const { TextArea } = InputAntd;
let images = [
  {
    id: "1",
    image: "https://tailwindui.com/img/ecommerce-images/category-page-04-image-card-02.jpg",
    file_name: "ภาพถ่ายรับสินค้า",
    time: "02/03/2023 10:10",
    latlng: "17.02324, 100.9023"
  },
  {
    id: "2",
    image: "https://tailwindui.com/img/ecommerce-images/category-page-04-image-card-03.jpg",
    file_name: "ภาพถ่ายสินค้า",
    time: "02/03/2023 10:12",
    latlng: "17.02362, 100.9020"
  }
]

let imagesSignature = [
  {
    id: "1",
    image: "/Images/pic-signature.png",
    file_name: "ลายเซ็น",
    time: "02/03/2023 10:16",
    latlng: "17.02324, 100.9023",
  }
]

// const Plan = (props) => {
const Plan = inject("routeStore", "authenStore")(observer((props) => {
  let routeStore = props.routeStore
  let authenStore = props.authenStore
  let { getAccountAuth } = authenStore // STATE
  let auth = { action_user_id: getAccountAuth?.user_id, token_id: getAccountAuth?.access_token }

  let { setRouteStore } = routeStore // ACTIONS

  const [loading, setLoading] = useState({ visible: false, tip: "กำลังโหลด" })
  const [dataQueue, setDataQueue]: any = useState([])
  const [dataRoute, setDataRoute]: any = useState([])
  const [dataDash, setDataDash]: any = useState({
    all: 0,
    open: 0,
    plan: 0,
    confirm: 0
  })
  const [queueSelected, setQueueSelected]: any = useState([])
  const [routeSelected, setRouteSelected]: any = useState([])
  const [modalInfo, setModalInfo]: any = useState({
    open: false,
    title: "",
    name: "",
    icon: ''
  })
  const [filter, setFilter]: any = useState({
    delivery_date: moment().format("YYYY-MM-DD"),
    loc_receipt_id: "",
    shipto_id: "",
    qno: "",
    doc_no: "",
    job_type_id: "",
    route_id: "",
    vid: "",
    service_id: "",
    cust_id: "",
    queue_status_id: "",
    route_status_id: ""
  })

  const [filterQueueCurrent, setFilterQueueCurrent]: any = useState({})
  const [filterRouteCurrent, setFilterRouteCurrent]: any = useState({})

  const [queueDateCurrent, setQueueDateCurrent]: any = useState("")
  const [routeDateCurrent, setRouteDateCurrent]: any = useState("")
  const [optionAlert, setOptionAlert]: any = useState({
    show: false,
    action: {
      id: "",
      name: ""
    },
    type: "success",
    title: "",
    description: {
      visible: false,
      type: "",
      content: "",
      contentLists: [],

    }
  })

  const stats = [
    { name: 'ทั้งหมด', stat: dataDash.all },
    { name: 'เปิดงาน', stat: dataDash.open },
    { name: 'วางแผน', stat: dataDash.plan },
    { name: 'ยืนยัน', stat: dataDash.confirm }
  ]

  let columns: any = [
    {
      dataField: 'image',
      caption: "ภาพถ่าย",
      alignment: "center",
      render: (e) => {
        return <div className="flex justify-center">
          <img
            src={e.value}
            className="w-36 h-36 mt-0.5 cursor-pointer"
          />
        </div>
      }
    },
    {
      dataField: 'file_name',
      caption: "ชื่อไฟล์ภาพ",
      alignment: "center"
    },
    {
      dataField: 'time',
      caption: "เวลา",
      alignment: "center"
    },
    {
      dataField: 'latlng',
      caption: "พิกัด",
      alignment: "center"
    }
  ]

  const [masterOptions, setMasterOptions] = useState({
    customer: [],
    shipto: [],
    service: [],
    vehicle: [],
    shipping_point: [],
    route_status: [],
    queue_status: []
  })

  useEffect(() => {
    if (firstRender || !isEmpty(getAccountAuth)) {
      setRouteStore({ defaultDeliveryDate: filter?.delivery_date })
      CommonService.getMasterData(auth, "service,customer,shipto,vehicle,shipping_point,route_status,queue_status")
        .then((res: any) => {
          let customer = res.result.customer.map((item) => ({ label: item.cust_name, value: item.cust_id }))
          let shipto = res.result.shipto.map((item) => ({ label: item.shipto_name, value: item.shipto_id }))
          let service = res.result.service.map((item) => ({ label: item.service_name, value: item.service_id }))
          let vehicle = res.result.vehicle.map((item) => ({ label: item.licenseplate, value: item.vehicle_id }))
          let shipping_point = res.result.shipping_point.map((item) => ({ label: item.shipping_name, value: item.shipping_id }))
          let route_status = res.result.route_status.map((item) => ({ label: item.status_name, value: item.status_code }))
          let queue_status = res.result.queue_status.map((item) => ({ label: item.status_name, value: item.status_id }))

          setMasterOptions({
            ...masterOptions,
            customer,
            shipto,
            service,
            vehicle,
            shipping_point,
            route_status,
            queue_status
          })
        })
        .catch((err) => {

        })
    }
    else
      firstRender = true
  }, [getAccountAuth])


  //#region QUEUE MANAMENT
  const searchQueue = (date = "", isReloadCurrent = false) => {
    setLoading({ ...loading, visible: true, tip: 'กำลังโหลด...' })
    let delivery_date = date === "" ? filter.delivery_date : date
    setQueueDateCurrent(delivery_date)

    let body: any = {}
    if (isReloadCurrent) {
      body = filterQueueCurrent
    }
    else {
      body = {
        delivery_date,
        loc_receipt_id: filter.loc_receipt_id,
        shipto_id: filter.shipto_id,
        qno: filter.qno,
        job_type_id: filter.job_type_id,
        route_id: filter.route_id,
        cust_id: filter.cust_id,
        status: filter.queue_status_id,
      }
    }

    setFilterQueueCurrent(body)
    RouteService.getQueue(auth, body)
      .then((res: any) => {
        let { code, message, sys_message, result } = res
        if (code === 200) {
          setDataQueue(result)
        }
        else {
          setOptionAlert({
            ...optionAlert,
            type: "danger",
            show: true,
            action: {
              id: "",
              name: ""
            },
            title: "ผิดพลาด",
            description: { visible: true, type: "text", content: `${message} ${sys_message}` }
          })
        }
        setLoading({ ...loading, visible: false, tip: 'กำลังโหลด...' })
      })
      .catch((err) => {
        setLoading({ ...loading, visible: false, tip: 'กำลังโหลด...' })
        setOptionAlert({
          ...optionAlert,
          type: "danger",
          show: true,
          action: {
            id: "",
            name: ""
          },
          title: "ผิดพลาด",
          description: { visible: true, type: "text", content: `${err}` }
        })
      })
  }
  const deleteQueue = (qSelected) => {
    let qids = ""
    if (qSelected.length > 0) qids = qSelected.join(',')

    QueueService.deleteQueue(auth, qids)
      .then((res: any) => {
        let { code, message, sys_message } = res
        if (code === 200) {
          setOptionAlert({
            ...optionAlert,
            type: "success",
            show: true,
            action: {
              id: "",
              name: ""
            },
            title: "ลบข้อมูลสำเร็จ",
            description: { visible: false, type: "text", content: " ", contentLists: [] }
          })
          searchQueue("", true)
          getDash()
        }
        else {
          setOptionAlert({
            ...optionAlert,
            type: "danger",
            show: true,
            action: {
              id: "",
              name: ""
            },
            title: "ผิดพลาด",
            description: { visible: true, type: "text", content: `${message} ${sys_message}` }
          })
        }
      })
      .catch((err) => {
        setOptionAlert({
          ...optionAlert,
          type: "danger",
          show: true,
          action: {
            id: "",
            name: ""
          },
          title: "ผิดพลาด",
          description: { visible: true, type: "text", content: `${err}` }
        })
      })
  }
  //#endregion

  //#region ROUTE MANAGEMENT
  const searchRoute = (date = "", isReloadCurrent = false) => {
    setLoading({ ...loading, visible: true, tip: 'กำลังโหลด...' })
    let delivery_date = date === "" ? filter.delivery_date : date
    setRouteDateCurrent(delivery_date)

    let body: any = {}
    if (isReloadCurrent) {
      body = filterRouteCurrent
    }
    else {
      body = {
        delivery_date,
        loc_receipt_id: filter.loc_receipt_id,
        shipto_id: filter.shipto_id,
        qno: filter.qno,
        doc_no: filter.doc_no,
        job_type_id: filter.job_type_id,
        route_id: filter.route_id,
        cust_id: filter.cust_id,
        service_id: filter.service_id,
        vid: filter.vid,
        status: filter.route_status_id
      }
    }

    setFilterRouteCurrent(body)
    RouteService.getRoute(auth, body)
      .then((res: any) => {
        let { code, message, sys_message, result } = res
        if (code === 200) {
          setDataRoute(result)
        }
        else {
          setOptionAlert({
            ...optionAlert,
            type: "danger",
            show: true,
            action: {
              id: "",
              name: ""
            },
            title: "ผิดพลาด",
            description: { visible: true, type: "text", content: `${message} ${sys_message}` }
          })
        }
        setLoading({ ...loading, visible: false, tip: 'กำลังโหลด...' })
      })
      .catch((err) => {
        setLoading({ ...loading, visible: false, tip: 'กำลังโหลด...' })
        setOptionAlert({
          ...optionAlert,
          type: "danger",
          show: true,
          action: {
            id: "",
            name: ""
          },
          title: "ผิดพลาด",
          description: { visible: true, type: "text", content: `${err}` }
        })
      })
  }

  const confirmRoute = (qSelected) => {
    let route_ids = ""
    if (qSelected.length > 0) route_ids = qSelected.join(',')

    RouteService.confirmRoute(auth, route_ids)
      .then((res: any) => {
        let { code, message, sys_message } = res
        if (code === 200) {
          setOptionAlert({
            ...optionAlert,
            type: "success",
            show: true,
            action: {
              id: "",
              name: ""
            },
            title: "ยืนยันแผนงานสำเร็จ",
            description: { visible: false, type: "text", content: " ", contentLists: [] }
          })
          searchRoute()
          getDash()
        }
        else {
          setOptionAlert({
            ...optionAlert,
            type: "danger",
            show: true,
            action: {
              id: "",
              name: ""
            },
            title: "ยืนยันแผนงานไม่สำเร็จ",
            description: { visible: true, type: "text", content: `${message} ${sys_message}` }
          })
        }
        // initialData()
      })
      .catch((err) => {
        setOptionAlert({
          ...optionAlert,
          type: "danger",
          show: true,
          action: {
            id: "",
            name: ""
          },
          title: "ยืนยันแผนงานไม่สำเร็จ",
          description: { visible: true, type: "text", content: `${err}` }
        })
      })
  }
  const deleteRoute = (qSelected) => {
    let route_ids = ""
    if (qSelected.length > 0) route_ids = qSelected.join(',')

    RouteService.deleteRoute(auth, route_ids)
      .then((res: any) => {
        let { code, message, sys_message } = res
        if (code === 200) {
          setOptionAlert({
            ...optionAlert,
            type: "success",
            show: true,
            action: {
              id: "",
              name: ""
            },
            title: "ลบข้อมูลสำเร็จ",
            description: { visible: false, type: "text", content: " ", contentLists: [] }
          })
          searchRoute("", true)
          getDash()
        }
        else {
          setOptionAlert({
            ...optionAlert,
            type: "danger",
            show: true,
            action: {
              id: "",
              name: ""
            },
            title: "ผิดพลาด",
            description: { visible: true, type: "text", content: `${message} ${sys_message}` }
          })
        }
      })
      .catch((err) => {

      })
  }

  const getDash = () => {
    RouteService.getDash(auth, filter.delivery_date)
      .then((res: any) => {
        let { code, result } = res
        if (code === 200) {
          setDataDash(result)
        }
        else {
          setDataDash({
            all: 0,
            open: 0,
            plan: 0,
            confirm: 0
          })
        }
        setLoading({ ...loading, visible: false, tip: 'กำลังโหลด...' })
      })
      .catch((err) => {
        setLoading({ ...loading, visible: false, tip: 'กำลังโหลด...' })
        setDataDash({
          all: 0,
          open: 0,
          plan: 0,
          confirm: 0
        })
      })
  }
  //#endregion

  const reloadData = () => {
    setLoading({ ...loading, visible: true, tip: 'กำลังโหลด...' })
    searchQueue()
    searchRoute()
    getDash()
  }

  return (
    <div className="space-y-3 p-3">
      <Card title="จัดการแผนงาน"  >
        <div className="bg-white -my-3">
          <div className="space-y-1">
            <div className="flex flex-row">
              <div className="flex-1 sm:grid sm:grid-cols-3 sm:items-end sm:gap-2">
                <label className="block text-xs font-medium text-gray-700 mb-1 text-right">
                  วันที่จัดส่ง :
                </label>
                <div className="mt-1 sm:col-span-2 sm:mt-0">
                  <DatePicker
                    allowClear={false}
                    placeholder="วันที่จัดส่ง"
                    defaultValue={filter.delivery_date}
                    onChange={(d) => {
                      if (d) {
                        setFilter({ ...filter, delivery_date: d.format("YYYY-MM-DD") })
                        setRouteStore({ defaultDeliveryDate: d.format("YYYY-MM-DD") })
                      }
                      else {
                        setFilter({ ...filter, delivery_date: "" })
                        setRouteStore({ defaultDeliveryDate: "" })
                      }
                    }}
                  />
                </div>
              </div>

              <div className="flex-1 sm:grid sm:grid-cols-3 sm:items-end sm:gap-2">
                <label className="block text-xs font-medium text-gray-700 mb-1 text-right">
                  เลขที่ใบงาน :
                </label>
                <div className="mt-1 sm:col-span-2 sm:mt-0">
                  <Input
                    label="เลขที่ใบงาน"
                    placeholder="เลขที่ใบงาน"
                    hiddenLabel={true}
                    onChange={(e) => setFilter({ ...filter, qno: e.target.value })}
                  />
                </div>
              </div>

              <div className="flex-1 sm:grid sm:grid-cols-3 sm:items-end sm:gap-2">
                <label className="block text-xs font-medium text-gray-700 mb-1 text-right">
                  เลขที่แผนงาน :
                </label>
                <div className="mt-1 sm:col-span-2 sm:mt-0">
                  <Input
                    label="เลขที่แผนงาน"
                    placeholder="เลขที่แผนงาน"
                    hiddenLabel={true}
                    onChange={(e) => setFilter({ ...filter, doc_no: e.target.value })}
                  />
                </div>
              </div>

              <div className="flex-1 sm:grid sm:grid-cols-3 sm:items-end sm:gap-2">
                <label className="block text-xs font-medium text-gray-700 mb-1 text-right">
                  คลัง/ต้นทาง :
                </label>
                <div className="mt-1 sm:col-span-2 sm:mt-0">
                  <Select
                    showSearch={true}
                    allowClear={true}
                    hiddenLabel={true}
                    defaultValue={filter.loc_receipt_id}
                    placeholder="คลัง/ต้นทาง"
                    options={masterOptions.shipping_point}
                    onChange={(value) => setFilter({ ...filter, loc_receipt_id: value })}
                  />
                </div>
              </div>
            </div>

            <div className="flex flex-row">
              <div className="flex-1 sm:grid sm:grid-cols-3 sm:items-end sm:gap-2">
                <label className="block text-xs font-medium text-gray-700 mb-1 text-right">
                  สาขา/ปลายทาง :
                </label>
                <div className="mt-1 sm:col-span-2 sm:mt-0">
                  <Select
                    showSearch={true}
                    allowClear={true}
                    hiddenLabel={true}
                    defaultValue={filter.shipto_id}
                    placeholder="สาขา/ปลายทาง"
                    options={masterOptions.shipto}
                    onChange={(value) => setFilter({ ...filter, shipto_id: value })}
                  />
                </div>
              </div>

              <div className="flex-1 sm:grid sm:grid-cols-3 sm:items-end sm:gap-2">
                <label className="block text-xs font-medium text-gray-700 mb-1 text-right">
                  ทะเบียนรถ :
                </label>
                <div className="mt-1 sm:col-span-2 sm:mt-0">
                  <Select
                    showSearch={true}
                    allowClear={true}
                    hiddenLabel={true}
                    defaultValue={filter.vid}
                    placeholder="ทะเบียนรถ"
                    options={masterOptions.vehicle}
                    onChange={(value) => setFilter({ ...filter, vid: value })}
                  />
                </div>
              </div>

              <div className="flex-1 sm:grid sm:grid-cols-3 sm:items-end sm:gap-2">
                <label className="block text-xs font-medium text-gray-700 mb-1 text-right">
                  พนักงาน :
                </label>
                <div className="mt-1 sm:col-span-2 sm:mt-0">
                  <Select
                    showSearch={true}
                    allowClear={true}
                    hiddenLabel={true}
                    defaultValue={filter.service_id}
                    placeholder="พนักงาน"
                    options={masterOptions.service}
                    onChange={(value) => setFilter({ ...filter, service_id: value })}
                  />
                </div>
              </div>

              <div className="flex-1 sm:grid sm:grid-cols-3 sm:items-end sm:gap-2">
                <label className="block text-xs font-medium text-gray-700 mb-1 text-right">
                  ลูกค้า :
                </label>
                <div className="mt-1 sm:col-span-2 sm:mt-0">
                  <Select
                    showSearch={true}
                    allowClear={true}
                    hiddenLabel={true}
                    defaultValue={filter.cust_id}
                    placeholder="ลูกค้า"
                    options={masterOptions.customer}
                    onChange={(value) => setFilter({ ...filter, cust_id: value })}
                  />
                </div>
              </div>
            </div>

            <div className="flex flex-row">
              <div className="flex-1 sm:grid sm:grid-cols-3 sm:items-end sm:gap-2">
                <label className="block text-xs font-medium text-gray-700 mb-1 text-right">
                  สถานะใบงาน :
                </label>
                <div className="mt-1 sm:col-span-2 sm:mt-0">
                  <Select
                    allowClear={true}
                    hiddenLabel={true}
                    defaultValue={filter.queue_status_id}
                    placeholder="สถานะใบงาน"
                    options={masterOptions.queue_status}
                    onChange={(value) => setFilter({ ...filter, queue_status_id: value })}
                  />
                </div>
              </div>

              <div className="flex-1 sm:grid sm:grid-cols-3 sm:items-end sm:gap-2">
                <label className="block text-xs font-medium text-gray-700 mb-1 text-right">
                  สถานะแผนงาน :
                </label>
                <div className="mt-1 sm:col-span-2 sm:mt-0">
                  <Select
                    allowClear={true}
                    hiddenLabel={true}
                    defaultValue={filter.route_status_id}
                    placeholder="สถานะแผนงาน"
                    options={masterOptions.route_status}
                    onChange={(value) => setFilter({ ...filter, route_status_id: value })}
                  />
                </div>
              </div>

              <div className="flex-1 sm:grid sm:grid-cols-3 sm:items-end sm:gap-2">
                <label className="block text-xs font-medium text-gray-700 mb-1 text-right">
                </label>
                <div className="flex flex-row mt-1 sm:col-span-2 sm:mt-0 space-x-2">
                  <button
                    type="button"
                    title="ค้นหา"
                    className={`flex flex-row ${loading.visible && 'cursor-not-allowed'} items-center rounded border border-transparent bg-blue-100 mt-0.5 px-2.5 py-1.5 text-xs font-medium text-blue-700 hover:bg-blue-200`}
                    onClick={() => {
                      if (filter.delivery_date === "") {
                        setOptionAlert({
                          ...optionAlert,
                          action: {
                            id: "",
                            name: ""
                          },
                          type: "info",
                          title: "โปรดระบุวันที่จัดส่ง",
                          show: true,
                          description: {
                            visible: false,
                            type: "text",
                            content: "",
                            contentLists: []
                          }
                        })
                      }
                      else {
                        reloadData()
                      }
                    }}
                  >
                    <div className="flex flex-row-reverse">
                      <span>ค้นหา</span>
                      <SearchIcon className="w-4 h-4 mr-1" />
                    </div>
                  </button>

                  <button
                    type="button"
                    title="ยืนยันแผน"
                    className={`flex flex-row  items-center rounded border border-transparent bg-success-100 mt-0.5 px-2.5 py-1.5 text-xs font-medium text-success-700 hover:bg-success-200`}
                    onClick={() => {
                      if (routeSelected.length === 0) {
                        setOptionAlert({
                          ...optionAlert,
                          action: {
                            id: "",
                            name: ""
                          },
                          type: "info",
                          title: "โปรดเลือกแผนงาน",
                          show: true,
                          description: {
                            visible: false,
                            type: "text",
                            content: "",
                            contentLists: []
                          }
                        })
                      }
                      else {
                        setOptionAlert({
                          ...optionAlert,
                          action: {
                            id: "",
                            name: 'confirm-route'
                          },
                          type: "confirm",
                          title: "ยืนยัน",
                          show: true,
                          description: {
                            visible: true,
                            type: "text",
                            content: "คุณแน่ใจที่จะยืนยันแผนงาน ?",
                            contentLists: []
                          }
                        })
                      }
                    }}
                  >
                    <div className="flex flex-row-reverse">
                      <span>ยืนยันแผน</span>
                      <CheckIcon className="w-4 h-4 mr-1" />
                    </div>
                  </button>
                </div>
              </div>
              <div className="flex-1 sm:grid sm:grid-cols-3 sm:items-end sm:gap-2" />
            </div>

            <div className="pt-2 border-t border-dashed">
              <dl className="grid grid-cols-1 border gap-0.5 overflow-hidden rounded-md text-center sm:grid-cols-2 lg:grid-cols-4">
                {stats.map((stat) => (
                  <div key={stat.name} className="flex flex-col bg-white/5 border-l border-white/10 py-0.5">
                    <dt className="text-xs font-semibold leading-6 text-gray-900">{stat.name}</dt>
                    <dd className="order-first text-xs font-semibold tracking-tight text-gray-500">{stat.stat}</dd>
                  </div>
                ))}
              </dl>
            </div>
          </div>
        </div>

        <Modal
          title={{ text: modalInfo.title }}
          open={modalInfo.open}
          maskClosable={true}
          width={modalInfo.name === "note" ? "w-1/4" : "w-1/2"}
          loading={false}
          onClosed={() => {

          }}
          icon={modalInfo.icon}
          onOk={() => { }}
          footer={<>
            {
              modalInfo.name === "note" && <Button
                text="บันทึก"
                width="w-20"
                type="primary"
                onClick={() => setModalInfo({ ...modalInfo, open: false })}
              />
            }

            <Button
              text="ปิด"
              width="w-20"
              type={modalInfo.name === "note" ? 'white' : 'primary'}
              onClick={() => setModalInfo({ ...modalInfo, open: false })}
            />
          </>}
        >
          <div className="flex flex-col -my-4 space-y-2">
            <div className={`flex-1 ${modalInfo.name === "note" && 'sr-only'}`}>
              <div className="flex-row">
                <div className="grid grid-cols-4 sm:items-start ">
                  <span className="block text-sm font-semibold leading-2">
                    เลขที่แผนงาน :
                  </span>
                  <span className="flex-1 flex-row truncate text-sm col-span-3">JOB-1009223</span>
                </div>
              </div>

              <div className="flex-row">
                <div className="grid grid-cols-4 sm:items-start ">
                  <span className="block text-sm font-semibold leading-2">
                    เลขที่ใบส่งสินค้า :
                  </span>
                  <span className="flex-1 flex-row truncate text-sm col-span-3">CCD-200123</span>
                </div>
              </div>

              <div className="flex-row">
                <div className="grid grid-cols-4 sm:items-start ">
                  <span className="block text-sm font-semibold leading-2">
                    วันที่จัดส่ง :
                  </span>
                  <span className="flex-1 flex-row truncate text-sm col-span-3">11/12/2023</span>
                </div>
              </div>

              <div className="flex-row">
                <div className="grid grid-cols-4 items-start ">
                  <span className="text-sm font-semibold leading-2">
                    ชื่อลูกค้า :
                  </span>
                  <span className="flex-1 flex-row truncate text-sm col-span-3">บริษัท กาแฟพันธุ์ไทย จำกัด</span>
                </div>
              </div>

              <div className="flex-row">
                <div className="grid grid-cols-4 sm:items-start ">
                  <span className="text-sm font-semibold leading-2">
                    สาขา/สถานที่ปลายทาง :
                  </span>
                  <span className="flex-1 flex-row truncate text-sm col-span-3">ลำลูกกา</span>
                </div>
              </div>
            </div>

            <div className="flex-1">
              {
                modalInfo.name === "note" ? <TextArea rows={10} />
                  : <DataGrid
                    dataSource={modalInfo.name === "camera" ? images : imagesSignature}
                    keyExpr="id"
                    showBorders={true}
                  >
                    {
                      columns.map((column) => <Column
                        key={column.dataField}
                        dataField={column.dataField}
                        caption={column.caption}
                        alignment={column.alignment}
                        cellRender={column.render}
                      />)
                    }
                  </DataGrid>
              }
            </div>
          </div>
        </Modal>
      </Card>

      <div className="bg-white rounded-md p-1 space-y-2">
        <FormLoading loading={loading.visible} tip={loading.tip}>
          <JobPlan
            auth={auth}
            filter={filter}
            queueDateCurrent={queueDateCurrent}
            dataQueue={dataQueue}
            dataRoute={dataRoute}
            setQueueSelected={(select) => setQueueSelected(select)}
            setRouteSelected={(select) => setRouteSelected(select)}
            onReloadQueue={(delivery_date) => searchQueue(delivery_date)}
            onReloadQueueCurrent={() => searchRoute("", true)}
            onReloadData={(reloadQueue) => {
              if (filter.delivery_date !== "") {
                if (reloadQueue)
                  searchQueue()
                else
                  reloadData()
              }
            }}
            onDeleteQueue={() => {
              if (queueSelected.length === 0) {
                setOptionAlert({
                  ...optionAlert,
                  action: {
                    id: "",
                    name: ""
                  },
                  type: "info",
                  title: "โปรดเลือกคิวที่ต้องการลบ",
                  show: true,
                  description: {
                    visible: false,
                    type: "text",
                    content: "",
                    contentLists: []
                  }
                })
              }
              else {
                setOptionAlert({
                  ...optionAlert,
                  action: {
                    id: "",
                    name: 'deleteQueue'
                  },
                  type: "confirm",
                  title: "ยืนยัน",
                  show: true,
                  description: {
                    visible: true,
                    type: "text",
                    content: "คุณแน่ใจที่จะลบข้อมูล ?",
                    contentLists: []
                  }
                })
              }
            }}
            onDeleteRoute={() => {
              if (routeSelected.length === 0) {
                setOptionAlert({
                  ...optionAlert,
                  action: {
                    id: "",
                    name: ""
                  },
                  type: "info",
                  title: "โปรดเลือกแผนงานที่ต้องการลบ",
                  show: true,
                  description: {
                    visible: false,
                    type: "text",
                    content: "",
                    contentLists: []
                  }
                })
              }
              else {
                setOptionAlert({
                  ...optionAlert,
                  action: {
                    id: "",
                    name: 'deleteRoute'
                  },
                  type: "confirm",
                  title: "ยืนยัน",
                  show: true,
                  description: {
                    visible: true,
                    type: "text",
                    content: "คุณแน่ใจที่จะลบข้อมูล ?",
                    contentLists: []
                  }
                })
              }
            }}
          />
        </FormLoading>
      </div>
      <ModalAlert
        {...optionAlert}
        onOk={async () => {
          let { action } = optionAlert

          if (action.name === "deleteQueue")
            deleteQueue(queueSelected)
          else if (action.name === "deleteRoute")
            deleteRoute(routeSelected)
          else if (action.name === "confirm-route")
            confirmRoute(routeSelected)

          setOptionAlert({ ...optionAlert, show: false })
        }}
        onCancel={() => {
          setOptionAlert({ ...optionAlert, show: false })
        }}
      />
    </div>
  )
}))

export default Plan