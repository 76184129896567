import axios from 'axios'
import { BASE_URL } from '../AppConfig'
import IAuth from '../interfaces/Common/IAuth'

interface IZone {
  zone_set?: string
  code?: string
}

interface IZoneData {
  zone_code: string
  zone_name: string
  zone_set: string
  prov_id: string
  amp_id?: string
}

interface IZoneUpdate {
  zone_id: number
  zone_code: string
  zone_name: string
  zone_set: string
  prov_id: string
  amp_id?: string
}

export default {

  getZone: async function (auth: IAuth, data: IZone) {
    let prmList: any = []
    for (const key in data) {
      if (data[key] !== "") prmList.push(`${key}=${data[key]}`)
    }
    let param = prmList.join('&');
    try {
      const response = await axios.get(`${BASE_URL}/zone/list${param !== "" ? `?${param}` : param}`, {
        headers: { action_user_id: auth?.action_user_id, token_id: auth?.token_id }
      })
      return response.data
    } catch (error) {
      throw error
    }
  },

  addZone: async function (auth: IAuth, data: IZoneData) {
    const response = await axios.post(`${BASE_URL}/zone/add`, data,
      {
        headers: { action_user_id: auth?.action_user_id, token_id: auth?.token_id }
      })
    return response
  },

  updateZone: async function (auth: IAuth, data: IZoneUpdate) {
    const response = await axios.put(`${BASE_URL}/zone/update`, data,
      {
        headers: { action_user_id: auth?.action_user_id, token_id: auth?.token_id }
      })
    return response
  },

  deleteZone: async function (auth: IAuth, zone_id) {
    try {
      const response = await axios.delete(`${BASE_URL}/zone/delete?zone_id=${zone_id}`, {
        headers: {
          action_user_id: auth?.action_user_id, token_id: auth?.token_id
        }
      })
      return response.data
    } catch (error) {
      throw error
    }
  }
}