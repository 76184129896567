import { useEffect, useState } from 'react'
import { inject, observer } from 'mobx-react';
import FormMaster from '../FormMaster'
import { CommonService } from "../../../services";
import { isEmpty } from 'lodash';

let firstRender = false
const ProductUnit = inject("authenStore")(observer((props) => {
  let authenStore = props.authenStore
  let { getAccountAuth } = authenStore // STATE
  let auth = { action_user_id: getAccountAuth?.user_id, token_id: getAccountAuth?.access_token }

  const [optionStatus, setOptionStatus] = useState([])

  useEffect(() => {
    if (firstRender || !isEmpty(getAccountAuth)) {
      CommonService.getMasterData(auth, "common_status")
        .then((res: any) => {
          let common_status = res.result.common_status.map((item) => ({ label: item.status_name, value: item.status_code }))
          setOptionStatus(common_status)
        })
        .catch((err) => {
          console.log(`Load master data : ${err}`)
        })
    }
    else
      firstRender = true
  }, [getAccountAuth])

  return <FormMaster
    title="ข้อมูลหน่วยสินค้า"
    tableConfig={{
      key: "id",
      apiPath: "produnit/list",
      allowDelete: {
        visible: true,
        key: "unit_id",
        apiPath: "produnit/delete",
        paramType: "param"
      },
      optionDeleteFag: true,
      defaultColumn: [],
      columns: [
        {
          dataField: 'no',
          caption: "ลำดับ",
          width: 60,
          alignment: "center",
          allowFiltering: false
        },
        {
          dataField: 'unit_code',
          caption: "รหัสสินค้า",
          allowFiltering: true
        },
        {
          dataField: 'unit_name',
          caption: "หน่วยสินค้า",
          allowFiltering: true
        },
        {
          dataField: 'description',
          caption: "คำอธิบาย"
        },
        {
          dataField: 'status_name',
          caption: "สถานะ",
          allowFiltering: true
        },
        {
          dataField: 'updated_by',
          caption: "แก้ไขโดย"
        }
      ],
      exportExcel: {
        fileName: 'ข้อมูลหน่วยสินค้า',
        columnConfig: [
          { label: 'ลำดับ', value: 'no' },
          { label: 'รหัสสินค้า', value: 'unit_code' },
          { label: 'Xหน่วยสินค้าXXXX', value: 'unit_name' },
          { label: 'คำอธิบาย', value: 'description' },
          { label: 'สถานะ', value: 'status_name' },
          { label: 'แก้ไขโดย', value: 'updated_by' }
        ]
      }
    }}
    formData={{
      unit_code: "",
      unit_name: "",
      description: "",
      status: "Y"
    }}
    modalConfig={{
      visible: true,
      title: "หน่วยสินค้า",
      required: ["unit_code", "unit_name"],
      fieldTypeNumber: [],
      paramName: "unit_id",
      apiPath: {
        "get": '',
        "post": 'produnit/add',
        "put": 'produnit/update'
      },
      elements: [
        {
          fieldName: "unit_code",
          label: "รหัสหน่วยสินค้า",
          placeholder: "รหัสหน่วยสินค้า",
          required: true,
          inputType: "text",
          isOneRow: true
        },
        {
          fieldName: "unit_name",
          label: "ชื่อหน่วยสินค้า",
          placeholder: "ชื่อหน่วยสินค้า",
          required: true,
          inputType: "text",
          isOneRow: true
        },
        {
          fieldName: "description",
          label: "คำอธิบาย",
          placeholder: "คำอธิบาย",
          inputType: "text",
          isOneRow: true
        },
        {
          showSearch: false,
          fieldName: "status",
          label: "สถานะ",
          inputType: "select",
          defaultValue: "Y",
          options: optionStatus
        }
      ]
    }}
  />
}))

export default ProductUnit