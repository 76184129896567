import { action, configure, observable, toJS, computed } from 'mobx';
import { persist } from 'mobx-persist'

configure({ enforceActions: 'always' });

export default class CommonStore {
  @persist('object') @observable isRefreshData: boolean = false
  @persist('object') @observable modalConfig: any = {
    show: false,
    action: "Add",
    id: "",
    isCustom: false,
    tableName: ""
  }

  @computed
  get getModalConfig() {
    return toJS(this.modalConfig)
  }

  @action.bound
  setMasterStore(objValue: any) {
    for (const [key, value] of Object.entries(objValue)) {
      this[key] = value
    }
  }

  @action
  reset() {

  }
}