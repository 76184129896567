import { action, configure, observable, toJS, computed } from 'mobx';
import { persist } from 'mobx-persist'

configure({ enforceActions: 'always' });

export default class AuthenStore {
  @persist @observable token: string = "XXXX"
  @persist('object') @observable accountAuth: any = {}
  @persist('list') @observable accounts = [
    {
      firstName: "Thodsapon",
      lastName: "Chupromwong",
      displayName: "Thodsapon Chupromwong",
      email: "thodsapon.cs104@gmail.com",
      username: "admin",
      password: "admin",
      roleName: "ผู้ดูแลระบบ",
      imageUrl: 'https://images.unsplash.com/photo-1520785643438-5bf77931f493?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=256&h=256&q=80'
    }
  ]

  @computed
  get getToken() {
    return this.token
  }


  @computed
  get getAccount() {
    return toJS(this.accounts)
  }

  @computed
  get getAccountAuth() {
    return toJS(this.accountAuth)
  }

  @action.bound
  setCommonValue(objValue: any) {
    for (const [key, value] of Object.entries(objValue)) {
      this[key] = value
    }
  }

  @action
  signinSuccess(token: string) {
    this.token = token
  }

  @action
  clearStored() {
    this.token = ""
  }
}