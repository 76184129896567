import { action, configure, observable, toJS, computed } from 'mobx';
import { persist } from 'mobx-persist'

configure({ enforceActions: 'always' });

export default class CalendarStore {
  @persist('object') @observable openModalCalendar: boolean = false
  @persist('object') @observable calendarData = {}

  @computed
  get getCalendarData() {
    return toJS(this.calendarData)
  }

  @action.bound
  setCalendarStore(objValue: any) {
    for (const [key, value] of Object.entries(objValue)) {
      this[key] = value
    }
  }

  @action
  reset() {
    this.openModalCalendar = false
    this.calendarData = {}
  }
}