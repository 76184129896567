import { useEffect, useState, useRef } from 'react'
import { inject, observer } from 'mobx-react';
import { CommonService, RouteService } from "../../services";
import { Card, Button, FormLoading, Select, DatePicker, Input, ModalAlert } from '../../components'
import DataGrid, { Paging, Selection, Column } from 'devextreme-react/data-grid';
import { SearchIcon } from '@heroicons/react/solid';
import { isEmpty } from 'lodash'

const SearchQueue = inject("routeStore")(observer((props) => {
  let { onClosed, onValidate, onAddCallback } = props // ACTIONS
  let { dataMain, routeId, action, auth } = props // STATES

  const gridRef: any = useRef(null);
  const [loading, setLoading] = useState(false)
  const [dataSource, setDataSource]: any = useState([])
  const [queueSelected, setQueueSelected]: any = useState([])
  const [currentRouteId, setCurrentRouteId]: any = useState("")
  const [filter, setFilter]: any = useState({
    delivery_date: dataMain?.delivery_date,
    qno: "",
    loc_receipt_id: "",
    shipto_id: ""
  })

  const [masterOptions, setMasterOptions] = useState({
    shipping_point: [],
    shipto: []
  })
  const [optionAlert, setOptionAlert]: any = useState({
    show: false,
    action: {
      id: "",
      name: ""
    },
    type: "success",
    title: "",
    description: {
      visible: false,
      type: "",
      content: "",
      contentLists: [],

    }
  })

  useEffect(() => {
    CommonService.getMasterData(auth, "shipping_point,shipto")
      .then((res: any) => {
        let shipping_point = res.result.shipping_point.map((item) => ({ label: item.shipping_name, value: item.shipping_id }))
        let shipto = res.result.shipto.map((item) => ({ label: item.shipto_name, value: item.shipto_id }))

        setMasterOptions({
          ...masterOptions,
          shipping_point,
          shipto
        })
      })
      .catch((err) => {

      })
  }, [])

  const searchQueue = (date = "") => {
    setLoading(true)

    RouteService.getQueue(auth, {
      delivery_date: filter.delivery_date,
      qno: filter.qno,
      loc_receipt_id: filter.loc_receipt_id,
      shipto_id: filter.shipto_id,
    })
      .then((res: any) => {
        let { code, message, result } = res
        if (code === 200) {
          setDataSource(result)
        }
        else {

        }
        setLoading(false)
      })
      .catch((err) => {
        console.log("err  :", err)
        setLoading(false)
      })
  }

  const onSave = () => {
    let validateCheck = {}, message = { isError: true, message: "โปรดระบุ" }

    if (dataMain.delivery_date.trim() === "")
      validateCheck['delivery_date'] = message
    if (dataMain.vid.length === 0 || dataMain.vid === "")
      validateCheck['vid'] = message
    if (dataMain.service_id.length === 0 || dataMain.service_id === "")
      validateCheck['service_id'] = message
    if (dataMain.logistic_type_id.length === 0 || dataMain.logistic_type_id === "")
      validateCheck['logistic_type_id'] = message

    if (isEmpty(validateCheck)) {
      onValidate(validateCheck)
      addQueue()
    }
    else {
      onValidate(validateCheck)
    }
  }

  const addQueue = () => {
    let queues = queueSelected.map((id) => {
      let queue = dataSource.find((item) => item.id === id)
      if (queue) {
        return ({
          q_id: queue.id,
          dlv_time_id: 0
        })
      }
    })

    let body: any = {
      vid: dataMain.vid,
      logistic_type_id: dataMain.logistic_type_id,
      service_id: dataMain.service_id,
      center_id: dataMain.center_id,
      delivery_date: dataMain.delivery_date,
      queues
    }

    setLoading(true)
    if (routeId !== "") body.route_id = routeId

    RouteService.addQueue(auth, body)
      .then((res: any) => {
        let { code, message, sys_message, result } = res.data
        if (code === 200) {
          getRoute(result.route_id)
        }
        else {
          setOptionAlert({
            ...optionAlert,
            type: "danger",
            show: true,
            action: {
              id: "",
              name: ""
            },
            title: "เพิ่มใบงานไม่สำเร็จ",
            description: { visible: true, type: "text", content: `${message} ${sys_message}` }
          })
          setLoading(false)
        }
      })
      .catch((err) => {
        setOptionAlert({
          ...optionAlert,
          type: "danger",
          show: true,
          action: {
            id: "",
            name: ""
          },
          title: "เพิ่มใบงานไม่สำเร็จ",
          description: { visible: true, type: "text", content: `${err}` }
        })
        setLoading(false)
      })
  }

  const getRoute = (route_id) => {
    RouteService.getRoute(auth, {
      delivery_date: dataMain.delivery_date,
      route_id
    })
      .then((res: any) => {
        let { code, message, sys_message, result } = res
        if (code === 200) {
          onAddCallback(result, route_id)
        }
        else {
          setOptionAlert({
            ...optionAlert,
            type: "danger",
            show: true,
            action: {
              id: "",
              name: ""
            },
            title: "ดึงข้อมูลผิดพลาด",
            description: { visible: true, type: "text", content: `${message} ${sys_message}` }
          })
        }
        setLoading(false)
      })
      .catch((err) => {
        setLoading(false)
        setOptionAlert({
          ...optionAlert,
          type: "danger",
          show: true,
          action: {
            id: "",
            name: ""
          },
          title: "ดึงข้อมูลผิดพลาด",
          description: { visible: true, type: "text", content: `webapi/route/list : ${err}` }
        })
      })
  }

  return <Card title="ค้นหาข้อมูลใบงาน" contentWithoutPadding={true}>
    <FormLoading loading={loading} tip="กำลังบันทึก">
      <div className='p-2 space-y-2' style={{ height: 300 }}>
        <div className="flex flex-row" style={{ height: 30 }} >
          <div className="flex-1 sm:grid sm:grid-cols-3 sm:items-end sm:gap-2">
            <label className="block text-xs font-medium text-gray-700 mb-1 text-right">
              วันที่จัดส่ง :
            </label>
            <div className="mt-1 sm:col-span-2 sm:mt-0">
              <DatePicker
                allowClear={false}
                placeholder="วันที่จัดส่ง"
                defaultValue={filter.delivery_date}
                onChange={(d) => {
                  if (d)
                    setFilter({ ...filter, delivery_date: d.format("YYYY-MM-DD") })
                  else
                    setFilter({ ...filter, delivery_date: "" })
                }}
              />
            </div>
          </div>

          <div className="flex-1 sm:grid sm:grid-cols-3 sm:items-end sm:gap-2">
            <label className="block text-xs font-medium text-gray-700 mb-1 text-right">
              เลขที่ใบงาน  :
            </label>
            <div className="mt-1 sm:col-span-2 sm:mt-0">
              <Input
                id="qno"
                label="เลขที่ใบงาน"
                placeholder="เลขที่ใบงาน"
                hiddenLabel={true}
                onChange={(e) => setFilter({ ...filter, qno: e.target.value })}
              />
            </div>
          </div>

          <div className="flex-1 sm:grid sm:grid-cols-3 sm:items-end sm:gap-2">
            <label className="block text-xs font-medium text-gray-700 mb-1 text-right">
              คลัง/ต้นทาง :
            </label>
            <div className="mt-1 sm:col-span-2 sm:mt-0">
              <Select
                showSearch={true}
                allowClear={true}
                hiddenLabel={true}
                placeholder="คลัง/ต้นทาง"
                defaultValue={filter.loc_receipt_id}
                options={masterOptions.shipping_point}
                onChange={(value) => setFilter({ ...filter, loc_receipt_id: value ? value : '' })}
              />
            </div>
          </div>

          <div className="flex-1 sm:grid sm:grid-cols-3 sm:items-end sm:gap-2">
            <label className="block text-xs font-medium text-gray-700 mb-1 text-right">
              ปลายทาง :
            </label>
            <div className="mt-1 sm:col-span-2 sm:mt-0">
              <Select
                showSearch={true}
                allowClear={true}
                placeholder="ปลายทาง"
                defaultValue={filter.shipto_id}
                hiddenLabel={true}
                options={masterOptions.shipto}
                onChange={(value) => setFilter({ ...filter, shipto_id: value ? value : '' })}
              />
            </div>
          </div>
          <div className="sm:grid sm:grid-cols-3 sm:items-end sm:gap-2">
            <label className="block text-xs font-medium text-gray-700 mb-1 text-right"></label>
            <div className="mt-1 sm:col-span-2 sm:mt-0">
              <Button
                text="ค้นหา"
                icon={<SearchIcon />}
                iconPosition='start'
                onClick={() => {
                  if (filter.delivery_date === "") {
                    setOptionAlert({
                      ...optionAlert,
                      action: {
                        id: "",
                        name: ""
                      },
                      type: "info",
                      title: "โปรดระบุวันที่จัดส่ง",
                      show: true,
                      description: {
                        visible: false,
                        type: "text",
                        content: "",
                        contentLists: []
                      }
                    })
                  }
                  else {
                    searchQueue()
                  }
                }}
              />
            </div>
          </div>
        </div>

        <div style={{ height: 200 }} >
          <DataGrid
            ref={(ref) => gridRef.current = ref}
            dataSource={dataSource}
            keyExpr="id"
            height="100%"
            allowColumnReordering={true}
            columnAutoWidth={true}
            showBorders={true}
            onSelectionChanged={(e) => setQueueSelected(e.selectedRowKeys)}
          >
            <Paging enabled={false} />
            <Selection
              mode="multiple"
              selectAllMode="allPages"
              showCheckBoxesMode="always"
            />
            <Column dataField="qno" caption="เลขที่ใบงาน" />
            <Column dataField="shippoint_name" caption="คลัง/ต้นทาง" />
            <Column dataField="shipto_name" caption="สาขา/ปลายทาง" />
            <Column dataField="delivery_date" caption="วันที่จัดส่งสินค้า" />
            <Column dataField="cust_code" caption="รหัสลูกค้า" />
            <Column dataField="cust_name" caption="ชื่อลูกค้า" />
            <Column dataField="job_type_name" caption="ประเภทงาน" />
            <Column dataField="zone_name" caption="โซน" />
          </DataGrid>
        </div>

        <div className='flex flex-row' style={{ height: 30 }} >
          <Button
            text="ตกลง"
            width="w-16"
            onClick={() => {
              if (queueSelected.length === 0) {
                setOptionAlert({
                  ...optionAlert,
                  action: {
                    id: "",
                    name: ""
                  },
                  type: "info",
                  title: "โปรดเลือกใบงาน",
                  show: true,
                  description: {
                    visible: false,
                    type: "text",
                    content: "",
                    contentLists: []
                  }
                })
              }
              else {
                onSave()
              }
            }}
          />
          <Button
            text="ปิด"
            width="w-16"
            type="white"
            onClick={() => {
              if (currentRouteId !== "") getRoute(currentRouteId)
              onClosed()
            }}
          />
        </div>
      </div>
    </FormLoading>
    <ModalAlert
      {...optionAlert}
      onOk={async () => {
        let { action } = optionAlert
        if (action.name === "add_q") addQueue()
        setOptionAlert({ ...optionAlert, show: false })
      }}
      onCancel={() => {
        setOptionAlert({ ...optionAlert, show: false })
      }}
    />
  </Card>
}))

export default SearchQueue