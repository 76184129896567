
import { useEffect } from 'react'
import { inject, observer } from 'mobx-react';
import { Modal, Button } from '../../components'
import DataGrid, { Scrolling, Column } from 'devextreme-react/data-grid';

const ModalCheck = inject("routeAutoStore")(observer((props) => {
  let routeAutoStore = props.routeAutoStore
  let { getCheckModelInfo, setRouteAutoStore } = routeAutoStore // STATE
  let { onClosed, open } = props

  useEffect(() => {
    return () => { onClosed() }
  }, [])


  return (<>
    <Modal
      title={{ text: 'สถานะการคำนวณ' }}
      open={open}
      maskClosable={true}
      buttonOk={{ text: "ตกลง" }}
      width='lg:w-3/4  sm:w-3/4'
      onClosed={() => { }}
      footer={<Button
        text="ปิด"
        width="w-20"
        onClick={() => onClosed()}
      />}
    >
      <div className="flex flex-col space-y-2">
        <div className='flex-1'>
          <DataGrid
            dataSource={getCheckModelInfo.matrix}
            keyExpr="id"
            allowColumnReordering={true}
            columnAutoWidth={true}
            hoverStateEnabled={true}
            showBorders={true}
            height={300}
          >
            <Scrolling columnRenderingMode="virtual" />
            <Column
              dataField="no"
              caption="ลำดับ"
              width={60}
              alignment="center"
              allowSorting={false}
            />

            <Column
              dataField="delivery_date"
              caption="วันที่จัดส่ง"
              alignment="center"
              allowSorting={false} />

            <Column
              dataField="created_date"
              caption="วันที่สร้าง"
              alignment="center"
              allowSorting={false} />

            <Column
              dataField="process_date"
              caption="เริ่มคำนวณ"
              alignment="center"
              allowSorting={false} />

            <Column
              dataField="finish_date"
              caption="สิ้นสุดการคำนวณ"
              alignment="center"
              allowSorting={false} />

            <Column
              dataField="status_name"
              caption="สถานะ"
              alignment="center"
              allowSorting={false} />

            <Column
              dataField="created_by"
              caption="สร้างโดย"
              alignment="center"
              allowSorting={false} />
          </DataGrid>
        </div>
      </div>
    </Modal>
  </>
  )
}))

export default ModalCheck