import { useEffect, useState, useRef, Fragment } from "react"
import GoogleMapReact from "google-map-react"
import useSupercluster from "use-supercluster"
// import MapControlCustom from './MapControlCustom'
import { GOOGLE_MAP_KEY } from '../../AppConfig'
import IOptionCustom from '../../interfaces/Common/IOptionCustom'
import CreatePortal from './CreatePortal'
import { MenuAlt1Icon, PlusIcon, MinusIcon, MapIcon } from '@heroicons/react/outline'
import './styles.css'
import { Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon, HandIcon, LocationMarkerIcon } from '@heroicons/react/solid'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDrawPolygon } from '@fortawesome/free-solid-svg-icons'

type Props = {
  id: string
  children?: React.ReactNode
  customOptions?: IOptionCustom[]
  height?: string | number
  width?: string | number
  sidebarOverlay?: {
    visible: boolean,
    title: string,
    children?: React.ReactNode
  }
  optionChange(id: string, active: boolean): void
  onMapRef(value): void
  onDrag?(): void
  onDragEnd?(): void
  onZoomAnimationStart?(): void
  onZoomAnimationEnd?(): void
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

const Map = ({
  id,
  children,
  customOptions,
  height = "calc(100vh - 80px)",
  width = "100%",
  sidebarOverlay,
  optionChange,
  onMapRef,
  onChange,
  onDrag,
  onDragEnd,
  onZoomAnimationStart,
  onZoomAnimationEnd
}: Props) => {
  const mapRef = useRef();
  const [map, setMap] = useState<any>(null)
  const [nightMode, setNightMode] = useState<boolean>(false)
  const [showSidebar, setShowSidebar] = useState(true)
  const [mapType, setMapType] = useState("roadmap")
  const [trafficActive, setTrafficActive] = useState(false)
  const [trafficLayer, setTrafficLayer]: any = useState(null)
  const [drawingToolActive, setDrawingToolActive] = useState("")

  useEffect(() => { // DID MOUNT

  }, []);

  const onMapStyleChange = (mapType) => {
    if (map !== null) {
      map.setMapTypeId(mapType)
      setMapType(mapType)
    }
  }

  const zoomMap = (type) => {
    if (map !== null) {
      let zoomCerrent = map.getZoom()
      if (type === "in" && zoomCerrent <= 22) {
        map.setZoom(zoomCerrent + 1)
      }
      else if (type === "out" && zoomCerrent >= 0) {
        map.setZoom(zoomCerrent - 1)
      }
    }
  }

  return (<>
    <div className="layout-collaps-history">
      <div className='left-side'>
        <div className='content'></div>
        <div style={{ height, width }}>
          <GoogleMapReact
            bootstrapURLKeys={{ key: `${GOOGLE_MAP_KEY}&libraries=drawing,geometry` }}
            defaultCenter={{ lat: 13.788500, lng: 100.610055 }}
            defaultZoom={5}
            yesIWantToUseGoogleMapApiInternals
            onGoogleApiLoaded={({ map }) => {
              mapRef.current = map
              setMap(map)
              onMapRef(map)
              setTrafficLayer(new window.google.maps.TrafficLayer())
            }}
            onChange={onChange}
            onDrag={onDrag}
            onDragEnd={onDragEnd}
            onZoomAnimationStart={onZoomAnimationStart}
            onZoomAnimationEnd={onZoomAnimationEnd}
            options={{
              gestureHandling: 'greedy',
              zoomControl: false,
              zoomControlOptions: {
                position: 5,
              },
              mapTypeControl: false,
              streetViewControl: false,
              streetViewControlOptions: {
                position: 6,
              },
              rotateControl: false,
              fullscreenControl: false,
              styles: nightMode && [
                { elementType: "geometry", stylers: [{ color: "#242f3e" }] },
                { elementType: "labels.text.stroke", stylers: [{ color: "#242f3e" }] },
                { elementType: "labels.text.fill", stylers: [{ color: "#746855" }] },
                {
                  featureType: "administrative.locality",
                  elementType: "labels.text.fill",
                  stylers: [{ color: "#d59563" }],
                },
                {
                  featureType: "poi",
                  elementType: "labels.text.fill",
                  stylers: [{ color: "#d59563" }],
                },
                {
                  featureType: "poi.park",
                  elementType: "geometry",
                  stylers: [{ color: "#263c3f" }],
                },
                {
                  featureType: "poi.park",
                  elementType: "labels.text.fill",
                  stylers: [{ color: "#6b9a76" }],
                },
                {
                  featureType: "road",
                  elementType: "geometry",
                  stylers: [{ color: "#38414e" }],
                },
                {
                  featureType: "road",
                  elementType: "geometry.stroke",
                  stylers: [{ color: "#212a37" }],
                },
                {
                  featureType: "road",
                  elementType: "labels.text.fill",
                  stylers: [{ color: "#9ca5b3" }],
                },
                {
                  featureType: "road.highway",
                  elementType: "geometry",
                  stylers: [{ color: "#746855" }],
                },
                {
                  featureType: "road.highway",
                  elementType: "geometry.stroke",
                  stylers: [{ color: "#1f2835" }],
                },
                {
                  featureType: "road.highway",
                  elementType: "labels.text.fill",
                  stylers: [{ color: "#f3d19c" }],
                },
                {
                  featureType: "transit",
                  elementType: "geometry",
                  stylers: [{ color: "#2f3948" }],
                },
                {
                  featureType: "transit.station",
                  elementType: "labels.text.fill",
                  stylers: [{ color: "#d59563" }],
                },
                {
                  featureType: "water",
                  elementType: "geometry",
                  stylers: [{ color: "#17263c" }],
                },
                {
                  featureType: "water",
                  elementType: "labels.text.fill",
                  stylers: [{ color: "#515c6d" }],
                },
                {
                  featureType: "water",
                  elementType: "labels.text.stroke",
                  stylers: [{ color: "#17263c" }],
                }
              ]
            }}
          >
            {
              map !== null && <>
                {/* <MapControlCustom
                  id="map-control-custom"
                  map={map}
                  width={150}
                  position={5}
                  customOptions={customOptions}
                  optionChange={(id, active) => optionChange(id, active)}
                  onMapStyleChange={(mapType) => {
                    if (mapType === "night-mode") {
                      setNightMode(!nightMode)
                    }
                    else {
                      setNightMode(false)
                      map.setMapTypeId(mapType)
                    }
                  }}
                /> */}

                <CreatePortal
                  map={map}
                  width={"150"}
                  position={7}>
                  {/* <div className="flex flex-col space-y-2 justify-center p-10">
                    <button
                      className={`inline-block rounded-full text-primary-500 bg-white dark:bg-gray-700 dark:text-white shadow-md w-10 h-10`}
                    >
                      <div style={{ marginLeft: 10 }}>
                        <PlusIcon
                          className={`w-5 h-5`}
                          onClick={() => zoomMap('in')}
                        />
                      </div>

                    </button>

                    <button
                      className={`inline-block rounded-full text-primary-500 bg-white dark:bg-gray-700 dark:text-white shadow-md w-10 h-10`}
                    >
                      <div style={{ marginLeft: 10 }}>
                        <MinusIcon
                          className={`w-5 h-5`}
                          onClick={() => zoomMap('out')}
                        />
                      </div>

                    </button>
                  </div> */}
                  <span className="flex flex-col shadow-sm m-4">
                    <button
                      type="button"
                      className="relative inline-flex items-center rounded-t-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
                    >
                      <PlusIcon
                        className={`w-5 h-5`}
                        onClick={() => zoomMap('in')}
                      />
                    </button>

                    <button
                      type="button"
                      className="relative inline-flex items-center border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10"
                    >
                      <MinusIcon
                        className={`w-5 h-5`}
                        onClick={() => zoomMap('out')}
                      />
                    </button>

                    <Menu as="div" className="relative  block">
                      <Menu.Button className="relative inline-flex items-center border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50">
                        <MapIcon className="h-5 w-5" aria-hidden="true" />
                      </Menu.Button>
                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className="absolute right-0 z-10 mt-2 -mr-1 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                          <div className="py-1">
                            <Menu.Item>
                              <a className="flex items-center px-4 py-2">
                                <input
                                  id="push-everything"
                                  name="push-notifications"
                                  type="radio"
                                  checked={mapType === "roadmap"}
                                  className="focus:ring-primary-300 h-4 w-4 text-primary-600 border-gray-300 cursor-pointer"
                                  onClick={() => onMapStyleChange('roadmap')}
                                />
                                <label htmlFor="push-everything" className="ml-3 block text-sm font-medium text-gray-700 dark:text-gray-200">
                                  แผนที่ทั่วไป
                                </label>
                              </a>
                            </Menu.Item>

                            <Menu.Item>
                              <a className="flex items-center px-4 py-2">
                                <input
                                  id="push-everything"
                                  name="push-notifications"
                                  type="radio"
                                  checked={mapType === "hybrid"}
                                  className="focus:ring-primary-300 h-4 w-4 text-primary-600 border-gray-300 cursor-pointer"
                                  onClick={() => onMapStyleChange('hybrid')}
                                />
                                <label htmlFor="push-everything" className="ml-3 block text-sm font-medium text-gray-700 dark:text-gray-200">
                                  แผนที่ภาพถ่ายดาวเทียม
                                </label>
                              </a>
                            </Menu.Item>

                            <Menu.Item>
                              <a className="flex items-center px-4 py-2">
                                <input
                                  id="push-everything"
                                  name="push-notifications"
                                  type="radio"
                                  checked={mapType === "terrain"}
                                  className="focus:ring-primary-300 h-4 w-4 text-primary-600 border-gray-300 cursor-pointer"
                                  onClick={() => onMapStyleChange('terrain')}
                                />
                                <label htmlFor="push-everything" className="ml-3 block text-sm font-medium text-gray-700 dark:text-gray-200">
                                  แผนที่ภูมิประเทศ
                                </label>
                              </a>
                            </Menu.Item>

                            <Menu.Item>
                              <a className="flex items-center px-4 py-2">
                                <input
                                  id="push-everything"
                                  name="push-notifications"
                                  type="checkbox"
                                  checked={trafficActive}
                                  className="focus:ring-primary-300 h-4 w-4  text-primary-500 border-gray-300  cursor-pointer"
                                  onClick={() => {
                                    if (map !== null) {
                                      if (trafficActive)
                                        trafficLayer.setMap(null)
                                      else
                                        trafficLayer.setMap(map)

                                      setTrafficActive(!trafficActive)
                                    }

                                  }}
                                />
                                <label htmlFor="push-everything" className="ml-3 block text-sm font-medium text-gray-700 dark:text-gray-200">
                                  การจราจร
                                </label>
                              </a>
                            </Menu.Item>
                          </div>
                        </Menu.Items>
                      </Transition>
                    </Menu>

                    <button
                      type="button"
                      className={`${drawingToolActive === "marker" ? "bg-primary-600 hover:bg-primary-800 " : "bg-white hover:bg-gray-50 "} relative inline-flex items-center border border-gray-300 px-2 py-2 text-sm font-medium focus:z-10`}
                      onClick={() => setDrawingToolActive("marker")}
                    >
                      <LocationMarkerIcon className={`w-5 h-5 ${drawingToolActive === "marker" ? "text-white" : "text-gray-700"}`} />
                    </button>

                    <button
                      type="button"
                      className={`${drawingToolActive === "polygon" ? "bg-primary-600 hover:bg-primary-800 " : "bg-white hover:bg-gray-50 "} relative inline-flex items-center border border-gray-300 px-2 py-2 text-sm font-medium focus:z-10`}
                      onClick={() => setDrawingToolActive("polygon")}
                    >
                      <FontAwesomeIcon icon={faDrawPolygon} style={{ marginLeft: 4, color: drawingToolActive === "polygon" ? "white" : "black" }} />
                    </button>

                    <button
                      type="button"
                      className={`${drawingToolActive === "hand" ? "bg-primary-600 hover:bg-primary-800 " : "bg-white hover:bg-gray-50 "} rounded-b-md relative inline-flex items-center border border-gray-300 px-2 py-2 text-sm font-medium focus:z-10`}
                      onClick={() => setDrawingToolActive("hand")}
                    >
                      <HandIcon className={`w-5 h-5 ${drawingToolActive === "hand" ? "text-white" : "text-gray-700"}`} />
                    </button>
                  </span>
                </CreatePortal>

              </>
            }
            {children}
          </GoogleMapReact>
        </div>
      </div>
    </div>
  </ >
  )
}

export default Map


const items = [
  { name: 'Save and schedule', href: '#' },
  { name: 'Save and publish', href: '#' },
  { name: 'Export PDF', href: '#' },
]
