import { useState, useRef, useEffect } from 'react'
import { inject, observer } from 'mobx-react';
import { createMultipleRef, getValueInputRef, validateInput } from '../../../helpers'
import { Input, Card, ModalAlert, Select, FormLoading, Button, Modal, ExportExcel } from '../../../components'
import { CommonService, ZoneService } from "../../../services";
import DataGrid, { SearchPanel, Paging, Pager, Column, HeaderFilter, Toolbar, Item } from 'devextreme-react/data-grid';
import { SearchIcon, PlusIcon } from '@heroicons/react/outline';
import { get, isEmpty } from 'lodash'

let firstRender = false
const Zone = inject("authenStore")(observer((props) => {
  let authenStore = props.authenStore
  let { getAccountAuth } = authenStore // STATE
  let auth = { action_user_id: getAccountAuth?.user_id, token_id: getAccountAuth?.access_token }

  const [masterOptions, setMasterOptions] = useState({
    zone_set: [],
    province: [],
    distince: [],
    routeAutoFilter: []
  })
  const [loadingSaving, setLoadingSaving]: any = useState(false)
  const [loading, setLoading]: any = useState(false)
  const [loadingTable, setLoadingTable]: any = useState(false)
  const [dataSource, setDatasource]: any = useState([])
  const [open, setOpen]: any = useState(false)
  const [shakeIsWrong, setShakeIsWrong] = useState(false)
  const [filter, setFilter]: any = useState({
    zone_set: "",
    code: ""
  })
  const [filterCurrent, setFilterCurrent]: any = useState({
    zone_set: "",
    code: ""
  })
  const [validation, setValidation]: any = useState({})
  const [dataForm, setDataForm]: any = useState({
    "id": "",
    "zone_code": "",
    "zone_name": "",
    "zone_set": "",
    "amp_id": "",
    "prov_id": ""
  })
  const [optionAlert, setOptionAlert]: any = useState({
    show: false,
    action: {
      id: "",
      name: ""
    },
    type: "success",
    title: "",
    description: {
      visible: false,
      type: "",
      content: "",
      contentLists: [],

    }
  })

  const gridRef: any = useRef(null);
  const inputRefS: any = useRef([]);
  const inputRef: any = useRef([]);
  createMultipleRef(inputRefS, 1)
  createMultipleRef(inputRef, 2)
  let columns = [
    {
      dataField: 'no',
      caption: "ลำดับ",
      width: 90,
      alignment: "center"
    },
    {
      dataField: 'zone_code',
      caption: "รหัสโซน"
    },
    {
      dataField: 'zone_name',
      caption: "ชื่อโซน"
    },
    {
      dataField: 'prov_name',
      caption: "จังหวัด"
    }
  ]

  const initialData = () => {
    setValidation({})
    setDataForm({
      "id": "",
      "zone_code": "",
      "zone_name": "",
      "zone_set": "",
      "amp_id": "",
      "prov_id": ""
    })
  }

  useEffect(() => {
    if (firstRender || !isEmpty(getAccountAuth)) {
      searchData()
      CommonService.getMasterData(auth, "zone_set,admin_level1,route_auto_filter")
        .then((res: any) => {
          let zone_set = res.result.zone_set.map((item) => ({ label: item.zone_set_name, value: item.zone_set_code }))
          let province = res.result.admin_level1.map((item) => ({ label: item.admin_level1_name, value: item.admin_level1_id }))
          let routeAutoFilter = res.result.route_auto_filter.map((item) => ({ label: item.desc, value: item.id }))

          setMasterOptions({
            ...masterOptions,
            zone_set,
            province,
            routeAutoFilter
          })
        })
        .catch((err) => {
          console.log(`Load master data : ${err}`)
        })
    }
    else
      firstRender = true
  }, [getAccountAuth])

  const onChangeProvince = (id_ref1) => { // GET MASTER DISTRICT
    if (id_ref1) {
      CommonService.getMasterData(auth, "admin_level2", id_ref1)
        .then((res: any) => {
          let distince = res.result.admin_level2.map((item) => ({ label: item.admin_level2_name, value: item.admin_level2_id }))
          setMasterOptions({ ...masterOptions, distince })
        })
        .catch((err) => {
        })
    }
    else {
      setMasterOptions({
        ...masterOptions,
        distince: []
      })
    }
  }

  //#region Call services
  const searchData = (isReload = false) => {
    setLoadingTable(true)
    let formData = getValueInputRef(inputRefS)
    let data = {
      zone_set: filter?.zone_set,
      code: get(formData, "zone_code_S", "")
    }
    let body = isReload ? filterCurrent : data
    setFilterCurrent(data)

    ZoneService.getZone(auth, body)
      .then((res: any) => {
        setDatasource(res?.result || [])
        setLoadingTable(false)
      })
      .catch((err) => {
        setDatasource([])
        setLoadingTable(false)
      })
  }
  //#endregion

  const onSave = () => {
    let validateCheck = {}, message = { isError: true, message: "โปรดระบุ" }
    let formData = getValueInputRef(inputRef)

    if (formData.zone_code.trim() === "")
      validateCheck['zone_code'] = message
    if (formData.zone_name.trim() === "")
      validateCheck['zone_name'] = message
    if (dataForm.zone_set.length === 0 || dataForm.zone_set === "")
      validateCheck['zone_set'] = message
    if (dataForm.prov_id.length === 0 || dataForm.prov_id === "")
      validateCheck['prov_id'] = message

    if (isEmpty(validateCheck)) {
      setValidation({})
      setOptionAlert({
        ...optionAlert,
        action: {
          id: dataForm.id,
          name: dataForm.id === "" ? 'create' : 'update'
        },
        type: "confirm",
        title: "ยืนยัน",
        show: true,
        description: {
          visible: true,
          type: "text",
          content: "คุณแน่ใจที่จะบันทึกข้อมูล ?",
          contentLists: []
        }
      })
    }
    else {
      setValidation(validateCheck)
      setShakeIsWrong(true)
      setTimeout(() => setShakeIsWrong(false), 100);
    }
  }

  const saveData = () => {
    setLoadingSaving(true)
    let formData = getValueInputRef(inputRef)
    if (optionAlert.action.name === "create") {
      ZoneService.addZone(auth, {
        zone_code: formData.zone_code,
        zone_name: formData.zone_name,
        zone_set: dataForm.zone_set,
        amp_id: dataForm.amp_id,
        prov_id: dataForm.prov_id
      })
        .then((res: any) => {
          let { code, message, sys_message } = res.data
          if (code === 200) {
            setOptionAlert({
              ...optionAlert,
              type: "success",
              show: true,
              action: {
                id: "",
                name: ""
              },
              title: "บันทึกข้อมูลสำเร็จ",
              description: { visible: false, type: "text", content: " ", contentLists: [] }
            })
            searchData(true)
            initialData()
            setOpen(false)
          }
          else {
            setOptionAlert({
              ...optionAlert,
              type: "danger",
              show: true,
              action: {
                id: "",
                name: ""
              },
              title: "ผิดพลาด",
              description: { visible: true, type: "text", content: `${message} ${sys_message}` }
            })
          }
          setLoadingSaving(false)

        })
        .catch((err) => {
          setOptionAlert({
            ...optionAlert,
            type: "danger",
            show: true,
            action: {
              id: "",
              name: ""
            },
            title: "ผิดพลาด",
            description: { visible: true, type: "text", content: `${err}` }
          })
          setLoadingSaving(false)
        })
    }
    else {
      ZoneService.updateZone(auth, {
        zone_id: dataForm.id,
        zone_code: formData.zone_code,
        zone_name: formData.zone_name,
        zone_set: dataForm.zone_set,
        amp_id: dataForm.amp_id,
        prov_id: dataForm.prov_id
      })
        .then((res: any) => {
          let { code, message, sys_message } = res.data
          if (code === 200) {
            setOptionAlert({
              ...optionAlert,
              type: "success",
              show: true,
              action: {
                id: "",
                name: ""
              },
              title: "บันทึกข้อมูลสำเร็จ",
              description: { visible: false, type: "text", content: " ", contentLists: [] }
            })
            searchData(true)
            initialData()
            setOpen(false)
          }
          else {
            setOptionAlert({
              ...optionAlert,
              type: "danger",
              show: true,
              action: {
                id: "",
                name: ""
              },
              title: "ผิดพลาด",
              description: { visible: true, type: "text", content: `${message} ${sys_message}` }
            })
          }
          setLoadingSaving(false)

        })
        .catch((err) => {
          setOptionAlert({
            ...optionAlert,
            type: "danger",
            show: true,
            action: {
              id: "",
              name: ""
            },
            title: "ผิดพลาด",
            description: { visible: true, type: "text", content: `${err}` }
          })
          setLoadingSaving(false)
        })
    }
  }

  const deleteZone = (id) => {
    ZoneService.deleteZone(auth, id)
      .then((res: any) => {
        let { code, message, sys_message } = res
        if (code === 200) {
          setOptionAlert({
            ...optionAlert,
            type: "success",
            show: true,
            action: {
              id: "",
              name: ""
            },
            title: "ลบข้อมูลสำเร็จ",
            description: { visible: false, type: "text", content: " ", contentLists: [] }
          })
          searchData(true)
        }
        else {
          setOptionAlert({
            ...optionAlert,
            type: "danger",
            show: true,
            action: {
              id: "",
              name: ""
            },
            title: "ผิดพลาด",
            description: { visible: true, type: "text", content: `${message} ${sys_message}` }
          })
        }
      })
      .catch((err) => {
        setOptionAlert({
          ...optionAlert,
          type: "danger",
          show: true,
          action: {
            id: "",
            name: ""
          },
          title: "ผิดพลาด",
          description: { visible: true, type: "text", content: `${err}` }
        })
      })
  }


  return (
    <>
      <Card title="ข้อมูลโซนการจัดส่ง">
        <div className="flex flex-col mb-4 space-y-1">
          <div className="space-y-1">
            <div className="flex lg:flex-row sm:flex-col">
              <div className="flex-1 sm:grid sm:grid-cols-3 sm:items-end sm:gap-2">
                <label className="block text-xs font-medium text-gray-700 mb-1 text-right">
                  กลุ่มโซน :
                </label>
                <div className="mt-1 sm:col-span-2 sm:mt-0">
                  <Select
                    allowClear={true}
                    showSearch={true}
                    placeholder="กลุ่มโซน"
                    defaultValue={filter.zone_set}
                    options={masterOptions.zone_set}
                    onChange={(value) => setFilter({ ...filter, zone_set: value })}
                  />
                </div>
              </div>

              <div className="flex-1 sm:grid sm:grid-cols-3 sm:items-end sm:gap-2">
                <label className="block text-xs font-medium text-gray-700 mb-1 text-right">
                  รหัสโซน :
                </label>
                <div className="mt-1 sm:col-span-2 sm:mt-0">
                  <Input
                    hiddenLabel={true}
                    id="zone_code_S"
                    label="รหัสโซน"
                    placeholder="รหัสโซน"
                    onRef={(ref) => inputRefS.current[0] = ref}
                  />
                </div>
              </div>
              <div className="flex-none sm:grid sm:grid-cols-3 sm:items-end sm:gap-2">
                <label className="block text-xs font-medium text-gray-700 mb-1 text-right">
                </label>
                <div className="flex flex-row mt-1 sm:col-span-2 sm:mt-0 space-x-1">
                  <button
                    type="button"
                    title="ค้นหา"
                    className={`flex flex-row ${loading.visible && 'cursor-not-allowed'} items-center rounded border border-transparent bg-blue-100 px-2.5 py-1.5 text-xs font-medium text-blue-700 hover:bg-blue-200`}
                    onClick={() => searchData()}>
                    <div className="flex flex-row-reverse">
                      <span>ค้นหา</span>
                      <SearchIcon className="w-4 h-4 mr-1" />
                    </div>
                  </button>

                  <ExportExcel
                    filename="ข้อมูลโซนการจัดส่ง"
                    data={dataSource}
                    columnConfig={[
                      { label: 'ลำดับ', value: 'no' },
                      { label: 'รหัสโซน', value: 'zone_code' },
                      { label: 'ชื่อโซน', value: 'zone_name' },
                      { label: 'จังหวัด', value: 'prov_name' }
                    ]}
                  />

                  <Button
                    text="เพิ่มข้อมูล"
                    icon={<PlusIcon />}
                    iconPosition='start'
                    onClick={() => setOpen(true)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-5 md:col-span-2 md:mt-0">
          <FormLoading loading={loadingTable} tip="">
            <DataGrid
              ref={(ref) => gridRef.current = ref}
              dataSource={dataSource}
              keyExpr={'id'}
              showBorders={true}
              wordWrapEnabled={false}
              hoverStateEnabled={true}
            >
              <SearchPanel visible={false} />
              <Paging defaultPageSize={10} />
              <Pager
                showInfo={true}
                showPageSizeSelector={true}
                allowedPageSizes={[10, 20, 50]}
              />
              <HeaderFilter visible={true} />
              {
                columns.map((column) => <Column
                  key={column.dataField}
                  width={column.width}
                  dataField={column.dataField}
                  allowFiltering={false}
                  caption={column.caption}
                  alignment={column.alignment}
                />)
              }

              <Column
                fixed={true}
                caption="แก้ไข/ลบ"
                type="buttons"
                width={80}
                buttons={[
                  {
                    hint: "แก้ไข",
                    icon: "fa fa-pencil-square-o",
                    visible: true,
                    onClick: (e) => {
                      e.cancel = "true"
                      let data = e.row.data
                      setDataForm({
                        "id": data.id,
                        "zone_code": data?.zone_code,
                        "zone_name": data.zone_name,
                        "zone_set": data.zone_set_code,
                        "amp_id": data.amp_id || "",
                        "prov_id": data.prov_id || ""
                      })
                      setOpen(true)
                    }
                  },
                  {
                    hint: "ลบ",
                    icon: "trash",
                    visible: true,
                    onClick: (e) => {
                      e.cancel = "true"
                      let data = e.row.data


                      setOptionAlert({
                        ...optionAlert,
                        action: {
                          id: data.id,
                          name: 'delete'
                        },
                        type: "confirm",
                        title: "ยืนยัน",
                        show: true,
                        description: {
                          visible: true,
                          type: "text",
                          content: "คุณแน่ใจที่จะบันทึกข้อมูล ?",
                          contentLists: []
                        }
                      })
                    }
                  }
                ]} />
            </DataGrid>
          </FormLoading>
        </div>

        <Modal
          title={{ text: `${dataForm.id === "" ? 'เพิ่ม' : 'แก้ไข'}โซนการจัดส่ง` }}
          open={open}
          maskClosable={true}
          shake={shakeIsWrong}
          loading={loadingSaving}
          onClosed={() => {
            setOpen(false)
            initialData()
          }}
          onOk={() => onSave()}
        >
          <FormLoading loading={loadingSaving} tip="กำลังบันทึก...">
            <div className="flex flex-col space-y-2">
              <div className='flex-1'>
                <Select
                  label="กลุ่มโซน"
                  placeholder="กลุ่มโซน"
                  required={true}
                  validate={validation?.zone_set}
                  onChange={(select) => setDataForm({ ...dataForm, zone_set: select })}
                  defaultValue={dataForm.zone_set === "" ? [] : dataForm.zone_set}
                  options={masterOptions.zone_set}
                />
              </div>

              <div className='flex-1'>
                <Input
                  id="zone_code"
                  label="รหัสโซน"
                  placeholder="รหัสโซน"
                  required={true}
                  defaultValue={dataForm.zone_code}
                  validate={validation?.zone_code}
                  onRef={(ref) => inputRef.current[0] = ref}
                />
              </div>

              <div className='flex-1'>
                <Input
                  id="zone_name"
                  label="ชื่อโซน"
                  placeholder="ชื่อโซน"
                  required={true}
                  defaultValue={dataForm.zone_name}
                  validate={validation?.zone_name}
                  onRef={(ref) => inputRef.current[1] = ref}
                />
              </div>

              <div className='flex-1'>
                <Select
                  label="จังหวัด"
                  placeholder="จังหวัด"
                  required={true}
                  validate={validation?.prov_id}
                  defaultValue={dataForm.prov_id === "" ? [] : dataForm.prov_id}
                  options={masterOptions.province}
                  onChange={(select) => {
                    if (select) setDataForm({ ...dataForm, prov_id: select, amp_id: "" })

                    onChangeProvince(select)
                  }}
                />
              </div>

              <div className='flex-1'>
                <Select
                  allowClear={true}
                  label="อำเภอ"
                  placeholder="อำเภอ"
                  onChange={(select) => setDataForm({ ...dataForm, amp_id: select })}
                  defaultValue={dataForm.amp_id === "" ? [] : dataForm.amp_id}
                  options={masterOptions.distince}
                />
              </div>
            </div>
          </FormLoading>
        </Modal>
      </Card >

      <ModalAlert
        {...optionAlert}
        onOk={() => {
          let { type, action } = optionAlert
          if (["create", "update"].includes(action.name)) {
            setLoading(true)
            saveData()
          }
          else if (action.name === "delete") {
            deleteZone(action.id)
          }

          setOptionAlert({ ...optionAlert, show: false })
        }}
        onCancel={() => {
          setOptionAlert({ ...optionAlert, show: false })
        }}
      />
    </>
  )
}))

export default Zone