import { useEffect, useState } from 'react'
import { inject, observer } from 'mobx-react';
import FormMaster from '../FormMaster'
import { CommonService } from "../../../services";
import { isEmpty } from 'lodash';

let firstRender = false
const Garage = inject("authenStore")(observer((props) => {
  let authenStore = props.authenStore
  let { getAccountAuth } = authenStore // STATE
  let auth = { action_user_id: getAccountAuth?.user_id, token_id: getAccountAuth?.access_token }

  const [optionStatus, setOptionStatus] = useState([])

  useEffect(() => {
    if (firstRender || !isEmpty(getAccountAuth)) {
      CommonService.getMasterData(auth, "common_status")
        .then((res: any) => {
          let options = res.result.common_status.map((item) => ({ label: item.status_name, value: item.status_code }))
          setOptionStatus(options)
        })
        .catch((err) => {
          console.log(`Load master data : ${err}`)
        })
    }
    else
      firstRender = true
  }, [getAccountAuth])

  return <FormMaster
    title="ข้อมูลอู่รถ"
    tableConfig={{
      key: "id",
      apiPath: "garage/list",
      allowDelete: {
        visible: true,
        key: "garage_id",
        apiPath: "garage/delete",
        paramType: 'param'
      },
      optionDeleteFag: true,
      defaultColumn: [],
      columns: [
        {
          dataField: 'no',
          caption: "ลำดับ",
          width: 60,
          alignment: "center",
          allowFiltering: false
        },
        {
          dataField: 'garage_name',
          caption: "ชื่ออู่",
          allowFiltering: true
        },
        {
          dataField: 'contact_name',
          caption: "ผู้ติดต่อ",
          allowFiltering: true
        },
        {
          dataField: 'tel',
          caption: "เบอร์โทร"
        },
        {
          dataField: 'address',
          caption: "ที่อยู่"
        },
        {
          dataField: 'loc',
          caption: "พิกัด"
        },
        {
          dataField: 'status',
          caption: "สถานะ",
          allowFiltering: true,
          cellRender: (e) => e.value === "Y" ? 'ใช้งาน' : 'ไม่ใช้งาน'
        },
        {
          dataField: 'updated_by',
          caption: "แก้ไขโดย"
        }
      ],
      exportExcel: {
        fileName: 'ข้อมูลอู่รถ',
        columnConfig: [
          { label: 'ลำดับ', value: 'no' },
          { label: 'ชื่ออู่', value: 'garage_name' },
          { label: 'ผู้ติดต่อ', value: 'contact_name' },
          { label: 'เบอร์โทร', value: 'tel' },
          { label: 'ที่อยู่', value: 'address' },
          { label: 'พิกัด', value: 'loc' },
          { label: 'สถานะ', value: (col) => (col.status === "Y" ? 'ใช้งาน' : 'ไม่ใช้งาน') },
          { label: 'แก้ไขโดย', value: 'updated_by' }
        ]
      }
    }}
    formData={{
      garage_name: "",
      address: "",
      phone: "",
      tel: "",
      contact_name: "",
      loc: "",
      status: "Y"
    }}
    modalConfig={{
      visible: true,
      title: "อู่รถ",
      required: ["garage_name"],
      fieldTypeNumber: [],
      paramName: "garage_id",
      apiPath: {
        "get": '',
        "post": 'garage/add',
        "put": 'garage/update'
      },
      elements: [
        {
          fieldName: "garage_name",
          label: "ชื่ออู่",
          placeholder: "ชื่ออู่",
          required: true,
          inputType: "text",
          isOneRow: true
        },
        {
          fieldName: "contact_name",
          label: "ชื่อผู้ติดต่อ",
          placeholder: "ชื่อผู้ติดต่อ",
          inputType: "text",
          isOneRow: true
        },
        {
          fieldName: "address",
          label: "ที่อยู่",
          placeholder: "ที่อยู่",
          inputType: "text",
          isOneRow: true
        },
        {
          fieldName: "tel",
          label: "เบอร์โทร",
          placeholder: "เบอร์โทร",
          inputType: "text",
          isOneRow: true
        },
        {
          fieldName: "loc",
          label: "พิกัด",
          placeholder: "พิกัด",
          inputType: "text",
          isOneRow: true
        },
        {
          showSearch: false,
          fieldName: "status",
          label: "สถานะ",
          inputType: "select",
          defaultValue: "Y",
          isOneRow: true,
          options: optionStatus
        }
      ]
    }}
  />
}))

export default Garage