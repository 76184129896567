import { useEffect, useState } from 'react'
import { inject, observer } from 'mobx-react';
import FormMaster from '../FormMaster'
import { CommonService } from "../../../services";
import { isEmpty } from 'lodash';

let firstRender = false
const Subcontract = inject("authenStore")(observer((props) => {
  let authenStore = props.authenStore
  let { getAccountAuth } = authenStore // STATE
  let auth = { action_user_id: getAccountAuth?.user_id, token_id: getAccountAuth?.access_token }

  const [optionStatus, setOptionStatus] = useState([])

  useEffect(() => {
    if (firstRender || !isEmpty(getAccountAuth)) {
      CommonService.getMasterData(auth, "sub_status")
        .then((res: any) => {
          let options = res.result.sub_status.map((item) => ({ label: item.status_name, value: item.status_code }))
          setOptionStatus(options)
        })
        .catch((err) => {
          console.log(`Load master data : ${err}`)
        })
    }
    else
      firstRender = true
  }, [getAccountAuth])

  return <FormMaster
    title="ข้อมูลผู้รับจ้างช่วง"
    tableConfig={{
      key: "id",
      apiPath: "subcontract/list",
      allowDelete: {
        visible: true,
        key: "sub_id",
        apiPath: "subcontract/delete",
        paramType: "body"
      },
      optionDeleteFag: true,
      defaultColumn: [],
      columns: [
        {
          dataField: 'no',
          caption: "ลำดับ",
          width: 60,
          alignment: "center",
          allowFiltering: false
        },
        {
          dataField: 'sub_name',
          caption: "ชื่อผู้รับจ้างช่วง",
          allowFiltering: true
        },
        {
          dataField: 'contact_name',
          caption: "ชื่อผู้ติดต่อ",
          allowFiltering: true
        },
        {
          dataField: 'contact_tel',
          caption: "เบอร์โทร"
        },
        {
          dataField: 'address',
          caption: "ที่อยู่"
        },
        {
          dataField: 'activate_code',
          caption: "Activate Code"
        },
        {
          dataField: 'owner_name',
          caption: "สังกัด",
          allowFiltering: true
        },
        {
          dataField: 'status_name',
          caption: "สถานะ",
          allowFiltering: true
        },
        {
          dataField: 'updated_by',
          caption: "แก้ไขโดย"
        }
      ],
      exportExcel: {
        fileName: 'ข้อมูลผู้รับจ้างช่วง',
        columnConfig: [
          { label: 'ลำดับ', value: 'no' },
          { label: 'ชื่อผู้รับจ้างช่วง', value: 'sub_name' },
          { label: 'ชื่อผู้ติดต่อ', value: 'contact_name' },
          { label: 'เบอร์โทร', value: 'contact_tel' },
          { label: 'ที่อยู่', value: 'address' },
          { label: 'Activate Code', value: 'activate_code' },
          { label: 'สังกัด', value: 'owner_name' },
          { label: 'สถานะ', value: 'status_name' },
          { label: 'แก้ไขโดย', value: 'updated_by' }
        ]
      }
    }}
    formData={{
      sub_name: "",
      description: "",
      address: "",
      contact_name: false,
      contact_tel: false,
      lat: "",
      lon: "",
      status: "Y",
      owner: "",
      log_vat: 1,
      min_drop: "",
      max_drop: ""
    }}
    modalConfig={{
      screenName: "subcontract",
      visible: true,
      title: "ผู้รับจ้างช่วง",
      required: ["sub_name", "contact_tel"],
      fieldTypeNumber: ["owner"],
      paramName: "sub_id",
      apiPath: {
        "get": '',
        "post": 'subcontract/add',
        "put": 'subcontract/update'
      },
      elements: [
        {
          fieldName: "sub_name",
          label: "ชื่อผู้รับจ้างช่วง",
          placeholder: "ชื่อผู้รับจ้างช่วง",
          required: true,
          inputType: "text",
        },
        {
          fieldName: "contact_name",
          label: "ชื่อผู้ติดต่อ",
          placeholder: "ชื่อผู้ติดต่อ",
          required: false,
          inputType: "text",
        },
        {
          fieldName: "contact_tel",
          label: "เบอร์โทร",
          placeholder: "เบอร์โทร",
          required: true,
          inputType: "text",
        },
        {
          fieldName: "address",
          label: "ที่อยู่",
          placeholder: "ที่อยู่",
          required: false,
          inputType: "text",
        },
        {
          fieldName: "lat",
          label: "ละติจูด",
          placeholder: "ละติจูด",
          required: false,
          inputType: "text",
        },
        {
          fieldName: "lon",
          label: "ลองติจูด",
          placeholder: "ลองติจูด",
          required: false,
          inputType: "text",
        },
        {
          fieldName: "activate_code",
          label: "Activate Code",
          placeholder: "Activate Code",
          required: false,
          disabled: true,
          inputType: "text",
        },
        {
          fieldName: "owner",
          label: "สังกัด",
          inputType: "select",
          defaultValue: 2,
          options: [
            {
              label: "บริษัท",
              value: 1
            },
            {
              label: "ภายนอก",
              value: 2
            }
          ]
        },
        {
          fieldName: "description",
          label: "รายละเอียดเพิ่มเติม",
          placeholder: "รายละเอียดเพิ่มเติม",
          rows: 3,
          required: false,
          inputType: "text",
        },
        {
          showSearch: false,
          fieldName: "status",
          label: "สถานะ",
          inputType: "select",
          defaultValue: "Y",
          options: optionStatus
        },
        {
          fieldName: "log_vat",
          label: "% ภาษีขนส่ง",
          placeholder: "% ภาษีขนส่ง",
          defaultValue: 1,
          min: 0,
          inputType: "number"
        },
        {},
        {
          fieldName: "min_drop",
          label: "จำนวนงานต่ำสุด",
          placeholder: "จำนวนงานต่ำสุด",
          min: 0,
          inputType: "number"
        },
        {
          fieldName: "max_drop",
          label: "จำนวนงานสูงสุด",
          placeholder: "จำนวนงานสูงสุด",
          min: 0,
          inputType: "number"
        },
      ]
    }}
  />
}))

export default Subcontract