
import React from "react";
import DataGrid, { Paging, Column, Scrolling, MasterDetail, Selection } from 'devextreme-react/data-grid';

const DataTable = (props) => {
  let { dataSource } = props // STATE
  let { onEdit } = props // ACTION

  const DetailTemplate = (props) => {
    let dataSource = props?.data?.data?.items[0] || []

    return <React.Fragment>
      <DataGrid
        dataSource={dataSource}
        showBorders={true}
        columnAutoWidth={true}
        width={'100%'}
        onRowPrepared={(e: any) => {
          if (e.rowType === "data") {
            if (e.data.warning_status === "W")
              e.rowElement.style.backgroundColor = "yellow"
            else if (e.data.warning_status === "A")
              e.rowElement.style.backgroundColor = "#ffbdbd"
          }
        }}
      >
        <Column
          dataField="no"
          caption="ลำดับ"
          width={50}
          alignment="center"
        />
        <Column dataField="ma_doc_no" caption="เลขเอกสาร" />
        <Column dataField="ma_type_name" caption="ประเภท" />
        <Column dataField="licenseplate" caption="ทะเบียนรถ" />
        <Column dataField="trans_date" caption="วันที่แจ้ง" />
        <Column dataField="ma_item_name" caption="รายการ" />
        <Column dataField="mileage" caption="เลขไมล์ตอนเข้าอู่" />
        <Column dataField="current_mileage" caption="เลขไมล์ปัจจุบัน" />
        <Column dataField="warning_mileage" caption="เลขไมล์เตือน" />
        <Column dataField="last_item_ma_date" caption="ซ่อมล่าสุด" />
        <Column dataField="ma_cost" caption="ค่าซ่อม" />
        <Column dataField="ma_status_name" caption="สถานะการซ่อม" />
        <Column dataField="warning_status_name" caption="สถานะการเตือน" />
        <Column
          fixed={true}
          caption="แก้ไข"
          type="buttons"
          alignment="center"
          width={50}
          buttons={[
            {
              hint: "แก้ไข",
              icon: "fa fa-pencil-square-o",
              visible: true,
              onClick: (e) => {
                e.cancel = "true"
                onEdit(e.row.data)
              }
            }
          ]} />
      </DataGrid>
    </React.Fragment>
  }

  return (<div className="">
    <DataGrid
      dataSource={dataSource}
      keyExpr="vid"
      height="calc(100vh - 285px)"
      showBorders={true}
      columnAutoWidth={true}
      hoverStateEnabled={true}
    >
      <Scrolling columnRenderingMode="virtual" />
      <Selection
        mode="multiple"
        selectAllMode="allPages"
        showCheckBoxesMode="always"
      />
      <Paging enabled={false} />
      <Column
        dataField="no"
        caption="ลำดับ"
        width={50}
        alignment="center"
      />
      <Column dataField="vehicle_name" caption="ชื่อรถ" />
      <Column dataField="licenseplate" caption="ทะเบียน" />
      <Column dataField="updated_by" caption="แก้ไขโดย" />
      <MasterDetail
        enabled={true}
        component={DetailTemplate}
      />
    </DataGrid>
  </div>
  )
}

export default DataTable